import { Mail, Palette } from "@mui/icons-material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Container, Tab } from "@mui/material"
import { useState } from "react"
import Customize from "./Customize"
import EmailTemplateEditor from "./EmailTemplateEditor"

enum PersonalizationTabs {
    INTERFACE = "INTERFACE",
    EMAIL = "EMAIL",
}

interface PersonalizationProps extends React.ComponentProps<any> {
    interfaceThemesCustomizationEnabled: boolean
}

const Personalization = (props: PersonalizationProps): JSX.Element => {

    const [selectedTab, setSelectedTab] = useState<PersonalizationTabs>(PersonalizationTabs.INTERFACE)

    return (
        <Container sx={{height: "80vh"}}>
            <TabContext value={selectedTab}>
                <TabList onChange={(e, tab) => setSelectedTab(tab)}>
                    <Tab icon={<Palette />} iconPosition="start" label="Tema da Organização" value={PersonalizationTabs.INTERFACE} />
                    <Tab icon={<Mail />} iconPosition="start" label="E-mail de Contratos" value={PersonalizationTabs.EMAIL} />
                </TabList>
                <TabPanel value={PersonalizationTabs.INTERFACE}>
                    <Customize interfaceThemesCustomizationEnabled={props.interfaceThemesCustomizationEnabled} />
                </TabPanel>
                <TabPanel value={PersonalizationTabs.EMAIL} sx={{height: "80vh"}} >
                    <EmailTemplateEditor />
                </TabPanel>
            </TabContext>
        </Container>
    )
}

export default Personalization