class ErrorWrapper {
    message : string;
    isHttpError : boolean;
    httpStatus : number;
    error : Error;
    errorCode : string;
    errorReason : string

    constructor(error : any) {
        this.isHttpError = (!!error?.response) || false;
        //Check for http status
        this.httpStatus = -1;
        if (this.isHttpError) {
            this.httpStatus = error.response.status;
        }
        

        //Check for HTTP message errors
        this.message = null!;
        if (error && error.response && error.response.headers) {
            this.message = error.response.headers["x-reason"] || error.message;
        }
        //Check for Error message
        else if (error.message) {
            this.message = error.message;
        }
        else {
            this.message = "Unexpected error";
        }

        this.errorCode = null!;
        if (error && error.response && error.response.headers) {
            this.errorCode = error.response.headers["x-error-code"];
            console.log(error.response.headers);
        } else {
            this.errorCode = "Unexpected error code";
        }

        this.errorReason = null!;
        if (error && error.response && error.response.headers) {
            this.errorReason = error.response.headers["x-error-reason"];
        } else {
            this.errorReason = "Unexpected error reason";
        }

        this.error = new Error(error);
    }

    get is2xx() : boolean {
        return Math.floor(this.httpStatus / 100) === 2;
    }

    get is4xx() : boolean {
        return Math.floor(this.httpStatus / 100) === 4;
    }

    get is5xx() : boolean {
        return Math.floor(this.httpStatus / 100) === 5;
    }
}

export default ErrorWrapper;