/**
 * Abstraction of an contextual Api configuration
 */
interface ApiContext {
    baseURL: string;
    timeout: number;
}

enum Enviroment {
    DEVELOPMENT = 'development',
    PRODUCTION = 'production'
}

interface CdnServer {
    server : string;
}

/**
 * Abstract of the web application context
 */
class WebappContext {
    /**
     * Store the Accounts API configuration
     */
    contractsAPI: ApiContext;

    accountsAPI: ApiContext;

    accountsWebappUrl : string;

    authWebappUrl : string;

    accountsCdn : CdnServer;

    constructor(contractsAPI: ApiContext, accountsAPI: ApiContext, accountsWebappUrl : string, authWebappUrl : string, accountsCdn: CdnServer) {
        this.contractsAPI = contractsAPI;
        this.accountsAPI = accountsAPI;
        this.accountsWebappUrl = accountsWebappUrl;
        this.authWebappUrl = authWebappUrl;
        this.accountsCdn = accountsCdn;
    }

    /**
     * Return an URL concatened with the path to the accounts webapp URL
     * @param path 
     * @returns 
     */
    pathToAccountsWebappUrl(path: string = ""): string {
        return this.accountsWebappUrl + path;
    }

}

//Store the default development host name
const developmentHost = process.env.REACT_APP_DCHOST || 'localhost';
const avatarCdn = process.env.REACT_APP_AVATAR_CDN || 'http://localhost:8080';

//Create the development context
const DevelopmentContext = new WebappContext(
    {
        baseURL: `http://${developmentHost}:21002/klausc2w-contracts-ws`,
        timeout: 100000
    },
    {
        baseURL: `http://${developmentHost}:21001/klausc2w-accounts-ws`,
        timeout: 100000
    },
    `http://${developmentHost}:22000`,
    `http://${developmentHost}:22002`,
    {
        server: avatarCdn
    },
);

// Create the production context
const ProductionContext = new WebappContext(
    {
        baseURL: `https://contracts-ws.cartorizi.com`,
        timeout: 60000
    },
    {
        baseURL: `https://accounts-ws.klaus.services`,
        timeout: 30000
    },
    `https://accounts.klaus.services`,
    `https://auth.klaus.services`,
    {
        server: "https://accountsblob.blob.core.windows.net/publicassets"
    },
)


//Store the current application context
let CurrentContext: WebappContext;
if (process.env.NODE_ENV === Enviroment.DEVELOPMENT) {
    CurrentContext = DevelopmentContext;
}
else {
    CurrentContext = ProductionContext;
}

//Export modules
export { CurrentContext, DevelopmentContext };