import { Box, Container, Dialog, DialogTitle, IconButton, Input, InputAdornment, Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Theme, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { DigitalContractSignaturesCache } from "../../models/digital-contract-cache";
import { useAppDispatch } from "../../redux/hooks";
import ContractsService from "../../services/contracts";
import { FindInPage, Close, ReportProblemRounded, Search } from "@mui/icons-material";
import { parseToNormalizedContract } from "../../models/contracts";
import { show } from "../../redux/features/contract-view-dialog/contract-view-dialog";
import {show as showNotification} from "../../redux/features/app-global-notification/app-global-notification-slice"
import { RouteParamContext, ServiceResponses } from "../../contexts/route-param";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { useNavigate } from "react-router-dom";
import AuthenticationContext from "../../contexts/authentication";

const SignedContracts = (): JSX.Element => {

    const [searchTerm, setSearchTerm] = useState('');
    
    const [userDigitalContractSignaturesCache, setUserDigitalContractSignaturesCache] = useState<DigitalContractSignaturesCache[]>([])
    const [filteredUserDigitalContractSignaturesCache, setFilteredUserDigitalContractSignaturesCache] = useState<DigitalContractSignaturesCache[]>([]);

    const [userDigitalContractSignaturesCachePage, setUserDigitalContractSignaturesCachePage] = useState(0)
    const [userDigitalContractSignaturesCacheLimit, setUserDigitalContractSignaturesCacheLimit] = useState(30)
    const [userDigitalContractSignaturesCacheTotalRecords, setUserDigitalContractSignaturesCacheTotalRecords] = useState(0)

    const [contractNotFoundDialog, setContractNotFoundDialog] = useState(false)

    const [accountsToken, setAccountsToken] = useState<string | null>(null);

    const routeParam = useContext(RouteParamContext)
    const navigate = useNavigate();
    const auth = useContext(AuthenticationContext)

    const listItemTheme: SxProps<Theme> = {
        width: "100%", overflow: "hidden", display: "inline-block", textOverflow: "ellipsis", whiteSpace: "nowrap"
    }

    const headerListerTheme: SxProps<Theme> = {
        ['@media(max-width:600px)']: { fontSize: "10px" },
    }

    const searchBarTheme: SxProps<Theme> = {
        ['@media(max-width:600px)']: { width: "80%" },
        border: "1px solid rgb(200 200 200)", borderRadius: "4px", padding: "8px", mr: "-2px"
    }

    const dispatch = useAppDispatch();

    /**
     * Effect used to the functions to fetch 
     * user recent viewed digital contract cache
     * and 
     * the user digital contract signatures cache
    */
    useEffect(() => {
        fetchDigitalContractSignaturesCache(1);
    }, [])

    /**
     * Get the query param when application is loaded
     */
    useEffect(() => { getQueryParam() }, [])
    const getQueryParam = () => {
        const token = routeParam.get(ServiceResponses.TOKEN);
        
        // Delete the params from the route
        routeParam.delete([ServiceResponses.TOKEN, ServiceResponses.ACCOUNT_TYPE]);

        //If query param has the token store it 
        if (!token) setAccountsToken(null);
        setAccountsToken(token)
    }

    /**
     * Authenticate the user as soon as the application gets the token as a URL query param
     */
    useEffect(() => { authenticate() }, [accountsToken])
    const authenticate = async () => {
        try {
            if (!accountsToken) return;

            //setLoginLoading(true)
            //Then authenticate the user if the received token
            await auth.authenticationWithAccountsToken(accountsToken)

           
            dispatch(showNotification({ message: `Bem vindo!`, type: 'success' }))
        }
        catch (e) {
            const err = new ErrorWrapper(e);
            //If 403 error, try to create account
           
            dispatch(showNotification({
                type: 'error',
                message: (err.httpStatus === 404) ? `Conta não encontrada` : err.message
            }))

            navigate('/', { replace: true })
        }
    }

    useEffect(() => {
        setFilteredUserDigitalContractSignaturesCache(userDigitalContractSignaturesCache.filter(item => item.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())));
    }, [userDigitalContractSignaturesCache, searchTerm])

    /** Function to fetch the user digital contract signatures cache */
    async function fetchDigitalContractSignaturesCache(currentPage: number, limit: number = 30) {
        const pagination = await ContractsService.paginationOfDigitalContractSignaturesCache(limit)
        setUserDigitalContractSignaturesCacheTotalRecords(pagination.totalRecords)

        ContractsService.fetchDigitalContractSignaturesCache(currentPage, limit)
        .then(response => {
            if (response != null) {
                setUserDigitalContractSignaturesCache(response);
            }
        })
    }

    /** Item list of user digital contract signatures cache */
    function DigitalContractSignaturesListItem(item: DigitalContractSignaturesCache) {
        return (
            <TableRow>
                <TableCell>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        {
                            item.logoUrl
                            ?
                                <Box sx={{width: "50px", height: "25px", backgroundColor: "#FFF", display: "inline-block", marginRight: "1rem"}}>
                                    <img src={`${item.logoUrl}?randomText=${(Math.random() + 1).toString(36).substring(7)}`} alt="" style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                                </Box>
                            :
                                <Box sx={{width: "50px", height: "25px", backgroundColor: "#FFF", display: "inline-block", marginRight: "1rem"}}></Box>
                        }
                        <Typography sx={listItemTheme}>{item.name}</Typography>
                    </Box>
                </TableCell>
                <TableCell>
                    <Typography sx={listItemTheme}>{new Date(item.signedAt).toLocaleString()}</Typography>
                </TableCell>
                <TableCell>
                    <IconButton color='primary' onClick={(e) => handleOpenContract(e, item.contractId)}>
                        <FindInPage fontSize="small" />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    const ContractNotFoundDialog = (): JSX.Element => {
        return (
            <Dialog open={contractNotFoundDialog} onClose={() => setContractNotFoundDialog(false)}  >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <IconButton
                    aria-label="close"
                    onClick={() => setContractNotFoundDialog(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                
                <Box m={3} sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}} >
                    <Typography variant="h6" >Contrato não encontrado</Typography>
                    <Box 
                        my={2}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 100,
                            height: 100,
                            backgroundColor: "red",
                            borderRadius: 5
                        }}
                    >
                        <ReportProblemRounded color="disabled" sx={{fontSize: 75}} />
                    </Box>
                    <Typography>O contrato pode ter sido deletado pela organização</Typography>
                </Box>
            </Dialog>
        )
    }

    function handleOpenContract(event: React.MouseEvent<HTMLButtonElement>, contractId: string): void {
        fetchContractAndOpenContractDialog(contractId)
    }

    /**
     * Fetch the contract and verify if its editable or issued and open the registry 
     */
    async function fetchContractAndOpenContractDialog(contractId: string): Promise<void> {
        try {
            // try to find in the editable contract
            try {
                let contract = await ContractsService.findEditableDigitalContractById(contractId);
                dispatch(show({
                    contract: {
                        normalized: parseToNormalizedContract(contract),
                        source: contract
                    },
                    visible: true
                }));
                return;
            } catch (error) {
                // ignore because it will try to fetch an issued contract
            }

            // try to find in the issued contract
            try {
                let contract = await ContractsService.findIssuedDigitalContractById(contractId);
                dispatch(show({
                    contract: {
                        normalized: parseToNormalizedContract(contract),
                        source: contract
                    },
                    visible: true
                }));
                return
            } catch (error) {
                // throw error
                throw error;
            }

        } catch (error) {
            setContractNotFoundDialog(true)

        }
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(e.target.value);
    };

    function handlePageChange(event: unknown, newPage: number) {
        // To fetch finance charges need to pass newPage + 1 because the pagination of the table starts at 0
        // and the pagination of the back-end starts at 1
        fetchDigitalContractSignaturesCache(newPage + 1, userDigitalContractSignaturesCacheLimit)
        setUserDigitalContractSignaturesCachePage(newPage)
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setUserDigitalContractSignaturesCacheLimit(+event.target.value)
        setUserDigitalContractSignaturesCachePage(0)
        fetchDigitalContractSignaturesCache(1, +event.target.value)
    }

    return (
        <Container>
            <Typography mt={2} sx={{ color: "#64067F", fontSize: "20px", }}>Meus contratos assinados</Typography>
            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                <Input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Filtre os contratos"
                    startAdornment={
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>}
                    sx={searchBarTheme}
                />
            </Box>
            <Paper>
                <TableContainer component={Paper} sx={{ maxHeight: "525px" }}>
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Nome do contrato
                                </TableCell>
                                <TableCell>
                                    Assinado em
                                </TableCell>
                                <TableCell>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (filteredUserDigitalContractSignaturesCache)
                                ?
                                    filteredUserDigitalContractSignaturesCache.map(item => (
                                        DigitalContractSignaturesListItem(item)
                                    ))
                                :
                                    userDigitalContractSignaturesCache.map(s => (
                                        DigitalContractSignaturesListItem(s)
                                    ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination 
                    rowsPerPageOptions={[10, 20, 30]}
                    component={"div"}
                    count={userDigitalContractSignaturesCacheTotalRecords}
                    rowsPerPage={userDigitalContractSignaturesCacheLimit}
                    page={userDigitalContractSignaturesCachePage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Linhas por página"
                />
            </Paper>
            <ContractNotFoundDialog />
        </Container>
    )
}

export default SignedContracts