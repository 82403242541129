import { AttachMoney, CalendarMonth, Edit, InsertDriveFileOutlined, Notes, PeopleAlt, Send } from "@mui/icons-material";
import { Box, Container, Fab, Grid, Tab, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import { ActionButton } from "../../../../utils/editorUtils";
import { LoadingState } from "../../../../utils/types";
import EditorNavbar from "../components/EditorNavbar";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { FileIssueValidityPeriod, EndDatePolicyType, StartDatePolicyType, FileIssueBilling, FileIssueContractPartie, ExternalContractDTO, DigitalContractStates } from "../../../../models/contracts";
import ExternalValidity from "./components/tabs/ExternalValidity";
import ExternalParties from "./components/tabs/ExternalParties";
import ExternalBillings from "./components/tabs/ExternalBillings";
import { FileWithPath } from "react-dropzone";
import FileUpload from "../../../../components/FileUpload";
import ExternalDataReview from "./components/ExternalDataReview";

// Interfaces and Types
type Perspective = "document" | "data";

type DocumentType = "PDF" | "WORD";

// Enums
enum Tabs {
    PARTIES = "PARTIES",
    FINANCE = "FINANCE",
    VALIDITY = "VALIDITY"
}

// Default values
const defaultValidityPeriod: FileIssueValidityPeriod = {
    endDatePolicyType: EndDatePolicyType.UNDETERMINED,
    startDatePolicyType: StartDatePolicyType.SET_BY_ISSUER
}

const CreateExternalContract = (): JSX.Element => {

    // Contexts
    const notification = useAppDispatch();
    const isMobile = useMediaQuery("(max-width:800px)");

    // Default variables
    const defaultActionButtons: ActionButton[] = [
        {
            label: "Editar arquivo do contrato",
            icon: <Edit />,
            onClick: handleEditFile,
            disabled: true,
            value: "edit"
        },
        {
            label: "Emitir contrato",
            icon: <Send />,
            onClick: handleIssueContract,
            disabled: true,
            value: "issue"
        },
    ]

    // Loading states
    const [uploadFileLoading, setUploadFileLoading] = useState<LoadingState>("loaded");

    // Boolean states
    const [canCreateExternalContract, setCanCreateExternalContract] = useState(false);
    const [openDataReviewDialog, setOpenDataReviewDialog] = useState(false);
    const [addPartie, setAddPartie] = useState(false);
    const [addBilling, setAddBilling] = useState(false);

    // Data states
    const [actionButtons, setActionButtons] = useState<ActionButton[]>(defaultActionButtons);
    const [contractFile, setContractFile] = useState<File | null>(null);
    const [fileURI, setFileURI] = useState<string | null>(null);
    const [validityPeriod, setValidityPeriod] = useState<FileIssueValidityPeriod>(defaultValidityPeriod);
    const [signatureDate, setSignatureDate] = useState<Date | null>(null);
    const [parties, setParties] = useState<FileIssueContractPartie[]>([]);
    const [currentCP, setCurrentCP] = useState<FileIssueContractPartie | null>(null);
    const [billings, setBillings] = useState<FileIssueBilling[]>([]);
    const [currentBilling, setCurrentBilling] = useState<FileIssueBilling | null>(null);
    const [documentType, setDocumentType] = useState<DocumentType | null>(null);
    const [externalDTO, setExternalDTO] = useState<ExternalContractDTO | null>(null);

    // Tab states
    const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.VALIDITY);
    const [perspective, setPerspective] = useState<Perspective>("document");

    // useEffects
    useEffect(() => {
        produceDTO();
        enableButtons();
    }, [signatureDate, parties, billings, currentBilling, currentCP, addPartie, addBilling])

    useEffect(() => {
        disableIssueButton();
    }, [currentBilling, currentCP, addPartie, addBilling])

    useEffect(() => {
        if (!canCreateExternalContract) return;
        setOpenDataReviewDialog(true);
        setCanCreateExternalContract(false);
    }, [canCreateExternalContract])

    // Functions
    function enableButtons() {
        if (!signatureDate || currentCP || currentBilling || addPartie || addBilling) return;

        const buttons = actionButtons.map(button => {
            if (button.value === "issue") return { ...button, disabled: false };
            return button;
        })

        setActionButtons(buttons);
    }

    function disableIssueButton() {
        if (!currentCP && !currentBilling && !addPartie && !addBilling) return;

        const buttons = actionButtons.map(button => {
            if (button.value === "issue") return { ...button, disabled: true };
            return button;
        })

        setActionButtons(buttons);
    }

    function produceDTO() {

        if (!documentType || !signatureDate) return;

        const dto: ExternalContractDTO = {
            billingData: billings,
            contractParties: parties,
            contractTags: {
                tags: []
            },
            documentType: documentType,
            name: "",
            region: "BRA",
            signatureDate: signatureDate,
            state: DigitalContractStates.SIGNED,
            validityPeriod: validityPeriod
        }

        setExternalDTO(dto);
    }

    function resetValues() {
        setParties([]);
        setBillings([]);
        setValidityPeriod(defaultValidityPeriod);
        setContractFile(null);
        setFileURI(null);
        setActionButtons(defaultActionButtons);
        setSignatureDate(null);
    }

    // Handler functions
    function handleFileUpload(acceptedFiles: FileWithPath[]) {
        // since the user can only upload one file get the first
        const file = acceptedFiles[0];

        // if the file is not present change state to not-found
        if (!file) {
            setUploadFileLoading("not-found");
            return;
        }

        // if file is not a .docx display a error
        if (
            file.type !== "application/pdf" &&
            file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
            setUploadFileLoading("error");
            return;
        }

        switch (file.type) {
            case "application/pdf":
                setDocumentType("PDF");
                break;
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                setDocumentType("WORD");
                break;
        }

        setContractFile(file);

        const fileBlob = new Blob([file], { type: file.type });

        setFileURI(URL.createObjectURL(fileBlob));

        const updatedButtons = actionButtons.map(button => {
            if (button.value === "edit") {
                return {
                    ...button,
                    disabled: false
                }
            }
            return button;
        })

        setActionButtons(updatedButtons);
    }

    function handleUpdateFields(current: FileIssueContractPartie, newCP: FileIssueContractPartie) {
        const updatedBillings = billings.map(b => {
            if (b.contractParty === current.role) {
                return {
                    ...b,
                    contractParty: newCP.role
                };
            }
            return b;
        })

        setBillings(updatedBillings);
    }

    function handleUpdateParties(updatedParties: FileIssueContractPartie[]) {
        setParties(updatedParties);
    }

    function handleIssueContract() {
        setCanCreateExternalContract(true);
    }

    function handleEditFile() {
        resetValues();
    }

    function handleChangeValidityPeriod(vp: FileIssueValidityPeriod) {
        if (!vp.startDate) return;

        setValidityPeriod(vp);
        setSignatureDate(vp.startDate);
    }

    /**
     * Change the current select tab
     * @param event 
     * @param newValue 
     */
    function handleChangeTabs(event: React.SyntheticEvent, newValue: Tabs) {
        setCurrentTab(newValue);
    }

    return (
        <Box
            sx={{
                maxWidth: "100vw",
                maxHeight: "100vh",
                overflow: "auto",
            }}
        >
            <EditorNavbar title="Criação de contrato externo" actionButtons={actionButtons} />
            <Box sx={{ height: "88vh", width: "100vw", mt: "64px" }}>
                {
                    !isMobile
                        ?
                        <Grid container>
                            <Grid item xs={5} >
                                <Box sx={{ bgcolor: 'background.paper', display: 'flex' }}>
                                    <TabContext value={currentTab}>
                                        <TabPanel value={Tabs.VALIDITY} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                            <Container>
                                                {
                                                    contractFile
                                                        ?
                                                        <>
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Vigência do contrato</Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                Informe a data que o contrato foi assinado e caso exista uma data de termino também pode ser informada.
                                                            </Typography>
                                                            <ExternalValidity setValidityPeriod={handleChangeValidityPeriod} validityPeriod={validityPeriod} />
                                                        </>
                                                        :
                                                        <>
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Vigência do contrato</Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                Para preencher os dados de vigência é necessário enviar o arquivo do contrato.
                                                            </Typography>
                                                        </>
                                                }
                                            </Container>
                                        </TabPanel>
                                        <TabPanel value={Tabs.PARTIES} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                            <Container>
                                                {
                                                    contractFile
                                                        ?
                                                        <>
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Participantes do Contrato</Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                Defina quais são os participantes do contrato
                                                            </Typography>
                                                            <ExternalParties
                                                                addPartie={addPartie}
                                                                setAddPartie={setAddPartie}
                                                                parties={parties}
                                                                setParties={handleUpdateParties}
                                                                currentCP={currentCP}
                                                                setCurrentCP={setCurrentCP}
                                                                updateFields={handleUpdateFields}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Participantes do Contrato</Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                Para adicionar os participantes é necessário enviar o arquivo do contrato.
                                                            </Typography>
                                                        </>
                                                }
                                            </Container>
                                        </TabPanel>
                                        <TabPanel value={Tabs.FINANCE} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                            <Container>
                                                <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Cobranças Financeiras</Typography>
                                                <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                    Aqui você pode adicionar campos de cobranças financeiras que são utilizadas para gerar dados analíticos da sua organização na plataforma. <br /> Lembre-se de confirir se todas as informações estão corretas.
                                                </Typography>
                                                <ExternalBillings
                                                    addBilling={addBilling}
                                                    billings={billings}
                                                    currentBill={currentBilling}
                                                    parties={parties}
                                                    setAddBillings={setAddBilling}
                                                    setBillings={setBillings}
                                                    setCurrentBill={setCurrentBilling}
                                                />
                                            </Container>
                                        </TabPanel>
                                        <TabList
                                            variant="scrollable"
                                            orientation="vertical"
                                            scrollButtons="auto"
                                            allowScrollButtonsMobile
                                            sx={{ borderLeft: 1, borderColor: 'divider' }}
                                            TabIndicatorProps={{
                                                sx: {
                                                    left: 0
                                                }
                                            }}
                                            onChange={(e, newTab) => handleChangeTabs(e, newTab)}
                                        >
                                            <Tab value={Tabs.VALIDITY} label="Vigência" icon={<CalendarMonth />} />
                                            <Tab value={Tabs.PARTIES} label="Participantes" icon={<PeopleAlt />} />
                                            <Tab value={Tabs.FINANCE} disabled={parties.length <= 0} label="Financeiro" icon={<AttachMoney />} />
                                        </TabList>
                                    </TabContext>
                                </Box>
                            </Grid>
                            <Grid item xs={7}
                                sx={{
                                    width: "100%",
                                    height: "100vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    overflow: "auto"
                                }}
                            >
                                {
                                    fileURI
                                        ?
                                        <iframe
                                            src={documentType && documentType === "PDF" ? `${fileURI}#toolbar=0&navpanes=0&scrollbar=0` : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileURI)}`}
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                        :
                                        <FileUpload fileLoading={uploadFileLoading} onFileUpload={handleFileUpload} acceptedFileExtension={[".pdf", ".docx"]} />

                                }
                            </Grid>
                        </Grid>
                        :
                        <Box>
                            {
                                perspective === "document"
                                    ?
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "100vh",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            overflow: "auto"
                                        }}
                                    >
                                        {
                                            fileURI
                                                ?
                                                <iframe
                                                    src={documentType && documentType === "PDF" ? `${fileURI}#toolbar=0&navpanes=0&scrollbar=0` : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileURI)}`}
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                                :
                                                <FileUpload fileLoading={uploadFileLoading} onFileUpload={handleFileUpload} acceptedFileExtension={[".pdf", ".docx"]} />

                                        }
                                    </Box>
                                    :
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "100vh",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            overflow: "auto"
                                        }}
                                    >
                                        <Box sx={{ bgcolor: 'background.paper' }}>
                                            <TabContext value={currentTab}>
                                                <TabList
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    allowScrollButtonsMobile
                                                    onChange={(e, newTab) => handleChangeTabs(e, newTab)}
                                                >
                                                    <Tab value={Tabs.VALIDITY} label="Vigência" icon={<CalendarMonth />} />
                                                    <Tab value={Tabs.PARTIES} label="Participantes" icon={<PeopleAlt />} />
                                                    <Tab value={Tabs.FINANCE} disabled={parties.length <= 0} label="Financeiro" icon={<AttachMoney />} />
                                                </TabList>
                                                <TabPanel value={Tabs.VALIDITY} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container>
                                                        {
                                                            contractFile
                                                                ?
                                                                <>
                                                                    <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Vigência do contrato</Typography>
                                                                    <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                        Informe a data que o contrato foi assinado e caso exista uma data de termino também pode ser informada.
                                                                    </Typography>
                                                                    <ExternalValidity setValidityPeriod={handleChangeValidityPeriod} validityPeriod={validityPeriod} />
                                                                </>
                                                                :
                                                                <>
                                                                    <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Vigência do contrato</Typography>
                                                                    <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                        Para preencher os dados de vigência é necessário enviar o arquivo do contrato.
                                                                    </Typography>
                                                                </>
                                                        }
                                                    </Container>
                                                </TabPanel>
                                                <TabPanel value={Tabs.PARTIES} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container>
                                                        {
                                                            contractFile
                                                                ?
                                                                <>
                                                                    <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Participantes do Contrato</Typography>
                                                                    <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                        Defina quais são os participantes do contrato
                                                                    </Typography>
                                                                    <ExternalParties
                                                                        addPartie={addPartie}
                                                                        setAddPartie={setAddPartie}
                                                                        parties={parties}
                                                                        setParties={handleUpdateParties}
                                                                        currentCP={currentCP}
                                                                        setCurrentCP={setCurrentCP}
                                                                        updateFields={handleUpdateFields}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Participantes do Contrato</Typography>
                                                                    <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                        Para adicionar os participantes é necessário enviar o arquivo do contrato.
                                                                    </Typography>
                                                                </>
                                                        }
                                                    </Container>
                                                </TabPanel>
                                                <TabPanel value={Tabs.FINANCE} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container>
                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Cobranças Financeiras</Typography>
                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                            Aqui você pode adicionar campos de cobranças financeiras que são utilizadas para gerar dados analíticos da sua organização na plataforma. <br /> Lembre-se de confirir se todas as informações estão corretas.
                                                        </Typography>
                                                        <ExternalBillings
                                                            addBilling={addBilling}
                                                            billings={billings}
                                                            currentBill={currentBilling}
                                                            parties={parties}
                                                            setAddBillings={setAddBilling}
                                                            setBillings={setBillings}
                                                            setCurrentBill={setCurrentBilling}
                                                        />
                                                    </Container>
                                                </TabPanel>
                                            </TabContext>
                                        </Box>
                                    </Box>
                            }
                        </Box>
                }
            </Box>
            {
                isMobile
                    ?
                    <Box
                        sx={{ position: "fixed", left: 20, bottom: 50 }}
                    >
                        {
                            perspective === "document"
                                ?
                                <Fab
                                    variant="extended"
                                    color="primary"
                                    onClick={() => setPerspective("data")}
                                >
                                    <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <Notes sx={{ textAlign: "center", display: "flex" }} />
                                    </Box>
                                </Fab>
                                :
                                <Fab
                                    variant="extended"
                                    color="primary"
                                    onClick={() => setPerspective("document")}
                                >
                                    <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <InsertDriveFileOutlined sx={{ textAlign: "center", display: "flex" }} />
                                    </Box>
                                </Fab>
                        }
                    </Box>
                    :
                    <></>
            }
            {
                externalDTO && contractFile
                    ?
                    <ExternalDataReview
                        contractFile={contractFile}
                        externalDTO={externalDTO}
                        open={openDataReviewDialog}
                        setExternalDTO={setExternalDTO}
                        setOpen={setOpenDataReviewDialog}
                    />
                    :
                    <></>
            }
        </Box>
    )
}

export default CreateExternalContract;