
/**
 * Represents an valid RGB color string hexadecimal value.
 */
const HEX_RGB_REGEX = /(#)[0-9a-fA-F]{6,8}/;
/**
 * Represents an valid RGBA color string hexadecimal value.
 */
const HEX_RGBA_REGEX = /(#)[0-9a-fA-F]{8}/;

/**
 * This array store all color regexes so it can be easily listed when necessary
 */
const ALL_INPUT_COLORS_REGEX = [HEX_RGB_REGEX, HEX_RGBA_REGEX];

function toHexDecimal(inputs : number[]) : string {
    let str = "#";
    inputs.forEach(input => {
        //get the hexadecimal representation of the input
        let inputHex = input.toString(16);

        // include padding  
        if (inputHex.length == 1) inputHex = "0" + inputHex;
        str += inputHex;
    });

    return str;
}

/**
 * Assert that an given value will be normalized RGB value: as an integer between 0 and 255
 * @param input 
 * @returns 
 */
function toValidRGBValue(input : number) : number {
    if (input < 0) return 0;
    else if (input > 255) return 255;
    else return Math.round(input);
}

/**
 * Assert that an given value will be normalized Alpha value: as an decimal number between 0 and 1
 * @param input 
 * @returns 
 */
function toValidAphaValue(input : number) : number {
    if (input < 0) return 0;
    else if (input > 255) return 255;
    else return input;
}

interface RGBA {
    red : number;
    green : number;
    blue : number;
    alpha? : number;
}

/**
 * Represents an color object
 */
export class Color {
    red : number;
    green : number;
    blue : number;
    alpha : number;

    constructor(source : RGBA | number[] | string) {
        // for RGBA array color source
        if (Array.isArray(source)) {
            if (source.length < 3) throw new Error("RGB source array must have at least 3 elements");
            this.red = source[0];
            this.green = source[1];
            this.blue = source[2];
            if (source.length >= 4) this.alpha = source[3];
            else this.alpha = 1;
        }
        // for #hex color source  
        else if (typeof source === "string") {
            // test for valid hex RGB/RGBA color string (#000000 or #00000000);
            if (HEX_RGB_REGEX.test(source)) {
                this.red = parseInt(source.substring(1, 3), 16);
                this.green = parseInt(source.substring(3, 5), 16);
                this.blue = parseInt(source.substring(5, 7), 16);
                if (HEX_RGBA_REGEX.test(source)) {
                    const alphaIntFromStr = parseInt(source.substring(7, 9), 16);
                    this.alpha = alphaIntFromStr / 255.0;
                } 
                else this.alpha = 1;
            }
            else {
                const allRegexesAsString = ALL_INPUT_COLORS_REGEX.map(r => r.source).join(", ");
                throw new Error("Color input string should match one of the regular expressions: " + allRegexesAsString);
            }
        }
        // for RGBA input type
        else {
            const rgbaSource = source as RGBA;
            this.red = rgbaSource.red;
            this.alpha = (rgbaSource.alpha) ? rgbaSource.alpha : 1;
            this.green = rgbaSource.green;
            this.blue = rgbaSource.blue;
        }

        // Make object validations 
        this.red = toValidRGBValue(this.red);
        this.green = toValidRGBValue(this.green);
        this.blue = toValidRGBValue(this.blue);
        this.alpha = toValidAphaValue(this.alpha);
    }

    /**
     * Return the brightness scale value of this color. The possible return values are an number between 0 and 1
     * where 0 is 0% bright and 1 is 100% bright.
     */
    get brightness() {
        return (this.redBrightness + this.greenBrightness + this.blueBrightness) / 3;
    }

    /**
     * Return an value index of the brightness of the red color. The return value vary between 0 and 1
     */
    get redBrightness() {
        return this.red / 255;
    }

    /**
     * Return an value index of the brightness of the green color. The return value vary between 0 and 1
     */
    get greenBrightness() {
        return this.green / 255;
    }

    /**
     * Return an value index of the brightness of the blue color. The return value vary between 0 and 1
     */
    get blueBrightness() {
        return this.blue / 255; 
    }

    toRGBHexadecimal() : string {
        return toHexDecimal([this.red, this.green, this.blue]);
    }

    toRGBAHexadecimal() : string {
        return toHexDecimal([this.red, this.green, this.blue, 255 * this.alpha]);
    }

    /**
     * Create an new instance of an Color object more brighter colors. This method reeive an parameter
     * called brightnessIndex that vary between 0 and 1, where 0 is 0% brighter and 1 is 100%.
     * @param brightnessIndex 
     * @returns 
     */
    brighter(brightnessIndex : number) : Color {
        const newColor : RGBA = {
            red : toValidRGBValue(this.red + (255 * brightnessIndex)),
            green : toValidRGBValue(this.green + (255 * brightnessIndex)),
            blue : toValidRGBValue(this.blue + (255 * brightnessIndex)),
            alpha : this.alpha
        }

        return new Color(newColor);
    }

    setLightness(lightness : number) {
        let lf = (2 * lightness) - 1;
        this.red = Math.max(Math.min((255 - this.red) * lf + this.red, 255), 0);
        this.green = Math.max(Math.min((255 - this.green) * lf + this.green, 255), 0);
        this.blue = Math.max(Math.min((255 - this.blue) * lf + this.blue, 255), 0);
    }

    setSaturation(saturation : number) {
        const sf = 128 * (1 - saturation);
        this.red += (this.red > 128) ? -sf : sf;
        this.green += (this.green > 128) ? -sf : sf;
        this.blue += (this.blue > 128) ? -sf : sf;
    }
}

const RandomColorGenerator = {
    /**
     * Generate an random Color object. The alpha scale of the color will set default to 1, but it can be set on the alphaScale parameter
     * @param alphaScale 
     * @returns 
     */
    create : (alphaScale = 1) : Color => {
        return new Color([Math.random() * 256, Math.random() * 256, Math.random() * 256, alphaScale])
    },

    withSL : (saturation : number, lightness : number) : Color => {
        //const baseRandomColor = new Color([0, 0, 255]);
        const baseRandomColor = RandomColorGenerator.create();

        // preset the colors
        let r = baseRandomColor.red;
        let g = baseRandomColor.green;
        let b = baseRandomColor.blue;

        // add lightness calculations
        let lf = (2 * lightness) - 1;
        r = Math.max(Math.min((255 - baseRandomColor.red) * lf + baseRandomColor.red, 255), 0);
        g = Math.max(Math.min((255 - baseRandomColor.green) * lf + baseRandomColor.green, 255), 0);
        b = Math.max(Math.min((255 - baseRandomColor.blue) * lf + baseRandomColor.blue, 255), 0);

        // add saturation calculations
        const sf = 128 * (1 - saturation);
        r += (r > 128) ? -sf : sf;
        g += (g > 128) ? -sf : sf;
        b += (b > 128) ? -sf : sf;
        return new Color([r, g, b]);
    },
}

const Colors = {
    random : RandomColorGenerator
}

export {
    Colors,
    RandomColorGenerator
}