import { DoneAll, Download, InsertDriveFileOutlined, Notes, PictureAsPdf, Print, Task, Warning } from "@mui/icons-material";
import { Avatar, Box, Button, CircularProgress, Divider, Fab, Grid, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, SxProps, Theme, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ContractParty, EditableDigitalContract, IssuedContractDocumentType, IssuedDigitalContract } from "../../../models/contracts";
import { downloadOriginalFile, downloadTheWatermarkAndSignatureContract, GeneratePDF } from "../../../models/generate-contract-blobs";
import { AuthenticatedUser } from "../../../models/user";
import ContractsService from "../../../services/contracts";
import "../css/Registry.css";
import { show } from "../../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../../redux/hooks";
import { InterfaceTheme } from "../../../models/organizations";
import moment from "moment";
import axios from "axios";
import EncryptedPDFDialog from "../../../components/EncryptedPDFDialog";

interface ContractProps extends React.ComponentProps<any> {
    source: EditableDigitalContract | IssuedDigitalContract,
    user: AuthenticatedUser | undefined,
    interfaceTheme: InterfaceTheme | null
};

const Signed = (props: ContractProps): JSX.Element => {

    const { source, user, interfaceTheme } = props

    // Constants
    const contract = props.source as IssuedDigitalContract

    const [loading, setLoading] = useState(false);
    const [contractPartiesWithPendingSignature, setContractPartiesWithPendingSignature] = useState<ContractParty[]>([]);
    const [digitalContractFileDownloadURL, setDigitalContractFileDownloadURL] = useState<string | null>(null);
    const [orginalDigitalContractFileDownloadURL, setorginalDigitalContractFileDownloadURL] = useState<string | null>(null);
    const [digitalContractFileRenderURL, setDigitalContractFileRenderURL] = useState<string | null>(null);

    const [signatureCertificatePDFContentBlob, setSignatureCertificatePDFContentBlob] = useState<Blob | undefined>(undefined);
    const [originalDocumentBlob, setOriginalDocumentBlob] = useState<Blob | undefined>(undefined);
    const [watermarkAndSignatureCertificateBlob, setWatermarkAndSignatureCertificateBlob] = useState<Blob | undefined>(undefined);
    const [documentFilename, setDocumentFilename] = useState<string | null>(null);

    const [inactiveDeletionDate, setInactiveDeletionDate] = useState("")

    const [isOriginalContractEncryptedDialog, setIsOriginalContractEncryptedDialog] = useState(false);
    const [isOriginalContractEncrypted, setIsOriginalContractEncrypted] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const notification = useAppDispatch();

    // references
    const containerReference = useRef<HTMLElement>();

    // effect used to load pending contract parties signatures state
    useEffect(() => {
        // ignore if no signature is set on the contract 
        const signatures = (contract.signatures) ? [...contract.signatures] : [];

        // map the contract parties roles that signed the contract
        const mapOfContractPartiesRolesThatSignedTheContract = signatures.map(s => s.contractParty.role);

        // set the state of the pending signature with only the contract parties that does not have signed the contract yet
        setContractPartiesWithPendingSignature(contract.contractParties.filter(cp => mapOfContractPartiesRolesThatSignedTheContract.indexOf(cp.role) < 0));

        if (contract.state === "INACTIVE" && contract.inactiveAt) {
            const deletionDate = moment(contract.inactiveAt).add(1, "years").toDate()
            setInactiveDeletionDate(deletionDate.toLocaleDateString())
        }
    }, []);

    useEffect(() => {
        setLoading(true)
        generateBlobsOfContracts()
        setLoading(false)
    }, [])

    async function generateBlobsOfContracts() {
        const blobs = await GeneratePDF(contract);

        setOriginalDocumentBlob(blobs.originalcontract);
        setWatermarkAndSignatureCertificateBlob(blobs.watermarkAndSignature);
        setSignatureCertificatePDFContentBlob(blobs.signatureCertificate)
        setIsOriginalContractEncryptedDialog(blobs.isOriginalContractEncrypted);
        setIsOriginalContractEncrypted(blobs.isOriginalContractEncrypted);
        
        switch (contract.documentType) {
            case IssuedContractDocumentType.WORD:
                break;

            case IssuedContractDocumentType.PDF:
                setDigitalContractFileRenderURL(URL.createObjectURL(blobs.watermarkAndSignature))
                break;
            default:
                notification(show({ type: 'error', message: `Tipo ${contract.documentType} não é suportado pela plataforma` }))
        }
    }

    // effect used to load the URL to download the PDF file
    useEffect(() => {
        ContractsService.createIssuedDigitalContractDocumentDownloadURI(source)
            .then(response => {
                const contract = props.source as IssuedDigitalContract
                setDigitalContractFileDownloadURL(response.uri);
                switch (contract.documentType) {
                    case IssuedContractDocumentType.WORD:
                        setDigitalContractFileRenderURL(`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(response.uri)}`)
                        setDocumentFilename(`${contract.id}.docx`)
                        break;

                    case IssuedContractDocumentType.PDF:
                        setDigitalContractFileRenderURL(response.uri)
                        setDocumentFilename(`${contract.id}.pdf`)
                        break;
                    default:
                        notification(show({ type: 'error', message: `Tipo ${contract.documentType} não é suportado pela plataforma` }))
                }
                ContractsService.downloadOrginalDigitalContract(contract)
                    .then(response => setorginalDigitalContractFileDownloadURL(response.uri))
            })
    }, [])

    const buttonTheme: SxProps<Theme> = {
        ['@media(max-width:650px)']: {
            width: "100%"
        },
        ['@media(max-width:350px)']: {
            fontSize: "0.688rem"
        },
        height: "36px",
        width: "210px",
        margin: "14px 0px"
    }

    const boxButtonsTheme: SxProps<Theme> = {
        ['@media(max-width:650px)']: {
            display: "block",
            width: "100%"
        },
        display: "flex",
        justifyContent: "space-between"
    }

    const AllAcceptablePerspectives = [
        "perspective-info",
        "perspective-doc"
    ]

    /**
    * This function will change the perspective of the application view
    * on mobile interface
    */
    function handlePerspectiveChange(perspective: "perspective-info" | "perspective-doc") {
        if (containerReference.current) {
            // remove all perspective that is not equal than the given
            AllAcceptablePerspectives.forEach(p => {
                if (p !== perspective) containerReference.current?.classList.remove(p);
            })
            containerReference.current.classList.add(perspective);
        }
    }

    return (
        <Box ref={containerReference} className="perspective-container perspective-info">
            <Grid container spacing={2} className="view-container" >
                <Grid item xs={9} className="view-doc">
                    <Box sx={{ width: "100%" }}>
                        {
                            props.source.state === "SIGNED"
                                ?
                                <Box sx={[
                                    {
                                        ['@media(max-width:700px)']: {
                                            padding: "0.625rem 1rem"
                                        }
                                    },
                                    {
                                        width: "100%",
                                        display: "block",
                                        padding: "20px 28px",
                                        color: "#555555",
                                        backgroundColor: "#ffffff",
                                        boxShadow: 5,
                                        margin: "auto",
                                        marginBottom: "14px",
                                        borderRadius: "10px",
                                        borderStyle: "solid",
                                        borderColor: "#4caf50",
                                        borderWidth: "10px 0px 0px 0px",
                                        boxSizing: "border-box",
                                    }]}>
                                    <Typography sx={[{
                                        ['@media(max-width:500px)']: {
                                            fontSize: "0.625rem"
                                        }
                                    }, { color: "#555555", }]}>O contrato <b>{contract.name}</b>, foi assinado com sucesso por todas as partes. </Typography>
                                </Box>
                                :
                                props.source.state === "INACTIVE"
                                    ?
                                    <Box sx={[
                                        {
                                            ['@media(max-width:700px)']: {
                                                padding: "0.625rem 1rem"
                                            }
                                        },
                                        {
                                            width: "100%",
                                            display: "block",
                                            padding: "20px 28px",
                                            color: "#555555",
                                            backgroundColor: "#ffffff",
                                            boxShadow: 5,
                                            margin: "auto",
                                            marginBottom: "14px",
                                            borderRadius: "10px",
                                            borderStyle: "solid",
                                            borderColor: "#ffa726",
                                            borderWidth: "10px 0px 0px 0px",
                                            boxSizing: "border-box",
                                        }]}>
                                        <Typography sx={[{
                                            ['@media(max-width:500px)']: {
                                                fontSize: "0.625rem"
                                            }
                                        }, { color: "#555555", }]}>O contrato <b>{contract.name}</b>, foi inativado pela organização e será deletado do sistema em {inactiveDeletionDate}. </Typography>
                                    </Box>
                                    :
                                    <></>
                        }
                        {
                            props.source
                                ?
                                <Box sx={{ height: "70vh", display: "inline-block", width: "100%" }}>
                                    <Box sx={boxButtonsTheme}>
                                        {
                                            (digitalContractFileDownloadURL)
                                                ?
                                                <Button
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick}
                                                    sx={{ ...buttonTheme, '&:hover': { backgroundColor: interfaceTheme ? interfaceTheme.secondaryColor : "#BF87C4" } }}
                                                    variant="contained"
                                                    component="label"
                                                    disabled={loading || !signatureCertificatePDFContentBlob}
                                                >
                                                    {
                                                        loading
                                                            ?
                                                            <CircularProgress size={25} sx={{ color: "#fff" }} />
                                                            :
                                                            (signatureCertificatePDFContentBlob && watermarkAndSignatureCertificateBlob)
                                                                ?
                                                                <Typography sx={{ display: "flex", fontSize: "14px", color: "#fff", alignItems: "center" }}>
                                                                    <Download sx={{ fontSize: "20px", color: "#fff", mr: "10px" }} />
                                                                    Baixar Documento
                                                                </Typography>
                                                                :
                                                                <Typography sx={{ display: "flex", fontSize: "14px", color: "#fff", alignItems: "center" }}>
                                                                    <Download sx={{ fontSize: "18px", color: "#fff", mr: "10px" }} />
                                                                    Preparando download
                                                                </Typography>
                                                    }
                                                </Button>
                                                :
                                                <CircularProgress />
                                        }
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >

                                            <MenuItem onClick={async () => {
                                                if (!signatureCertificatePDFContentBlob) {
                                                    return
                                                }

                                                if (watermarkAndSignatureCertificateBlob) {
                                                    downloadTheWatermarkAndSignatureContract(watermarkAndSignatureCertificateBlob, contract)
                                                }
                                            }}>

                                                <ListItemIcon>
                                                    <Print />
                                                </ListItemIcon>
                                                Baixar documento (Timbrado)
                                            </MenuItem>

                                            <MenuItem disabled={!orginalDigitalContractFileDownloadURL} onClick={() => {
                                                if (!orginalDigitalContractFileDownloadURL) return;
                                                axios.get(orginalDigitalContractFileDownloadURL, {
                                                    responseType: "blob"
                                                }).then(downloadedFile => {
                                                    // create file link in browser's memory
                                                    const href = URL.createObjectURL(downloadedFile.data);

                                                    // create "a" HTML element with href to file & click
                                                    const link = document.createElement('a');
                                                    link.href = href;
                                                    link.setAttribute('download', documentFilename ? documentFilename : "arquivo"); //or any other extension
                                                    document.body.appendChild(link);
                                                    link.click();

                                                    // clean up "a" element & remove ObjectURL
                                                    document.body.removeChild(link);
                                                    URL.revokeObjectURL(href);
                                                })
                                            }}
                                            >
                                                <ListItemIcon>
                                                    {
                                                        contract.documentType === IssuedContractDocumentType.PDF
                                                            ?
                                                            <PictureAsPdf />
                                                            :
                                                            <Task />
                                                    }
                                                </ListItemIcon>
                                                Baixar documento original
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                    {
                                        (watermarkAndSignatureCertificateBlob)
                                            ?
                                            isOriginalContractEncrypted && originalDocumentBlob
                                                ?
                                                <embed
                                                    src={`${URL.createObjectURL(originalDocumentBlob)}#toolbar=0&navpanes=0&scrollbar=0`}
                                                    type="application/pdf"
                                                    width="100%"
                                                    height="875"
                                                ></embed>
                                                :
                                                <embed
                                                    src={`${digitalContractFileRenderURL}#toolbar=0&navpanes=0&scrollbar=0`}
                                                    width="100%"
                                                    height="875"
                                                ></embed>
                                            :
                                            <Box sx={{ width: "100%" }}>
                                                <Skeleton variant="rectangular" width={650} height={800} sx={{ margin: "auto" }} />
                                            </Box>
                                    }
                                </Box>
                                :
                                <></>
                        }

                    </Box>
                </Grid >
                <Grid item xs={3} sx={{ padding: "48px" }} className="view-info">
                    <Box sx={{ width: "100%", padding: "24px" }}>
                        <Typography sx={{ fontSize: "1.375rem", fontWeight: "600", marginBottom: "1.2rem" }} >Assinaturas e Autenticidade</Typography>
                        {
                            (contract.signatures && contract.signatures.length > 0)
                                ?
                                <>
                                    <Typography variant="h6" >Assinaturas das partes do contrato</Typography>
                                    <List>
                                        {
                                            contract.signatures.map(s => (
                                                <React.Fragment key={s.contractParty.role}>
                                                    <ListItem>
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: "#4CAF50" }}>
                                                                <DoneAll />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={s.contractParty.role} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary="Data de assinatura" secondary={new Date(s.signatureDate).toLocaleString()} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText sx={{ wordBreak: "break-all" }} primary="E-mail utilizado para assinatura" secondary={s.userEmail} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary="Endereço IP do assinante" secondary={s.ipAddress} />
                                                    </ListItem>
                                                    <Divider sx={{ mt: 2, mb: 4 }} />
                                                </React.Fragment>
                                            ))
                                        }
                                    </List>
                                </>
                                :
                                <></>
                        }
                        {
                            (contractPartiesWithPendingSignature.length > 0)
                                ?
                                <>
                                    <Typography variant="h6">Partes com assinatura pendente</Typography>
                                    <List>
                                        {
                                            contractPartiesWithPendingSignature.map(cps => (
                                                <React.Fragment>
                                                    <ListItem>
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: "#ffa940" }}>
                                                                <Warning />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={cps.role} />
                                                    </ListItem>
                                                    <Divider sx={{ mt: 2, mb: 4 }} />
                                                </React.Fragment>
                                            ))
                                        }
                                    </List>

                                </>
                                :
                                <></>
                        }
                        <Typography variant="h6">Informações de autenticidade do contrato</Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="Algoritmo de hash do documento" secondary={contract.digitalTrustData.documentHashAlgorithm} />
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ wordBreak: "break-all" }} primary="Valor do hash do documento" secondary={contract.digitalTrustData.documentHashValue} />
                            </ListItem>
                        </List>
                    </Box>
                </Grid>
            </Grid >
            <Box sx={[{ position: "fixed", left: 20, bottom: 50, }]} className="perspective-button-container">
                <Fab
                    variant="extended"
                    color="primary"
                    onClick={() => handlePerspectiveChange("perspective-info")}
                    className="perspective-trigger show-info"

                >
                    <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                        <Notes sx={{ textAlign: "center", display: "flex" }} />
                    </Box>
                </Fab>
                <Fab
                    variant="extended"
                    color="primary"
                    onClick={() => handlePerspectiveChange("perspective-doc")}
                    className="perspective-trigger show-doc"
                >
                    <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                        <InsertDriveFileOutlined sx={{ textAlign: "center", display: "flex" }} />
                    </Box>
                </Fab>
            </Box >
            <EncryptedPDFDialog open={isOriginalContractEncryptedDialog} onClose={() => setIsOriginalContractEncryptedDialog(false)} contract={contract} originalContract={originalDocumentBlob} signatureCertificate={signatureCertificatePDFContentBlob} />
        </Box >
    )
}
export default Signed;
