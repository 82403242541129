import { AlternateEmail, Business } from "@mui/icons-material";
import { Box, Button, FormControl, FormHelperText, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { BillingPeriodType, BillingSpecification, ContractPartie, IdentificationType } from "../../../../../../models/contracts";
import { billTypes } from "../../../../../../utils/editorUtils";
import { BILLING_MAXIMUN_VALUE } from "../../../../../../utils/numberUtils";

// Interface and Types
interface BillingFormProps extends React.ComponentProps<any> {
    currentBill?: BillingSpecification | null,
    billings: BillingSpecification[],
    parties: ContractPartie[],
    addBilling: (bill: BillingSpecification) => void,
    cancelAction: () => void,
}

// Default variables
const dummyBill: BillingSpecification = {
    billingPeriodType: BillingPeriodType.ONE_TIME_PAYMENT,
    maximumValue: BILLING_MAXIMUN_VALUE,
    minimumValue: 0,
    name: "",
    required: true,
    debtorContractParty: {
        role: ""
    },
    debtorContractPartyRole: "",
}

const BillingForm = (props: BillingFormProps): JSX.Element => {

    // Destructure props object
    const { currentBill, billings, parties, addBilling, cancelAction } = props;

    // Data states
    const [newBill, setNewBill] = useState<BillingSpecification>(currentBill ? currentBill : dummyBill);
    const [newBillErrors, setNewBillErrors] = useState<any>({});
    const [billName, setBillName] = useState(newBill.name);
    const [numberOfInstallments, setNumberOfInstallments] = useState(newBill.billingPeriod ? newBill.billingPeriod.maxInstallments : 12);

    // useEffects
    useEffect(() => {
        checkForErrors();
    }, [newBill])

    // Function
    function checkForErrors() {
        const newErrors = { ...newBillErrors }

        // delete all errors
        for (const key in newErrors) {
            delete newErrors[key];
        }

        if (!currentBill || currentBill.name !== newBill.name) {
            const filter = billings.filter(b => b.name.toLocaleLowerCase() === newBill.name.toLocaleLowerCase());

            if (filter.length > 0) newErrors["name"] = "Cobrança já adicionada";
            else if (!newBill.name) newErrors["name"] = "Campo obrigatório";
        }

        if (!newBill.debtorContractParty.role) newErrors["role"] = "Campo obrigatório";

        if (newBill.maximumValue > BILLING_MAXIMUN_VALUE) newErrors["max"] = "Valor máximo excede o limite";
        if (newBill.minimumValue < 0) newErrors["min"] = "Valor minimo excede o limite";
        if (
            newBill.billingPeriodType === BillingPeriodType.INSTALLMENTS &&
            (!newBill.billingPeriod || !newBill.billingPeriod.maxInstallments || newBill.billingPeriod.maxInstallments <= 0)
        ) newErrors["installments"] = "Campo obrigatório";

        setNewBillErrors(newErrors);
        return newErrors;
    }

    // Handler functions
    function handleChangeBillName(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setBillName(e.target.value);
        setNewBill({ ...newBill, name: e.target.value });
    }

    function handleChangeParty(e: SelectChangeEvent) {
        setNewBill({ ...newBill, debtorContractParty: { role: e.target.value }, debtorContractPartyRole: e.target.value })
    }

    function handleChangeBillType(e: SelectChangeEvent) {
        let b: BillingSpecification | null = null;
        if (e.target.value === BillingPeriodType.INSTALLMENTS) {
            b = {
                ...newBill,
                billingPeriod: {
                    maxInstallments: numberOfInstallments
                },
                billingPeriodType: e.target.value as BillingPeriodType
            };
        } else {
            b = {
                ...newBill,
                billingPeriodType: e.target.value as BillingPeriodType
            };
            for (const key in b) {
                if (key === "billingPeriod") {
                    delete b[key];
                }
            }
        }

        setNewBill(b);
    }

    function handleChangeNumberValues(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: "max" | "min" | "installments") {

        if (isNaN(Number(e.target.value))) return;

        let value = Number(e.target.value);

        switch (type) {
            case "max":

                // check that the value is complient to the rules            
                if (value < newBill.minimumValue) value = newBill.minimumValue;
                if (value > BILLING_MAXIMUN_VALUE) value = BILLING_MAXIMUN_VALUE;

                setNewBill({ ...newBill, maximumValue: value });
                break;
            case "min":

                // check that the value is complient to the rules
                if (value < 0) value = 0;
                if (value > newBill.maximumValue) value = newBill.maximumValue;

                setNewBill({ ...newBill, minimumValue: value });
                break;
            case "installments":

                // ensure that the billingPeriodType is Installments
                if (newBill.billingPeriodType !== BillingPeriodType.INSTALLMENTS) return;

                // check that the value is complient to the rules
                if (value < 1) value = 1;
                else if (value > BILLING_MAXIMUN_VALUE) value = BILLING_MAXIMUN_VALUE;

                setNewBill({ ...newBill, billingPeriod: { maxInstallments: value } });
                setNumberOfInstallments(value);
                break;
        }
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                p: 4,
                border: "1px solid rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
                mt: 2,
                mb: 3
            }}
        >
            <TextField
                fullWidth
                value={billName}
                inputProps={{
                    maxLength: 30,
                }}
                onChange={(e) => handleChangeBillName(e)}
                required
                label="Nome da Cobrança"
                sx={{ mt: 3 }}
                error={Boolean(newBillErrors["name"])}
                helperText={newBillErrors["name"] ? newBillErrors["name"] : !newBill.isWildcard ? `${newBill.name.length} / 30` : ""}
                disabled={newBill.isWildcard}
            />
            <FormControl fullWidth sx={{ my: 6 }} required error={Boolean(newBillErrors["role"])}>
                <InputLabel id="contract-party-selection" >Participante responsável por efetuar o pagamento</InputLabel>
                <Select
                    fullWidth
                    labelId="contract-party-selection"
                    value={newBill.debtorContractParty.role}
                    label="Participante responsável por efetuar o pagamento"
                    renderValue={(value) => (
                        <Box display={"flex"} alignItems={"center"}>
                            {
                                parties.filter(p => p.role === newBill.debtorContractParty.role).map(party => (
                                    <ListItemIcon>
                                        {
                                            party.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                                ?
                                                <AlternateEmail />
                                                :
                                                <Business />

                                        }
                                    </ListItemIcon>
                                ))
                            }
                            <Typography >{value}</Typography>
                        </Box>
                    )}
                    onChange={(e) => handleChangeParty(e)}
                    error={Boolean(newBillErrors["role"])}
                >
                    {
                        parties.map(party => (
                            <MenuItem key={party.role} value={party.role} >
                                <ListItemIcon>
                                    {
                                        party.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                            ?
                                            <AlternateEmail />
                                            :
                                            <Business />

                                    }
                                </ListItemIcon>
                                <ListItemText>{party.role}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText >{newBillErrors["role"] ? newBillErrors["role"] : ""}</FormHelperText>
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 6 }}>
                <InputLabel id="bill-type" >Tipo da cobrança</InputLabel>
                <Select
                    fullWidth
                    labelId="bill-type"
                    value={newBill.billingPeriodType}
                    renderValue={(value) => (
                        billTypes.filter(bill => bill.value === value).map(bill => (
                            <Box display={"center"} alignItems={"center"}>
                                {bill.icon}
                                <Typography sx={{ ml: 2 }} >{bill.label}</Typography>
                            </Box>
                        ))
                    )}
                    label="Tipo da cobrança"
                    onChange={handleChangeBillType}
                >
                    {
                        billTypes.map(bill => (
                            <MenuItem value={bill.value}>
                                <ListItemIcon>
                                    {bill.icon}
                                </ListItemIcon>
                                <ListItemText>{bill.label}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            {
                newBill.billingPeriodType === BillingPeriodType.INSTALLMENTS
                    ?
                    <TextField
                        fullWidth
                        required
                        label="Número máximo de parcelas"
                        value={numberOfInstallments}
                        type="number"
                        inputProps={{
                            max: BILLING_MAXIMUN_VALUE,
                            min: 1
                        }}
                        onChange={(e) => handleChangeNumberValues(e, "installments")}
                        sx={{ mb: 6 }}
                        error={newBillErrors["installments"]}
                        helperText={newBillErrors["installments"] ? newBillErrors["installments"] : ""}
                    />
                    :
                    <></>
            }
            <TextField
                fullWidth
                required
                label="Valor mínimo da cobrança"
                value={newBill.minimumValue}
                type="number"
                inputProps={{
                    max: newBill.maximumValue,
                    min: 0
                }}
                onChange={(e) => handleChangeNumberValues(e, "min")}
                error={newBillErrors["min"]}
                helperText={newBillErrors["min"] ? newBillErrors["min"] : ""}
            />
            <TextField
                fullWidth
                required
                label="Valor máximo da cobrança"
                value={newBill.maximumValue}
                type="number"
                inputProps={{
                    max: BILLING_MAXIMUN_VALUE,
                    min: newBill.minimumValue
                }}
                onChange={(e) => handleChangeNumberValues(e, "max")}
                error={newBillErrors["max"]}
                helperText={newBillErrors["max"] ? newBillErrors["max"] : ""}
                sx={{ my: 6 }}
            />
            <TextField
                fullWidth
                multiline
                minRows={3}
                maxRows={5}
                label="Adicionar descrição da cobrança"
                value={newBill.description}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewBill({ ...newBill, description: event.target.value })}
                inputProps={{ maxLength: 200 }}
                helperText={`${newBill.description ? newBill.description.length : 0} / 200`}
                sx={{ mb: 3 }}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                <Button variant="text" onClick={cancelAction} >Cancelar</Button>
                <Button variant="contained" disabled={Object.keys(newBillErrors).length > 0} onClick={() => addBilling(newBill)} >Salvar</Button>
            </Box>

        </Box>
    )
}

export default BillingForm