import { useEffect, useState } from "react";
import { EditorMode, LoadingState } from "../../../../utils/types";
import { useParams } from "react-router-dom";
import { AppBar, Box, Button, CircularProgress, Container, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import ContractsService from "../../../../services/contracts";
import { DigitalContractTemplate } from "../../../../models/contracts";
import ErrorWrapper from "../../../../utils/ErrorWrapper";
import { useAppDispatch } from "../../../../redux/hooks";
import { show } from "../../../../redux/features/app-global-notification/app-global-notification-slice";
import CreateTemplate from "./create/Create";
import { Description, Download, Edit, Save, Menu as MenuIcon } from "@mui/icons-material";
import { ActionButton } from "../../../../utils/editorUtils";
import EditTemplate from "./edit/EditTemplate";

interface TemplateProps extends React.ComponentProps<any> {
    editorMode: EditorMode
}

const Template = (props: TemplateProps): JSX.Element => {

    // Destructure props object
    const { editorMode } = props;

    // Params
    const { templateId } = useParams();

    // Contexts
    const notification = useAppDispatch();

    // Loading States
    const [loadingTemplate, setLoadingTemplate] = useState<LoadingState>("loading");

    // Data states
    const [template, setTemplate] = useState<DigitalContractTemplate | null>(null);
    
    // Use Effects
    useEffect(() => {
        fetchTemplate();
    }, [])

    // Functions
    function fetchTemplate() {
        if (!templateId) {
            if (editorMode === "edit-template") {
                setLoadingTemplate("not-found");
            } else {
                setLoadingTemplate("loaded");
            }

            return;
        };

        let loadingStates: LoadingState | null = null;

        ContractsService.fetchTemplateById(templateId)
            .then(response => {
                setTemplate(response);
            })
            .catch(e => {
                const err = new ErrorWrapper(e);

                switch (err.httpStatus) {
                    case 404:
                        loadingStates = "not-found"
                        setLoadingTemplate(loadingStates);
                        break;
                    default:
                        loadingStates = "error"
                        setLoadingTemplate(loadingStates);
                        notification(show({type: "error", message: err.message}));
                        break;
                };
            })
            .finally(() => {
                if (!loadingStates) {
                    setLoadingTemplate("loaded");
                }
            });
    }

    return (
        <Box 
            sx={{
                maxWidth: "100vw",
                maxHeight: "100vh",
                overflow: "auto",
            }}
        >
            {
                loadingTemplate === "loaded"
                    ?
                    editorMode === "create-template"
                        ?
                        <CreateTemplate />
                        :
                        template
                            ?
                            <EditTemplate template={template} />
                            :
                            <></>
                    :
                    loadingTemplate === "not-found"
                        ?
                        <></>
                        :
                        loadingTemplate === "error"
                            ?
                            <></>
                            :
                            <Box sx={{height: "88vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}} >
                                <CircularProgress sx={{fontSize: "48px"}} color="primary" />
                            </Box>
            }
        </Box>
    )
}

export default Template;