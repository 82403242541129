import { SyntheticEvent, useEffect, useState } from "react";
import { Box, Button, DialogActions, Divider, Drawer, Switch, Typography } from "@mui/material";
import { LoadingButton } from "../../components/LoadingButton";
import { useAppDispatch } from '../../redux/hooks';
import SearchBar from "../../components/SearchBar";
import { SearchResult } from "../../models/search-result";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { AccountRoles, UserAccount } from "../../models/user";
import SubusersService from "../../services/subusers";

interface SubuserRolesProps extends React.ComponentProps<any> {
    open: boolean,
    close: () => void,
    subuser: UserAccount | null,
}

//deno-lint-ignore-file no-explicit-any
const SubuserRoles = (props: SubuserRolesProps) => {

    //Props
    const { open, close, subuser } = props;

    //Context props
    const notification = useAppDispatch();

    //States
    const [loading, setLoading] = useState(false);

    //Roles States
    const [admRole, setAdmRole] = useState(false);
    const [userRole, setUserRole] = useState(false);
    const [contractRole, setContractRole] = useState(false);
    const [financialRole, setFinancialRole] = useState(false);

    useEffect(() => {
        if (!subuser) return;

        if (subuser.accountRoles.length > 0) {
            //Set current user account roles state
            subuser.accountRoles.includes(AccountRoles.ADMINISTRATOR) ? setAdmRole(true) : setAdmRole(false)
            subuser.accountRoles.includes(AccountRoles.USER_AND_TEAMS_MANAGER) ? setUserRole(true) : setUserRole(false)
            subuser.accountRoles.includes(AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER) ? setContractRole(true) : setContractRole(false)
            subuser.accountRoles.includes(AccountRoles.FINANCE_MANAGER) ? setFinancialRole(true) : setFinancialRole(false)
        } else {
            setAdmRole(false)
            setUserRole(false)
            setContractRole(false)
            setFinancialRole(false)
        }


    }, [subuser])

    /**
     * Import a selected user from Klaus Accounts
     * @returns user reutn
     */
    const updateRoles = async () => {
        if (!subuser) return
        setLoading(true)
        try {

            //Get user roles 
            const userRoles: AccountRoles[] = [];
            if (admRole) userRoles.push(AccountRoles.ADMINISTRATOR)
            if (userRole) userRoles.push(AccountRoles.USER_AND_TEAMS_MANAGER)
            if (contractRole) userRoles.push(AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER)
            if (financialRole) userRoles.push(AccountRoles.FINANCE_MANAGER)

            //Send request
            await SubusersService.updateRoles(subuser.uuid, { roles: userRoles })

            //Notify user
            notification(show({ type: 'success', message: `Permissões alteradas` }))

            subuser.accountRoles = userRoles
        } catch (e) {
            notification(show({
                type: 'error',
                message: `Ocorreu um erro: ${new ErrorWrapper(e).message}`
            }))
        } finally { setLoading(false) }
    }

    //Style
    const sty = { display: 'flex', justifyContent: 'space-between', mt: 3, alignItems: 'center' }

    return (
        <Drawer anchor='right' open={open} onClose={close} sx={{ '& .MuiDrawer-paper': { width: { sm: 550, xs: 350 }, p: { sm: 5, xs: 3 } } }}>
            <Typography variant='h6' mb={3} color='primary.main'>Permissões de <strong>{subuser?.preferredName}</strong></Typography>
            <Box sx={sty}>
                <Typography>Administrador</Typography>
                <Switch checked={admRole} onChange={(e: any) => setAdmRole(e.target.checked)} />
            </Box>
            <Typography mb={3} color='text.secondary' variant='body2'>Permissão para gerenciar a conta de uma organização</Typography>
            <Divider />
            {
                admRole
                    ?
                    <>
                         <Box sx={sty}>
                            <Typography>Gerente de usuários e times</Typography>
                            <Switch
                                checked={true} disabled={true} />
                        </Box>
                        <Typography mb={3} color='text.secondary' variant='body2'>Permissão para gerenciar usuários e times pertencentes à uma organização</Typography>
                        <Divider />
                        <Box sx={sty}>
                            <Typography>Gerente de contratos</Typography>
                            <Switch checked={true} disabled={true} />
                        </Box>
                        <Typography mb={3} color='text.secondary' variant='body2'>Permissão para gerenciar os contratos digitais</Typography>
                        <Divider />
                        <Box sx={sty}>
                            <Typography>Gerente Financeiro</Typography>
                            <Switch checked={true} disabled={true} />
                        </Box>
                        <Typography color='text.secondary' variant='body2'>Permissão para acessar o modulo de gestão financeira</Typography>
                        <Divider sx={{my: 3}} />
                        <Typography variant="caption" >Como o usuário já possui permissões de <b>admininstrador</b> todas permissões acima já estão aplicadas automaticamente</Typography>
                    </>
                    :
                    <>
                         <Box sx={sty}>
                            <Typography>Gerente de usuários e times</Typography>
                            <Switch
                                checked={userRole} onChange={(e: any) => setUserRole(e.target.checked)} />
                        </Box>
                        <Typography mb={3} color='text.secondary' variant='body2'>Permissão para gerenciar usuários e times pertencentes à uma organização</Typography>
                        <Divider />
                        <Box sx={sty}>
                            <Typography>Gerente de contratos</Typography>
                            <Switch checked={contractRole} onChange={(e: any) => setContractRole(e.target.checked)} />
                        </Box>
                        <Typography mb={3} color='text.secondary' variant='body2'>Permissão para gerenciar os contratos digitais</Typography>
                        <Divider />
                        <Box sx={sty}>
                            <Typography>Gerente Financeiro</Typography>
                            <Switch checked={financialRole} onChange={(e: any) => setFinancialRole(e.target.checked)} />
                        </Box>
                        <Typography color='text.secondary' variant='body2'>Permissão para acessar o modulo de gestão financeira</Typography>
                    </>
            }
            <DialogActions sx={{ mt: 15, mb: 8 }}>
                <Button onClick={close} color='error'>Cancelar</Button>
                <LoadingButton variant='contained' loading={loading} onClick={updateRoles}>Salvar</LoadingButton>
            </DialogActions>
        </Drawer >
    )
}

export default SubuserRoles;
