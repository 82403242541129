import { ContentCopy, WhatsApp } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { EditableDigitalContract, IssuedDigitalContract, PlatformUserContractParty, IdentifiedByEmailContractParty } from "../../models/contracts"
import React, { useState } from "react"

interface OnCloseCallback {
    (): void
}

interface ShareContractDialogProps extends React.ComponentProps<any> {
    open: boolean,
    source: EditableDigitalContract | IssuedDigitalContract,
    onClose?: OnCloseCallback,
    targetContractPartyEmail?: string,
    shareWithOrganization?: boolean
}

const ShareContractDialog = (props: ShareContractDialogProps): JSX.Element => {

    // build share link
    const url = window.location.toString().replace("/contracts", "");
    let queryParams = "";
    if (props.targetContractPartyEmail) queryParams += "requiredEmail=" + props.targetContractPartyEmail;

    // constants
    const shareLink = `${url}/registry/${props.source.id}?${queryParams}`
    const organizationShareLink = `${url}/contracts?srt=${isAnEditableOrIssuedContract()}&srid=${props.source.id}`

    // state
    const [shareLinkVisible, setShareLinkVisible] = useState(false);
    const [copied, setCopied] = useState(false);

    /**Function to generate the Whatsapp share link and open a new tab with the link, taking the user to the whatsapp page or app */
    const generateWhatsappShareLink = () => {
        const link = props.shareWithOrganization ? organizationShareLink : shareLink;
        const message = `Aqui está seu contrato com a plataforma Cartorizi 📄✍.\r\n` +
        `\r\n` +
        `🔗 Acesse já seu contrato: ${link}\r\n` +
        `\r\n` +
        `⚠ Caso não reconheça ou não queira assinar este contrato apenas ignore-o. Sem o seu consentimento o contrato não terá nenhum valor jurídico`;
        
        const shareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(`${message}`)}`
        window.open(shareURL);
    }

    function isAnEditableOrIssuedContract(){
        if((props.source as IssuedDigitalContract).shardKey){
            return 'issued-contract'
        }
        else{
            return 'editable-contract'
        }
    }

    /**Function to copy the link*/
    function copyToClipboardButton(textToCopy: string) {
        navigator.clipboard.writeText(textToCopy);
    }

    const handleCopied = () => { setCopied(true); setTimeout(() => { setCopied(false) }, 6000) }

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle sx={{ fontWeight: 600 }}>Compartilhar</DialogTitle>
            <DialogContent sx={{ width: "500px" }}>
                {
                    (props.targetContractPartyEmail)
                    ?
                    <>
                        <Typography sx={{ display: "flex", fontSize: "11px", mb: "20px" }}>
                            <Typography sx={{ color: "#f00", fontSize: "11px" }}>*</Typography> 
                            Esse link é exclusivamente para o destinatário: <b>&nbsp;{props.targetContractPartyEmail}</b>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>Gerar link de compartilhamento:</Typography>
                    </>
                    :
                    <></>
                }
                
                {
                    shareLinkVisible
                        ?
                        <Box>
                            <Box sx={{ display: "flex" }}>
                                <TextField onFocus={() => { copyToClipboardButton(props.shareWithOrganization ? organizationShareLink : shareLink); handleCopied() }} sx={{ fontSize: "12px" }} fullWidth value={props.shareWithOrganization ? organizationShareLink : shareLink} variant="outlined" />
                                <Button onClick={() => { copyToClipboardButton(props.shareWithOrganization ? organizationShareLink : shareLink); handleCopied() }}>
                                    <ContentCopy />
                                </Button>
                            </Box>
                            <Typography sx={{ mb: "20px", mr: "10px", fontSize: "10px", height: "10px", display: "flex", justifyContent: "end" }}>{!copied ? "" : "Copiado!"}</Typography>
                        </Box>
                        :
                        <Button onClick={() => setShareLinkVisible(true)} sx={{ mb: "20px" }}>Gerar link</Button>
                }
                <Typography sx={{ mb: "10px" }}>Enviar por Whatsapp:</Typography>
                <Button onClick={() => generateWhatsappShareLink()} sx={{ mb: "20px" }}><WhatsApp sx={{ mr: "10px" }} />Whatsapp</Button>
            </DialogContent>
        </Dialog>
    )
}

export default ShareContractDialog;