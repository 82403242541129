import { CloudUpload } from "@mui/icons-material";
import { Box, CircularProgress, Paper, SxProps, Theme, Typography } from "@mui/material"
import { useCallback } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { LoadingState } from "../utils/types";

type FileExtensions = ".docx" | ".pdf";

interface FileUploadProps extends React.ComponentProps<any> {
    onFileUpload: (acceptedFiles: FileWithPath[]) => void,
    fileLoading: LoadingState,
    acceptedFileExtension: FileExtensions[]
}

const FileUpload = (props: FileUploadProps): JSX.Element => {

    // Destructure props object
    const { onFileUpload, fileLoading, acceptedFileExtension } = props;

    // Configure dropzone callback
    const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
        // Invoke the callback function with the array of accepted files
        onFileUpload(acceptedFiles);
    }, [onFileUpload]);

    // Destructed useDropzone hook
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    // Styles
    const dropzoneTheme: SxProps<Theme> = {
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        border: isDragActive ? '2px dashed #2196f3' : '2px dashed #ccc',
        minWidth: "320px",
        minHeight: "220px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    };

    const dropzoneErrorTheme: SxProps<Theme> = {
        ...dropzoneTheme,
        border: isDragActive ? '2px dashed #2196f3' : '2px dashed #f44336',
        backgroundColor: isDragActive ? "#FFF" : "#e5737366",
    }

    return (
        <Box>
            {
                fileLoading === "loaded"
                    ?
                    <Paper
                        {...getRootProps()}
                        elevation={3}
                        sx={dropzoneTheme}
                    >
                        <input {...getInputProps()} />
                        <CloudUpload style={{ fontSize: 50, marginBottom: 10 }} />
                        <Typography variant="subtitle2">
                            {isDragActive ? 'Solte os arquivos aqui' : 'Arreste e solte ou clique aqui para subir o arquivo'}
                        </Typography>
                    </Paper>
                    :
                    fileLoading === "error"
                        ?
                        <Paper
                            {...getRootProps()}
                            elevation={3}
                            sx={dropzoneErrorTheme}
                        >
                            <input {...getInputProps()} />
                            <CloudUpload style={{ fontSize: 50, marginBottom: 10 }} />
                            <Typography variant="subtitle2">
                                {isDragActive ? 'Solte os arquivos aqui' : `Arquivo enviado não é um arquivo ${acceptedFileExtension.join(", ")}, por favor envie o arquivo correto`}
                            </Typography>
                        </Paper>
                        :
                        fileLoading === "not-found"
                            ?
                            <Paper
                                {...getRootProps()}
                                elevation={3}
                                sx={dropzoneErrorTheme}
                            >
                                <input {...getInputProps()} />
                                <CloudUpload style={{ fontSize: 50, marginBottom: 10 }} />
                                <Typography variant="subtitle2">
                                    {isDragActive ? 'Solte os arquivos aqui' : 'Houve um erro ao enviar o arquivo, por favor envie novamente'}
                                </Typography>
                            </Paper>
                            :
                            <Paper
                                {...getRootProps()}
                                elevation={3}
                                sx={dropzoneTheme}
                            >
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                                    <CircularProgress color="primary" sx={{ fontSize: "48px" }} />
                                </Box>
                            </Paper>


            }
        </Box>
    )
}

export default FileUpload;