
/**
 * Abstraction of the platform user account type
 */
export interface UserAccount {
    deletedAt: Date | null,
    creationDate: Date,
    email: string,
    fullname: string,
    preferredName: string,
    uuid: string,
    hasHandwrittenSignature : Boolean,
    accountRoles: AccountRoles[],
    contractsRestrictViewModeEnabled: boolean,
    emailConfirmationDate?: Date,
    hasAvatar?: boolean,
    organizationPresentationName?: string,
    isSupervisor?: boolean,
    document?: string | null,
    signatureName?: string | null,
}


export interface Subuser extends UserAccount {
    organizationId: string
}

/**
 * Store session data
 */
export interface Session {
    accountType: AccountTypes,
    rasUuid: string,
    roles: SessionRoles,
    stl: number
}

/**
 * Organization subuser roles
 */
export enum SessionRoles {
    ROLE_STL_0 = 'ROLE_STL_0',
    ROLE_STL_1 = 'ROLE_STL_1',
    ROLE_STL_2 = 'ROLE_STL_2',
    ROLE_STL_3 = 'ROLE_STL_3',
    ROLE_STL_4 = 'ROLE_STL_4',
    ROLE_ACCOUNT_TYPE_CUSTOMER = 'ROLE_ACCOUNT_TYPE_CUSTOMER',
    ROLE_ACCOUNT_TYPE_CUSTOMER_SUBUSER = 'ROLE_ACCOUNT_TYPE_CUSTOMER_SUBUSER',
    CONTRACT_SAS_SIGN_CONTRACT = "ROLE_CONTRACT_SAS_SIGN_CONTRACT",
}

export enum AccountRoles {
    ADMINISTRATOR = 'ADMINISTRATOR',
    USER_AND_TEAMS_MANAGER = 'USER_AND_TEAMS_MANAGER',
    DIGITAL_CONTRACT_TEMPLATE_MANAGER = 'DIGITAL_CONTRACT_TEMPLATE_MANAGER',
    FINANCE_MANAGER = "FINANCE_MANAGER"
}

/**
 * Account types of the platform
 */
export enum AccountTypes {
    Administrator = "ADMINISTRATOR",
    Customer = "CUSTOMER",
    CustomerSubuser = "CUSTOMER_SUBUSER"
}

/**
 * Encapsulates UserAccount and Session objects of the authenticated user
 */
export interface AuthenticatedUser {
    account: UserAccount,
    session: Session,
}

export interface UpdateUser {
    signatureDocument: string | null,
    signatureName: string,
}