import { AccessTime, ArrowDropDown, FilterList, InfoOutlined } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Container, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Menu, Radio, RadioGroup, Switch, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import TagsSearch from "../../components/TagsSearch";
import ExternalContractCard from "../../components/external-contracts/ExternalContractCard";
import { EncapsulatedContract, ExternalDigitalContract } from "../../models/contracts";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../redux/hooks";
import ContractsService from "../../services/contracts";
import { TagsModel } from "../../services/tags";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { useNavigate } from "react-router-dom";

interface DateInterval {
    startDate: Date,
    endDate: Date,
}

interface DateIntervalSelection {
    label: string,
    interval: DateInterval | null,
}

const ExternalContractsView = (): JSX.Element => {

    // Contexts
    const notification = useAppDispatch();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const navigate = useNavigate();

    // Static variables
    const PAGINATION_LIMIT = 20;

    // - Date inteval options
    const DateIntervalSelections: DateIntervalSelection[] = [
        {
            label: "Último dia",
            interval: {
                startDate: moment().subtract(1, "days").toDate(),
                endDate: new Date(),
            }
        },
        {
            label: "Últimos 7 dias",
            interval: {
                startDate: moment().subtract(7, "days").toDate(),
                endDate: new Date(),
            }
        },
        {
            label: "Últimos 30 dias",
            interval: {
                startDate: moment().subtract(30, "days").toDate(),
                endDate: new Date(),
            }
        },
        {
            label: "Personalizado",
            interval: null
        },
    ]

    // Boolean states
    const [loadingData, setLoadingData] = useState(false);
    const [showContractTags, setShowContractTags] = useState(true);

    // Data states
    const [externalContracts, setExternalContracts] = useState<ExternalDigitalContract[]>([])
    const [displayContracts, setDisplayContracts] = useState<ExternalDigitalContract[]>([])
    const [selectedDateInterval, setSelectedDateInterval] = useState(DateIntervalSelections[1]);
    const [startDate, setStartDate] = useState(DateIntervalSelections[1].interval?.startDate);
    const [endDate, setEndDate] = useState(new Date());
    const [selectedTag, setSelectedTag] = useState<TagsModel | null>(null);
    const [selectedTags, setSelectedTags] = useState<TagsModel[]>([]);
    const [filterAndAndOr, setFilterAndAndOr] = useState("E");


    // Ref states
    const [dateIntervalMenuAnchor, setDateIntervalMenuAnchor] = useState<null | HTMLElement>(null);
    const [tagFilterMenuAnchor, setTagFilterMenuAnchor] = useState<null | HTMLElement>(null);

    // useEffects
    useEffect(() => {
        fetchExternalContracts();
    }, [startDate, endDate])

    useEffect(() => {
        filterExternalContractsByTag();
    }, [selectedTags])

    // Functions
    async function fetchExternalContracts() {
        setLoadingData(true);
        setExternalContracts([]);
        setDisplayContracts([]);

        if (!startDate || !endDate) return;

        try {
            const pagination = await ContractsService.fetchExternalContractsPaginationMetadata(PAGINATION_LIMIT, startDate, endDate);

            const externalContracts: ExternalDigitalContract[] = []
            for (let currentPage = 1; currentPage <= pagination.totalPages; currentPage++) {
                ContractsService.fetchExternalContractsWithPagination(currentPage, PAGINATION_LIMIT, startDate, endDate)
                    .then(response => {
                        const allContracts = [...response, ...externalContracts];
                        setExternalContracts(allContracts);
                        setDisplayContracts(allContracts);
                    })
                    .catch(e => {
                        const err = new ErrorWrapper(e);
                        notification(show({ type: "error", message: err.message }));
                    })
            }
        } catch (e) {
            const err = new ErrorWrapper(e);
            notification(show({ type: "error", message: err.message }));
        }

        setLoadingData(false);

    }

    function handleFilterAndAndOr(value: string){
        setFilterAndAndOr(value);
    }

    function filterExternalContractsByTag() {
        if (!selectedTags) {
            setDisplayContracts(externalContracts);
            return;
        };

        const filteredContracts = externalContracts.filter(c => {
            if (!c.contractTags) {
                return false;
            }

            if (selectedTags.length > 0) {
                // check if contract has tags
                if (!c.contractTags || c.contractTags.length === 0) {
                    return false; // ignore contract without tags
                }

                const contractTags = c.contractTags;
                
                // "OR" logic - Find all contracts that have matching tags 
                if(filterAndAndOr === "OU") {
                    const matchingTag = contractTags.find(tag => selectedTags.some(t => tag.tagName === t.tagName));
                    if (!matchingTag) {
                        return false;
                    }
                }
                // "E" logic - All tags in selectedTags must match
                else {
                    const hasAllTags = selectedTags.every(selectedTag =>
                        contractTags.some(contractTag => contractTag.tagName === selectedTag.tagName)
                    );
                    if (!hasAllTags) {
                        return false;
                    }
                }
            }

            return true;
        })

        setDisplayContracts(filteredContracts);
    }

    // Handler functions
    /**
     * Function that handles the event when the editable contract is deleted
     * @param deletedContract 
     */
    function handleContractCardContractDeletedEvent(deletedContract: ExternalDigitalContract) {
        // refresh the normalized contracts state removing the deleted contract given by the parameter
        setDisplayContracts(displayContracts.filter(n => n.id !== deletedContract.id));
    }

    // Handler functions
    function handleDateIntervalsRadioGroupOnChange(event: React.ChangeEvent<HTMLInputElement>, value: string): void {
        const selectedDateInterval = DateIntervalSelections.find(d => d.label === value);
        if (selectedDateInterval) {
            // update selected date interval state
            setSelectedDateInterval(selectedDateInterval);
            // if the interval is defined, set it to the start and end date state. This will trigger the contracts fetch function by the useEffect
            if (selectedDateInterval.interval) {
                setStartDate(selectedDateInterval.interval.startDate);
                setEndDate(selectedDateInterval.interval.endDate);
            }
        }
        else {
            throw new Error("Application could not resolve selected date interval selection: " + value);
        }
    }

    function handleTagSelected(tags: TagsModel[]) {
        setSelectedTags(tags);
    };

    function handleShowTags() {
        if (showContractTags) setShowContractTags(false);
        else setShowContractTags(true);
    }



    // JSX Functions
    const ExternalContractsContent = (): JSX.Element => {
        return (
            <>
                {
                    !loadingData
                        ?
                        displayContracts.length > 0
                            ?
                            <Box>
                                <Container>
                                    <Box id="contract-list-container">
                                        {
                                            displayContracts.map(contract => (
                                                <ExternalContractCard
                                                    key={contract.id}
                                                    source={contract}
                                                    showContractTags={showContractTags}
                                                    onContractDeleted={handleContractCardContractDeletedEvent}
                                                />
                                            ))
                                        }
                                    </Box>
                                </Container>
                            </Box>
                            :
                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", textAlign: "center"}}>
                                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", maxWidth: "1000px"}}>
                                    <InfoOutlined sx={{fontSize: "80px"}} color="info" />
                                    <Typography variant="body1">Você não possui nenhum contrato externo registrado neste período</Typography>
                                    <Typography variant="body2" my={1} >
                                        Contratos Externos são documentos que podem ser incluídos na plataforma para serem armazenados e utilizar os analytics, porém, é importante ressaltar que eles não têm validade jurídica garantida pelo Cartorizi.
                                    </Typography>
                                    <Typography variant="caption">
                                        Caso queira saber mais sobre contratos externos basta clicar
                                        <a href="https://klaus-fiscal.movidesk.com/kb/article/432250/contratos-externos"> neste link</a>
                                    </Typography>
                                </Box>
                            </Box>
                        :
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "88vh" }}>
                            <CircularProgress color="primary" size={32} />
                        </Box>
                }

            </>
        )
    }

    return (
        <>
            <Box
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 4
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "100%"
                    }}
                >
                    <Button
                        onClick={() => navigate(`/external-contracts/create`)}
                        variant="contained"
                        sx={{ mb: 3 }}
                    >
                        Adicionar Contrato Externo
                    </Button>
                </Box>
                <Box sx={{ textAlign: "end" }}>
                    <Button
                        variant="outlined"
                        startIcon={<AccessTime />}
                        onClick={e => setDateIntervalMenuAnchor(e.currentTarget)}>
                        <Typography sx={{ color: (theme) => theme.palette.primary.main }} >
                            Filtro por tempo   <b style={{ marginLeft: 4, marginRight: 4 }}>{selectedDateInterval.label} </b> <small> ({startDate?.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).split(',')[0]} - {endDate?.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).split(',')[0]})</small>
                        </Typography>
                    </Button>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch size="small" defaultChecked onChange={handleShowTags} />}
                                label={<Typography sx={{ fontSize: "14px" }} >Mostrar tags</Typography>} />

                        </FormGroup>
                    </Box>
                </Box>
                <Menu
                    id="basic-menu"
                    anchorEl={dateIntervalMenuAnchor}
                    open={Boolean(dateIntervalMenuAnchor)}
                    onClose={() => setDateIntervalMenuAnchor(null)}
                >
                    <FormControl
                        sx={{ p: 2 }}
                    >
                        <FormLabel id="intervals-radio-button-group">Intervalo de busca de contratos</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="intervals-radio-button-group"
                            name="row-intervals-radio-button-group"
                            onChange={handleDateIntervalsRadioGroupOnChange}
                            value={selectedDateInterval.label}
                        >
                            {
                                DateIntervalSelections.map(d => (
                                    <FormControlLabel value={d.label} control={<Radio />} label={d.label} />
                                ))
                            }

                        </RadioGroup>
                    </FormControl>
                    <Divider sx={{ mt: 2 }} />
                    {
                        // Render date pickers only if the date interval selection does not have an defined interval
                        (!selectedDateInterval.interval || selectedDateInterval.label === "Personalizado")
                            ?
                            <Box sx={{ p: 2 }}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Selecione uma data inicial"
                                        inputFormat="DD/MM/YYYY"
                                        value={startDate}
                                        onChange={(value: Moment | null) => { if (value) setStartDate(value.toDate()) }}
                                        renderInput={(params) => <TextField {...params} size='small' sx={{ mb: 2 }} />}
                                        maxDate={moment(endDate)}
                                    />
                                    <DatePicker
                                        label="Selecione uma data final"
                                        inputFormat="DD/MM/YYYY"
                                        value={endDate}
                                        onChange={(value: Moment | null) => { if (value) setEndDate(value.toDate()) }}
                                        renderInput={(params) => <TextField {...params} size='small' sx={{ ml: 2 }} />}
                                        minDate={moment(startDate)}
                                    />
                                </LocalizationProvider>
                            </Box>
                            :
                            <></>
                    }
                </Menu>
                <Grid container columns={{ xs: 12, md: 12 }} sx={{ border: "1px solid rgb(230, 230, 230)", alignItems: "center", minHeight: "48px", mb: 2, p: "0 16px" }}>
                    <Grid item md={2}><Box sx={{ display: (isSmallScreen ? "none" : "flex"), alignItems: "center" }}><FilterList sx={{ margin: "0px 12px" }} />Filtrar</Box></Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container columns={{ xs: 12 }} sx={{ alignItems: "center", justifyContent: "flex-end" }}>
                            <Grid item xs="auto" md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {/**Tags */}
                                <Button
                                    endIcon={<ArrowDropDown />}
                                    onClick={e => setTagFilterMenuAnchor(e.currentTarget)}>
                                    <b>Tag</b>{selectedTag ? `: ${selectedTag.tagName}` : ""}
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={tagFilterMenuAnchor}
                                    open={Boolean(tagFilterMenuAnchor)}
                                    onClose={() => setTagFilterMenuAnchor(null)}
                                >
                                    <TagsSearch onTagSelected={handleTagSelected} selectedTags={selectedTags} onAndAndOrChange={handleFilterAndAndOr} andAndOr={filterAndAndOr} />
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <ExternalContractsContent />
            </Box>
        </>
    );
}

export default ExternalContractsView;