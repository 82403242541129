import { Box, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from "@mui/material";

interface PayWallDialogProps extends React.ComponentProps<any> {
    open: boolean
    onClose: React.ReactEventHandler<{}>
    quotaName: String
    productName: String
    type: "module" | "limiter"
}

const PayWallDialog = (props: PayWallDialogProps): JSX.Element => {

    let quotaMessage = ""
    let productMessage = ""

    if (props.type == "module") {
        quotaMessage = `Você não possui acesso a ${props.quotaName}`
        productMessage = `para adiquirir acesso do ${props.productName}`
    } else {
        quotaMessage = `Você atingiu o seu limite da cota de <b>${props.quotaName}</b>`
        productMessage = `para adiquirir mais ${props.productName}`
    }


    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle sx={{textAlign: "center"}}>
                <img src="/images/logo/cartorize_icon.svg" style={{marginBottom: "10px"}} />
                <Typography sx={{fontSize: "1.5rem", fontWeight: 800}}>Cota de {props.quotaName}</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{textAlign: "center"}}>
                <Typography>{quotaMessage}</Typography>
                <Divider />
                <Typography>{productMessage} {props.productName} entre em contato com a Klaus</Typography>
            </DialogContent>
        </Dialog>
    )
}

export default PayWallDialog;