import SendIcon from '@mui/icons-material/Send';
import { Box, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Fab, SxProps, Tooltip, Typography, Alert } from "@mui/material";
import { Theme, width } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { AttachmentSpecification, ContractParty, EditableDigitalContract, IdentifiedByEmailContractParty, NormalizedAttachment, NormalizedUserRequiredInformation, PlatformUserContractParty, RequiredContractPartyInformation } from "../../../models/contracts";
import { AuthenticatedUser, SessionRoles } from "../../../models/user";
import { show } from "../../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../../redux/hooks";
import ContractsService from "../../../services/contracts";
import ErrorWrapper from "../../../utils/ErrorWrapper";
import RequiredAttachment from "../RequiredAttachment";
import RequiredInformationComponent from "../rifields/RequiredInformation";
import { InterfaceTheme } from '../../../models/organizations';
import AuthenticationContext from '../../../contexts/authentication';
import StlInterceptor from '../../../contexts/stl';
import { SecurityTierLevels } from '../../../store/stl';
import RenewAccessDialog from '../../../views/authentication/RenewAccessDialog';
import AuthorizationService from '../../../services/authorization';

interface ContractProps extends React.ComponentProps<any> {
    source: EditableDigitalContract | undefined,
    user: AuthenticatedUser | undefined,
    interfaceTheme: InterfaceTheme | null
};

interface RequiredContractPartyAttachment {
    contractParty: ContractParty,
    description: string,
    name: string,
    required: boolean,
};

const RequiredInformationTab = (props: ContractProps): JSX.Element => {

    const { source, user, interfaceTheme } = props;

    // ctx
    const stl = useContext(StlInterceptor);
    const auth = useContext(AuthenticationContext);

    const [showRenewAccessDialog, setShowRenewAccessDialog] = useState(false);
    const [showRenewAccessDialogRemove, setShowRenewAccessDialogRemove] = useState(false);

    const contract = props.source;
    if (!contract) {
        console.error("Contract not found", props.source);
        throw new Error("Contract not found");
    }

    //Verify if the contract is Revoked
    const isRevoked = props.source?.revokedBy !== null && (props.source?.revokedBy || []).length > 0;

    // States
    const notification = useAppDispatch();
    const [loadingButtonSubmitRequiredInformations, setLoadingButtonSubmitRequiredInformations] = useState(false);
    const [loadingRevokeButton, setLoadingRevokeButton] = useState(false);
    const [loading, setLoading] = useState(false);

    // States used to authenticate the user and verify if he is a contract party
    const [contractParties, setContractParties] = useState<(IdentifiedByEmailContractParty | PlatformUserContractParty)[]>([]);
    const [verifiedContractParty, setVerifiedContractParty] = useState<IdentifiedByEmailContractParty | PlatformUserContractParty>();

    const [verifiedCPRevokedThisContract, setVerifiedCPRevokedThisContract] = useState(false);

    // Required Contract Parties Information in template
    const [requiredContractPartiesAttachments, setRequiredContractPartiesRequiredAttachments] = useState<RequiredContractPartyAttachment[]>([]);
    const [allFieldsAreFilled, setAllFieldsAreFilled] = useState(false);
    const [allFieldsHasBeenFilledInForThisContractPartie, setAllFieldsHasBeenFilledInForThisContractPartie] = useState(false);

    // User Required Informations
    const [userRequiredInformations, setUserRequiredInformations] = useState<NormalizedUserRequiredInformation[]>(processNormalizedUserRequiredInformation(contract));
    const [userRequiredAttachments, setUserRequiredAttachments] = useState<NormalizedAttachment[]>(processNormalizedAttachment(contract));

    // Dialog
    const [revokeDialogIsOpen, setRevokeDialogIsOpen] = useState(false);
    const [removeRevokeDialogIsOpen, setRemoveRevokeDialogIsOpen] = useState(false);

    function processNormalizedUserRequiredInformation(contract: EditableDigitalContract): NormalizedUserRequiredInformation[] {
        return (
            (contract.template.requiredContractPartiesInformation as RequiredContractPartyInformation[])
                .filter(rcpi => {
                    // only fetch required informations if:
                    // 1) The authenticated user is the issuer and the template is allowed to recieve data from the issuer
                    // 2) or, if the authenticated user is the target contract party role
                    const filterResult =
                        (contract.issuerUuid === props.user?.account.uuid && props.source?.template.allowIssuerToSendRequiredData) ||
                        (verifiedContractParty?.role === rcpi.contractParty.role)

                    return filterResult;

                })
                .map((rcpi): NormalizedUserRequiredInformation => {
                    const userInput = contract.userInformation?.find(ui => ui.requiredInformation.requiredInformation.name === rcpi.requiredInformation.name);
                    return {
                        isValid: false,
                        requiredInformation: rcpi,
                        requiredInformationName: rcpi.requiredInformation.name,
                        value: (userInput) ? userInput.value : ""
                    }
                })
        )
    }

    function processNormalizedAttachment(contract: EditableDigitalContract): NormalizedAttachment[] {
        return (
            (contract.template.attachmentSpecifications as AttachmentSpecification[])
                .filter(as => {
                    // only fetch required informations if:
                    // 1) The authenticated user is the issuer and the template is allowed to recieve data from the issuer
                    // 2) or, if the authenticated user is the target contract party role
                    return (contract.issuerUuid === props.user?.account.uuid && props.source?.template.allowIssuerToSendRequiredData) ||
                        verifiedContractParty?.role === as.contractParty.role
                })
                .map((as): NormalizedAttachment => {
                    const userAttachment = contract.attachments?.find(a => a.name === as.name);
                    return {
                        // the attachment will be considered valid if  is not required or it already have a user attachment uploaded into it
                        isValid: (!as.required) ? true : (userAttachment) ? true : false,
                        specification: as,
                        attachment: userAttachment
                    }
                })
        )
    }

    // Verify if the verified user already filled the informations and the attachments
    function verifyIfAllFieldsHasBeenFilledInForThisContractPartie(contract: EditableDigitalContract): boolean {
        let count = 0;
        let requiredFields = [];

        (contract.template.requiredContractPartiesInformation as RequiredContractPartyInformation[]).filter(rcpi => {
            // only fetch required informations if:
            // 1) The authenticated user is the issuer and the template is allowed to recieve data from the issuer
            // 2) or, if the authenticated user is the target contract party role
            const filterResult =
                (contract.issuerUuid === props.user?.account.uuid && props.source?.template.allowIssuerToSendRequiredData) ||
                (verifiedContractParty?.role === rcpi.contractParty.role)
            return filterResult;
        }).forEach(rcpi => {
            const userInput = contract.userInformation?.find(ui => ui.requiredInformation.requiredInformation.name === rcpi.requiredInformation.name);
            if (userInput) { count += 1 }
            else { count -= 1; requiredFields.push(userInput) };
        });

        (contract.template.attachmentSpecifications as AttachmentSpecification[])
            .filter(as => {
                // only fetch required informations if:
                // 1) The authenticated user is the issuer and the template is allowed to recieve data from the issuer
                // 2) or, if the authenticated user is the target contract party role
                return (contract.issuerUuid === props.user?.account.uuid && props.source?.template.allowIssuerToSendRequiredData) ||
                    verifiedContractParty?.role === as.contractParty.role
            })
            .forEach(as => {
                const userAttachment = contract.attachments?.find(a => a.name === as.name);
                if (userAttachment) { count += 1 }
                else { count -= 1; requiredFields.push(userAttachment) };
            })

        return (
            (count >= 0 && requiredFields.length == 0)
        )
    }

    async function revokeContract() {
        if (props.source) {
            setLoadingRevokeButton(true);

            let contractsSessions = user;
            
            if (!contractsSessions) {
                contractsSessions = await AuthorizationService.me();
            }
            
            let canUserSign = false;

            for (let i = 0; i < contractsSessions.session.roles.length; i++) {
                const role: any = contractsSessions.session.roles.at(i)
                if (Object.values(role).includes(SessionRoles.CONTRACT_SAS_SIGN_CONTRACT)) {
                    canUserSign = true
                    break;
                }
            }
            if (contractsSessions.session.stl >= SecurityTierLevels.SamePerson || canUserSign) {
                try {
                    await ContractsService.revokeEditableDigitalContract(props.source.id);
                    notification(show({
                        type: 'success',
                        message: 'Revogado com sucesso.'
                    }))
                    setVerifiedCPRevokedThisContract(true);
                } catch (e) {
                    const err = new ErrorWrapper(e)
                    notification(show({
                        type: 'error',
                        message: `Não foi possível revogar o contrato.`
                    }))
                }
                finally {
                    setLoadingRevokeButton(false);
                    setRevokeDialogIsOpen(false);
                    setShowRenewAccessDialog(false);
                }
            }
            else {
                setShowRenewAccessDialog(true);
            }
            setLoadingRevokeButton(false);
        }
    }

    async function removeRevokeContract() {
        if (props.source) {
            setLoadingRevokeButton(true);
            const contractsSessions = await AuthorizationService.me();
            let canUserSign = false;

            for (let i = 0; i < contractsSessions.session.roles.length; i++) {
                const role: any = contractsSessions.session.roles.at(i)
                if (Object.values(role).includes(SessionRoles.CONTRACT_SAS_SIGN_CONTRACT)) {
                    canUserSign = true
                    break;
                }
            }
            if (contractsSessions.session.stl >= SecurityTierLevels.SamePerson || canUserSign) {
                try {
                    await ContractsService.removeRevokeEditableDigitalContract(props.source.id);
                    notification(show({
                        type: 'success',
                        message: 'Revogação revertida com sucesso.'
                    }))
                    setVerifiedCPRevokedThisContract(false);
                } catch (e) {
                    const err = new ErrorWrapper(e)
                    notification(show({
                        type: 'error',
                        message: `Não foi possível reverter a revogação do contrato.`
                    }))
                }
                finally {
                    setLoadingRevokeButton(false);
                    setRemoveRevokeDialogIsOpen(false);
                    setShowRenewAccessDialogRemove(false);
                }
            }
            else {
                setShowRenewAccessDialog(true);
            }
            setLoadingRevokeButton(false);
        }
    }

    // As the verified contract party is set asynchronously we must create this effect
    // to repopulate the normalizes user required information data
    useEffect(() => {
        setUserRequiredInformations(processNormalizedUserRequiredInformation(contract));
        setUserRequiredAttachments(processNormalizedAttachment(contract));
        setAllFieldsHasBeenFilledInForThisContractPartie(verifyIfAllFieldsHasBeenFilledInForThisContractPartie(contract));

        // Verify if the current user revoked the contract
        if (contract.revokedBy && verifiedContractParty) {
            const thisContractPartyRevokedThisContract = contract.revokedBy.some(revoked => {
                return revoked.contractParty.role === verifiedContractParty.role;
            });
            setVerifiedCPRevokedThisContract(thisContractPartyRevokedThisContract);
        }
    }, [verifiedContractParty])

    // fetch the contract and set the Required Contract Parties Informations and Attachments
    useEffect(() => {
        if (props.source) {
            setContractParties(props.source.contractParties);
            setRequiredContractPartiesRequiredAttachments(props.source.template.attachmentSpecifications);
        }
    }, [])

    // fetch the contract parties
    useEffect(() => {
        contractParties?.forEach(contractParty => {

            // verify if the contract party is identified by email
            if (contractParty.contractPartyIdentificationType === "IDENTIFIED_BY_EMAIL") {
                contractParty = contractParty as IdentifiedByEmailContractParty;

                // Check if the contract party is the same as the contract by its email
                if (contractParty.email === props.user?.account.email) {
                    setVerifiedContractParty(contractParty);
                }
            }
            // verify if the contract party is a platform user
            else if (contractParty.contractPartyIdentificationType === "PLATFORM_USER") {
                contractParty = contractParty as PlatformUserContractParty;

                // Check if the contract party is the same as the contract by its uuid
                if (contractParty.uuid === props.user?.account.uuid) {
                    setVerifiedContractParty(contractParty)
                }
            }
        })
    }, [contractParties])

    useEffect(() => {
        // all fields are considered filled when all 'isValid' prop is true
        if (contract) {
            const ra = contract.template.attachmentSpecifications
            const ri = contract.template.requiredContractPartiesInformation

            // all fields are considered filled when any field is set as invalid
            setAllFieldsAreFilled(
                (userRequiredInformations.filter(rip => !rip.isValid).length == 0) &&
                (userRequiredAttachments.filter(rap => !rap.isValid).length == 0)
            );
        }
    }, [userRequiredAttachments, userRequiredInformations])

    // Themes
    const boxHeaderStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            fontSize: "0.75rem",
            padding: "0.25rem 0.5rem"
        },
        ['@media(max-width:300px)']: {
            fontSize: "0.5rem",
            padding: "0.125rem 0.25rem"
        },
        width: "100%",
        display: "block",
        padding: "20px 28px",
        color: "#555555",
        backgroundColor: "#ffffff",
        marginBottom: "8px",
        borderRadius: "10px",
        borderStyle: "solid",
        borderColor: interfaceTheme ? interfaceTheme.primaryColor : "#64067f",
        borderWidth: "10px 0px 0px 0px",
        boxSizing: "border-box",
    }
    const boxHeaderTypographyStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            fontSize: "0.875rem",
            padding: "0.375rem 0.625rem"
        },
        ['@media(max-width:300px)']: {
            fontSize: "0.5rem",
            padding: "0.125rem 0.25rem"
        },
        fontSize: "1.625rem",
        color: "#555555",
    }
    const requiredInformationBoxTheme: SxProps<Theme> = {
        ['@media(max-width:700px)']: {
            width: "100%"
        },
        width: "700px"
    }
    const buttonDisabledTheme: SxProps<Theme> = {
        "&.Mui-disabled": {
            bgcolor: "#c8c8c8",
        }
    }
    const revokedAlertTheme: SxProps<Theme> = {
        ['@media(max-width:700px)']: {
            width: "100%"
        },
        width: "700px"
    }

    // submit all the required informations
    async function submitRequiredInformations() {
        setLoadingButtonSubmitRequiredInformations(true)
        // check if source is defined
        if (!props.source) {
            throw new Error("An unexpected error occur while trying to submitRequiredInformations. 'props.source' state prop is undefined");
        }

        try {
            // when the user click in the send form button
            // we verified if there is a required information in the array
            // because to maintain a logic UX we have to enable the button
            // even if there is no required information
            // because the attachments are presented to the user in the same interface
            // this feature may cause a confusion if the button is disasociated with the attachments
            // so we enable the "send form" button even if there is no need for that
            if (userRequiredInformations.length > 0) {
                await ContractsService.uploadUserInformation(props.source.id, {
                    data: userRequiredInformations
                });
            }
            notification(show({
                type: 'success',
                message: 'Todas as informações foram enviadas com sucesso'
            }))
        } catch (e) {
            const err = new ErrorWrapper(e)
            notification(show({
                type: 'error',
                message: (err.httpStatus != 200) ? `Não foi possível enviar as informações requeridas.` : undefined
            }))
        }
        finally {
            // reload the page as we can fetch the new state of the contract
            window.location.reload();
        }
        setLoadingButtonSubmitRequiredInformations(false)
    }

    /**
     * Callback that syncs that data in each required information field and this form state data
     * @param userInformations 
     * @param value 
     * @param isValid 
     */
    function onRequiredInformationChange(userInformations: NormalizedUserRequiredInformation, value: string, isValid: boolean) {
        const copyOfUserRequiredInformation = [...userRequiredInformations];
        const updatedUserRequiredInformation = copyOfUserRequiredInformation.find(c => c.requiredInformationName === userInformations.requiredInformationName);

        if (!updatedUserRequiredInformation) {
            throw new Error("Could not find updatedUserRequiredInformation by name");
        }
        else {
            updatedUserRequiredInformation.value = value;
            updatedUserRequiredInformation.isValid = isValid;
            setUserRequiredInformations(copyOfUserRequiredInformation);
        }
    }

    /**
     * Callback that syncs the data in each attachment field and this form state data
     * @param attachment 
     * @param isValid 
     */
    function onAttachmentUploadedChange(attachment: NormalizedAttachment, isValid: boolean) {
        const copyOfAttachments = [...userRequiredAttachments];
        const updatedAttachment = copyOfAttachments.find(a => a.specification.name === attachment.specification.name);

        if (!updatedAttachment) {
            throw new Error("Could not find attachment by name");
        }
        else {
            updatedAttachment.isValid = isValid;
            setUserRequiredAttachments(copyOfAttachments);
        }
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyItems: "center", flexDirection: "column", clear: "both" }}>
            {
                isRevoked
                    ?
                    <Box sx={revokedAlertTheme}>
                        <Alert severity="error" sx={{ margin: "16px 0" }}>Contrato não poderá prosseguir para assinatura pois foi revogado por uma das partes.</Alert>
                    </Box>
                    :
                    <></>
            }
            <Box>
                <Box sx={{ bgcolor: "#fff", minHeight: "0", borderRadius: "12px", boxShadow: 3 }}>
                    <Box sx={requiredInformationBoxTheme}>
                        <Box sx={boxHeaderStyle}>
                            <Typography sx={boxHeaderTypographyStyle}>
                                Formulário de Adição de Informações Requeridas e Anexos do Contrato: </Typography>
                            <Typography sx={[
                                boxHeaderTypographyStyle,
                                {
                                    fontSize: "26px",
                                    color: interfaceTheme ? interfaceTheme.primaryColor : "#64067f",
                                    fontWeight: 600,
                                    mb: 1
                                }
                            ]}>
                                {props.source?.name}
                            </Typography>
                            {
                                verifiedCPRevokedThisContract
                                    ?
                                    <Button onClick={() => setRemoveRevokeDialogIsOpen(true)} sx={{ m: 0, p: 0, color: "#D32F2F" }}>Você revogou esse contrato. Clique aqui para reverter a revogação</Button>
                                    :
                                    <Button onClick={() => setRevokeDialogIsOpen(true)} sx={{ m: 0, p: 0, color: "#6fa8ff" }}>Revogar contrato</Button>
                            }
                        </Box>
                        {
                            allFieldsHasBeenFilledInForThisContractPartie
                                ?
                                <Box sx={{ width: "100%", backgroundColor: "green", mb: 1 }}>
                                    <Typography sx={{ padding: "12px 28px", color: "#fff", fontSize: "14px" }}>
                                        Você já preencheu todas as informações necessárias. Aguarde os outros participantes.
                                    </Typography>
                                </Box>
                                :
                                <></>
                        }
                        <Divider />

                        {/* Maps the contract partie Required Informations */}
                        <Box key={verifiedContractParty ? verifiedContractParty?.contractPartyIdentificationType : `Issuer ${props.user?.account.uuid}`}>
                            {
                                userRequiredInformations
                                    .sort((a, b) => a.requiredInformationName.localeCompare(b.requiredInformationName))
                                    .map((ri) => (
                                        <RequiredInformationComponent key={ri.requiredInformationName} requiredInformation={ri} onChange={(value, isValid) => onRequiredInformationChange(ri, value, isValid)} />
                                    ))
                            }
                        </Box>

                        {/* Maps the contract partie Required Attachments */}
                        <Box key={requiredContractPartiesAttachments.length}>
                            {
                                userRequiredAttachments
                                    .sort((a, b) => a.specification.name.localeCompare(b.specification.name))
                                    .map((ra) => (
                                        <RequiredAttachment key={ra.specification.name} attachment={ra} contract={contract} onChange={onAttachmentUploadedChange} />
                                    ))
                            }
                        </Box>
                    </Box>
                </Box >

                {/* Button for requiredInformations that only render if the contract partie has requiredInformations */}
                <Box>
                    {
                        <Tooltip title={(!allFieldsAreFilled) ? "Existem informações ou anexos inválidos e/ou não preenchidos" : null}>
                            <Box sx={{ width: "220px", height: "40px", position: "fixed", right: 50, bottom: 50, }}>
                                <Fab
                                    onClick={() => submitRequiredInformations()}
                                    variant="extended"
                                    color="primary"
                                    sx={[buttonDisabledTheme,
                                        {
                                            bgcolor: !allFieldsAreFilled ? "#c8c8c8" : interfaceTheme ? interfaceTheme.primaryColor : "#64067f",
                                            '&:hover': { bgcolor: interfaceTheme ? interfaceTheme.secondaryColor : "#BF87C4" }
                                        }]}
                                    disabled={!allFieldsAreFilled}
                                >
                                    {
                                        (!loadingButtonSubmitRequiredInformations)
                                            ?
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <SendIcon sx={{ mr: "8px" }} />
                                                {
                                                    allFieldsHasBeenFilledInForThisContractPartie
                                                        ?
                                                        "Atualizar Formulário"
                                                        :
                                                        "Enviar Formulário"
                                                }
                                            </Box>
                                            :
                                            <CircularProgress size={25} sx={{ color: "#fff" }} />
                                    }
                                </Fab>
                            </Box>
                        </Tooltip>
                    }
                </Box>
            </Box >

            <Dialog open={revokeDialogIsOpen} onClose={() => setRevokeDialogIsOpen(false)}>
                <DialogTitle><b>Revogar contrato?</b></DialogTitle>
                <DialogContent>
                    <Typography>
                        Qualquer parte do contrato pode escolher revogá-lo. Ao revogar um contrato, o sistema impossibilitará que ele avance para a fase de assinatura das partes.
                        <p>Você pode reverter sua solicitação de revogação clicando novamente no link.</p>
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "space-between", p: 2, pt: 0 }}>
                    <Button variant='contained' onClick={() => setRevokeDialogIsOpen(false)} >Cancelar</Button>
                    {
                        loadingRevokeButton
                            ?
                            <Button disabled variant='contained'><CircularProgress size={24} /></Button>
                            :
                            <Button variant='contained' onClick={() => revokeContract()}>Revogar</Button>
                    }
                </DialogActions>
            </Dialog>

            <Dialog open={removeRevokeDialogIsOpen} onClose={() => setRemoveRevokeDialogIsOpen(false)}>
                <DialogTitle><b>Reverter revogação do contrato?</b></DialogTitle>
                <DialogContent>
                    <Typography>
                        Ao reverter sua solicitação de revogação, será possível avançar para a fase de assinatura do contrato.
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "space-between", p: 2, pt: 0 }}>
                    <Button variant='contained' onClick={() => setRemoveRevokeDialogIsOpen(false)} >Cancelar</Button>
                    {
                        loadingRevokeButton
                            ?
                            <Button disabled variant='contained'><CircularProgress size={24} /></Button>
                            :
                            <Button variant='contained' onClick={() => removeRevokeContract()}>Reverter revogação</Button>
                    }
                </DialogActions>
            </Dialog>
            <RenewAccessDialog open={showRenewAccessDialog} sessionRenewed={revokeContract} />
            <RenewAccessDialog open={showRenewAccessDialogRemove} sessionRenewed={removeRevokeContract} />
        </Box >
    )
}

export default RequiredInformationTab;