import { useContext, useEffect, useState } from "react";
import { Box, LinearProgress, IconButton, Menu, MenuItem, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from "@mui/material";
import { AddCircle, Download, MoreVert } from "@mui/icons-material";
import { DataGrid, GridColumns, GridRenderCellParams, ptBR } from '@mui/x-data-grid';
import AuthenticationContext from "../../contexts/authentication";
import { RolesContext } from "../../contexts/roles";
import { AccountRoles } from "../../models/user";
import TeamsService from "../../services/teams";
import { tableStyle } from "../../theme"
import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../utils/ErrorWrapper";
import { Pagination } from "../../models/pagination";
import CreateTeams from "./Create";
import { OrganizationTeam } from "../../models/teams";
import TeamMembers from "./Members";
import ImportTeams from "./Import";
import StlInterceptor from "../../contexts/stl";
import RenewAccessDialog from "../authentication/RenewAccessDialog";
import { SecurityTierLevels } from "../../store/stl";
import OrganizationContractsQuotaService from "../../services/organization-quota";
import PayWallDialog from "../../components/PayWallDialog";

interface TeamsProps extends React.ComponentProps<any> {
    currentSubusersCount: Number
    maxSubuser: Number
    updateQuota: () => void
}

const Teams = (props: TeamsProps): JSX.Element => {

    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Times"
    }, []);

    //Context props
    const auth = useContext(AuthenticationContext);
    const userRoles = useContext(RolesContext);
    const stl = useContext(StlInterceptor);
    const notification = useAppDispatch();

    //States
    const [openCreateTeamDrawer, setOpenCreateTeamDrawer] = useState(false)
    const [openTeamMembersDrawer, setOpenTeamMembersDrawer] = useState(false)
    const [openImportTeamDrawer, setOpenImportTeamDrawer] = useState(false)
    const [openConfirmDeletionDialog, setOpenConfirmDeletionDialog] = useState(false)
    const [openRenewAccessDialog, setOpenRenewAccessDialog] = useState(false);
    
    const [loading, setLoading] = useState(false)

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [teams, setTeams] = useState<OrganizationTeam[]>([]);
    const [pagination, setPagination] = useState<Pagination>(null!);

    const [openPayWallDialog, setOpenPayWallDialog] = useState(false)


    //Fetch teams pagination when component loads
    useEffect(() => { fetchPagination() }, [])

    //Fetch teams when page or limit value changes 
    useEffect(() => { fetchTeams() }, [page, limit])

    /**
     * Fetch pagination data of teams
     */
    const fetchPagination = () => {
        TeamsService.pagination()
            .then(pag => setPagination(pag))
            .catch(e =>
                notification(show({
                    type: 'error',
                    message: `Ocorreu um erro: ${new ErrorWrapper(e).message}`
                })))
    }

    /**
     * Fetch organization teams using pagination
     */
    const fetchTeams = () => {
        setLoading(true)
        TeamsService.fetch(page)
            .then(resp => setTeams(resp ? resp : []))
            .catch(e =>
                notification(show({
                    type: 'error',
                    message: `Ocorreu um erro: ${new ErrorWrapper(e).message}`
                })))
            .finally(() => setLoading(false))
    }

    //Utils - normalize date values
    const normalize = (milliseconds: string | number | Date) => { return new Date(milliseconds).toLocaleDateString() };

    //Store the anchor HTML element associated with the challenge context menu
    const [teamsMenuAnchor, setTeamsMenuAnchor] = useState<HTMLElement | null>(null);
    const [selectedTeam, setSelectedTeam] = useState<OrganizationTeam | null>(null);

    //Teams grid column def
    const teamsColumnDef: GridColumns = [
        { field: 'name', headerName: 'Nome', sortable: false, flex: 1, headerAlign: 'center', align: 'center' },
        {
            field: 'creationDate', headerName: 'Data de criação', sortable: false, flex: 1, headerAlign: 'center', align: 'center',
            renderCell: (params: GridRenderCellParams) => { return normalize(params.row.creationDate) }
        },
        {
            field: '', headerName: '', align: 'center', sortable: false,
            renderCell: (params: GridRenderCellParams) => {

                const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    setTeamsMenuAnchor(event.currentTarget);
                    setSelectedTeam(params.row);
                };

                return (
                    <Box>
                        <IconButton
                            onClick={onClick}
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={teamsMenuAnchor ? 'true' : undefined}
                        >
                            <MoreVert color='action' />
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    /**
     * Return default props used in pagination
     * @param pagination 
     * @returns 
     */
    function paginationProps(pagination: Pagination | null): Object {
        //Return empty object if pagination is null
        if (pagination === null) return {}
        return {
            rowCount: pagination?.totalRecords,
            pageSize: pagination?.recordsPerPage,
            paginationMode: 'server',
            page: page - 1,
            onPageChange: (page: number) => setPage(page + 1)
        }
    }

    /**
     * Delete an team
     */
    async function deleteTeam() {
        // check if there is a selected team
        if (!selectedTeam) {
            throw new Error("Error while deleting an team: There is no selectedTeam set on react state")
        }

        // call the API deletion endpoint and update the interface
        try {
            setLoading(true);
            const deletedTeam = await TeamsService.deleteTeam(selectedTeam.guid);
            
            // update the teams list removing the deleted item
            setTeams(teams.filter(t => t.guid !== deletedTeam.guid));
            setSelectedTeam(null);
            setOpenConfirmDeletionDialog(false);

            // success
            notification(show({
                message : "Time deletado com sucesso",
                type : "success"
            }));
        } catch (e) {
            // error
            notification(show({
                message : "Ocorreu um erro ao deletar time",
                type : "error"
            }));
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    function handleClosePayWallDialog() {
        setOpenPayWallDialog(false);
    }

    function handleOpenPayWallDialog() {
        setOpenPayWallDialog(true)
    }

    return (
        <Box px={2} py={2} className="container">
            <Typography variant='h6' color='primary.main'>Times de sua <strong>organização</strong></Typography>
            <Typography variant='subtitle2'> Aqui você pode visualizar e gerenciar os times de sua organização</Typography>

            <Menu
                anchorEl={teamsMenuAnchor}
                open={!!teamsMenuAnchor}
                onClose={() => setTeamsMenuAnchor(null)}
            >
                <MenuItem onClick={() => { setOpenTeamMembersDrawer(true); setTeamsMenuAnchor(null) }} >
                    Ver membros
                </MenuItem>
                <MenuItem onClick={() => { 
                    stl.require(auth, SecurityTierLevels.MaxLevel)
                        .then(() => {
                            setOpenRenewAccessDialog(false);
                            setOpenConfirmDeletionDialog(true);
                        })
                        .catch(() => setOpenRenewAccessDialog(true)); 
                        setTeamsMenuAnchor(null) 
                        }} 
                >
                    Deletar time
                </MenuItem>
            </Menu>

            <Box mt={5}>
                <DataGrid
                    components={{
                        LoadingOverlay: LinearProgress,
                        NoRowsOverlay: () => <Typography mt={3} textAlign='center' color='text.secondary'> Nenhuma informação</Typography>
                    }}
                    loading={loading}
                    sx={{...tableStyle, minHeight: '80vh'}}
                    disableColumnMenu
                    disableColumnFilter
                    rowsPerPageOptions={[20]}
                    getRowId={(team) => team.guid}
                    rows={teams}
                    columns={teamsColumnDef}
                    isRowSelectable={() => false}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    {...paginationProps(pagination)}
                />
            </Box>
            {
                !userRoles.assertIsOrganizationOrAdministratorOr(auth, AccountRoles.USER_AND_TEAMS_MANAGER) ? null :
                    <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }} >
                        <SpeedDial ariaLabel='sp'  icon={<SpeedDialIcon />} sx={{ position: 'absolute', bottom: 32, right: 16 }}>
                            <SpeedDialAction
                                icon={<AddCircle color='primary' />}
                                tooltipTitle='Criar' tooltipOpen
                                onClick={() => setOpenCreateTeamDrawer(true)}
                            />
                            {(props.currentSubusersCount >= props.maxSubusers)? 
                                <SpeedDialAction
                                    icon={<Download color='primary' />}
                                    tooltipTitle='Importar' tooltipOpen
                                    onClick={handleOpenPayWallDialog}
                                />
                            :
                                <SpeedDialAction
                                icon={<Download color='primary' />}
                                tooltipTitle='Importar' tooltipOpen
                                onClick={() => setOpenImportTeamDrawer(true)}
                                />
                            }
                        </SpeedDial>
                    </Box>
            }

            {/* Drawers */}
            <TeamMembers open={openTeamMembersDrawer} close={() => setOpenTeamMembersDrawer(false)} onSuccess={() => { }} team={selectedTeam} updateQuota={props.updateQuota}/>

            <CreateTeams open={openCreateTeamDrawer} close={() => setOpenCreateTeamDrawer(false)} totalRecords={pagination?.totalRecords}
                onSuccess={(newTeam: OrganizationTeam) => setTeams([...teams, newTeam])} />

            <ImportTeams open={openImportTeamDrawer} close={() => setOpenImportTeamDrawer(false)}
                onSuccess={(importedTeam: OrganizationTeam) => setTeams([...teams, importedTeam])} 
                currentSubusers={props.currentSubusersCount} maxSubusers={props.maxSubuser} 
                updateQuota={props.updateQuota}
            />

            {/** Dialogs */}
            <RenewAccessDialog sessionRenewed={() => setOpenConfirmDeletionDialog(true)} open={openRenewAccessDialog} onClose={() => setOpenRenewAccessDialog(false)} />

            {/** Delete team confirmation dialog */}
            <Dialog
                open={openConfirmDeletionDialog}
                onClose={() => setOpenConfirmDeletionDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirmar deleção de time"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Você deseja realmente deletar o grupo <b>{selectedTeam?.name}</b>? 
                        Fazendo isso toda referência a esse grupo (como em configurações de participações de contratos) deverão
                        ser modificadas.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setOpenConfirmDeletionDialog(false)}>Cancelar</Button>
                <Button onClick={deleteTeam} autoFocus disabled={loading}>
                    Deletar 
                    {(loading) ? <CircularProgress /> : <></>}
                </Button>
                </DialogActions>
            </Dialog>
            <PayWallDialog open={openPayWallDialog} onClose={handleClosePayWallDialog} quotaName="Sub Usuários" productName="Usuários da Organização" type="limiter" />

        </Box>
    )
}

export default Teams;
