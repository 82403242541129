import { PropertyFilter } from "../models/analytics";
import { NotificationModel } from "../models/notifications";
import { Pagination } from "../models/pagination";
import contractsAPI from "./contracts-api"

const NotificationsService = {
    // Fetch all notifications from the notifications API and if not result is found
    // return an empty body with 204 status code, otherwise it will return 200.
    fetchNotificationsPaginationMetadata: async (
        limit: number = 20,
        startDate: Date | undefined = undefined,
        endDate: Date | undefined = undefined,
        filter: PropertyFilter[] | undefined = undefined): Promise<Pagination> => {

        //Send Request to notifications API
        let url = `/rest/v1/organizations/my/notifications/pagination?limit=${limit}`;
        if (startDate) url += `&startDate=${startDate.getTime()}`;
        if (endDate) url += `&endDate=${endDate.getTime()}`;
        if (filter) url += `&filter=${encodeURI(JSON.stringify(filter))}`

        return await (await contractsAPI.get(url)).data;
    },

    // Fetch all notifications from the notifications API and if not result is found
    // return an empty body with 204 status code, otherwise it will return 200.
    fetchNotifications: async (
        page: number, 
        limit: number = 20,
        startDate: Date | undefined = undefined,
        endDate: Date | undefined = undefined,
        filter: PropertyFilter[] | undefined = undefined): Promise<NotificationModel[]> => {
       
        //Send Request to notifications API
        let url = `/rest/v1/organizations/my/notifications?limit=${limit}&page=${page}`;
        if (startDate) url += `&startDate=${startDate.getTime()}`;
        if (endDate) url += `&endDate=${endDate.getTime()}`;
        if (filter) url += `&filter=${encodeURI(JSON.stringify(filter))}`

        const response = await contractsAPI.get(url);

        //If the response is empty, return an empty array
        if (response.status === 204) return [];

        const notifications = await (response).data as NotificationModel[];
        return notifications;
    },
}

export default NotificationsService;