import { useEffect, useContext, useState } from "react";
import { Box, Button, CircularProgress, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { RouteParamContext, ServiceResponses } from "../../contexts/route-param";
import { LoadingButton } from "../../components/LoadingButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthenticationContext from "../../contexts/authentication";

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../utils/ErrorWrapper";
import UserAccountService from "../../services/user-account";
import { AccountTypes } from "../../models/user";
import TeamsService from "../../services/teams";
import { OrganizationTeam } from "../../models/teams";
import authentication from "../../store/authentication";

enum Actions {
    LOGIN = 'login',
    CREATE_ACCOUNT = 'create-account'
}

const Login = (): JSX.Element => {

    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Autenticação"
    }, []);

    const isMobile = useMediaQuery("(max-width:800px)")
    const showKlausLogo = useMediaQuery("(max-width:500px)")

    //Context props
    const auth = useContext(AuthenticationContext)
    const routeParam = useContext(RouteParamContext)
    const navigate = useNavigate();
    const notification = useAppDispatch();

    // React Router Search parameters
    const [searchParams, setSearchParams] = useSearchParams();

    //States
    const [loading, setLoading] = useState(false);
    // const [loginLoading, setLoginLoading] = useState(false)
    const [accountsToken, setAccountsToken] = useState<string | null>(null);

    const [searchResult, setSearchResult] = useState<string | null>(null)
 
    //State that indicates if user can create account in the platform
    const [canCreateAccount, setCanCreateAccount] = useState(true)

    //Redirect the user to the home page if it is already authenticated
    useEffect(() => {
        if (auth.isAuthenticated()) {
            //srt = search resource type
            const srt = searchParams.get("srt");
            //srid = search resource id
            const srid = searchParams.get("srid");

            //Navigate to home and notify user
            if (srt && srid) {
                navigate(`/?srid=${srid}&srt=${srt}`, { replace: true })
                notification(show({ message: `Bem vindo!`, type: 'success' }))
            } else {
                navigate('/', { replace: true })
                notification(show({ message: `Bem vindo!`, type: 'success' }))
            }
        }
    }, [])

    /**
     * Redirect to accounts webapp to authenticate the requester
     * @param action 
     */
    const redirectToAccounts = (action: Actions) => {
        setLoading(true);
        window.location.href = `${process.env.REACT_APP_AUTH_WEBAPP}/${action}?service=contracts`
    }

    /**
     * Get the query param when application is loaded
     */
    useEffect(() => { getQueryParam() }, [])
    const getQueryParam = () => {
        const token = routeParam.get(ServiceResponses.TOKEN);
        
        // Delete the params from the route
        routeParam.delete([ServiceResponses.TOKEN, ServiceResponses.ACCOUNT_TYPE]);

        //If query param has the token store it 
        if (!token) setAccountsToken(null);
        setAccountsToken(token)
    }

    /**
     * Authenticate the user as soon as the application gets the token as a URL query param
     */
    useEffect(() => { authenticate(); }, [accountsToken])
    const authenticate = async () => {
        setLoading(true)

        try {
            if (!accountsToken) return;
            
            //srt = search resource type
            const srt = searchParams.get("srt");
            //srid = search resource id
            const srid = searchParams.get("srid");

            //setLoginLoading(true)
            //Then authenticate the user if the received token
            await auth.authenticationWithAccountsToken(accountsToken)

            //Fetch auth user teams
            await fetchAuthUserTeams()

            //Navigate to home and notify user
            if (srt !== null && srid !== null) {
                navigate(`/?srid=${srid}&srt=${srt}`, { replace: true })
                notification(show({ message: `Bem vindo!`, type: 'success' }))
            } else {
                navigate('/', { replace: true })
                notification(show({ message: `Bem vindo!`, type: 'success' }))
            }

        }
        catch (e) {
            const err = new ErrorWrapper(e);
            //If 403 error, try to create account
            if (err.httpStatus === 403) return createAccount();
            notification(show({
                type: 'error',
                message: (err.httpStatus === 404) ? `Conta não encontrada` : err.message
            }))
        }
        finally { 
            setLoading(false) 
            //setLoginLoading(false)
        }
    }

    /**
     * If user has a account on the Accounts plataform but not on Contracts, create one
     * @returns 
     */
    const createAccount = async () => {
        setLoading(true)
        try {
            //Return if there is no token
            if (!accountsToken) return;

            //Get the authenticated user account type
            const accountType = routeParam.get(ServiceResponses.ACCOUNT_TYPE);

            //Return if it is Customer Subuser because this type of account has to be imported into the platform
            if (accountType === AccountTypes.CustomerSubuser)
                return setCanCreateAccount(false)

            //Create users account
            await UserAccountService.create(accountsToken);

            //setLoginLoading(true)
            //Then authenticate it
            await auth.authenticationWithAccountsToken(accountsToken)

            //Navigate to home and notify user
            navigate('/', { replace: true })
            notification(show({ message: `Bem vindo!`, type: 'success' }))
        }
        catch (e) {
            notification(show({
                type: 'error',
                message: `Ocorreu um erro: ${new ErrorWrapper(e).message}`
            }))
        }
        finally { 
            setLoading(false) 
            //setLoginLoading(false)
        }
    }

    const fetchAuthUserTeams = async () => {
        const authId = auth.user().account.uuid;
        const myTeams: string[] = []
        try {
            //Fetch all organization teams
            const teams: OrganizationTeam[] | null = await TeamsService.fetch(1);
            if (!teams) return;

            //Loop through each team to fetch members
            for (const team in teams) {
                //Fetch team members
                const membros = await TeamsService.fetchMembers(teams[team].guid, 1)
                if (!membros) return;

                //Check if auth user is a member
                const findMe = membros.find(m => m.uuid === authId)

                //Push team guid into array of auth user teams
                if (findMe) myTeams.push(teams[team].guid)
            }
        }
        catch (e) {
            console.error(e)
        }
        finally {
            //Save auth user teams in localstorage 
            if (myTeams.length > 0) authentication.setAuthUserTeams(myTeams)
        }
    }

    //Style
    const bSty = { width: 'fit-content', alignSelf: 'center' }
    const cSty = { maxWidth: 400, width: "100%", minWidth: 300, m: 'auto', px: "75px", borderRadius: 1.3, display: 'flex', flexDirection: 'column' }

    return (
        <>  
            {
                !showKlausLogo
                ?
                    <img src="/images/assets/logo_klaus_white.svg" alt="" style={{width: "104px", position: "absolute", bottom: "24px", left: "24px"}} />
                :
                    <img style={{width: "104px", position: "absolute", bottom: "24px", left: "24px"}} src="http://klausfiscal.com.br/images/klaus-logo.png" />
            }
            {
                isMobile
                ?
                    <Container sx={{
                            width: "100vw", 
                            height: "100vh", 
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "center",
                            background: "linear-gradient(125deg, rgba(41,41,41,1) 50%, rgba(100,6,127,1) 75%, rgba(115,66,234,1) 100%)",
                            '@media(max-width:500px)': {
                                p: 0
                            }
                        }}
                    >
                        <Box sx={{
                                width: "100vw", 
                                height: "100vh", 
                                display: "flex", 
                                alignItems: "center", 
                                justifyContent: "center", 
                                flexDirection: "column", 
                                backgroundColor: "#FFF", 
                                '@media(min-width:500px)': {
                                    maxWidth: "600px",
                                    maxHeight: "400px",
                                    borderRadius: "5px"
                                }
                            }}
                        >
                            <Box sx={{p: 3}}>
                                <Box sx={{display: "flex", flexDirection: "column"}}>
                                    <img src="/images/cartorize-logo.svg" alt="" style={{maxWidth: "300px", width: "60%", marginBottom: "2rem", alignSelf: "center"}} />
                                </Box>
                                <Typography variant="subtitle1" sx={{fontFamily: "Poppins, Noto Sans, sans-serif"}}>Bem-vindo(a)!</Typography>
                                {
                                    !loading ?
                                        <Box>
                                            <Typography variant="subtitle2" sx={{mt: 3, mb: 6}}>
                                                É preciso que você entre com sua conta no Klaus Accounts para prosseguir!
                                            </Typography>
                                            <LoadingButton 
                                                variant='contained'
                                                sx={{py: 1.5}}
                                                loading={loading} 
                                                fullWidth 
                                                onClick={() => redirectToAccounts(Actions.LOGIN)}
                                            >
                                                <Typography sx={{fontWeight: 800, color: "#FFF", fontSize: "16px"}}>Entrar com o Klaus Accounts</Typography>
                                            </LoadingButton>
                                        </Box>
                                        :
                                        <Box>
                                            <Typography variant='subtitle1' mb={3}>Por favor aguarde</Typography>
                                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                <CircularProgress  />
                                            </Box>
                                            <Typography variant='subtitle1' mt={3}>Estamos conectando você à plataforma Cartorizi</Typography>
                                        </Box>
                                }
                            </Box>
                        </Box>
                    </Container>
                :
                    <Grid container sx={{width: "100vw", height: "100vh", overflow: "hidden"}}>
                        <Grid item xs={7} md={8}
                            sx={{
                                background: "linear-gradient(125deg, rgba(41,41,41,1) 50%, rgba(100,6,127,1) 75%, rgba(115,66,234,1) 100%)"
                            }}
                        >
                            <Box sx={{width: "100%", height: "100%", display: "flex", justifyContent: "end", alignItems: "center"}}>
                                <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-end", mr: 6}}>
                                    <img src="/images/white_dots.svg" alt="" style={{maxWidth: "72px", width: "100%", marginBottom: "2rem"}} />
                                    <img src="/images/logo_cartorizi_white.svg" alt="" style={{maxWidth: "608px", width: "60%"}} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs={5} md={4} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Container sx={{...cSty, }}>
                                <Box>
                                    <Typography variant="h5" sx={{fontFamily: "Poppins, Noto Sans, sans-serif"}}>Bem-vindo(a)!</Typography>
                                    {
                                        !loading ?
                                            <Box>
                                                <Typography variant="subtitle1" sx={{mt: 3, mb: 6}}>
                                                    É preciso que você entre com sua conta no Klaus Accounts para prosseguir!
                                                </Typography>
                                                <LoadingButton 
                                                    variant='contained'
                                                    sx={{py: 2}}
                                                    loading={loading} 
                                                    fullWidth 
                                                    onClick={() => redirectToAccounts(Actions.LOGIN)}
                                                >
                                                    <Typography sx={{fontWeight: 800, color: "#FFF", fontSize: "16px"}}>Entrar com o Klaus Accounts</Typography>
                                                </LoadingButton>
                                            </Box>
                                            :
                                            <Box>
                                                <Typography variant='subtitle1' mb={3}>Por favor aguarde</Typography>
                                                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                    <CircularProgress  />
                                                </Box>
                                                <Typography variant='subtitle1' mt={3}>Estamos conectando você à plataforma Cartorizi</Typography>
                                            </Box>
                                    }
                                </Box>
                            </Container>
                        </Grid>
                    </Grid>
            }
        </>
    );
}

export default Login;