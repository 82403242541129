import { Autocomplete, Badge, Box, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, TextField, Typography, styled } from "@mui/material";
import TagsService, { TagsModel } from "../services/tags";
import { useEffect, useState } from "react";

interface TagsSearchProps extends React.ComponentProps<any> {
    onTagSelected: (tags: TagsModel[]) => void;
    onAndAndOrChange: (value: string) => void;
    andAndOr: string;
    selectedTags: TagsModel[];
}

const TagsSearch = (props: TagsSearchProps): JSX.Element => {

    // Destructure props object
    const { onTagSelected, selectedTags: tags1, onAndAndOrChange, andAndOr } = props;

    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState<TagsModel[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [selectedTags, setSelectedTags] = useState<TagsModel[]>(tags1)
    const [selectedOptionAndAndOr, setSelectedOptionAndAndOr] = useState(andAndOr);

    const handleChangeAndAndOr = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOptionAndAndOr(event.target.value);
        onAndAndOrChange(event.target.value);
    };

    async function fetchTags() {
        try {
            /**
             * This service is using 1 because he brings the most hits in the contract
             */
            const fetchedTags = await TagsService.fetchTagsUsingPagination(1);
            setTags(fetchedTags);
        }
        catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        fetchTags()
    }, [])

    useEffect(() => {
        onTagSelected(selectedTags);
    }, [selectedTags, onTagSelected])

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(() => {
            searchTagsByText(searchTerm);
        }, 500);

        setTimeoutId(newTimeoutId);
    }, [searchTerm]);

    useEffect(() => {
        handleSelectedTagsBasedInAndAndOr();
    }, [selectedOptionAndAndOr])

    function handleSelectedTagsBasedInAndAndOr() {

    }

    async function searchTagsByText(text: string) {
        try {
            const searchedTags: TagsModel[] = [];

            if (text.length > 0) {
                (await TagsService.searchTagsByName(text)).forEach( t => searchedTags.push({ tagName: t.data.tagName, hits: t.data.hits }) );
            } else {
                (await TagsService.fetchTagsUsingPagination(1)).forEach( t => searchedTags.push({ tagName: t.tagName, hits: t.hits }) );
            }

            setTags(searchedTags);
        } catch (error) {
            console.error('Não foi possível buscar as tags pelo texto', error);
        }
    }

    return (
        <Box sx={{ p: 2 }}>
            <Typography sx={{ fontWeight: 600, mb: 2 }}>Filtrar por tag do contrato</Typography>
            <RadioGroup
                name="options"
                value={selectedOptionAndAndOr}
                onChange={handleChangeAndAndOr}
            >
                <Box sx={{ display: 'flex' }}>
                    <FormControlLabel value="E" control={<Radio disabled={selectedTags.length <= 1} />} label="E" />
                    <FormControlLabel value="OU" control={<Radio disabled={selectedTags.length <= 1} />} label="OU" />
                </Box>
            </RadioGroup>
            <Autocomplete
                multiple
                disableCloseOnSelect
                options={tags}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                noOptionsText="Sem resultados"
                value={selectedTags}
                onChange={(e, n) => {
                    setSelectedTags(n)

                }}
                sx={{ overflow: "hidden", textOverflow: "ellipsis", minWidth: "160px", border: "none" }}
                getOptionLabel={(option) => option.tagName}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={selectedTags.length > 0 ? "" : "Filtre por tag"}
                        variant="standard"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                )}
                renderOption={(props, option) => {
                    const tag = option as TagsModel;
                    return (
                        <Box component="li" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} {...props}>
                            <Typography sx={{ margin: 1 }}>
                                {tag.tagName}
                            </Typography>
                            <Badge badgeContent={tag.hits} color="primary" sx={{ ml: 2 }} />
                        </Box>
                    )
                }}
            />
        </Box>
    )
}

export default TagsSearch;
