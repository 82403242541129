import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import Subusers from "../../subusers/Subusers";
import Teams from "../../team/Teams";
import { Box, Container } from "@mui/material";

interface UserAndTeamsProps extends React.ComponentProps<any> {
    currentSubusersCount: Number,
    maxSubuser: Number,
    updateUsersQuota: (addedSubusers: number) => void
    updateQuota: () => void
}

const UsersAndTeams = (props: UserAndTeamsProps) : JSX.Element=> {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <Container>
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Usuários" />
                <Tab label="Times" />
            </Tabs>
            <Box>
                {activeTab === 0 && <Subusers currentSubusersCount={props.currentSubusersCount} maxSubuser={props.maxSubuser} updateUsersQuota={props.updateUsersQuota}/>}
                {activeTab === 1 && <Teams currentSubusersCount={props.currentSubusersCount} maxSubuser={props.maxSubuser} updateQuota={props.updateQuota} />}
            </Box>
        </Container>
    );
};

export default UsersAndTeams;
