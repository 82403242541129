import { AttachFile, AttachMoney, DoneAll, Download, FileDownload, Loop, Mail, PictureAsPdf, Print, VerifiedUser, Warning, Contacts as ContactsIcon, Share } from "@mui/icons-material";
import { TabContext, TabList, TabPanel, TimelineOppositeContent } from "@mui/lab";
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Alert, Avatar, Box, Button, Chip, CircularProgress, Container, DialogContent, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Tab, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useContext, useState, useEffect } from "react";
import AuthenticationContext from "../../contexts/authentication";
import { RolesContext } from "../../contexts/roles";
import { GenerateContactDTO } from "../../models/contacts";
import { ContractPartyActivityModel, DigitalContractPartyActivityModel } from "../../models/contract-parties-activities";
import { ContractParty, DigitalContractBilling, IdentifiedByEmailContractParty, IssuedContractDocumentType, IssuedDigitalContract, PlatformUserContractParty, UserInformation } from "../../models/contracts";
import { GeneratePDF, downloadOriginalFile, downloadTheWatermarkAndSignatureContract } from "../../models/generate-contract-blobs";
import { AccountRoles, UserAccount } from "../../models/user";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../redux/hooks";
import ContactsService from "../../services/contacts";
import ContractPartiesActivitiesService from "../../services/contract-party-activity";
import ContractsService from "../../services/contracts";
import OrganizationService from "../../services/organization";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { CurrencyValue, SupportedCurrencies } from "../../utils/currency";
import ShareContractDialog from "./ShareContractDialog";
import EncryptedPDFDialog from "../EncryptedPDFDialog";


interface IssuedContractDialogContentProps extends React.ComponentProps<any> {
    source: IssuedDigitalContract
}

interface OrganizationMemberMap {
    [uuid: string]: UserAccount
}

class IssuedDigitalContractViewModel {
    constructor(source: IssuedDigitalContract) {

    }
}

interface AsyncOrganizationMemberComponentProps extends React.ComponentProps<any> {
    uuid: string
}

interface ContractPartyListItemProps extends React.ComponentProps<any> {
    contractParty: IdentifiedByEmailContractParty | PlatformUserContractParty
}

interface BillingDataListViewItemProps extends React.ComponentProps<any> {
    billingData: DigitalContractBilling
}

interface UserInformationListItemProps extends React.ComponentProps<any> {
    userInformation: UserInformation
}

/**
 * check the type of the contract party activity and return the corresponding element 
 * @param contractPartyActivity /
 * @returns 
 */
function ContractPartyActivitiesType(contractPartyActivity: ContractPartyActivityModel | undefined): JSX.Element {
    if ((contractPartyActivity as DigitalContractPartyActivityModel).contractId) {

        switch (contractPartyActivity?.type) {
            case 'EDITABLE_CONTRACT_CREATED':
                return (
                    <Typography sx={{ color: (theme) => theme.palette.primary.main, fontWeight: "600", marginRight: "10px" }}><b>[{contractPartyActivity.contractParty.role}] - </b> Data de criação do contrato editável: </Typography>
                )
            case 'ATTACHMENT_UPDATED':
                return (
                    <Typography sx={{ color: "#212121", fontWeight: "600", marginRight: "10px" }}><b>[{contractPartyActivity.contractParty.role}] - </b> Anexo Atualizado: </Typography>
                )
            case 'USER_INFORMATION_UPDATED':
                return (
                    <Typography sx={{ color: "#212121", fontWeight: "600", marginRight: "10px" }}><b>[{contractPartyActivity.contractParty.role}] - </b> Informações do usuário atualizadas: </Typography>
                )
            case 'CONTRACT_PARTY_SIGNED_CONTRACT':
                return (
                    <Typography sx={{ color: "#212121", fontWeight: "600", marginRight: "10px" }}><b>[{contractPartyActivity.contractParty.role}] - </b> Contrato assinado: </Typography>
                )
            case 'CONTRACT_WAS_ISSUED':
                return (
                    <Typography sx={{ color: "#212121", fontWeight: "600", marginRight: "10px" }}><b>[{contractPartyActivity.contractParty.role}] - </b> O estado do contrato foi alterado: </Typography>
                )
            default: return <></>
        }
    }
    else {
        return <></>
    }
}

/**
 * Main component render function
 * @param props 
 * @returns 
 */
const IssuedContractDialogContent = (props: IssuedContractDialogContentProps): JSX.Element => {
    // states
    const [tab, setTab] = useState("0");
    const [userInformationTab, setUserInformationTab] = useState<string>("");
    const [organizationMembersMap, setOrganizationMembersMap] = useState<OrganizationMemberMap>({});
    const [loading, setLoading] = useState(false);
    const [userInformationContractPartiesRoles, setUserInformationContractPartiesRoles] = useState<string[]>([]);
    const [contractPartiesWithPendingSignature, setContractPartiesWithPendingSignature] = useState<ContractParty[]>([]);
    const [contractPartyActivitiesLogs, setContractPartyActivitiesLogs] = useState<ContractPartyActivityModel[]>([]);
    const contract = props.source;

    // states of the blobs of the contract  
    const [signatureCertificatePDFContentBlob, setSignatureCertificatePDFContentBlob] = useState<Blob | undefined>(undefined);
    const [originalDocumentBlob, setOriginalDocumentBlob] = useState<Blob | undefined>(undefined);
    const [watermarkAndSignatureCertificateBlob, setWatermarkAndSignatureCertificateBlob] = useState<Blob | undefined>(undefined);

    const [templateDownloadLink, setTemplateDownloadLink] = useState("");

    const [isOriginalContractEncryptedDialog, setIsOriginalContractEncryptedDialog] = useState(false);
    const [isOriginalContractEncrypted, setIsOriginalContractEncrypted] = useState(false);

    const authUser = useContext(AuthenticationContext);
    const userRoles = useContext(RolesContext);

    React.useEffect(() => {
        if (props.source.documentType === IssuedContractDocumentType.WORD) {
            fetchTemplateDownloadLink();
        }
    }, [])

    const fetchTemplateDownloadLink = async () => {
        const url = (await ContractsService.createIssuedDigitalContractDocumentDownloadURI(props.source)).uri
        const encodedUrl = encodeURIComponent(url)
        setTemplateDownloadLink(encodedUrl);
    }

    // handle menu
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    //Context props
    const notification = useAppDispatch();

    // effect used to load the userInformationContractParties state prop
    React.useEffect(() => {
        // ignore if the user information array is null or empty
        if (!props.source.userInformation || props.source.userInformation.length == 0) return;

        // map the contract parties roles from the userInformation data
        // also do a filter to remove duplicated entries
        let contractPartiesRoles = props.source.userInformation
            .map(ui => ui.requiredInformation.contractParty.role)
            .filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });

        // update the state value
        setUserInformationContractPartiesRoles(contractPartiesRoles);
        setUserInformationTab(contractPartiesRoles[0]);
    }, []);

    // effect used to load pending contract parties signatures state
    React.useEffect(() => {
        // ignore if no signature is set on the contract 
        const signatures = (props.source.signatures) ? [...props.source.signatures] : [];

        // map the contract parties roles that signed the contract
        const mapOfContractPartiesRolesThatSignedTheContract = signatures.map(s => s.contractParty.role);

        // set the state of the pending signature with only the contract parties that does not have signed the contract yet
        setContractPartiesWithPendingSignature(props.source.contractParties.filter(cp => mapOfContractPartiesRolesThatSignedTheContract.indexOf(cp.role) < 0));
    }, []);

    // effect used to load contract parties activities logs state
    React.useEffect(() => {
        ContractPartiesActivitiesService.fetchContractPartyActivitiesByContractId(props.source.id)
            .then(response => {
                setContractPartyActivitiesLogs([...contractPartyActivitiesLogs, ...response])
            }
            ).catch(error => {
                console.error(error)
            })
    }, []);

    // effect used to call the function to generate the blobs of the contracts
    React.useEffect(() => {
        setLoading(true)
        generateBlobsOfContracts()
        setLoading(false)
    }, [])

    // function to generate the blobs of the contracts
    async function generateBlobsOfContracts() {
        const blobs = await GeneratePDF(contract);

        setOriginalDocumentBlob(blobs.originalcontract);
        setWatermarkAndSignatureCertificateBlob(blobs.watermarkAndSignature);
        setSignatureCertificatePDFContentBlob(blobs.signatureCertificate)
        setIsOriginalContractEncryptedDialog(blobs.isOriginalContractEncrypted);
        setIsOriginalContractEncrypted(blobs.isOriginalContractEncrypted);
    }


    // function to download the attachment file
    async function handleDownloadAttachmentFile(contractId: string, attachmentName: string, attachmentFileName: string) {
        setLoading(true)
        try {
            const downloadURI = await (await ContractsService.generateDownloadURIForDigitalContractAttachment(contractId, attachmentName)).uri

            // access the URI provided and create the blob of the attachment file
            axios.get(`${downloadURI}`, {
                responseType: "blob"
            }).then(downloadedFile => {
                // create file link in browser's memory
                const href = URL.createObjectURL(downloadedFile.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', attachmentFileName); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
                .finally(() => {
                    setLoading(false)
                })

        } catch (e) {
            const error = new ErrorWrapper(e)
            notification(show({ type: "error", message: error.message }))
        }
    }

    function formatRequiredInformationValue(ri: UserInformation): string | undefined {
        if (!ri) return;
        let riValue = ri.value

        if (ri.requiredInformation.requiredInformation.type === "DATE") {
            return moment(riValue).format("DD/MM/YYYY")
        }
        return riValue
    }

    /**
     * checks if the contract party activity log type is the editable contract created, if so, returns the first timeline element, 
     * otherwise adds the timeline element with the connector
     * 
     */
    function contractPartyActivitiesLogsCard(contractPartyActivityLog: ContractPartyActivityModel): JSX.Element {
        const type = contractPartyActivityLog.type.toString();

        if (type === "EDITABLE_CONTRACT_CREATED") {
            return (
                <TimelineItem>
                    <TimelineOppositeContent display="none" />
                    <TimelineSeparator>
                        <TimelineDot color="success" />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography display="flex" sx={{ fontWeight: "600", color: (theme) => theme.palette.primary.main }}>{ContractPartyActivitiesType(contractPartyActivityLog)} {new Date(contractPartyActivityLog.creationDate).toLocaleString()}</Typography>
                    </TimelineContent>
                </TimelineItem>
            )
        }
        else {
            return (
                <TimelineItem>
                    <TimelineOppositeContent display="none" />
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography display="flex" sx={{ fontWeight: "600", color: "#212121" }}>{ContractPartyActivitiesType(contractPartyActivityLog)} {new Date(contractPartyActivityLog.creationDate).toLocaleString()}</Typography>
                    </TimelineContent>
                </TimelineItem>
            )
        }
    }

    function ContractStatusBadge(): JSX.Element {
        switch (props.source.state) {
            case "WAITING_FOR_SIGNATURES":
                return <Chip label="Aguardando assinaturas" color="error" variant="outlined" size="small" />;
            case "SIGNED":
                return <Chip label="Assinado" color="success" variant="outlined" size="small" />;
            case "INACTIVE":
                return (<Chip label="Inativo" color="default" variant="outlined" size="small" />);
            default:
                return <b>Status desconhecido</b>
        }
    }

    /**
     * Component that renders data of an organization member identified by its UUID. This component handles an async data fetch from the API
     * @param props 
     * @returns 
     */
    function AsyncOrganizationMemberComponent(props: AsyncOrganizationMemberComponentProps): JSX.Element {
        let member = organizationMembersMap[props.uuid];

        // return the member if it is already loaded on the organization members map cache
        if (member) {
            return (
                <List>
                    <ListItem>
                        <ListItemText primary="Nome do emissor" secondary={member.fullname} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="E-mail do emissor" secondary={member.email} />
                    </ListItem>
                </List>
            );
        }

        // otherwise, put an dummy object into the member variable and store it into the map cache
        member = {
            accountRoles: [AccountRoles.USER_AND_TEAMS_MANAGER],
            creationDate: new Date(),
            deletedAt: null,
            email: "...",
            fullname: "...",
            hasHandwrittenSignature: false,
            preferredName: "...",
            uuid: "...",
            contractsRestrictViewModeEnabled: false
        }

        // update the map cache with the dummy data
        let organizationMembersMapCopy = Object.assign(organizationMembersMap);
        organizationMembersMapCopy[props.uuid] = member;
        setOrganizationMembersMap(organizationMembersMapCopy);

        // make an async call to the API to fetch the user data into the map cache
        setLoading(true);
        OrganizationService.fetchOrganizationMemberByUuid(props.uuid)
            .then(member => {
                organizationMembersMapCopy[props.uuid] = member;
                setOrganizationMembersMap(organizationMembersMapCopy);
            })
            .catch(error => {
                console.error("Could not fetch organization members data from the API", error);
            })
            .finally(() => {
                setLoading(false);
            });

        // return an dummy object
        return <CircularProgress />
    }

    function normalizedValidityPeriodType(type: string) {
        switch (type) {
            case 'STARTS_WHEN_SIGNED_BY_ALL_PARTIES':
                return "Inicia quando todas as partes assinarem";
            case 'SET_BY_ISSUER':
                return "Definido pelo emissor";
            case 'UNDETERMINED':
                return "Indeterminado";
            case 'SET_AUTOMATICALLY_AFTER_START_DATE':
                return "Definido automaticamente após o início do contrato";
            default:
                return type;
        }
    }



    function ContractPartyListItem(props: ContractPartyListItemProps): JSX.Element {
        const [shareDialog, setShareDialog] = useState(false);
        const [lastAccess, setLastAccess] = useState("Ainda não acessou o contrato");

        useEffect(() => {
            getLastAccessDate();
        }, [])

        function getLastAccessDate() {

            const accessHistory = contract.accessHistory;

            Object.keys(accessHistory).forEach(cpRole => {
                if (cpRole === props.contractParty.role) {
                    setLastAccess(moment(accessHistory[cpRole].accessedAt).format("DD/MM/YYYY - HH:mm:ss"));
                }
            })
        }

        function goToContact(identification: string) {
            ContactsService.findByIdentification(identification)
                .then(response => {
                    if (response) {
                        window.location.replace(`/organization/contacts/${response.id}`)
                    } else if (userRoles.assertIsOrganizationOrAdministratorOr(authUser, AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER)) {
                        const dto: GenerateContactDTO = {
                            email: identification,
                            role: props.contractParty.role
                        };
                        ContactsService.registerContactByContractParty(contract.id, dto)
                            .then((response) => {
                                window.location.replace(`/organization/contacts/${response.id}`);
                            })
                            .catch((e) => {
                                const err = new ErrorWrapper(e);
                                notification(show({ type: "error", message: err.message }));
                            })
                    } else {
                        notification(show({ type: "error", message: "Contato não existe" }))
                    }
                })
                .catch(e => {
                    const err = new ErrorWrapper(e);
                    notification(show({ type: "error", message: err.message }));
                })
        }

        // if the contract is IDENTIFIED_BY_EMAIL
        if (props.contractParty.contractPartyIdentificationType === "IDENTIFIED_BY_EMAIL") {
            const contractParty = props.contractParty as IdentifiedByEmailContractParty;
            return (
                <>
                    <ListItem>
                        <ListItemButton>
                            <ListItemIcon>
                                <Mail />
                            </ListItemIcon>
                            <ListItemText primary="Identificado por e-mail" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Último acesso em: " secondary={lastAccess} />
                    </ListItem>
                    <ListItem>
                        <Box>
                        </Box>
                        <ListItemText primary="Endereço de e-mail utilizado para assinatura" secondary={contractParty.email} />
                        <Tooltip title="Compartilhar contrato">
                            <IconButton onClick={() => setShareDialog(true)}><Share /></IconButton>
                        </Tooltip>
                        {
                            contract.state === "SIGNED"
                                ?
                                <Tooltip title="Ir para o contato">
                                    <IconButton onClick={() => goToContact(contractParty.email)}><ContactsIcon /></IconButton>
                                </Tooltip>
                                :
                                <></>
                        }
                        <ShareContractDialog open={shareDialog} onClose={() => setShareDialog(false)} source={contract} targetContractPartyEmail={contractParty.email} />
                    </ListItem>
                </>
            )
        }
        // if the contract is PLATFORM_USER
        else if (props.contractParty.contractPartyIdentificationType === "PLATFORM_USER") {
            const contractParty = props.contractParty as PlatformUserContractParty;
            let member = organizationMembersMap[contractParty.uuid];

            // if the member is on the map cache return the true component
            if (member) {
                return (
                    <>
                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon>
                                    <VerifiedUser />
                                </ListItemIcon>
                                <ListItemText primary="Membro da plataforma" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Nome" secondary={member.fullname} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="E-mail" secondary={member.email} />
                            <Tooltip title="Compartilhar contrato">
                                <IconButton onClick={() => setShareDialog(true)}><Share /></IconButton>
                            </Tooltip>
                            {
                                contract.state === "SIGNED"
                                    ?
                                    <Tooltip title="Ir para o contato">
                                        <IconButton onClick={() => goToContact(member.email)}><ContactsIcon /></IconButton>
                                    </Tooltip>
                                    :
                                    <></>
                            }
                            <ShareContractDialog open={shareDialog} onClose={() => setShareDialog(false)} source={contract} targetContractPartyEmail={member.email} />
                        </ListItem>
                    </>
                )
            }

            // otherwise, put an dummy object into the member variable and store it into the map cache
            member = {
                accountRoles: [AccountRoles.USER_AND_TEAMS_MANAGER],
                creationDate: new Date(),
                deletedAt: null,
                email: "...",
                fullname: "...",
                hasHandwrittenSignature: false,
                preferredName: "...",
                uuid: "...",
                contractsRestrictViewModeEnabled: false
            }

            let organizationMembersMapCopy = Object.assign(organizationMembersMap);
            organizationMembersMapCopy[contractParty.uuid] = member;
            setOrganizationMembersMap(organizationMembersMapCopy);

            // make an async call to the API to fetch the user data into the map cache
            setLoading(true);
            OrganizationService.fetchOrganizationMemberByUuid(contractParty.uuid)
                .then(member => {

                    organizationMembersMapCopy[contractParty.uuid] = member;
                    setOrganizationMembersMap(organizationMembersMapCopy);
                })
                .catch(error => {
                    console.error("Could not fetch organization members data from the API", error);
                })
                .finally(() => {
                    setLoading(false);
                });

            // return an CircularProgress to indicate that the data is being fetched
            return <CircularProgress />
        }
        else {
            return <Alert severity="error">Não foi possível identificar a participação deste membro do contrato</Alert>
        }
    }

    /**
     * Render ListItem with billing data
     * @param props 
     * @returns 
     */
    function BillingDataListItem(props: BillingDataListViewItemProps): JSX.Element {
        switch (props.billingData.billingPeriodType) {
            case "RECURRING":
                return (
                    <React.Fragment>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Loop />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={props.billingData.name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Forma de pagamento" secondary="Recorrente" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Responsável pelo pagamento" secondary={props.billingData.debtorContractParty.role} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Data inicial de cobrança" secondary={new Date(props.billingData.initialBillingPeriod).toLocaleDateString()} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Vencimento da cobrança" secondary={props.billingData.billingDueDay} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Valor de cobrança" secondary={props.billingData.value.regionalString} />
                        </ListItem>
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <AttachMoney />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={props.billingData.name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Forma de pagamento" secondary="Parcelado / à vista" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Responsável pelo pagamento" secondary={props.billingData.debtorContractParty.role} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Data inicial de cobrança" secondary={new Date(props.billingData.initialBillingPeriod).toLocaleDateString()} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Valor total de cobrança" secondary={props.billingData.value.regionalString} />
                        </ListItem>
                        {
                            (props.billingData.numberOfInstallments > 1)
                                ?
                                <ListItem>
                                    <ListItemText
                                        primary="Parcelas"
                                        secondary={
                                            `${props.billingData.numberOfInstallments}x de ${new CurrencyValue(props.billingData.value.value / props.billingData.numberOfInstallments, props.billingData.value.currency as SupportedCurrencies).regionalValueString}`
                                        }
                                    />
                                </ListItem>
                                :
                                <ListItem>
                                    <ListItemText
                                        primary="Pagamento será à vista"
                                    />
                                </ListItem>
                        }
                    </React.Fragment>
                );
        }
    }

    function UserInformationListItem(props: UserInformationListItemProps): JSX.Element {
        return (
            <React.Fragment>
                <ListItem>
                    <Box>
                        <ListItemText primary={props.userInformation.requiredInformation.requiredInformation.name} secondary={formatRequiredInformationValue(props.userInformation)} />
                        {
                            props.userInformation.requiredInformation.requiredInformation.description ?
                                <Typography sx={{ mt: 2 }} variant="caption"><b>Descrição:</b> {props.userInformation.requiredInformation.requiredInformation.description}</Typography>
                                :
                                <></>
                        }
                    </Box>
                </ListItem>
            </React.Fragment>
        )
    }

    // Main component
    return (
        <Container>
            <DialogContent>
                <TabContext value={tab}>
                    <TabList variant="scrollable" scrollButtons="auto" onChange={(e, selectedTab) => setTab(selectedTab)} aria-label="Navegação dos dados do contrato">
                        <Tab label="Geral" value="0" />
                        <Tab label="Participantes do Contrato" value="1" />
                        <Tab label="Financeiro" value="2" />
                        <Tab label="Informações requeridas" value="3" />
                        <Tab label="Anexos" value="4" />
                        <Tab label="Assinaturas e Autencidade" value="5" />
                        <Tab label="Visualizar contrato" value="6" />
                        <Tab label="Histórico de Atividades" value="7" />
                    </TabList>
                    {/** Geral */}
                    <TabPanel value="0">
                        <Typography variant="h6">Informações do contrato</Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="Nome do contrato" secondary={props.source.name} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Status do contrato" />
                                <ContractStatusBadge />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Data de emissão" secondary={new Date(props.source.issueDate).toLocaleString()} />
                            </ListItem>
                            {
                                (props.source.fullySignedDate && props.source.state === "SIGNED")
                                    ?
                                    <ListItem>
                                        <ListItemText primary="Data de assinatura" secondary={new Date(props.source.fullySignedDate).toLocaleString()} />
                                    </ListItem>
                                    :
                                    <></>
                            }
                        </List>
                        <Divider />
                        <Typography variant="h6">Vigência do contrato</Typography>
                        <List>
                            <ListItem>
                                <Typography>Início</Typography>
                            </ListItem>
                            <ListItem>
                                {
                                    (props.source.validityPeriod.startDate && props.source.validityPeriod.startDate)
                                        ?
                                        <>
                                            <ListItemText primary="Data de início" secondary={new Date(props.source.validityPeriod.startDate).toLocaleString()} />
                                        </>
                                        :
                                        <></>
                                }
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Tipo de vigência: " secondary={normalizedValidityPeriodType(props.source.validityPeriod.startDatePolicyType)} />
                            </ListItem>
                            <ListItem>
                                <Divider sx={{ width: "100%" }} />
                            </ListItem>
                            <ListItem>
                                <Typography>Término</Typography>
                            </ListItem>
                            <ListItem>
                                {
                                    (props.source.validityPeriod.endDate && props.source.validityPeriod.endDate)
                                        ?
                                        <>
                                            <ListItemText primary="Data de término" secondary={new Date(props.source.validityPeriod.endDate).toLocaleString()} />
                                        </>
                                        :
                                        <></>
                                }
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Tipo de vigência: " secondary={normalizedValidityPeriodType(props.source.validityPeriod.endDatePolicyType)} />
                            </ListItem>
                        </List>
                        <Divider />
                        <Typography variant="h6">Emissor do contrato</Typography>
                        <AsyncOrganizationMemberComponent uuid={props.source.issuerUuid} />
                        <Divider />
                        <Typography variant="h6">Identificações do Cartorizi</Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="ID" secondary={props.source.id} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="SKID" secondary={props.source.shardKey} />
                            </ListItem>
                        </List>
                        <Divider />
                        <Typography variant="h6" sx={{ mb: 2 }}>Tags</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {
                                (props.source.contractTags)
                                    ?
                                    props.source.contractTags.map(tag => {
                                        return (
                                            <>
                                                <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "primary.main", m: 1, borderRadius: "24px", justifyContent: "center", minWidth: "18%", height: "36px", maxWidth: "18%" }}>
                                                    <Tooltip title={tag.tagName}>
                                                        <Typography sx={{ fontSize: "12px", color: "#fff", margin: "0 16px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{tag.tagName}</Typography>
                                                    </Tooltip>
                                                </Box>
                                            </>
                                        )
                                    })
                                    :
                                    <></>
                            }
                        </Box>
                    </TabPanel>
                    {/** Participantes do contrato */}
                    <TabPanel value="1">
                        <List>
                            {
                                props.source.contractParties.map(cp => (
                                    <>

                                        <Typography variant="h6">{cp.role}</Typography>
                                        <ContractPartyListItem contractParty={cp} />
                                        <Divider />
                                    </>
                                ))
                            }
                        </List>
                    </TabPanel>
                    {/** Financeiro */}
                    <TabPanel value="2">
                        {
                            (props.source.billingData && props.source.billingData.length > 0)
                                ?
                                <List>
                                    {
                                        props.source.billingData.map(b => (
                                            <React.Fragment>
                                                <BillingDataListItem billingData={b} />
                                                <Divider sx={{ mb: '16px', mt: '16px' }} />
                                            </React.Fragment>
                                        ))
                                    }
                                </List>
                                :
                                <Alert severity="info">Esse contrato não possui dados financeiros automatizados</Alert>
                        }
                    </TabPanel>
                    {/** Informacoes requeridas */}
                    <TabPanel value="3">
                        {
                            (props.source.userInformation !== null && props.source.userInformation.length > 0)
                                ?
                                //This part will be render only if the contract has user information:
                                // render an TabContext to show the user information grouping by contract party role from userInformationContractPartiesRoles
                                <TabContext value={userInformationTab}>
                                    <TabList
                                        onChange={(e, selectedTab) => setUserInformationTab(selectedTab)}
                                        aria-label="Navegação dos dados de informações requeridas categorizados por participante"
                                    >
                                        {

                                            userInformationContractPartiesRoles.map(u => (
                                                <Tab label={u} value={u} />
                                            ))
                                        }
                                    </TabList>
                                    {
                                        userInformationContractPartiesRoles.map(u => (
                                            <TabPanel value={u}>
                                                <List>
                                                    {
                                                        (props.source.userInformation !== null && props.source.userInformation.length > 0)
                                                            ?
                                                            // only render the user information from the selected user information tab (.filter)
                                                            props.source.userInformation
                                                                .filter(ui => ui.requiredInformation.contractParty.role === userInformationTab)
                                                                .map(ui => (
                                                                    <React.Fragment>
                                                                        <UserInformationListItem userInformation={ui} />
                                                                        <Divider sx={{ mb: '16px', mt: '16px' }} />
                                                                    </React.Fragment>
                                                                ))
                                                            :
                                                            <></>
                                                    }
                                                </List>
                                            </TabPanel>
                                        ))
                                    }
                                </TabContext>
                                :
                                <Alert severity="info">Esse contrato não possuí nenhuma informação requerida</Alert>
                        }
                    </TabPanel>
                    {/** Anexos */}
                    <TabPanel value="4">
                        {
                            (props.source.attachments && props.source.attachments.length > 0)
                                ?
                                <List>
                                    {
                                        props.source.attachments.map(a => (
                                            <React.Fragment>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <AttachFile />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={a.name} secondary={`FID: ${a.guid}`} />
                                                </ListItem>
                                                <ListItemButton
                                                    disabled={loading}
                                                    onClick={() => handleDownloadAttachmentFile(props.source.id, a.name, a.filename)}
                                                >
                                                    <ListItemIcon>
                                                        <FileDownload />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Baixar arquivo" secondary={a.filename} />
                                                </ListItemButton>
                                                <Divider sx={{ mt: 4, mb: 4 }} />
                                            </React.Fragment>
                                        ))
                                    }
                                </List>
                                :
                                <Alert severity="info">Esse contrato não possui anexos</Alert>
                        }
                    </TabPanel>
                    {/** Assinaturas e autencidade */}
                    <TabPanel value="5">
                        {
                            (props.source.signatures && props.source.signatures.length > 0)
                                ?
                                <>
                                    <Typography variant="h5">Assinaturas das partes do contrato</Typography>
                                    <List>
                                        {
                                            props.source.signatures.map(s => (
                                                <React.Fragment>
                                                    <ListItem>
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: "#4CAF50" }}>
                                                                <DoneAll />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={s.contractParty.role} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary="Data de assinatura" secondary={new Date(s.signatureDate).toLocaleString()} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary="E-mail utilizado para assinatura" secondary={s.userEmail} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary="Endereço IP do assinante" secondary={s.ipAddress} />
                                                    </ListItem>
                                                    <Divider sx={{ mt: 2, mb: 4 }} />
                                                </React.Fragment>
                                            ))
                                        }
                                    </List>
                                </>
                                :
                                <></>
                        }
                        {
                            (contractPartiesWithPendingSignature.length > 0)
                                ?
                                <>
                                    <Typography variant="h5">Partes com assinatura pendente</Typography>
                                    <List>
                                        {
                                            contractPartiesWithPendingSignature.map(cps => (
                                                <React.Fragment>
                                                    <ListItem>
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: "#ffa940" }}>
                                                                <Warning />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={cps.role} />
                                                    </ListItem>
                                                    <Divider sx={{ mt: 2, mb: 4 }} />
                                                </React.Fragment>
                                            ))
                                        }
                                    </List>
                                </>
                                :
                                <></>
                        }
                        <Typography variant="h5">Informações de autenticidade do contrato</Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="Algoritmo de hash do documento" secondary={props.source.digitalTrustData.documentHashAlgorithm} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Valor do hash do documento" secondary={props.source.digitalTrustData.documentHashValue} />
                            </ListItem>
                        </List>
                    </TabPanel>
                    {/** Visualizar Contrato*/}
                    <TabPanel value="6">
                        {
                            (props.source.documentType === IssuedContractDocumentType.WORD)
                                ?
                                <>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        variant="contained"
                                        component="label"
                                        startIcon={<Download />}

                                    >
                                        Baixar contrato
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={!!anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={async () => {
                                            setAnchorEl(null);
                                            if (!signatureCertificatePDFContentBlob) {
                                                return
                                            }

                                            if (watermarkAndSignatureCertificateBlob) {
                                                downloadTheWatermarkAndSignatureContract(watermarkAndSignatureCertificateBlob, contract)
                                            }

                                        }}>

                                            <ListItemIcon>
                                                <Print />
                                            </ListItemIcon>
                                            Baixar documento (Timbrado)
                                        </MenuItem>

                                        <MenuItem onClick={() => {
                                            setAnchorEl(null);
                                            downloadOriginalFile(originalDocumentBlob, contract);
                                        }}>
                                            <ListItemIcon>
                                                <PictureAsPdf />
                                            </ListItemIcon>
                                            Baixar documento (Original)
                                        </MenuItem>
                                    </Menu>
                                    <iframe
                                        onLoad={() => setLoading(true)} frameBorder="0"
                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${templateDownloadLink}`}
                                        style={{ height: "92vh", width: "100%", marginTop: "20px" }}
                                    />
                                </>
                                :
                                <>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        variant="contained"
                                        component="label"
                                        disabled={loading || !signatureCertificatePDFContentBlob}
                                    >
                                        Baixar PDF do contrato
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={!!anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={async () => {
                                            setAnchorEl(null);
                                            if (!signatureCertificatePDFContentBlob) {
                                                return
                                            }

                                            if (watermarkAndSignatureCertificateBlob) {
                                                downloadTheWatermarkAndSignatureContract(watermarkAndSignatureCertificateBlob, contract)
                                            }

                                        }}>

                                            <ListItemIcon>
                                                <Print />
                                            </ListItemIcon>
                                            Baixar documento (Timbrado)
                                        </MenuItem>

                                        <MenuItem onClick={() => {
                                            setAnchorEl(null);
                                            downloadOriginalFile(originalDocumentBlob, contract);
                                        }}>
                                            <ListItemIcon>
                                                <PictureAsPdf />
                                            </ListItemIcon>
                                            Baixar documento original
                                        </MenuItem>
                                    </Menu>
                                    {
                                        (watermarkAndSignatureCertificateBlob && originalDocumentBlob)
                                            ?
                                            (contract.state === "SIGNED" && !isOriginalContractEncrypted)
                                                ?
                                                <embed
                                                    src={`${URL.createObjectURL(watermarkAndSignatureCertificateBlob)}#toolbar=0&navpanes=0&scrollbar=0`}
                                                    type="application/pdf"
                                                    width="100%"
                                                    height="875"
                                                >
                                                </embed>
                                                :
                                                <embed
                                                    src={`${URL.createObjectURL(originalDocumentBlob)}#toolbar=0&navpanes=0&scrollbar=0`}
                                                    type="application/pdf"
                                                    width="100%"
                                                    height="875"
                                                >
                                                </embed>
                                            :
                                            <CircularProgress />
                                    }
                                </>
                        }
                    </TabPanel>

                    {/**Histórico de Atividades*/}
                    <TabPanel value="7">
                        <Typography variant="h6">Histórico</Typography>
                        <Timeline position="right">

                            {/* Every time a contract party activity is added to the contract, it adds a new Time Line Item */}
                            {
                                (contractPartyActivitiesLogs.map(contractPartyActivityLog => (contractPartyActivitiesLogsCard(contractPartyActivityLog))).reverse())
                            }
                        </Timeline>

                    </TabPanel>
                </TabContext>
            </DialogContent>
            <EncryptedPDFDialog open={isOriginalContractEncryptedDialog} onClose={() => setIsOriginalContractEncryptedDialog(false)} contract={contract} originalContract={originalDocumentBlob} signatureCertificate={signatureCertificatePDFContentBlob} />
        </Container >
    )
}

export default IssuedContractDialogContent;