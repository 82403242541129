import { Theme } from "@emotion/react";
import { Alert, Box, Checkbox, Divider, Input, ListItemText, MenuItem, Select, SxProps, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { RequiredInformationInputFieldProps } from "../rifields";

const MultipleSelect = (props: RequiredInformationInputFieldProps): JSX.Element => {
    const [value, setValue] = useState(props.requiredInformation.value);
    const [isValid, setIsValid] = useState(false);
    const options = props.requiredInformation.requiredInformation.requiredInformation.options;

    const boxStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            padding: "1rem 0.8rem"
        },
        ['@media(max-width:300px)']: {
            padding: "1rem 0.8rem"
        },
        width: "100%",
        padding: "20px 28px",
        backgroundColor: "#ffffff",
        marginBottom: "14px",
        borderRadius: "10px",
        boxSizing: "border-box",
    }
    const titleStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            fontSize: "0.875rem",
            padding: "0.375rem 0.625rem"
        },
        ['@media(max-width:300px)']: {
            fontSize: "0.5rem",
            padding: "0.125rem 0.25rem"
        },
        color: "#555555",
        marginBottom: "30px",
        fontSize: "20px"
    }

    useEffect(() => {
        setIsValid(value.length > 1);
    }, [value]);

    useEffect(() => {
        props.onChange(value, isValid);
    }, [value, isValid])

    function handleOnChange(item: string) {
        const isSelected = value.includes(item);
        const updatedValue = isSelected
            ? value.split(', ').filter(selectedItem => selectedItem !== item).join(', ')
            : value ? `${value}, ${item}` : item;
        setValue(updatedValue);
    }

    return (
        <Fragment key={props.requiredInformation.requiredInformationName}>
            <Box sx={boxStyle} >
                <Typography sx={titleStyle}>{props.requiredInformation.requiredInformationName}</Typography>
                {options ? options.map((item) => (
                    <Tooltip title={(isValid) ? null : "Selecione pelo menos uma opção"}>
                        <MenuItem key={item} onClick={() => handleOnChange(item)}>
                            <Checkbox checked={value.includes(item)} />
                            <ListItemText primary={<Typography variant="body2" style={{ color: isValid ? "#212121" : "#d32f2f" }}>{item}</Typography>} />
                        </MenuItem>
                    </Tooltip>
                ))
                    : null}
            </Box>
            <Divider />
        </Fragment>
    );
}

export default MultipleSelect;