import { ContractParty, UserInformation } from "./contracts";
import { SearchResultIdentification } from "./search-result";

export type NotificationTypes = 'DIGITAL_CONTRACT_ATTACHMENT_UPDATED' | 'DIGITAL_CONTRACT_USER_INFORMATION_UPDATED' | 'EDITABLE_DIGITAL_CONTRACT_STATE_CHANGED' | 'DIGITAL_CONTRACT_ISSUED' | 'CONTRACT_PARTY_SIGNED_CONTRACT' | "CONTRACT_PARTY_REVOKED_ISSUED_CONTRACT" | "CONTRACT_PARTY_REVOKED_EDITABLE_CONTRACT";

export interface NotificationModel {
    id: string;
    creationDate: number;
    organizationId: string;
    recipientsUuid: string;
    state: string;
    type: NotificationTypes;
    description: string;
    value: string;
    wasSeen? : boolean;
    contractId: string;
}

export interface DigitalContractNotificationModel extends NotificationModel {
    contractId: string;
    contractName: string;
}

export interface DigitalContractUserInformationUpdatedNotification extends DigitalContractNotificationModel {
    requester: ContractParty;
}

const parseNotificationTypeToSearchResultNotification = (n : NotificationModel) : SearchResultIdentification | undefined => {
    if ((n as DigitalContractNotificationModel).contractId) {
        let notification = n as DigitalContractNotificationModel;
        return {
            resourceId: notification.contractId,
            resourceType: "contract"
        }
    }
    else {
        return undefined;
    }
}

export {
    parseNotificationTypeToSearchResultNotification
}