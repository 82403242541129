import { AuthenticationContextData } from "../models/authentication";
import { AuthenticatedUser } from "../models/user";
import AuthorizationService from "../services/authorization";

const storage = localStorage;
const STORE_USER = 'dev_user';
const STORE_CONTRACTS_TOKEN = 'dev_contracts_token';
const STORE_ACCOUNTS_TOKEN = 'dev_accounts_token';
const STORE_AUTH_USER_TEAMS = 'dev_auth_user_teams'

/**
 * Class that store authenticated user data on application cache
 */
class AuthenticationStore implements AuthenticationContextData {
    /**
     * Indicates if current user is authenticated
     * @returns 
     */
    isAuthenticated(): boolean {
        return this.user() != null
    }

    /**
     * Get the authenticated user data from local storage
     * @returns 
     */
    user(): AuthenticatedUser {
        const storageData = storage.getItem(STORE_USER);
        return (storageData) ? JSON.parse(storageData) : null;
    }

    authenticationWithAccountsToken = async (accountsToken: string): Promise<AuthenticatedUser> => {

        //Authenticated the user
        const authenticationResponse = await AuthorizationService.contractsLogin(accountsToken);
        this.setAccountsToken(accountsToken)
        this.setContractsToken(authenticationResponse.token);
        
        //Return the updated user from server
        return await this.updatedUser();
    }

    /**
     * Update the authenticated user
     * @returns 
     */
    async updatedUser(): Promise<AuthenticatedUser> {
        //Return user data from server
        const authenticatedUser = await AuthorizationService.me();

        //Store the user data
        storage.setItem(STORE_USER, JSON.stringify(authenticatedUser));

        return authenticatedUser;
    }

    /**
     * Return the user authentication contracts token
     * @returns 
     */
    getContractsToken(): string | null {
        return storage.getItem(STORE_CONTRACTS_TOKEN);
    }

    /**
     * Store the contracts token on storage
     * @param contractsToken 
     */
    setContractsToken(contractsToken: string) {
        storage.setItem(STORE_CONTRACTS_TOKEN, contractsToken);
    }

    /**
     * Return the user authentication accounts token
     * @returns 
     */
    getAccountsToken(): string | null {
        return storage.getItem(STORE_ACCOUNTS_TOKEN);
    }

    /**
     * Store the accounts token on storage
     * @param accountsToken 
     */
    setAccountsToken(accountsToken: string) {
        storage.setItem(STORE_ACCOUNTS_TOKEN, accountsToken);
    }

    /**
     * Log current user out and clear local storage
     * @param callback 
     */
    logout(callback: VoidFunction) {
        storage.clear()
        if (!this.isAuthenticated() && callback) callback();
    }

    getAuthUserTeams() {
        const storageData = storage.getItem(STORE_AUTH_USER_TEAMS);
        return (storageData) ? JSON.parse(storageData) : [];
    }

    setAuthUserTeams(teams: string[]) {
        storage.setItem(STORE_AUTH_USER_TEAMS, JSON.stringify(teams))
    }
}

export default new AuthenticationStore();
