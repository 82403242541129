import { EditableDigitalContract, NormalizedAttachment } from "../../models/contracts";
import {ChangeEvent, Fragment, useEffect, useState} from "react";
import ContractsService from "../../services/contracts";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { useAppDispatch } from "../../redux/hooks";
import { Box, SxProps } from "@mui/system";
import { Alert, Avatar, Button, CircularProgress, Divider, Theme, Typography } from "@mui/material";
import { Check, Close, UploadFileOutlined, Warning } from "@mui/icons-material";

const boxStyle: SxProps<Theme> = {
    ['@media(max-width:450px)']: {
        padding: "1rem 0.8rem"
    },
    ['@media(max-width:300px)']: {
        padding: "1rem 0.8rem"
    },
    width: "100%",
    padding: "20px 28px",
    backgroundColor: "#ffffff",
    marginBottom: "14px",
    borderRadius: "10px",
    boxSizing: "border-box",
}

const titleStyle: SxProps<Theme> = {
    ['@media(max-width:450px)']: {
        fontSize: "0.875rem",
        padding: "0.375rem 0.625rem"
    },
    ['@media(max-width:300px)']: {
        fontSize: "0.5rem",
        padding: "0.125rem 0.25rem"
    },
    color: "#555555",
    marginBottom: "30px",
    fontSize: "20px"
}
const buttomBoxTheme: SxProps<Theme> = {
    ['@media(max-width:450px)']: {
        padding: "0.375rem 0.625rem",
        margin: "auto"
    },
    ['@media(max-width:300px)']: {
        padding: "0.125rem 0.25rem",
        height: "1.5rem",
        width: "6.5rem",
        margin: "auto"
    },
    height: "36px",
    width: "230px"
}

const alertTheme: SxProps<Theme> = {
    width: "100%",
    padding: "20px 28px",
    marginBottom: "14px",
    borderRadius: "10px",
    boxSizing: "border-box",
}
const buttonTheme: SxProps<Theme> = {
    ['@media(max-width:450px)']: {
        fontSize: "0.75rem"
    },
    ['@media(max-width:300px)']: {
        fontSize: "0.5rem"
    },
    fontSize: "0.9rem",
    color: "#fff",
}
const buttonIconTheme: SxProps<Theme> = {
    ['@media(max-width:450px)']: {
        fontSize: "1.125rem"
    },
    ['@media(max-width:300px)']: {
        fontSize: "0.75rem"
    },
    fontSize: "1.5rem",
    color: "#fff",
    marginRight: "0.25rem"
}

interface OnChangeCallback {
    (source : NormalizedAttachment, isValid: boolean): void;
}

export interface RequiredAttachmentProps {
    attachment: NormalizedAttachment;
    contract : EditableDigitalContract;
    onChange: OnChangeCallback;
}

// function that receive the type of the file and set image url with the blob of the result of the switch/case
function fileType(file: File) {
    const type = file.type.split("/")
    const blob = URL.createObjectURL(file)

    switch (type[1]) {
        case 'webp':
        case 'png':
        case 'jpeg':
        case 'gif':
        case 'bmp':
            return blob

        case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return "/images/assets/excel.webp"

        case 'msword':
            return "/images/assets/word.webp"

        case 'pdf':
            return "/images/assets/pdf.webp"

        case 'x-zip-compressed':
        case 'rar':
            return "/images/assets/zipper.webp"

        default:
            return "/images/assets/regular.webp"
    }
}

interface FilePreview {
    blob : string;
    filename : string;
}

const RequiredAttachment = (props : RequiredAttachmentProps) : JSX.Element => {

    // states
    const [uploaded, setUploaded] = useState((props.attachment.attachment) ? true : false);
    const [isValid, setIsValid] = useState((props.attachment.attachment) ? true : false);
    const [loading, setLoading] = useState(false);
    const [selectedFilePreviewBlob, setSelectedFilePreviewBlob] = useState<FilePreview | undefined>(undefined);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [hasError, setHasError] = useState(false);
    

    // context
    const notification = useAppDispatch();


    useEffect(() => {
        props.onChange(props.attachment, isValid);
    }, [isValid])

    useEffect(() => {
        setIsValid(!props.attachment.specification.required || uploaded);
    }, [uploaded]);

    async function uploadRequiredAttachment(e: ChangeEvent<HTMLInputElement>) {

        if (e.target.files && e.target.files.length >= 1) {
        
            setLoading(true);
            setHasError(false);
            setUploadPercentage(10);

            const file = e.target.files[0];
            setUploadPercentage(25);
            try {
                const fileName = file.name.replace(/[^a-zA-Z0-9 ._-]/g, "-");
                const attachmentName = encodeURIComponent(props.attachment.specification.name)

                setUploadPercentage(50);
                await ContractsService.uploadUserAttachments(
                    props.contract.id,
                    { file: file },
                    fileName,
                    attachmentName
                )
                setUploadPercentage(75);
                notification(show({
                    type: 'success',
                    message: 'Upload do arquivo enviado com sucesso.'
                }))

                setUploaded(true);
                setSelectedFilePreviewBlob({
                    blob : fileType(file),
                    filename : file.name
                })
            }
            catch (e) {
                const err = new ErrorWrapper(e)
                notification(show({
                    type: 'error',
                    message: (err.httpStatus != 200) ? `Não foi possível enviar o arquivo.` : undefined
                }))
                setHasError(true);
                
            }
            finally {
                setUploadPercentage(0);
                setLoading(false);
            }
        }
    }

    return (
        <Fragment>
                <Box sx={boxStyle} key={props.attachment.specification.name}>
                    <Typography sx={titleStyle}>{props.attachment.specification.name}</Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom : "8px"}}>
                        <Button sx={buttomBoxTheme}
                            variant="contained"
                            component="label">
                            <UploadFileOutlined sx={buttonIconTheme} />
                            <Typography sx={buttonTheme}>
                                {
                                    (!uploaded) 
                                    ?
                                        "Upload do arquivo"
                                    :
                                        "Upload de outro arquivo"
                                }
                            </Typography>
                            <input
                                type="file"
                                id="select-image"
                                onChange={(e) => {
                                    uploadRequiredAttachment(e)
                                }}
                                hidden
                            />
                        </Button>
                        {
                            (isValid)
                            ?
                                <Avatar sx={{ bgcolor: "#66ba6a" }}>
                                    <Check />
                                </Avatar>
                            :
                            (hasError)
                            ?
                                <Avatar sx={{ bgcolor: "#ffa940" }}>
                                    <Warning />
                                </Avatar>
                            :
                            <Avatar sx={{ bgcolor: "#d62828" }}>
                                <Warning />
                            </Avatar>
                        }

                        <Box sx={{ width: '25%', margin: "0px 10px" }}>
                            {
                                (selectedFilePreviewBlob) ? 
                                (
                                    <Box>
                                        <Box sx={{ display: "flex", height: "100px", borderRadius: "12px", margin: "0px 28px 0px 28px", alignItems: "center", justifyContent: "center" }}>
                                            <img src={selectedFilePreviewBlob.blob.toString()} style={{ maxHeight: "80px", maxWidth: "80px", height: "80px", borderRadius: "12px" }} />
                                        </Box>
                                        <Typography sx={{ textAlign: "center", maxWidth: "150px", maxHeight: "24px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", color: "#555555" }}>
                                            {selectedFilePreviewBlob.filename}
                                        </Typography>
                                    </Box>
                                )
                                :
                                <></>
                            }
                            {
                                (loading)
                                    ?
                                    (uploaded)
                                        ?
                                        <Box sx={{ mt: "20px", display: "flex", justifyContent: "center" }}>
                                            <Box sx={{ display: "inline-block", position: "relative" }}>
                                                <CircularProgress color="success"/>
                                                <Check color="success" sx={{ position: "absolute", top: "8px", right: "8px", }} />
                                            </Box>
                                        </Box>
                                        :
                                        (hasError)
                                            ?
                                            <Box sx={{ mt: "20px", display: "flex", justifyContent: "center" }}>
                                                <Box sx={{ display: "inline-block", position: "relative" }}>
                                                    <CircularProgress color="error" />
                                                    <Close color="error" sx={{ position: "absolute", top: "8px", right: "8px", }} />
                                                </Box>
                                            </Box>
                                            :
                                            <Box sx={{ mt: "20px", display: "flex", justifyContent: "center" }}>
                                                <Box sx={{ display: "inline-block", position: "relative" }}>
                                                    <CircularProgress color="primary" />
                                                    <Typography color="primary" sx={{ position: "absolute", top: "11px", right: "8px", fontSize: "0.7rem" }}>{uploadPercentage}%</Typography>
                                                </Box>
                                            </Box>
                                    :
                                    <></>
                            }
                        </Box>
                    </Box>
                </Box >
                <Divider />
            </Fragment>
    )
}

export default RequiredAttachment;