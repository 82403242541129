import { UserAccount } from "../models/user";
import contractsAPI from "./contracts-api";

const UserAccountService = {
    /**
     * Create a organization team
     * @param authenticationToken
     */
    create: async (token: string): Promise<UserAccount> => {
        //Send request
        return await (await contractsAPI.post(`/rest/v1/user-accounts/public/create-with-zaninte-account`, { token })).data
    },

    /**
     * Send an HTTP/S request to the contracts API to delete the authenticated user handwritten signature
     * @returns Current user account data in the database
     */
    deleteHandwrittenSignature: async (): Promise<UserAccount> => {
        return await (await contractsAPI.delete(`/rest/v1/user-accounts/handwritten-signature`)).data
    },

    /**
     * Send an HTTP/S request to the contracts API to upload an handwritten signature image to the
     * authenticated user account
     * @param image - The image that will be uploaded
     * @returns The current state of the user account
     */
    uploadHandwrittenSignature: async (signatureImage: Blob): Promise<UserAccount> => {
        // request body
        var body = new FormData();
        body.append("signature-file", signatureImage);

        return (await contractsAPI.post("/rest/v1/user-accounts/handwritten-signature", body)).data
    },

    checkIfDocumentExists: async (document: string): Promise<boolean> => {
        const response = await contractsAPI.get(`/rest/v1/user-accounts/public/check-document?document=${document}`);

        return await response.data;
    }
}

export default UserAccountService;