import { ArrowBack, ArrowDropDown, ArrowDropUp, Close, FindInPage, Menu as MenuIcon, UploadFile, Verified, Warning } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, IconButton, SxProps, Tab, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";
import { pdf } from "@react-pdf/renderer";
import axios from "axios";
import { Buffer } from 'buffer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSearchParams } from "react-router-dom";
import sha256 from "sha256";
import { CurrentContext } from "../../appctx/webappContext";
import { ContractParty, IssuedContractDocumentType, IssuedDigitalContract } from "../../models/contracts";
import { createWatermarkVersion } from "../../models/generate-contract-blobs";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../redux/hooks";
import ContractsService from "../../services/contracts";
import ErrorWrapper from "../../utils/ErrorWrapper";
import SignatureCertificate from "../registry/certificate/SignatureCertificate";
import CodeInput from "./CodeInput";

interface Props {
    onFileDrop: (files: File[]) => void;
    setSelectedFile: (file: File | null) => void;
}

interface SignatureModel {
    uuid: String,
    signatureDate: number,
    userEmail: String,
    ipAddress: String,
    contractParty: ContractParty
}

interface OriginalDocumentToDownload {
    contractURI: string | null,
    displayContractUri: string | null,
    fileName: string | null,
}

interface PrintableDocumentToDownload {
    contractURI: string | Blob | null,
    displayContractUri: string | Blob | null,
    fileName: string | null,
}

const Dropzone: React.FC<Props> = ({ onFileDrop, setSelectedFile }) => {
    const dropzoneBoxTheme: SxProps<Theme> = {
        ['@media(max-width:1000px)']: {
            padding: "20px 40px"
        },
        display: "flex", alignItems: "center", justifyContent: "center", padding: "60px 160px"
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        onFileDrop(acceptedFiles);
        setSelectedFile(acceptedFiles[0] || null);
    }, [onFileDrop, setSelectedFile]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        < div {...getRootProps()}>
            <input {...getInputProps()} />
            <Box sx={dropzoneBoxTheme}>
                <UploadFile sx={{ fontSize: "64px", margin: "10px" }} />
                <p>Arraste o Documento Original do seu contrato para cá ou faça o <Typography sx={{ display: "inline" }} color={"primary"}>upload do arquivo</Typography>.</p>
            </Box>
        </div >
    );
};

const ValidadeYourContract = (): JSX.Element => {
    //States
    const handleFileDrop = (files: File[]) => { };

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [pdfHashx16, setPdfHashx16] = useState('');
    const [contract, setContract] = useState<IssuedDigitalContract>();

    const [displayShowMore, setDisplayShowMore] = useState('none');
    const [displayShowMoreButton, setDisplayShowMoreButton] = useState('flex');
    const [displayShowLessButton, setDisplayShowLessButton] = useState('none');

    const [hasContractInHandsDisplay, setHasContractInHandsDisplay] = useState('none');
    const [haventContractInHandsDisplay, setHaventContractInHandsDisplay] = useState('none');

    const [loading, setLoading] = useState(false);

    const [contractFound, setContractFound] = useState<IssuedDigitalContract>();

    const [searchParams] = useSearchParams();
    const documentHash = searchParams.get('documentHash');

    const [downloadModal, setDownloadModal] = useState(false);
    const [enteringPin, setEnteringPin] = useState(false);

    const [originalContract, setOriginalContract] = useState<OriginalDocumentToDownload>({contractURI: null, displayContractUri: null, fileName: null});
    const [printableContract, setPrintableContract] = useState<PrintableDocumentToDownload>({contractURI: null, displayContractUri: null, fileName: null});

    const [tab, setTab] = useState("0");

    //Context props
    const notification = useAppDispatch();
    const isMobile = useMediaQuery("(max-width:800px)")

    //Effects
    /** Get the hash of the file */
    useEffect(() => {
        getHashFile();
    }, [selectedFile])

    /** With the hash of the pdf, try to find the issued digital contract using the hash*/
    useEffect(() => {
        if (pdfHashx16 != null && pdfHashx16 != '' && pdfHashx16) {
            try {
                findIssuedDigitalContractByHash(pdfHashx16);
            } catch { }
        }
    }, [pdfHashx16])

    useEffect(() => {
        setContractFound(contract);
        getOriginalDocumentURI();
    }, [contract])

    useEffect(()=> {
        getPrintableDocumentURI();
    }, [originalContract])

    useEffect(() => {
        if (documentHash && documentHash.length === 64) {
            setPdfHashx16(documentHash)
        }
    }, [])

    //Themes
    const mainTheme: SxProps<Theme> = {
        ['@media(max-width:1000px)']: {
            padding: "16px"
        },
        marginTop: "10px", padding: "12px 256px"
    }
    const validationBoxTheme: SxProps<Theme> = {
        ['@media(max-width:1000px)']: {
            padding: "30px 0px", width: "100%", margin: 0, mb: 2
        },
        padding: "20px 80px", borderRadius: "20px", boxShadow: 3, margin: 2, height: "100%"
    }

    const centralizedItemsInsideBoxTheme: SxProps<Theme> = {
        display: "block", textAlign: "center", margin: "10px 0px"
    }
    const logoVerificationTheme: SxProps<Theme> = {
        fontSize: "72px"
    }
    const showMoreTheme: SxProps<Theme> = {
        display: displayShowMore, padding: "0px 10px", mt: "20px"
    }
    const moreDetailsBoxTheme: SxProps<Theme> = {
        display: "flex", mb: "10px"
    }
    const moreDetailsTypographyDescriptionTheme: SxProps<Theme> = {
        ml: "10px",
    }

    //Functions
    const handleCloseDownloadModal = () => {
        setDownloadModal(false);
        setEnteringPin(false);
    }

    // Generate the Contract with the Watermark Signature PDF
    async function generateSignatureCertificatePDFContentBlob() {
        if (!contract || !originalContract.contractURI) return; 

        const originalDocumentBlob = await axios.get(`${originalContract.contractURI}`, {
            responseType: "blob"
        }).then(response => {
            const downloadedFile = response.data as Blob;
            return downloadedFile;
        });
        const signatureCertificatePDFContent = <SignatureCertificate source={contract} originalDocumentDownloadLink={originalContract.contractURI} />
        const signatureCertificatePDFContentBlob = await pdf(signatureCertificatePDFContent).toBlob()
            .then(blob => {
                return blob;
            })
            .catch((error) => {
                return error;
            });

        const watermarkAndSignatureCertificateBlob = await createWatermarkVersion(originalDocumentBlob, signatureCertificatePDFContentBlob, contract);

        setPrintableContract({
            contractURI: watermarkAndSignatureCertificateBlob,
            fileName: `${contract.id}-Timbrado.pdf`,
            displayContractUri: watermarkAndSignatureCertificateBlob
        })
    }

    async function getDigitalContractAuthorizationDownloadLinkUsingPin(pin: string) {
        if (contract) {
            try {
                const downloadURI = await (await ContractsService.generateDownloadLinkUsingPin(pin, contract.id)).uri;

                // access the URI provided and create the blob of the file
                axios.get(`${downloadURI}`, {
                    responseType: "blob"
                }).then(downloadedFile => {
                    // create file link in browser's memory
                    const href = URL.createObjectURL(downloadedFile.data);
                    const filename = contract.documentType === IssuedContractDocumentType.PDF ? `${contract.id}.pdf` : `${contract.id}.docx`

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', filename); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    handleCloseDownloadModal();
                    notification(show({
                        type: 'success',
                        message: 'Documento baixado com sucesso.'
                    }));
                })
            } catch (e) {
                const error = new ErrorWrapper(e)
                if (error.httpStatus === 404) {
                    notification(show({
                        type: "error",
                        message: "PIN incorreto. Ele pode ter expirado ou ter sido deletado pela organização"
                    }))
                }

                if (error.httpStatus === 403) {
                    if (error.message.includes("another contract pin")) {
                        notification(show({
                            type: "error",
                            message: "Não é possivel baixar este contrato com o PIN fornecido"
                        }))
                    } else {
                        notification(show({
                            type: "error",
                            message: "Algo deu errado. Verifique o PIN inserido e tente novamente"
                        }))
                    }
                }
            }
        }
    }

    function getOriginalDocumentURI() {
        if (!contract) return;
        setLoading(true);
        ContractsService.downloadDocumentByHash(contract.digitalTrustData.documentHashAlgorithm, contract.digitalTrustData.documentHashValue)
            .then(response => {
                if (contract.documentType === "WORD") {
                    setOriginalContract(prev => ({...prev, fileName:`${contract.id}.docx`, displayContractUri: `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(response.uri)}`}));
                } else {
                    setOriginalContract(prev => ({...prev, displayContractUri: response.uri, fileName: `${contract.id}.pdf`}));
                }
                setOriginalContract(prev => ({...prev, contractURI: response.uri}));
            })
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({ type: "error", message: err.message }))
            })
            .finally(() => setLoading(false));
    }

    function getPrintableDocumentURI(){
        if (!contract) return;

        if(contract.documentType === "WORD"){
            setLoading(true);
            ContractsService.downloadPrintableDocumentByHash(contract.digitalTrustData.documentHashAlgorithm, contract.digitalTrustData.documentHashValue)
                .then(response => {
                    setPrintableContract({
                        displayContractUri: `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(response.uri)}`,
                        fileName: `${contract.id}-Timbrado.docx`,
                        contractURI: response.uri});
                }) 
                .catch(e => {
                    const err = new ErrorWrapper(e);
                    notification(show({ type: "error", message: err.message }))
                })
                .finally(() => setLoading(false));
        } else if (contract.documentType === "PDF"){
            generateSignatureCertificatePDFContentBlob();
        }
    }

    /** Show more or less details on the contract or hash informations */
    const handleShowMore = () => {
        setDisplayShowMore('block');
        setDisplayShowMoreButton('none');
        setDisplayShowLessButton('flex');
    }
    const handleShowLess = () => {
        setDisplayShowMore('none');
        setDisplayShowMoreButton('flex');
        setDisplayShowLessButton('none');
    }

    const handleShowHashInHandDocuments = () => {
        if (hasContractInHandsDisplay === 'none') {
            setHasContractInHandsDisplay('block');
        }
        else if (hasContractInHandsDisplay === 'block') {
            setHasContractInHandsDisplay('none');
        }
    }
    const handleShowHaventContractInHandDocuments = () => {
        if (haventContractInHandsDisplay === 'none') {
            setHaventContractInHandsDisplay('block');
        }
        else if (haventContractInHandsDisplay === 'block') {
            setHaventContractInHandsDisplay('none');
        }
    }

    /** Restart the validation to the user can verify other contract */
    const restartValidation = () => {
        setContract(undefined);
        setSelectedFile(null);
        setPdfHashx16('');
        setDisplayShowMore('none');
        setDisplayShowMoreButton('flex');
        setDisplayShowLessButton('none');
    }

    /** Generate the hash of the file*/
    const getHashFile = () => {
        const reader = new FileReader();
        reader.onload = () => {
            const pdfBuffer = Buffer.from(reader.result as ArrayBuffer);
            const hashx16 = sha256(pdfBuffer);

            if (hashx16 != null && hashx16 != '' && hashx16) {
                setPdfHashx16(hashx16);
            }
        };
        if (selectedFile) {
            reader.readAsArrayBuffer(selectedFile);
        }
    }

    /** Find the Issued Digital Contract by hash */
    async function findIssuedDigitalContractByHash(hash: string) {
        setLoading(true)
        try {
            const contract = await ContractsService.findIssuedDigitalContractByHash(hash);
            if (contract) {
                setContract(contract)
            }
            setLoading(false)
        }
        catch {
            setLoading(false)
        }
    }

    /** Function to map the signatures of the contract */
    function SignatureMap(signature: SignatureModel): JSX.Element {
        return (
            <Box key={signature.signatureDate} sx={{ padding: "0px 60px" }}>
                <Box sx={moreDetailsBoxTheme}>
                    <Typography sx={moreDetailsTypographyDescriptionTheme}><b>Participante:</b></Typography>
                    <Typography sx={moreDetailsTypographyDescriptionTheme}>{signature.contractParty.role}</Typography>
                </Box>

                <Box sx={moreDetailsBoxTheme}>
                    <Typography sx={moreDetailsTypographyDescriptionTheme}><b>Email do usuário:</b></Typography>
                    <Typography sx={moreDetailsTypographyDescriptionTheme}>{signature.userEmail}</Typography>
                </Box>

                <Box sx={moreDetailsBoxTheme}>
                    <Typography sx={moreDetailsTypographyDescriptionTheme}><b>Data de assinatura:</b></Typography>
                    <Typography sx={moreDetailsTypographyDescriptionTheme}>{new Date(signature.signatureDate).toLocaleString()}</Typography>
                </Box>

                <Box sx={moreDetailsBoxTheme}>
                    <Typography sx={moreDetailsTypographyDescriptionTheme}><b>IP Address:</b></Typography>
                    <Typography sx={moreDetailsTypographyDescriptionTheme}>{signature.ipAddress}</Typography>
                </Box>
                <p></p>
                <Divider />
            </Box>
        )
    }

    const pinDialogTheme: SxProps<Theme> = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        height: "100%",
    }

    const OriginalDocumentTab = () => {
        return (
            <>
                <Box sx={{ margin: 2 }}>
                    <Button variant="contained" onClick={() => {
                        if (!originalContract.contractURI) return;
                        axios.get(originalContract.contractURI, {
                            responseType: "blob"
                        }).then(downloadedFile => {
                            // create file link in browser's memory
                            const href = URL.createObjectURL(downloadedFile.data);

                            // create "a" HTML element with href to file & click
                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', originalContract.fileName ? originalContract.fileName : "arquivo"); //or any other extension
                            document.body.appendChild(link);
                            link.click();

                            // clean up "a" element & remove ObjectURL
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        })
                    }}>Baixar contrato original</Button>
                </Box>
                <Box sx={validationBoxTheme}>
                    {
                        originalContract.displayContractUri
                        ?
                        <iframe
                            src={`${originalContract.displayContractUri}#toolbar=0&navpanes=0&scrollbar=0`}
                            width="100%"
                            height="875"
                        >
                        </iframe>
                        :
                        <CircularProgress />
                    }
                </Box>
            </>
        )
    }

    const LetterheadDocumentTab = () => {
        return (
            <>
                <Box sx={{ margin: 2 }}>
                    <Button variant="contained" onClick={() => {
                        if (!printableContract.contractURI) return;

                        if (printableContract.contractURI instanceof Blob) {
                            const href = URL.createObjectURL(printableContract.contractURI);

                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', printableContract.fileName || "arquivo");
                            document.body.appendChild(link);
                            link.click();
                    
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        }
                        else {
                            axios.get(printableContract.contractURI, {
                                responseType: "blob"
                            }).then(downloadedFile => {
                                // create file link in browser's memory
                                const href = URL.createObjectURL(downloadedFile.data);

                                // create "a" HTML element with href to file & click
                                const link = document.createElement('a');
                                link.href = href;
                                link.setAttribute('download', printableContract.fileName ? printableContract.fileName : "arquivo"); //or any other extension
                                document.body.appendChild(link);
                                link.click();

                                // clean up "a" element & remove ObjectURL
                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                            })
                        }
                    }}>Baixar contrato timbrado</Button>
                </Box>
                <Box sx={validationBoxTheme}>
                    {
                        printableContract.displayContractUri
                        ?
                            contract && contract.documentType === "WORD"
                            ?
                            <iframe
                                src={`${printableContract.displayContractUri}#toolbar=0&navpanes=0&scrollbar=0`}
                                width="100%"
                                height="875"
                            >
                            </iframe>
                            :
                            <embed
                                src={`${URL.createObjectURL(printableContract.displayContractUri as Blob)}#toolbar=0&navpanes=0&scrollbar=0`}
                                type="application/pdf"
                                width="100%"
                                height="875"
                                >
                            </embed>
                        :
                        <CircularProgress />
                    }
                </Box>
            </>
        )
    }

    /** Component for valid contracts */
    const ValidComponent = () => {

        const [openDownloadMenu, setOpenDownloadMenu] = useState(false)
        const [downloadMenuAE, setDownloadMenuAE] = useState<HTMLElement | null>()

        function handleOpenDownloadMenu(e: React.MouseEvent<HTMLElement>) {
            setDownloadMenuAE(e.currentTarget)
            setOpenDownloadMenu(true)
        }

        function handleDownloadAsContractParty() {
            if (!contract) return;
            window.open(`${CurrentContext.authWebappUrl}/login?service=contracts&redirectUrl=/auth/service-response?srid=${contract.id}_srt=issued-contract`)
        }

        if (contract) {

            return (

                <Box sx={{ wordBreak: "break-word" }}>
                    < Button onClick={restartValidation} >
                        <ArrowBack sx={{ fontSize: "18px", mr: "10px" }} /> Validar outro contrato
                    </Button >
                    <Box sx={validationBoxTheme}>
                        {/*Valid Digital Contract*/}
                        <Box sx={centralizedItemsInsideBoxTheme}>
                            <Verified sx={logoVerificationTheme} color="success" />
                            <Typography sx={{ textAlign: "center", fontSize: "24px", fontWeight: "600", mb: "16px" }}>Contrato válido</Typography>
                        </Box>
                        <Box sx={{ padding: "10px 60px" }}>
                            <Typography sx={{ textAlign: "center" }}>Encontramos seu contrato e garantimos que ele foi emitido pela plataforma <b>Cartorizi</b>.</Typography>
                        </Box>
                    </Box>

                    <TabContext value={tab}>
                        <TabList variant="scrollable" scrollButtons="auto" onChange={(e, selectedTab) => { setTab(selectedTab) }}>
                            <Tab label="Documento Original" value="0" />
                            <Tab label="Documento Timbrado" value="1" />
                        </TabList>

                        {/* Original */}
                        <TabPanel value="0">
                            <OriginalDocumentTab />
                        </TabPanel>
                        
                        {/* Letterhead */}
                        <TabPanel value="1">
                            <LetterheadDocumentTab />
                        </TabPanel>
                    </TabContext>

                    <Dialog open={downloadModal} onClose={handleCloseDownloadModal} fullScreen={isMobile}>
                        <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight={600}>
                                Baixar contrato utilizando PIN
                            </Typography>
                            <IconButton onClick={handleCloseDownloadModal}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Box sx={isMobile ? pinDialogTheme : { p: 2 }}>
                                <Box>
                                    {
                                        enteringPin
                                            ?
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CodeInput totalDigits={6} onComplete={getDigitalContractAuthorizationDownloadLinkUsingPin} isMobile={isMobile} />
                                            </Box>
                                            :
                                            <>
                                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                    Para baixar esse contrato é necessário inserir o PIN de autorização da organização.
                                                </Typography>
                                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                    Solicite o PIN de acesso a organização ou insira o PIN clicando no botão abaixo.
                                                </Typography>
                                                <Box sx={{ display: "flex", justifyContent: "end" }}>
                                                    <Button onClick={() => setEnteringPin(true)}>Inserir o PIN</Button>
                                                </Box>
                                            </>
                                    }
                                </Box>
                            </Box>
                        </DialogContent>
                    </Dialog >
                </Box >
            )
        }
        else {
            return (<></>)
        }
    }

    /** Component for invalid contracts */
    const InvalidComponent = () => {
        return (

            <Box sx={{ wordBreak: "break-word" }}>
                <Button onClick={restartValidation}>
                    <ArrowBack sx={{ fontSize: "18px", mr: "10px" }} /> Validar outro contrato
                </Button>
                <Box sx={validationBoxTheme}>
                    {/*Invalid Digital Contract*/}
                    <Box>
                        <Box sx={centralizedItemsInsideBoxTheme}>
                            <Warning sx={logoVerificationTheme} color="error" />
                        </Box>
                        <Box sx={{ padding: "10px 60px" }}>
                            <Typography sx={{ textAlign: "center", fontSize: "24px", fontWeight: "600", mb: "16px" }}>Contrato inválido</Typography>
                            <Typography sx={{ textAlign: "center" }}>Esse contrato não foi emitido pela plataforma <b>Cartorizi</b>.</Typography>
                        </Box>


                        <Box sx={[centralizedItemsInsideBoxTheme, { alignItems: "center", display: displayShowMoreButton }]}>
                            <Button onClick={handleShowMore}>
                                <ArrowDropDown /> Mais detalhes
                            </Button>
                        </Box>

                        <Box sx={showMoreTheme}>
                            <Box sx={[moreDetailsBoxTheme, { alignItems: "center" }]}>
                                <Typography><b>Código hash:</b></Typography>
                                <Typography sx={[moreDetailsTypographyDescriptionTheme, { fontSize: "15px", mb: "0px" }]}>{pdfHashx16}</Typography>
                            </Box>

                            <Box sx={[centralizedItemsInsideBoxTheme, { alignItems: "center", display: displayShowLessButton }]}>
                                <Button onClick={handleShowLess}>
                                    <ArrowDropUp /> Mostrar menos
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box >
                <Box sx={validationBoxTheme}>
                    <Typography sx={{ mt: 2, mb: 4, fontSize: "24px" }}>
                        <b>Como validar um documento original</b>
                    </Typography>

                    <Box>
                        <Button sx={{ mb: 2 }} onClick={() => handleShowHashInHandDocuments()}>
                            <Typography>
                                <b>Caso tenha o documento em mãos</b>
                            </Typography>
                        </Button>

                        <Box sx={{ display: hasContractInHandsDisplay }}>
                            <Typography sx={{ fontSize: "14px", mb: 2 }}>Na última página do seu documento, escaneie o <b>QR Code</b> do Certificado de Assinatura. Exemplo na imagem a seguir:</Typography>
                            <Box sx={{ boxShadow: 3, mb: 4, display: "flex", justifyContent: "center" }}>
                                <img style={{ width: "60%" }} src="/images/assets/validate/signature-certificate-example.png" />
                            </Box>
                        </Box>
                    </Box>

                    <Divider sx={{ width: "100%" }} />

                    <Box sx={{ mt: 4, mb: 2 }}>
                        <Button sx={{ mb: 2 }} onClick={() => handleShowHaventContractInHandDocuments()}>
                            <Typography>
                                <b>Caso não tenha o documento em mãos</b>
                            </Typography>
                        </Button>

                        <Box sx={{ display: haventContractInHandsDisplay }}>
                            <Typography sx={{ fontSize: "14px" }}>- Acesse <b>https://app.cartorizi.com/contracts</b>.</Typography>
                            <Typography sx={{ fontSize: "14px", display: "flex", alignItems: "center", mb: 2 }}>- Encontre o contrato desejado e abra o menu  <MenuIcon sx={{ mr: "2px", ml: "2px" }} /> e selecione a opção <FindInPage sx={{ ml: "2px", mr: "2px", fontSize: "16px" }} /><b>Ver contrato</b>.</Typography>
                            <Box sx={{ boxShadow: 3, mb: 4, display: "flex", justifyContent: "center" }}>
                                <img style={{ width: "60%" }} src="/images/assets/validate/see-contract.png" />
                            </Box>
                            <Typography sx={{ fontSize: "14px", mb: 2 }}>- Na aba de <b>Visualizar contrato</b> clique no botão <b>Baixar PDF do contrato</b>.</Typography>
                            <Box sx={{ boxShadow: 3, mb: 4, display: "flex", justifyContent: "center" }}>
                                <img style={{ width: "60%", marginBottom: 4 }} src="/images/assets/validate/pdf-download.png" />
                            </Box>
                            <Typography sx={{ fontSize: "14px" }}>- Faça o upload do contrato original novamente no site <b>https://app.cartorizi.com/validation</b>.</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box >
        )
    }

    /** Loading component */
    const LoadingComponent = () => {
        return (
            <Box sx={validationBoxTheme}>
                <Box sx={{ display: "flex", justifyContent: "center", margin: "10px 0px" }}>
                    <CircularProgress />
                </Box>
                <Typography sx={{ textAlign: "center", mb: "30px" }}>Verificando se o contrato é valido...</Typography>
            </Box>
        )
    }


    /** Verify if the contract is valid and return the respective component */
    const VerifyIfTheContractIsValid = () => {
        return (
            !pdfHashx16
                ?
                < Box sx={[validationBoxTheme, { paddingBottom: "30px" }]}>
                    {/*Dropzone Box */}
                    <Typography sx={{ textAlign: "center", mb: "20px" }}>Valide seu <b>Contrato Digital</b> para verificar se foi emitido pela plataforma <b>Cartorizi</b>.</Typography>
                    <Box sx={{ padding: "0px 20px" }}>
                        <Box sx={{ border: "1px dashed", backgroundColor: "#F0F0F0", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", borderRadius: "20px" }}>
                            <Dropzone onFileDrop={handleFileDrop} setSelectedFile={setSelectedFile} />
                        </Box>
                    </Box>
                </Box>
                :
                !!contractFound
                    ?
                    <ValidComponent />
                    :
                    <InvalidComponent />
        )
    }

    return (
        <Box>
            <Box sx={{ width: "100%", height: "64px", backgroundColor: "#64067F", display: "flex", alignItems: "center" }}>
                <Button onClick={() => window.location.replace(`/`)} sx={{ display: "flex", ml: "20px" }}><ArrowBack sx={{ fontSize: "18px", mr: "10px", color: "#fff" }} /><Typography sx={{ color: "#fff" }}>Voltar</Typography></Button>
                <Box sx={{ margin: "auto" }}>
                    <img src="/images/cartorizi_white_logo.svg" alt="Cartorizi" height="32px" style={{ marginRight: "10px" }} />
                </Box>
            </Box>
            <Box sx={mainTheme}>
                {
                    loading
                        ?
                        <LoadingComponent />
                        :
                        <VerifyIfTheContractIsValid />
                }
            </Box >
        </Box >
    )
}

export default ValidadeYourContract;