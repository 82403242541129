import { AccountBalanceWallet, AccountBox, Analytics, AttachMoney, Close, ColorLens, Contacts as ContactsIcon, Create, Description, FiberNew, Groups, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Logout, ManageAccounts, Menu as MenuIcon, Send, Settings, TrendingUp, UploadFile, ViewKanban } from "@mui/icons-material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AppBar, Avatar, Badge, Box, Button, Container, Divider, Drawer, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, Stack, Switch, Toolbar, Tooltip, Typography } from "@mui/material";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { BrowserRouter, Link, NavLink, Navigate, Outlet, Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { CurrentContext } from "./appctx/webappContext";
import GlobalSearchBar from "./components/GlobalResourceSearchBar";
import NotificationsDrawer from "./components/NotificationsDrawer";
import { SivedicoSplashscreen } from "./components/SivedicoSplashscreen";
import Splashscreen from "./components/Splashscreen";
import { UserEditorPreferenceDialog } from "./components/UserEditorPreferenceDialog";
import ViewExternalContractDialog from "./components/external-contracts/ViewExternalContractDialog";
import AuthenticationContext from "./contexts/authentication";
import CacheControl from "./contexts/cache-control";
import { RolesContext } from "./contexts/roles";
import { ServiceResponses } from "./contexts/route-param";
import { ExternalDigitalContract, parseToNormalizedContract } from "./models/contracts";
import { ContractsQuota, Plans } from "./models/organizations";
import { SearchResultIdentification } from "./models/search-result";
import { AccountRoles } from "./models/user";
import { show } from "./redux/features/contract-view-dialog/contract-view-dialog";
import { useAppDispatch } from "./redux/hooks";
import DigitalContractService from "./services/contracts";
import NotificationsService from "./services/notification";
import OrganizationContractsQuotaService from "./services/organization-quota";
import StepsForContractSignatureView from "./standalones/registry/StepsForContractSignatureView";
import ValidadeYourContract from "./standalones/validate/ValidadeYourContract";
import LastSeenNotificationStore from "./store/lastSeenNotification";
import PersonalInfo from "./views/PersonalInfo";
import QuotaInfo from "./views/QuotaInfo";
import Login from "./views/authentication/Login";
import ContactInfo from "./views/contacts/ContactInfo";
import Contacts from "./views/contacts/Contacts";
import ContractsView from "./views/contracts/Contracts";
import ExternalContractsView from "./views/contracts/ExternalContracts";
import CreateAnContract from "./views/contracts/create/CreateAnContract";
import AnalyticsHome from "./views/contracts/finance/AnalyticsHome";
import AnalyticsPerformance from "./views/contracts/finance/AnalyticsPerformance";
import ContractsFinanceView from "./views/contracts/finance/ContractsFinance";
import ContractsOrganizationDashboard from "./views/contracts/organization-dashboard/ContractsOrganizationDashboard";
import UsersAndTeams from "./views/contracts/users-and-teams/UsersAndTeams";
import Personalization from "./views/customize/Personalization";
import Home from "./views/home/Home";
import SignedContracts from "./views/home/SignedContracts";
import NotFound from "./views/not-found/NotFound";
import NotificationCenter from "./views/notifications/NotificationCenter";

//Util tab enum 
enum Tabs { USERS = 'users', TEAMS = 'teams', NONE = 'none', ME = `me`, CUSTOMIZE = 'customize', QUOTAS = 'cotas', NOTIFICATION = 'notification-center', SETTINGS = `settings` }

const LEFT_MENU_MAX_WIDTH = 280;

export interface UserPreferenceEditorCache {
  version: string,
  lastChange: string
}

export interface UserEditorPreferenceDialogProps {
  userName: string,
  changeUserEditorPreference: boolean,
  userPreferenceEditorCache: boolean
}

const App = (): JSX.Element => {
  //Context props
  const userRoles = useContext(RolesContext)
  const authUser = useContext(AuthenticationContext);

  const username = authUser.user() ? authUser.user().account.preferredName : "";

  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false)
  const [leftMenuMaxWidth, setLeftMenuMaxWidth] = useState(280);

  const [userQuota, setUserQuota] = useState<ContractsQuota>({
    customerContractsQuota: {
      contractAttachmentsEnabled: false,
      contractsFieldsAutomationEnabled: false,
      contractsIssuedPerMonth: 0,
      financialAnalyticsModuleEnabled: false,
      maxStorageBytes: 0,
      maxSubusers: 0,
      maxTemplateCount: 0,
      plan: Plans.NO_QUOTA,
      interfaceThemesCustomizationEnabled: false
    },
    currentStorageConsumptionInBytes: 0,
    currentSubusersCount: 0,
    currentTemplateCount: 0,
    contractsIssuedPerMonth: 0
  })

  // Dialog
  const [newFeatureDialog, setNewFeatureDialog] = useState(false);
  const [userPreferenceEditorCache, setUserPreferenceEditorCache] = useState(false);
  const [userPreferenceExpired, setUserPreferenceExpired] = useState(false);
  const [componentsPreview, setComponentsPreview] = useState<string[]>([]);

  // Effect that will be used to fetch the user quotas
  // useEffect(() => {
  //   OrganizationContractsQuotaService.fetchContractsQuota()
  //   .then(response => {
  //       setUserQuota(response);
  //   })
  // }, []);

  const [loading, setLoading] = useState(false)

  // Effect that will be used to fetch the user quotas
  useEffect(() => {
    fetchOrganizationQuota();
  }, [userIsAuthenticated]);

  const fetchOrganizationQuota = () => {
    if (userIsAuthenticated) {
      OrganizationContractsQuotaService.fetchContractsQuota()
        .then(response => {
          setUserQuota(response);
        })
    }
  }

  useEffect(() => {
    getUsersPreferences();
    saveUserPreference();
  }, [userPreferenceEditorCache, userPreferenceExpired])

  async function saveUserPreference() {
    const uep = localStorage.getItem("user_preferences_cache");
    if (!uep) return;
    await DigitalContractService.saveUserEditorPrefencesMetric(uep);
  }

  function verifyIfUserPreferenceExpired(){
    const uep = localStorage.getItem("user_preferences_cache");
    if(!uep) {
        setUserPreferenceExpired(true);
        return true;
    };

    const uepj = JSON.parse(uep);
    const lastChange = new Date(Number(uepj.lastChange));            

    // Checks if it has been more than 3 days since the user set the option to the old editor.
    // This state will make the dialog show again. 
    if ((Date.now() - lastChange.getTime()) > 3 * 24 * 60 * 60 * 1000) {
      setUserPreferenceExpired(true);
      return true;
    }
    else {
      return false;
    }
  }

  // Get the users preferences in local storage and set in states
  function getUsersPreferences() {
    const uep = localStorage.getItem("user_preferences_cache");
    if (uep) {
      try {
        // Create an object using the JSON in local storage
        const uepj = JSON.parse(uep);

        // Verify if its the new version, otherwise set the user preferences to expired 
        // to show the dialog next time user try to acess it
        if (uepj.version === "new_version") {
          setUserPreferenceEditorCache(true);
          setComponentsPreview(newRoutes);
        }
        else {
          const lastChange = new Date(Number(uepj.lastChange));

          // Checks if it has been more than 3 days since the user set the option to the old editor.
          // This state will make the dialog show again. 
          if ((Date.now() - lastChange.getTime()) > 3 * 24 * 60 * 60 * 1000) {
            setUserPreferenceExpired(true);
          }
          setComponentsPreview(oldRoutes);
        }
      }
      catch (error) {
        console.error("Error to parse json: ", error);
      }
    }
    else {
      setUserPreferenceExpired(true);
    }
  }

  useEffect(() => {
    getUsersPreferences();
  }, [])

  function updateUsersQuota(addedSubUsers: number) {
    setUserQuota({
      ...userQuota,
      currentSubusersCount: userQuota.currentSubusersCount + addedSubUsers
    })
  }

  const newRoutes = [
    "/templates/create",
    "/templates/edit/:templateId",
    "/issue/template/:templateId",
    "/issue/file",
    "/renew-contract/:contractId",
    "/external-contracts/create"
  ]

  const oldRoutes = [
    "/new-template",
    "/templates/:templateId/editing",
    "/templates/:templateId/issue",
    "/issued-contracts/issue-from-file",
    "/issued-contracts/:previousContractId/renew",
    "/external-contracts/upload"
  ]

  //Validate authentication 
  const AppContent = () => {
    const [currentUserSession, setCurrentUserSession] = useState<"authorized" | "forbidden" | "loading">("loading");

    useEffect(() => {

      if (currentUserSession !== "authorized" && currentUserSession !== "forbidden") {
        authUser.updatedUser()
          .then(newAuthUser => {
            const currentUserSession = (newAuthUser.session.stl >= 0) ? "authorized" : "forbidden";
            if (currentUserSession === "authorized") {
              setUserIsAuthenticated(true);
            }
            else {
              authUser.logout(() => { });
            }
            setCurrentUserSession(currentUserSession)
          })
          .catch(error => {
            console.error(error);
            setCurrentUserSession("forbidden");
            authUser.logout(() => { });
          })
      }
    }, []);

    return (
      <>
        {
          userIsAuthenticated || currentUserSession === "authorized"
            ?
            <AuthenticatedContent />
            :
            (currentUserSession === "forbidden")
              ?
              <Login />
              :
              (currentUserSession === "loading")
                ?
                <Splashscreen hasErrors={false} />
                :
                <></>
        }
      </>
    )
  }

  //Authentidated content only
  const AuthenticatedContent = (): JSX.Element => {
    const location = useLocation();

    // this constante will store every view that will automatically hide the static left menu
    const AutoHideMenuViews = [
      "/registry-webapp-iframe",
      "/contracts/finance",
      "/analytics/finance",
      "/analytics/performance",
      "/templates",
      "/issue",
      "/external-contracts/create",
      "/renew-contract",
    ];

    // set a flag that store if the application should hide the static left menu
    const HideStaticLeftMenu = AutoHideMenuViews.find(m => location.pathname.startsWith(m)) !== undefined;

    //States - expanded menu
    const [anchorAuthUserMenu, setAnchorAuthUserMenu] = useState<HTMLElement | null>(null);
    const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.TEAMS);
    const [newNotificationsCount, setNewNotificationsCount] = useState(0);

    //State - retracted menu
    const [openMenu, setOpenMenu] = useState(false);
    const handleDrawerOpen = () => setOpenMenu(true);
    const handleDrawerClose = () => setOpenMenu(false);

    // React Router Search parameters
    const [searchParams, setSearchParams] = useSearchParams();

    //State Notification Prop
    const [openDrawerNotification, setOpenDrawerNotification] = useState(false)

    const [avatarAnchorEl, setAvatarAnchorEl] = useState<HTMLElement | null>(null)
    const [openUserMenu, setOpenUserMenu] = useState(false)

    // Accordion State
    const [expandedConfiguration, setExpandedConfiguration] = useState(false);
    const [expandedContractManagement, setExpandedContractManagement] = useState(false);
    const [expandedAnalytics, setExpandedAnalytics] = useState(false);

    // Boolean States
    const [openExternalContractDialog, setOpenExternalContractDialog] = useState(false);

    // Data States
    const [externalContract, setExternalContract] = useState<ExternalDigitalContract | null>(null)

    const handleExpandedConfiguration = () => {
      setExpandedConfiguration(!expandedConfiguration);
    };

    const handleExpandedContractManagement = () => {
      setExpandedContractManagement(!expandedContractManagement);
    };

    const handleExpandedAnalytics = () => {
      setExpandedAnalytics(!expandedAnalytics);
    };

    function handleDrawerNotificationOpen() {
      setNewNotificationsCount(0);
      setOpenDrawerNotification(true)
    }
    function handleNotificationDrawerOnClose(): void {
      setOpenDrawerNotification(false);
      LastSeenNotificationStore.set();
    }

    useEffect(() => {
      if (authUser.user().session.stl < 0) {
        authUser.logout(() => { })
        window.location.replace(`${window.location.origin}/auth`)
      }
    }, [])

    //Refs
    const userMenuRef = useRef<HTMLDivElement | null>(null);

    //Event handlers
    const handleUserMenuClick = () => setAnchorAuthUserMenu(userMenuRef.current || null);

    // Redux dispatcher
    const dispatch = useAppDispatch();

    const [width, setWidth] = useState(window.innerWidth);

    // Store the leftMenu preference in local storage and when load, set the width with the prefered
    useEffect(() => {
      const leftMenu = localStorage.getItem("left_menu_cache");
      if (leftMenu) setLeftMenuMaxWidth(parseInt(leftMenu));
    }, [])

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    // effect used to fetch data from the 'srt'|'srid' query parameters when the app load
    useEffect(() => {
      //srt = search resource type
      const srt = searchParams.get("srt");
      //srid = search resource id
      const srid = searchParams.get("srid");

      if (!srt || !srid) {
        return;
      }

      // trigger the search resource click function based on the value from 'srt' parameter
      switch (srt) {
        case "editable-contract":
          triggerSearchResultAction({
            resourceId: srid,
            resourceType: "editable-contract"
          });
          break;
        case "issued-contract":
          triggerSearchResultAction({
            resourceId: srid,
            resourceType: "issued-contract"
          });
          break;
        case "digital-contract-template":
          triggerSearchResultAction({
            resourceId: srid,
            resourceType: "digital-contract-template"
          });
          break;
        default:
          console.error("Unkown/unsupported 'srt' paramter identified from query parameters", srt);
          break;
      }
    }, []);

    // effect that check how many new notifications is
    const FETCH_NEW_NOTIFICATIONS_INTERVAL = 120 * 1000;
    useEffect(() => {
      fetchNewNotificationsCount();
      setInterval(() => {
        fetchNewNotificationsCount();
      }, FETCH_NEW_NOTIFICATIONS_INTERVAL);
    }, []);

    function fetchNewNotificationsCount() {
      let lastSeenDate = LastSeenNotificationStore.get();
      if (lastSeenDate) {
        NotificationsService.fetchNotificationsPaginationMetadata(20, lastSeenDate)
          .then(response => {
            setNewNotificationsCount(response.totalRecords);
          })
      }
    }

    // Resize the left menu and store the value in cache
    function resizeLeftMenuMaxWidth() {
      let width = leftMenuMaxWidth === 280 ? 64 : 280;
      setLeftMenuMaxWidth(width);
      localStorage.setItem("left_menu_cache", width.toString())
    }

    async function triggerSearchResultAction(resource: SearchResultIdentification): Promise<void> {

      try {
        switch (resource.resourceType) {
          case "issued-contract":
          case "editable-contract":
          case "contract":
            // try to find in the editable contract
            try {
              let contract = await DigitalContractService.findEditableDigitalContractById(resource.resourceId);
              dispatch(show({
                contract: {
                  normalized: parseToNormalizedContract(contract),
                  source: contract
                },
                visible: true
              }));
              return;
            } catch (error) {
              // ignore because it will try to fetch an issued contract
            }

            // try to find in the issued contract
            try {
              let contract = await DigitalContractService.findIssuedDigitalContractById(resource.resourceId);
              dispatch(show({
                contract: {
                  normalized: parseToNormalizedContract(contract),
                  source: contract
                },
                visible: true
              }));
              return
            } catch (error) {
              // throw error
              throw error;
            }
          case "external-contract":
            try {
              const contract = await DigitalContractService.fetchExternalContractWithId(resource.resourceId)
              setExternalContract(contract);
              setOpenExternalContractDialog(true);
            } catch (e) {

            }
        }
      } catch (error) {
        throw error;
      } finally {
        // remove the query parameters from the URL
        searchParams.delete("srt");
        searchParams.delete("srid");

        const token = searchParams.get(ServiceResponses.TOKEN);

        if (token) {
          // Delete the params from the route
          searchParams.delete(ServiceResponses.TOKEN);
          searchParams.delete(ServiceResponses.ACCOUNT_TYPE)
        }

        setSearchParams(searchParams);
      }
    }

    const configurationMenuItems = [
      {
        label: 'Configurações',
        link: '/settings',
        icon: <ManageAccounts style={{ fontSize: "30px" }} />,
        value: Tabs.SETTINGS,
        click: () => setSelectedTab(Tabs.SETTINGS),
        enabled: () => true
      },
      {
        label: 'Personalizar',
        link: 'settings/organization-customize',
        icon: <ColorLens style={{ fontSize: "30px" }} />,
        value: Tabs.CUSTOMIZE,
        click: (() => setSelectedTab(Tabs.CUSTOMIZE)),
        enabled: () => userRoles.assertIsOrganizationOrAdministratorOr(authUser, AccountRoles.USER_AND_TEAMS_MANAGER)
      },
      {
        label: 'Cotas',
        link: '/organization/quotas',
        icon: <AccountBalanceWallet style={{ fontSize: "30px" }} />,
        value: Tabs.QUOTAS,
        click: () => setSelectedTab(Tabs.QUOTAS),
        enabled: () => userRoles.assertIsOrganizationOrAdministrator(authUser)
      }
    ]

    // Menu item to display new features, if there's no feature in the array, the "Novidades" item will not appear
    const newFeatures = [
      {
        label: 'Novo editor de contratos',
        link: '/contracts/create',
        icon: <Send style={{ fontSize: "30px" }} />,
        value: '',
        endIcon: <FiberNew style={{ fontSize: "20px", color: "#FF00B8" }} />
      }
    ]

    interface NavigationMenuProps {
      staticLeftMenu?: boolean;
    }

    /**
     * Menu used to navigate on the website
     * @returns 
     */
    const NavigationMenu = (props: NavigationMenuProps): JSX.Element => {
      /**
       * Return default props used in mobile menu buttons
       * @returns 
       */
      const buttonProps = (): Record<string, unknown> => {
        return {
          sx: { justifyContent: 'start', fontSize: 15, borderRadius: 0, pl: 3, '&:hover': { bgcolor: '#E7F3FF' } },
          className: 'nh',
          //onClick: () => setOpenMenu(false)
        }
      }

      return (
        <Box display="flex" flexDirection='column' width={1} mt={1}>
          <NotificationsDrawer open={openDrawerNotification} onClose={handleNotificationDrawerOnClose} onOpen={handleDrawerNotificationOpen} onSearchResultAction={triggerSearchResultAction} />
          <Box display="flex" flexDirection='column' sx={{ width: "100%", maxWidth: props.staticLeftMenu ? 320 : `${leftMenuMaxWidth}px`, mb: "64px" }}>
            <Typography mx={2} my={1} variant='caption' color='primary.light'>{leftMenuMaxWidth === 280 || props.staticLeftMenu || props.staticLeftMenu ? "Minha conta" : ""}</Typography>
            <Tooltip placement="right" title="Página Inicial" >
              <Button
                color="info"
                component={NavLink}
                to={"/"}
                {...buttonProps()}>
                <img src="/images/minimalist_logo_info.svg" alt="Home" height="26px" style={{ marginRight: "10px", marginLeft: "-4px" }} />
                {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Página Inicial" : ""}
              </Button>
            </Tooltip>
            <Tooltip placement="right" title="Contratos que assinei">
              <Button
                color="info"
                startIcon={<Create style={{ fontSize: "30px" }} />}
                component={NavLink}
                to={"/contracts/signed"}
                {...buttonProps()}>
                {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Contratos que assinei" : ""}
              </Button>
            </Tooltip>
            <Divider sx={{ my: 1 }} />
            <Typography mx={2} my={1} variant='caption' color='primary.light'>{leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Serviços Cartorizi" : ""}</Typography>
            {loading ?

              Array.from(Array(3).keys()).map(() => (
                <Skeleton variant="text" width={210} height={40} />
              ))

              :
              <>
                <Tooltip placement="right" title="Gestão de contratos">
                  <Button
                    color="info"
                    startIcon={<Description style={{ fontSize: "30px" }} />}
                    onClick={() => handleExpandedContractManagement()}
                    {...buttonProps()}
                  >
                    {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Gestão de contratos" : ""}
                  </Button>
                </Tooltip>
                {
                  expandedContractManagement && (
                    <Box sx={{ display: "flex", flexDirection: "column", pl: leftMenuMaxWidth === 280 || props.staticLeftMenu ? 4 : 0 }}>
                      <Tooltip placement="right" title="Enviar contrato">
                        <Button
                          sx={{ width: "100%" }}
                          color="secondary"
                          startIcon={<Send style={{ fontSize: "30px" }} />}
                          component={NavLink}
                          to={"/contracts/create"}
                          onClick={() => setNewFeatureDialog(verifyIfUserPreferenceExpired())}
                          {...buttonProps()}>
                          {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Enviar contrato" : ""}
                        </Button>
                      </Tooltip>
                      <Tooltip placement="right" title="Contratos">
                        <Button
                          sx={{ width: "100%" }}
                          color="secondary"
                          startIcon={<ViewKanban style={{ fontSize: "30px" }} />}
                          component={NavLink}
                          to={"/contracts"}
                          {...buttonProps()}>
                          {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Contratos" : ""}
                        </Button>
                      </Tooltip>
                      <Tooltip placement="right" title="Contratos Externos">
                        <Button
                          sx={{ width: "100%" }}
                          color="secondary"
                          startIcon={<UploadFile style={{ fontSize: "30px" }} />}
                          component={NavLink}
                          to={"/contracts/external"}
                          {...buttonProps()}>
                          {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Contratos Externos" : ""}
                        </Button>
                      </Tooltip>
                    </Box>
                  )
                }
                <Tooltip placement="right" title="Analytics">
                  <Button
                    color="info"
                    startIcon={<Analytics style={{ fontSize: "30px" }} />}
                    onClick={() => handleExpandedAnalytics()}
                    {...buttonProps()}
                  >
                    {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Analytics" : ""}
                  </Button>
                </Tooltip>
                {
                  expandedAnalytics && (
                    <Box sx={{ display: "flex", flexDirection: "column", pl: leftMenuMaxWidth === 280 || props.staticLeftMenu ? 4 : 0 }}>
                      <Tooltip placement="right" title="Financeiro">
                        <Button
                          sx={{ width: "100%" }}
                          color="secondary"
                          startIcon={<AttachMoney style={{ fontSize: "30px" }} />}
                          component={NavLink}
                          to={"/analytics/finance"}
                          {...buttonProps()}>
                          {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Financeiro" : ""}
                        </Button>
                      </Tooltip>
                      <Tooltip placement="right" title="Performance">
                        <Button
                          sx={{ width: "100%" }}
                          color="secondary"
                          startIcon={<TrendingUp style={{ fontSize: "30px" }} />}
                          component={NavLink}
                          to={"/analytics/performance"}
                          {...buttonProps()}>
                          {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Performance" : ""}
                        </Button>
                      </Tooltip>
                    </Box>
                  )
                }
                <Tooltip placement="right" title="Lista de contatos" >
                  <Button
                    color="info"
                    startIcon={<ContactsIcon style={{ fontSize: "30px" }} />}
                    component={NavLink}
                    to={"/organization/contacts"}
                    {...buttonProps()}
                  >
                    {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Lista de contatos" : ""}
                  </Button>
                </Tooltip>
              </>
            }
            <Divider sx={{ my: 1 }} />
            <Typography mx={2} my={1} variant='caption' color='primary.light'>{leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Configurações" : ""}</Typography>
            <Tooltip placement="right" title="Configurações">
              <Button
                color="info"
                startIcon={<Settings style={{ fontSize: "30px" }} />}
                onClick={() => handleExpandedConfiguration()}
                {...buttonProps()}
              >
                {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Configurações" : ""}
              </Button>
            </Tooltip>
            {expandedConfiguration && (
              <Box sx={{ display: "flex", flexDirection: "column", pl: leftMenuMaxWidth === 280 || props.staticLeftMenu ? 4 : 0 }}>
                {configurationMenuItems.filter(menuItem => menuItem.enabled()).map(((menuItem) => (
                  <Tooltip placement="right" title={menuItem.label}>
                    <Button
                      color="secondary"
                      startIcon={menuItem.icon}
                      key={menuItem.link}
                      onClick={menuItem.click}
                      component={NavLink}
                      to={menuItem.link}
                      sx={{ minWidth: "100%", display: "block", marginBottom: "8px" }} // Adicione essa linha
                      {...buttonProps()}
                    >
                      {leftMenuMaxWidth === 280 || props.staticLeftMenu ? menuItem.label : ""}
                    </Button>
                  </Tooltip>
                )))}
              </Box>
            )}
            <Tooltip placement="right" title="Usuários e Times">
              <Button
                color="info"
                startIcon={<Groups style={{ fontSize: "30px" }} />}
                component={NavLink}
                to={"/settings/organization-users-and-teams"}
                {...buttonProps()}>
                {leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Usuários e Times" : ""}
              </Button>
            </Tooltip>

            {/**Menu item to display new features, if there's no feature in the array, the "Novidades" item will not appear*/}
            {
              (newFeatures.length > 0)
                ?
                <>
                  <Divider sx={{ my: 1 }} />
                  <Typography mx={2} my={1} variant='caption' color='primary.light'>{leftMenuMaxWidth === 280 || props.staticLeftMenu ? "Novidades" : ""}</Typography>
                  {
                    newFeatures.map(nf =>
                      <Tooltip placement="right" title={nf.label}>
                        <Button
                          color="info"
                          startIcon={nf.icon}
                          component={NavLink}
                          to={nf.link}
                          endIcon={nf.endIcon ? nf.endIcon : <></>}
                          onClick={() => setNewFeatureDialog(true)}
                          {...buttonProps()}>
                          {leftMenuMaxWidth === 280 || props.staticLeftMenu ? nf.label : ""}
                        </Button>
                      </Tooltip>
                    )
                  }
                </>
                : <></>
            }
          </Box>
          <Box sx={{ display: "flex", position: "fixed", bottom: 0, justifyContent: "end", width: `${leftMenuMaxWidth}px`, backgroundColor: "#750b87", borderTop: '1px solid rgb(0, 0,0, 0.12)' }}>
            {
              props.staticLeftMenu
                ?
                <></>
                :
                <IconButton color="info" onClick={() => resizeLeftMenuMaxWidth()}>
                  {
                    leftMenuMaxWidth === 280 ?
                      <KeyboardDoubleArrowLeft sx={{ fontSize: "36px" }} />
                      :
                      <Tooltip title="">
                        <KeyboardDoubleArrowRight sx={{ fontSize: "36px" }} />
                      </Tooltip>
                  }
                </IconButton>
            }
          </Box>
          {
            externalContract
              ?
              <ViewExternalContractDialog open={openExternalContractDialog} source={externalContract} onClose={() => setOpenExternalContractDialog(false)} />
              :
              <></>
          }
          <UserEditorPreferenceDialog userName={authUser.user().account.preferredName} open={newFeatureDialog} close={() => setNewFeatureDialog(false)} changeUserEditorPreference={(e) => changeUserEditorPreference(e)} userPreferenceEditorCache={(upec) => setUserPreferenceEditorCache(upec)} />

        </Box >
      )
    }

    function changeUserEditorPreference(newEditor: boolean) {
      let userPreference: UserPreferenceEditorCache = {
        version: newEditor ? "new_version" : "old_version",
        lastChange: Date.now().toString()
      }

      localStorage.setItem("user_preferences_cache", JSON.stringify(userPreference));
    }

    function handleOpenUserMenu(e: React.MouseEvent<HTMLElement>) {
      setAvatarAnchorEl(e.currentTarget)
      setOpenUserMenu(true)
    }

    /**
    * Open a new tab with Klaus Accounts webapp  
    */
    const goToAccounts = () => {
      window.open(`${CurrentContext.authWebappUrl}/login?service=accounts&requiredEmail=${authUser.user().account.email}`)
    }

    /**
    * Open a new tab with Klaus Accounts webapp to update selected user  
    */
    const goToProfile = () => {
      window.open(`${CurrentContext.authWebappUrl}/login?service=accounts&requiredEmail=${authUser.user().account.email}&redirectUrl=/my-profile`)
    }

    return (
      <Container maxWidth={false} disableGutters>
        <AppBar id="main-app-bar" position='fixed' sx={{ justifyContent: "space-between", alignItems: { xs: 'start' }, bgcolor: { xs: 'primary' } }}>
          {/* MOBILE MENU */}
          <Toolbar
            sx={{
              width: "100%",
              justifyContent: "space-between",
            }}>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerOpen}
              sx={{ mr: 2, display: { sm: 'block', md: (HideStaticLeftMenu) ? 'unset' : 'none' } }}
            >
              <MenuIcon />
            </IconButton>

            <Link to="/">
              {
                width <= 500
                  ?
                  <img src="/images/minimalist_logo.svg" alt="Logo" height="32px" />
                  :
                  <img src="/images/logo_w_48.png" alt="Logo" height="32px" />
              }
            </Link>

            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: 'none', sm: 'block', color: '#fff' }
              }}
            >
            </Typography>

            {/* Search bar  */}
            <GlobalSearchBar onResourceClick={triggerSearchResultAction} />
            {/* Notification Button */}
            <Box sx={{ margin: "0 1.5rem" }}>
              {(['right'] as const).map((anchor) => (
                <Fragment key={anchor}>
                  <Badge badgeContent={newNotificationsCount} color="error">
                    <IconButton
                      sx={{
                        padding: "0px",
                        color: "#fff",
                      }}
                      onClick={handleDrawerNotificationOpen}
                    >
                      <NotificationsIcon />
                    </IconButton>
                  </Badge>
                </Fragment>
              ))}
            </Box>
            {/* User profile */}
            <Box sx={{ marginRight: "2.5rem" }}>
              <Avatar sx={
                {
                  width: "40px",
                  height: "40px",
                  '&:hover': { cursor: "pointer" },
                  border: "2px solid rgba(0, 0, 0, 0.75)",
                  backgroundColor: (theme) => theme.palette.secondary.main
                }
              }
                onClick={(e) => handleOpenUserMenu(e)}
              >
                {
                  authUser.user().account.hasAvatar
                    ?
                    <img
                      src={
                        `${CurrentContext.accountsCdn.server}/${authUser.user().account.uuid}?cache-tmstp=${CacheControl.avatarCdn()}`
                      }
                      alt="User Avatar"
                      style={{ height: 48, width: 48, objectFit: 'cover', borderRadius: '50%' }}
                    />
                    :
                    <Typography variant="h5" fontSize={"18px"}>{(authUser.user().account.preferredName.length >= 2) ? authUser.user().account.preferredName.substring(0, 2).toLocaleUpperCase() : "PP"}</Typography>
                }
              </Avatar>
            </Box>
            <Menu
              anchorEl={avatarAnchorEl}
              open={openUserMenu}
              onClose={() => {
                setAvatarAnchorEl(null)
                setOpenUserMenu(false)
              }}
              onClick={() => {
                setAvatarAnchorEl(null)
                setOpenUserMenu(false)
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }} m={2}>
                <Box>
                  <Avatar sx={
                    {
                      width: "64px",
                      height: "64px",
                      '&:hover': { cursor: "pointer" },
                      border: "2px solid rgba(0, 0, 0, 0.75)",
                      backgroundColor: (theme) => theme.palette.secondary.main
                    }
                  }
                    onClick={(e) => handleOpenUserMenu(e)}
                  >
                    {
                      authUser.user().account.hasAvatar
                        ?
                        <img
                          src={
                            `${CurrentContext.accountsCdn.server}/${authUser.user().account.uuid}?cache-tmstp=${CacheControl.avatarCdn()}`
                          }
                          alt="User Avatar"
                          style={{ height: 64, width: 64, objectFit: 'cover', borderRadius: '50%' }}
                        />
                        :
                        <Typography variant="h4" fontSize={"28px"}>{authUser.user().account.preferredName.substring(0, 2).toLocaleUpperCase()}</Typography>
                    }
                  </Avatar>
                </Box>
                <Box mx={1.5}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {
                      authUser.user().account.preferredName.length > 12
                        ?
                        <Tooltip placement="right" title={authUser.user().account.preferredName}>
                          <Typography>
                            {`${authUser.user().account.preferredName.substring(0, 12)}...`}
                          </Typography>
                        </Tooltip>
                        :
                        <Typography>{authUser.user().account.preferredName}</Typography>
                    }
                    {
                      authUser.user().account.fullname.length > 12
                        ?
                        <Tooltip title={authUser.user().account.fullname}>
                          <Typography variant="subtitle2" color={"#ccc"} ml={1}>
                            {`${authUser.user().account.fullname.substring(0, 12)}...`}
                          </Typography>
                        </Tooltip>
                        :
                        <Typography variant="subtitle2" color={"#ccc"} ml={1}>{authUser.user().account.fullname}</Typography>
                    }
                  </Box>
                  {
                    authUser.user().account.email.length > 24
                      ?
                      <Tooltip title={authUser.user().account.email}>
                        <Typography>
                          {`${authUser.user().account.email.substring(0, 24)}...`}
                        </Typography>
                      </Tooltip>
                      :
                      <Typography>{authUser.user().account.email}</Typography>
                  }
                </Box>
              </Box>
              <Divider sx={{ mt: 1.5 }} />
              <Box sx={{ p: "6px 8px", display: "flex", alignItems: "center" }}>
                <Switch
                  checked={userPreferenceEditorCache}
                  value={userPreferenceEditorCache}
                  onChange={() => {
                    changeUserEditorPreference(!userPreferenceEditorCache);
                    setUserPreferenceEditorCache(!userPreferenceEditorCache)
                  }} />
                <Typography>Novo editor</Typography>
              </Box>
              <Divider sx={{ mb: 1.5 }} />
              <MenuItem onClick={goToAccounts}>
                <ListItemIcon><ManageAccounts /> </ListItemIcon>
                <ListItemText>Ir para Klaus Accounts</ListItemText>
              </MenuItem>
              <MenuItem onClick={goToProfile}>
                <ListItemIcon><AccountBox /> </ListItemIcon>
                <ListItemText>Meu Perfil</ListItemText>
              </MenuItem>
              <MenuItem sx={{ p: 0 }} >
                <Button
                  sx={{ width: "100%", height: "100%", p: "6px 16px" }}
                  component={NavLink}
                  to={'/auth'}
                  onClick={() => {
                    authUser.logout(() => { });
                  }}
                >
                  <ListItemIcon><Logout /> </ListItemIcon>
                  <ListItemText>Sair</ListItemText>
                </Button>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        {/* MOBILE MENU DRAWER - small screen */}
        <Drawer
          sx={{
            '& .MuiDrawer-paper': {
              maxWidth: 320,
              minWidth: 200,
              boxSizing: 'border-box',
              backgroundColor: "primary.main"
            },
          }}
          anchor="left"
          open={openMenu}
          onClose={() => setOpenMenu(false)}
        >
          <Box textAlign='end' p={1}>
            <IconButton onClick={() => setOpenMenu(false)}>
              <Close sx={{ fontSize: 20, color: '#DDE9E9' }} />
            </IconButton>
          </Box>
          <NavigationMenu staticLeftMenu={true} />
        </Drawer>
        <Stack
          direction='row'
          sx={{
            minHeight: "calc(100vh)",
            maxHeight: "100vh"
          }}>
          {/** left menu */}
          <Box sx={{
            marginTop: '64px',
            display: (HideStaticLeftMenu) ? "none" : { xs: 'none', md: 'block' },
            maxWidth: `${leftMenuMaxWidth}px`,
            backgroundColor: "#750B87",
            maxHeight: "100vh",
            width: "100%",
            overflow: "auto"
          }}>
            <NavigationMenu />
          </Box>
          {/** Page content */}
          <Box sx={{
            marginTop: '64px',
            minWidth: (HideStaticLeftMenu) ? "calc(100vw - 20px)" : `calc(100vw - ${leftMenuMaxWidth + 20}px)`,
            width: "100%",
            maxWidth: "100vw",
            maxHeight: "100vh",
            overflow: "auto"
          }}>
            <Outlet />
          </Box>
        </Stack>
      </Container >
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        {/*Public routes*/}
        <Route path="/auth" element={<Login />} />
        <Route path="/auth/service-response" element={<Login />} />
        <Route path="/validation" element={<ValidadeYourContract />} />

        {/* Authenticated routes */}
        <Route element={<AppContent />}>
          <Route path="/" element={<Home quota={userQuota} />} />
          <Route path="/contracts/signed" element={<SignedContracts />} />
          <Route path="/settings" element={<PersonalInfo />} />
          <Route path="/contracts/create"
            element={
              <CreateAnContract
                currentTemplateCount={userQuota.currentTemplateCount}
                maxTemplateCount={userQuota.customerContractsQuota.maxTemplateCount}
              />
            }
          />
          <Route path="/analytics/finance"
            element={
              <ContractsFinanceView
                financialModuleEnabled={userQuota.customerContractsQuota.financialAnalyticsModuleEnabled}
              />
            }
          />
          <Route path="/analytics/performance"
            element={
              <AnalyticsPerformance financialModuleEnabled={userQuota.customerContractsQuota.financialAnalyticsModuleEnabled} />
            }
          />
          <Route path="/analytics" element={<AnalyticsHome financialModuleEnabled={userQuota.customerContractsQuota.financialAnalyticsModuleEnabled} />} />
          <Route path="/contracts/finance" element={<Navigate to="/analytics/finance" />} />
          <Route path="/contracts/external" element={<ExternalContractsView />} />
          <Route path="/contracts" element={<ContractsView financialModuleEnabled={userQuota.customerContractsQuota.financialAnalyticsModuleEnabled} />} />
          <Route path="/contracts/organization-dashboard" element={<ContractsOrganizationDashboard />} />
          <Route path="/settings/organization-users-and-teams" element={
            <UsersAndTeams
              currentSubusersCount={userQuota.currentSubusersCount}
              maxSubuser={userQuota.customerContractsQuota.maxSubusers}
              updateUsersQuota={updateUsersQuota}
              updateQuota={fetchOrganizationQuota}
            />
          }
          />
          <Route path="/organization/quotas" element={<QuotaInfo />} />
          <Route path="/settings/organization-customize"
            element={
              <Personalization interfaceThemesCustomizationEnabled={userQuota.customerContractsQuota.interfaceThemesCustomizationEnabled} />
            }
          />
          <Route path='/notification-center' element={<NotificationCenter />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/organization/contacts" element={<Contacts />} />
          <Route path="/organization/contacts/:contactId" element={<ContactInfo />} />
          <Route path="/templates/create" element={<SivedicoSplashscreen newRoutes={newRoutes} oldRoutes={oldRoutes} componentsPreview={componentsPreview} userPreferenceExpire={(upe) => setUserPreferenceExpired} userPreferenceEditorCache={(upec) => setUserPreferenceEditorCache(upec)} userName={username} />} />
          <Route path="/templates/edit/:templateId" element={<SivedicoSplashscreen newRoutes={newRoutes} oldRoutes={oldRoutes} componentsPreview={componentsPreview} userPreferenceExpire={(upe) => setUserPreferenceExpired} userPreferenceEditorCache={(upec) => setUserPreferenceEditorCache(upec)} userName={username} />} />
          <Route path="/issue/template/:templateId" element={<SivedicoSplashscreen newRoutes={newRoutes} oldRoutes={oldRoutes} componentsPreview={componentsPreview} userPreferenceExpire={(upe) => setUserPreferenceExpired} userPreferenceEditorCache={(upec) => setUserPreferenceEditorCache(upec)} userName={username} />} />
          <Route path="/issue/file" element={<SivedicoSplashscreen newRoutes={newRoutes} oldRoutes={oldRoutes} componentsPreview={componentsPreview} userPreferenceExpire={(upe) => setUserPreferenceExpired} userPreferenceEditorCache={(upec) => setUserPreferenceEditorCache(upec)} userName={username} />} />
          <Route path="/external-contracts/create" element={<SivedicoSplashscreen newRoutes={newRoutes} oldRoutes={oldRoutes} componentsPreview={componentsPreview} userPreferenceExpire={(upe) => setUserPreferenceExpired} userPreferenceEditorCache={(upec) => setUserPreferenceEditorCache(upec)} userName={username} />} />
          <Route path="/renew-contract/:contractId" element={<SivedicoSplashscreen newRoutes={newRoutes} oldRoutes={oldRoutes} componentsPreview={componentsPreview} userPreferenceExpire={(upe) => setUserPreferenceExpired} userPreferenceEditorCache={(upec) => setUserPreferenceEditorCache(upec)} userName={username} />} />
        </Route>
        <Route path="/registry/:contractId" element={<StepsForContractSignatureView />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
