import { useContext, useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DigitalContractStates, EditableDigitalContract, IssuedDigitalContract } from "../../models/contracts";
import ContractsService from "../../services/contracts" 
import StlInterceptor from "../../contexts/stl";
import AuthenticationContext from "../../contexts/authentication";
import { SecurityTierLevels } from "../../store/stl";
import RenewAccessDialog from "../../views/authentication/RenewAccessDialog";

interface OnCloseCallback {
    (contractWasDeleted : boolean) : void
}

interface SendContractToIssuedStateProps extends React.ComponentProps<any> {
    open : boolean;
    source : EditableDigitalContract | IssuedDigitalContract;
    onClose : OnCloseCallback;
}

const DeleteEditableDigitalContractDialog = (props : SendContractToIssuedStateProps) : JSX.Element => {

    // ctx
    const stl = useContext(StlInterceptor);
    const auth = useContext(AuthenticationContext);

    // states
    // - states : application control states
    const [loading, setLoading] = useState(false);
    const [showRenewAccessDialog, setShowRenewAccessDialog] = useState(false);

    /**
     * Function that handles the inner <Dialog> onClose event. This function will call the onClose callback
     */
    function handleDialogOnCloseEvent() {
        props.onClose(false);
    }

    /**
     * Function that will create the issued contract based on the editable contract
     */
    function checkSTLAndDeleteContract() {

        // check for STL level
        setLoading(true);
        stl.require(auth, SecurityTierLevels.MaxLevel)
        .then(() => {
            deleteContract();
        })
        .catch(() => {
            setShowRenewAccessDialog(true);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    function deleteContract() {
        setLoading(true);

        if (props.source.state !== "WAITING_FOR_SIGNATURES") {
            // call the API to delete the contract
            ContractsService.deleteEditableContract(props.source.id)
            .then(() => {
                props.onClose(true);
            })
            .finally(() => {
                setLoading(false);
            })
        } else {
            ContractsService.deleteIssuedContract(props.source.id)
            .then(() => {
                props.onClose(true);
            })
            .finally(() => {
                setLoading(false);
            })
        }

        
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleDialogOnCloseEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Deletar contrato?
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Deseja deletar o contrato <b>{props.source.name}</b>? Lembre-se que este contrato ainda não foi assinado o que o torna ainda
                sem validade jurídica.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogOnCloseEvent} disabled={loading}>Cancelar</Button>
                {
                    (!loading) 
                    ?
                    <Button onClick={checkSTLAndDeleteContract} autoFocus color="warning">
                        Deletar
                    </Button>
                    :
                    <CircularProgress size="small" />
                }
                
            </DialogActions>
            <RenewAccessDialog open={showRenewAccessDialog} sessionRenewed={deleteContract} />
        </Dialog>
    );
}

export default DeleteEditableDigitalContractDialog;