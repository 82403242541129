import { useEffect, useState } from "react";
import { Box, Button, Popover, Grid, Paper, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography, IconButton, Skeleton, Divider } from "@mui/material";
import { CalendarMonth, Search, FilterList } from "@mui/icons-material";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CountsPerState } from "../../../models/analytics";
import AnalyticsService from "../../../services/analytics";
import ContractsSearchList from "../../../components/contracts/ContractsSearchList"
import moment from "moment";

const Analytics = (): JSX.Element => {

    const [loading, setLoading] = useState(false)
    const [counts, setCounts] = useState<CountsPerState>(null!)
    const [period, setPeriod] = useState<number | null>(30);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(null); setEndDate(null);
        setPeriod(Number((event.target as HTMLInputElement).value));
    };

    //Popover states - desktop
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null);

    //Popover states - mobile
    const [mobileAnchorEl, setMobileAnchorEl] = useState<null | HTMLElement>(null);
    const openMobile = Boolean(mobileAnchorEl);
    const handleClickMobile = (event: React.MouseEvent<HTMLButtonElement>) => setMobileAnchorEl(event.currentTarget)
    const handleCloseMobile = () => setMobileAnchorEl(null);

    //Fetch analytics result according to selected period
    useEffect(() => { fetchResult() }, [period])
    const fetchResult = async () => {
        //Update states
        handleClose(); handleCloseMobile();
        setLoading(true);

        //Parse dates
        if (startDate && endDate) setPeriod(null);
        const sd = startDate ? startDate.getTime() : moment().subtract(period, 'days').valueOf();
        const ed = endDate ? endDate.getTime() : new Date().getTime()

        //Send request
        try {
            const count = await AnalyticsService.countsPerState(sd, ed)
            setCounts(count);
        }
        catch (e) {
            console.error(e)
        }
        finally { setLoading(false) }
    }

    //Date picker component 
    const DatePickerComponent = (): JSX.Element => {
        return (
            <Box width={300}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="Selecione uma data inicial"
                        inputFormat="DD/MM/YYYY"
                        value={startDate} disableFuture
                        onChange={(value: Date | null) => { if (value) setStartDate(new Date(value)) }}
                        renderInput={(params) => <TextField {...params} size='small' sx={{ mb: 2 }} />}
                    />
                    <DatePicker
                        label="Selecione uma data final"
                        inputFormat="DD/MM/YYYY"
                        value={endDate} disableFuture
                        onChange={(value: Date | null) => { if (value) setEndDate(new Date(value)) }}
                        renderInput={(params) => <TextField {...params} size='small' />}
                    />
                </LocalizationProvider>
            </Box>
        )
    }
    //Parse dates to render
    const getStartDate = () => {
        return startDate ? moment(startDate).format("DD/MM/YYYY") : moment().subtract(period, 'days').format("DD/MM/YYYY")
    }
    const getEndDate = () => {
        return endDate ? moment(endDate).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")
    }

    //Utils - styles
    const mainBox = { m: { xs: 2, sm: 5 }, borderRadius: '8px', maxWidth : '880px' }
    const countMainBox = { mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }
    const countBox = { mt: 1, display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: .8, maxWidth: 200 }

    return (
        <Box className="container">
            <Typography variant="h6" color='primary.main'>Visão geral dos contratos no período selecionado</Typography>                
            <Box display={{ xs: 'none', sm: 'none', md: 'flex' }} alignItems='center' justifyContent='space-between'>
                <Box display='flex' alignItems='center'>
                    <Typography mr={5}><strong>Filtro</strong> de dados</Typography>
                    <FormControl>
                        <RadioGroup row onChange={handleChange} value={period}>
                            <FormControlLabel value={30} control={<Radio />} label="Últimos 30 dias" />
                            <FormControlLabel value={60} control={<Radio />} label="Últimos 60 dias" />
                            <FormControlLabel value={90} control={<Radio />} label="Últimos 90 dias" />
                        </RadioGroup>
                    </FormControl>
                    <IconButton onClick={handleClick} sx={{ display: { sm: 'none', md: 'flex', lg: 'none' } }}>
                        <CalendarMonth sx={{ color: period ? '#8A8A8A' : 'primary.main' }} />
                    </IconButton>
                    <Button onClick={handleClick}
                        startIcon={<CalendarMonth sx={{ color: period ? '#8A8A8A' : 'primary.main' }} />}
                        sx={{ color: 'text.primary', fontSize: '1rem', ml: 2, display: { md: 'none', lg: 'flex' } }}
                    >
                        Período personalizado
                    </Button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    >
                        <Box p={2} display='grid'>
                            <Box mb={3} display='flex' alignItems='center' justifyContent='space-between'>
                                <Typography>Intervalo de tempo </Typography>
                                <IconButton sx={{ p: 0 }} onClick={fetchResult}><Search /></IconButton>
                            </Box>
                            <DatePickerComponent />
                        </Box>
                    </Popover>
                </Box>
                <Typography variant="caption">{getStartDate()} até {getEndDate()}</Typography>
            </Box>
            <Box px={{ xs: 2, sm: 5 }} display={{ xs: 'flex', sm: 'flex', md: 'none' }} justifyContent='space-between' alignItems='center' >
                <Box display='flex' >
                    <Typography mr={3}>Filtro de dados</Typography>
                    <IconButton sx={{ p: 0 }} onClick={handleClickMobile}><FilterList /></IconButton>
                    <Popover
                        open={openMobile}
                        anchorEl={mobileAnchorEl}
                        onClose={handleCloseMobile}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    >
                        <Box p={2}>
                            <Box display='flex' justifyContent='space-between' alignItems='start'>
                                <FormControl>
                                    <RadioGroup onChange={handleChange} value={period}>
                                        <FormControlLabel value={30} control={<Radio />} label="Últimos 30 dias" />
                                        <FormControlLabel value={60} control={<Radio />} label="Últimos 60 dias" />
                                        <FormControlLabel value={90} control={<Radio />} label="Últimos 90 dias" />
                                    </RadioGroup>
                                </FormControl>
                                <IconButton sx={{ p: 0 }} onClick={fetchResult}><Search /></IconButton>
                            </Box>
                            <Divider />
                            <Typography my={2}>Intervalo de tempo </Typography>
                            <DatePickerComponent />
                        </Box>
                    </Popover>
                </Box>
                <Typography variant="caption">{getStartDate()} até {getEndDate()}</Typography>
            </Box>           
            <Box sx={mainBox}>
                {!counts ? null :
                    <Grid container mt={3}>
                        <Grid item sx={{ borderRight: { sm: 'none', md: '.5px solid #DDD' }, px: { xs: 2, sm: 4 }, py: 1 }} >
                            <Typography>Contratos em <strong>desenvolvimento</strong></Typography>
                            {loading ?
                                <>
                                    <Skeleton sx={{ mt: 2.5, mb: 1 }} />
                                    <Skeleton />
                                </>
                                :
                                <Box sx={countMainBox}>
                                    <Box sx={countBox}>
                                        <Typography variant="caption">Aguardando informações</Typography>
                                        <Typography color='primary'>{counts.editableContracts.amountOfContractsNotReadyToBeIssued}</Typography>
                                    </Box>
                                    <Box sx={countBox}>
                                        <Typography variant="caption">Prontos para enviar para assinatura</Typography>
                                        <Typography color='primary'>{counts.editableContracts.amountOfContractsReadyToBeIssued}</Typography>
                                    </Box>
                                </Box>
                            }

                        </Grid>
                        <Grid item sx={{ px: { xs: 2, sm: 4 }, py: 1 }} textAlign='center'>
                            <Typography>Contratos em <strong>fase de assinatura</strong></Typography>
                            {loading ?
                                <>
                                    <Skeleton sx={{ mt: 2.5 }} />
                                    <Skeleton sx={{ my: 1 }} />
                                    <Skeleton />
                                </>
                                :
                                <Box sx={countMainBox} alignItems='center'>
                                    <Box sx={countBox}>
                                        <Typography variant="caption">Aguardando assinaturas</Typography>
                                        <Typography color='primary'>{counts.issuedContracts.amountOfContractsWaitingForSignatures}</Typography>
                                    </Box>
                                    <Box sx={countBox}>
                                        <Typography variant="caption">Assinados</Typography>
                                        <Typography color='primary'>{counts.issuedContracts.amountOfSignedContracts}</Typography>
                                    </Box>
                                    <Box sx={countBox}>
                                        <Typography variant="caption">Inativos</Typography>
                                        <Typography color='primary'>{counts.issuedContracts.amountOfInactiveContracts}</Typography>
                                    </Box>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                }
            </Box>
            <Divider light />
            <Box className="container">
                <ContractsSearchList />
            </Box>
        </Box>
    );
}

export default Analytics;
