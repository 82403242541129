import { useEffect, useState } from "react";
import { UserPreferenceEditorCache } from "../App";
import CreateExternalContract from "../views/contracts/editor/external-contracts/CreateExternalContract";
import IssueFromFile from "../views/contracts/editor/issue/issue-from-file/IssueFromFile";
import IssueFromTemplate from "../views/contracts/editor/issue/issue-template/IssueFromTemplate";
import RenewContract from "../views/contracts/editor/issue/issue-template/RenewContract";
import Template from "../views/contracts/editor/templates/Template";
import RegistryWebappIFrame from "../views/registry-webapp-iframe/RegistryWebappIframe";
import { SivedicoRoute, SivedicoRouter } from "./Sivedico";
import { UserEditorPreferenceDialog } from "./UserEditorPreferenceDialog";

interface SivedicoSplashscreenProps extends React.ComponentProps<any> {
    newRoutes: string[],
    oldRoutes: string[],
    componentsPreview: string[],
    userPreferenceExpire: (upe: boolean) => void;
    userPreferenceEditorCache: (upec: boolean) => void;
}

export const SivedicoSplashscreen = (props: SivedicoSplashscreenProps): JSX.Element => {
    const [userPreferenceExpired, setUserPreferenceExpired] = useState(false);

    useEffect(() => {
        const uep = localStorage.getItem("user_preferences_cache");
        if(!uep) {
            setUserPreferenceExpired(true);
            return;
        };

        const uepj = JSON.parse(uep);
        const lastChange = new Date(Number(uepj.lastChange));            

        // Checks if it has been more than 3 days since the user set the option to the old editor.
        // This state will make the dialog show again. 
        if ((Date.now() - lastChange.getTime()) > 3 * 24 * 60 * 60 * 1000) {
        setUserPreferenceExpired(true);
        }
    }, [])

    function changeUserEditorPreference(newEditor: boolean) {
        let userPreference: UserPreferenceEditorCache = {
            version: newEditor ? "new_version" : "old_version",
            lastChange: Date.now().toString()
        }

        localStorage.setItem("user_preferences_cache", JSON.stringify(userPreference));
    }

    function SivedicoRouterComponent() {
        const routes: SivedicoRoute[] =
            [
                {
                    route: "/templates/create",
                    hasParam: false,
                    defaultComponent: <RegistryWebappIFrame />,
                    newComponent: <Template editorMode="create-template" />
                },
                {
                    route: "/templates/edit/:templateId",
                    hasParam: true,
                    defaultComponent: <RegistryWebappIFrame />,
                    newComponent: <Template editorMode="edit-template" />
                },
                {
                    route: "/issue/template/:templateId",
                    hasParam: true,
                    defaultComponent: <RegistryWebappIFrame />,
                    newComponent: <IssueFromTemplate />
                },
                {
                    route: "/issue/file",
                    hasParam: false,
                    defaultComponent: <RegistryWebappIFrame />,
                    newComponent: <IssueFromFile />
                },
                {
                    route: "/external-contracts/create",
                    hasParam: false,
                    defaultComponent: <RegistryWebappIFrame />,
                    newComponent: <CreateExternalContract />
                },
                {
                    route: "/renew-contract/:contractId",
                    hasParam: true,
                    defaultComponent: <RegistryWebappIFrame />,
                    newComponent: <RenewContract />
                }
            ];

        return <SivedicoRouter routes={routes} userSelectedVersions={props.componentsPreview} />;
    }

    return (
        <>
            <UserEditorPreferenceDialog
                userName={props.userName}
                open={userPreferenceExpired}
                close={() => setUserPreferenceExpired(false)}
                changeUserEditorPreference={changeUserEditorPreference}
                userPreferenceEditorCache={(upec) => props.userPreferenceEditorCache(upec)} />
            <SivedicoRouterComponent />
        </>
    )
}