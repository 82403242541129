import { Container, Typography, Box } from "@mui/material";
import { ContractPartie } from "../../../../../../../models/contracts";
import IssuePartieForm from "../forms/IssuePartieForm";

// Interfaces and Types
interface IssuePartyProps extends React.ComponentProps<any> {
    parties: ContractPartie[]
    updateParties: (value: ContractPartie) => void
}

const IssueParty = (props: IssuePartyProps): JSX.Element => {

    // Destructure props object
    const { parties, updateParties } = props;

    return (
        <Container>
            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Participantes do Contrato</Typography>
            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                Defina quais são os participantes do contrato
            </Typography>
            <Box sx={{ maxHeight: "600px", overflow: "auto", mb: 3 }}>
                {
                    parties.map(party => (
                        <IssuePartieForm party={party} parties={parties} updateParties={updateParties} key={party.role} />
                    ))
                }
            </Box>
        </Container>
    )
}

export default IssueParty;