//Redux imports
import { EditableDigitalContract, IssuedDigitalContract } from "../models/contracts";
import { hide } from "../redux/features/contract-view-dialog/contract-view-dialog";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useState } from "react";
import EditableDigitalContractDialogContent from "./contracts/EditableDigitalContractDialogContent";
import IssuedContractDialogContent from "./contracts/IssuedContractDialogContent";
import { Menu as MenuIcon } from "@mui/icons-material";
import ContractMenu from "./contracts/ContractMenu";

const ContractViewDialog = (props: React.ComponentProps<any>): JSX.Element => {

  // Redux props
  const contractDialogViewState = useAppSelector((state) => state.contractViewDialog);
  const dispatch = useAppDispatch();

  const [menuAE, setMenuAE] = useState<HTMLElement | null>(null)
  const [tags, setTags] = useState((contractDialogViewState.contract && contractDialogViewState.contract.source.contractTags) ? contractDialogViewState.contract?.source.contractTags : []);

  /**
   * Render the contract of the dialog according to the type of the contract on the source
   * @returns 
   */
  function ContractDialogContent(): JSX.Element {
    // render nothing if there is no contract on the redux view state
    if (contractDialogViewState.contract === undefined) return <></>

    // check if the contract is an Issued contract
    if ((contractDialogViewState.contract.source as IssuedDigitalContract).shardKey) {
      return <IssuedContractDialogContent source={contractDialogViewState.contract.source as IssuedDigitalContract} />
    }
    else if ((contractDialogViewState.contract.source as EditableDigitalContract).template) {
      return <EditableDigitalContractDialogContent source={contractDialogViewState.contract.source as EditableDigitalContract} />;
    }
    else {
      console.error("Application could not render contract information because the source of the contract is unknown");
      return <></>;
    }
  }

  /**
     * handle 'onclick' event for IconButton component
     * @param event 
     */
  function handleMenuIconClick(event: React.MouseEvent<HTMLButtonElement>): void {
    setMenuAE(event.currentTarget);
  }

  // Render the main component
  return (
    <Dialog
      open={contractDialogViewState.visible}
      onClose={() => { dispatch(hide()) }}
      fullScreen
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => { dispatch(hide()) }}
            aria-label="close"
          >
            <GridCloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color="secondary.light">
            Visualização de Contrato: {contractDialogViewState.contract?.normalized.name}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleMenuIconClick}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/**Render the contract of the dialog according to the type of the contract on the source */}
      <ContractDialogContent />
      {
        contractDialogViewState.contract
          ?
          <ContractMenu anchorElement={menuAE} setAnchorElement={setMenuAE} source={contractDialogViewState.contract} setTags={setTags} />
          :
          <></>
      }

    </Dialog>
  );
}

export default ContractViewDialog;