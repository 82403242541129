import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { ExternalDigitalContract, TagOrigin } from "../../models/contracts";
import { Delete, Download, FindInPage, Sell } from "@mui/icons-material";
import { useContext, useState } from "react";
import AuthenticationContext from "../../contexts/authentication";
import { RolesContext } from "../../contexts/roles";
import { AccountRoles } from "../../models/user";
import DeleteExternalContractDialog from "./DeleteExternalContractDialog";
import ViewExternalContractDialog from "./ViewExternalContractDialog";
import ContractsService from "../../services/contracts";
import axios from "axios";
import { useAppDispatch } from "../../redux/hooks";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../utils/ErrorWrapper";
import ExternalContractTagsDialog from "./ExternalContractTagsDialog";
import { TagsModel } from "../../services/tags";

interface ContractWasDeletedCallback {
    (deletedContract: ExternalDigitalContract): void;
}

interface ExternalContractMenuProps extends React.ComponentProps<any> {
    anchorElement: HTMLElement | null;
    source: ExternalDigitalContract;
    setTags: (value: any[]) => void
    setAnchorElement: (value: HTMLElement | null) => void;
    onContractDeleted?: ContractWasDeletedCallback;
}

const ExternalContractMenu = (props: ExternalContractMenuProps): JSX.Element => {

    // Destructure props object
    const { anchorElement, source, setTags, setAnchorElement, onContractDeleted } = props;

    // Contexts
    const authUser = useContext(AuthenticationContext);
    const userRoles = useContext(RolesContext);
    const notification = useAppDispatch();

    // Boolean states
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openViewContractDialog, setOpenViewContractDialog] = useState(false);
    const [openTagsDialog, setOpenTagsDialog] = useState(false);
    const [loadingDownloadContract, setLoadingDownloadContract] = useState(false);

    // Helpers
    const canUserModifyOrDeleteContract = userRoles.assertIsOrganizationOrAdministratorOr(authUser, AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER);

    // Handlers function
    function handleCloseMenu() {
        setAnchorElement(null);
    }

    /**
     * Handle onClose event for DeleteEditableDigitalContractDialog. This function will trigger the 'onContractDeleted' if the contract was
     * successfully deleted
     * @param contractWasDeleted 
     */
    function handleDeleteContractDialogOnCloseEvent(contractWasDeleted: boolean) {
        if (contractWasDeleted && onContractDeleted) {
            onContractDeleted(source);
        }
        setOpenDeleteDialog(false);
    }

    function handleTagsDialogOnCloseEvent(tagDialogClose: boolean, updatedTags: TagsModel[]) {
        setOpenTagsDialog(false);
        setTags(updatedTags.map(ut => (
            {
                tagName: ut.tagName,
                tagOrigin: TagOrigin.CONTRACT
            }
        )))
    }

    return (
        <>
            <Menu anchorEl={anchorElement} open={Boolean(anchorElement)} onClose={handleCloseMenu}>
                <MenuItem
                    onClick={() => setOpenViewContractDialog(true)}
                >
                    <ListItemIcon>
                        <FindInPage fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Ver contrato
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={async () => {
                        const contract = source
                        const uri = await ContractsService.downloadExternalContractDocumentURI(contract)
                        axios.get(`${uri.uri}`, {
                            responseType: "blob"
                        })
                            .then(downloadedFile => {
                                const filename = contract.id

                                // create file link in browser's memory
                                const href = URL.createObjectURL(downloadedFile.data);

                                // create "a" HTML element with href to file & click
                                const link = document.createElement('a');
                                link.href = href;
                                link.setAttribute('download', filename); //or any other extension
                                document.body.appendChild(link);
                                link.click();

                                // clean up "a" element & remove ObjectURL
                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                                notification(show({type: "success", message: "Contrato baixado com sucesso"}))
                            })
                            .catch(e => {
                                const err = new ErrorWrapper(e)
                                notification(show({type: "error", message: err.message}))
                            })
                            .finally(() => {
                                setLoadingDownloadContract(false)
                            })
                    }}
                    disabled={loadingDownloadContract}
                >
                    <ListItemIcon>
                        <Download fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Baixar contrato
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => setOpenDeleteDialog(true)}
                    disabled={!canUserModifyOrDeleteContract}
                >
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Deletar contrato
                        {
                            !canUserModifyOrDeleteContract
                                ?
                                <Typography variant="subtitle2" sx={{ display: "block" }}>
                                    (Exclusivo dono da organização, administradores ou gerente de contratos)
                                </Typography>
                                :
                                <></>
                        }
                    </ListItemText>
                </MenuItem>
                <MenuItem 
                    onClick={() => setOpenTagsDialog(true)}
                    disabled={!canUserModifyOrDeleteContract}
                >
                    <ListItemIcon><Sell /></ListItemIcon>
                    <ListItemText>Editar tags</ListItemText>
                </MenuItem>
            </Menu>
            <ViewExternalContractDialog
                source={source}
                open={openViewContractDialog}
                onClose={() => setOpenViewContractDialog(false)}
            />
            <DeleteExternalContractDialog
                open={openDeleteDialog}
                source={source}
                onClose={handleDeleteContractDialogOnCloseEvent}
            />
            <ExternalContractTagsDialog 
                open={openTagsDialog}
                source={source}
                onClose={handleTagsDialogOnCloseEvent}
            />
        </>
    );
}

export default ExternalContractMenu;