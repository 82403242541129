import { Add } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";

// Interfaces and Types
interface AddFieldButtonProps extends React.ComponentProps<any> {
    disabled: boolean,
    title: string
    onClick: () => void
}

const AddFieldButton = (props: AddFieldButtonProps): JSX.Element => {

    // Destructure props object
    const { disabled, title, onClick } = props;

    return (
        <Button
            variant="outlined"
            fullWidth
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                py: 2,
                px: 2,
                border: "1px solid rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
                cursor: "pointer",
                mb: 3
            }}
            onClick={onClick}
            disabled={disabled}
        >
            <Add color="primary" />
            <Typography sx={{ ml: 2, color: (theme) => theme.palette.primary.main }}>{title}</Typography>
        </Button>
    )
}

export default AddFieldButton;