import { Box, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import { DateInterval, EndDatePolicyType, IssueValidityPeriod, StartDatePolicyType, ValidityPeriodSpecification } from "../../../../../../../models/contracts";
import { CalendarToday, Event, Today } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";

// Interfaces and types
interface IssueValidityFormProps extends React.ComponentProps<any> {
    validityPeriod: ValidityPeriodSpecification,
    issueValidity: IssueValidityPeriod,
    updateIssueValidity: (value: IssueValidityPeriod) => void;
}

const IssueValidityForm = (props: IssueValidityFormProps): JSX.Element => {

    // Destrucuture props object
    const { validityPeriod, issueValidity, updateIssueValidity } = props;

    // Data states
    const [startDate, setStartDate] = useState<Date | null>(issueValidity.startDate ? new Date(issueValidity.startDate.timestamp) : null);
    const [endDate, setEndDate] = useState<Date | null>(issueValidity.endDate ? new Date(issueValidity.endDate.timestamp) : null);

    // useEffects
    useEffect(() => {
        updateValidity();
    }, [startDate, endDate])

    // Functions
    function calculateEndDate() {
        if (!validityPeriod.endDatePolicy) return;
        switch (validityPeriod.endDatePolicy.dateInterval) {
            case DateInterval.DAY:
                return moment().add(validityPeriod.endDatePolicy.value, "days").format("DD/MM/YYYY")
            case DateInterval.WEEK:
                return moment().add(validityPeriod.endDatePolicy.value, "weeks").format("DD/MM/YYYY")
            case DateInterval.MONTH:
                return moment().add(validityPeriod.endDatePolicy.value, "months").format("DD/MM/YYYY")
            case DateInterval.YEAR:
                return moment().add(validityPeriod.endDatePolicy.value, "years").format("DD/MM/YYYY")
        }
    }

    function updateValidity() {
        const endDateTimestamp = endDate ? { timestamp: endDate.getTime() } : null;
        const startDateTimestamp = startDate ? { timestamp: startDate.getTime() } : null;
        
        const newValidity: IssueValidityPeriod = {
            endDate: endDateTimestamp,
            startDate: startDateTimestamp
        }

        updateIssueValidity(newValidity);
    }

    return (
        <Box sx={{ mt: 3 }}>
            <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Today color="primary" />
                    <Typography sx={{ ml: 3 }} >Data Inicial</Typography>
                </Box>
                <Box sx={{ mt: 3, mb: 6 }}>
                    {
                        validityPeriod.startDatePolicyType === StartDatePolicyType.STARTS_WHEN_SIGNED_BY_ALL_PARTIES
                            ?
                            <Typography>A data de início do período de vigência deste contrato será definida após a assinatura de todos os participantes.</Typography>
                            :
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                                <DatePicker
                                    label="Selecione a data inicial"
                                    inputFormat="DD/MM/YYYY"
                                    value={startDate}
                                    onChange={(value: Moment | null) => { if (value) setStartDate(value.toDate()) }}
                                    renderInput={(params) => <TextField {...params} fullWidth error={!startDate} helperText={!startDate ? "Campo obrigatório" : ""} />}
                                />
                            </LocalizationProvider>
                    }
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", }}>
                    <Event color="primary" />
                    <Typography sx={{ ml: 3 }} >Data Final</Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                    {
                        validityPeriod.endDatePolicyType === EndDatePolicyType.UNDETERMINED
                            ?
                            <Typography>
                                Este contrato não possui uma data de termino do período de vigência, então seu termino seguirá de acordo com as leis da região de emissão do contrato.
                            </Typography>
                            :
                            validityPeriod.endDatePolicyType === EndDatePolicyType.SET_AUTOMATICALLY_AFTER_START_DATE
                                ?
                                <Box>
                                    <Typography>A data final será fixado após a data de início baseando-se no tempo definido no modelo deste contrato.</Typography>
                                    <Box sx={{ mt: 6, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px", p: 2 }}>
                                        <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                                            <ListItemIcon><CalendarToday /></ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="h6">Simulador de datas</Typography>
                                            </ListItemText>
                                        </Box>
                                        <Typography>
                                            Considerando a data de início como hoje, este contrato se encerrará em: <b>{calculateEndDate()}</b>
                                        </Typography>
                                    </Box>
                                </Box>
                                :
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                                    <DatePicker
                                        label="Selecione a data final"
                                        inputFormat="DD/MM/YYYY"
                                        value={endDate}
                                        onChange={(value: Moment | null) => { if (value) setEndDate(value.toDate()) }}
                                        renderInput={(params) => <TextField {...params} fullWidth error={!endDate} helperText={!endDate ? "Campo obrigatório" : ""} />}
                                    />
                                </LocalizationProvider>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default IssueValidityForm;