import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import authenticationStorage from "../../store/authentication";

const RegistryWebappIFrame = (): JSX.Element => {

    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Plataforma de Contratos Digitais"
    }, []);

    // states
    // - store the loading iframe state
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const path =
        process.env.REACT_APP_REGISTRY_WEBAPP +
        location.pathname +
        `?authToken=${authenticationStorage.getAccountsToken()}`;

    return (
        <iframe
            onLoad={() => setLoading(false)} frameBorder="0"
            src={path}
            style={{ overflow: 'hidden', height: '100%', width: '100%' }}
        />
    )
}

export default RegistryWebappIFrame;