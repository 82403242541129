import { ContractPartyActivityModel } from "../models/contract-parties-activities"
import contractsApi from "./contracts-api";

const ContractPartiesActivitiesService = {
    fetchContractPartyActivitiesByContractId: async (contractId: String): Promise<ContractPartyActivityModel[]> => {
        let url = `/rest/v1/digital-contracts/${contractId}/contract-party-activities/`;
        const response = await contractsApi.get(url);

        //If the response is empty, return an empty array
        if (response.status === 204) return [];

        const contractPartyActivity = await (response).data as ContractPartyActivityModel[];
        return contractPartyActivity;
    },
}
export default ContractPartiesActivitiesService;