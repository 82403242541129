import { Theme } from "@emotion/react";
import { Box, Divider, Input, SxProps, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { RequiredInformationInputFieldProps } from "../rifields"
import { Help } from "@mui/icons-material";

const Integer = (props: RequiredInformationInputFieldProps): JSX.Element => {

    //states
    const [value, setValue] = useState(props.requiredInformation.value);
    const [isValid, setIsValid] = useState(false);

    const boxStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            padding: "1rem 0.8rem"
        },
        ['@media(max-width:300px)']: {
            padding: "1rem 0.8rem"
        },
        width: "100%",
        padding: "20px 28px",
        backgroundColor: "#ffffff",
        marginBottom: "14px",
        borderRadius: "10px",
        boxSizing: "border-box",
    }
    const titleStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            fontSize: "0.875rem",
            padding: "0.375rem 0.625rem"
        },
        ['@media(max-width:300px)']: {
            fontSize: "0.5rem",
            padding: "0.125rem 0.25rem"
        },
        color: "#555555",
        fontSize: "20px"
    }

    useEffect(() => {
        setIsValid(/^\d+$/.test(value))
    }, [value])

    useEffect(() => {
        props.onChange(
            value,
            isValid
        );
    }, [value, isValid]);


    function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.value.length === 0 ||  /^\d+$/.test(event.target.value)) {
            setValue(event.target.value);
        }
    }

    return (
        <Fragment key={props.requiredInformation.requiredInformationName}>
            <Box sx={boxStyle} >
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
                    <Typography sx={titleStyle}>{props.requiredInformation.requiredInformationName}</Typography>
                    {
                        props.requiredInformation.requiredInformation.requiredInformation.description
                            ?
                            <Tooltip title={props.requiredInformation.requiredInformation.requiredInformation.description}>
                                <Help sx={{ ml: 1, fontSize: "18px", color: "#555" }} />
                            </Tooltip>
                            :
                            <></>
                    }
                </Box>
                <Tooltip title={(isValid) ? null : "Preencha este campo"}>
                    <Input
                        error={!isValid}
                        placeholder="Insira aqui"
                        type="text"
                        value={value}
                        fullWidth
                        onChange={handleOnChange}
                    />
                </Tooltip>
            </Box>
            <Divider />
        </Fragment>
    );
}

export default Integer;