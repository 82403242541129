import { SupportedCurrencies } from "../utils/currency";

export interface ContractPartyTemplateSpecification {
    role: string,
    identificationType: "IDENTIFIED_BY_EMAIL" | "ORGANIZATION_USER" | "ORGANIZATION_TEAM",
    identification: string
}

/**
 * 
 */
export interface AttachmentSpecification {
    name: string;
    description: string;
    required: boolean;
    contractParty: ContractPartyTemplateSpecification;
    requiresApproval?: boolean;
}

/**
 * This model represents an digital contract template
 */
type DigitalContractTemplate = {
    id: string,
    version: number,
    organizationOwnerId: string,
    name: string,
    legalRegionOfIssuance: "BRA",
    creationDate: Date,
    templateInputFile: {
        fileId: string,
        type: string,
        version: number
    },
    validityPeriodSpecification: ValidityPeriodSpecification,
    contractTags: Tags[] | null,
    requiredContractPartiesInformation: any[],
    attachmentSpecifications: any[],
    billingSpecifications: BillingSpecification[],
    contractPartySpecifications: any[],
    contractIssuersSpecifications: any[],
    allowIssuerToSendRequiredData: boolean,
    wildcardMetadata?: WildcardMetadata[],
    mailTemplates?: MailTemplate[],
    clausesToConfirm: string[] | null,
    lastModificationLog: ModificationLog,
    contractPartiesSignatureOrder: string[] | null,
}

/**
 * Represents an type of a contract used in the platform
 */
export enum DigitalContractType {
    Editable,
    Issued
}

/**
 * Represent an digital contract state
 */
enum DigitalContractStates {
    NOT_READY_TO_BE_ISSUED = "NOT_READY_TO_BE_ISSUED",
    READY_TO_BE_ISSUED = "READY_TO_BE_ISSUED",
    WAITING_FOR_SIGNATURES = "WAITING_FOR_SIGNATURES",
    SIGNED = "SIGNED",
    INACTIVE = "INACTIVE",
}

enum DigitalContractInputFileType {
    HTML_1_0 = "HTML_1_0",
    WORD_1_0 = "WORD_1_0"
}

/**
 * This model represents an digital contract in editable phase
 */
type EditableDigitalContract = {
    id: string,
    issuerUuid: string,
    template: DigitalContractTemplate,
    name: string,
    state: string,
    contractParties: (PlatformUserContractParty | IdentifiedByEmailContractParty)[]
    organizationOwnerId: string,
    legalRegionOfIssuance: "BRA",
    creationDate: number,
    billingData: DigitalContractBilling[] | null,
    userInformation: UserInformation[] | null,
    attachments: DigitalContractAttachment[] | null,
    personalizationActive: boolean,
    validityPeriod: EditableDigitalContractValidityPeriod,
    contractTags: Tags[] | null,
    revokedBy: RevokedEvent[] | null,
    accessHistory: AccessContractParty<AccessHistory>
}

type DigitalContractSignature = {
    uuid: String,
    signatureDate: number,
    userEmail: String,
    ipAddress: String,
    contractParty: ContractParty,
    userFullname?: string,
    userDocument?: string,
}

/**
 * Represents an abstract an global abstract of an ContractParty
 */
export interface ContractParty {
    role: string,
    contractPartyIdentificationType: "IDENTIFIED_BY_EMAIL" | "PLATFORM_USER",
    comment?: string
}

/**
 * Represents an contract party identified by email
 */
export interface IdentifiedByEmailContractParty extends ContractParty {
    email: string
}

/**
 * Represents an contract party registered on the platform identified by an UUID
 */
export interface PlatformUserContractParty extends ContractParty {
    uuid: string
}

export interface CurrencyValue {
    value: number,
    currency: string,
    regionalString: string,
    regionalValue: number,
}

export interface DigitalContractBilling {
    name: string,
    debtorContractParty: ContractParty,
    description: string,
    value: CurrencyValue,
    billingPeriodType: string,
    initialBillingPeriod: number,
    billingDueDay: number,
    numberOfInstallments: number
}

export type RequiredInformationType = "SINGLE_LINE_TEXT" | "INTEGER" | "DECIMAL" | "TIMESTAMP" | "DATE" | "TIME" | "ADDRESS" | "BRA_CPF" | "BRA_CNPJ" | "BRA_CPF_OR_CNPJ" | "MULTIPLE_SELECT" | "SINGLE_SELECT" | "EMAIL"

export interface RequiredInformation {
    name: string,
    required: boolean,
    description: string,
    type: RequiredInformationType
    options?: string[],
    isWildcard?: boolean,
    contractParty?: {
        role: string
    }
}

export interface RequiredContractPartyInformation {
    contractParty: ContractParty,
    requiredInformation: RequiredInformation
}

export interface AttachmentSpecification {
    name: string;
    description: string;
    required: boolean;
    contractParty: ContractPartyTemplateSpecification;
}

export interface UserInformation {
    requiredInformation: RequiredContractPartyInformation,
    value: string,
}

export interface DigitalContractAttachment {
    guid: string,
    uploaderId: string,
    filename: string,
    name: string,
    status?: AttachmentStatus,
    statusDescription?: string,
}

export type HashAlgorithms = "SHA_256"

export interface DigitalTrustData {
    documentHashValue: string,
    documentHashAlgorithm: HashAlgorithms
}

interface RevokedEvent {
    contractParty: ContractParty,
    revokedAt: Date
}

/**
 * This model represents an digital contract in editable phase
 */
type IssuedDigitalContract = {
    id: string,
    shardKey: string,
    issuerUuid: string,
    state: string,
    digitalTrustData: DigitalTrustData,
    issueDate: number,
    legalRegionOfIssuance: "BRA",
    templateId: string | null,
    name: string,
    contractParties: (PlatformUserContractParty | IdentifiedByEmailContractParty)[],
    billingData: DigitalContractBilling[] | null,
    attachments: DigitalContractAttachment[] | null,
    userInformation: UserInformation[] | null,
    signatures: DigitalContractSignature[] | null,
    organizationOwnerId: string,
    documentType: IssuedContractDocumentType,
    personalizationActive: boolean,
    validityPeriod: ValidityPeriod,
    inactiveAt?: Date,
    fullySignedDate?: number,
    contractTags: Tags[] | null,
    revokedBy: RevokedEvent[] | null,
    clausesToConfirm: string[] | null,
    accessHistory: AccessContractParty<AccessHistory>,
    contractPartiesSignatureOrder: string[] | null,
}

export enum IssuedContractDocumentType {
    WORD = "WORD",
    PDF = "PDF"
}

export enum TagOrigin {
    CONTRACT = "CONTRACT",
    TEMPLATE = "TEMPLATE"
}


/**
 * This type represents an contract with normalized data
 */
type NormalizedContract = {
    type: DigitalContractType,
    state: DigitalContractStates,
    id: string,
    name: string,
    issuanceDate: Date,
    fullySignedDate?: Date,
    templateId: string | null,
    billingData: DigitalContractBilling[] | null,
    issuerUuid: string,
    contractTags: Tags[] | null,
    contractPartiesSignatureOrder: string[] | null,
    accessHistory: AccessContractParty<AccessHistory>,
}

/**
 * This type is used to encapsulate an normalized and an original source of contract
 */
type EncapsulatedContract = {
    normalized: NormalizedContract,
    source: EditableDigitalContract | IssuedDigitalContract
};

type Tags = {
    tagName: string,
    tagOrigin: TagOrigin,
}

/**
 * Parse an source contract type to a normalized SummaryContract type.
 * @param sourceContract The contract that will be parsed to NormalizedContract
 * @returns 
 */
function parseToNormalizedContract(sourceContract: EditableDigitalContract | IssuedDigitalContract): NormalizedContract {
    if ((sourceContract as EditableDigitalContract).template) {
        return {
            type: DigitalContractType.Editable,
            state: DigitalContractStates[sourceContract.state as keyof typeof DigitalContractStates],
            id: sourceContract.id,
            name: sourceContract.name,
            templateId: (sourceContract as EditableDigitalContract).template.id,
            issuanceDate: new Date((sourceContract as EditableDigitalContract).creationDate),
            billingData: sourceContract.billingData,
            issuerUuid: sourceContract.issuerUuid,
            contractTags: sourceContract.contractTags,
            contractPartiesSignatureOrder: (sourceContract as EditableDigitalContract).template.contractPartiesSignatureOrder,
            accessHistory: sourceContract.accessHistory,
        }
    }
    else {
        if ((sourceContract as IssuedDigitalContract).fullySignedDate) {
            const fullySignedDate = (sourceContract as IssuedDigitalContract).fullySignedDate
            if (fullySignedDate)
                return {
                    type: DigitalContractType.Issued,
                    state: DigitalContractStates[sourceContract.state as keyof typeof DigitalContractStates],
                    id: sourceContract.id,
                    name: sourceContract.name,
                    issuanceDate: new Date((sourceContract as IssuedDigitalContract).issueDate),
                    templateId: (sourceContract as IssuedDigitalContract).templateId,
                    fullySignedDate: new Date(fullySignedDate),
                    billingData: sourceContract.billingData,
                    issuerUuid: sourceContract.issuerUuid,
                    contractTags: sourceContract.contractTags,
                    contractPartiesSignatureOrder: (sourceContract as IssuedDigitalContract).contractPartiesSignatureOrder,
                    accessHistory: sourceContract.accessHistory,
                }
        }
        return {
            type: DigitalContractType.Issued,
            state: DigitalContractStates[sourceContract.state as keyof typeof DigitalContractStates],
            id: sourceContract.id,
            name: sourceContract.name,
            issuanceDate: new Date((sourceContract as IssuedDigitalContract).issueDate),
            templateId: (sourceContract as IssuedDigitalContract).templateId,
            billingData: sourceContract.billingData,
            issuerUuid: sourceContract.issuerUuid,
            contractTags: sourceContract.contractTags,
            contractPartiesSignatureOrder: (sourceContract as IssuedDigitalContract).contractPartiesSignatureOrder,
            accessHistory: sourceContract.accessHistory,
        }
    }
}

/**
 * Interface used to represent an analytical data from the rest/v1/analytics/organization/total-amount-billing-data endpoint
 */
export interface TotalAmountOfBillingDataAnalyticsWithGranularity {
    granularity: string,
    templateId: string,
    currency: SupportedCurrencies,
    billingName: string,
    state: "WAITING_FOR_ISSUED" | "WAITING_FOR_SIGNATURES" | "SIGNED" | "INACTIVE",
    totalSum: number,
}

/**
 * Interface used to represent an analytical data from the rest/v1/analytics/organization/total-amount-billing-data/count endpoint
 */
export interface TotalAmountOfBillingDataAnalyticsWithGranularityCount {
    granularity: string,
    templateId: string,
    currency: SupportedCurrencies,
    billingName: string,
    state: "WAITING_FOR_ISSUED" | "WAITING_FOR_SIGNATURES" | "SIGNED" | "INACTIVE",
    totalCount: number,
}

export interface LabeledTotalAmountOfBillingDataAnalyticsWithGranularity {
    granularity: string,
    templateId: string,
    currency: SupportedCurrencies,
    billingName: string,
    state: "WAITING_FOR_ISSUED" | "WAITING_FOR_SIGNATURES" | "SIGNED" | "INACTIVE",
    totalSum: number,
    group: string,
    count: number,
}

export interface PerformanceComparanceData {
    firstPeriod: LabeledTotalAmountOfBillingDataAnalyticsWithGranularity[],
    secondPeriod: LabeledTotalAmountOfBillingDataAnalyticsWithGranularity[],
}

/**
 * Interface used to represent an analytical data from the rest/v1/analytics/organization/total-issued-contracts endpoint
 */
export interface TotalContractsDataAnalyticsWithGranularity {
    granularity: string,
    templateId: string,
    state: "WAITING_FOR_ISSUED" | "WAITING_FOR_SIGNATURES" | "SIGNED" | "INACTIVE",
    totalCount: number,
}

export interface NormalizedUserRequiredInformation {
    requiredInformation: RequiredContractPartyInformation,
    requiredInformationName: string,
    value: string,
    isValid: boolean;
}

export interface NormalizedAttachment {
    isValid: boolean;
    specification: AttachmentSpecification;
    attachment?: DigitalContractAttachment;
}

export interface ValidityPeriod {
    startDatePolicyType: StartDatePolicyType,
    startDate: number | null,
    endDatePolicyType: EndDatePolicyType,
    endDate: number | null
}

export interface EditableDigitalContractValidityPeriod {
    startDatePolicyType: StartDatePolicyType,
    startDate: ValidityPeriodTimestamp | null,
    endDatePolicyType: EndDatePolicyType,
    endDate: ValidityPeriodTimestamp | null
}

export interface ValidityPeriodTimestamp {
    timestamp?: number | null
}

export interface ValidityPeriodSpecification {
    startDatePolicyType: string,
    endDatePolicyType: string,
    endDatePolicy?: DatePolicy,
    startDatePolicy?: DatePolicy,
}

export interface DatePolicy {
    value: number,
    dateInterval: DateInterval,
}

export interface ApproveOrReproveAttachment {
    name: string;
    description: string;
    status: AttachmentStatus;
}

export interface ExternalDigitalContract {
    id: string,
    shardKey: string,
    state: string,
    signatureDate: number,
    legalRegionOfIssuance: "BRA",
    name: string,
    contractParties: (PlatformUserContractParty | IdentifiedByEmailContractParty)[],
    billingData: DigitalContractBilling[] | null,
    organizationOwnerId: string,
    validityPeriod: ValidityPeriod,
    contractTags: Tags[] | null,
    documentType: IssuedContractDocumentType
}

export interface ModificationLog {
    name: string,
    email: string,
    uuid: string,
    type: ModificationType,
    date: Date,
}

export interface DigitalContractTemplateModificationLog {
    templateId: string,
    organizationId: string,
    logs: ModificationLog[]
}

enum EndDatePolicyType {
    UNDETERMINED = "UNDETERMINED",
    SET_BY_ISSUER = "SET_BY_ISSUER",
    SET_AUTOMATICALLY_AFTER_START_DATE = "SET_AUTOMATICALLY_AFTER_START_DATE"
}
enum StartDatePolicyType {
    SET_BY_ISSUER = "SET_BY_ISSUER",
    STARTS_WHEN_SIGNED_BY_ALL_PARTIES = "STARTS_WHEN_SIGNED_BY_ALL_PARTIES"
}

export enum DateInterval {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR",
}

export enum AttachmentStatus {
    APPROVED = "APPROVED",
    REPROVED = "REPROVED",
    PENDING = "PENDING"
}

export interface AccessContractParty<T> {
    [label: string]: T,
}
export interface AccessHistory {
    accessedAt: number
}
export enum ModificationType {
    TEMPLATE = "TEMPLATE",
    INPUT_FILE = "INPUT_FILE",
}


export interface TemplateFileDTO {
    uri: URIRedirectDTO,
    wildcards: ComputedWildcards,
}

export interface ComputedWildcards {
    wildcards: ComputedWildcard[],
    wildcardErrors: string[]
}

export interface ComputedWildcard {
    name: string,
    wildcardType: WildcardType
}

export enum WildcardType {
    REQUIRED_INFORMATION = "REQUIRED_INFORMATION",
    BILLING = "BILLING",
}

export enum IdentificationType {
    ORGANIZATION_USER = "ORGANIZATION_USER",
    ORGANIZATION_TEAM = "ORGANIZATION_TEAM",
    IDENTIFIED_BY_EMAIL = "IDENTIFIED_BY_EMAIL",
    PLATFORM_USER = "PLATFORM_USER"
}

export interface RequiredInformationSpecification {
    requiredInformation: {
        name: string,
        required: boolean,
        description: string,
        type: RequiredInformationType
        options?: string[],
    }
    isWildcard?: boolean,
    contractParty: {
        role: string
    },
    contractPartyRole: string
}

export enum BillingPeriodType {
    RECURRING = "RECURRING",
    ONE_TIME_PAYMENT = "ONE_TIME_PAYMENT",
    INSTALLMENTS = "INSTALLMENTS"
}

export interface BillingSpecification {
    required: boolean,
    name: string,
    billingPeriodType: BillingPeriodType,
    description?: string,
    minimumValue: number,
    maximumValue: number,
    billingPeriod?: {
        maxInstallments: number
    },
    debtorContractParty: {
        role: string
    },
    isWildcard?: boolean,
    debtorContractPartyRole: string
}

export interface RequiredAttachment {
    required: boolean,
    name: string,
    contractParty: {
        role: string
    },
    requiresApproval: boolean,
    contractPartyRole: string,
    description?: string
}

export interface ContractPartyIdentification {
    role: string,
    identificationType: IdentificationType | null,
    identification: {
        uuid?: string,
        email?: string,
        teamId?: string
    } | undefined,
    email: string,
    comment?: string,
}

export interface ContractPartie {
    role: string,
    identificationType: IdentificationType,
    identification: {
        teamId?: string,
        uuid?: string,
        email?: string
    },
    comment?: string
}

export interface ContractIssuer {
    identificationType: IdentificationType,
    identification: {
        teamId?: string,
        uuid?: string
    }
}

export interface WildcardMetadata {
    name: string,
    type: WildcardType | null
}

export enum EmailTypes {
    ISSUE_CONTRACT = "ISSUE_CONTRACT",
    CHANGE_STATES = "CHANGE_STATES",
    FULLY_SIGNED = "FULLY_SIGNED",
}

export interface MailTemplate {
    mailTemplate: string,
    mailType: EmailTypes,
}

export interface TemplateDTO {
    id: string,
    contractParties: ContractPartie[];
    name: string,
    legalRegionOfIssuance: string,
    validityPeriod: ValidityPeriodSpecification,
    requiredContractPartiesInformation: RequiredInformationSpecification[],
    contractIssuers: ContractIssuer[],
    billingSpecifications: BillingSpecification[],
    requiredAttachments: RequiredAttachment[],
    templateInputFile: null | {
        fileId: string
    },
    allowIssuerToSendRequiredData: boolean,
    wildcardMetadata: WildcardMetadata[],
    contractTags: {
        tags: string[]
    },
    mailTemplates?: MailTemplate[],
    clausesToConfirm: string[],
    contractPartiesSignatureOrder: string[] | null,
}

export interface IssueContractPartie extends ContractPartie {
    duplicated: boolean
}

export interface IssueValidityPeriodDate {
    timestamp: number
}

export interface IssueValidityPeriod {
    startDate: IssueValidityPeriodDate | null,
    endDate: IssueValidityPeriodDate | null,
}

export interface IssueBilling {
    name: string,
    value: {
        value: number,
        currency: SupportedCurrencies
    },
    initialBillingPeriod: number,
    billingDueDay: number,
    numberOfInstallments: number
}

export interface FileIssueBilling {
    contractParty: string,
    billingPeriodType: BillingPeriodType,
    name: string,
    value: {
        value: number,
        currency: SupportedCurrencies
    },
    initialBillingPeriod: Date,
    billingDueDay: number,
    numberOfInstallments: number,
    description?: string
}

export interface FileIssueValidityPeriod {
    startDatePolicyType: string,
    endDatePolicyType: string,
    endDate?: Date,
    startDate?: Date,
}

export interface IssueTemplateDTO {
    id: string,
    contractParties: IssueContractPartie[],
    validityPeriod: IssueValidityPeriod,
    billingData: IssueBilling[],
    contractTags: {
        tags: string[]
    },
    mailTemplates: MailTemplate[],
    name: string
}

export interface FileIssueContractPartie {
    role: string,
    contractPartyIdentificationType: IdentificationType,
    identification: {
        teamId?: string,
        uuid?: string,
        email?: string
    },
    comment?: string
}

export interface FileIssueDTO {
    name: string,
    contractParties: FileIssueContractPartie[],
    region: string,
    validityPeriod: FileIssueValidityPeriod,
    billingData: FileIssueBilling[],
    contractTags: {
        tags: string[]
    },
    mailTemplates: MailTemplate[],
    contractPartiesSignatureOrder?: string[] | null,
    clausesToConfirm: string[],
}

interface URIRedirectDTO {
    uri: string;
}

export interface ExternalContractDTO {
    name: string,
    documentType: string,
    validityPeriod: FileIssueValidityPeriod,
    region: string,
    contractParties: FileIssueContractPartie[],
    billingData: FileIssueBilling[],
    contractTags: { 
        tags: string[]
    },
    state: DigitalContractStates,
    signatureDate: Date,
    contractPartiesSignatureOrder?: string[] | null,
}

export interface TotalMonthlyCountOfContractsDTO {
    referenceMonth: Date;
    countOfSigned: number;
    countOfWaitingForSignature: number;
    countOfNotReadyForIssue: number;
    countOfReadyForIssue: number;
    countOfDeleted: number;
    countOfNotSigned: number;
}

export interface TotalMonthlyCountOfContracts {
    [key: string]: TotalMonthlyCountOfContractsDTO;
}

export type {
    DigitalContractSignature,
    DigitalContractTemplate,
    EditableDigitalContract,
    EncapsulatedContract,
    IssuedDigitalContract,
    NormalizedContract,
}


export {
    DigitalContractInputFileType, DigitalContractStates, EndDatePolicyType,
    StartDatePolicyType, parseToNormalizedContract
};
