import { AlternateEmail, Business, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, IconButton, ListItemIcon, Typography } from "@mui/material";
import { ContractPartie, ContractPartyIdentification, IdentificationType } from "../../../../../../models/contracts";
import AddFieldButton from "../../../components/AddFieldButton";
import PartyForm from "../forms/PartyForm";

// Interfaces and Types
interface PartiesProps extends React.ComponentProps<any> {
    maxHeight: string
    addPartie: boolean,
    parties: ContractPartie[],
    currentCP: ContractPartyIdentification | null,
    setAddPartie: (addPartie: boolean) => void,
    setParties: (parties: ContractPartie[]) => void
    setCurrentCP: (currentCP: ContractPartyIdentification | null) => void,
    updateFields: (current: ContractPartyIdentification, newCP: ContractPartyIdentification) => void

}

const Parties = (props: PartiesProps): JSX.Element => {

    // Destructure props object
    const { maxHeight, addPartie, parties, currentCP, setAddPartie, setParties, setCurrentCP, updateFields } = props;

    // Handler functions
    function handleAddParty(contractParty: ContractPartyIdentification) {

        const newCP: ContractPartie = {
            identification: contractParty.identification ? contractParty.identification : {},
            identificationType: contractParty.identificationType ? contractParty.identificationType : IdentificationType.IDENTIFIED_BY_EMAIL,
            role: contractParty.role,
            comment: contractParty.comment
        };

        const sortedParties = [...parties];
        sortedParties.push(newCP);
        sortedParties.sort((a, b) => a.role.localeCompare(b.role));

        setParties(sortedParties);
        setAddPartie(false);
    }

    function handleEditParty(contractParty: ContractPartyIdentification) {
        if (!currentCP) return;

        const updatedParties = parties.map(cp => {
            if (cp.role === currentCP.role) {
                const updatedParty: ContractPartie = {
                    identification: contractParty.identification ? contractParty.identification : {},
                    identificationType: contractParty.identificationType ? contractParty.identificationType : IdentificationType.IDENTIFIED_BY_EMAIL,
                    role: contractParty.role,
                    comment: contractParty.comment
                }

                return updatedParty;
            }
            return cp;
        });

        updateFields(currentCP, contractParty);
        setParties(updatedParties);
        setCurrentCP(null);
    }

    function handleOpenEditParty(party: ContractPartie) {
        const editingParty: ContractPartyIdentification = {
            email: "",
            identification: party.identification,
            identificationType: party.identificationType,
            role: party.role,
            comment: party.comment
        };

        setCurrentCP(editingParty);
    }

    function handleDeleteParty(party: ContractPartie) {
        const filteredParties = parties.filter(p => p.role !== party.role);
        setParties(filteredParties);
    }

    return (
        <Box sx={{ maxHeight, overflow: "auto", mb: 3 }}>
            {
                addPartie
                    ?
                    <PartyForm parties={parties} addParty={handleAddParty} cancelAction={() => setAddPartie(false)} />
                    :
                    <AddFieldButton
                        onClick={() => { setAddPartie(true) }}
                        disabled={currentCP !== null || addPartie}
                        title="Adicionar participante"
                    />
            }
            {
                parties.map(party => (
                    <>
                        {
                            currentCP && currentCP.role === party.role
                                ?
                                <PartyForm
                                    currentCP={currentCP}
                                    parties={parties}
                                    cancelAction={() => setCurrentCP(null)}
                                    addParty={handleEditParty}
                                />
                                :
                                <Box
                                    key={party.role}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        py: 1,
                                        px: 2,
                                        border: "1px solid rgba(0, 0, 0, 0.25)",
                                        borderRadius: "5px",
                                        mb: 3
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <ListItemIcon>
                                            {
                                                party.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                                    ?
                                                    <AlternateEmail color="primary" />
                                                    :
                                                    <Business color="primary" />
                                            }
                                        </ListItemIcon>
                                        <Typography >{party.role}</Typography>
                                    </Box>
                                    <Box>
                                        <IconButton onClick={() => handleOpenEditParty(party)} >
                                            <EditOutlined color="primary" />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteParty(party)} >
                                            <DeleteOutline color="error" />
                                        </IconButton>
                                    </Box>
                                </Box>
                        }
                    </>
                ))
            }
        </Box>
    )
}

export default Parties;