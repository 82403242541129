import axios from "axios";
import { CurrentContext } from "../appctx/webappContext";
import { Address } from "../models/address";
import authenticationStorage from "../store/authentication";

//Marks if the application is running on development
const DEV = process.env.NODE_ENV === 'development';

//Create the AccountsApi Axios instance
const ViaCepApi = axios.create({
    baseURL: "https://viacep.com.br/ws",
    timeout: 10000
});

interface ErrorResponse {
    erro : boolean
}

/**
 * Represents the API response from viacep zip code fetch service
 */
interface FetchAddressResponse extends ErrorResponse {
    cep : string;
    logradouro : string;
    complemento : string;
    bairro : string;
    localidade : string;
    uf : string;
}

const BRAServices = {
    async fetchAddressFromAPI(zipCode : string) : Promise<Address> {
        const braAddressResponse : FetchAddressResponse =  await (await ViaCepApi.get(`/${zipCode}/json/`)).data;

        if (braAddressResponse.erro) {
            throw new Error("viacep.com.br returned an error response body");
        }

        return {
            addressNumber : "",
            country : "Brasil",
            district : braAddressResponse.bairro,
            locality : braAddressResponse.localidade,
            publicPlace : braAddressResponse.logradouro,
            state : braAddressResponse.uf,
            zipCode : zipCode,
        }
    }
}



export default BRAServices;