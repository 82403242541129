import { Payments, PersonOutline } from "@mui/icons-material";
import { Box, ListItemIcon, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { BillingPeriodType, BillingSpecification, IssueBilling } from "../../../../../../../models/contracts";
import { SupportedCurrenciesValue } from "../../../../../../../utils/currency";
import { billTypes } from "../../../../../../../utils/editorUtils";

// Interfaces and types
interface IssueFinanceFormProps extends React.ComponentProps<any> {
    billing: BillingSpecification,
    issueBilling: IssueBilling | undefined,
    updateBilling: (value: IssueBilling) => void,
}

const IssueFinanceForm = (props: IssueFinanceFormProps): JSX.Element => {

    // Destructure props object
    const { billing, issueBilling, updateBilling } = props;

    // Data states
    const [billingDate, setBillingDate] = useState<Date | null>(issueBilling ? new Date(issueBilling.initialBillingPeriod) : null);
    const [billingValue, setBillingValue] = useState<number | null>(issueBilling ? Number((issueBilling.value.value / 100).toFixed(2)) : null);
    const [billingInstallments, setBillingInstallments] = useState<number | null>(issueBilling ? issueBilling.numberOfInstallments : null);

    useEffect(() => {
        createBilling();
    }, [billingDate, billingValue, billingInstallments])

    // Functions
    function calculateInstallments() {
        if (!billingValue || !billingInstallments) return;
        const value = billingValue / billingInstallments;
        return value.toFixed(2);
    }

    function createBilling() {
        if (!billingDate || !billingValue) return;
        const newBilling: IssueBilling = {
            billingDueDay: billingDate.getDate(),
            initialBillingPeriod: billingDate.getTime(),
            value: {
                value: SupportedCurrenciesValue.BRL.fromRegionValueToSubunit(billingValue),
                currency: SupportedCurrenciesValue.BRL.currencyName
            },
            name: billing.name,
            numberOfInstallments: billingInstallments ? billingInstallments : 1
        }

        updateBilling(newBilling)
    }

    // Handler functions
    function handleChangeNumberValues(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: "value" | "installement") {

        if (isNaN(Number(e.target.value))) return;

        let value = Number(e.target.value);

        switch (type) {
            case "value":
                if (value < billing.minimumValue) {
                    value = billing.minimumValue;
                }
                else if (value > billing.maximumValue) {
                    value = billing.maximumValue;
                }
                setBillingValue(value);
                break;
            case "installement":
                if (!billing.billingPeriod) return;
                if (value < 1) {
                    value = billing.minimumValue;
                }
                else if (value > billing.billingPeriod.maxInstallments) {
                    value = billing.billingPeriod.maxInstallments;
                }
                setBillingInstallments(value);
                break;
        }

    }

    return (
        <Box sx={{ mb: 6, p: 2, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px" }}>
            <Box sx={{display: "flex", alignItems: "center"}}>
                {
                    billTypes.filter(bType => bType.value === billing.billingPeriodType).map(bType => (
                        <ListItemIcon>
                            {bType.icon}
                        </ListItemIcon>
                    ))
                }
                <Typography>{billing.name}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", my: 3 }}>
                <PersonOutline color="primary" />
                <Typography variant="subtitle1" sx={{ ml: 2 }} >Participante responsável pelo pagamento: <b>{billing.debtorContractParty.role}</b></Typography>
            </Box>
            <Box>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                    <DatePicker
                        label="Data inicial de pagamento"
                        inputFormat="DD/MM/YYYY"
                        value={billingDate}
                        onChange={(value: Moment | null) => { if (value) setBillingDate(value.toDate()) }}
                        renderInput={(params) => <TextField {...params} fullWidth error={!billingDate} helperText={!billingDate ? "Campo obrigatório" : ""} />}
                    />
                </LocalizationProvider>
            </Box>
            <Box sx={{ my: 3 }}>
                <TextField
                    fullWidth
                    required
                    label="Valor do pagamento"
                    value={billingValue}
                    type="number"
                    inputProps={{
                        max: billing.maximumValue,
                        min: billing.minimumValue,
                        step: "any"
                    }}
                    onChange={(e) => handleChangeNumberValues(e, "value")}
                    error={!billingValue} helperText={!billingValue ? "Campo obrigatório" : ""}
                />
            </Box>
            {
                billing.billingPeriodType === BillingPeriodType.INSTALLMENTS
                    ?
                    <Box>
                        <TextField
                            fullWidth
                            required
                            label="Número de parcelas"
                            value={billingInstallments}
                            type="number"
                            inputProps={{
                                max: billing.maximumValue,
                                min: billing.minimumValue,
                                step: 1
                            }}
                            onChange={(e) => handleChangeNumberValues(e, "installement")}
                            error={!billingInstallments} helperText={!billingInstallments ? "Campo obrigatório" : ""}
                        />
                        {
                            billingValue && billingInstallments
                                ?
                                <Box sx={{ mt: 3, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px", p: 2 }}>
                                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                        <Payments color="primary" />
                                        <Typography sx={{ ml: 3 }} >Simulador de parcelas</Typography>
                                    </Box>
                                    <Typography>O valor de cada parcela será de R${calculateInstallments()}</Typography>
                                </Box>
                                :
                                <></>
                        }
                    </Box>
                    :
                    <></>
            }
        </Box>
    )
}

export default IssueFinanceForm;