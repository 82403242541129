import { Block, BorderColorSharp, Check, Close, DoneAll, Download, InsertDriveFileOutlined, Notes, ReportProblemRounded, Warning } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Alert, AlertTitle, Avatar, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Modal, Skeleton, SxProps, Tab, TextField, Theme, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CurrentContext } from "../../../appctx/webappContext";
import AuthenticationContext from "../../../contexts/authentication";
import StlInterceptor from "../../../contexts/stl";
import { ContractParty, DigitalContractSignature, EditableDigitalContract, IdentifiedByEmailContractParty, IssuedContractDocumentType, IssuedDigitalContract, PlatformUserContractParty } from "../../../models/contracts";
import { InterfaceTheme } from "../../../models/organizations";
import { AuthenticatedUser, SessionRoles, UpdateUser } from "../../../models/user";
import { show } from "../../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../../redux/hooks";
import AuthorizationService from "../../../services/authorization";
import { default as ContractsService, default as contractsService } from "../../../services/contracts";
import { SecurityTierLevels } from "../../../store/stl";
import ErrorWrapper from "../../../utils/ErrorWrapper";
import RenewAccessDialog from "../../../views/authentication/RenewAccessDialog";
import ConfirmLoginToSignContract from "../ConfirmLoginToSignContract";
import "../css/Registry.css";
import { CleanCPFOrCNPJ } from "../../../utils/stringUtils";
import { cnpj, cpf } from "cpf-cnpj-validator";

interface ContractProps extends React.ComponentProps<any> {
    source: EditableDigitalContract | IssuedDigitalContract,
    user: AuthenticatedUser | undefined,
    interfaceTheme: InterfaceTheme | null
};

enum SignatureTabs {
    SIGNATURE = "SIGNATURE",
    CONFIRM = "CONFIRM"
}


const WaitingForSignature = (props: ContractProps): JSX.Element => {

    const { source, user, interfaceTheme } = props

    //Verify if the contract is Revoked
    const isRevoked = props.source?.revokedBy !== null && (props.source?.revokedBy || []).length > 0;

    //const contract = props.source as IssuedDigitalContract
    const [contract, setContract] = useState({ ...props.source } as IssuedDigitalContract);
    const [renewSessionDialog, setRenewSessionDialog] = useState(false);
    const [contractPartiesWithPendingSignature, setContractPartiesWithPendingSignature] = useState<ContractParty[]>([]);
    const [searchParam] = useSearchParams();
    const [digitalContractFileDownloadURL, setDigitalContractFileDownloadURL] = useState<string | null>(null);
    const [documentFilename, setDocumentFilename] = useState<string | null>(null);
    const [digitalContractFileRenderURL, setDigitalContractFileRenderURL] = useState<string | null>(null);

    const [isAContractParty, setIsAContractParty] = useState(false);
    const [inCacheUser, setInCacheUser] = useState<AuthenticatedUser>();

    const [contractParties, setContractParties] = useState<(IdentifiedByEmailContractParty | PlatformUserContractParty)[]>([]);
    const [verifiedContractParty, setVerifiedContractParty] = useState<IdentifiedByEmailContractParty | PlatformUserContractParty>();

    const [loadingSignContract, setLoadingSignContract] = useState(false);
    const [loadingDownloadPDF, setLoadingDownloadPDF] = useState(false);
    const [loadingButtonSubmitRequiredInformations, setLoadingButtonSubmitRequiredInformations] = useState(false);
    const [loadingRevokeButton, setLoadingRevokeButton] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showRenewAccessDialog, setShowRenewAccessDialog] = useState(false);
    const [showRenewAccessDialogRemove, setShowRenewAccessDialogRemove] = useState(false);

    // Dialog
    const [revokeDialogIsOpen, setRevokeDialogIsOpen] = useState(false);
    const [removeRevokeDialogIsOpen, setRemoveRevokeDialogIsOpen] = useState(false);
    const [signerInformationDialogIsOpen, setSignerInformationDialogIsOpen] = useState(false);

    const [verifiedCPRevokedThisContract, setVerifiedCPRevokedThisContract] = useState(false);

    // States used to verify if the user in cache already sign the contract
    const [userAlreadySignTheContract, setUserAlreadySignTheContract] = useState(false);
    const [alreadySignedModal, setAlreadySignedModal] = useState(false);

    const [partyHasConfirmedRead, setPartyHasConfirmedRead] = useState(false);
    const [confirmed, setConfirmed] = useState<string[]>([]);

    const [tabValue, setTabValue] = useState<SignatureTabs>((contract.clausesToConfirm !== null && contract.clausesToConfirm.length > 0) ? SignatureTabs.CONFIRM : SignatureTabs.SIGNATURE);
    // States to verify if contractParty can sign
    const [isContractPartyTurnToSign, setIsContractPartyTurnToSign] = useState(false);
    const [signatureOrder, setSignatureOrder] = useState<DigitalContractSignature[] | null>(null)

    const auth = useContext(AuthenticationContext);
    const stl = useContext(StlInterceptor);
    const notification = useAppDispatch();

    // references
    const containerReference = useRef<HTMLElement>();
    const containerConfirmReadContainer = useRef<HTMLElement>();

    const authUrl = `${CurrentContext.authWebappUrl}/login?service=accounts&requiredEmail=${user?.account.email}&redirecUrl=/my-account-security`

    // Effect used to verify if the user in cache already sign the contract
    useEffect(() => {
        if (contract.signatures) {
            contract.signatures.map(s => {
                if (s.userEmail === inCacheUser?.account.email) {
                    setUserAlreadySignTheContract(true);
                    setAlreadySignedModal(true);
                }
            });
        }
    }, [contract, inCacheUser]);

    // effect used to load pending contract parties signatures state
    useEffect(() => {
        // ignore if no signature is set on the contract 
        const signatures = (contract.signatures) ? [...contract.signatures] : [];

        // map the contract parties roles that signed the contract
        const mapOfContractPartiesRolesThatSignedTheContract = signatures.map(s => s.contractParty.role);

        const cpWithPendingSignatures = contract.contractParties.filter(cp => mapOfContractPartiesRolesThatSignedTheContract.indexOf(cp.role) < 0);

        const order = contract.contractPartiesSignatureOrder
        if (order) {
            const indexMap = new Map<string, number>();
            order.forEach((str, index) => {
                indexMap.set(str, index);
            });

            cpWithPendingSignatures.sort((a, b) => {
                const indexA = indexMap.get(a.role) || 0;
                const indexB = indexMap.get(b.role) || 0;
                return indexA - indexB;
            });

        }

        // set the state of the pending signature with only the contract parties that does not have signed the contract yet
        setContractPartiesWithPendingSignature(cpWithPendingSignatures);

        if (contract.state === "SIGNED") {
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    }, [contract]);

    useEffect(() => {
        const contract = props.source as IssuedDigitalContract

        const signatures = contract.signatures;
        const order = contract.contractPartiesSignatureOrder
        if (signatures && order) {
            const indexMap = new Map<string, number>();
            order.forEach((str, index) => {
                indexMap.set(str, index);
            });

            signatures.sort((a, b) => {
                const indexA = indexMap.get(a.contractParty.role) || 0;
                const indexB = indexMap.get(b.contractParty.role) || 0;
                return indexA - indexB;
            });

        }
        setSignatureOrder(signatures);
        contractParties?.forEach(contractParty => {

            // verify if the contract party is identified by email
            if (contractParty.contractPartyIdentificationType === "IDENTIFIED_BY_EMAIL") {
                contractParty = contractParty as IdentifiedByEmailContractParty;

                // Check if the contract party is the same as the contract by its email
                if (contractParty.email === props.user?.account.email) {
                    setVerifiedContractParty(contractParty);

                    // Check if the contract has a signatureOrder if not just set the signature turn to true
                    if (order) {
                        // Check if the contractParty is the first to sign
                        if (order[0] === contractParty.role) {
                            setIsContractPartyTurnToSign(true);
                            return;
                        }
                        let signed: DigitalContractSignature | undefined = undefined;
                        order.forEach(role => {
                            // if the contract has no signatures return as it's not the contractParty turn to sign
                            if (!signatures) return;

                            if (signed && contractParty.role === role) {
                                setIsContractPartyTurnToSign(true);
                            }
                            signed = signatures.find(s => s.contractParty.role === role)
                        })
                    } else {
                        setIsContractPartyTurnToSign(true);
                    }
                }
            }
            // verify if the contract party is a platform user
            else if (contractParty.contractPartyIdentificationType === "PLATFORM_USER") {
                contractParty = contractParty as PlatformUserContractParty;

                // Check if the contract party is the same as the contract by its uuid
                if (contractParty.uuid === props.user?.account.uuid) {
                    setVerifiedContractParty(contractParty);

                    // Check if the contract has a signatureOrder if not just set the signature turn to true
                    if (order) {
                        // Check if the contractParty is the first to sign
                        if (order[0] === contractParty.role) {
                            setIsContractPartyTurnToSign(true);
                            return;
                        }
                        let signed: DigitalContractSignature | undefined = undefined;
                        order.forEach(role => {
                            // if the contract has no signatures return as it's not the contractParty turn to sign
                            if (!signatures) return;
                            if (signed && contractParty.role === role) {
                                setIsContractPartyTurnToSign(true);
                            }
                            signed = signatures.find(s => s.contractParty.role === role)
                        })
                    } else {
                        setIsContractPartyTurnToSign(true);
                    }
                }
            }
        })
    }, [contractParties])

    // effect used to load the URL to download the PDF file
    useEffect(() => {
        ContractsService.createIssuedDigitalContractDocumentDownloadURI(source)
            .then(response => {
                const contract = props.source as IssuedDigitalContract
                setDigitalContractFileDownloadURL(response.uri);
                switch (contract.documentType) {
                    case IssuedContractDocumentType.WORD:
                        setDigitalContractFileRenderURL(`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(response.uri)}`)
                        setDocumentFilename(`${contract.id}.docx`)
                        break;

                    case IssuedContractDocumentType.PDF:
                        setDigitalContractFileRenderURL(response.uri)
                        setDocumentFilename(`${contract.id}.pdf`)
                        break;
                    default:
                        notification(show({ type: 'error', message: `Tipo ${contract.documentType} não é suportado pela plataforma` }))
                }
            })
        fetchUser()
        setContractParties(props.source.contractParties);
    }, []);

    useEffect(() => {
        props.source.contractParties.forEach(cp => {
            if ((cp as PlatformUserContractParty).uuid) {
                if ((cp as PlatformUserContractParty).uuid == inCacheUser?.account.uuid) {
                    setIsAContractParty(true);
                }
            }
            else if ((cp as IdentifiedByEmailContractParty).email) {
                const email = (cp as IdentifiedByEmailContractParty).email.toLowerCase();
                const inCacheEmail = inCacheUser?.account.email.toLowerCase();
                if (email == inCacheEmail) {
                    setIsAContractParty(true);
                }
            }
        })
    })

    useEffect(() => {
        // Verify if the current user revoked the contract
        if (contract.revokedBy && verifiedContractParty) {
            const thisContractPartyRevokedThisContract = contract.revokedBy.some(revoked => {
                return revoked.contractParty.role === verifiedContractParty.role;
            });
            setVerifiedCPRevokedThisContract(thisContractPartyRevokedThisContract);
        }
    }, [verifiedContractParty])

    const buttonTheme: SxProps<Theme> = {
        ['@media(max-width:650px)']: {
            width: "100%"
        },
        ['@media(max-width:350px)']: {
            fontSize: "0.688rem"
        },
        height: "36px",
        width: "210px",
        margin: "14px 0px"
    }

    const boxButtonsTheme: SxProps<Theme> = {
        ['@media(max-width:650px)']: {
            display: "block",
            width: "100%"
        },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }

    const skeletonTheme: SxProps<Theme> = {
        ['@media(max-width:1000px)']: {
            width: "100%"
        },
    }

    async function fetchUser() {
        if (!user) {
            const contractsSessions = await AuthorizationService.me();
            setInCacheUser(contractsSessions);
        } else {
            setInCacheUser(user);
        }
    }

    /**function to sign the contract using ttpca*/
    async function signContract(document: string | null, signatureName: string) {
        setLoadingSignContract(true);

        setRenewSessionDialog(false);
        setSignerInformationDialogIsOpen(false);

        let updateUser: UpdateUser = {
            signatureDocument: document,
            signatureName: signatureName,
        } 

        // fetch the current state of the contracts session from the authenticated user
        let contractsSessions = await auth.updatedUser();

        let canUserSign = false;

        for (let i = 0; i < contractsSessions.session.roles.length; i++) {
            const role: any = contractsSessions.session.roles.at(i)
            if (Object.values(role).includes(SessionRoles.CONTRACT_SAS_SIGN_CONTRACT)) {
                canUserSign = true
                break;
            }
        }

        // If the STL level >= SAME_PERSON try to sign the contract
        if (contractsSessions.session.stl >= SecurityTierLevels.SamePerson || canUserSign) {

            try {
                const signedContract = await contractsService.signContract(contract.id, updateUser);
                notification(show({
                    type: 'success',
                    message: 'Assinado com sucesso.'
                }))
                setContract(signedContract);
            }
            catch (e) {
                const err = new ErrorWrapper(e);
                if (err.message == "You already signed this contract") {
                    notification(show({
                        type: 'warning',
                        message: `Você já assinou esse contrato.`
                    }))
                    return
                } else if (err.httpStatus != 200) {
                    notification(show({
                        type: 'error',
                        message: `Não foi possível assinar o contrato.`
                    }))
                }
            }
            finally {
                setLoadingSignContract(false);
            }

        }
        // If the STL level < MAX_LEVEL show the renew session dialog
        else {
            setRenewSessionDialog(true)
        }
    }

    const handlePartyConfirmRead = (e: React.ChangeEvent<HTMLInputElement>, confirmedObj: string) => {
        if (!contract.clausesToConfirm) return;

        if (e.target.checked) {
            setPartyHasConfirmedRead(confirmed.length + 1 === contract.clausesToConfirm.length);
            setConfirmed([...confirmed, confirmedObj]);
        } else {
            setPartyHasConfirmedRead(false);
            setConfirmed(confirmed.filter(c => c !== confirmedObj));
        }
    }

    const handleCloseSessionDialog = () => {
        setRenewSessionDialog(false)
        setLoadingSignContract(false);
    }

    const AllAcceptablePerspectives = [
        "perspective-info",
        "perspective-doc"
    ]

    /**
     * This function will change the perspective of the application view
     * on mobile interface
     */
    function handlePerspectiveChange(perspective: "perspective-info" | "perspective-doc") {
        if (containerReference.current) {
            // remove all perspective that is not equal than the given
            AllAcceptablePerspectives.forEach(p => {
                if (p !== perspective) containerReference.current?.classList.remove(p);
            })

            containerReference.current.classList.add(perspective);
        }

        if (contract.clausesToConfirm !== null && contract.clausesToConfirm.length > 0) {
            setTabValue(SignatureTabs.CONFIRM);
            if (containerConfirmReadContainer.current) {
                containerConfirmReadContainer.current.classList.add("scale-transform-12");
                setTimeout(() => {
                    if (!containerConfirmReadContainer.current) return;
                    containerConfirmReadContainer.current.classList.remove("scale-transform-12");
                }, 500)
            }
        }
    }

    // function checkSTLAndRevokeContract() {
    //     // check for STL level
    //     setLoading(true);
    //     stl.require(auth, SecurityTierLevels.MaxLevel)
    //         .then(() => {
    //             revokeContract();
    //         })
    //         .catch(() => {
    //             setShowRenewAccessDialog(true);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // }

    function checkSTLAndRemoveRevokeContract() {
        // check for STL level
        setLoading(true);
        stl.require(auth, SecurityTierLevels.MaxLevel)
            .then(() => {
                removeRevokeContract();
            })
            .catch(() => {
                setShowRenewAccessDialogRemove(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    async function revokeContract() {
        if (props.source) {
            setLoadingRevokeButton(true);

            let contractsSessions = await auth.updatedUser();

            let canUserSign = false;

            for (let i = 0; i < contractsSessions.session.roles.length; i++) {
                const role: any = contractsSessions.session.roles.at(i)
                if (Object.values(role).includes(SessionRoles.CONTRACT_SAS_SIGN_CONTRACT)) {
                    canUserSign = true
                    break;
                }
            }
            if (contractsSessions.session.stl >= SecurityTierLevels.SamePerson || canUserSign) {
                try {
                    await ContractsService.revokeIssuedDigitalContract(props.source.id);
                    notification(show({
                        type: 'success',
                        message: 'Revogado com sucesso.'
                    }))
                    setVerifiedCPRevokedThisContract(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } catch (e) {
                    const err = new ErrorWrapper(e)
                    notification(show({
                        type: 'error',
                        message: `Não foi possível revogar o contrato.`
                    }))
                }
                finally {
                    setLoadingRevokeButton(false);
                    setRevokeDialogIsOpen(false);
                    setShowRenewAccessDialog(false);
                }
            }
            else {
                setRenewSessionDialog(true)
            }
            setLoadingRevokeButton(true);
        }
    }

    async function removeRevokeContract() {
        if (props.source) {
            setLoadingRevokeButton(true);

            let contractsSessions = await auth.updatedUser();
            
            let canUserSign = false;

            for (let i = 0; i < contractsSessions.session.roles.length; i++) {
                const role: any = contractsSessions.session.roles.at(i)
                if (Object.values(role).includes(SessionRoles.CONTRACT_SAS_SIGN_CONTRACT)) {
                    canUserSign = true
                    break;
                }
            }
            if (contractsSessions.session.stl >= SecurityTierLevels.SamePerson || canUserSign) {
                try {
                    await ContractsService.removeRevokeIssuedDigitalContract(props.source.id);
                    notification(show({
                        type: 'success',
                        message: 'Revogação revertida com sucesso.'
                    }))
                    setVerifiedCPRevokedThisContract(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } catch (e) {
                    const err = new ErrorWrapper(e)
                    notification(show({
                        type: 'error',
                        message: `Não foi possível reverter a revogação do contrato.`
                    }))
                }
                finally {
                    setLoadingRevokeButton(false);
                    setRevokeDialogIsOpen(false);
                    setShowRenewAccessDialog(false);
                }
            }
            else {
                setRenewSessionDialog(true)
            }
            setLoadingRevokeButton(false);
        }
    }

    // Handle used to close the Already Signed by User modal
    const handleCloseAlreadySignedModal = () => setAlreadySignedModal(false);

    function contractPartyHasRevoked(cp: ContractParty): boolean {
        if (contract.revokedBy) {
            const thisContractPartyRevokedThisContract = contract.revokedBy.some(revoked => {
                return revoked.contractParty.role === cp.role;
            });
            return thisContractPartyRevokedThisContract;
        }
        else {
            return false;
        }
    }

    const SignerInformationDialog = (): JSX.Element => {

        // Loading states
        const [loadingInformation, setLoadingInformation] = useState(true);

        // Boolean states
        const [isGringo, setIsGringo] = useState(false);
        const [isDocumentValid, setIsDocumentValid] = useState(false);
        const [wasInformationChanged, setWasInformationChanged] = useState(false);

        // Data states
        const [signerFullname, setSignerFullname] = useState<string | null>(null);
        const [signerDocument, setSignerDocument] = useState<string | null>(null);
        const [signerDirtyDocument, setSignerDirtyDocument] = useState("");
        const [signerSignatureName, setSignerSignatureName] = useState("");
        const [signerOriginalDocument, setSignerOriginalDocument] = useState("");

        // useEffects
        useEffect(() => {
            fetchInformation();
        }, [])

        useEffect(() => {
            checkIfInformationWasChanged();
        }, [signerFullname, signerDocument])

        // Functions
        function fetchInformation() {
            setLoadingInformation(true);

            auth.updatedUser()
                .then(user => {
                    setSignerFullname(user.account.signatureName ? user.account.signatureName : user.account.fullname);
                    setSignerSignatureName(user.account.signatureName ? user.account.signatureName : '');
                    if (user.account.document) {
                        setSignerDocument(user.account.document);
                        setSignerDirtyDocument(user.account.document);
                        setSignerOriginalDocument(user.account.document);
                        setIsDocumentValid(true);
                    }
                })
                .finally(() => setLoadingInformation(false))

        }
        
        function checkIfInformationWasChanged() {
            let changeDetected = false;

            if (signerFullname !== signerSignatureName) changeDetected = true;
            if (signerDocument !== signerOriginalDocument) changeDetected = true;

            setWasInformationChanged(changeDetected);
        }

        // Handler functions
        function handleChangeDocument(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            setIsDocumentValid(cnpj.isValid(e.target.value) || cpf.isValid(e.target.value))

            setSignerDirtyDocument(e.target.value);
            const normalizedValue = CleanCPFOrCNPJ(e.target.value);
            setSignerDocument(normalizedValue);
        }

        function handleCheckForeigner(e: ChangeEvent<HTMLInputElement>) {
            setIsGringo(e.target.checked);

            if (e.target.checked) {
                setSignerDocument(null);
            } else {
                setSignerDocument(CleanCPFOrCNPJ(signerDirtyDocument));
            }
        }

        function handleChangeSignerFullname(e: ChangeEvent<HTMLInputElement>){
            setSignerFullname(e.target.value);
        }

        return (
            <Dialog open={signerInformationDialogIsOpen} onClose={() => setSignerInformationDialogIsOpen(false)}>
                <DialogTitle>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="subtitle1">Confirme as informações</Typography>
                        <IconButton onClick={() => setSignerInformationDialogIsOpen(false)}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {
                        !loadingInformation
                            ?
                            <>
                                <TextField
                                    required
                                    fullWidth
                                    value={signerFullname}
                                    label="Nome Completo (conforme documento)"
                                    error={!signerFullname}
                                    helperText={!signerFullname ? "Campo obrigatório" : ""}
                                    onChange={handleChangeSignerFullname}
                                    sx={{ my: 2 }}
                                />
                                <Box sx={{display: "flex", alignItems: "flex-end", justifyContent: "space-between", my: 2}}>
                                    <TextField
                                        required
                                        label="CPF ou CNPJ"
                                        value={signerDirtyDocument}
                                        onChange={handleChangeDocument}
                                        error={!signerDirtyDocument && !isGringo}
                                        helperText={!signerDirtyDocument && !isGringo ? "Campo obrigatório" : ""}
                                        disabled={isGringo}
                                    />
                                    <FormGroup>
                                        <FormControlLabel sx={{mx: 2}} label="Sou estrangeiro" control={<Checkbox value={isGringo} onChange={handleCheckForeigner} />} />
                                    </FormGroup>
                                </Box>
                            </>
                            :
                            <CircularProgress color="primary" sx={{ fontSize: "64px" }} />
                    }
                </DialogContent>
                <DialogActions>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <Button variant="text" sx={{mx: 3}} onClick={() => setSignerInformationDialogIsOpen(false)}>
                            Cancelar
                        </Button>
                        <Tooltip title={(!isDocumentValid && !isGringo) ? "O documento não é válido" : ""}>
                            {
                                isDocumentValid && signerDocument && signerFullname
                                ?
                                <Box>
                                    <Button variant="contained" disabled={!isDocumentValid && !isGringo} onClick={() => { 
                                        signContract(signerDocument, signerFullname); 
                                        setSignerInformationDialogIsOpen(false)
                                        }}>
                                        Assinar
                                    </Button>
                                </Box>
                            :
                                <Box>
                                    <Button variant="contained" disabled={!isDocumentValid && !isGringo}>
                                        Assinar
                                    </Button>
                                </Box>
                            }
                        </Tooltip>
                    </Box>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Box ref={containerReference} className="perspective-container perspective-doc">
            {
                (user && !user.account.emailConfirmationDate)
                    ?
                    <Alert severity="warning">
                        <AlertTitle>E-mail não confirmado</AlertTitle>
                        Para assinar este contrato é necessário confirmar o e-mail <br />
                        Para isso basta acessar a <a href={authUrl}>plataforma</a> ou entrar pelo e-mail recebido
                    </Alert>
                    :
                    <></>
            }
            {
                isRevoked
                    ?
                    <Box>
                        <Alert severity="error" sx={{ margin: "2px 0" }}>Contrato não poderá prosseguir para assinatura pois foi revogado por uma das partes.</Alert>
                    </Box>
                    :
                    <></>
            }
            <Grid container spacing={2} className="view-container">
                <Grid item xs={9} className="view-doc" >
                    <Box sx={[{ width: "100%" }]}>
                        {
                            props.source
                                ?
                                <Box sx={{ height: "70vh", display: "inline-block", width: "100%" }}>
                                    <Box sx={boxButtonsTheme}>
                                        {
                                            isContractPartyTurnToSign
                                                ?
                                                (digitalContractFileDownloadURL)
                                                    ?
                                                    <Button
                                                        sx={{ ...buttonTheme, '&:hover': { backgroundColor: interfaceTheme ? interfaceTheme.secondaryColor : "#BF87C4" } }}
                                                        variant="contained"
                                                        component="label"
                                                        disabled={loadingDownloadPDF}
                                                        onClick={() => {
                                                            setLoadingDownloadPDF(true);
                                                            axios.get(digitalContractFileDownloadURL, {
                                                                responseType: "blob"
                                                            }).then(downloadedFile => {
                                                                // create file link in browser's memory
                                                                const href = URL.createObjectURL(downloadedFile.data);

                                                                // create "a" HTML element with href to file & click
                                                                const link = document.createElement('a');
                                                                link.href = href;
                                                                link.setAttribute('download', documentFilename ? documentFilename : "arquivo"); //or any other extension
                                                                document.body.appendChild(link);
                                                                link.click();

                                                                // clean up "a" element & remove ObjectURL
                                                                document.body.removeChild(link);
                                                                URL.revokeObjectURL(href);
                                                            })
                                                                .finally(() => {
                                                                    setLoadingDownloadPDF(false)
                                                                })
                                                        }}
                                                    >
                                                        {
                                                            (loadingDownloadPDF)
                                                                ?
                                                                <CircularProgress size={25} sx={{ color: "#fff" }} />
                                                                :
                                                                <Typography sx={{ display: "flex", fontSize: "14px", color: "#fff", alignItems: "center" }}>
                                                                    <Download sx={{ fontSize: "20px", color: "#fff", mr: "10px" }} />
                                                                    Baixar Contrato
                                                                </Typography>
                                                        }
                                                    </Button>
                                                    :
                                                    <Box></Box>
                                                :
                                                <Box></Box>
                                        }
                                        {
                                            (isAContractParty)
                                                ?
                                                (userAlreadySignTheContract)
                                                    ?
                                                    <Box sx={{ backgroundColor: "green", color: "#fff", padding: "6px 16px", borderRadius: "4px", boxShadow: 3 }}>
                                                        <Typography sx={{ color: "#fff", margin: "0px 4px", alignItems: "center", display: "flex", fontSize: "14px" }}>
                                                            <Check sx={{ mr: 1 }} />Você assinou esse contrato
                                                        </Typography>
                                                    </Box>
                                                    :
                                                    (isRevoked)
                                                        ?
                                                        <Box sx={{ backgroundColor: "#D32F2F", color: "#fff", padding: "6px 16px", borderRadius: "4px", boxShadow: 3 }}>
                                                            <Typography sx={{ color: "#fff", margin: "0px 4px", alignItems: "center", display: "flex", fontSize: "14px" }}>
                                                                <Block sx={{ mr: 1 }} />Contrato revogado
                                                            </Typography>
                                                        </Box>
                                                        :
                                                        (contract.clausesToConfirm === null || contract.clausesToConfirm.length < 1) || partyHasConfirmedRead && isContractPartyTurnToSign
                                                            ?
                                                            <Button
                                                                sx={buttonTheme}
                                                                variant="contained"
                                                                component="label"
                                                                disabled={loadingSignContract}
                                                                onClick={() => setSignerInformationDialogIsOpen(true)}>
                                                                {
                                                                    (renewSessionDialog)
                                                                        ?
                                                                        <CircularProgress size={25} sx={{ color: "#fff" }} />
                                                                        :
                                                                        (loadingSignContract)
                                                                            ?
                                                                            <CircularProgress size={25} sx={{ color: "#fff" }} />
                                                                            :
                                                                            <Typography sx={{ display: "flex", fontSize: "14px", color: "#fff", alignItems: "center" }}>
                                                                                <BorderColorSharp sx={{ fontSize: "18px", color: "#fff", mr: "10px" }} />
                                                                                Assinar Contrato
                                                                            </Typography>
                                                                }
                                                            </Button>
                                                            :
                                                            <Tooltip title={(!isContractPartyTurnToSign) ? "Não é a sua vez de assinar o contrato" : "Para assinar o contrato necessário confirmar alguns items"}>
                                                                <span onClick={() => handlePerspectiveChange("perspective-info")}>
                                                                    <Button
                                                                        sx={buttonTheme}
                                                                        variant="contained"
                                                                        component="label"
                                                                    >
                                                                        {
                                                                            (renewSessionDialog || loadingSignContract)
                                                                                ?
                                                                                <CircularProgress size={25} sx={{ color: "#fff" }} />
                                                                                :
                                                                                <Typography sx={{ display: "flex", fontSize: "14px", color: "#fff", alignItems: "center" }}>
                                                                                    <BorderColorSharp sx={{ fontSize: "18px", color: "#fff", mr: "10px" }} />
                                                                                    Assinar Contrato
                                                                                </Typography>
                                                                        }
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>
                                                :
                                                <Tooltip title="Você não é um participante do contrato">
                                                    <span><Button sx={buttonTheme} variant="contained" disabled><BorderColorSharp sx={{ fontSize: "18px", color: "#fff", mr: "10px" }} />
                                                        Assinar Contrato</Button></span>
                                                </Tooltip>
                                        }
                                        <ConfirmLoginToSignContract sessionRenewed={() => setSignerInformationDialogIsOpen(true)} open={renewSessionDialog} onClose={() => handleCloseSessionDialog()} />
                                    </Box>

                                    {
                                        (digitalContractFileRenderURL)
                                            ?
                                            !contract.contractPartiesSignatureOrder || isContractPartyTurnToSign
                                                ?
                                                <embed
                                                    src={`${digitalContractFileRenderURL}#toolbar=0&navpanes=0&scrollbar=0`}
                                                    width="100%"
                                                    height="875"
                                                ></embed>
                                                :
                                                <Box
                                                    sx={{
                                                        width: "100%",
                                                        height: "75vh",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: "75%",
                                                            height: "100%",
                                                            backgroundColor: "#DDD",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "column"
                                                        }}
                                                    >
                                                        <ReportProblemRounded sx={{ fontSize: "96px" }} />
                                                        <Typography variant="h5">Contrato ainda não disponível</Typography>
                                                        <Typography>Esse contrato possuí uma ordem de assinatura e ainda não é sua vez. Confira ao lado a ordem de assinatura</Typography>
                                                    </Box>
                                                </Box>
                                            :
                                            <Box sx={{ width: "100%" }}>
                                                <Skeleton variant="rectangular" sx={[skeletonTheme, { margin: "auto", width: "650px", height: "800px" }]} />
                                            </Box>
                                    }

                                </Box>
                                :
                                <></>
                        }
                    </Box>
                </Grid >
                <Grid item xs={3} sx={[{ padding: "48px" }]} className="view-info">
                    <Box sx={[{ width: "100%", padding: "24px" }]}>
                        <TabContext value={tabValue}>
                            <Box>
                                {
                                    (contract.clausesToConfirm !== null && contract.clausesToConfirm.length >= 1)
                                        ?
                                        <TabList variant="fullWidth" centered onChange={(e, tab) => setTabValue(tab)}>
                                            <Tab value={SignatureTabs.CONFIRM} label="Confirmação" />
                                            <Tab value={SignatureTabs.SIGNATURE} label="Assinaturas" />
                                        </TabList>
                                        :
                                        <></>
                                }
                            </Box>
                            <TabPanel value={SignatureTabs.CONFIRM} >

                                <Box ref={containerConfirmReadContainer} className="scale-transform" >
                                    <Typography sx={{ fontSize: "1.375rem", fontWeight: "600", marginBottom: "1.2rem", textAlign: "center" }} >Confirmação de Leitura</Typography>
                                    <Typography variant="body2" sx={{ mb: 1 }} >Para a assinatura desse contrato é necessário a confirmação de leitura das cláusulas e / ou itens  do contrato.</Typography>
                                    <Typography variant="body2" >É necessário clicar em todos os itens para confirmar a leitura do conteúdo do documento listados  abaixo:</Typography>
                                </Box>

                                {
                                    contract.clausesToConfirm
                                        ?
                                        <FormGroup>
                                            {
                                                contract.clausesToConfirm.map(clause => (
                                                    <Box sx={{ mt: 2 }}>
                                                        <FormControlLabel control={<Checkbox checked={confirmed.find(c => c === clause) ? true : false} onChange={(e) => handlePartyConfirmRead(e, clause)} />} label={clause} />
                                                        <Divider sx={{ mt: 2 }} />
                                                    </Box>
                                                ))
                                            }
                                        </FormGroup>
                                        :
                                        <></>
                                }

                            </TabPanel>
                            <TabPanel value={SignatureTabs.SIGNATURE}>
                                <Typography sx={{ fontSize: "1.375rem", fontWeight: "600", marginBottom: "1.2rem" }} >Assinaturas e Autenticidade</Typography>
                                {
                                    (contract.signatures && contract.signatures.length > 0)
                                        ?
                                        <>
                                            <Typography variant="h6" >Assinaturas das partes do contrato</Typography>
                                            <List>
                                                {
                                                    contract.signatures.map(s => (
                                                        <React.Fragment key={s.contractParty.role}>
                                                            <ListItem>
                                                                <ListItemAvatar >
                                                                    <Avatar sx={{ bgcolor: "#4CAF50" }}>
                                                                        <DoneAll />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText primary={s.contractParty.role} />
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemText primary="Data de assinatura" secondary={new Date(s.signatureDate).toLocaleString()} />
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemText sx={{ wordBreak: "break-all" }} primary="E-mail utilizado para assinatura" secondary={s.userEmail} />
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemText primary="Endereço IP do assinante" secondary={s.ipAddress} />
                                                            </ListItem>
                                                            <Divider sx={{ mt: 2, mb: 4 }} />
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </List>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    (contractPartiesWithPendingSignature.length > 0)
                                        ?
                                        <>
                                            <Typography variant="h6">Partes com assinatura pendente</Typography>
                                            <List>
                                                {
                                                    contractPartiesWithPendingSignature.map(cps => (
                                                        <React.Fragment key={cps.role}>
                                                            <ListItem>
                                                                <ListItemAvatar >
                                                                    {
                                                                        contractPartyHasRevoked(cps)
                                                                            ?
                                                                            <Avatar sx={{ bgcolor: "#D32F2F" }}>
                                                                                <Block />
                                                                            </Avatar>
                                                                            :
                                                                            <Avatar sx={{ bgcolor: "#ffa940" }}>
                                                                                <Warning />
                                                                            </Avatar>
                                                                    }
                                                                </ListItemAvatar>
                                                                <Box>
                                                                    <Typography>{cps.role}</Typography>
                                                                    {
                                                                        cps.role === verifiedContractParty?.role
                                                                            ?
                                                                            verifiedCPRevokedThisContract
                                                                                ?
                                                                                <Button onClick={() => setRemoveRevokeDialogIsOpen(true)} sx={{ p: 0, fontSize: "12px", color: "#D32F2F", textAlign: "left" }}>Você revogou esse contrato. Clique aqui para reverter a revogação</Button>
                                                                                :
                                                                                <Button onClick={() => setRevokeDialogIsOpen(true)} sx={{ p: 0, fontSize: "12px", color: "#6fa8ff" }}>Revogar contrato</Button>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </Box>
                                                            </ListItem>
                                                            <Divider sx={{ mt: 2, mb: 4 }} />
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </List>

                                        </>
                                        :
                                        <></>
                                }
                                <Typography variant="h6">Informações de autenticidade do contrato</Typography>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Algoritmo de hash do documento" secondary={contract.digitalTrustData.documentHashAlgorithm} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText sx={{ wordBreak: "break-all" }} primary="Valor do hash do documento" secondary={contract.digitalTrustData.documentHashValue} />
                                    </ListItem>
                                </List>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Grid>
            </Grid >
            <Box sx={[{ position: "fixed", left: 20, bottom: 50, }]} className="perspective-button-container">
                <Fab
                    variant="extended"
                    color="primary"
                    onClick={() => handlePerspectiveChange("perspective-info")}
                    className="perspective-trigger show-info"

                >
                    <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                        <Notes sx={{ textAlign: "center", display: "flex" }} />
                    </Box>
                </Fab>
                <Fab
                    variant="extended"
                    color="primary"
                    onClick={() => handlePerspectiveChange("perspective-doc")}
                    className="perspective-trigger show-doc"
                >
                    <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                        <InsertDriveFileOutlined sx={{ textAlign: "center", display: "flex" }} />
                    </Box>
                </Fab>
            </Box >
            <Modal
                open={alreadySignedModal}
                onClose={handleCloseAlreadySignedModal}
            >
                <Box sx={{ position: 'absolute' as 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Typography variant="h6" component="h2" sx={{ fontWeight: 600, display: "flex", alignItems: "center" }}>
                        <Avatar sx={{ bgcolor: "#4CAF50", mr: 2 }}>
                            <DoneAll />
                        </Avatar>
                        Você assinou esse contrato com sucesso
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        Aguarde os outros participantes do contrato assinarem.
                    </Typography>
                </Box>
            </Modal >
            <Dialog open={revokeDialogIsOpen} onClose={() => setRevokeDialogIsOpen(false)}>
                <DialogTitle><b>Revogar contrato?</b></DialogTitle>
                <DialogContent>
                    <Typography>
                        Qualquer parte do contrato pode escolher revogá-lo. Ao revogar um contrato, o sistema impossibilitará que ele avance para a fase de assinatura das partes.
                        <p>Você pode reverter sua solicitação de revogação clicando novamente no link.</p>
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "space-between", p: 2, pt: 0 }}>
                    <Button variant='contained' onClick={() => setRevokeDialogIsOpen(false)} >Cancelar</Button>
                    {
                        loadingRevokeButton
                            ?
                            <Button disabled variant='contained'><CircularProgress size={24} /></Button>
                            :
                            <Button variant='contained' onClick={() => revokeContract()}>Revogar</Button>
                    }
                </DialogActions>
            </Dialog>

            <Dialog open={removeRevokeDialogIsOpen} onClose={() => setRemoveRevokeDialogIsOpen(false)}>
                <DialogTitle><b>Reverter revogação do contrato?</b></DialogTitle>
                <DialogContent>
                    <Typography>
                        Ao reverter sua solicitação de revogação, será possível avançar para a fase de assinatura do contrato.
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "space-between", p: 2, pt: 0 }}>
                    <Button variant='contained' onClick={() => setRemoveRevokeDialogIsOpen(false)} >Cancelar</Button>
                    {
                        loadingRevokeButton
                            ?
                            <Button disabled variant='contained'><CircularProgress size={24} /></Button>
                            :
                            <Button variant='contained' onClick={() => removeRevokeContract()}>Reverter revogação</Button>
                    }
                </DialogActions>
            </Dialog>
            <RenewAccessDialog open={showRenewAccessDialog} sessionRenewed={revokeContract} />
            <RenewAccessDialog open={showRenewAccessDialogRemove} sessionRenewed={removeRevokeContract} />
            <SignerInformationDialog />
        </Box >
    )
}
export default WaitingForSignature;
