import { ArrowDownward, ArrowUpward, Block, Check, DoneAll, Edit, InfoOutlined, Menu as MenuIcon, PeopleAlt, TimerOutlined, WarningAmberRounded } from "@mui/icons-material";
import { Box, Button, Chip, Divider, IconButton, Popover, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AttachmentStatus, DigitalContractAttachment, DigitalContractStates, EditableDigitalContract, EncapsulatedContract, IdentifiedByEmailContractParty, IssuedDigitalContract, NormalizedContract, PlatformUserContractParty } from "../../models/contracts";
import "./contracts-card.css";

import AuthenticationContext from "../../contexts/authentication";
import ContractMenu from "./ContractMenu";

interface ContractWasIssuedCallback {
    (sourceContract: EditableDigitalContract, newContract: IssuedDigitalContract): void;
}

interface ContractWasDeletedCallback {
    (deletedContract: EncapsulatedContract): void;
}

/**
 *  Contract card custom props interface 
 */
interface ContractStatusBadgeProps extends React.ComponentProps<any> {
    source: DigitalContractStates;
};

type ContractViewConfirmation = "none" | "parcial" | "all";

/**
 * Badge used to identify the digital contract status
 * @param props 
 * @returns 
 */
function ContractStatusBadge(props: ContractStatusBadgeProps): JSX.Element {
    const { source, ...otherProps } = props;

    let chip = <></>;

    switch (props.source) {
        case DigitalContractStates.INACTIVE:
            chip = (<Chip label="Inativo" color="default" variant="outlined" size="small" />);
            break;
        case DigitalContractStates.NOT_READY_TO_BE_ISSUED:
            chip = (<Chip label="Aguardando informações" color="info" variant="outlined" size="small" />);
            break;
        case DigitalContractStates.READY_TO_BE_ISSUED:
            chip = (<Chip label="Pronto para enviar para assinatura" color="warning" variant="outlined" size="small" />);
            break;
        case DigitalContractStates.SIGNED:
            chip = (<Chip label="Assinado" color="success" variant="outlined" size="small" />);
            break;
        case DigitalContractStates.WAITING_FOR_SIGNATURES:
            chip = (<Chip label="Aguardando assinaturas" color="error" variant="outlined" size="small" />);
            break;
    }
    return (
        <Box sx={props?.sx} style={props?.style}>
            {chip}
        </Box>
    )
}

/**
 *  Contract card custom props interface 
 */
interface HowLongSinceIssueLabelProps extends React.ComponentProps<any> {
    source: NormalizedContract
};

/**
 * Display a label that indicates how long since the contract was issued
 * @param contract 
 */
function HowLongSinceIssueLabel(props: HowLongSinceIssueLabelProps): JSX.Element {
    let momentSinceIssue = moment(props.source.issuanceDate).fromNow();
    return (
        <Typography variant="caption"><b>Tempo desde a emissão do contrato: </b> {momentSinceIssue.toLocaleLowerCase()}</Typography>
    );
}

/**
 *  Contract card custom props interface 
 */
interface ContractCardProps extends React.ComponentProps<any> {
    source: EncapsulatedContract;
    onContractWasIssued?: ContractWasIssuedCallback;
    onContractDeleted?: ContractWasDeletedCallback;
    showContractTags?: boolean;
};

/**
 * Main component for contract card display
 * @param props 
 * @returns 
 */
const ContractCard = (props: ContractCardProps) => {

    //Context props
    const authUser = useContext(AuthenticationContext);

    // states
    // - Digital contract menu anchor element state manager
    const [menuAE, setMenuAE] = useState<HTMLElement | null>(null);

    // Store application control states
    const [isAContractPartie, setIsAContractPartie] = useState(false);
    const [isAIssuerOfTheContract, setIsAIssuerOfTheContract] = useState(false);
    const [tags, setTags] = useState((props.source.source.contractTags) ? props.source.source.contractTags : []);
    const [isARevokedContract, setIsARevokedContract] = useState(false);
    const [hasApprovalAttachmentsPending, setHasApprovalAttachmentsPending] = useState(false);
    const [contractHasErrors, setContractHasErrors] = useState(false);
    const [isContractAboutToBeDeleted, setIsContractAboutToBeDeleted] = useState(false);
    const [timeUntilDeletion, setTimeUntilDeletion] = useState("");
    const [contractViewConfirmation, setContractViewConfirmation] = useState<ContractViewConfirmation>("none");
    const [showMoreDetails, setShowMoreDetails] = useState(false);

    // Effect used to Iterates over all contracts and checks if the user is the participant of any contracts and if the participant is the issuer of any contracts
    useEffect(() => {
        const contract = props.source.source;
        for (const partie of contract.contractParties) {
            if (partie.contractPartyIdentificationType === "IDENTIFIED_BY_EMAIL") {
                if ((partie as IdentifiedByEmailContractParty).email.toLocaleLowerCase() === authUser.user().account.email.toLocaleLowerCase()) {
                    setIsAContractPartie(true);
                };
            }
            else if (partie.contractPartyIdentificationType === "PLATFORM_USER") {
                if ((partie as PlatformUserContractParty).uuid === authUser.user().account.uuid) {
                    setIsAContractPartie(true);
                }
            }
        }
        if (authUser.user().account.uuid === props.source.source.issuerUuid) {
            setIsAIssuerOfTheContract(true);
        }
        if (props.source.source.revokedBy != null && props.source.source.revokedBy.length > 0) {
            setIsARevokedContract(true);
            setContractHasErrors(true);
        }
        if (props.source.source.attachments &&
            (props.source.source.state === DigitalContractStates.NOT_READY_TO_BE_ISSUED || props.source.source.state === DigitalContractStates.READY_TO_BE_ISSUED) &&
            props.source.source.attachments.some((attachment: DigitalContractAttachment) => attachment.status === AttachmentStatus.PENDING || attachment.status === AttachmentStatus.REPROVED)) {
            setContractHasErrors(true);
            setHasApprovalAttachmentsPending(true);
        }

        if (contract.state !== "SIGNED" && contract.state !== "INACTIVE") {
            if ((contract as EditableDigitalContract).creationDate) {
                const creationDate = new Date((contract as EditableDigitalContract).creationDate)
                const momentCreation = moment(creationDate);
                const deletionDate = momentCreation.clone().add(90, "days");
    
                const difference = deletionDate.diff(moment(), "days");
    
                if (difference <= 10) {
                    setIsContractAboutToBeDeleted(true);
                }
    
                setTimeUntilDeletion(deletionDate.fromNow());
            } else if ((contract as IssuedDigitalContract).issueDate) {
                const issueDate = new Date((contract as IssuedDigitalContract).issueDate)
                const momentIssued = moment(issueDate);
                const deletionDate = momentIssued.clone().add(90, "days");
    
                const difference = deletionDate.diff(moment(), "days");
                
                if (difference <= 10) {
                    setIsContractAboutToBeDeleted(true);
                }
    
                setTimeUntilDeletion(deletionDate.fromNow());
            }
        }

        checkContractViewState();
    }, [])

    /**
     * handle 'onclick' event for IconButton component
     * @param event 
     */
    function handleMenuIconClick(event: React.MouseEvent<HTMLButtonElement>): void {
        setMenuAE(event.currentTarget);
    }

    const TruncatedTextWithTooltip = (): JSX.Element => {
        const { name } = props.source.normalized;
        const maxLength = 77;

        if (name.length > maxLength) {
            const truncatedName = name.substring(0, maxLength) + '...';

            return (
                <Tooltip title={name} arrow>
                    <Typography variant="subtitle2" my={1} sx={{fontWeight: 600}}>{truncatedName}</Typography>
                </Tooltip>
            );
        }

        return <Typography variant="subtitle2" my={1} sx={{fontWeight: 600}}>{name}</Typography>;
    };

    function checkContractViewState() {
        if (Object.keys(props.source.source.accessHistory).length === props.source.source.contractParties.length ||
            props.source.source.contractParties.length == (props.source.source as IssuedDigitalContract).signatures?.length) {
            setContractViewConfirmation("all");
        } else if (
            Object.keys(props.source.source.accessHistory).length === 0 ||
            (props.source.source as IssuedDigitalContract).signatures?.length === 0) {
            setContractViewConfirmation("none");
        } else {
            setContractViewConfirmation("parcial");
        }
    }
    const PartiesThatSigned = (): JSX.Element => {
        const contract = props.source.source as IssuedDigitalContract;

        const [signaturesAE, setSignaturesAE] = useState<HTMLElement | null>(null);

        const [partiesThatHaveNotSigned, setPartiesThatHaveNotSigned] = useState<string[]>([]);

        useEffect(() => {
            if (partiesThatHaveNotSigned.length > 0) return;
            if (!signaturesAE || !contract.signatures) return;

            const parties: string[] = contract.contractParties.map(cp => cp.role);
            const signed: string[] = contract.signatures.map(sig => sig.contractParty.role);

            const notSigned = parties.filter(cp => {
                if(!signed.includes(cp)) return cp;
            });

            setPartiesThatHaveNotSigned(notSigned);

        }, [signaturesAE])

        function getNumberOfPartiesThatSigned(): string {
            const numberOfParties = contract.contractParties.length;
            const numberOfSignatures = contract.signatures ? contract.signatures.length : 0;

            return `${numberOfSignatures} de ${numberOfParties}`;
        }

        function handleOpenSignatures(event: React.MouseEvent<HTMLElement>) {
            setSignaturesAE(event.currentTarget);
        }

        function handleCloseSignatures() {
            setSignaturesAE(null);
        }

        // Returns blank if it's not in the WAITING_FOR_SIGNATURES state
        if (contract.state !== "WAITING_FOR_SIGNATURES") return (<></>);

        return (
            <>
                <Box sx={{ display: "flex", alignItems: "center" }} onMouseEnter={handleOpenSignatures}>
                    <Typography variant="caption">
                        <b>Participantes que assinaram:</b> {getNumberOfPartiesThatSigned()}
                    </Typography>
                    <InfoOutlined color="inherit" htmlColor="#8A8A8A" sx={{ ml: 1, fontSize: "16px" }} />
                </Box>
                <Popover
                    open={Boolean(signaturesAE)}
                    anchorEl={signaturesAE}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleCloseSignatures}
                    disableRestoreFocus

                >
                    {
                        (contract.signatures && contract.signatures.length > 0)
                            ?
                            <Box sx={{ p: 1 }} onMouseLeave={handleCloseSignatures}>
                                <Box>
                                    <Typography variant="subtitle1">Participantes que assinaram o contrato</Typography>
                                    {contract.signatures.map(sig => (
                                        <Typography variant="body2" sx={{ mb: 0.5 }} ><b>{sig.contractParty.role}</b> - {moment(sig.signatureDate).format("DD/MM/YYYY HH:mm:ss")}</Typography>
                                    ))}
                                </Box>
                                <Box sx={{ mt: 1 }}>
                                    <Typography variant="subtitle1">Participantes que não assinaram o contrato</Typography>
                                    {partiesThatHaveNotSigned.map(cp => (
                                        <Typography variant="body2" sx={{ mb: 0.5 }} ><b>{cp}</b></Typography>
                                    ))}
                                </Box>
                            </Box>
                            :
                            <Box sx={{ p: 1 }} onMouseLeave={handleCloseSignatures}>
                                <Typography variant="subtitle1">Nenhum participante assinou o contrato</Typography>
                            </Box>
                    }
                </Popover>
            </>
        )
    }

    return (
        <>
            <Box className={contractHasErrors ? "contract-card-error" : isContractAboutToBeDeleted ? "contract-card-warning" : "contract-card"}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "4px" }}>
                    <ContractStatusBadge
                        source={props.source.normalized.state}
                        sx={{
                            fontSize: '0.75rem',
                            width: "60%"
                        }}
                    />
                    {/* Action Menu*/}
                    <Box>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleMenuIconClick}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Box>
                <TruncatedTextWithTooltip />

                {/** Icons */}
                <Box sx={{ display: "flex", gap: "4px" }}>
                        {
                            contractHasErrors
                                ?
                                isARevokedContract
                                    ?
                                    <Tooltip title="Esse contrato foi revogado por uma das partes do contrato">
                                        <Block sx={{ fontSize: "16px", p: "4px", backgroundColor: "#eeeeee", color: "#D32F2F", borderRadius: "50%" }} />
                                    </Tooltip>
                                    :
                                    hasApprovalAttachmentsPending
                                        ?
                                        <Tooltip title="Esse contrato tem anexos aguardando aprovação">
                                            <Block sx={{ fontSize: "16px", p: "4px", backgroundColor: "#eeeeee", color: "#D32F2F", borderRadius: "50%" }} />
                                        </Tooltip>
                                        :
                                        <></>
                                :
                                isContractAboutToBeDeleted
                                    ?
                                    <Tooltip title="Esse contrato está prestes a ser deletado">
                                        <WarningAmberRounded sx={{ fontSize: "16px", p: "4px", backgroundColor: "#eeeeee", color: "#ffb74d", borderRadius: "50%" }} />
                                    </Tooltip>
                                    :
                                    <></>
                        }
                        {
                            isAIssuerOfTheContract
                                ?
                                <Tooltip title="Você é o emissor desse contrato">
                                    <Edit sx={{ fontSize: "16px", p: "4px", backgroundColor: "#eeeeee", color: "#777", borderRadius: "50%" }} />
                                </Tooltip>
                                :
                                <></>
                        }
                        {
                            isAContractPartie
                                ?
                                <Tooltip title="Você é um participante desse contrato">
                                    <PeopleAlt sx={{ fontSize: "16px", p: "4px", backgroundColor: "#eeeeee", color: "#777", borderRadius: "50%" }} />
                                </Tooltip>
                                :
                                <></>
                        }
                        {
                            contractViewConfirmation === "all"
                                ?
                                <Tooltip title="Todos participantes visualizaram o contrato">
                                    <DoneAll sx={{ fontSize: "16px", p: "4px", backgroundColor: "#eeeeee", borderRadius: "50%" }} color="primary" />
                                </Tooltip>
                                :
                                <></>
                        }
                        {
                            contractViewConfirmation === "parcial"
                                ?
                                <Tooltip title="Alguns participantes visualizaram o contrato">
                                    <Check sx={{ fontSize: "16px", p: "4px", backgroundColor: "#eeeeee", borderRadius: "50%" }} color="secondary" />
                                </Tooltip>
                                :
                                <></>
                        }
                        {
                            contractViewConfirmation === "none"
                                ?
                                <Tooltip title="Nenhum dos participantes visualizou o contrato">
                                    <TimerOutlined sx={{ fontSize: "16px", p: "4px", backgroundColor: "#eeeeee", color: "#777", borderRadius: "50%" }} />
                                </Tooltip>
                                :
                                <></>
                        }
                    </Box>
                <Divider light sx={{my: 1}}/>

                {/* line 2 : description and menu */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* descriptions*/}
                    <Box sx={{ width: "100%" }}>
                        <Typography variant="caption"><b>Data de emissão: </b> {props.source.normalized.issuanceDate.toLocaleString()}</Typography><br />
                        {
                            (props.source.normalized.fullySignedDate && props.source.normalized.state === DigitalContractStates.SIGNED)
                                ?
                                <><Typography variant="caption" ><b>Data de assinatura: </b> {new Date(props.source.normalized.fullySignedDate).toLocaleString()}</Typography><br /></>
                                :
                                <></>
                        }
                        <PartiesThatSigned />
                        <HowLongSinceIssueLabel source={props.source.normalized} /><br />
                        {
                            props.source.normalized.state !== "SIGNED" && props.source.normalized.state !== "INACTIVE"
                                ?
                                <>
                                    <Typography variant="caption"><b>Tempo até a deleção:</b> {timeUntilDeletion}</Typography><br />
                                </>
                                :
                                <></>
                        }

                        {/** All items that will be displayed when show more details is true */}
                        {
                            showMoreDetails 
                            ? 
                                <>
                                   <Typography variant="caption"><b>ID do contrato: </b> {props.source.normalized.id}</Typography><br />
                                </>
                            :
                                <></>
                        }

                        {/** Show more details */}
                        <Button variant="text" sx={{display: "flex", justifyContent:"space-between", width: "100%", padding: 0, my: 1}} onClick={()=> setShowMoreDetails(!showMoreDetails)}>
                            <Typography sx={{fontSize: "12px", color: "#888"}}>Ver mais detalhes</Typography>
                            {
                                showMoreDetails
                                ?
                                    <ArrowUpward sx={{color: "#888", fontSize: "16px"}}/>
                                :
                                    <ArrowDownward sx={{color: "#888", fontSize: "16px"}}/>
                            }
                        </Button>
                        {
                            (tags.length > 0 && props.showContractTags)
                                ?
                                <>
                                    <Divider sx={{ my: 1 , maxWidth: "100%" }} />
                                    <Box sx={{ display: (props.source.source.contractTags ? "flex" : "none"), maxWidth: "100%" }}>
                                        {
                                            // If there is at maximum of 3 tags, show each one...
                                            (tags.length <= 3)
                                                ?
                                                tags.map((t, i) => {
                                                    return (
                                                        <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "primary.main", mr: 1, borderRadius: "12px", justifyContent: "center", width: "33%", height: "18px" }}>
                                                            <Tooltip title={t.tagName}>
                                                                <Typography sx={{ fontSize: "10px", color: "#fff", margin: "0 16px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{t.tagName}</Typography>
                                                            </Tooltip>
                                                        </Box>
                                                    )
                                                })
                                                :
                                                // otherwise, display all of them into an single tag and displaying each tag using tooltip
                                                <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "primary.main", mr: 1, borderRadius: "12px", justifyContent: "center", width: "33%", height: "18px" }}>
                                                    <Tooltip title={tags.map(t => t.tagName).join(", ")}>
                                                        <Typography sx={{ fontSize: "10px", color: "#fff", margin: "0 16px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Ver tags...</Typography>
                                                    </Tooltip>
                                                </Box>
                                        }
                                    </Box>
                                </>
                                :
                                <></>
                        }
                    </Box>
                </Box>
            </Box>
            {/* Menu to manage the contracts */}
            <ContractMenu
                anchorElement={menuAE}
                setAnchorElement={setMenuAE}
                setTags={setTags}
                source={props.source}
                onContractDeleted={props.onContractDeleted}
                onContractWasIssued={props.onContractWasIssued}
            />

        </>
    )
}

export { ContractCard, ContractStatusBadge };