import { pdf } from "@react-pdf/renderer";
import axios from "axios";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import ContractsService from "../services/contracts";
import SignatureCertificate from "../standalones/registry/certificate/SignatureCertificate";
import { IssuedContractDocumentType, IssuedDigitalContract } from "./contracts";

interface BlobsOfContractPromise {
    originalcontract: Blob,
    signatureCertificate: Blob,
    watermarkAndSignature: Blob,
    isOriginalContractEncrypted: boolean
}

let isOriginalContractEncrypted = false;

/**
 * function that receives the contract, makes the blobs and return the blob of the 3 contracts
 * Original Contract, Signature Certificate and the Watermark Contract with Signature Certificate
 */
export async function GeneratePDF(contract: IssuedDigitalContract): Promise<BlobsOfContractPromise> {

    async function getOriginalDocumentDownloadLink(contract: IssuedDigitalContract) {
        const downloadUri = await ContractsService.downloadDocumentByHash(contract.digitalTrustData.documentHashAlgorithm, contract.digitalTrustData.documentHashValue);
        const downloadLink = downloadUri.toString();

        return downloadLink;
    }

    const originalDocumentDownloadLink = await getOriginalDocumentDownloadLink(contract);

    const signatureCertificatePDFContent = <SignatureCertificate source={contract} originalDocumentDownloadLink={originalDocumentDownloadLink} />

    /**
     * Set the blobs in the respective variables, and notice that in each one have an await 
     * to guarantee that the result is the promise of the previous one
     */
    const digitalContractFileDownloadURL = await loadURLDownload(contract);
    const originalDocumentBlob = await setOriginalContract(digitalContractFileDownloadURL);
    const signatureCertificatePDFContentBlob = await setSignatureCertificate(signatureCertificatePDFContent);
    let watermarkAndSignatureCertificateBlob: Blob;

    switch (contract.documentType) {
        case IssuedContractDocumentType.WORD:
            let printableDigitalContractFileDownloadURL = await loadPrintableContractURLDownload(contract);
            watermarkAndSignatureCertificateBlob = await setOriginalContract(printableDigitalContractFileDownloadURL);
            break;
        case IssuedContractDocumentType.PDF:
            watermarkAndSignatureCertificateBlob = await createWatermarkVersion(originalDocumentBlob, signatureCertificatePDFContentBlob, contract);
            break;
    }

    return new Promise((resolve, reject) => {
        const originalcontract = originalDocumentBlob;
        const signatureCertificate = signatureCertificatePDFContentBlob;
        const watermarkAndSignature = watermarkAndSignatureCertificateBlob;

        const blobs: BlobsOfContractPromise = {
            originalcontract,
            signatureCertificate,
            watermarkAndSignature,
            isOriginalContractEncrypted
            };

        resolve(blobs);
    })
}

/** Create the version of the contract with the Original PDF with Cartorizi - {contradId} footer in all pages 
 * and add in the final of the contract the Signature Certificate
 */
export async function createWatermarkVersion(originalPdfBlob: Blob, signatureCertificatePdfBlob: Blob, contract: IssuedDigitalContract): Promise<Blob> {

    // create the modified version based on the original
    let modifiedPdfWithWatermarks = await PDFDocument.load(await originalPdfBlob.arrayBuffer(), { ignoreEncryption: true });

    if (modifiedPdfWithWatermarks.isEncrypted) {
        // If the content is encrypted return ony the signature page
        const mergedPdf = await PDFDocument.create();
        const signatureCertificatePdf = await PDFDocument.load(await signatureCertificatePdfBlob.arrayBuffer(), { ignoreEncryption: true });
        const pdfPages = await mergedPdf.copyPages(signatureCertificatePdf, signatureCertificatePdf.getPageIndices());
        pdfPages.forEach((page) => mergedPdf.addPage(page));

        const mergedPdfBytes = await mergedPdf.save();

        isOriginalContractEncrypted = true;
        return (new Blob([mergedPdfBytes], { type: 'application/pdf' }))
    }

    // we will iterate over each page from the original to add the Cartorizi watermark
    const fontBold = await modifiedPdfWithWatermarks.embedFont(StandardFonts.HelveticaBold);
    const fontNormal = await modifiedPdfWithWatermarks.embedFont(StandardFonts.Helvetica);

    const boldText = "Cartorizi"
    const normalText = " - " + contract.id;
    const pages = await modifiedPdfWithWatermarks.getPages();
    for (let i = 0; i < pages.length; i++) {
        const page = pages[i];

        const { width } = page.getSize();
        const boldTextSize = {
            width: fontBold.widthOfTextAtSize(boldText, 10),
            height: fontBold.heightAtSize(10)
        }
        const normalTextSize = {
            width: fontNormal.widthOfTextAtSize(normalText, 10),
            height: fontNormal.heightAtSize(10)
        }
        const totalTextWidth = boldTextSize.width + normalTextSize.width;

        page.drawText(boldText, {
            x: width / 2 - totalTextWidth / 2,
            y: 10,
            size: 10,
            font: fontBold,
            color: rgb(.5, .5, .5),
        });
        page.drawText(normalText, {
            x: width / 2 - totalTextWidth / 2 + boldTextSize.width,
            y: 10,
            size: 10,
            font: fontNormal,
            color: rgb(.5, .5, .5),
        });
    }

    // merge the watermark with the signature certificate
    const mergedPdf = await PDFDocument.create();

    const signatureCertificatePdf = await PDFDocument.load(await signatureCertificatePdfBlob.arrayBuffer(), { ignoreEncryption: true });

    const pdf1Pages = await mergedPdf.copyPages(modifiedPdfWithWatermarks, modifiedPdfWithWatermarks.getPageIndices());
    const pdf2Pages = await mergedPdf.copyPages(signatureCertificatePdf, signatureCertificatePdf.getPageIndices());

    pdf1Pages.forEach((page) => mergedPdf.addPage(page));
    pdf2Pages.forEach((page) => mergedPdf.addPage(page));

    const mergedPdfBytes = await mergedPdf.save();
    return (new Blob([mergedPdfBytes], { type: 'application/pdf' }))
}

/** Merge the Original PDF with watermark with the Signature Certified
 */
export function downloadTheWatermarkAndSignatureContract(watermarkAndSignatureCertificateBlob: Blob, contract: IssuedDigitalContract) {
    if (!watermarkAndSignatureCertificateBlob) {
        console.error("watermarkAndSignatureCertificateBlob is undefined");
        return;
    }

    const url = window.URL.createObjectURL(watermarkAndSignatureCertificateBlob);

    const filename = contract.documentType === IssuedContractDocumentType.PDF ? `${contract.id}.pdf` : `${contract.id}.docx`

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Contrato Timbrado - ${filename}`);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

/** Download the Original PDF file
 * Hello programmer. Are you thinking that blob undefined is strange? Think twice. Remove it and you will thank me for doing that @GustavoProcopio.
 */
export function downloadOriginalFile(blob: Blob | undefined, contract: IssuedDigitalContract) {
    if (!blob) {
        return;
    }

    // create file link in browser's memory
    const href = URL.createObjectURL(blob);

    const filename = contract.documentType === IssuedContractDocumentType.PDF ? `${contract.id}.pdf` : `${contract.id}.docx`

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}

/** Function used to load the URL to download the original PDF file
 */
async function loadURLDownload(contract: IssuedDigitalContract): Promise<string> {
    return ContractsService.getOriginalDocumentDownloadURI(contract.id)
        .then(response => {
            return response.uri;
        })
}

async function loadPrintableContractURLDownload(contract: IssuedDigitalContract): Promise<string> {
    if (contract.state === "SIGNED" && contract.documentType === IssuedContractDocumentType.WORD) {
        return ContractsService.generateDownloadURIForPrintableDigitalContract(contract.id).then(response => {
            return response.uri;
        })
    } else {
        return ContractsService.createIssuedDigitalContractDocumentDownloadURI(contract)
            .then(response => {
                return response.uri;
            })
    }

}

/** Function used to transform the digitalContractFileDownloadURL in a blob of the contract
 */
export async function setOriginalContract(digitalContractFileDownloadURL: string): Promise<Blob> {
    return axios.get(`${digitalContractFileDownloadURL}`, {
        responseType: "blob"
    }).then(response => {
        const downloadedFile = response.data as Blob;
        return downloadedFile;
    });
}

/** Function used to transform the signatureCertificatePDFContent from the JSX Element in a blob of the contract
 */
export async function setSignatureCertificate(signatureCertificatePDFContent: JSX.Element): Promise<Blob> {
    return pdf(signatureCertificatePDFContent).toBlob()
        .then(blob => {
            return blob;
        })
        .catch((error) => {
            return error;
        });
}
