import { AccountCircle, AdminPanelSettings, Groups, Search } from "@mui/icons-material";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AuthenticationContext from "../contexts/authentication";
import { SearchResult } from "../models/search-result";
import AuthorizationService from "../services/authorization";
import AccountsSearchService from "../services/search";

interface SearchBarProps extends React.ComponentProps<any> {
    searchMode?: 'teams' | 'accounts-users' | 'contracts-users',
    rederInputProps?: React.ComponentProps<any>
}

const SearchBar = (props: SearchBarProps): JSX.Element => {

    // Contexts
    const authUser = useContext(AuthenticationContext);

    //Global state props
    const [queryText, setQueryText] = useState("");
    const [searchResult, setSearchResult] = useState<SearchResult[]>([]);

    useEffect(() => {
        if (queryText.length === 0) return setSearchResult([]);

        //Set a timeout to fetch results
        if (keyDownTimeout) clearTimeout(keyDownTimeout);
        setKeyDownTimeout(setTimeout(() => {
            if (queryText.length >= 2) fetchSearchResults();
        }, 600));

    }, [queryText])

    useEffect(() => {
        if (props.searchMode && props.searchMode !== "contracts-users" && searchResult.length > 0) return;

        const fixedOptions: SearchResult[] = [];

        if (authUser.user().session.accountType === "CUSTOMER") {
            const fixedOption: SearchResult = {
                description: `${authUser.user().account.preferredName} (Você)`,
                id: authUser.user().account.uuid,
                object: authUser.user().account,
                subdescription: authUser.user().account.email,
                type: "ORGANIZATION_ACCOUNT"
              };
            fixedOptions.push(fixedOption);
        } else {
            const fixedOption: SearchResult = {
                description: `${authUser.user().account.preferredName} (Você)`,
                id: authUser.user().account.uuid,
                object: authUser.user().account,
                subdescription: authUser.user().account.email,
                type: "ORGANIZATION_SUBUSER_ACCOUNT"
            };

            fixedOptions.push(fixedOption)

            AuthorizationService.getSubuserOrganizationOwner()
                .then(response => {
                    const organizationAccount = {
                        contractsRestrictViewModeEnabled: false,
                        creationDate: response.creationDate,
                        customerOrAdministrator: true,
                        deletedAt: null,
                        email: response.email,
                        fullname: response.fullname,
                        hasHandwrittenSignature: false,
                        prefferedName: response.preferredName,
                        preferredRegion: response.user.preferredRegion,
                        uuid: response.uuid,
                    }
                    const option: SearchResult = {
                        description: `${organizationAccount.prefferedName} (Sua Organização)`,
                        id: organizationAccount.uuid,
                        object: organizationAccount,
                        subdescription: organizationAccount.email,
                        type: "ORGANIZATION_ACCOUNT",
                    }
                    fixedOptions.push(option)
                })
        }
        setSearchResult(fixedOptions);
    }, [])

    //Component props
    let { searchMode, renderInputProps, ...otherProps } = props;

    //Set default values for component props
    searchMode = (searchMode) ? searchMode : 'teams';
    renderInputProps = (renderInputProps) ? renderInputProps : {};

    //Store the timeout state as React state
    const [keyDownTimeout, setKeyDownTimeout] = useState<NodeJS.Timeout | null>(null);

    //Store the loading state
    const [loading, setLoading] = useState(false)

    //Events
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //Update the query text prop
        setQueryText(event.target.value);
    }

    //Inner components
    function CustomAvatar(searchResult: SearchResult): JSX.Element | null {
        switch (searchResult.type) {
            case 'ORGANIZATION_ACCOUNT':
                return <AdminPanelSettings color='primary' sx={{ fontSize: 27 }} />;
            case 'ORGANIZATION_SUBUSER_ACCOUNT':
                return <AccountCircle color='primary' />;
            case 'ORGANIZATION_TEAM':
                return <Groups color='primary' />;
            default:
                return null;
        }
    }

    //Global events
    const fetchSearchResults = () => {
        switch (searchMode) {
            case 'teams':
                setLoading(true);
                AccountsSearchService.searchAllTeamsFromKlausAccounts(queryText)
                    .then(response => {
                        if (!response) return setSearchResult([]);
                        setSearchResult(response);
                    })
                    .catch(e => console.error(e))
                    .finally(() => setLoading(false))
                break;
            case 'accounts-users':
                setLoading(true);
                AccountsSearchService.searchAllUsersFromKlausAccounts(queryText)
                    .then(response => {
                        if (!response) return setSearchResult([]);
                        setSearchResult(response);
                    })
                    .catch(e => console.error(e))
                    .finally(() => setLoading(false))
                break;
            case 'contracts-users':
                setLoading(true);
                AccountsSearchService.searchAllOrganizationUsers(queryText)
                    .then(response => {
                        if (!response) return setSearchResult([]);

                        //Filter deleted users
                        const filteredSearchResult: SearchResult[] = []
                        response.forEach(resp => { if (!resp.object.deletedAt) filteredSearchResult.push(resp) })
                        setSearchResult(filteredSearchResult);
                    })
                    .catch(e => console.error(e))
                    .finally(() => setLoading(false))
                break;
            default:
                throw Error(`Unknown/Not supported search mode: ${searchMode}`);
        }
    };

    return (
        <Autocomplete
            {...otherProps}
            options={searchResult}
            getOptionLabel={(option: SearchResult) => option.description}
            filterOptions={(x) => x}
            loading={loading}
            noOptionsText={'Sem dados'}
            loadingText={'Buscando...'}
            renderInput={(params) => (
                <TextField {...params} {...renderInputProps} onChange={onChange} className="c-white" InputProps={{
                    ...params.InputProps,
                    startAdornment: <span><Search sx={{ color: 'secondary.light' }} /> {params.InputProps.startAdornment}</span>
                }} />
            )}
            renderOption={(props, option) => {
                return (
                    <Box sx={{ '&:hover': { bgcolor: '#E7F3FF' } }} key={option.id}>
                        <li {...props} style={{ borderBottom: '1px solid #E9E9E9' }}>
                            <Box display='flex' flexDirection='row' alignItems='center' >
                                {CustomAvatar(option)}
                                <Box display='flex' flexDirection='column' ml={3}>
                                    <Typography color='text.primary' >
                                        {option.description}
                                    </Typography>
                                    <Typography color='text.secondary' fontSize={12}>
                                        {option.subdescription}
                                    </Typography>
                                </Box>
                            </Box>
                        </li>
                    </Box>
                );
            }
            }
        />
    );

}

export default SearchBar;