import { ContactInformation, GenerateContactDTO, UpdateContactDTO } from "../models/contacts";
import { Pagination } from "../models/pagination";
import ContractsApi from "./contracts-api";

const ContactsService = {

    /**
     * Fetch the pagination metadata for the contacts
     * @param limit 
     * @param searchCriteria 
     * @returns 
     */
    async fetchContactsPagination(limit: number, searchCriteria: string | null = null): Promise<Pagination> {
        let url = `/rest/v1/contacts/pagination?limit=${limit}`;

        if (searchCriteria !== null) {
            url += `&searchCriteria=${searchCriteria}`
        }

        const response = await ContractsApi.get(url);

        return await response.data;
    },

    /**
     * Fetch the contacts using pagination
     * @param page 
     * @param limit 
     * @param searchCriteria 
     * @returns 
     */
    async fetchContactsWithPagination(page: number, limit: number, searchCriteria: string | null = null): Promise<ContactInformation[]> {
        let url = `/rest/v1/contacts/?page=${page}&limit=${limit}`;

        if (searchCriteria !== null) {
            url += `&searchCriteria=${searchCriteria}`
        }

        const response = await ContractsApi.get(url);

        return await response.data;
    },

    /**
     * Update the desired contact
     * @param contactId 
     * @param dto 
     * @returns 
     */
    async updateContact(contactId: string, dto: UpdateContactDTO): Promise<ContactInformation> {
        const response = await ContractsApi.put(`/rest/v1/contacts/${contactId}`, dto);

        return await response.data;
    },

    /**
     * Delete the desired contact
     * @param contactId 
     * @returns 
     */
    async deleteContact(contactId: string): Promise<ContactInformation> {
        const response = await ContractsApi.delete(`/rest/v1/contacts/${contactId}`);

        return await response.data;
    },

    /**
     * Fetch the date from the desired contact
     * @param contactId 
     * @returns 
     */
    async fetchContactById(contactId: string): Promise<ContactInformation> {
        const response = await ContractsApi.get(`/rest/v1/contacts/${contactId}`);

        return await response.data;
    },

    /**
     * Generate a contact using the desired contract party
     * @param contractId 
     * @param dto 
     * @returns 
     */
    async registerContactByContractParty(contractId: string, dto: GenerateContactDTO): Promise<ContactInformation> {
        const response = await ContractsApi.post(`/rest/v1/contacts/create-from-contract-party/${contractId}`, dto);

        return await response.data;
    },

    /**
     * Generate contacts from all contract parties in the provided contract
     * @param contractId 
     * @returns 
     */
    async registerContactsFromContract(contractId: string): Promise<ContactInformation[]> {
        const response = await ContractsApi.post(`/rest/v1/contacts/create-from-contract/${contractId}`);

        return await response.data;
    },

    /**
     * Search for contacts matching the searchTerm
     * @param searchTerm 
     */
    async searchContacts(searchTerm: string): Promise<ContactInformation[]> {
        const response = await ContractsApi.get(`/rest/v1/contacts/search?searchTerm=${searchTerm}`);

        return await response.data;
    },

    async findByIdentification(identification: string): Promise<ContactInformation> {
        const response = await ContractsApi.get(`/rest/v1/contacts/find-by-identification?identification=${identification}`);

        return await response.data;
    }

};

export default ContactsService;