import { SupportedCurrencies } from "../utils/currency";


export enum Plans {
    NO_QUOTA = "NO_QUOTA",
    PAY_AS_YOU_GO = "PAY_AS_YOU_GO",
    BASIC = "BASIC",
    SMALL_BUSINESS = "SMALL_BUSINESS",
    BUSINESS = "BUSINESS"
}

export interface ContractsQuota {
    customerContractsQuota: {
        maxTemplateCount: number,
        maxSubusers: number,
        contractsIssuedPerMonth: number,
        maxStorageBytes: number,
        financialAnalyticsModuleEnabled: boolean,
        contractsFieldsAutomationEnabled: boolean,
        contractAttachmentsEnabled: boolean,
        interfaceThemesCustomizationEnabled: boolean,
        plan: Plans
    },
    currentTemplateCount: number,
    currentSubusersCount: number,
    currentStorageConsumptionInBytes: number,
    contractsIssuedPerMonth: number
}

export interface FinanceChargeAnalytics {
    value : number,
    currency : SupportedCurrencies,
    regionalValue : number,
    regionalString : string,
}

export interface SubscriptionChargeAnalytics {
    currencyValue: FinanceChargeAnalytics,
    endDate: string,
    totalEntries: number
}

export interface GranularityDataset<T> {
    [label : string] : T,
}

export interface InterfaceColors {
    primaryColor: string,
    secondaryColor: string
}

export interface InterfaceTheme {
    primaryColor: string,
    secondaryColor: string,
    logoUrl: string
}
export interface OrganizationPreferences {
    contractsRestrictViewModeEnabled: boolean,
    organizationPresentationName: string
}

export type CurrencyGroupingFinanceChargeAnalytics = GranularityDataset<GranularityDataset<FinanceChargeAnalytics>>;

export type CurrencyGroupingSubscriptionChargeAnalytics = GranularityDataset<GranularityDataset<SubscriptionChargeAnalytics[]>>;

export type CurrencyGroupingFinanceChargeCount = GranularityDataset<GranularityDataset<number>>;

export enum Regions {
    BRA = "BRA"
}

export interface CreateUserAccount {
    email: string,
    fullname: string,
    preferredName: string,
    preferredRegion: Regions,
    roles: string[],
    document?: string | null
}
