import { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { EditableDigitalContract, IssuedDigitalContract } from "../../models/contracts";
import ContractsService from "../../services/contracts" 
import ErrorWrapper from "../../utils/ErrorWrapper";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { useAppDispatch } from "../../redux/hooks";

interface OnCloseCallback {
    (contractWasIssued : boolean, newContract : IssuedDigitalContract | undefined) : void
}

interface SendContractToIssuedStateProps extends React.ComponentProps<any> {
    open : boolean;
    source : EditableDigitalContract;
    onClose : OnCloseCallback;
}

const SendContractToIssuedStateDialog = (props : SendContractToIssuedStateProps) : JSX.Element => {

    // states
    // - state : store the created contract
    const [createdContract, setCreatedContract] = useState<IssuedDigitalContract | undefined>(undefined);

    // - states : application control states
    const [loading, setLoading] = useState(false);

    const notification = useAppDispatch();

    // effect that observe if the createdContract state prop received an value. If it does, this will trigger the close callback
    useEffect(() => {
        if (createdContract) {
            props.onClose(true, createdContract);
        }
    }, [createdContract]);

    /**
     * Function that handles the inner <Dialog> onClose event. This function will call the onClose callback
     */
    function handleDialogOnCloseEvent() {
        props.onClose(false, undefined);
    }

    /**
     * Function that will create the issued contract based on the editable contract
     */
    function createIssuedContract() {
        setLoading(true);
        ContractsService.createIssuedContractFromEditable(props.source.id)
        .then(response => {
            setCreatedContract(response);
        })
        .catch((e: Error) => {
            const err = new ErrorWrapper(e);
            notification(show({
                type: 'error',
                message: err.message
            }));
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleDialogOnCloseEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Enviar contrato para assinatura?
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Deseja enviar o contrato <b>{props.source.name}</b> para assinatura?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogOnCloseEvent} disabled={loading}>Cancelar</Button>
                {
                    (!loading) 
                    ?
                    <Button onClick={createIssuedContract} autoFocus>
                        Enviar
                    </Button>
                    :
                    <CircularProgress size="small" />
                }
                
            </DialogActions>
        </Dialog>
    );
}

export default SendContractToIssuedStateDialog;