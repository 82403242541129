import { AdminPanelSettings, Person, Search, SupervisedUserCircle } from "@mui/icons-material";
import { Autocomplete, ListItemIcon, ListItemText, MenuItem, TextField } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import AuthenticationContext from "../contexts/authentication";
import AuthorizationService from "../services/authorization";
import SearchService from "../services/search";
import { EditorSearchResult, EditorSearchResultType } from "../utils/editorUtils";
import { LoadingState } from "../utils/types";

// Interfaces and Types
interface SearchUsersProps extends React.ComponentProps<any> {
    value: string,
    onResourceClick: (resource: EditorSearchResult) => void,
    errors: any,
    inputChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    limiter?: "platform-users"
}

const SearchUsers = (props: SearchUsersProps): JSX.Element => {

    // Destructure props object
    const { onResourceClick, value, errors, inputChange, limiter } = props;

    // Contexts
    const auth = useContext(AuthenticationContext);

    // Static variables
    const userSearchOption: EditorSearchResult = {
        description: `${auth.user().account.preferredName} (Você)`,
        id: auth.user().account.uuid,
        object: auth.user().account,
        subdescription: auth.user().account.email,
        type: auth.user().session.accountType === "CUSTOMER" ? EditorSearchResultType.ORGANIZATION_ACCOUNT : EditorSearchResultType.ORGANIZATION_SUBUSER_ACCOUNT
    }
    const SEARCH_TIMEOUT = 1200;

    // Refs
    let lastTimeout = useRef<NodeJS.Timeout | null>(null);

    // Loading states
    const [fetchOptions, setFetchOptions] = useState<LoadingState>("loaded");

    // Data states
    const [fixedOptions, setFixedOptions] = useState<EditorSearchResult[]>([userSearchOption]);
    const [searchResult, setSearchResult] = useState<EditorSearchResult[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    // UseEffects
    useEffect(() => {
        getFixedOptions();
    }, [])

    useEffect(() => {
        searchAllEntitiesBySearchTerm();
    }, [searchTerm])

    // Functions
    function getFixedOptions() {
        if (userSearchOption.type === EditorSearchResultType.ORGANIZATION_ACCOUNT) {
            setSearchResult([userSearchOption]);
            return;
        }
        setFetchOptions("loading");
        AuthorizationService.getSubuserOrganizationOwner()
            .then(response => {
                const organizationAccount = {
                    contractsRestrictViewModeEnabled: false,
                    creationDate: response.creationDate,
                    customerOrAdministrator: true,
                    deletedAt: null,
                    email: response.email,
                    fullname: response.fullname,
                    hasHandwrittenSignature: false,
                    prefferedName: response.preferredName,
                    preferredRegion: response.user.preferredRegion,
                    uuid: response.uuid,
                }
                const option: EditorSearchResult = {
                    description: `${organizationAccount.prefferedName} (Sua Organização)`,
                    id: organizationAccount.uuid,
                    object: organizationAccount,
                    subdescription: organizationAccount.email,
                    type: EditorSearchResultType.ORGANIZATION_ACCOUNT,
                }
                setFixedOptions([...fixedOptions, option]);
                setSearchResult([...fixedOptions, option]);
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => setFetchOptions("loaded"));
    }

    function searchAllEntitiesBySearchTerm() {
        if (lastTimeout.current) {
            clearTimeout(lastTimeout.current)
        }

        lastTimeout.current = setTimeout(() => {

            if (searchTerm.length === 0 || fetchOptions === "loading") {
                return;
            }

            setFetchOptions("loading");
            setSearchResult([]);

            SearchService.searchAllEntities(searchTerm)
                .then(response => {
                    if (!response) {
                        setSearchResult([]);
                        return;
                    }

                    let searchResults = response.map(r => {
                        const foundItem = fixedOptions.find(o => o.id === r.id);
                        if (foundItem) return foundItem;
                        return r;
                    })

                    if (limiter === "platform-users") {
                        searchResults = searchResults.filter(sr => sr.type !== EditorSearchResultType.ORGANIZATION_TEAM)
                    }

                    setSearchResult(searchResults);
                })
                .catch(e => console.error(e))
                .finally(() => setFetchOptions("loaded"));

        }, SEARCH_TIMEOUT)
    }

    function CustomAvatar(searchResult: EditorSearchResult): JSX.Element {
        switch (searchResult.type) {
            case EditorSearchResultType.ORGANIZATION_ACCOUNT:
                return <AdminPanelSettings />;
            case EditorSearchResultType.ORGANIZATION_SUBUSER_ACCOUNT:
                return <Person />;
            case EditorSearchResultType.ORGANIZATION_TEAM:
                return <SupervisedUserCircle />;
            default:
                return <></>;
        }
    }

    // Handler functions
    function handleInputChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setSearchTerm(e.target.value);
        setSearchResult([userSearchOption]);
        inputChange(e);
    }

    function handleSelectOption(option: EditorSearchResult) {
        onResourceClick(option);
        setSearchResult(fixedOptions);
    }

    return (
        <Autocomplete
            fullWidth
            disableCloseOnSelect
            noOptionsText={"Sem resultados"}
            loading={fetchOptions === "loading"}
            loadingText={'Buscando...'}
            options={searchResult}
            filterOptions={(x) => x}
            renderOption={(props, option, { selected }) => {
                return (
                    <MenuItem {...props} onClick={() => handleSelectOption(option)}>
                        <ListItemIcon>
                            {CustomAvatar(option)}
                        </ListItemIcon>
                        <ListItemText primary={option.description} secondary={option.subdescription} />
                    </MenuItem>
                );
            }
            }
            inputValue={value}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Selecione o membro"
                    placeholder="Busque pelo nome do usuário ou do time"
                    onChange={handleInputChange}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <span><Search sx={{ color: props.style ? "black" : "white" }} />{params.InputProps.startAdornment}</span>
                    }}
                    required
                    error={Boolean(errors["identification"])}
                    helperText={errors["identification"]}
                />
            }
            sx={{ mb: 6 }}
        />
    )
}

export default SearchUsers