import { Description, Menu as MenuIcon } from "@mui/icons-material";
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { ActionButton } from "../../../../utils/editorUtils";
import { DigitalContractTemplate } from "../../../../models/contracts";

// Interfaces and Types
interface EditorNavbarProps extends React.ComponentProps<any> {
    title: string,
    actionButtons: ActionButton[],
    subtitle?: string
}

const EditorNavbar = (props: EditorNavbarProps): JSX.Element => {

    // Destructure props object
    const { actionButtons, title, subtitle } = props;

    // Anchor states
    const [menuAE, setMenuAE] = useState<HTMLElement | null>(null);

    return (
        <Box
            sx={{
                width: "100%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
                position: "fixed",
                top: "64px",
                zIndex: 100,
                minHeight: "64px",
                backgroundColor: "#FFF",
                display: "flex",
                alignItems: "center"
            }}
        >
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{px: 2, py: 1, width: "100%"}}>
                <Box display={"flex"} alignItems={"center"}>
                    <Description color="primary" sx={{ mr: 3 }} />
                    <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main }} >{title} <b>{subtitle}</b></Typography>
                </Box>
                <Box
                    sx={{
                        display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    {
                        actionButtons.map((action, i) => (
                            <Button
                                variant="contained"
                                onClick={() => action.onClick()}
                                sx={{ mr: 2 }}
                                startIcon={action.icon}
                                disabled={action.disabled}
                                key={i}
                            >
                                {action.label}
                            </Button>
                        ))
                    }
                </Box>
                <Box
                    sx={{
                        display: { md: "flex", lg: "none" },
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Button variant="contained" onClick={(e) => setMenuAE(e.currentTarget)} startIcon={<MenuIcon />} >
                        Ações
                    </Button>
                    <Menu
                        open={Boolean(menuAE)}
                        anchorEl={menuAE}
                        onClose={() => setMenuAE(null)}
                    >
                        {
                            actionButtons.map((action, i) => (
                                <MenuItem key={i} onClick={() => action.onClick()} disabled={action.disabled}>
                                    <ListItemIcon>
                                        {action.icon}
                                    </ListItemIcon>
                                    <ListItemText>
                                        {action.label}
                                    </ListItemText>
                                </MenuItem>
                            ))
                        }
                    </Menu>
                </Box>
            </Box>
        </Box>
    )
}

export default EditorNavbar;