import { Close, Edit, Email, ExpandLess, ExpandMore, Star, StarOutline, TextFields } from '@mui/icons-material';
import { Box, Button, Collapse, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EmailTypes, MailTemplate } from '../../../../models/contracts';
import { InterfaceTheme } from '../../../../models/organizations';

// Interfaces and Types
interface EmailEditorProps extends React.ComponentProps<any> {
    interfaceTheme: InterfaceTheme,
    mailTemplates: MailTemplate[],
    updateMailTemplates: (value: string, emailType: EmailTypes) => void,
}

// Default variables
const toolbarOptions = [
    [{ 'bold': true }, { 'italic': true }, { 'underline': true }, { 'strike': true }],
    [{ 'header': 1 }, { 'header': 2 }, 'blockquote'],
    [{ 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }],
    ['clean']
];

const EmailEditor = (props: EmailEditorProps): JSX.Element => {

    // Destructure props object
    const { interfaceTheme, mailTemplates, updateMailTemplates } = props;

    // Boolean states
    const [openWildcardsTips, setOpenWildcardsTips] = useState(true);
    const [openMailPreview, setOpenMailPreview] = useState(false);

    // Data states
    const [mailType, setMailType] = useState<EmailTypes | null>(null);
    const [content, setContent] = useState("");

    // useEffects
    useEffect(() => {
        initializeQuill();
    }, [])

    // Functions
    function initializeQuill() {
        // Register Quill modules
        const backgroundStyle = Quill.import('attributors/style/background');
        const colorStyle = Quill.import('attributors/style/color');
        const sizeStyle = Quill.import('attributors/style/size');
        const alignStyle = Quill.import('attributors/style/align');
        Quill.register(backgroundStyle, true);
        Quill.register(colorStyle, true);
        Quill.register(sizeStyle, true);
        Quill.register(alignStyle, true);
    };

    function isMailTemplateCustomized(mailType: EmailTypes) {
        const foundMailTemplate = mailTemplates.find(mt => mt.mailType === mailType);

        if (foundMailTemplate) {
            return (
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }} >
                    <Star color="success" />
                    <Typography variant="caption" sx={{ color: (theme) => theme.palette.success.main, ml: 3 }}>E-mail personalizado</Typography>
                </Box>
            )
        } else {
            return (
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }} >
                    <StarOutline />
                    <Typography variant="caption" sx={{ ml: 3 }} >E-mail não possui personalização</Typography>
                </Box>
            )
        }
    }

    function getEditMailTitle() {
        if (!mailType) return;

        switch (mailType) {
            case EmailTypes.ISSUE_CONTRACT:
                return (
                    <>
                        <Box display={"flex"} alignItems={"center"}>
                            <Edit color="primary" sx={{ fontSize: "32px" }} />
                            <Typography sx={{ ml: 2 }} >Personalização do e-mail de emissão</Typography>
                        </Box>
                        <Typography variant="caption">Esse e-mail é enviado quando um contrato é emitido utilizando um modelo</Typography>
                    </>
                )
            case EmailTypes.CHANGE_STATES:
                return (
                    <>
                        <Box display={"flex"} alignItems={"center"}>
                            <Edit color="primary" sx={{ fontSize: "32px" }} />
                            <Typography sx={{ ml: 2 }} >Personalização do e-mail de aguardando assinaturas</Typography>
                        </Box>
                        <Typography variant="caption">Esse e-mail é enviado quando um contrato está aguardando assinaturas dos participantes</Typography>
                    </>
                )
            case EmailTypes.FULLY_SIGNED:
                return (
                    <>
                        <Box display={"flex"} alignItems={"center"}>
                            <Edit color="primary" sx={{ fontSize: "32px" }} />
                            <Typography sx={{ ml: 2 }} >Contrato totalmente assinado</Typography>
                        </Box>
                        <Typography variant="caption">Esse e-mail é enviado quando um contrato foi assinado por todos os seus participantes</Typography>
                    </>
                )
            default:
                return (
                    <></>
                )
        }
    }

    // Handler functions
    function handleEditMailTemplate(emailType: EmailTypes) {
        const foundTemplate = mailTemplates.find(mt => mt.mailType === emailType);

        if (foundTemplate) {
            setContent(foundTemplate.mailTemplate);
        } else {
            setContent("");
        }

        setMailType(emailType);
    }

    function handleUpdateMailTemplate() {
        if (!mailType) return;
        if (content === "<p><br></p>") updateMailTemplates("", mailType);
        else updateMailTemplates(content, mailType);
        setMailType(null);
    }

    // JSX Components
    function DisplayPreview(): JSX.Element {
        return (
            <Dialog open={openMailPreview} onClose={() => setOpenMailPreview(false)} maxWidth="lg" fullWidth>
                <DialogTitle sx={{ textAlign: "end" }}>
                    <IconButton onClick={() => setOpenMailPreview(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div style={{ width: "700px", backgroundColor: "#fff", padding: "2rem", borderRadius: "15px", margin: "auto", boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.4)" }}>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ marginBottom: "2rem" }}>
                                <img src={interfaceTheme.logoUrl} alt="Cartorizi" style={{ width: "150px" }} />
                            </div>
                            <h1 style={{ color: interfaceTheme.primaryColor }}>Assinatura do contrato: {"{contract.name}"}</h1>
                            <p>Olá, seu contrato com a <b>{"{organization.name}"}</b> foi emitido.</p>
                            <p>
                                Você foi adicionado como <b>{"{contractParty.role}"}</b> do contrato <b>{"{contract.name}"}</b>, para visualizar o seu contrato clique no botão abaixo.
                            </p>
                            <p style={{ margin: "36px" }}>
                                <a href="#" style={{ backgroundColor: interfaceTheme.secondaryColor, color: "white", padding: "8px 16px", borderRadius: "4px", textDecoration: "none" }}>
                                    Clique aqui para ver o contrato
                                </a>
                            </p>
                            <div style={{ textAlign: "start" }}><p dangerouslySetInnerHTML={{ __html: content }} style={{maxWidth: "700px", textOverflow: "ellipsis"}} ></p></div>
                            <hr style={{ margin: "16px" }} />
                            <p>
                                <b>Caso não reconheça ou não queira assinar este contrato, Apenas ignore-o. Sem o seu consentimento o contrato não terá nenhum valor jurídico.</b>
                            </p>
                            <p>Se tiver problemas ou em caso de dúvidas <a href={"#"}>contate nosso suporte</a>.</p>
                            <hr style={{ margin: "16px" }} />
                            <h4 style={{ color: "#d4a6fc" }}>Sobre o Cartorizi</h4>
                            <p style={{ color: "#a1a1a1", fontSize: "12px" }}>
                                A Cartorizi é uma plataforma de contratos digitais que permite a criação, assinatura e gerenciamento de documentos e acordos de forma rápida e segura.
                                Com a Cartorizi, é possível automatizar processos e garantir a validade jurídica dos contratos, já que todas as etapas são realizadas de maneira digital e registradas em blockchain.
                                Além disso, a plataforma oferece uma interface intuitiva e fácil de usar, tornando o processo de criação de contratos acessível para usuários com pouco conhecimento técnico.
                                Com a Cartorizi, é possível economizar tempo e dinheiro, além de garantir a segurança e a validade dos contratos.
                            </p>
                            <h4 style={{ color: "#d4a6fc" }}>Por quê assinaturas digitais são confiáveis?</h4>
                            <p style={{ color: "#a1a1a1", fontSize: "12px" }}>
                                A Cartorizi utiliza hashs para garantir a autenticidade de seus documentos. As assinaturas com hash são consideradas confiáveis porque utilizam uma função matemática para gerar um resumo único e imutável do documento original.
                                Esse resumo, chamado de "hash", é gerado a partir do conteúdo do documento e serve como uma "assinatura digital" do mesmo.
                                Quando um documento é assinado com hash, o assinante gera o hash do documento original e o assina digitalmente, confirmando sua autenticidade.
                                Se o documento for alterado posteriormente, o hash gerado será diferente do original e a assinatura tornar-se-á inválida.
                                Desta forma, as assinaturas com hash garantem a integridade e a autenticidade dos documentos, tornando-os confiáveis e seguros.
                            </p>
                        </div>
                    </div>
                </DialogContent >
            </Dialog >
        )
    }

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            {
                !mailType
                    ?
                    <Box>
                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                            <Email color="primary" sx={{ fontSize: "32px" }} />
                            <Typography sx={{ ml: 2 }} >Personalização de E-mail</Typography>
                        </Box>
                        <Grid container>
                            <Grid item md={12} lg={4}>
                                <Typography>Emissão de contrato</Typography>
                                <Typography variant="caption">Esse e-mail é enviado quando um contrato é emitido utilizando um modelo</Typography>
                                {isMailTemplateCustomized(EmailTypes.ISSUE_CONTRACT)}
                                <Button variant="contained" sx={{ mt: 3 }} endIcon={<Edit />} onClick={() => handleEditMailTemplate(EmailTypes.ISSUE_CONTRACT)} >Editar</Button>
                            </Grid>
                            <Grid item md={12} lg={4}>
                                <Typography>Aguardando assinaturas</Typography>
                                <Typography variant="caption">Esse e-mail é enviado quando um contrato está aguardando assinaturas dos participantes</Typography>
                                {isMailTemplateCustomized(EmailTypes.CHANGE_STATES)}
                                <Button variant="contained" sx={{ mt: 3 }} endIcon={<Edit />} onClick={() => handleEditMailTemplate(EmailTypes.CHANGE_STATES)} >Editar</Button>
                            </Grid>
                            <Grid item md={12} lg={4}>
                                <Typography>Contrato totalmente assinado</Typography>
                                <Typography variant="caption">Esse e-mail é enviado quando um contrato foi assinado por todos os seus participantes</Typography>
                                {isMailTemplateCustomized(EmailTypes.FULLY_SIGNED)}
                                <Button variant="contained" sx={{ mt: 3 }} endIcon={<Edit />} onClick={() => handleEditMailTemplate(EmailTypes.FULLY_SIGNED)} >Editar</Button>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <Box>
                        {getEditMailTitle()}
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ mt: 3, width: "100%" }} >
                            <Box display={"flex"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => setOpenWildcardsTips(!openWildcardsTips)}>
                                <TextFields color="primary" sx={{ fontSize: "32px" }} />
                                <Typography sx={{ ml: 2 }} >Coringas</Typography>
                                {
                                    openWildcardsTips
                                        ?
                                        <ExpandLess sx={{ ml: 6 }} />
                                        :
                                        <ExpandMore sx={{ ml: 6 }} />
                                }
                            </Box>
                            <Button variant="outlined" onClick={() => setOpenMailPreview(true)}>Visualizar Prévia de e-mail</Button>
                        </Box>
                        <Collapse in={openWildcardsTips} sx={{ mt: 2 }}>
                            <Typography >Na edição dos e-mails podem utilizados alguns coringas para definir os valores
                                com base no contrato</Typography>
                            <Typography sx={{ mt: 1 }} ><b>{"{contract.name}"}</b> - Nome do contrato emitido.</Typography>
                            <Typography sx={{ mt: 1 }} ><b>{"{contractParty.role}"}</b> - Nome do participante. ex: Contratante, Testemunha,
                                Contratada, etc...</Typography>
                            <Typography sx={{ mt: 1 }} ><b>{"{organization.name}"}</b> - Nome de apresentação da organização</Typography>
                        </Collapse>
                        <Box sx={{ mt: 3 }}>
                            <ReactQuill
                                theme="snow"
                                value={content}
                                onChange={(e) => setContent(e)}
                                modules={{
                                    toolbar: toolbarOptions,
                                }}
                                style={{minHeight: "300px", height: "100%"}}
                            />
                        </Box>
                        <Box sx={{ width: "100", display: "flex", alignItems: "center", justifyContent: "space-between", mt: 3 }}>
                            <Button variant="text" onClick={() => setMailType(null)} >Voltar</Button>
                            <Button variant="contained" onClick={handleUpdateMailTemplate} >Salvar</Button>
                        </Box>
                    </Box>
            }
            <DisplayPreview />
        </Box>
    )
}

export default EmailEditor;