import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { IssuedDigitalContract } from "../../models/contracts";
import { useNavigate } from "react-router-dom";

interface OnCloseCallback {
    (contractWasRenewed: boolean): void
}

interface RenewContractStateProps extends React.ComponentProps<any> {
    open: boolean;
    source: IssuedDigitalContract;
    onClose: OnCloseCallback;
}

const RenewIssuedDigitalContractDialog = (props: RenewContractStateProps): JSX.Element => {

    /**
     * Function that handles the inner <Dialog> onClose event. This function will call the onClose callback
     */
    function handleDialogOnCloseEvent() {
        props.onClose(false);
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleDialogOnCloseEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Renovar contrato?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Deseja <b>renovar</b> o contrato <b>{props.source.name}</b>? Ao renovar esse contrato, o contrato atual será <b>inativado</b> e um novo contrato será gerado.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogOnCloseEvent}>Cancelar</Button>
                <Button onClick={() => window.location.replace(`/renew-contract/${props.source.id}`)} autoFocus color="warning">Renovar contrato</Button>
            </DialogActions>
        </Dialog>
    );
}

export default RenewIssuedDigitalContractDialog;