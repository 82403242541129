import { Container, Typography } from "@mui/material";
import { BillingSpecification, IssueBilling } from "../../../../../../../models/contracts";
import IssueFinanceForm from "../forms/IssueFinanceForm";

// Interfaces and Types
interface IssueFinanceProps extends React.ComponentProps<any> {
    billings: BillingSpecification[],
    issueBillings: IssueBilling[],
    updateBillings: (value: IssueBilling) => void
}

const IssueFinance = (props: IssueFinanceProps): JSX.Element => {

    // Destructure props object
    const { billings, issueBillings, updateBillings } = props;

    return (
        <Container>
            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >
                Cobranças financeiras do contrato
            </Typography>
            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                Defina os valores e datas iniciais de pagamento das cobranças
            </Typography>
            {
                billings.map(bill => (
                    <IssueFinanceForm billing={bill} issueBilling={issueBillings.find(b => b.name === bill.name)} updateBilling={updateBillings} />
                ))
            }
        </Container>
    )
}

export default IssueFinance;