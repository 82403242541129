interface IIndexable {
    [key : string] : any
}

const ObjectUtils = {
    deepEquals : (source : IIndexable, field : string, value : any) : boolean => {

        // this two variable will store the comparison source object and its comparison field
        let curCmpObject = source;
        let curCmpObjectField = field;

        // this loop will iterate in the nested fields of the current compared object and field
        // for each occurrence of '.' splitting the field name. For example:
        // billing.name will iterate two times:
        let compositeFieldSeparatorIndex = curCmpObjectField.indexOf(".");
        while(compositeFieldSeparatorIndex >= 0) {

            // using the 'billing.name' example:
            // this block of code will store the billing object of the source as the current comparison object
            // anb the 'name' as the current comparison field name
            const firstPartOfTheField = curCmpObjectField.substring(0, compositeFieldSeparatorIndex);
            curCmpObject = curCmpObject[firstPartOfTheField];
            curCmpObjectField = curCmpObjectField.substring(compositeFieldSeparatorIndex + 1, curCmpObjectField.length);

            // if the current object do not exists, return false
            if (!curCmpObject){
                return false;
            }

            // check if there is more nested fields on the field name
            compositeFieldSeparatorIndex = curCmpObjectField.indexOf(".");
        }

        // if the current comparison object is an array, return any result that matches the value
        // otherwise compare using an simple object approach
        if (Array.isArray(curCmpObject)) {
            return curCmpObject.findIndex(c => c[curCmpObjectField] === value) >= 0;
        }
        else {
            return curCmpObject[curCmpObjectField] === value;
        }
    }
}

export {
    ObjectUtils
}