import { AccountCircle, ArrowDownward } from "@mui/icons-material";
import { Box, Button, Dialog, Grid, Switch, Typography } from "@mui/material";
import ProgressBar from "../../components/ProgressBar";
import { LoadingButton } from "../../components/LoadingButton";
import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { useState } from "react";
import { OrganizationTeam, SelectedMember } from "../../models/teams";
import TeamsService from "../../services/teams";
import ErrorWrapper from "../../utils/ErrorWrapper";

interface SelectMembersToImportProps extends React.ComponentProps<any> {
    currentSubusers: Number,
    maxSubusers: Number,
    importedMembers: SelectedMember[] | null,
    selectedTeam: string | null,
    open: boolean,
    close: () => void,
    onSuccess: (importedTeam: OrganizationTeam) => void,
    updateQuota: () => void

}

const SelectMembersToImport = (props: SelectMembersToImportProps): JSX.Element => {

    const {currentSubusers, maxSubusers, importedMembers, selectedTeam, open, close, onSuccess, updateQuota} = props;

    //Context props
    const notification = useAppDispatch();

    const [loading, setLoading] = useState(false)
    const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set())

    let userConsumptionPercentage = ((Number(currentSubusers) / Number(maxSubusers)) * 100).toFixed(4)

    if (Number(userConsumptionPercentage) > 100) userConsumptionPercentage = "100"
    
    const [userConsumptionAfterImportPercentage, setUserConsumptionAfterImportPercetage] = useState(userConsumptionPercentage)

    const importTeam = async () => {
        if (!selectedTeam) return;
        setLoading(true);

        // Prepare an aux matriz and put one of each selectedMembers in it
        const members:string[] = [];
        selectedMembers.forEach(member => {
            members.push(member)
        });
        
        try {
            //Import Klaus Accounts team into Contracts plataform
            const importedTeam = await TeamsService.import({
                teamId: selectedTeam,
                membersUuid: members
            })

            //Notify user and close drawer
            notification(show({ type: 'success', message: `Time importado` }))
            onSuccess(importedTeam);
            close();
        }
        catch (e) {
            notification(show({
                type: 'error',
                message: `Ocorreu um erro: ${new ErrorWrapper(e).message}`
            }))
        }
        finally { 
            setLoading(false);
            updateQuota();
        }
    }
        
    const handleChangeImportUser = (e: React.ChangeEvent<HTMLInputElement>, memberUuid: string) => {
        
        if (!importedMembers) return;

        let newSelectedMembers
        if (e.target.checked) {
            newSelectedMembers = new Set(selectedMembers)
            newSelectedMembers.add(memberUuid)

            if (Number(currentSubusers) + newSelectedMembers.size > Number(maxSubusers)) {
            
                notification(show({
                    type: 'error',
                    message: `Limite de cota atingido não é possivel adicionar mais usuários`
                }))
                return;
            }   

            setSelectedMembers(newSelectedMembers)
        } else {
            newSelectedMembers = new Set(selectedMembers)
            newSelectedMembers.delete(memberUuid)
            setSelectedMembers(newSelectedMembers)
        }
        importedMembers.forEach(member => {
            if (member.user.uuid === memberUuid) member.importUser = e.target.checked;
        })
        
        let percentage = (((Number(currentSubusers) + newSelectedMembers.size) / Number(maxSubusers)) * 100).toFixed(4)

        if (Number(percentage) > 100) percentage = "100"

        setUserConsumptionAfterImportPercetage(percentage)
    }

    const handleCloseImportDialog = () => {
        const newSet: Set<string> = new Set()
        setSelectedMembers(newSet)
        setUserConsumptionAfterImportPercetage(userConsumptionPercentage)
        close()
    }

    return (
        <Dialog open={open} fullScreen >
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                <Typography my={3} variant="h5">Selecione os membros que você deseja importar</Typography>
                {
                    importedMembers
                    ?
                        <Box>
                            <Grid container spacing={2} sx={{maxWidth: "1000px", maxHeight: "400px", overflow: "scroll"}}>
                                {importedMembers.map(member => (
                                    <Grid item md={6} xs={12} mt={2} sx={{display: "flex", flexDirection: "column"}} key={member.user.uuid}>
                                        <Box ml={2} sx={{display: "flex"}}>
                                            <AccountCircle sx={{width: 48, height: 48}} />
                                            <Box ml={2}>
                                                <Typography>{member.user.preferredName}</Typography>
                                                <Typography>{member.user.email}</Typography>
                                                <Typography>{member.user.uuid}</Typography>
                                            </Box>
                                        </Box>
                                        <Box ml={2} sx={{display: "flex", alignItems: "center"}}>
                                            <Typography sx={{fontSize: "12px"}}>Importar Membro</Typography>
                                            <Switch checked={member.importUser} onChange={(e) => handleChangeImportUser(e, member.user.uuid)}/>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                            <Box mt={3} sx={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                                <Typography variant="subtitle1" mb={1} >Cota de usuários antes da importação</Typography>
                                <ProgressBar 
                                    percentageString={userConsumptionPercentage} 
                                    currentValue={Number(currentSubusers)} 
                                    maxValue={Number(maxSubusers)}
                                />
                                <ArrowDownward />
                                <Typography variant="subtitle1" mb={1} >Cota de usuários após a importação</Typography>
                                <ProgressBar 
                                    percentageString={userConsumptionAfterImportPercentage} 
                                    currentValue={Number(currentSubusers) + selectedMembers.size} 
                                    maxValue={Number(maxSubusers)}
                                />
                            </Box>
                            <Box mt={3} sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <Button onClick={() => handleCloseImportDialog()} color='error'>Cancelar</Button>
                                <LoadingButton variant='contained' loading={loading} onClick={importTeam}>Salvar</LoadingButton>
                            </Box>
                        </Box>
                    :
                        <></>
                }
            </Box>
        </Dialog>
    )
}

export default SelectMembersToImport