import { Close, ReportProblemRounded } from "@mui/icons-material"
import { Dialog, DialogTitle, IconButton, Box, Typography } from "@mui/material"

interface DialogProps extends React.ComponentProps<any> {
    open: boolean,
    close: () => void
}

const ContractNotFoundDialog = (props: DialogProps): JSX.Element => {

    // Destructure the props object
    const { open, close } = props

    return (
        <Dialog open={open} onClose={close}  >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme: any) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>

            <Box m={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
                <Typography variant="h6" >Contrato não encontrado</Typography>
                <Box
                    my={2}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 100,
                        height: 100,
                        backgroundColor: "red",
                        borderRadius: 5
                    }}
                >
                    <ReportProblemRounded color="disabled" sx={{ fontSize: 75 }} />
                </Box>
                <Typography>O contrato pode ter sido deletado pela organização</Typography>
            </Box>
        </Dialog>
    )
}

export default ContractNotFoundDialog