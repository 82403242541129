import { DeleteOutline, EditOutlined } from "@mui/icons-material"
import { Box, IconButton, ListItemIcon, Tooltip, Typography } from "@mui/material"
import { ContractPartie, RequiredInformationSpecification } from "../../../../../../models/contracts"
import { riTypes } from "../../../../../../utils/editorUtils"
import AddFieldButton from "../../../components/AddFieldButton"
import RIForm from "../forms/RIForm"

// Interfaces and Types
interface RequiredInformationsProps extends React.ComponentProps<any> {
    addRI: boolean,
    parties: ContractPartie[],
    requiredInformations: RequiredInformationSpecification[],
    currentRI: RequiredInformationSpecification | null,
    setRequiredInformations: (requiredInformations: RequiredInformationSpecification[]) => void,
    setAddRI: (addRI: boolean) => void,
    setCurrentRI: (currentRI: RequiredInformationSpecification | null) => void,
    maxHeight: string
}

const RequiredInformations = (props: RequiredInformationsProps): JSX.Element => {

    // Destructure props object
    const {  addRI, currentRI, parties, requiredInformations, maxHeight, setAddRI, setRequiredInformations, setCurrentRI} = props;

    // Handler functions
    function handleAddRI(ri: RequiredInformationSpecification) {
        const updatedRIs = [...requiredInformations, ri];
        setRequiredInformations(updatedRIs.sort((a, b) => a.requiredInformation.name.localeCompare(b.requiredInformation.name)));
        setAddRI(false);
    }

    function handleEditRI(ri: RequiredInformationSpecification) {
        if (!currentRI) return;

        const updatedRIs = requiredInformations.map(r => {
            if (r.requiredInformation.name === currentRI.requiredInformation.name) {
                return ri;
            }
            return r;
        })

        setRequiredInformations(updatedRIs);
        setCurrentRI(null);
    }

    function handleOpenRIEdit(ri: RequiredInformationSpecification) {
        setCurrentRI(ri);
    }

    function handleDeleteRI(ri: RequiredInformationSpecification) {
        const filter = requiredInformations.filter(r => r.requiredInformation.name !== ri.requiredInformation.name);

        setRequiredInformations(filter);
    }

    return (
        <Box sx={{ maxHeight, overflow: "auto", mb: 3 }}>
            {
                addRI
                    ?
                    <RIForm
                        cancelAction={() => setAddRI(false)}
                        parties={parties}
                        requiredInformations={requiredInformations}
                        addRequiredInformation={handleAddRI}
                    />
                    :
                    <AddFieldButton
                        onClick={() => { setAddRI(true) }}
                        disabled={currentRI !== null || addRI}
                        title="Adicionar Campo"
                    />

            }
            {
                requiredInformations.map(ri => (
                    <Box key={ri.requiredInformation.name}>
                        {
                            currentRI && currentRI.requiredInformation.name === ri.requiredInformation.name
                                ?
                                <RIForm
                                    currentRI={currentRI}
                                    parties={parties}
                                    requiredInformations={requiredInformations}
                                    cancelAction={() => setCurrentRI(null)}
                                    addRequiredInformation={handleEditRI}
                                />
                                :
                                ri.contractParty.role
                                    ?
                                    <Box
                                        key={ri.requiredInformation.name}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            py: 1,
                                            px: 2,
                                            border: "1px solid rgba(0, 0, 0, 0.25)",
                                            borderRadius: "5px",
                                            mb: 3
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {
                                                riTypes.filter(riType => riType.value === ri.requiredInformation.type).map(riType => (
                                                    <Tooltip title={riType.label}>
                                                        <ListItemIcon>
                                                            {riType.icon}
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                ))
                                            }
                                            <Typography >{ri.requiredInformation.name}</Typography>
                                        </Box>
                                        <Box>
                                            <IconButton onClick={() => handleOpenRIEdit(ri)} >
                                                <EditOutlined color="primary" />
                                            </IconButton>
                                            {
                                                ri.isWildcard
                                                    ?
                                                    <></>
                                                    :
                                                    <IconButton onClick={() => handleDeleteRI(ri)} >
                                                        <DeleteOutline color="error" />
                                                    </IconButton>
                                            }
                                        </Box>
                                    </Box>
                                    :
                                    <Tooltip title="Falta adicionar o participante responsável pelo preenchimento deste campo">
                                        <Box
                                            key={ri.requiredInformation.name}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                py: 1,
                                                px: 2,
                                                border: "1px solid #d62828",
                                                borderRadius: "5px",
                                                mb: 3
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {
                                                    riTypes.filter(riType => riType.value === ri.requiredInformation.type).map(riType => (
                                                        <Tooltip title={riType.label}>
                                                            <ListItemIcon>
                                                                {riType.icon}
                                                            </ListItemIcon>
                                                        </Tooltip>
                                                    ))
                                                }
                                                <Typography >{ri.requiredInformation.name}</Typography>
                                            </Box>
                                            <Box>
                                                <IconButton onClick={() => handleOpenRIEdit(ri)} >
                                                    <EditOutlined color="primary" />
                                                </IconButton>
                                                {
                                                    ri.isWildcard
                                                        ?
                                                        <></>
                                                        :
                                                        <IconButton onClick={() => handleDeleteRI(ri)} >
                                                            <DeleteOutline color="error" />
                                                        </IconButton>
                                                }
                                            </Box>
                                        </Box>
                                    </Tooltip>

                        }
                    </Box>
                ))
            }
        </Box>
    )
}

export default RequiredInformations