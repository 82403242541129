import { CalendarToday, Event, Today } from "@mui/icons-material";
import { Box, FormControl, FormControlLabel, InputLabel, ListItemIcon, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import moment from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import { DateInterval, DatePolicy, EndDatePolicyType, StartDatePolicyType, ValidityPeriodSpecification } from "../../../../../../models/contracts";
import { INTEGER_MAXIMUN_VALUE } from "../../../../../../utils/numberUtils";

// Interfaces and Types
interface ValidityProps extends React.ComponentProps<any> {
    validityPeriod: ValidityPeriodSpecification
    setValidityPeriod: (value: ValidityPeriodSpecification) => void
}

// Default Variables
const dummyDatePolicy: DatePolicy = {
    dateInterval: DateInterval.YEAR,
    value: 1
}

const Validity = (props: ValidityProps): JSX.Element => {

    // Destructure props object
    const { validityPeriod, setValidityPeriod } = props;

    // Data states
    const [startDatePolicyType, setStartDatePolicyType] = useState<StartDatePolicyType>(validityPeriod.startDatePolicyType as StartDatePolicyType);
    const [endDatePolicyType, setEndDatePolicyType] = useState<EndDatePolicyType>(validityPeriod.endDatePolicyType as EndDatePolicyType);
    const [endDatePolicy, setEndDatePolicy] = useState<DatePolicy>(validityPeriod.endDatePolicy ? validityPeriod.endDatePolicy : dummyDatePolicy);
    const [endDatePolicyValue, setEndDatePolicyValue] = useState(validityPeriod.endDatePolicy ? validityPeriod.endDatePolicy.value : dummyDatePolicy.value);

    // useEffects
    useEffect(() => {
        updateValidityPeriod();
    }, [startDatePolicyType, endDatePolicyType, endDatePolicy])

    // Functions
    function formatDateInterval(value: DateInterval): string {
        switch (value) {
            case DateInterval.DAY:
                return "Diário"
            case DateInterval.WEEK:
                return "Semanal"
            case DateInterval.MONTH:
                return "Mensal"
            case DateInterval.YEAR:
                return "Anual"
        }
    }

    function calculateEndDate(): string {
        switch (endDatePolicy.dateInterval) {
            case DateInterval.DAY:
                return moment().add(endDatePolicy.value, "days").format("DD/MM/YYYY")
            case DateInterval.WEEK:
                return moment().add(endDatePolicy.value, "weeks").format("DD/MM/YYYY")
            case DateInterval.MONTH:
                return moment().add(endDatePolicy.value, "months").format("DD/MM/YYYY")
            case DateInterval.YEAR:
                return moment().add(endDatePolicy.value, "years").format("DD/MM/YYYY")
        }
    }

    function updateValidityPeriod() {
        const validityPeriod: ValidityPeriodSpecification = {
            endDatePolicyType,
            startDatePolicyType,
        }

        if (endDatePolicyType === EndDatePolicyType.SET_AUTOMATICALLY_AFTER_START_DATE) {
            validityPeriod.endDatePolicy = endDatePolicy;
        }

        setValidityPeriod(validityPeriod);
    }

    // Handler functions
    function handleChangeDatePolicyValue(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (isNaN(Number(e.target.value))) return;

        let value = Number(e.target.value);

        if (value < 1) value = 1;
        
        setEndDatePolicyValue(value);
        setEndDatePolicy({ ...endDatePolicy, value });
    }

    return (
        <Box>
            <Box sx={{ mt: 3, mb: 6 }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <ListItemIcon>
                        <Today sx={{ fontSize: "32px" }} />
                    </ListItemIcon>
                    <Typography variant="h6">Data Inicial</Typography>
                </Box>
                <FormControl sx={{ ml: 1 }}>
                    <RadioGroup
                        value={startDatePolicyType}
                        onChange={(e) => setStartDatePolicyType(e.target.value as StartDatePolicyType)}
                    >
                        <FormControlLabel value={StartDatePolicyType.STARTS_WHEN_SIGNED_BY_ALL_PARTIES} control={<Radio />} label="Quando todos os participantes assinarem" />
                        <FormControlLabel value={StartDatePolicyType.SET_BY_ISSUER} control={<Radio />} label="Definir durante a emissão do contrato" />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <ListItemIcon>
                        <Event sx={{ fontSize: "32px" }} />
                    </ListItemIcon>
                    <Typography variant="h6">Data Final</Typography>
                </Box>
                <FormControl sx={{ ml: 1 }}>
                    <RadioGroup
                        value={endDatePolicyType}
                        onChange={(e) => setEndDatePolicyType(e.target.value as EndDatePolicyType)}
                    >
                        <FormControlLabel value={EndDatePolicyType.UNDETERMINED} control={<Radio />} label="Indetermindado" />
                        <FormControlLabel value={EndDatePolicyType.SET_BY_ISSUER} control={<Radio />} label="Definir durante a emissão do contrato" />
                        <FormControlLabel value={EndDatePolicyType.SET_AUTOMATICALLY_AFTER_START_DATE} control={<Radio />} label="Automaticamente após o início do contrato" />
                    </RadioGroup>
                </FormControl>
            </Box>
            {
                endDatePolicyType === EndDatePolicyType.SET_AUTOMATICALLY_AFTER_START_DATE
                    ?
                    <Box sx={{ mt: 6 }}>
                        <TextField
                            fullWidth
                            required
                            label="Valor inteiro"
                            value={endDatePolicyValue}
                            onChange={handleChangeDatePolicyValue}
                            type="number"
                            inputProps={{
                                min: 1,
                                max: INTEGER_MAXIMUN_VALUE
                            }}
                        />
                        <FormControl fullWidth sx={{ mt: 6 }} required>
                            <InputLabel id="date-interval" >Intervalo de tempo</InputLabel>
                            <Select
                                fullWidth
                                labelId="date-interval"
                                value={endDatePolicy.dateInterval}
                                label="Intervalo de tempo"
                                renderValue={(value) => (
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Typography >{formatDateInterval(value)}</Typography>
                                    </Box>
                                )}
                                onChange={(e) => setEndDatePolicy({ ...endDatePolicy, dateInterval: e.target.value as DateInterval })}
                            >
                                <MenuItem value={DateInterval.DAY} >
                                    <ListItemText>Diário</ListItemText>
                                </MenuItem>
                                <MenuItem value={DateInterval.WEEK} >
                                    <ListItemText>Semanal</ListItemText>
                                </MenuItem>
                                <MenuItem value={DateInterval.MONTH} >
                                    <ListItemText>Mensal</ListItemText>
                                </MenuItem>
                                <MenuItem value={DateInterval.YEAR} >
                                    <ListItemText>Anual</ListItemText>
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <Box sx={{ mt: 6, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px", p: 2 }}>
                            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                                <ListItemIcon><CalendarToday /></ListItemIcon>
                                <ListItemText>
                                    <Typography variant="h6">Simulador de datas</Typography>
                                </ListItemText>
                            </Box>
                            <Typography>
                                Considerando a data de início como hoje, este contrato se encerrará em: <b>{calculateEndDate()}</b>
                            </Typography>
                        </Box>
                    </Box>
                    :
                    <></>
            }
        </Box>
    )
}

export default Validity;