import { ArrowDropDown, Close, FilterList, Search } from '@mui/icons-material';
import { Autocomplete, Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Menu, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import GlobalSearchBar from "../../components/GlobalResourceSearchBar";
import { NotificationDescription, NotificationIcon, NotificationType } from '../../components/NotificationsDrawer';
import { PropertyFilter } from '../../models/analytics';
import { EditableDigitalContract, IssuedDigitalContract, parseToNormalizedContract } from '../../models/contracts';
import { NotificationModel } from '../../models/notifications';
import { SearchResultIdentification } from '../../models/search-result';
import { show as showNotification } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { show } from "../../redux/features/contract-view-dialog/contract-view-dialog";
import { useAppDispatch } from '../../redux/hooks';
import DigitalContractService from "../../services/contracts";
import NotificationsService from '../../services/notification';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

type NotificationTypes = 'DIGITAL_CONTRACT_ATTACHMENT_UPDATED' | 'DIGITAL_CONTRACT_USER_INFORMATION_UPDATED' | 'EDITABLE_DIGITAL_CONTRACT_STATE_CHANGED' | 'DIGITAL_CONTRACT_ISSUED' | 'CONTRACT_PARTY_SIGNED_CONTRACT' | 'CONTRACT_PARTY_REVOKED_ISSUED_CONTRACT' | "CONTRACT_PARTY_REVOKED_EDITABLE_CONTRACT";

interface TypeNotification {
    type: NotificationTypes,
    text: string
}

interface DateInterval {
    startDate: Date,
    endDate: Date,
}

interface DateIntervalSelection {
    label: string,
    interval: DateInterval | null,
}

const types: TypeNotification[] = [
    { type: 'DIGITAL_CONTRACT_ATTACHMENT_UPDATED', text: 'Anexo adicionado ao contrato' },
    { type: 'DIGITAL_CONTRACT_USER_INFORMATION_UPDATED', text: 'Informação do usuário atualizada' },
    { type: 'EDITABLE_DIGITAL_CONTRACT_STATE_CHANGED', text: 'O estado do contrato editável foi alterado' },
    { type: 'DIGITAL_CONTRACT_ISSUED', text: 'O contrato foi enviado para assinatura' },
    { type: 'CONTRACT_PARTY_SIGNED_CONTRACT', text: 'O participante assinou o contrato' },
    { type: 'CONTRACT_PARTY_REVOKED_ISSUED_CONTRACT', text: 'O participante revogou o contrato' },
    { type: 'CONTRACT_PARTY_REVOKED_EDITABLE_CONTRACT', text: 'O participante revogou o contrato' },
]

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiAutocomplete-inputRoot': {
        padding: theme.spacing(1),
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    '& .MuiInput-root:before': {
        border: 'none',
    },
    '& .MuiInput-root:hover:before': {
        border: 'none',
    },
}));

interface Column {
    id: 'icon' | 'type' | 'description' | 'date' | 'open';
    label: string;
    minWidth?: number;
    format?: (value: any) => string;
}

// Columns in table
const columns: Column[] = [
    { id: 'icon', label: '', minWidth: 20 },
    { id: 'type', label: 'Tipo', minWidth: 200 },
    { id: 'description', label: 'Descrição', minWidth: 500 },
    { id: 'date', label: 'Data', minWidth: 200, format: (value: Date) => value.toLocaleString() },
    { id: 'open', label: '', minWidth: 20 },
];

// Date interval options
const DateIntervalSelections: DateIntervalSelection[] = [
    {
        label: "Último dia",
        interval: {
            startDate: moment().subtract(1, "days").toDate(),
            endDate: new Date(),
        }
    },
    {
        label: "Últimos 7 dias",
        interval: {
            startDate: moment().subtract(7, "days").toDate(),
            endDate: new Date(),
        }
    },
    {
        label: "Últimos 30 dias",
        interval: {
            startDate: moment().subtract(30, "days").toDate(),
            endDate: new Date(),
        }
    },
    {
        label: "Personalizado",
        interval: null
    },
]

const NotificationCenter = (): JSX.Element => {
    // Constants
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const dataInterval: DateIntervalSelection = DateIntervalSelections[1];
    const refSelectedDateInterval = dataInterval;
    const limit = 20;

    // States
    const [page, setPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [notifications, setNotifications] = useState<NotificationModel[]>([]);

    const [searchParams, setSearchParams] = useSearchParams();

    // Filters
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [apiFilters, setApiFilters] = useState<PropertyFilter[]>([]);

    // Selected States
    const [selectedType, setSelectedType] = useState<TypeNotification | undefined>(undefined);
    const [selectedContractFilter, setSelectedContractFilter] = useState<EditableDigitalContract | IssuedDigitalContract | undefined>(undefined);
    const [selectedDateInterval, setSelectedDateInterval] = useState(refSelectedDateInterval);

    // Menu Anchor
    const [typeFilterMenuAnchor, setTypeFilterMenuAnchor] = useState<null | HTMLElement>(null);
    const [contractFilterMenuAnchor, setContractFilterMenuAnchor] = useState<null | HTMLElement>(null);
    const [dateIntervalFilterMenuAnchor, setDateIntervalFilterMenuAnchor] = useState<null | HTMLElement>(null);

    // Redux dispatcher
    const dispatch = useAppDispatch();
    const notification = useAppDispatch();

    // Effects
    useEffect(() => {
        fetchNotificationsMetadata();
        fetchNotifications(0);
    }, [])

    useEffect(() => {
        // build the object that will be sent
        const propertyFilters: PropertyFilter[] = [];

        if (selectedContractFilter) propertyFilters.push({ field: "contractId", value: selectedContractFilter.id, operations: "EQUALS" });
        if (selectedType) propertyFilters.push({ field: "type", value: selectedType.type, operations: "EQUALS" });

        // set the property filters
        setApiFilters(propertyFilters);
    }, [selectedType, selectedContractFilter, selectedDateInterval, startDate, endDate])

    useEffect(() => {
        // When api filters is set, fetch the notifications and notifications metadata using PropertyFilters
        fetchNotifications(0, limit, startDate, endDate, apiFilters)
        fetchNotificationsMetadata(limit, startDate, endDate, apiFilters)
    }, [apiFilters])

    // Functions
    const handleChangePage = (event: unknown, newPage: number) => {
        // To fetch notifications correctly its necessary to pass newPage + 1 because the pagination of the table starts at 0
        //and back-end starts with 1
        fetchNotifications(newPage + 1, limit, startDate, endDate, apiFilters);
        setPage(newPage);
    };

    // Fetch the Pagination of the Notifications
    async function fetchNotificationsMetadata(
        limit: number | undefined = undefined,
        startDate: Date | undefined = undefined,
        endDate: Date | undefined = undefined,
        propertyFilters: PropertyFilter[] | undefined = undefined) {
        try {
            const metadata = await NotificationsService.fetchNotificationsPaginationMetadata(limit, startDate, endDate, propertyFilters);
            setTotalRows(metadata.totalRecords);
        }
        catch (e) {
            console.error("Não foi possível buscar os metadados das notificações")
        }
    }

    //Fetch the notifications
    async function fetchNotifications(
        p: number,
        limit: number | undefined = undefined,
        startDate: Date | undefined = undefined,
        endDate: Date | undefined = undefined,
        propertyFilters: PropertyFilter[] | undefined = undefined) {
        try {
            const fetchedNotifications = await NotificationsService.fetchNotifications(p, limit, startDate, endDate, propertyFilters);
            setNotifications(fetchedNotifications);
        }
        catch (e) {
            console.error("Não foi possível buscar as notificações")
        }
    }

    // Find the contract by its id and open the contract
    async function triggerSearchResultAction(contractId: string): Promise<void> {
        try {
            // try to find in the editable contract
            try {
                let contract = await DigitalContractService.findEditableDigitalContractById(contractId);
                dispatch(show({
                    contract: {
                        normalized: parseToNormalizedContract(contract),
                        source: contract
                    },
                    visible: true
                }));
                return;
            } catch (error) {
                // ignore because it will try to fetch an issued contract
            }

            // try to find in the issued contract
            try {
                let contract = await DigitalContractService.findIssuedDigitalContractById(contractId);
                dispatch(show({
                    contract: {
                        normalized: parseToNormalizedContract(contract),
                        source: contract
                    },
                    visible: true
                }));
                return
            } catch (error) {
                throw error;
            }
        } catch (error) {
            throw error;
        } finally {
            // remove the query parameters from the URL
            searchParams.delete("srt");
            searchParams.delete("srid");
            setSearchParams(searchParams);
        }
    }

    async function triggerSearchResultFilter(resource: SearchResultIdentification): Promise<void> {
        if (resource.resourceType === 'issued-contract') {
            // try to find in the issued contract
            try {
                let contract = await DigitalContractService.findIssuedDigitalContractById(resource.resourceId);
                setSelectedContractFilter(contract as IssuedDigitalContract);
                return;
            } catch (error) {
                notification(showNotification({ type: 'error', message: "Não foi possível encontrar o contrato" }))
                console.error(error)
            }
        }
        else if (resource.resourceType === 'editable-contract') {
            try {
                let contract = await DigitalContractService.findEditableDigitalContractById(resource.resourceId);
                setSelectedContractFilter(contract as EditableDigitalContract);
                return;
            } catch (error) {
                notification(showNotification({ type: 'error', message: "Não foi possível encontrar o contrato" }))
                console.error(error)
            }
        }
    }

    function handleDateIntervalsRadioGroupOnChange(event: React.ChangeEvent<HTMLInputElement>, value: string): void {
        const selectedDateInterval = DateIntervalSelections.find(d => d.label === value);
        if (selectedDateInterval) {
            // update selected date interval state
            setSelectedDateInterval(selectedDateInterval);
            // if the interval is defined, set it to the start and end date state. This will trigger the contracts fetch function by the useEffect
            if (selectedDateInterval.interval) {
                setStartDate(selectedDateInterval.interval.startDate);
                setEndDate(selectedDateInterval.interval.endDate);
            }
        }
        else {
            throw new Error("Application could not resolve selected date interval selection: " + value);
        }
    }

    // function that handles the key down event
    function handleGlobalSearchBarKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        event.stopPropagation()
    };

    function handleClearFilter() {
        setSelectedType(undefined)
        setSelectedDateInterval(refSelectedDateInterval)
        setSelectedContractFilter(undefined)
    }

    return (
        <Box px={5} py={2} className="container" sx={{ maxWidth: "100%" }}>
            <Typography variant='h6' color='primary.main'><strong>Central de Notificações</strong></Typography>
            <Typography variant='subtitle2'>Gerencie e filtre as notificações de seus contratos</Typography>

            <Grid container columns={{ xs: 12, md: 12 }} sx={{ border: "1px solid #EBEBEB", alignItems: "center", minHeight: "48px", p: "0 16px", mt: 2, mb: 2 }}>
                <Grid item md={2}><Box sx={{ display: (isSmallScreen ? "none" : "flex"), alignItems: "center" }}><FilterList sx={{ margin: "0px 12px" }} />Filtrar</Box></Grid>

                <Grid item xs={12} md={10}>
                    <Grid container columns={{ xs: 12 }} sx={{ alignItems: "center", justifyContent: "flex-end" }}>

                        {/**Período*/}
                        <Grid item xs="auto" md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Button
                                endIcon={<ArrowDropDown />}
                                onClick={e => setDateIntervalFilterMenuAnchor(e.currentTarget)}>
                                <b>Período</b>{selectedDateInterval && startDate && endDate ? `: ${startDate?.toLocaleDateString()} - ${endDate?.toLocaleDateString()}` : ""}
                            </Button>
                            <Menu id="basic-menu" anchorEl={dateIntervalFilterMenuAnchor} open={Boolean(dateIntervalFilterMenuAnchor)} onClose={() => setDateIntervalFilterMenuAnchor(null)}>
                                <Box sx={{ p: 2 }}>
                                    <Typography sx={{ fontWeight: 600, mb: 2 }}>Filtrar por período</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="intervals-radio-button-group"
                                                name="row-intervals-radio-button-group"
                                                onChange={handleDateIntervalsRadioGroupOnChange}
                                                value={selectedDateInterval.label}
                                            >
                                                {
                                                    DateIntervalSelections.map(d => (
                                                        <FormControlLabel value={d.label} control={<Radio />} label={d.label} />
                                                    ))
                                                }

                                            </RadioGroup>
                                        </FormControl>
                                        {
                                            // Render date pickers only if the date interval selection does not have an defined interval
                                            (!selectedDateInterval.interval || selectedDateInterval.label === "Personalizado")
                                                ?
                                                <>
                                                    <Divider sx={{ mt: 2 }} />
                                                    <Box sx={{ p: 2 }}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                                label="Selecione uma data inicial"
                                                                inputFormat="DD/MM/YYYY"
                                                                value={startDate}
                                                                onChange={(value: Moment | null) => { if (value) setStartDate(value.toDate()) }}
                                                                renderInput={(params) => <TextField {...params} size='small' sx={{ mb: 2 }} />}
                                                                maxDate={moment(endDate)}
                                                            />
                                                            <DatePicker
                                                                label="Selecione uma data final"
                                                                inputFormat="DD/MM/YYYY"
                                                                value={endDate}
                                                                onChange={(value: Moment | null) => { if (value) setEndDate(value.toDate()) }}
                                                                renderInput={(params) => <TextField {...params} size='small' sx={{ ml: 2 }} />}
                                                                minDate={moment(startDate)}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                </>
                                                :
                                                <></>
                                        }
                                    </Box>
                                </Box>
                            </Menu>
                        </Grid>

                        {/**Contrato*/}
                        <Grid item xs="auto" md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Button
                                endIcon={<ArrowDropDown />}
                                onClick={e => setContractFilterMenuAnchor(e.currentTarget)}>
                                <b>Contrato</b>{selectedContractFilter ? `: ${selectedContractFilter.name}` : ""}
                            </Button>
                        </Grid>
                        <Menu id="basic-menu" anchorEl={contractFilterMenuAnchor} open={Boolean(contractFilterMenuAnchor)} onClose={() => setContractFilterMenuAnchor(null)}>
                            <Box sx={{ p: 2 }} onKeyDown={handleGlobalSearchBarKeyDown}>
                                <Typography sx={{ fontWeight: 600, mb: 2 }}>Filtrar por contrato</Typography>
                                <GlobalSearchBar style='black' onResourceClick={triggerSearchResultFilter}/>
                            </Box>
                        </Menu>

                        {/**Tipo*/}
                        <Grid item xs="auto" md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Button
                                endIcon={<ArrowDropDown />}
                                onClick={e => setTypeFilterMenuAnchor(e.currentTarget)}>
                                <b>Tipo</b>{selectedType ? `: ${selectedType.text}` : ""}
                            </Button>
                            <Menu id="basic-menu" anchorEl={typeFilterMenuAnchor} open={Boolean(typeFilterMenuAnchor)} onClose={() => setTypeFilterMenuAnchor(null)}>
                                <Box sx={{ p: 2 }}>
                                    <Typography sx={{ fontWeight: 600, mb: 2 }}>Filtrar por tipo de notificação</Typography>
                                    <StyledAutocomplete
                                        options={types}
                                        getOptionLabel={(option) => (option as TypeNotification).text}
                                        value={selectedType}
                                        onChange={(event, value) => setSelectedType((value as TypeNotification))}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Selecione o tipo de notificação"
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </Box>
                            </Menu>
                        </Grid>
                        {
                            (selectedType || selectedContractFilter)
                                ?
                                <Grid item xs="auto" md="auto" >
                                    <Tooltip title="Limpar filtro">
                                        <IconButton onClick={handleClearFilter}> <Close color='error' /> </IconButton>
                                    </Tooltip>
                                </Grid>
                                :
                                <></>
                        }

                    </Grid>
                </Grid>
            </Grid>

            <TableContainer sx={{ border: "1px solid #EBEBEB", height: "70vh" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column) => (
                                    <TableCell key={column.id} style={{ minWidth: column.minWidth }}><strong>{column.label}</strong></TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notifications.map((notification: NotificationModel) => {
                            const icon = NotificationIcon(notification);
                            const type = NotificationType(notification);
                            const description = NotificationDescription(notification);
                            const open = (
                                <Tooltip title="Visualizar contrato">
                                    <Button onClick={() => triggerSearchResultAction(notification.contractId)}><Search /></Button>
                                </Tooltip>
                            );

                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={notification.id}>
                                    <TableCell>{icon}</TableCell>
                                    <TableCell>{type}</TableCell>
                                    <TableCell>{description}</TableCell>
                                    <TableCell>{new Date(notification.creationDate).toLocaleString()}</TableCell>
                                    <TableCell>{open}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={totalRows}
                rowsPerPage={20}
                page={page}
                onPageChange={handleChangePage}
            />
        </Box >
    );
};

export default NotificationCenter;
