import { Analytics, Assessment, CalendarMonthOutlined, Close, DeleteForever, FindInPage, Newspaper, ReportProblemRounded, Search, Send } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Input, InputAdornment, Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Theme } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { NavLink } from "react-router-dom";
import AuthenticationContext from "../../contexts/authentication";
import { RolesContext } from "../../contexts/roles";
import { NormalizedGranularityAndDataset } from "../../models/analytics";
import { parseToNormalizedContract } from "../../models/contracts";
import { UserRecentViewedDigitalContractCache } from "../../models/digital-contract-cache";
import { ContractsQuota } from "../../models/organizations";
import { AccountRoles } from "../../models/user";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { show as showDialog } from "../../redux/features/contract-view-dialog/contract-view-dialog";
import { useAppDispatch } from "../../redux/hooks";
import { default as ContractsService, default as DigitalContractService } from "../../services/contracts";
import OrganizationService from "../../services/organization";
import UpdateService, { Updates } from "../../services/updates";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { SupportedCurrencies, fromRegionalValue } from "../../utils/currency";
import { format } from "date-fns";
import { ptBR } from 'date-fns/locale';

interface HomeProps {
    quota: ContractsQuota
}

const Home = (props: HomeProps): JSX.Element => {
    //Dismember props
    const { quota } = props;

    //Context
    const user = useContext(AuthenticationContext);
    const userRoles = useContext(RolesContext);

    const notification = useAppDispatch();

    //States
    const [userRecentViewedDigitalContractCache, setUserRecentViewedDigitalContractCache] = useState<UserRecentViewedDigitalContractCache[]>([])
    const [loading, setLoading] = useState(false)

    //Handler States
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUserRecentViewedDigitalContractCache, setFilteredUserRecentViewedDigitalContractCache] = useState<UserRecentViewedDigitalContractCache[]>([]);

    const [organizationFinanceChargesTotalAmounAnalyticsDatasets, setOrganizationFinanceChargesTotalAmountAnalyticsDatasets] = useState<NormalizedGranularityAndDataset[] | null>(null);
    const [organizationFinanceChargesTotalAmounAnalyticsLabels, setOrganizationFinanceChargesTotalAmounAnalyticsLabels] = useState<string[]>([]);

    const [contractNotFoundDialog, setContractNotFoundDialog] = useState(false)

    const [openDeleteRecentContractsDialog, setOpenDeleteRecentCotractsDialog] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)

    const [updates, setUpdates] = useState<Updates>();

    //Themes
    const themeButton: SxProps<Theme> = {
        ['@media(max-width:800px)']: { height: "110px", fontSize: "10px" },
        width: "30%", height: "140px", borderRadius: "10px", display: "grid", padding: "10px", boxShadow: 3, textAlign: "center"
    }

    const mainBoxTheme: SxProps<Theme> = {
        ['@media(max-width:1000px)']: { padding: "10px", margin: "0px" },
        margin: "24px",
    }

    const boxShadowTheme: SxProps<Theme> = {
        ['@media(max-width:600px)']: { padding: "8px", marginTop: "12px" },
        boxShadow: "5px 5px 20px 0px rgba(0, 0, 0, 0.25)", padding: "24px"
    }

    const emphasisButtonDescription: SxProps<Theme> = {
        ['@media(max-width:800px)']: { display: "none" },
        color: "#fff", padding: "0px 16px", fontSize: "12px"
    }
    const emphasisButtonTitle: SxProps<Theme> = {
        ['@media(max-width:600px)']: { fontSize: "10px" },
        color: "#fff", margin: "auto", fontSize: "16px", fontWeight: "bold"
    }

    const listItemTheme: SxProps<Theme> = {
        width: "100%", overflow: "hidden", display: "inline-block", textOverflow: "ellipsis", whiteSpace: "nowrap"
    }

    const searchBarTheme: SxProps<Theme> = {
        ['@media(max-width:600px)']: { width: "80%" },
        border: "1px solid rgb(200 200 200)", borderRadius: "4px", padding: "16px 8px", mr: "-2px"
    }

    // Redux dispatcher
    const dispatch = useAppDispatch();

    const canAccessFinance = userRoles.assertIsOrganizationOrAdministratorOr(user, AccountRoles.FINANCE_MANAGER) && quota.customerContractsQuota.financialAnalyticsModuleEnabled;

    /**
     * Effect used to the functions to fetch 
     * user recent viewed digital contract cache
     * and 
     * the user digital contract signatures cache
    */
    useEffect(() => {
        fetchUserRecentDigitalContractCache();
        fetchOrganizationFinanceChargesTotalAmountAnalytics();
        fetchUpdates();
    }, [])

    useEffect(() => {
        setFilteredUserRecentViewedDigitalContractCache(userRecentViewedDigitalContractCache.filter(item => item.contractName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())));
    }, [userRecentViewedDigitalContractCache, searchTerm])

    /** Function to fetch user recent viewed digital contract cache */
    function fetchUserRecentDigitalContractCache() {
        ContractsService.fetchUserRecentViewedDigitalContractCache(30)
            .then(response => {
                if (response != null) {
                    setUserRecentViewedDigitalContractCache(response);
                }
            })
    }

    function handleDeleteRecentContract(event: React.MouseEvent<HTMLButtonElement>, contractId: string) {
        ContractsService.deleteRecentViewedContract(contractId).then(response => {
            const updatedRecentContracts = userRecentViewedDigitalContractCache.filter(rc => rc.contractId !== response.contractId)
            setUserRecentViewedDigitalContractCache(updatedRecentContracts)

            notification(show({ type: "success", message: "Item deletado com sucesso" }))
        })
            .catch(e => {
                const err = new ErrorWrapper(e)

                if (err.httpStatus === 404) notification(show({ type: "error", message: "Item não encontrado no banco de dados" }))

                notification(show({ type: "error", message: "Não foi possivel deletar o item" }))
            })
    }

    /** Fetch updates from the blog */
    async function fetchUpdates(){
        const response = await UpdateService.fetchUpdates();
        const json = await response.json();
        setUpdates(json);
    }

    /** Extract and normalize the text from the html */
    function extractTextFromHtml(html: string): string {
        const lastSpanIndex = html.lastIndexOf('</span>');
        if (lastSpanIndex !== -1) {
            const cleanedText = html.substring(lastSpanIndex + '</span>'.length);
            return cleanedText.trim();
        }
        return html.trim();
    }

    /** Extract and normalize the text from the html and remove Reading time from the start of the html */
    function extractReadingTime(html: string): string {
        return html.replace(/^Reading Time:.*?minutes/, '').trim();
    }

    /** Item list of user recent viewed digital contract cache */
    function RecentViewedDigitalListItem(item: UserRecentViewedDigitalContractCache) {
        return (
            <TableRow key={item.id}>
                <TableCell sx={{ maxWidth: "400px", padding: "16px 8px" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {
                            item.interfaceTheme && item.interfaceTheme.logoUrl
                                ?
                                <Box sx={{ width: "50px", height: "25px", backgroundColor: "#FFF", display: "inline-block", marginRight: "1rem" }}>
                                    <img src={`${item.interfaceTheme.logoUrl}?randomText=${(Math.random() + 1).toString(36).substring(7)}`} alt="" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                </Box>
                                :
                                <Box sx={{ width: "50px", height: "25px", backgroundColor: "#FFF", display: "inline-block", marginRight: "1rem" }}></Box>
                        }
                        <Typography sx={{ ...listItemTheme, width: "80%" }}>{item.contractName}</Typography>
                    </Box>
                </TableCell>
                <TableCell sx={{ padding: "16px 8px" }} >
                    <Typography sx={listItemTheme}>{new Date(item.creationDate).toLocaleString()}</Typography>
                </TableCell>
                <TableCell sx={{ padding: "16px 8px" }} align="center">
                    <Tooltip title="Visualizar contrato">
                        <IconButton color='primary' onClick={(e) => handleOpenContract(e, item.contractId)}>
                            <FindInPage fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell sx={{ padding: "16px 8px" }} align="center">
                    <Tooltip title="Limpar dos recentes">
                        <IconButton color="error" onClick={(e) => handleDeleteRecentContract(e, item.contractId)}>
                            <DeleteForever fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    }

    const ContractNotFoundDialog = (): JSX.Element => {
        return (
            <Dialog open={contractNotFoundDialog} onClose={() => setContractNotFoundDialog(false)}  >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => setContractNotFoundDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <Box m={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
                    <Typography variant="h6" >Contrato não encontrado</Typography>
                    <Box
                        my={2}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 100,
                            height: 100,
                            backgroundColor: "red",
                            borderRadius: 5
                        }}
                    >
                        <ReportProblemRounded color="disabled" sx={{ fontSize: 75 }} />
                    </Box>
                    <Typography>O contrato pode ter sido deletado pela organização</Typography>
                </Box>
            </Dialog>
        )
    }

    function handleOpenContract(event: React.MouseEvent<HTMLButtonElement>, contractId: string): void {
        fetchContractAndOpenContractDialog(contractId)
    }

    /**
     * Fetch the contract and verify if its editable or issued and open the registry 
     */
    async function fetchContractAndOpenContractDialog(contractId: string): Promise<void> {
        try {
            // try to find in the editable contract
            try {
                let contract = await DigitalContractService.findEditableDigitalContractById(contractId);
                dispatch(showDialog({
                    contract: {
                        normalized: parseToNormalizedContract(contract),
                        source: contract
                    },
                    visible: true
                }));
                return;
            } catch (error) {
                // ignore because it will try to fetch an issued contract
            }

            // try to find in the issued contract
            try {
                let contract = await DigitalContractService.findIssuedDigitalContractById(contractId);
                dispatch(showDialog({
                    contract: {
                        normalized: parseToNormalizedContract(contract),
                        source: contract
                    },
                    visible: true
                }));
                return
            } catch (error) {
                // throw error
                throw error;
            }

        } catch (error) {
            setContractNotFoundDialog(true)

        }
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(e.target.value);
    };

    function handleDeleteAllRecentContracts() {
        setLoadingDelete(true)
        ContractsService.deleteAllRecentViewedContracts().then(response => {
            setUserRecentViewedDigitalContractCache([])

            notification(show({ type: "success", message: "Itens deletados com sucesso" }))
        })
            .catch(e => {
                const err = new ErrorWrapper(e)

                if (err.httpStatus === 404) notification(show({ type: "error", message: "Não há itens para ser deletados" }))

                notification(show({ type: "error", message: "Não foi possivel deletar itens" }))
            })
            .finally(() => {
                setLoadingDelete(false)
                setOpenDeleteRecentCotractsDialog(false)
            })
    }

    function fetchOrganizationFinanceChargesTotalAmountAnalytics() {
        setLoading(true)

        const endDate = new Date(); // Current date and time
        const startDate = new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000);

        OrganizationService.fetchOrganizationFinanceChargesTotalAmountAnalytics(
            "credited",
            startDate,
            endDate,
            "YEAR_MONTH_AND_DAY",
            null,
            null,
            null)
            .then(response => {
                // map all the granularity labels
                if (response !== null) {
                    let labels: string[] = [];

                    // iterate over each data from the API to extract the labels...
                    // iterate over each 'currency' granularity
                    Object.keys(response).forEach(currencyKey => {

                        // iterate over each 'date' granularity
                        Object.keys(response[currencyKey]).forEach(granularityKey => {

                            // only add non duplicate items
                            if (labels.indexOf(granularityKey) < 0) {
                                labels.push(granularityKey);
                            }
                        })
                    });

                    // sort the labels and set it on the state
                    labels = labels.sort();
                    setOrganizationFinanceChargesTotalAmounAnalyticsLabels(labels);

                    // this part will create an normalized object that the front-end can easily 
                    // manage the data provided from the API...  
                    const currencyAndDatasets: NormalizedGranularityAndDataset[] = [];

                    // iterate over each currency layer from the response (BRL, USD)
                    for (const currencyKey in response) {

                        // create the normalized currency and dataset
                        const datasetFromCurrencyGranularity = response[currencyKey];
                        const currencyAndDataset: NormalizedGranularityAndDataset = {
                            currency: currencyKey,
                            dataset: []
                        };

                        // iterate over each sorted label....
                        labels.forEach(label => {
                            // if the currenct dataset granularity does not have the label, push an empty value
                            if (!datasetFromCurrencyGranularity[label]) {
                                currencyAndDataset.dataset.push({
                                    presentation: "--",
                                    value: 0,
                                })
                            }
                            // otherwise push the value from the API
                            else {
                                currencyAndDataset.dataset.push({
                                    presentation: datasetFromCurrencyGranularity[label].regionalString,
                                    value: datasetFromCurrencyGranularity[label].regionalValue,
                                })
                            }
                        });

                        // include the normalized dataset into the array
                        currencyAndDatasets.push(currencyAndDataset);
                    }

                    // update the view state
                    setOrganizationFinanceChargesTotalAmountAnalyticsDatasets(currencyAndDatasets);
                }

            })
            .finally(() => {
                setLoading(false)
            })
    }

    /**
     * This function will render an dataset value label
     * @param gDataset 
     * @param fontColor 
     * @returns 
     */
    function TotalDatasetValueLabel(gDataset: NormalizedGranularityAndDataset, fontColor: string = "primary"): JSX.Element {
        // sum the values inside the normalized dataset
        let total = 0;
        gDataset.dataset.forEach(d => {
            total += d.value;
        });

        // return the rendered component
        return (
            <Box>
                <Typography variant="h6" color={fontColor}>
                    {gDataset.currency} <b>{fromRegionalValue(total, gDataset.currency as SupportedCurrencies).regionalValueString}</b>
                </Typography>
            </Box>
        )
    }

    const ConfirmDeleteRecentContractsDialog = (): JSX.Element => {
        return (
            <Dialog open={openDeleteRecentContractsDialog} onClose={() => setOpenDeleteRecentCotractsDialog(false)}>
                <DialogTitle id="alert-dialog-title">
                    Deletar todos os contratos vistos recentemente?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deseja realmente deletar todos os contratos que foram vistos recentemente?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteRecentCotractsDialog(false)} disabled={loading}>Cancelar</Button>
                    {
                        (!loadingDelete)
                            ?
                            <Button onClick={handleDeleteAllRecentContracts} autoFocus color="warning">
                                Deletar
                            </Button>
                            :
                            <CircularProgress size="small" />
                    }

                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Box sx={mainBoxTheme}>
            <Grid container spacing={4}>
                <Grid item xs={12} xl={8}>
                    {/**Welcome and Nav buttons*/}
                    <Box sx={[boxShadowTheme, { mb: "32px", height: "20vh", overflow: "auto" }]}>
                        <Box sx={{ mb: 2 }}>
                            <Typography sx={{ fontSize: "20px" }}>Olá, <Typography sx={{ color: "#64067F", display: "inline-block", fontSize: "20px" }}><b>{user.user().account.preferredName}</b></Typography>.</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                            <Button sx={themeButton} variant="contained" component={NavLink} to={"/contracts/create"}>
                                <Send sx={{ color: "#fff", margin: "auto", fontSize: "36px" }} />
                                <Typography sx={emphasisButtonTitle}>Enviar contrato</Typography>
                                <Typography sx={emphasisButtonDescription}>Veja seus modelos de contrato e envie contratos para a emissão.</Typography>
                            </Button>
                            <Button sx={themeButton} variant="contained" component={NavLink} to={"/contracts"}>
                                <Assessment sx={{ color: "#fff", margin: "auto", fontSize: "36px" }} />
                                <Typography sx={emphasisButtonTitle}>Contratos</Typography>
                                <Typography sx={emphasisButtonDescription}>Visualize as informações de todos os seus contratos na plataforma.</Typography>
                            </Button>
                            {
                                (canAccessFinance)
                                    ?
                                    <Button sx={themeButton} variant="contained" component={NavLink} to={"/analytics"}>
                                        <Analytics sx={{ color: "#fff", margin: "auto", fontSize: "36px" }} />
                                        <Typography sx={emphasisButtonTitle}>Analytics</Typography>
                                        <Typography sx={emphasisButtonDescription}>Faça análises com os dados financeiros dos contratos de sua organização.</Typography>
                                    </Button>
                                    :
                                    <></>
                            }
                        </Box>
                    </Box>

                    {/**Recent Contracts */}
                    <Box sx={[boxShadowTheme, { height: "53vh", overflow: "auto" }]}>
                        <Typography sx={{ color: "#64067F", fontSize: "20px", }}>Contratos Recentes</Typography>
                        {
                            filteredUserRecentViewedDigitalContractCache.length > 0
                                ?
                                <>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Box></Box>
                                        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                                            <Input
                                                type="text"
                                                value={searchTerm}
                                                onChange={handleSearch}
                                                placeholder="Filtre os contratos"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Search />
                                                    </InputAdornment>}
                                                sx={searchBarTheme}
                                            />
                                        </Box>
                                    </Box>
                                    <TableContainer component={Paper} sx={{ height: "42vh" }}>
                                        <Table stickyHeader >
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell sx={{ maxWidth: "400px", padding: "16px 8px" }} align="center">
                                                        Nome do contrato
                                                    </TableCell>
                                                    <TableCell >
                                                        Último Acesso em
                                                    </TableCell>
                                                    <TableCell sx={{ padding: "16px 8px" }} align="center">
                                                        Visualizar contrato
                                                    </TableCell>
                                                    <TableCell sx={{ padding: "16px 8px" }} align="center">
                                                        <Tooltip title="Limpar lista">
                                                            <Button variant="text" onClick={() => setOpenDeleteRecentCotractsDialog(true)}>
                                                                Limpar lista
                                                            </Button>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    (filteredUserRecentViewedDigitalContractCache)
                                                        ?
                                                        filteredUserRecentViewedDigitalContractCache.map(item => (
                                                            RecentViewedDigitalListItem(item)
                                                        ))
                                                        :
                                                        userRecentViewedDigitalContractCache.map(c => (
                                                            RecentViewedDigitalListItem(c)
                                                        ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                                :
                                <Alert severity="info" sx={{ mt: 4 }}>Você não tem contrato recentes.</Alert>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} xl={4}>
                    <Box sx={[boxShadowTheme, { mb: "32px", height: canAccessFinance ? "42vh" : "81vh"}]}>
                        <Typography sx={{ color: "#64067F", fontSize: "20px", alignItems: "center", display:"flex", mb: 2}}><Newspaper sx={{mr: 1}}/>Atualizações</Typography>
                        {
                            updates
                            ?
                                <Box sx={{maxHeight: "90%", overflow:"auto"}}>
                                {
                                    updates.items.map(item => (
                                        <>
                                            <Button onClick={() => window.open(item.url, '_blank')} sx={{textAlign: "left", padding: 0, margin: 0}}>
                                                <Box sx={{padding: 1.5}}>
                                                    <Box sx={{display:"flex", alignItems:"center"}}>
                                                        <Typography sx={{fontWeight: 600}}>{item.title}</Typography> 
                                                        <Typography sx={{mx: 1, fontWeight: 600}}>·</Typography>
                                                        <CalendarMonthOutlined sx={{color: "primary.main", fontSize: "20px", mr: 1}}/>
                                                        <Typography sx={{fontSize:"14px", color: "primary.main"}}>
                                                            {format(new Date(item.date_published), 'MMMM dd, yyyy', { locale: ptBR })}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ maxHeight: '64px', textOverflow: 'ellipsis', my: 1 }}>
                                                        <Typography 
                                                            sx={{ fontSize: "12px", color:"#8C8C8C", textOverflow: 'ellipsis', 
                                                            overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', 
                                                            WebkitLineClamp: 3 
                                                            }}>
                                                            {
                                                                // Try to use item.summary, if it not exist, use item.content_text instead
                                                                item.summary
                                                                ?
                                                                extractTextFromHtml(item.summary)
                                                                :
                                                                item.content_text
                                                                    ?
                                                                    extractReadingTime(item.content_text)
                                                                    :
                                                                    <></>
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Button>
                                            <Divider />
                                        </>
                                    ))
                                }
                                </Box>
                            :
                            <Box sx={{ mt: 2 }}>
                                <Alert severity="info">Nenhuma atualização no momento.</Alert>
                            </Box>
                        }
                    </Box>
                    {
                        canAccessFinance && organizationFinanceChargesTotalAmounAnalyticsDatasets
                            ?
                            <Box sx={[boxShadowTheme, { height: "31vh", overflow: "auto" }]}>
                                <Box>
                                    <Typography variant="subtitle1">Total de entradas financeiras dos últimos 7 dias</Typography>
                                    {
                                        organizationFinanceChargesTotalAmounAnalyticsDatasets.map(gDataset => (
                                            <React.Fragment>
                                                {TotalDatasetValueLabel(gDataset)}
                                            </React.Fragment>

                                        ))
                                    }
                                    <Box sx={{ border: "1px solid rgb(230, 230, 230)", p:1 }}>
                                        <Typography variant="subtitle1" sx={{ mt: 1, mb: 1, ml: 1 }}>Progressão de entradas financeiras por dia</Typography>
                                        <Line
                                            style={{ width: "100%", maxHeight: "18vh", }}
                                            data={{
                                                labels: organizationFinanceChargesTotalAmounAnalyticsLabels,
                                                datasets: organizationFinanceChargesTotalAmounAnalyticsDatasets.map(d => ({
                                                    label: d.currency,
                                                    data: d.dataset.map(ds => ds.value),
                                                }))
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            :
                            <></>
                    }
                </Grid>
            </Grid>
            <ContractNotFoundDialog />
            <ConfirmDeleteRecentContractsDialog />
        </Box >
    );
}

export default Home;