import { Box, Button, CircularProgress, SpeedDial } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

const LoadingSpeedDial = (props: React.ComponentProps<any>) => {
    const { loading, show, ...other } = props;

    const errorStyle = {
        cursor: loading ? 'not-allowed' : 'pointer',
        bgcolor: loading ? 'error.light' : 'error.main',
        '&:hover': { bgcolor: loading ? 'error.light' : 'error.dark' }
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <SpeedDial FabProps={{ sx: errorStyle }}
                hidden={!show} ariaLabel='spt'
                icon={loading  ? <CircularProgress sx={{ color: 'primary.light' }} size={20} /> : <Delete />}
                sx={{ position: 'absolute', bottom: 32, right: 16 }} {...other} />
        </Box>
    )
}


const LoadingButton = (props: React.ComponentProps<any>) => {

    const { loading, progressColor, ...other } = props;

    if (loading) {
        return (
            <Button  {...other} disabled>
                <CircularProgress size={25} sx={{ color: 'primary.light' }} />
            </Button>
        );
    }

    return <Button  {...other} />;
};

export { LoadingButton, LoadingSpeedDial };
//props.variant === 'text' ? '#FFECE8' : 