import {DigitalContractTemplate} from "../../../../models/contracts";
import { TagsModel } from "../../../../services/tags";

export type TotalAmountOfBillingDataAnalyticsContractStates = "WAITING_FOR_ISSUED" | "WAITING_FOR_SIGNATURES" | "SIGNED" | "INACTIVE";



/**
 * Translate an input state into an string representation
 * @param state 
 * @returns 
 */
function translateTotalAmountOfBillingDataAnalyticsContractStates(state : TotalAmountOfBillingDataAnalyticsContractStates) : string {
    switch (state) {
        case "WAITING_FOR_ISSUED" : 
            return "Aguardando ser emitido";
        case "WAITING_FOR_SIGNATURES":
            return "Aguardando assinaturas";
        case "SIGNED":
            return "Assinado";
        case "INACTIVE" :
            return "Cancelado";
    }
}

export interface AnalyticsProps extends React.ComponentProps<any> {
    digitalContractTemplate : DigitalContractTemplate | null,
    digitalContractTemplates? : DigitalContractTemplate[],
    startDate : Date,
    endDate : Date,
    issuerId?: string,
    tags?: TagsModel[]
}

export interface PerformanceAnalyticsProps extends React.ComponentProps<any> {
    digitalContractTemplate : DigitalContractTemplate | null,
    digitalContractTemplates? : DigitalContractTemplate[],
    issuerId?: string,
    tag?: TagsModel
}

export enum FilterOperations {
    EQUALS = "EQUALS",
    NOT_EQUALS = "NOT_EQUALS",
    GREATER_THAN = "GREATER_THAN",
    GREATER_OR_EQUALS_THAN = "GREATER_OR_EQUALS_THAN",
    LESSER_THAN = "LESSER_THAN",
    LESSER_OR_EQUALS_THAN = "LESSER_OR_EQUALS_THAN"
}

export {
    translateTotalAmountOfBillingDataAnalyticsContractStates
}