import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { ContractPartie } from "../../../../../../models/contracts";

interface SignatureOrderProps{
    parties: ContractPartie[],
    signatureOrdenation: string,
    onSignatureOrdenationChange: (value: string) => void,
    onContractPartiesSignatureOrdenation: (contractPartiesListSignatureOrdenation: string[]) => void;
}

const SignatureOrder = (props: SignatureOrderProps): JSX.Element => {   
    const [localParties, setLocalParties] = useState<ContractPartie[]>(props.parties);
    const [signatureOrdenationValue, setSignatureOrdenationValue] = useState(props.signatureOrdenation);

    useEffect(() => {
        setLocalParties(props.parties);
    }, [props.parties]);

    useEffect(() => {
        if(signatureOrdenationValue == "0"){
            props.onContractPartiesSignatureOrdenation([]);
        }
        else{
            const partiesOrdenation: string[] = localParties.map((party) => party.role);
            props.onContractPartiesSignatureOrdenation(partiesOrdenation);
        }
    }, [signatureOrdenationValue]);

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;
    
        const updatedParties = Array.from(localParties);
        const [movedParty] = updatedParties.splice(result.source.index, 1);
        updatedParties.splice(result.destination.index, 0, movedParty);
    
        setLocalParties(updatedParties);
    
        const partiesOrdenation: string[] = updatedParties.map((party) => party.role);
    
        props.onContractPartiesSignatureOrdenation(partiesOrdenation);
      };

    return (
        <Box sx={{ display: "block", width: "100%", height: "100%" }}>
            <FormControl sx={{ display: "block", mb: 2 }}>
                <RadioGroup
                    value={props.signatureOrdenation}
                    onChange={(e) => { setSignatureOrdenationValue(e.target.value); props.onSignatureOrdenationChange(e.target.value)}}
                >
                    <FormControlLabel value="0" control={<Radio />} label="Sem ordenação" />
                    <FormControlLabel value="1" control={<Radio />} label="Ordenado" />
                </RadioGroup>
            </FormControl>
            
            {props.signatureOrdenation === '1' && (
            <>
                <Typography sx={{fontSize: "14px", color:"#8c8c8c"}}>As partes assinam em sequência definida por você.</Typography>
                <Typography sx={{fontSize: "14px", color:"#8c8c8c"}}>Toda vez que uma parte assinar, a próxima receberá um e-mail avisando que é sua vez</Typography>
                <Typography sx={{fontSize: "16px", my: 2}}>Para modificar ordem, basta arrastar cada participante para a posição desejada</Typography>

                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="parties">
                    {(provided) => (
                        <ul style={{width: "100%", padding: 0}} {...provided.droppableProps} ref={provided.innerRef}>
                        {localParties.map((party, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                            {(provided, snapshot) => (
                                <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                style={{
                                    ...provided.draggableProps.style,
                                }}
                                >
                                <Box sx={{display:"flex", width:"100%", alignItems: "center"}}>
                                    <Typography sx={{ mr: 1, fontWeight: 600 }}><strong>{index + 1}º</strong></Typography>
                                    <Box sx={{ display: 'flex', width:"90%", my: 2, padding: 2, backgroundColor: "white", 
                                        border: snapshot.isDragging ? "solid 2px #64067f" : "solid 1px #e0e0e0",}}>
                                        <Typography>{party.role}</Typography>
                                    </Box>
                                </Box>
                                </div>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </ul>
                    )}
                    </Droppable>
                </DragDropContext>
                
            </>
            )}
        </Box>
    );
};

export default SignatureOrder;
