import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, Divider, Drawer, Switch, Typography } from "@mui/material";
import { LoadingButton } from "../../components/LoadingButton";
import { useAppDispatch } from '../../redux/hooks';
import SearchBar from "../../components/SearchBar";
import { SearchResult } from "../../models/search-result";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { AccountRoles, UserAccount } from "../../models/user";
import SubusersService from "../../services/subusers";
import { AccountCircle, ArrowDownward } from "@mui/icons-material";
import ProgressBar from "../../components/ProgressBar";
import { CurrentContext } from "../../appctx/webappContext";
import AuthenticationContext from "../../contexts/authentication";

interface ImportSubuser extends React.ComponentProps<any> {
    currentSubusers: Number,
    maxSubusers: Number,
    open: boolean,
    close: () => void
    onSuccess: (newUser: any) => void,
    updateUsersQuota: (addedSubusers: number) => void
}

const ImportSubuser = (props: ImportSubuser) => {

    //Props
    const { currentSubusers, maxSubusers, open, close, onSuccess, updateUsersQuota } = props;

    //Context props
    const notification = useAppDispatch();

    // authenticated user
    const auth = useContext(AuthenticationContext);

    //States
    const [loading, setLoading] = useState(false)
    const [selectedUser, setSelectedUser] = useState<UserAccount | null>(null)

    //Reset selected user on close
    useEffect(() => { setSelectedUser(null) }, [close])

    //Roles States
    const [admRole, setAdmRole] = useState(false);
    const [userRole, setUserRole] = useState(false);
    const [contractRole, setContractRole] = useState(false);
    const handleChangeAdmRole = (e: React.ChangeEvent<HTMLInputElement>) => setAdmRole(e.target.checked);
    const handleChangeUserRole = (e: React.ChangeEvent<HTMLInputElement>) => setUserRole(e.target.checked);
    const handleChangeContractRole = (e: React.ChangeEvent<HTMLInputElement>) => setContractRole(e.target.checked);

    const [openImportUserDialog, setOpenImportUserDialog] = useState(false)

    let userConsumptionPercentage = ((Number(currentSubusers) / Number(maxSubusers)) * 100).toFixed(4)

    if (Number(userConsumptionPercentage) > 100) userConsumptionPercentage = "100"

    let userConsumptionAfterImportPercentage = (((Number(currentSubusers) + 1) / Number(maxSubusers)) * 100).toFixed(4)

    if (Number(userConsumptionAfterImportPercentage) > 100) userConsumptionAfterImportPercentage = "100"

    //Events  
    const handleSearchBarChange = (e: SyntheticEvent, value: SearchResult) => {
        //Ignore empty values
        if (!value) return setSelectedUser(null);

        //Get the UserAccount from the search result
        const newUser: UserAccount = value.object as UserAccount;

        //Storage the selected user
        setSelectedUser(newUser)
    }

    /**
     * Import a selected user from Klaus Accounts
     * @returns 
     */
    const importUser = () => {

        if (!selectedUser) return;
        setLoading(true)

        //Get user roles 
        const userRoles: AccountRoles[] = [];
        if (admRole) userRoles.push(AccountRoles.ADMINISTRATOR)
        if (userRole) userRoles.push(AccountRoles.USER_AND_TEAMS_MANAGER)
        if (contractRole) userRoles.push(AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER)

        //Send request
        SubusersService.importFromKlausAccounts({ targetUUID: selectedUser.uuid, roles: userRoles })
            .then(importedUser => {
                //Reset state
                setSelectedUser(null);
                setOpenImportUserDialog(false)
                //Callback functions
                onSuccess(importedUser);
                close();
            })
            .catch(err => {
                const e = new ErrorWrapper(err);
                notification(show({
                    type: 'error',
                    message: e.httpStatus === 403 ?
                        `Usuário já cadastrado` : e.httpStatus === 404 ?
                            `Usuário não encontrado ` : e.httpStatus === 422 ?
                                `A cota de usuários atingiu o limite ` : e.httpStatus === 402 ?
                                    `Dados inválidos` : `Ocorreu um erro: ${e.message}`
                }))
            })
            .finally(() => {
                setLoading(false);
                updateUsersQuota(1);
            })
    }

    const handleGoToAccounts = () => {
        window.open(`${CurrentContext.authWebappUrl}/login?service=accounts&requiredEmail=${auth.user().account.email}&redirectUrl=/user-and-teams`, `_blank`)
    }

    //Style
    const sty = { display: 'flex', justifyContent: 'space-between', mt: 3, alignItems: 'center' }

    const ImportUserDialog = (): JSX.Element => {

        const handleCloseImportDialog = () => {
            setOpenImportUserDialog(false)
            close()
        }

        return (
            <Dialog open={openImportUserDialog} fullWidth >
                <Box p={2} m={3}>
                    {
                        selectedUser
                            ?
                            <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
                                <Box ml={2} sx={{ display: "flex" }}>
                                    <AccountCircle sx={{ width: 48, height: 48 }} />
                                    <Box ml={2}>
                                        <Typography>{selectedUser.preferredName}</Typography>
                                        <Typography>{selectedUser.email}</Typography>
                                        <Typography>{selectedUser.uuid}</Typography>
                                    </Box>
                                </Box>
                                <Box mt={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <Typography variant="subtitle1" mb={1} >Cota de usuários antes da importação</Typography>
                                    <ProgressBar
                                        percentageString={userConsumptionPercentage}
                                        currentValue={Number(currentSubusers)}
                                        maxValue={Number(maxSubusers)}
                                    />
                                    <ArrowDownward />
                                    <Typography variant="subtitle1" mb={1} >Cota de usuários após a importação</Typography>
                                    <ProgressBar
                                        percentageString={userConsumptionAfterImportPercentage}
                                        currentValue={Number(currentSubusers) + 1}
                                        maxValue={Number(maxSubusers)}
                                    />
                                </Box>
                            </Box>
                            :
                            <Typography variant="h6" color="red">Erro ao buscar o usuário</Typography>
                    }
                    <Box mt={3} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Button onClick={() => handleCloseImportDialog()} color='error'>{selectedUser ? "Cancelar" : "Fechar"}</Button>
                        {
                            selectedUser
                                ?
                                <LoadingButton variant='contained' loading={loading} onClick={importUser}>Salvar</LoadingButton>
                                :
                                <></>
                        }
                    </Box>
                </Box>
            </Dialog>
        )
    }

    const handleCloseDrawer = () => {
        setOpenImportUserDialog(true)
    }

    return (
        <>
        <Drawer anchor='right' open={open} onClose={close} sx={{ '& .MuiDrawer-paper': { width: { sm: 550, xs: 350 }, p: { sm: 5, xs: 3 } } }}>
            <Typography variant='h6' mb={5}>Importar usuário do <strong>Klaus Accounts</strong></Typography>
            <SearchBar searchMode='accounts-users' fullWidth
                onChange={handleSearchBarChange}
                renderInputProps={{ label: 'Busque pelo nome, email ou ID do usuário' }}
            />
            {!selectedUser ?
                <Box>
                    <Typography mt={1} variant="body2" >
                        Não encontrou o usuário? Cadastre-o primeiramente na central de contas <Button variant="text" onClick={() => handleGoToAccounts()}>
                            Klaus Accounts
                        </Button> e procure-o aqui após a operação.
                    </Typography>
                    <DialogActions sx={{ mt: 5 }}><Button onClick={close}>Voltar</Button></DialogActions>
                </Box>
                :
                <Box mt={7}>
                    <Typography fontWeight={600}>Permissões na plataforma</Typography>
                    <Box sx={sty}>
                        <Typography>Administrador</Typography>
                        <Switch checked={admRole} onChange={handleChangeAdmRole} />
                        </Box>
                        <Typography mb={3} color='text.secondary' variant='body2'>Permissão para gerenciar a conta de uma organização</Typography>
                        <Divider />
                        <Box sx={sty}>
                            <Typography>Gerente de usuários e times</Typography>
                            <Switch checked={userRole} onChange={handleChangeUserRole} />
                        </Box>
                        <Typography mb={3} color='text.secondary' variant='body2'>Permissão para gerenciar usuários e times pertencentes à uma organização</Typography>
                        <Divider />
                        <Box sx={sty}>
                            <Typography>Gerente de contratos</Typography>
                            <Switch checked={contractRole} onChange={handleChangeContractRole} />
                        </Box>
                        <Typography color='text.secondary' variant='body2'>Permissão para gerenciar os contratos digitais</Typography>
                        <DialogActions sx={{ my: 10 }}>
                            <Button onClick={close} color='error'>Cancelar</Button>
                            <LoadingButton variant='contained' loading={loading} onClick={handleCloseDrawer}>Avançar</LoadingButton>
                        </DialogActions>
                    </Box>
                }
            </Drawer >
            <ImportUserDialog />
        </>
    )
}

export default ImportSubuser;
