import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Divider, Typography, Box } from "@mui/material";
import { UserAccount } from "../models/user";
import AuthorizationService from "../services/authorization"
import UserAccountService from "../services/user-account"
import { useState } from "react";
import { useAppDispatch } from "../redux/hooks";
import { show } from "../redux/features/app-global-notification/app-global-notification-slice";
import ErrorWrapper from "../utils/ErrorWrapper";

interface OnAccountCreatedCallback {
    (createdAccount: UserAccount): void
}

interface OnAuthorizationTokenIssued {
    (createdAccount: UserAccount, contractsAuthorizationToken: string): void
}

interface CreateContractsAccountDialogProps extends DialogProps {
    /**
     * The authentication token provided by the Klaus Accounts platform
     */
    klausAuthenticationToken: string;

    /**
     * The email used to authenticate the account
     */
    email?: string;

    /**
     * Callback that is called when an account is created
     */
    onAccountCreated?: OnAccountCreatedCallback;

    /**
     * Callback that is called when an account is created and an authorization token was issued
     */
    onAuthorizationTokenIssued?: OnAuthorizationTokenIssued;
}

/**
 * 
 * @param props 
 * @returns 
 */
const CreateContractsAccountDialog = (props: CreateContractsAccountDialogProps): JSX.Element => {

    const { klausAuthenticationToken, ...otherProps } = props;

    //Context props
    const notification = useAppDispatch();

    // props
    const [loading, setLoading] = useState(false);
    const [hasAccountCreated, setHasAccountCreated] = useState(false);

    /**
     * Create an account using the data provided in this dialog
     */
    async function createAccount() {
        let contractsUserAccount: UserAccount;

        // call the API to create the account
        try {
            setLoading(true);
            contractsUserAccount = await UserAccountService.create(props.klausAuthenticationToken);
        } catch (error) {
            const e = new ErrorWrapper(error);
            console.error(error)
            notification(show({ type: 'error', message: e.message }))
            return;
        } finally {
            setLoading(false);
        }

        // call the API to issue the authorization token
        let authorizationToken: string;

        try {
            setLoading(true)
            authorizationToken = await (await AuthorizationService.contractsLogin(props.klausAuthenticationToken)).token;
            setHasAccountCreated(true);

            // trigger onAccountCreated callback
            if (props.onAccountCreated) {
                props.onAccountCreated(contractsUserAccount);
            }

        } catch (error) {
            const e = new ErrorWrapper(error);
            notification(show({ type: 'error', message: e.message }))
            return;
        } finally {
            setLoading(false);
        }

        // trigger onAuthorizationTokenIssued callback
        if (props.onAuthorizationTokenIssued) props.onAuthorizationTokenIssued(contractsUserAccount, authorizationToken);

    }

    return (
        <Dialog
            {...otherProps}
            fullScreen={(window.screen.width <= 600) ? true : undefined}
        >
            <DialogTitle id="alert-dialog-title">
                Criar uma conta no Cartorizi
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Box sx={{ textAlign: "center", mb: 2 }}>
                        <img src="/images/cartorize-logo.svg" width="128px" />
                        <span style={{ fontSize: "24px", marginLeft: 24, marginRight: 24 }}>🔗</span>
                        <img src="https://klausfiscal.com.br/wp-content/themes/klaus-V2/assets/logo-klaus-rebrand.svg" width="96px" />
                    </Box>
                    <Divider />
                    <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
                        Clicando no botão abaixo você vinculará sua conta criada no Klaus Accounts com o Cartorizi {(props.email) ? `utilizando o e-mail {props.email}` : ""}
                    </Typography>
                </DialogContentText>
                <Box sx={{ textAlign: "center", mt: 8 }}>
                    <Button variant="contained" onClick={createAccount} autoFocus size="large" disabled={loading}>
                        {
                            (loading)
                                ?
                                <CircularProgress />
                                :
                                <span>
                                    Criar e Vincular Conta
                                </span>
                        }
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions sx={{ textAlign: 'center' }}>

            </DialogActions>
        </Dialog>
    );
}

export default CreateContractsAccountDialog;