import App from './App';
import { createRoot } from 'react-dom/client';
import './index.css';

import { Provider } from 'react-redux'
import store from './redux/store'

import { ThemeProvider } from '@mui/material/styles';
import { Theme } from "./theme"
import AppNotification from './components/AppNotification';
import '@fontsource/noto-sans'
import '@fontsource/poppins'

import authenticationStorage from "./store/authentication";
import {AuthenticatedUser} from "./models/user";
import ContractViewDialog from './components/ContractViewDialog';

import 'chart.js/auto'
import Splashscreen from './components/Splashscreen';
import ErrorWrapper from './utils/ErrorWrapper';
import { useState } from 'react';

const app = document.getElementById('root');
const root = createRoot(app!);

let hasError = false

// Adicione os scripts do Movidesk chat aqui
const movideskChatClient = "07C5939BEB7C4C02AA89146719ED0A2B";

const movideskChatScript = document.createElement('script');
movideskChatScript.type = 'text/javascript';
movideskChatScript.innerHTML = `var mdChatClient="${movideskChatClient}";`;

const chatWidgetScript = document.createElement('script');
chatWidgetScript.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';

document.head.appendChild(movideskChatScript);
document.head.appendChild(chatWidgetScript);

// this promise is used to create an authorization every time that the application is loaded for the first time
new Promise<AuthenticatedUser>((resolve, reject) => {
    // get the accounts token
    const accountsToken = authenticationStorage.getAccountsToken();
    if (accountsToken == null) {
        reject("The user session does not contain an accounts token");
        return;
    }

    root.render(
        <Splashscreen hasErrors={false} />
    )

    // authenticate the user with the accounts token
    authenticationStorage.authenticationWithAccountsToken(accountsToken)
        .then(authenticatedUser => {
            resolve(authenticatedUser);
        })
        .catch((e) => {
            reject(e);
        });
    
})
// if the promise failed to create the authorization token automatically it will logout the user
.catch((e) => {
    const err = new ErrorWrapper(e)
    console.error(err);
    if ((!err.isHttpError && e !== "The user session does not contain an accounts token") || err.is5xx) {
        hasError = true
        root.render(
            <Splashscreen hasErrors={true} />
        )
    } else {
        authenticationStorage.logout(() => {});
    }
})
.finally(() => {
    if(!hasError) {
        root.render(
            <Provider store={store}>
                <ThemeProvider theme={Theme}>
                    <App />
                    <AppNotification />
                    <ContractViewDialog />
                </ThemeProvider>
            </Provider>
        );
    }

});
