import { Box, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { EncapsulatedContract } from "../../models/contracts";
import "./contracts-card.css";
import moment from "moment";
import { Close, FindInPage } from "@mui/icons-material";
import { useAppDispatch } from "../../redux/hooks";
import { show } from "../../redux/features/contract-view-dialog/contract-view-dialog"

interface DialogProps extends React.ComponentProps<any> {
    contracts: EncapsulatedContract[]
    open: boolean,
    loading: boolean,
    onClose: () => void
}

interface InternalComponentsProps extends React.ComponentProps<any> {
    contract: EncapsulatedContract
}

const ContractsScheduleForDeletionDialog = (props: DialogProps): JSX.Element => {

    // Destructure props object
    const { contracts, open, loading, onClose } = props;

    const notification = useAppDispatch();

    function setCardClass(contract: EncapsulatedContract): string {

        const momentIssue = moment(contract.normalized.issuanceDate);

        const momentDelete = momentIssue.clone().add(90, "days");

        const diff = Math.abs(moment().diff(momentDelete, "days"));

        if (diff >= 30) return "contract-card";
        else if (diff >= 20 && diff < 30) return "contract-card-warning";
        else if (diff >= 10 && diff < 20) return "contract-card-urgent";
        else return "contract-card-error";
    }

    const TruncatedTextWithTooltip = (props: InternalComponentsProps): JSX.Element => {
        const { name } = props.contract.normalized;
        const maxLength = 77;

        if (name.length > maxLength) {
            const truncatedName = name.substring(0, maxLength) + '...';

            return (
                <Tooltip title={name} arrow>
                    <Typography variant="subtitle2" my={1}>{truncatedName}</Typography>
                </Tooltip>
            );
        }

        return <Typography variant="subtitle2" my={1}>{name}</Typography>;
    };

    /**
     * Display a label that indicates how long since the contract was issued
     * @param contract 
     */
    const HowLongToDeleteLabel = (props: InternalComponentsProps): JSX.Element => {
        const momentIssueDate = moment(props.contract.normalized.issuanceDate)
        let momentSinceIssue = momentIssueDate.add(90, "days").fromNow();
        return (
            <Typography variant="caption"><b>Tempo até o contrato ser deletado: </b> {momentSinceIssue.toLocaleLowerCase()}</Typography>
        );
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth >
            <DialogTitle>
                <Typography variant="h6" >Contrato que serão excluidos</Typography>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant="caption" >Somente contratos que não foram assinados no prazo de 90 dias desde sua criação serão excluidos</Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => { onClose() }}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ maxHeight: "550px", overflow: "auto" }}>
                    {
                        contracts.map(contract => (
                            <>
                                <Box className={setCardClass(contract)}>
                                    <Grid container sx={{display: "flex", alignItems: "center", justifyContent: "center"}} >
                                        <Grid item xs={10} >
                                            <TruncatedTextWithTooltip contract={contract} />

                                            <Divider light sx={{ my: 1 }} />
                                            <HowLongToDeleteLabel contract={contract} />
                                        </Grid>
                                        <Grid item xs={2} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            <Box>
                                                {/* action menu*/}
                                                <IconButton
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    onClick={() => notification(show({visible: true, contract: contract}))}
                                                >
                                                    <FindInPage color="primary" fontSize="large" />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        ))
                    }
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ContractsScheduleForDeletionDialog;