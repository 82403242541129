import React, { SyntheticEvent, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, Drawer, Grid, Switch, Typography } from "@mui/material";
import { LoadingButton } from "../../components/LoadingButton";
import { OrganizationTeam, SelectedMember } from "../../models/teams";
import { useAppDispatch } from '../../redux/hooks';
import SearchBar from "../../components/SearchBar";
import { SearchResult } from "../../models/search-result";
import TeamsService from "../../services/teams";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import ErrorWrapper from "../../utils/ErrorWrapper";
import SelectMembersToImport from "./SelectMembers";

interface ImportTeamsProps extends React.ComponentProps<any> {
    open: boolean,
    close: () => void,
    onSuccess: (importedTeam: OrganizationTeam) => void,
    currentSubusers: Number,
    maxSubusers: Number,
    updateQuota: () => void
}

const ImportTeams = (props: ImportTeamsProps) => {

    const { open, close, onSuccess, currentSubusers, maxSubusers, updateQuota } = props;

    //Context props
    const notification = useAppDispatch();

    //States
    const [loading, setLoading] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState<OrganizationTeam | null>(null)
    const [importMembers, setImportMembers] = useState(true);
    const [showImportMembers, setShowImportMembers] = useState(false)

    const [importedMembers, setImportedMembers] = useState<SelectedMember[] | null>(null)

    const [openImportMembersDialog, setOpenImportMembersDialog] = useState(false)

    //Reset state on close
    useEffect(() => { setShowImportMembers(false) }, [close])

    //Events  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImportMembers(event.target.checked);
    };

    const handleSearchBarChange = (event: SyntheticEvent, value: SearchResult) => {
        //Ignore empty values
        if (!value) return;

        //Get the UserAccount from the search result
        const newTeam: OrganizationTeam = value.object as OrganizationTeam;

        //Storage the selected team
        setSelectedTeam(newTeam)
        setShowImportMembers(true)
    }

    const importTeam = async () => {
        if (!selectedTeam) return;
        setLoading(true);

        try {
            //Import Klaus Accounts team into Contracts plataform
            const importedTeam = await TeamsService.import({
                teamId: selectedTeam.guid,
                membersUuid: []
            })

            //Notify user and close drawer
            notification(show({ type: 'success', message: `Time importado` }))
            onSuccess(importedTeam);
            close();
        }
        catch (e) {
            notification(show({
                type: 'error',
                message: `Ocorreu um erro: ${new ErrorWrapper(e).message}`
            }))
        }
        finally { 
            setLoading(false);
        }
    }
    
    const fetchTeamMembers = async () => {
        if (!selectedTeam) return;

        const fetchedTeamMembers = await TeamsService.searchAllMembersFromAccounts(selectedTeam.guid);
        const normalizedMembers = fetchedTeamMembers.map(member => {
            const normMember: SelectedMember = {
                user: member,
                importUser: false
            }
            return normMember
        })
        setImportedMembers(normalizedMembers)
        
    }

    const handleImportMembers = () => {
        fetchTeamMembers()
        setOpenImportMembersDialog(true)
        props.close()
    }

    

    return (
        <Box>
            <Drawer anchor='right' open={open} onClose={close} sx={{ '& .MuiDrawer-paper': { width: { sm: 550, xs: 350 }, p: { sm: 5, xs: 3 } } }}>
                <Typography variant='h6' mb={5}>Importar time do <strong>Klaus Accounts</strong></Typography>
                <Typography mb={3} variant='body1'>Busque times através do nome ou do ID</Typography>
                <SearchBar searchMode="teams" fullWidth
                    onChange={handleSearchBarChange}
                    renderInputProps={{ label: "Time do Klaus Accounts" }}
                />
                {
                    !showImportMembers ? null :
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
                            <Typography>Importar membros</Typography>
                            <Switch color="primary" checked={importMembers} onChange={handleChange} />
                        </Box>
                }
                <DialogActions sx={{ mt: 7 }}>
                    <Button onClick={close} color='error'>Cancelar</Button>
                    {
                        importMembers
                        ?
                            <Button variant="contained" disabled={!selectedTeam} onClick={handleImportMembers} >Selecionar membros</Button>
                        :
                            <LoadingButton variant='contained' loading={loading} onClick={importTeam}>Salvar</LoadingButton>
                    }
                </DialogActions>
            </Drawer>
            <SelectMembersToImport 
                currentSubusers={currentSubusers} 
                maxSubusers={maxSubusers} 
                importedMembers={importedMembers}
                selectedTeam={selectedTeam ? selectedTeam.guid : null}
                open={openImportMembersDialog}
                close={() => setOpenImportMembersDialog(false)}
                onSuccess={onSuccess}
                updateQuota={updateQuota}
            />
        </Box>
    )
}

export default ImportTeams;
