import { Box, Button, CircularProgress, SxProps, Typography, Alert, Divider, Avatar, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Theme } from "@mui/system";
import React, { useState, ChangeEvent, useEffect } from "react";
import { AttachmentStatus, DigitalContractAttachment, EditableDigitalContract, NormalizedAttachment } from "../../../models/contracts";
import { AuthenticatedUser } from "../../../models/user";
import { useAppDispatch } from "../../../redux/hooks";
import { show } from "../../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../../utils/ErrorWrapper";
import contractsService from "../../../services/contracts";
import { InterfaceTheme } from "../../../models/organizations";
import { Check, Close, AccessTime, Upload } from "@mui/icons-material";
import RequiredAttachment from "../RequiredAttachment";
import ContractsService from "../../../services/contracts";

interface ContractProps extends React.ComponentProps<any> {
    source: EditableDigitalContract | undefined,
    user: AuthenticatedUser | undefined,
    interfaceTheme: InterfaceTheme | null
};
const WaitingForEmission = (props: ContractProps): JSX.Element => {

    const { source, user, interfaceTheme } = props

    //Verify if the contract is Revoked
    const isRevoked = props.source?.revokedBy !== null && (props.source?.revokedBy || []).length > 0;

    const [loadingTemplateFile, setLoadingTemplateFile] = useState(false);
    const [loadingIssueContract, setLoadingIssueContract] = useState(false);
    const [loading, setLoading] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const notification = useAppDispatch();

    const waitingForEmissionBoxTheme: SxProps<Theme> = {
        ['@media(max-width:1000px)']: {
            width: "100%"
        },
        minHeight: "100vh", width: "900px"
    }
    const buttonIssueTheme: SxProps<Theme> = {
        ['@media(max-width:300px)']: {
            width: "100%"
        },
        height: "36px",
        width: "210px",
        margin: "14px 0px"
    }
    const revokedAlertTheme: SxProps<Theme> = {
        ['@media(max-width:700px)']: {
            width: "100%"
        },
        width: "900px"
    }

    //Turns editable contract into issued
    async function turnsEditableContractIntoIssued() {
        setLoadingIssueContract(true)
        if (props.source) {
            try {
                await contractsService.issueNewIssuedContract(props.source.id);
                notification(show({
                    type: 'success',
                    message: 'Emitido com sucesso.'
                }))
                return;
            }
            catch (e) {
                const err = new ErrorWrapper(e)
                console.error(e);
                notification(show({
                    type: 'error',
                    message: err.message == "Contract is not ready to be issued. Check the issuance state of this contract before issuing it" 
                                                ? 
                                                "Contrato não está pronto para ser enviado para assinatura. Verifique o estado do contrato antes de enviar para assinatura." 
                                                :
                                                err.message == "Unable to issue contract due to excess of quota"
                                                ?
                                                "Ocorreu um erro: Sua cota de emissão atingiu o limite."
                                                :
                                                err.message
                }));
            }
            finally {
                setTimeout(() => {
                    window.location.reload();
                }, 1200)
                setLoadingIssueContract(false)
            }
        }
    }

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    async function uploadRequiredAttachment(e: ChangeEvent<HTMLInputElement>, attachment: DigitalContractAttachment) {
        if(!props.source) return;
        if (e.target.files && e.target.files.length >= 1) {
            setLoading(true);

            const file = e.target.files[0];
            try {
                const fileName = file.name.replace(/[^a-zA-Z0-9 ._-]/g, "-");
                const attachmentName = encodeURIComponent(attachment.name)

                await ContractsService.uploadUserAttachments(
                    props.source.id,
                    { file: file },
                    fileName,
                    attachmentName
                )
                notification(show({
                    type: 'success',
                    message: 'Upload do arquivo enviado com sucesso.'
                }))
            }
            catch (e) {
                const err = new ErrorWrapper(e)
                notification(show({
                    type: 'error',
                    message: (err.httpStatus != 200) ? `Não foi possível enviar o arquivo.` : undefined
                }))
                
            }
            finally {
                setLoading(false);
            }
        }
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyItems: "center", flexDirection: "column", clear: "both" }}>
            {
                isRevoked
                    ?
                    <Box sx={revokedAlertTheme}>
                        <Alert severity="error" sx={{ margin: "16px 0" }}>Contrato não poderá prosseguir para assinatura pois foi revogado por uma das partes.</Alert>
                    </Box>
                    :
                    <></>
            }
            <Box sx={waitingForEmissionBoxTheme}>
                < Box sx={{
                    width: "100%",
                    display: "block",
                    padding: "20px 28px",
                    color: "#555555",
                    backgroundColor: "#ffffff",
                    boxShadow: 5,
                    margin: "auto",
                    marginBottom: "14px",
                    borderRadius: "10px",
                    borderStyle: "solid",
                    borderColor: interfaceTheme ? interfaceTheme.primaryColor : "#64067f",
                    borderWidth: "10px 0px 0px 0px",
                    boxSizing: "border-box",
                }}>
                    <Typography sx={{ color: "#555555", }}>
                        Todas as Informações e Anexos Requeridos foram adicionados, aguarde a emissão do contrato.
                    </Typography>
                </Box>
                {
                    (props.source?.issuerUuid === props.user?.account.uuid)
                        ?
                        loadingIssueContract
                            ?
                            <Button sx={buttonIssueTheme} variant="contained">
                                <CircularProgress size={25} sx={{ color: "#fff" }} />
                            </Button>
                            :
                            <Button
                                sx={{ ...buttonIssueTheme, '&:hover': { backgroundColor: interfaceTheme ? interfaceTheme.secondaryColor : "#BF87C4" } }}
                                variant="contained"
                                component="label"
                                disabled={loadingIssueContract}
                                onClick={() => turnsEditableContractIntoIssued()}>
                                <Typography sx={{ display: "flex", fontSize: "14px", color: "#fff" }}>Enviar para assinatura</Typography>
                            </Button>
                        :
                        <></>
                }
                {
                    (props.source?.attachments && props.source?.attachments.filter(a => a.status).length >= 1)
                        ?
                            <Box sx={{width: "100%",
                                display: "block",
                                padding: "20px 28px",
                                color: "#555555",
                                backgroundColor: "#ffffff",
                                boxShadow: 5,
                                margin: "auto",
                                marginBottom: "14px",
                                borderRadius: "10px",
                                boxSizing: "border-box",}}>
                                {
                                (props.source.attachments.filter(a => a.status === AttachmentStatus.APPROVED).length >= 1)
                                ?
                                <Box sx={{mb: 2}}>
                                    <Typography sx={{fontSize: "18px", fontWeight: 600}}>Anexos aprovados</Typography>
                                        {
                                            props.source.attachments
                                            .filter(a => a.status === AttachmentStatus.APPROVED)
                                            .map(a => (
                                                <>
                                                    <Box sx={{padding: 2}}>
                                                        <Box sx={{width: "100%", display: "grid", alignItems:"center", padding: 1, gridTemplateColumns: "0.1fr 2fr 1fr 1fr"}}>
                                                            <Avatar sx={{ bgcolor: "#66ba6a", mr: 1, width:"24px", height: "24px" }}><Check sx={{fontSize: "1rem"}}/></Avatar>
                                                            <Typography>{a.name}</Typography>
                                                            <Typography sx={{display: "flex"}}>
                                                                {(screenWidth <= 600) ? <></> : <b>Status:</b>} 
                                                                <Typography sx={{color: "#66ba6a", fontWeight: 600, ml: 1}}>Aprovado</Typography>
                                                            </Typography>
                                                        </Box>
                                                        <Divider/>
                                                    </Box>
                                                </>
                                            ))
                                        }
                                </Box>
                                    :
                                    <></>
                                }
                                {
                                    props.source.attachments.filter(a=> a.status === AttachmentStatus.PENDING || a.status === AttachmentStatus.REPROVED).length >= 1
                                    ?
                                        <>
                                            <Typography sx={{fontSize: "18px", fontWeight: 600}}>Anexos aguardando aprovação</Typography>
                                            {
                                                props.source.attachments
                                                .filter(a=> a.status === AttachmentStatus.PENDING || a.status === AttachmentStatus.REPROVED)
                                                .map(a => (
                                                    <>
                                                    <Box sx={{padding: 2}}>
                                                        <Box sx={{width: "100%", display: "grid", alignItems:"center", padding: 1, gridTemplateColumns: "0.1fr 2fr 1fr 1fr"}}>
                                                            <Avatar sx={{ bgcolor: (a.status == AttachmentStatus.REPROVED ? "#AD0000" : "#999") , mr: 1, width:"24px", height: "24px"}}>{a.status == AttachmentStatus.REPROVED ? <Close sx={{fontSize: "1rem"}}/> : <AccessTime sx={{fontSize: "1rem"}}/>  }</Avatar>
                                                            <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>{a.name}</Typography>
                                                            <Typography sx={{ display:"flex" }}>
                                                                {(screenWidth <= 600) ? <></> : <b>Status:</b>} 
                                                                <Typography sx={{color: (a.status == AttachmentStatus.REPROVED ?  "#AD0000" : "#999"), ml: 1, fontWeight: 600}}>
                                                                    {a.status == AttachmentStatus.REPROVED ? "Reprovado" : "Pendente"}
                                                                </Typography>
                                                            </Typography>
                                                            {
                                                                (a.status === AttachmentStatus.REPROVED)
                                                                ?
                                                                    loading
                                                                    ?
                                                                    <CircularProgress size={24}/>
                                                                    :
                                                                    <Button component="label" startIcon={<Upload />}>
                                                                    {(screenWidth <= 600) ? <></> : "Enviar novamente"} 
                                                                    <input
                                                                        type="file"
                                                                        id="select-image"
                                                                        onChange={(e) => {
                                                                            uploadRequiredAttachment(e, a)
                                                                        }}
                                                                        hidden
                                                                        />
                                                                    </Button>
                                                                :
                                                                <></>
                                                            }
                                                        </Box>
                                                        {
                                                           (a.status === AttachmentStatus.REPROVED)
                                                           ?
                                                                <Box sx={{p: 1}}>
                                                                    <Typography sx={{display: "flex", fontSize: "14px", alignItems: "center"}}><b>Motivo:</b>
                                                                        <Typography sx={{fontSize: "12px", ml: 1, color: "#999"}}>{a.statusDescription}</Typography>
                                                                    </Typography>
                                                                </Box>
                                                            :
                                                                <></>
                                                        }
                                                        <Divider/>
                                                    </Box>
                                                </>
                                                ))
                                            }
                                        </>
                                        :
                                        <></>
                                }
                            </Box>
                        :
                            <></>
                }
                {
                    props.source
                        ?
                        <Box sx={{ height: "70vh", display: "inline-block", width: "100%" }}>
                            {loadingTemplateFile ? <Box height={1} alignItems='center' display='flex' justifyContent='center'><CircularProgress /></Box> : null}
                            <iframe
                                onLoad={() => setLoadingTemplateFile(false)} frameBorder="0"
                                src={`${process.env.REACT_APP_REGISTRY_WEBAPP}/templates/${props.source.template.templateInputFile.fileId}/template-file-viwer`}
                                style={{ overflow: 'hidden', height: '170%', width: '100%' }}
                            />
                        </Box>
                        :
                        <></>
                }
            </Box >
        </Box>
    )
}
export default WaitingForEmission;
