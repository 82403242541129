import { SearchResult } from "../models/search-result";
import OrganizationService from "./organization";
import accountsAPI from "../services/accounts-api"
import ContractsApi from "./contracts-api";
import { EditorSearchResult, EditorSearchResultType } from "../utils/editorUtils";

interface ApiSearchResult {
    type: 'ORGANIZATION_ACCOUNT' | 'ORGANIZATION_SUBUSER_ACCOUNT' | 'ORGANIZATION_TEAM';
    data: any
}

/**
 * Normalize the subdescription prop from the API search
 * @param data 
 * @returns 
 */
function normalizeSubdescription(data: ApiSearchResult): string | null {
    switch (data.type) {
        case 'ORGANIZATION_TEAM':
            return data.data.guid;
        case 'ORGANIZATION_SUBUSER_ACCOUNT': case 'ORGANIZATION_ACCOUNT':
            return data.data.email;
        default:
            return null;
    }
}

/**
 * Normalize the description prop from the API search
 * @param data 
 * @returns 
 */
function normalizeDescription(data: ApiSearchResult): string {
    switch (data.type) {
        case 'ORGANIZATION_SUBUSER_ACCOUNT': case 'ORGANIZATION_ACCOUNT':
            return data.data.fullname;
        case 'ORGANIZATION_TEAM':
            return data.data.name;
        default:
            throw Error(`Not identified entity: ${data.type}`);
    }
}

/**
 * Normalize the id prop from the API search
 * @param data 
 * @returns 
 */
function normalizeId(data: ApiSearchResult): string {
    switch (data.type) {
        case 'ORGANIZATION_SUBUSER_ACCOUNT': case 'ORGANIZATION_ACCOUNT':
            return data.data.uuid;
        case 'ORGANIZATION_TEAM':
            return data.data.guid;
        default:
            throw Error(`Not identified entity: ${data.type}`);
    }
}

/**
 * Normalize the id prop from the API search
 * @param type 
 * @returns 
 */
function normalizaDataType(type: string) {
    switch (type) {
        case 'CUSTOMER_SUBUSER':
            return 'ORGANIZATION_SUBUSER_ACCOUNT'
        case 'CUSTOMER':
            return 'ORGANIZATION_ACCOUNT';
        case 'CUSTOMER_TEAM':
            return 'ORGANIZATION_TEAM';
        default:
            throw Error(`Not identified entity: ${type}`);
    }
}

const SearchService = {
    /**
     * Search all teams associated with the authenticated user organization from Klaus Accounts
     * @param query 
     * @returns 
     */
    searchAllTeamsFromKlausAccounts: async (query: string = ''): Promise<SearchResult[] | null> => {
        //Fetch the result from the API
        const response = await accountsAPI.get(`/rest/v1/organizations/my/search/teams?q=${query}`)

        //If the response is empty, return null
        if (!response || response.status === 204) return null;

        //Parse the response
        const responseData = await (response).data as ApiSearchResult[];

        //Normalize the data
        return responseData.map((data): SearchResult => {
            data.type = normalizaDataType(data.type);
            return { 
                type: data.type,
                description: normalizeDescription(data),
                subdescription: normalizeSubdescription(data),
                id: normalizeId(data),
                object: data.data
            }
        })
    },

    /**
     * Searsh all subusers from the requester organization
     * @param query 
     * @returns 
     */
    searchAllUsersFromKlausAccounts: async (query: string): Promise<SearchResult[] | null> => {
        //Fetch the result from the API
        const response = await accountsAPI.get(`/rest/v1/organizations/my/search/user-accounts?q=${query}`)

        //If the response is empty, return null
        if (!response || response.status === 204) return null;

        //Parse the response
        const responseData = await (response).data as ApiSearchResult[];
        
        //Normalize the data
        return responseData.map((data): SearchResult => {
            data.type = normalizaDataType(data.type);
            return {
                description: normalizeDescription(data),
                subdescription: normalizeSubdescription(data),
                type: data.type,
                id: normalizeId(data),
                object: data.data
            }
        })
    },

    searchAllOrganizationUsers: async (query: string): Promise<SearchResult[] | null> => {
        //Fetch the result from the API
        const response = await OrganizationService.fetchAllUsers(query);

        //If the response is empty, return null
        if (!response) return null;

        //Normalize the data
        return response.map((data): SearchResult => {
            return {
                description: normalizeDescription(data),
                subdescription: normalizeSubdescription(data),
                type: data.type,
                id: normalizeId(data),
                object: data.data
            }
        })
    },

    searchAllEntities: async (searchTerm: string): Promise<EditorSearchResult[] | null> => {
        //Fetch the result from the API
        const response = await ContractsApi.get(`/rest/v1/organizations/my/search/all-entities?q=${searchTerm}`);
     
        //If the response is empty, return null
        if (!response || response.status === 204) return null;

        //Parse the response
        const responseData = await (response).data as ApiSearchResult[];

        //Normalize the data
        return responseData.map((data): EditorSearchResult => {
            return {
                description: normalizeDescription(data),
                subdescription: normalizeSubdescription(data),
                type: data.type as EditorSearchResultType,
                id: normalizeId(data),
                object: data.data
            }
        })
    }
}

export default SearchService;