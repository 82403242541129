import { useState } from "react";
import { Box, Button, DialogActions, Typography } from "@mui/material";
import { LoadingButton } from "../../components/LoadingButton";
import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../utils/ErrorWrapper";
import TeamsService from "../../services/teams";
import { UserAccount } from "../../models/user";
import SearchBar from "../../components/SearchBar";
import { SearchResult } from "../../models/search-result";
import { OrganizationTeam } from "../../models/teams";

interface AddMembersProps extends React.ComponentProps<any> {
    team: OrganizationTeam | null,
    close: () => void,
    onSuccess: (newMembers: UserAccount[]) => void,
    updateQuota: () => void
}

const AddTeamMembers = (props: AddMembersProps) => {

    //Props
    const { close, team, members, supervisors, onSuccess, updateQuota } = props;

    //Indicates the maximum amount of members a organization team can have
    const MAX_ORGANIZATION_TEAM_MEMBERS = 999;

    //Context props
    const notification = useAppDispatch();

    //States
    const [loading, setLoading] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState<SearchResult[]>([]);

    //Events
    const handleSearchBarChange = (event: any, value: SearchResult[]) => setSelectedMembers(value);

    /**
     * Add new members into the organization team
     * @returns 
     */
    const addMembers = () => {
        if (!team) return;
        setLoading(true)

        //Concat old members with new members
        const membersUuid = members.concat(selectedMembers.map((m: SearchResult): string => m.id));
        let supervisorsUuid = supervisors.map((s: UserAccount) => s.uuid);

        //Check if total members have reached the max organization team members value of 999 members
        if (membersUuid.length > MAX_ORGANIZATION_TEAM_MEMBERS)
            return notification(show({ type: 'error', message: `Número máximo de membros atingido` }))

        //Send request
        TeamsService.update(team.guid, {
            name: team.name,
            members: membersUuid,
            supervisors: supervisorsUuid
        })
            .then(() => {
                //Callback functions when members where added
                onSuccess(selectedMembers.map((m: SearchResult) => m.object as UserAccount) as UserAccount[]);
                close();
            })
            .catch(err => {
                console.error(err)
                //Show notification error
                notification(show({
                    type: 'error',
                    message: `Ocorreu um erro: ${new ErrorWrapper(err).message}`
                }))
            })
            .finally(() => {
                setLoading(false)
                updateQuota();
            })
    }

    return (
        <Box height='inherit'>
            <Typography variant='h6' color='primary.main' mb={5}>Novos membros de <strong>{team?.name}</strong></Typography>

            <Typography mb={3}>Selecione os membros que deseja adicionar ao time</Typography>
            <SearchBar
                searchMode='contracts-users'
                fullWidth multiple disableCloseOnSelect
                onChange={handleSearchBarChange}
                renderInputProps={{ label: 'Busque pelo nome, email ou ID do usuário' }} />

            <DialogActions sx={{ mt: 5 }}>
                <Button onClick={close}>Voltar</Button>
                <LoadingButton variant='contained' loading={loading} onClick={addMembers}>Salvar</LoadingButton>
            </DialogActions>
        </Box>
    )
}

export default AddTeamMembers;