import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material";
import { IssuedDigitalContract } from "../../models/contracts";
import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from "react";
import ContractsService from "../../services/contracts";
import { DigitalContractTemporaryAccessPin } from "../../models/digital-contract-temporary-access-pin";
import { Delete } from "@mui/icons-material";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { useAppDispatch } from "../../redux/hooks";

interface OnCloseCallback {
    (contractWasRenewed: boolean): void
}

interface Props extends React.ComponentProps<any> {
    open: boolean;
    source: IssuedDigitalContract;
    onClose: OnCloseCallback;
}

const GenerateTemporaryAccessPin = (props: Props): JSX.Element => {
    // presets
    let pinCreationSecondsTimeout = 30;
    //Context props
    const notification = useAppDispatch();

    const [currentView, setCurrentView] = useState<"warning" | "pin-modal">("warning");
    const [pin, setPin] = useState('000000');
    const [contractPins, setContractPins] = useState<DigitalContractTemporaryAccessPin[]>([]);
    const [loading, setLoading] = useState(false);
    const [generatePinButtonDisabled, setGeneratePinButtonDisabled] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(30);
    const [pinCreationInterval, setPinCreationInterval] = useState<NodeJS.Timer | undefined>(undefined);

    const formattedTime = `${Math.floor(timeRemaining / 60)
        .toString()
        .padStart(2, '0')}:${(timeRemaining % 60).toString().padStart(2, '0')}`;

    function handleDialogOnCloseEvent() {
        stopPinCreationInterval();
        //Close the dialog and set values to default
        props.onClose(false);
        setCurrentView("warning");
        setPin('000000');
        setContractPins([]);
        setGeneratePinButtonDisabled(false);
        setTimeRemaining(30)
    }

    function startPinCreationInterval() {
        if (pinCreationInterval) clearInterval(pinCreationInterval);
        setPinCreationInterval(setInterval(() => {
            pinCreationSecondsTimeout -= 1;
            setTimeRemaining(pinCreationSecondsTimeout);
        }, 1000))
    }

    function stopPinCreationInterval() {
        if (pinCreationInterval) clearInterval(pinCreationInterval);
        setPinCreationInterval(undefined);
    }

    async function generateDigitalContractAccessPIN() {
        // Generate pin access and list them
        setGeneratePinButtonDisabled(true)
        try {
            const pin = await ContractsService.generateDigitalContractAccessPIN(props.source.id);
            listContractAccessPins();
            setPin(pin.id);
            startPinCreationInterval();
        } catch (e) {
            setGeneratePinButtonDisabled(false);

            const error = new ErrorWrapper(e)
            notification(show({
                type: "error",
                message: error.httpStatus == 404 ? "PIN incorreto. Ele pode ter expirado ou ter sido deletado pela organização" :
                    error.message == "Reached pin creation limit" ? "Você atingiu o limite de criação de pins (Máximo de 10 por contrato)" : "Algo deu errado. Não foi possível criar o pin."
            }))
        }
    }

    async function listContractAccessPins() {
        setLoading(true);
        const pins = await ContractsService.fetchIssuedDigitalContractTemporaryAccessPins(props.source.id);
        setContractPins(pins);

        setLoading(false);
    }

    async function deletePin(pin: DigitalContractTemporaryAccessPin) {
        try {
            await ContractsService.deleteIssuedDigitalContractTemporaryAccessPin(pin.contractId, pin.id);
            notification(show({
                type: "success",
                message: "Pin deletado com sucesso."
            }))
            const auxList = contractPins.filter((p) => p.id !== pin.id);
            setContractPins(auxList)
        } catch (e) {
            notification(show({
                type: "error",
                message: "Algo deu errado. Não foi possível deletar esse PIN"
            }))
        }
    }

    useEffect(() => {
        if (timeRemaining <= 0) {
            setTimeRemaining(30)
            setGeneratePinButtonDisabled(false);
        }
    }, [timeRemaining])

    return (
        <Dialog
            open={props.open}
            onClose={handleDialogOnCloseEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" fontWeight={600} sx={{ mb: 2 }}>
                Gerar PIN de compartilhamento de acesso de contrato
            </DialogTitle>
            {
                currentView === "pin-modal"
                    ?
                    <>
                        <DialogContent>
                            <DialogContentText>
                                <Box>
                                    <Typography sx={{ textAlign: "center", fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "end" }}><Typography sx={{ color: "red" }} >*</Typography>O PIN expira após 30 minutos e só pode ser usado uma vez.</Typography>
                                    <Typography sx={{ fontSize: "36px", letterSpacing: "0.5em", textAlign: "center", mb: 2, fontWeight: 600 }}>{pin}</Typography>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        {
                                            generatePinButtonDisabled
                                                ?
                                                <Button sx={{ mb: 2 }} disabled={generatePinButtonDisabled} variant="contained">Aguarde {formattedTime} para gerar um novo pin</Button>
                                                :
                                                <Button sx={{ mb: 2 }} disabled={generatePinButtonDisabled} onClick={() => generateDigitalContractAccessPIN()} variant="contained">Gerar PIN de Acesso</Button>
                                        }
                                    </Box>
                                </Box>
                            </DialogContentText>
                            <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Button onClick={handleDialogOnCloseEvent}>Cancelar</Button>
                                <Button onClick={() => listContractAccessPins()} variant="contained">
                                    {
                                        loading
                                            ?
                                            <CircularProgress sx={{ color: "#fff" }} size={24} />
                                            :
                                            "Mostrar lista de PINs"
                                    }
                                </Button>
                            </DialogActions>
                            {
                                (contractPins.length > 0)
                                    ?
                                    <DialogContent>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                                            <Typography fontWeight={600}>PIN</Typography>
                                            <Typography fontWeight={600}>Expira em</Typography>
                                            <Typography />
                                        </Box>
                                        {contractPins.map((p) => (
                                            <>
                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Typography sx={{ mr: 2 }}>{p.id}</Typography>
                                                    <Typography sx={{ textAlign: "left" }}>
                                                        {new Date(p.ttl.valueOf() + 30 * 60 * 1000).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                                    </Typography>
                                                    <Button onClick={() => deletePin(p)}><Delete /></Button>
                                                </Box>
                                                <Divider />
                                            </>
                                        ))}
                                    </DialogContent>
                                    :
                                    <></>
                            }
                        </DialogContent>
                    </>
                    :
                    currentView === "warning"
                        ?
                        <>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
                                    Gerar um <b>PIN</b> de acesso possibilitará qualquer usuário baixar o contrato na página de
                                    <Typography component={RouterLink} to="/validation" target="_blank"> validação</Typography>.
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    Como o Cartorizi preza muito pela sua segurança e pela segurança de seus documentos,
                                    o <b>PIN</b> tem uso único e será deletado após o uso.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDialogOnCloseEvent}>Cancelar</Button>
                                <Button onClick={() => setCurrentView("pin-modal")} variant="contained">Próximo</Button>
                            </DialogActions>
                        </>
                        :
                        <></>
            }
        </Dialog >
    );
}

export default GenerateTemporaryAccessPin;