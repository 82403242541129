import { AlternateEmail, Business, HelpOutline } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { ContractPartie, IdentificationType, RequiredAttachment } from "../../../../../../models/contracts";

// Interfaces and Types
interface AttachmentFormProps extends React.ComponentProps<any> {
    parties: ContractPartie[],
    currentAttach: RequiredAttachment | null,
    attachments: RequiredAttachment[],
    cancelAction: () => void,
    addAttachment: (attach: RequiredAttachment) => void
}

// Default variables
const dummyAttach: RequiredAttachment = {
    contractParty: {
        role: ""
    },
    name: "",
    required: true,
    requiresApproval: false,
    contractPartyRole: ""
}

const AttachmentForm = (props: AttachmentFormProps): JSX.Element => {

    // Destructure props object
    const { parties, currentAttach, attachments, cancelAction, addAttachment } = props;

    // Data states
    const [newAttach, setNewAttach] = useState<RequiredAttachment>(currentAttach ? currentAttach : dummyAttach);
    const [newAttachErrors, setNewAttachErrors] = useState<any>({});
    const [attachName, setAttachName] = useState(newAttach.name);

    // useEffects
    useEffect(() => {
        checkForErrors();
    }, [newAttach])

    // Functions
    function checkForErrors() {
        const newErrors = { ...newAttachErrors };

        // delete all errors
        for (const key in newErrors) {
            delete newErrors[key];
        }

        if (!currentAttach || currentAttach.name !== newAttach.name) {
            const filter = attachments.filter(at => at.name.toLocaleLowerCase() === newAttach.name.toLocaleLowerCase());

            if (filter.length > 0) newErrors["name"] = "Anexo já adicionado";
            else if (!newAttach.name) newErrors["name"] = "Campo obrigatório";
        }

        if (!newAttach.contractParty.role) newErrors["role"] = "Campo obrigatório";

        setNewAttachErrors(newErrors);
        return newErrors;
    }

    // Handler functions
    function handleChangeAttachName(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setAttachName(e.target.value);
        setNewAttach({ ...newAttach, name: e.target.value });
    }

    function handleChangeParty(e: SelectChangeEvent) {
        setNewAttach({ ...newAttach, contractParty: { role: e.target.value }, contractPartyRole: e.target.value })
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                p: 4,
                border: "1px solid rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
                mt: 2,
                mb: 3
            }}
        >
            <FormGroup sx={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
                <FormControlLabel
                    control={<Checkbox checked={newAttach.required} onChange={(e) => setNewAttach({ ...newAttach, required: e.target.checked })} />}
                    label="Preenchimento obrigatório"
                />
            </FormGroup>
            <TextField
                fullWidth
                value={attachName}
                inputProps={{
                    maxLength: 30,
                }}
                onChange={(e) => handleChangeAttachName(e)}
                required
                label="Nome do anexo"
                sx={{ mt: 3 }}
                error={Boolean(newAttachErrors["name"])}
                helperText={newAttachErrors["name"] ? newAttachErrors["name"] : `${newAttach.name.length} / 30`}
            />
            <FormControl fullWidth sx={{ my: 6 }} required error={Boolean(newAttachErrors["role"])}>
                <InputLabel id="contract-party-selection" >Participante responsável por enviar anexo</InputLabel>
                <Select
                    fullWidth
                    labelId="contract-party-selection"
                    value={newAttach.contractParty.role}
                    label="Participante responsável por enviar anexo"
                    renderValue={(value) => (
                        <Box display={"flex"} alignItems={"center"}>
                            {
                                parties.filter(p => p.role === newAttach.contractParty.role).map(party => (
                                    <ListItemIcon>
                                        {
                                            party.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                                ?
                                                <AlternateEmail />
                                                :
                                                <Business />

                                        }
                                    </ListItemIcon>
                                ))
                            }
                            <Typography >{value}</Typography>
                        </Box>
                    )}
                    onChange={(e) => handleChangeParty(e)}
                    error={Boolean(newAttachErrors["role"])}
                >
                    {
                        parties.map(party => (
                            <MenuItem key={party.role} value={party.role} >
                                <ListItemIcon>
                                    {
                                        party.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                            ?
                                            <AlternateEmail />
                                            :
                                            <Business />

                                    }
                                </ListItemIcon>
                                <ListItemText>{party.role}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText >{newAttachErrors["role"] ? newAttachErrors["role"] : ""}</FormHelperText>
            </FormControl >
            <FormGroup sx={{mb: 3, display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "flex-start", width: "100%"}}>
                <FormControlLabel
                    control={<Checkbox checked={newAttach.requiresApproval} onChange={(e) => setNewAttach({ ...newAttach, requiresApproval: e.target.checked })} />}
                    label="Requer aprovação"
                />
                <Tooltip title="Quando selecionada, esta opção vai obrigar que o emissor do contrato aprove este anexo antes de ser enviado para assinatura">
                    <HelpOutline />
                </Tooltip>
            </FormGroup >
            <TextField
                fullWidth
                multiline
                minRows={3}
                maxRows={5}
                label="Adicionar descrição do anexo"
                value={newAttach.description}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewAttach({ ...newAttach, description: event.target.value })}
                inputProps={{ maxLength: 200 }}
                helperText={`${newAttach.description ? newAttach.description.length : 0} / 200`}
                sx={{ mb: 3 }}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                <Button variant="text" onClick={cancelAction} >Cancelar</Button>
                <Button variant="contained" disabled={Object.keys(newAttachErrors).length > 0} onClick={() => addAttachment(newAttach)} >Salvar</Button>
            </Box>
        </Box>
    )
}

export default AttachmentForm;