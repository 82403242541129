import { Pagination } from "../models/pagination";
import contractsApi from "./contracts-api";

interface FinanceChargeRoles {
    financialChargeRole: "debited" | "credited",
}

export interface AbstractPaymentAccount {
    creationDate: number,
    customerOrAdministrator: boolean,
    deletedAt: number | null,
    email: string,
    fullname: string,
    hasHandwrittenSignature: boolean,
    preferredName: string,
    preferredRegion: string,
    uuid: string,
}

export interface FinanceCharge {
    chargeOrigin: "PLATFORM_CHARGE" | "PLATFORM_SUBSCRIPTION",
    chargeOriginId: string,
    creditedAccount: AbstractPaymentAccount,
    currency: string,
    debitedAccount: AbstractPaymentAccount,
    dueDate: number,
    licenseId: string | null,
    licenseOrigin: string,
    name: string,
    productId: string,
    productOrigin: string,
    region: string,
    value: number,
}

export interface SubscriptionCharge {
    billingDueDay: number,
    creditedAccount: AbstractPaymentAccount,
    currency: string,
    debitedAccount: AbstractPaymentAccount,
    finalBillingPeriod: number | null,
    initialBillingPeriod: number,
    licenseId: string | null,
    licenseOrigin: string,
    name: string,
    processingOffsetDate: number | null,
    productId: string,
    productOrigin: string,
    region: string,
    value: number,
}

const FinanceService = {

    /**
    * Fetch Finance Charges
    */
    async fetchFinanceCharges(
        financialRole: "debited" | "credited",
        startDate: number,
        endDate: number,
        page: number,
        limit: number = 30,
        productId?: String,
    ): Promise<FinanceCharge[]> {
        let url = `/rest/v1/finances/my/charges/${financialRole}?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
        if(productId) url += `&productId=${productId}`;
        return await (await contractsApi.get(url)).data;
    },
    /**
     * Fetch Subscription Charges
     */
    async fetchSubscriptionCharges(
        financialRole: "debited" | "credited",
        startDate: number,
        endDate: number,
        page?: number,
        limit: number = 30,
        productId?: String,
    ): Promise<SubscriptionCharge[]> {
        let url = `/rest/v1/finances/my/subscriptions/${financialRole}?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
        if(productId) url += `&productId=${productId}`
        return await (await contractsApi.get(url)).data;
    },

    /**
    * Fetch Finance Charges
    */
    async fetchFinanceChargesPagination(
        financialRole: "debited" | "credited",
        startDate: number,
        endDate: number,
        limit: number = 30,
        productId?: String,
    ): Promise<Pagination> {
        let url = `/rest/v1/finances/my/charges/${financialRole}/pagination?startDate=${startDate}&endDate=${endDate}&limit=${limit}`;
        if(productId) url += `&productId=${productId}`;
        return await (await contractsApi.get(url)).data;
    },
    /**
     * Fetch Subscription Charges
     */
    async fetchSubscriptionChargesPagination(
        financialRole: "debited" | "credited",
        startDate: number,
        endDate: number,
        limit: number = 30,
        productId?: String,
        ): Promise<Pagination> {
            let url = `/rest/v1/finances/my/subscriptions/${financialRole}/pagination?startDate=${startDate}&endDate=${endDate}&limit=${limit}`;
            if(productId) url += `&productId=${productId}`;
        return await (await contractsApi.get(url)).data;
    },

}
export default FinanceService;