import { Delete, Description, Edit, History, MoreVert, OpenInNew, PictureAsPdf, Report, Send } from "@mui/icons-material";
import { Badge, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Menu, MenuItem, Skeleton, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Theme, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalSearchBar from "../../../components/GlobalResourceSearchBar";
import { LoadingButton } from "../../../components/LoadingButton";
import PayWallDialog from "../../../components/PayWallDialog";
import AuthenticationContext from "../../../contexts/authentication";
import { RolesContext } from "../../../contexts/roles";
import StlInterceptor from '../../../contexts/stl';
import { DigitalContractInputFileType, DigitalContractTemplate, ModificationLog } from "../../../models/contracts";
import { SearchResultIdentification } from "../../../models/search-result";
import { AccountRoles } from "../../../models/user";
import { show } from "../../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../../redux/hooks";
import ContractsService from "../../../services/contracts";
import authentication from "../../../store/authentication";
import { SecurityTierLevels } from "../../../store/stl";
import ErrorWrapper from "../../../utils/ErrorWrapper";
import RenewAccessDialog from "../../authentication/RenewAccessDialog";
import TemplateHistoryDialog from "../../../components/TemplateHistoryDialog";

enum IdentificationTypes {
    ORGANIZATION_USER = 'ORGANIZATION_USER',
    ORGANIZATION_TEAM = 'ORGANIZATION_TEAM',
    IDENTIFIED_BY_EMAIL = 'IDENTIFIED_BY_EMAIL'
}

interface ContractsProps extends React.ComponentProps<any> {
    currentTemplateCount: Number
    maxTemplateCount: Number
}

interface TemplateTableData {
    icon: JSX.Element,
    name: string,
    version: string,
    creationDate: string,
    option: JSX.Element,
    modificationLog: ModificationLog | null,
}


type Order = 'asc' | 'desc';


const Contracts = (props: ContractsProps): JSX.Element => {

    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Envio de contratos"
    }, []);

    //Context props
    const auth = useContext(AuthenticationContext);
    const userRoles = useContext(RolesContext);
    const notification = useAppDispatch();
    const stl = useContext(StlInterceptor);
    const navigate = useNavigate();

    // helpers
    const UserCanCreateTemplates = userRoles.assertIsOrganizationOrAdministratorOr(auth, AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER);

    //Flag that indicated if auth user has required role
    const hasRole = userRoles.assertIsOrganizationOrAdministratorOr(auth, AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER)

    //Renew access dialog 
    const [renewSessionDialog, setRenewSessionDialog] = useState(false);

    //States
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [fetchMore, setFetchMore] = useState(true);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingTemplateFile, setLoadingTemplateFile] = useState(true);
    const [templates, setTemplates] = useState<DigitalContractTemplate[]>([]);
    const [recentIssuedtemplates, setRecentIssuedtemplates] = useState<DigitalContractTemplate[]>([]);
    const [authUserTemplates, setAuthUserTemplates] = useState<DigitalContractTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<DigitalContractTemplate | null>(null)
    const [templateTableScrollTop, setTemplateTableScrollTop] = useState(0)
    const [isTemplatesFiltered, setIsTemplatesFiltered] = useState(false)
    const [filteredTemplates, setFilteredTemplates] = useState<DigitalContractTemplate[]>([]);
    const [scrollChange, setScrollChange] = useState(false)
    const [buttonAnchorEl, setButtonAnchorEl] = useState<null | HTMLElement>(null)
    const [openSendContractMenu, setOpenSendContractMenu] = useState(false)

    //Dialogs states
    const [showPreviewDialog, setShowPreviewDialog] = useState(false);
    const [showIssuerDialog, setShowIssuerDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [openPayWallDialog, setOpenPayWallDialog] = useState(false);
    const [showHistoryDialog, setShowHistoryDialog] = useState(false);

    const [isAWordTemplate, setIsAWordTemplate] = useState(false);
    const [templateDownloadLink, setTemplateDownloadLink] = useState("");

    //Refs
    const templateTableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectedTemplate?.templateInputFile && 
            selectedTemplate?.templateInputFile.type === DigitalContractInputFileType.WORD_1_0) {
            setIsAWordTemplate(true);
        } else {
            setIsAWordTemplate(false)
        }
        fetchTemplateDownloadLink();
    }, [selectedTemplate])

    const fetchTemplateDownloadLink = async () => {
        if (selectedTemplate) {
            const url = (await ContractsService.createDigitalContractTemplateDownloadTemplateFileDownloadUri(selectedTemplate.id as string)).uri
            const encodedUrl = encodeURIComponent(url)
            setTemplateDownloadLink(encodedUrl);
        }
    }
    //Render components
    const DisplayTemplateCard = (props: any): JSX.Element => {
        const temp = props.temp as DigitalContractTemplate;

        return (
            <Box key={temp.id} sx={templateCard}>   
                <Box sx={{ bgcolor: 'action.disabledBackground', px: 2, mb: 3 }}>
                    <Typography variant='caption'>Version <strong>{temp.version}</strong></Typography>
                </Box>
                <Box textAlign='center' mb={2}> <Description sx={{ color: 'secondary.main', fontSize: 40 }} /></Box>
                <Divider sx={{ mx: 1 }} />
                <Box display='flex' alignItems='center' justifyContent='space-between' sx={{ px: 1.5 }}>
                    <Box>
                        <Typography my={1}>{temp.name}</Typography>
                        <Typography variant='caption'>Criado em: {normalize(temp.creationDate)}</Typography><br />
                        {
                            temp.lastModificationLog
                                ?
                                <Tooltip title={`Modificado por: ${temp.lastModificationLog.name}`}>
                                    <Typography variant="caption" >Última modificação em: {normalize(temp.lastModificationLog.date)}</Typography>
                                </Tooltip>
                                :
                                <></>
                        }
                    </Box>
                    <Box >
                        <DisplayMenu temp={temp} />
                    </Box>
                </Box>
            </Box>
        )
    }
    const DisplayMenu = (props: any): JSX.Element => {

        const temp = props.temp as DigitalContractTemplate

        //State - anchor menu
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
        const handleClose = () => setAnchorEl(null);

        const iconStyle = { color: 'secondary.main', fontSize: 20, mr: 2 }
        return (
            <Box >
                <IconButton color='secondary' onClick={handleMenuClick} sx={{ p: 0 }}>
                    <MoreVert sx={{ color: 'secondary.main', fontSize: 30 }} />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                >
                    {temp.templateInputFile === null ?
                        <Tooltip title='Este modelo não possui um arquivo de mala-direta vinculado à ele' placement='top'>
                            <span><MenuItem color='secondary' disabled={!temp.templateInputFile}>
                                <OpenInNew sx={iconStyle} /> Ver
                            </MenuItem></span>
                        </Tooltip>
                        :
                        <MenuItem color='secondary' onClick={() => { setSelectedTemplate(temp); setShowPreviewDialog(true) }}>
                            <OpenInNew sx={{ color: 'secondary.main', fontSize: 20, mr: 2 }} /> Ver
                        </MenuItem>
                    }
                    <MenuItem color='secondary' onClick={() => validateIssuer(temp)}>
                        <Send sx={iconStyle} /> Emitir
                    </MenuItem>
                    {!hasRole ?
                        <Tooltip title='Permissão de usuário insuficiente' placement='top'>
                            <span>
                                <MenuItem color='secondary' disabled={!hasRole}>
                                    <Edit sx={iconStyle} /> Editar
                                </MenuItem>
                                <MenuItem color='secondary' disabled={!hasRole}>
                                    <Delete sx={iconStyle} /> Deletar
                                </MenuItem>
                            </span>
                        </Tooltip>
                        :
                        <Box>
                            <MenuItem color='secondary' onClick={() => { setSelectedTemplate(temp); navigate(`/templates/edit/${temp?.id}`)}}>
                                <Edit sx={{ color: 'secondary.main', fontSize: 20, mr: 2 }} /> Editar
                            </MenuItem>
                            <MenuItem color='secondary' onClick={() => { setSelectedTemplate(temp); setShowDeleteConfirm(true) }}>
                                <Delete sx={{ color: 'secondary.main', fontSize: 20, mr: 2 }} /> Deletar
                            </MenuItem>
                        </Box>
                    }
                    <MenuItem color="secondary" onClick={() => { setSelectedTemplate(temp); setShowHistoryDialog(true); }} >
                        <History sx={{ color: 'secondary.main', fontSize: 20, mr: 2 }} /> Histórico
                    </MenuItem>
                </Menu >
            </Box>
        )
    }

    const TemplateTable = (): JSX.Element => {
        const [sortBy, setSortBy] = useState('name');
        const [sortOrder, setSortOrder] = useState<"asc" | "desc">('asc');

        const handleSortChange = (property: string) => {
            const isAsc = sortBy === property && sortOrder === 'asc';
            setSortBy(property);
            setSortOrder(isAsc ? 'desc' : 'asc');
        };

        // Normalize the templates to be used in the table
        const templateTableData: TemplateTableData[] = filteredTemplates.map(template => {
            return {
                icon: <Description sx={{ color: 'secondary.main' }} />,
                name: template.name,
                version: `Version ${template.version}`,
                creationDate: normalize(template.creationDate),
                option: <DisplayMenu temp={template} />,
                modificationLog: template.lastModificationLog ? template.lastModificationLog : null
            }
        })
        // This function sorts an array based on a given sort order and property to sort by.
        const sortedData = templateTableData.sort((a, b) => {
            // Determine if its ascending or descending
            const isAsc = sortOrder === 'asc';
            //Sort based on the desired property
            if (sortBy === 'name') {
                return isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            } else if (sortBy === 'creationDate') {
                // The object x.creationDate is a string, so its necessary to transform in a Date to compare
                const dateA = new Date(
                    parseInt(a.creationDate.substr(6, 4)), // Year
                    parseInt(a.creationDate.substr(3, 2)) - 1, // month (starts in 0)
                    parseInt(a.creationDate.substr(0, 2)) // day
                );

                const dateB = new Date(
                    parseInt(b.creationDate.substr(6, 4)),
                    parseInt(b.creationDate.substr(3, 2)) - 1,
                    parseInt(b.creationDate.substr(0, 2))
                );

                const comparison = dateA.getTime() - dateB.getTime();
                return isAsc ? comparison : -comparison;
            }
            return 0;
        });

        // This function checks if the user has scroll to the end of the table and store the position of the scroll
        function handleScroll() {
            // Get the current reference element
            const container = templateTableRef.current;
            if (container) {
                const isEndReached = container.scrollTop + container.clientHeight === container.scrollHeight;
                // Check if the user has reached the end of the table scroll and if there is more templates to fetch
                if (isEndReached && fetchMore) {
                    setPage(page + 1)
                    setTemplateTableScrollTop(container.scrollTop)
                }
            }
        }

        return (
            <>
                <TableContainer ref={templateTableRef} sx={{ maxHeight: "350px", overflow: "auto" }} onScroll={handleScroll} >
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>
                                    <TableSortLabel
                                        active={sortBy === 'name'}
                                        direction={sortOrder}
                                        onClick={() => handleSortChange('name')}
                                    >
                                        Nome
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Versão</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortBy === 'creationDate'}
                                        direction={sortOrder}
                                        onClick={() => handleSortChange('creationDate')}
                                    >
                                        Data de Criação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    Última modificação em:
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell>{row.icon}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.version}</TableCell>
                                    <TableCell>{row.creationDate}</TableCell>
                                    <TableCell>
                                        {
                                            row.modificationLog
                                                ?
                                                <Tooltip title={`Modificado por: ${row.modificationLog.name}`}>
                                                    <Typography>{normalize(row.modificationLog.date)}</Typography>
                                                </Tooltip>
                                                :
                                                "-"
                                        }
                                    </TableCell>
                                    <TableCell>{row.option}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!loading ? null :
                    <Box mt={2} sx={{ display: "center", alignItems: "center", justifyContent: "center" }} >
                        <CircularProgress />
                    </Box>
                }
            </>
        );
    }

    //Delete a template of digital contract
    const deleteTemplate = async () => {
        if (!selectedTemplate) return;

        try {
            //Update states
            setRenewSessionDialog(false);
            setLoadingDelete(true)

            //Send delete request
            const deleted = await ContractsService.deleteTemplate(selectedTemplate.id);

            

            //Update list of templates
            setTemplates(templates.filter(t => t.id != deleted.id))
            setFilteredTemplates(filteredTemplates.filter(t => t.id != deleted.id))
            setRecentIssuedtemplates(recentIssuedtemplates.filter(r => r.id != deleted.id));

            //Notify user
            setShowDeleteConfirm(false)
            notification(show({
                type: 'success',
                message: `Modelo de template deletado`
            }))
        }
        catch (e) {
            notification(show({
                type: 'error',
                message: `Ocorreu um erro ao deletar este modelo de contrato: ${new ErrorWrapper(e).message}`
            }))
        }
        finally { setLoadingDelete(false) }
    }

    //Fetch templates when page value changes 
    useEffect(() => { fetchTemplates() }, [page])
    const fetchTemplates = async () => {
        setLoading(true);
        try {
            const resp = await ContractsService.fetchTemplates(page)
            if (resp.length === 0) setFetchMore(false)
            filterTemplates(resp)
        }
        catch (e) {
            notification(show({
                type: 'error',
                message: `Ocorreu um erro ao buscar seus modelos de contrato: ${new ErrorWrapper(e).message}`
            }))
        }
        finally {
            setLoading(false)
            //Check if it's not the first request
            if (page > 1) {
                /**
                 * This state function is to call the useEffect to change the scroll position of the table
                 * after the element is re-rendered in the screen
                 */
                setScrollChange(!scrollChange)
            }
        }

    }
    //Change the position of the scroll in the template list to the previous end position
    useEffect(() => {
        const templateTable = templateTableRef.current
        if (templateTable) {
            templateTable.scrollTo(0, templateTableScrollTop)
        }
    }, [scrollChange])

    //Get the recent issued templates in the local storage
    useEffect(() => {
        const localRecentIssuedTemplates = localStorage.getItem("recentIssuedTemplates")
        if (localRecentIssuedTemplates) {
            setRecentIssuedtemplates(JSON.parse(localRecentIssuedTemplates))
        }
    }, [])

    // Observe recentIssuedTemplates for:
    // update the items on local storage
    useEffect(() => {
        localStorage.setItem("recentIssuedTemplates", JSON.stringify(recentIssuedtemplates));
    }, [recentIssuedtemplates]);

    //Filter templates 
    const filterTemplates = (temps: DigitalContractTemplate[]) => {
        if (!temps || temps.length === 0) return;

        //Get auth user teams
        const myTeams = authentication.getAuthUserTeams();

        //Loop throught each organization template 
        temps.forEach(t => {
            //Get template issuers
            const issuers = t.contractIssuersSpecifications
            if (issuers) {
                //Loop trought each template issuer
                issuers.forEach((i: any) => {
                    //Validate if auth user if one of the template issuers and push it into array of auth user templates
                    if (i.identificationType === IdentificationTypes.ORGANIZATION_TEAM && myTeams.includes(i.identification.teamId))
                        authUserTemplates.push(t);
                    if (i.identificationType === IdentificationTypes.ORGANIZATION_USER && auth.user().account.uuid === i.identification.uuid)
                        authUserTemplates.push(t);
                })
            }
        })

        //Filter templates to remove auth user templates
        for (const t in temps) {
            if (authUserTemplates.indexOf(temps[t]) == -1)
                //Get all templates from the pagination to keep them in memory
                templates.push(temps[t])
            filteredTemplates.push(temps[t])
        }
    }

    //Validate if user is allowed to issue selected template and if valid add template to recentIssued
    const validateIssuer = (template: DigitalContractTemplate) => {
        ContractsService.validateIssuer(template.id)
            .then(() => {
                const localRecentIssuedTemplates = localStorage.getItem("recentIssuedTemplates")
                let recentIssuedTemplatesArray: DigitalContractTemplate[]

                if (localRecentIssuedTemplates) {
                    recentIssuedTemplatesArray = JSON.parse(localRecentIssuedTemplates)
                } else {
                    recentIssuedTemplatesArray = []
                }

                const recentIssuedTemplateIndex = recentIssuedTemplatesArray.findIndex(temp => temp.id === template.id)

                if (recentIssuedTemplateIndex === -1) {
                    recentIssuedTemplatesArray.unshift(template)
                } else {
                    recentIssuedTemplatesArray.splice(recentIssuedTemplateIndex, 1)
                    recentIssuedTemplatesArray.unshift(template)
                }

                if (recentIssuedTemplatesArray.length > 5) {
                    recentIssuedTemplatesArray.pop()
                }

                localStorage.setItem("recentIssuedTemplates", JSON.stringify(recentIssuedTemplatesArray))

                navigate(`/issue/template/${template.id}`)
            })
            .catch(() => setShowIssuerDialog(true))
    }

    //Utils - normalize date values
    const normalize = (milliseconds: string | number | Date) => { return new Date(milliseconds).toLocaleDateString() };
    //Utils - styles
    const mainBox = { mx: {xs:2 , md: 5}, borderRadius: .8, p: {xs: 1 , md:3}, border: '1px solid #DDD' }
    const templateCard = { borderRadius: .8, py: 2, width: 200, m: 1.5, border: '1px solid #DDD' }
    const templateBox = { display: 'flex', mt: 2, maxHeight: '480px', overflow: 'auto' }

    function handleClosePayWallDialog() {
        setOpenPayWallDialog(false);
    }

    function handleOpenPayWallDialog() {
        setOpenPayWallDialog(true)
    }

    //Set searched template to template table
    function triggerSearchResultAction(resource: SearchResultIdentification,
        template: DigitalContractTemplate | undefined): void {
        if (template) {
            setFilteredTemplates([template])
            setIsTemplatesFiltered(true)
        }
    }

    // Clear search filter and reset the template table to previous fetched templates
    function handleClearFilter() {
        setIsTemplatesFiltered(false)
        setFetchMore(true)
        setFilteredTemplates(templates)
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setButtonAnchorEl(event.currentTarget);
        setOpenSendContractMenu(true)
    };
    const handleClose = () => {
        setButtonAnchorEl(null);
        setOpenSendContractMenu(false)
    };

    function handleCloseHistory() {
        setShowHistoryDialog(false);
    }

    const TemplateListHeader: SxProps<Theme> = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        ['@media(max-width:600px)']: {
            flexDirection: "column"
        },
    }

    const TemplateSearch: SxProps<Theme> = {
        display: "flex",

        ['@media(max-width:600px)']: {
            maxWidth: "200px"
        },
    }

    return (
        <Box pt={5}>
            <Box sx={mainBox} >
                {recentIssuedtemplates.length === 0 || loading ? null :
                    <Typography variant="h6" color='primary.main'>Modelos emitidos recentemente</Typography>
                }
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "right",
                        mb: 2
                    }}
                >
                    <Button
                        id="basic-button"
                        aria-controls={openSendContractMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSendContractMenu ? 'true' : undefined}
                        onClick={handleClick}
                        variant="contained"
                    >
                        Novo contrato
                    </Button>
                    <Menu
                        id="basic-menu"
                        open={openSendContractMenu}
                        anchorEl={buttonAnchorEl}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button'
                        }}

                    >
                        <MenuItem onClick={() => navigate("/templates/create")}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography mr={2}>Novo modelo por word</Typography>
                                <Description />
                            </Box>
                        </MenuItem>
                        <MenuItem onClick={() => navigate("/issue/file")}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography mr={2}>Emitir contrato por PDF</Typography>
                                <PictureAsPdf />
                            </Box>
                        </MenuItem>
                    </Menu>
                </Box>
                <Box sx={templateBox}>
                    {
                        (!loading || recentIssuedtemplates.length === 0) ? null :
                            <Box mr={2} mt={2}>
                                <Skeleton variant="rectangular" width={210} height={150} />
                                <Skeleton />
                                <Skeleton width="70%" />
                            </Box>
                    }
                    {
                        recentIssuedtemplates.length === 0 || loading ? null :
                            recentIssuedtemplates.map(temp => <Box><DisplayTemplateCard temp={temp} key={temp.id} /></Box>)
                    }
                </Box>
                <Box mt={5}>
                    <Box sx={TemplateListHeader}>
                        <Typography variant="h6" color="primary.main">Modelos de Contrato</Typography>
                        <Box sx={TemplateSearch} >
                            {isTemplatesFiltered ?
                                <Button
                                    variant="text"
                                    sx={{ fontSize: "0.75rem" }}
                                    onClick={handleClearFilter}
                                >
                                    Limpar filtro
                                </Button> :
                                <></>
                            }
                            <GlobalSearchBar searchLimiter="DIGITAL_CONTRACT_TEMPLATE" onResourceClick={triggerSearchResultAction} />
                        </Box>
                    </Box>
                    <Box mt={2}>
                        {(!loading || page > 1) ?
                            <TemplateTable />
                            :
                            <Skeleton variant="rectangular" height={"350px"} />
                        }
                    </Box>
                </Box>


                {/* USER NOT ALLOWED TO ISSUE DIALOG         */}
                <Dialog open={showIssuerDialog} onClose={() => setShowIssuerDialog(false)}>
                    <DialogTitle sx={{ textAlign: 'center', pt: 3 }} >
                        <Report sx={{ color: 'secondary.main', fontSize: 50 }} />
                    </DialogTitle>
                    <DialogContent>
                        <Typography>Você não é um possível emissor deste contrato.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowIssuerDialog(false)}>Voltar</Button>
                    </DialogActions>
                </Dialog >

                {/* CONFIRM DELETE TEMPLATE DIALOG  */}
                <Dialog open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
                    <DialogTitle>
                        Deletar template
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Tem certeza que deseja deletar o modelo de template: {selectedTemplate?.name}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowDeleteConfirm(false)}>Cancelar</Button>
                        <LoadingButton loading={loadingDelete} variant='contained' className='nh' sx={{ bgcolor: 'error.main' }}
                            onClick={() => {
                                stl.require(auth, SecurityTierLevels.SamePerson)
                                    .then(() => deleteTemplate())
                                    .catch(() => setRenewSessionDialog(true))
                            }}>
                            Deletar
                        </LoadingButton>
                    </DialogActions>
                </Dialog>

                {/* TEMPLATE PREVIEW DIALOG */}
                <Dialog open={showPreviewDialog} fullWidth scroll={'paper'} onClose={() => { setShowPreviewDialog(false); setLoadingTemplateFile(true) }}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                height: "100%",
                                maxWidth: "850px"
                            },
                        }
                    }}>
                    <DialogTitle id="alert-dialog-title">
                        <Typography color='primary.main'><strong>Pré-visualização</strong> de {selectedTemplate?.name}</Typography>
                    </DialogTitle>
                    <DialogContent dividers sx={{ p: 0, overflow: 'hidden' }}>
                        <Box height={500} width='100%'>
                            {loadingTemplateFile ? <Box height={1} alignItems='center' display='flex' justifyContent='center'><CircularProgress /></Box> : null}
                            {
                                (isAWordTemplate && selectedTemplate && selectedTemplate.templateInputFile.type === DigitalContractInputFileType.WORD_1_0)
                                    ?
                                    <iframe
                                        onLoad={() => setLoadingTemplateFile(false)} frameBorder="0"
                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${templateDownloadLink}`}
                                        style={{ height: "92vh", width: "100%", marginTop: "20px" }}
                                    >
                                        This is an embedded <a target='_blank' href='http://office.com'>
                                            Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>
                                            Office Online</a>.
                                    </iframe>
                                    :
                                    <iframe
                                        onLoad={() => setLoadingTemplateFile(false)} frameBorder="0"
                                        src={`${process.env.REACT_APP_REGISTRY_WEBAPP}/templates/${selectedTemplate?.id}/template-file-viwer?authToken=${authentication.getAccountsToken()}`}
                                        style={{ overflow: 'hidden', height: '170%', width: '100%' }}
                                    />
                            }
                        </Box>
                    </DialogContent>
                </Dialog >

                {/* STL LEVEL - RENEW ACCESS DIALOG */}
                <RenewAccessDialog sessionRenewed={() => deleteTemplate()} open={renewSessionDialog} onClose={() => setRenewSessionDialog(false)} />
            </Box >
            <PayWallDialog open={openPayWallDialog} onClose={handleClosePayWallDialog} quotaName="Modelos de contrato" productName="Modelos de contrato" type="limiter" />
            {
                selectedTemplate
                    ?
                    <TemplateHistoryDialog open={showHistoryDialog} onClose={handleCloseHistory} template={selectedTemplate} />
                    :
                    <></>
            }
        </Box >
    )
}

export default Contracts;