import { Alert } from "@mui/material";
import { RequiredInformationInputFieldProps } from "./rifields";
import Address from "./types/Address";
import BRA_CNPJ from "./types/BRA_CNPJ";
import BRA_CPF from "./types/BRA_CPF";
import BRA_CPF_OR_CNPJ from "./types/BRA_CPF_OR_CNPJ";
import Date from "./types/Date";
import Decimal from "./types/Decimal";
import Integer from "./types/Integer";
import SingleLineText from "./types/SingleLineText";
import Time from "./types/Time";
import Timestamp from "./types/Timestamp";
import MultipleSelect from "./types/MultipleSelect";
import SingleSelect from "./types/SingleSelect";
import Email from "./types/Email";

const RequiredInformationComponent = (props: RequiredInformationInputFieldProps): JSX.Element => {
    switch (props.requiredInformation.requiredInformation.requiredInformation.type) {
        case "SINGLE_LINE_TEXT":
            return <SingleLineText {...props} />
        case "INTEGER":
            return <Integer {...props} />
        case "DECIMAL":
            return <Decimal {...props} />
        case "ADDRESS":
            return <Address {...props} />
        case "BRA_CNPJ":
            return <BRA_CNPJ {...props} />
        case "BRA_CPF_OR_CNPJ":
            return <BRA_CPF_OR_CNPJ {...props} />
        case "BRA_CPF":
            return <BRA_CPF {...props} />
        case "DATE":
            return <Date {...props} />
        case "TIME":
            return <Time {...props} />
        case "TIMESTAMP":
            return <Timestamp {...props} />
        case "MULTIPLE_SELECT":
            return <MultipleSelect {...props}/>
        case "SINGLE_SELECT":
            return <SingleSelect {...props}/>
        case "EMAIL":
            return <Email {...props}/>
        default:
            return (
                <Alert sx={{
                    width: "100%",
                    padding: "20px 28px",
                    marginBottom: "14px",
                    borderRadius: "10px",
                    boxSizing: "border-box",
                }}
                    severity="error"
                >
                    Não foi possível encontrar o tipo da informação requerida. Favor entrar em contato com o suporte.
                </Alert>
            )
    }
}
export default RequiredInformationComponent;