import { AttachmentOutlined, AutorenewOutlined, Block, HistoryEduOutlined, ManageAccountsOutlined, OpenInNew as OpenInNewIcon, TaskOutlined } from "@mui/icons-material";
import { Alert, Box, Button, Divider, IconButton, SwipeableDrawer, SxProps, Theme, Typography } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { DigitalContractNotificationModel, DigitalContractUserInformationUpdatedNotification, NotificationModel, parseNotificationTypeToSearchResultNotification } from "../models/notifications";
import { SearchResultIdentification } from "../models/search-result";
import NotificationsService from "../services/notification";
import LastSeenNotificationStore from "../store/lastSeenNotification";

export function NotificationIcon(notification: NotificationModel): JSX.Element {
  switch (notification.type) {
    case 'DIGITAL_CONTRACT_ATTACHMENT_UPDATED':
      return <AttachmentOutlined />
    case 'DIGITAL_CONTRACT_USER_INFORMATION_UPDATED':
      return <ManageAccountsOutlined />
    case 'EDITABLE_DIGITAL_CONTRACT_STATE_CHANGED':
      return <AutorenewOutlined />
    case 'DIGITAL_CONTRACT_ISSUED':
      return <TaskOutlined />
    case 'CONTRACT_PARTY_SIGNED_CONTRACT':
      return <HistoryEduOutlined />
    case 'CONTRACT_PARTY_REVOKED_ISSUED_CONTRACT':
    case 'CONTRACT_PARTY_REVOKED_EDITABLE_CONTRACT':
      return <Block />
    default:
      return <TaskOutlined />
  }
}

export function NotificationDescription(notification: NotificationModel): JSX.Element {
  if ((notification as DigitalContractNotificationModel).contractId) {
    let digitalContractNotification = notification as DigitalContractNotificationModel;
    //Verifiy the type of the notification
    switch (notification.type) {

      //Editable Digital Contract Notifications
      case 'DIGITAL_CONTRACT_ATTACHMENT_UPDATED':
        return (
          <>
            <Typography variant="body2" sx={descriptionElementStyle}>Um anexo foi adicionado ao contrato <Typography sx={emphasisElementStyle}>{digitalContractNotification.contractName}</Typography>.</Typography>
          </>
        )
      case 'DIGITAL_CONTRACT_USER_INFORMATION_UPDATED':
        let userInformationUpdatedOnContractNotification = digitalContractNotification as DigitalContractUserInformationUpdatedNotification;
        let contractName = userInformationUpdatedOnContractNotification.contractName
        let contractParty = userInformationUpdatedOnContractNotification.requester.role;
        return (
          <>
            <Typography variant="body2" sx={descriptionElementStyle}> As informações do contrato <Typography sx={emphasisElementStyle}>{contractName}</Typography> foram adicionadas pelo participante <Typography sx={emphasisElementStyle}>{contractParty}.</Typography></Typography>
          </>
        )
      case 'EDITABLE_DIGITAL_CONTRACT_STATE_CHANGED':
        return (
          <>
            <Typography variant="body2" sx={descriptionElementStyle}>O contrato <Typography sx={emphasisElementStyle}>{digitalContractNotification.contractName}</Typography> teve o seu estado alterado.</Typography>
          </>
        )

      //Issued Digital Contract Notification
      case 'DIGITAL_CONTRACT_ISSUED':
        return (
          <>
            <Typography variant="body2" sx={descriptionElementStyle}>O contrato <Typography sx={emphasisElementStyle}>{digitalContractNotification.contractName}</Typography> foi enviado para assinatura.</Typography>
          </>
        )
      case 'CONTRACT_PARTY_SIGNED_CONTRACT':
        return (
          <>
            <Typography variant="body2" sx={descriptionElementStyle}>Um participante assinou o contrato <Typography sx={emphasisElementStyle}>{digitalContractNotification.contractName}</Typography>.</Typography>
          </>
        )
      case 'CONTRACT_PARTY_REVOKED_ISSUED_CONTRACT':
      case 'CONTRACT_PARTY_REVOKED_EDITABLE_CONTRACT':
        return (
          <>
            <Typography variant="body2" sx={descriptionElementStyle}>Um participante revogou o contrato <Typography sx={emphasisElementStyle}>{digitalContractNotification.contractName}</Typography>.</Typography>
          </>
        )
      default: return <></>
    }
  }
  else {
    return <></>
  }
}

export function NotificationType(notification: NotificationModel): JSX.Element {
  if ((notification as DigitalContractNotificationModel).contractId) {

    //Verifiy the type of the notification
    switch (notification.type) {

      //Editable Digital Contract Notifications
      case 'DIGITAL_CONTRACT_ATTACHMENT_UPDATED':
        return (
          <>
            <Typography variant="body2" sx={typeElementStyle}>Anexo adicionado ao contrato</Typography>
          </>
        )
      case 'DIGITAL_CONTRACT_USER_INFORMATION_UPDATED':
        return (
          <>
            <Typography variant="body2" sx={typeElementStyle}>Informação do usuário Atualizada </Typography>
          </>
        )
      case 'EDITABLE_DIGITAL_CONTRACT_STATE_CHANGED':
        return (
          <>
            <Typography variant="body2" sx={typeElementStyle}>O estado do contrato editável foi alterado</Typography>
          </>
        )

      //Issued Digital Contract Notification
      case 'DIGITAL_CONTRACT_ISSUED':
        return (
          <>
            <Typography variant="body2" sx={typeElementStyle}>O contrato foi enviado para assinatura</Typography>
          </>
        )
      case 'CONTRACT_PARTY_SIGNED_CONTRACT':
        return (
          <>
            <Typography variant="body2" sx={typeElementStyle}>O participante assinou o contrato.</Typography>
          </>
        )
      case 'CONTRACT_PARTY_REVOKED_ISSUED_CONTRACT':
      case 'CONTRACT_PARTY_REVOKED_EDITABLE_CONTRACT':
        return (
          <>
            <Typography variant="body2" sx={typeElementStyle}>O participante revogou o contrato.</Typography>
          </>
        )
      default: return <></>
    }
  }
  else {
    return <></>
  }
}

// Style used on notification descriptionElement
const descriptionElementStyle: SxProps<Theme> = {
  fontWeight: "600",
  fontSize: "12px",
  textAlign: "left",
  wordBreak: "break-word",
  whiteSpace: "normal",
  color: "#8C8C8C",
  margin: "0px",
  padding: "0px",
}

// Style used on notification typeElement
const typeElementStyle: SxProps<Theme> = {
  display: "inline-block",
  fontWeight: "600",
  fontSize: "14px",
  textAlign: "left",
  wordBreak: "break-word",
  whiteSpace: "normal",
  color: "#5C5C5C",
  margin: "0px",
  padding: "0px 10px",
}

// Style used on notification to emphasis informations in notification
const emphasisElementStyle: SxProps<Theme> = {
  display: "inline-block",
  color: (theme) => theme.palette.primary.main,
  fontWeight: "600",
  fontSize: "12px",
}

interface SearchResultActionCallback {
  (resource: SearchResultIdentification): void,
}

interface NotificationDrawerProps extends React.ComponentProps<any> {
  open: boolean
  onClose: React.ReactEventHandler<{}>
  onOpen: React.ReactEventHandler<{}>
  onSearchResultAction?: SearchResultActionCallback,
}

const unseenNotificationStyle: SxProps<Theme> = {
  margin: "10px",
  padding: "20px 10px",
  display: "block",
  minWidth: "200px",
  maxWidth: "300px",
  height: "150px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.primary.main,
  backgroundColor: "#fbeeec",
}

const seenNotificationStyle: SxProps<Theme> = {
  margin: "10px",
  padding: "20px 10px",
  display: "block",
  minWidth: "200px",
  maxWidth: "300px",
  height: "150px",
  border: "1px solid",
  borderColor: "#8C8C8C",
}

const NotificationsDrawer = (props: NotificationDrawerProps): JSX.Element => {

  // State - Drawer Notifications
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);
  const [page, setPage] = useState(1)
  const [hasMoreContentToFetch, setHasMoreContentToFetch] = useState(true);

  //fetch notifications on new page when click on load more button
  useEffect(() => {
    NotificationsService.fetchNotifications(page)
      .then(response => {
        const lastOpenNotificationsTimeStamp = LastSeenNotificationStore.get();

        // prevent the client to render the fetch more button
        if (response.length == 0) {
          setHasMoreContentToFetch(false);
          return;
        }

        // programatically, set if the notification was seen using the last close notification timestamp
        if (lastOpenNotificationsTimeStamp != null) {
          response.forEach(notification => {
            notification.wasSeen = notification.creationDate < lastOpenNotificationsTimeStamp.getTime();
          });
        }

        const auxNotificationArray = [...notifications, ...response]

        auxNotificationArray.sort((a, b) => b.creationDate - a.creationDate)

        setNotifications(auxNotificationArray)
      }
      ).catch(error => {
        console.error(error);
      })
  }, [page]);

  function NotificationCard(notification: NotificationModel): JSX.Element {

    // if an search result callback is given, try to identify the search result
    // from the notification to trigger it
    let notificationSearchResult: SearchResultIdentification | undefined = undefined;
    if (props.onSearchResultAction) {
      notificationSearchResult = parseNotificationTypeToSearchResultNotification(notification);
      if (!notificationSearchResult) {
        const error = new Error("Could not identify action to trigger for notification");
        console.error(error, notification);
        throw error;
      }
    }
    return (
      <Box sx={(notification.wasSeen) ? seenNotificationStyle : unseenNotificationStyle}>
        <Box sx={{
          display: "block",
          alignItems: "center"
        }}>
          {/*Notification Structure*/}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}>
            <Box sx={{
              display: "inline-block"
            }}>
              {NotificationIcon(notification)}
            </Box>
            <Typography
              sx={{
                margin: "auto",
                display: "inline-block"
              }}>
              {NotificationType(notification)}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "block",
              overflow: "hidden",
              margin: "15px 0px ",
            }}>
            {NotificationDescription(notification)}
          </Box>
        </Box>

        <Box sx={{ justifyContent: "space-between", alignItems: { xs: 'start' }, position: "relative" }}>
          <Typography variant="body2"
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              textAlign: "left",
              whiteSpace: "normal",
              color: "#8C8C8C",
              margin: "0px",
              padding: "0px",
              display: "inline-block"
            }}>
            {new Date(notification.creationDate).toLocaleString()}
          </Typography>
          <IconButton
            sx={{
              margin: "10px 0px",
              padding: "0px 20px",
              display: "inline-block",
              position: "absolute", right: "0px"
            }} aria-label="openinnewicon"
            onClick={(e) => {
              if (props.onSearchResultAction && notificationSearchResult) {
                props.onClose(e);
                props.onSearchResultAction(notificationSearchResult);
              }
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        </Box>
      </Box >
    )
  }

  function handleDrawerOnOpen(e: SyntheticEvent<{}, Event>): void {
    setPage(1);
    setNotifications([]);
    props.onOpen(e);
  }

  return (
    <Box
      sx={{ width: 300, margin: "10px", backdropFilter: "blur(3px)", backgroundColor: "#fff" }}
    >
      <SwipeableDrawer
        anchor="right"
        role="presentation"
        open={props.open}
        onClose={props.onClose}
        onOpen={handleDrawerOnOpen}
      >
        <Button sx={{ margin: "auto", weight: 600, mt: 1, mb: 1 }} endIcon={<OpenInNewIcon />} component={NavLink} to={"/notification-center"}>Central de Notificações</Button>
        {
          notifications.filter(n => !n.wasSeen).map(notification => (
            NotificationCard(notification)
          ))
        }

        {
          (notifications.filter(n => !n.wasSeen).map(notification => (NotificationCard(notification))).length > 0)
            ?
            <Divider>
              <Typography sx={{ display: "block", margin: "auto", fontSize: "12px", color: (theme) => theme.palette.primary.main, fontWeight: "600" }}>
                Notificações Antigas
              </Typography>
            </Divider>
            :
            <></>
        }

        {
          notifications.filter(n => n.wasSeen === true).map(notification => (
            NotificationCard(notification)
          ))
        }

        {/* Renders the button only if it has more content to fetch */}
        {
          (hasMoreContentToFetch)
            ?
            <Button
              sx={{
                mt: 2, display: "block",
                margin: "auto",
                marginBottom: "10px",
              }}
              variant='contained'
              onClick={() => { setPage(page + 1) }}
            >
              Carregar mais
            </Button>
            :
            <Alert sx={{ margin: "auto", marginBottom: "10px", width: "290px", maxHeight: "150px" }} variant="outlined" severity="info">Não há notificações para mostrar</Alert>
        }
        <Divider />
      </SwipeableDrawer >
    </Box >
  )
}

export default NotificationsDrawer;