import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DigitalContractTemplate, EditableDigitalContract, IssuedDigitalContract } from "../../models/contracts";
import TagsService, { TagsBulk, TagsModel } from "../../services/tags";
import TagsAdder from "../TagsAdder";
import { Close, Sell } from "@mui/icons-material";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../redux/hooks";

interface OnCloseCallback {
    (tagDialogClose: boolean, tags: TagsModel[]): void
}

interface TagsDialogProps {
    open: boolean,
    source: IssuedDigitalContract | EditableDigitalContract | DigitalContractTemplate,
    onClose: OnCloseCallback,
}

enum TagOrigin {
    CONTRACT = "CONTRACT",
    TEMPLATE = "TEMPLATE"
}

const TagsDialog = (props: TagsDialogProps): JSX.Element => {
    const [contractTags, setContractTags] = useState<TagsModel[]>([])
    const [templateTags, setTemplateTags] = useState<TagsModel[]>([])
    const [loading, setLoading] = useState(false);

    //Context props
    const notification = useAppDispatch();

    useEffect(() => {
        // Fetch the Contract Tags
        if (props.source.contractTags) {
            props.source.contractTags.forEach((tag) => {
                if (tag.tagOrigin === TagOrigin.CONTRACT) {
                    setContractTags((prevContractTags) => [...prevContractTags, tag]);
                } else if (tag.tagOrigin === TagOrigin.TEMPLATE) {
                    setTemplateTags((prevTemplateTags) => [...prevTemplateTags, tag]);
                }
            });
        }
    }, []);

    function handleDialogOnCloseEvent() {
        props.onClose(false, [...contractTags, ...templateTags]);
    }

    function handleAddedTag(tag: TagsModel) {
        const isTagDuplicate = contractTags.some((t) => t.tagName === tag.tagName);

        if (!isTagDuplicate) {
            const updatedTags = [...contractTags, tag];
            setContractTags(updatedTags);
        }
    }

    async function submitTags() {
        setLoading(true);

        const normalizedTags: TagsBulk = {
            tags: contractTags.concat(templateTags).map(tag => tag.tagName),
        };

        try {
            await TagsService.updateContractTags(props.source.id, normalizedTags);
            notification(show({ type: 'success', message: "Salvo." }))
        }
        catch (error) {
            const e = new ErrorWrapper(error);
            notification(show({ type: 'error', message: "Não foi possível editar as tags." }))
            setLoading(false);
            return;
        }
        finally {
            setLoading(false);
        }
        handleDialogOnCloseEvent();
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleDialogOnCloseEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title">Editar tags do contrato: <b>{props.source.name}</b></DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexWrap: "wrap", width: "600px", height: "100px", alignItems: "center" }}>
                    {
                        loading
                            ?
                            <CircularProgress />
                            :
                            contractTags
                                ?
                                contractTags.map(t => (
                                    <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "primary.main", m: 1, borderRadius: "36px", flexBasis: "30%", maxWidth: "30%", justifyContent: "end", height: "36px", boxShadow: 3 }}>
                                        <Tooltip title={t.tagName}>
                                            <Typography sx={{ fontSize: "12px", color: "#fff", padding: "6px 16px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{t.tagName}</Typography>
                                        </Tooltip>
                                        <Button onClick={() => {
                                            const updatedTags = contractTags.filter((tag) => t.tagName !== tag.tagName);
                                            setContractTags(updatedTags);
                                        }} startIcon={<Close sx={{ color: "#fff" }} />} />
                                    </Box>))
                                :
                                <></>
                    }
                    <Box sx={{ width: "30%", m: 1 }}>
                        {
                            (contractTags.length >= 5)
                                ?
                                <Tooltip title="Máximo de 5 tags por contrato">
                                    <span>
                                        <Button variant="contained" disabled sx={{ borderRadius: '24px', width: "100%" }} startIcon={<Sell />}>
                                            Adicionar tag
                                        </Button>
                                    </span>
                                </Tooltip>
                                :
                                <TagsAdder onTagAdded={handleAddedTag} />
                        }
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogOnCloseEvent}>Cancelar</Button>
                <Button variant="contained" onClick={submitTags}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TagsDialog;

