import { Box, Divider, IconButton, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material"
import { ExternalDigitalContract } from "../../models/contracts";
import { useState } from "react";
import ExternalContractMenu from "./ExternalContractMenu";

interface ContractWasDeletedCallback {
    (deletedContract: ExternalDigitalContract): void;
}

interface ExternalContractCardProps extends React.ComponentProps<any> {
    source: ExternalDigitalContract,
    showContractTags: boolean,
    onContractDeleted?: ContractWasDeletedCallback;
}

const ExternalContractCard = (props: ExternalContractCardProps): JSX.Element => {

    // Destructure props
    const { source, showContractTags, onContractDeleted } = props

    // Data states
    const [tags, setTags] = useState((source.contractTags) ? source.contractTags : []);

    // Ref states
    const [menuAE, setMenuAE] = useState<HTMLElement | null>(null);

    // Handler functions
    function handleMenuIconClick(event: React.MouseEvent<HTMLButtonElement>): void {
        setMenuAE(event.currentTarget);
    }

    // Styles
    const cardTheme: SxProps<Theme> = {
        border: "1px solid rgb(200, 200, 200)",
        borderRadius: "8px",
        mb: "18px",
        p: "4px 8px 16px 24px",
    }

    // JSX Components
    const TruncatedTextWithTooltip = (): JSX.Element => {
        const { name } = source;
        const maxLength = 77;

        if (name.length > maxLength) {
            const truncatedName = name.substring(0, maxLength) + '...';

            return (
                <Tooltip title={name} arrow>
                    <Typography variant="subtitle2" my={1}>{truncatedName}</Typography>
                </Tooltip>
            );
        }

        return <Typography variant="subtitle2" my={1}>{name}</Typography>;
    };

    return (
        <>
            <Box sx={cardTheme}>
                <TruncatedTextWithTooltip />

                <Divider light />
                {/* line 2 : description and menu */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* descriptions*/}
                    <Box sx={{ width: "100%" }}>
                        <Typography variant="caption"><b>Data de assinatura: </b> {new Date(source.signatureDate).toLocaleString()}</Typography><br />
                        <Typography variant="caption"><b>ID do contrato: </b> {source.id}</Typography><br />
                        {
                            (tags && showContractTags)
                                ?
                                <>
                                    <Divider sx={{ margin: "4px 0px", maxWidth: "100%" }} />
                                    <Box sx={{ display: (source.contractTags ? "flex" : "none"), maxWidth: "100%" }}>
                                        {
                                            // If there is at maximum of 3 tags, show each one...
                                            (tags.length <= 3)
                                                ?
                                                tags.map((t, i) => {
                                                    return (
                                                        <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "primary.main", mr: 1, borderRadius: "12px", justifyContent: "center", width: "33%", height: "18px" }}>
                                                            <Tooltip title={t.tagName}>
                                                                <Typography sx={{ fontSize: "10px", color: "#fff", margin: "0 16px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{t.tagName}</Typography>
                                                            </Tooltip>
                                                        </Box>
                                                    )
                                                })
                                                :
                                                // otherwise, display all of them into an single tag and displaying each tag using tooltip
                                                <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "primary.main", mr: 1, borderRadius: "12px", justifyContent: "center", width: "33%", height: "18px" }}>
                                                    <Tooltip title={tags.map(t => t.tagName).join(", ")}>
                                                        <Typography sx={{ fontSize: "10px", color: "#fff", margin: "0 16px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Ver tags...</Typography>
                                                    </Tooltip>
                                                </Box>
                                        }
                                    </Box>
                                </>
                                :
                                <></>
                        }
                    </Box>
                    <Box>
                        {/* action menu*/}
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleMenuIconClick}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Box>
                <ExternalContractMenu 
                    anchorElement={menuAE}
                    setAnchorElement={setMenuAE}
                    setTags={setTags}
                    source={source}
                    onContractDeleted={onContractDeleted}
                />
            </Box>
        </>
    )
}

export default ExternalContractCard;