import { Box, Container, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = (): JSX.Element => {
    const navigate = useNavigate();

    return (
        <Container>
            <Box sx={{ justifyContent: "center", alignItems: "center", mt: "10%" }}>
                <Box sx={{ display: "grid", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <img style={{ display: "block", margin: "auto", marginBottom: "16px" }} src="../images/cartorizi_err.svg" width={500} />
                    <Typography sx={{ color: "primary.main", fontSize: "2.25rem", fontWeight: 600, mb: 2 }}>Ops... 404! - Página não encontrada</Typography>
                    <Typography sx={{ fontSize: "1rem", mb: 2 }}>Não foi possível encontrar a página que você tentou acessar.</Typography>
                    <Button sx={{ fontSize: "1rem" }} onClick={() => navigate("/", { replace: true })}>Voltar ao início</Button>
                </Box>
            </Box>
        </Container>
    )
}

export default NotFound;