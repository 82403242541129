import { useContext, useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DigitalContractStates, EditableDigitalContract, IssuedDigitalContract } from "../../models/contracts";
import ContractsService from "../../services/contracts" 
import StlInterceptor from "../../contexts/stl";
import AuthenticationContext from "../../contexts/authentication";
import { SecurityTierLevels } from "../../store/stl";
import RenewAccessDialog from "../../views/authentication/RenewAccessDialog";

interface OnCloseCallback {
    (contractWasInactivate : boolean) : void
}

interface SendContractToIssuedStateProps extends React.ComponentProps<any> {
    open : boolean;
    source : IssuedDigitalContract;
    onClose : OnCloseCallback;
}

const InactivateIssuedDigitalContractDialog = (props : SendContractToIssuedStateProps) : JSX.Element => {

    // ctx
    const stl = useContext(StlInterceptor);
    const auth = useContext(AuthenticationContext);

    // states
    const [loading, setLoading] = useState(false);
    const [showRenewAccessDialog, setShowRenewAccessDialog] = useState(false);

    /**
     * Function that handles the inner <Dialog> onClose event. This function will call the onClose callback
     */
    function handleDialogOnCloseEvent() {
        props.onClose(false);
    }

    /**
     * Function to elevate STL to max level and call inactivateContract function
     */
    function checkSTLAndInactivateContract() {

        // check for STL level
        setLoading(true);
        stl.require(auth, SecurityTierLevels.MaxLevel)
        .then(() => {
            inactivateContract();
        })
        .catch(() => {
            setShowRenewAccessDialog(true);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    function inactivateContract() {
        setLoading(true);
        // only its possible to inactivate contracts that are in SIGNED state
        if (props.source.state === "SIGNED") {
            // call the API to inactivate the contract
            ContractsService.inactivateIssuedDigitalContract(props.source.id)
            .then(() => {
                props.onClose(true);
            })
            .finally(() => {
                setLoading(false);
            })
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleDialogOnCloseEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Inativar contrato?
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Deseja <b>inativar</b> o contrato <b>{props.source.name}</b>? Inativar o contrato não garante que o contrato perdeu a validade jurídica, 
                essa função é exclusivamente para a gestão dos contratos.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogOnCloseEvent} disabled={loading}>Cancelar</Button>
                {
                    (!loading) 
                    ?
                    <Button onClick={checkSTLAndInactivateContract} autoFocus color="warning">
                        Inativar
                    </Button>
                    :
                    <CircularProgress size="small" />
                }
                
            </DialogActions>
            <RenewAccessDialog open={showRenewAccessDialog} sessionRenewed={inactivateContract} />
        </Dialog>
    );
}

export default InactivateIssuedDigitalContractDialog;