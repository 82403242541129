import { Alert, AppBar, Avatar, Box, Button, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Skeleton, Tab, Toolbar, Tooltip, Typography } from "@mui/material";
import { AttachMoney, Loop, Mail, Menu as MenuIcon, VerifiedUser } from "@mui/icons-material"
import { DigitalContractBilling, ExternalDigitalContract, IdentifiedByEmailContractParty, IssuedContractDocumentType, PlatformUserContractParty } from "../../models/contracts";
import ExternalContractMenu from "./ExternalContractMenu";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AccountRoles, UserAccount } from "../../models/user";
import OrganizationService from "../../services/organization";
import { CurrencyValue, SupportedCurrencies } from "../../utils/currency";
import ContractsService from "../../services/contracts";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { useAppDispatch } from "../../redux/hooks";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import axios from "axios";
import { LoadingButton } from "../LoadingButton";

interface OnCloseCallback {
    (): void
}

interface ViewContractDialogProps extends React.ComponentProps<any> {
    source: ExternalDigitalContract;
    open: boolean,
    onClose: OnCloseCallback;
}

interface ContractPartyListItemProps extends React.ComponentProps<any> {
    contractParty: IdentifiedByEmailContractParty | PlatformUserContractParty
}

interface BillingDataListViewItemProps extends React.ComponentProps<any> {
    billingData: DigitalContractBilling
}

interface OrganizationMemberMap {
    [uuid: string]: UserAccount
}

enum Tabs {
    GENERAL = "GENERAL",
    PARTY = "PARTY",
    BILLING = "BILLING",
    VIEW = "VIEW"
}

const ViewExternalContractDialog = (props: ViewContractDialogProps): JSX.Element => {

    // Destructure props object
    const { open, source, onClose } = props

    // Contexts
    const notification = useAppDispatch();

    // Boolean states
    const [loadingDocument, setLoadingDocument] = useState(true);
    const [loadingDownloadDocument, setLoadingDownloadDocument] = useState(false)

    // Data states
    const [tags, setTags] = useState((source.contractTags) ? source.contractTags : []);
    const [tabValue, setTabValue] = useState(Tabs.GENERAL);
    const [documentURL, setDocumentURL] = useState<string | null>(null);

    // Ref States
    const [menuAE, setMenuAE] = useState<HTMLElement | null>(null);

    // useEffects

    useEffect(() => {
        fetchDocumentURL();
    }, [open])

    // Functions

    function fetchDocumentURL() {
        if(!open) return;
        setLoadingDocument(true)
        ContractsService.downloadExternalContractDocumentURI(source)
            .then(response => {
                setDocumentURL(response.uri);
            })
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({ type: "error", message: err.message }));
            })
            .finally(() => setLoadingDocument(false))
    }

    function normalizedValidityPeriodType(type: string) {
        switch (type) {
            case 'STARTS_WHEN_SIGNED_BY_ALL_PARTIES':
                return "Inicia quando todas as partes assinarem";
            case 'SET_BY_ISSUER':
                return "Data de assinatura";
            case 'UNDETERMINED':
                return "Indeterminado";
            case 'SET_AUTOMATICALLY_AFTER_START_DATE':
                return "Definido automaticamente após o início do contrato";
            default:
                return type;
        }
    }

    // Handler functions
    function handleMenuIconClick(event: React.MouseEvent<HTMLButtonElement>): void {
        setMenuAE(event.currentTarget);
    }

    // JSX Components

    function ContractPartyListItem(props: ContractPartyListItemProps): JSX.Element {

        // Destructure props object
        const { contractParty } = props

        // Boolean states
        const [loading, setLoading] = useState(false)

        // Data states
        const [organizationMembersMap, setOrganizationMembersMap] = useState<OrganizationMemberMap>({});

        // if the contract is IDENTIFIED_BY_EMAIL
        if (contractParty.contractPartyIdentificationType === "IDENTIFIED_BY_EMAIL") {
            const cp = contractParty as IdentifiedByEmailContractParty;
            return (
                <>
                    <ListItem>
                        <ListItemButton>
                            <ListItemIcon>
                                <Mail />
                            </ListItemIcon>
                            <ListItemText primary="Identificado por e-mail" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Endereço de e-mail utilizado para assinatura" secondary={cp.email} />
                    </ListItem>
                </>
            )
        }
        // if the contract is PLATFORM_USER
        else if (contractParty.contractPartyIdentificationType === "PLATFORM_USER") {
            const cp = contractParty as PlatformUserContractParty;
            let member = organizationMembersMap[cp.uuid];

            // if the member is on the map cache return the true component
            if (member) {
                return (
                    <>
                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon>
                                    <VerifiedUser />
                                </ListItemIcon>
                                <ListItemText primary="Membro da plataforma" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Nome" secondary={member.fullname} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="E-mail" secondary={member.email} />
                        </ListItem>
                    </>
                )
            }

            // otherwise, put an dummy object into the member variable and store it into the map cache
            member = {
                accountRoles: [AccountRoles.USER_AND_TEAMS_MANAGER],
                creationDate: new Date(),
                deletedAt: null,
                email: "...",
                fullname: "...",
                hasHandwrittenSignature: false,
                preferredName: "...",
                uuid: "...",
                contractsRestrictViewModeEnabled: false
            }

            let organizationMembersMapCopy = Object.assign(organizationMembersMap);
            organizationMembersMapCopy[cp.uuid] = member;
            setOrganizationMembersMap(organizationMembersMapCopy);

            // make an async call to the API to fetch the user data into the map cache
            setLoading(true);
            OrganizationService.fetchOrganizationMemberByUuid(cp.uuid)
                .then(member => {

                    organizationMembersMapCopy[cp.uuid] = member;
                    setOrganizationMembersMap(organizationMembersMapCopy);
                })
                .catch(error => {
                    console.error("Could not fetch organization members data from the API", error);
                })
                .finally(() => {
                    setLoading(false);
                });

            // return an CircularProgress to indicate that the data is being fetched
            return <CircularProgress />
        }
        else {
            return <Alert severity="error">Não foi possível identificar a participação deste membro do contrato</Alert>
        }
    }

    /**
     * Render ListItem with billing data
     * @param props 
     * @returns 
     */
    function BillingDataListItem(props: BillingDataListViewItemProps): JSX.Element {

        // Destructure props object
        const { billingData } = props

        switch (billingData.billingPeriodType) {
            case "RECURRING":
                return (
                    <>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Loop />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={billingData.name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Forma de pagamento" secondary="Recorrente" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Responsável pelo pagamento" secondary={billingData.debtorContractParty.role} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Data inicial de cobrança" secondary={new Date(billingData.initialBillingPeriod).toLocaleDateString()} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Vencimento da cobrança" secondary={billingData.billingDueDay} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Valor de cobrança" secondary={billingData.value.regionalString} />
                        </ListItem>
                    </>
                );
            default:
                return (
                    <>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <AttachMoney />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={billingData.name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Forma de pagamento" secondary="Parcelado / à vista" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Responsável pelo pagamento" secondary={billingData.debtorContractParty.role} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Data inicial de cobrança" secondary={new Date(billingData.initialBillingPeriod).toLocaleDateString()} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Valor total de cobrança" secondary={billingData.value.regionalString} />
                        </ListItem>
                        {
                            (billingData.numberOfInstallments > 1)
                                ?
                                <ListItem>
                                    <ListItemText
                                        primary="Parcelas"
                                        secondary={
                                            `${billingData.numberOfInstallments}x de ${new CurrencyValue(billingData.value.value / billingData.numberOfInstallments, billingData.value.currency as SupportedCurrencies).regionalValueString}`
                                        }
                                    />
                                </ListItem>
                                :
                                <ListItem>
                                    <ListItemText
                                        primary="Pagamento será à vista"
                                    />
                                </ListItem>
                        }
                    </>
                );
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color="secondary.light">
                        Visualização de Contrato: {source.name}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleMenuIconClick}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Container>
                <DialogContent>
                    <TabContext value={tabValue}>
                        <TabList
                            variant="scrollable"
                            scrollButtons="auto"
                            onChange={(e, tab) => setTabValue(tab)}
                            aria-label="Navegação dos dados do contrato"
                        >
                            <Tab value={Tabs.GENERAL} label="Geral" />
                            <Tab value={Tabs.PARTY} label="Participantes do contrato" />
                            <Tab value={Tabs.BILLING} label="Financeiro" />
                            <Tab value={Tabs.VIEW} label="Visualizar contrato" />
                        </TabList>

                        <TabPanel value={Tabs.GENERAL} >
                            <Typography variant="h6">Informações do contrato</Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Nome do contrato" secondary={source.name} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Data de assinatura" secondary={new Date(source.signatureDate).toLocaleString()} />
                                </ListItem>
                            </List>
                            <Divider />
                            <Typography variant="h6">Vigência do contrato</Typography>
                            <List>
                                <ListItem>
                                    <Typography>Início</Typography>
                                </ListItem>
                                <ListItem>
                                    {
                                        (source.validityPeriod.startDate && source.validityPeriod.startDate)
                                            ?
                                            <>
                                                <ListItemText primary="Data de início" secondary={new Date(source.validityPeriod.startDate).toLocaleString()} />
                                            </>
                                            :
                                            <></>
                                    }
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Tipo de vigência: " secondary={normalizedValidityPeriodType(source.validityPeriod.startDatePolicyType)} />
                                </ListItem>
                                <ListItem>
                                    <Divider sx={{ width: "100%" }} />
                                </ListItem>
                                <ListItem>
                                    <Typography>Término</Typography>
                                </ListItem>
                                <ListItem>
                                    {
                                        (source.validityPeriod.endDate && source.validityPeriod.endDate)
                                            ?
                                            <>
                                                <ListItemText primary="Data de término" secondary={new Date(source.validityPeriod.endDate).toLocaleString()} />
                                            </>
                                            :
                                            <></>
                                    }
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Tipo de vigência: " secondary={normalizedValidityPeriodType(source.validityPeriod.endDatePolicyType)} />
                                </ListItem>
                            </List>
                            <Divider />
                            <Typography variant="h6">Identificações do Cartorizi</Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary="ID" secondary={source.id} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="SKID" secondary={source.shardKey} />
                                </ListItem>
                            </List>
                            <Divider />
                            <Typography variant="h6" sx={{ mb: 2 }}>Tags</Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                {
                                    (source.contractTags)
                                        ?
                                        source.contractTags.map(tag => {
                                            return (
                                                <>
                                                    <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "primary.main", m: 1, borderRadius: "24px", justifyContent: "center", minWidth: "18%", height: "36px", maxWidth: "18%" }}>
                                                        <Tooltip title={tag.tagName}>
                                                            <Typography sx={{ fontSize: "12px", color: "#fff", margin: "0 16px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{tag.tagName}</Typography>
                                                        </Tooltip>
                                                    </Box>
                                                </>
                                            )
                                        })
                                        :
                                        <></>
                                }
                            </Box>
                        </TabPanel>
                        <TabPanel value={Tabs.PARTY}>
                            {
                                source.contractParties.length > 0
                                    ?
                                    source.contractParties.map(cp => (
                                        <>

                                            <Typography variant="h6">{cp.role}</Typography>
                                            <ContractPartyListItem contractParty={cp} />
                                            <Divider />
                                        </>
                                    ))
                                    :
                                    <Alert severity="info">Este contrato não possui participantes cadastrados</Alert>
                            }
                        </TabPanel>
                        <TabPanel value={Tabs.BILLING}>
                            {
                                (source.billingData && source.billingData.length > 0)
                                    ?
                                    <List>
                                        {
                                            source.billingData.map(b => (
                                                <>
                                                    <BillingDataListItem billingData={b} />
                                                    <Divider sx={{ mb: '16px', mt: '16px' }} />
                                                </>
                                            ))
                                        }
                                    </List>
                                    :
                                    <Alert severity="info">Esse contrato não possui dados financeiros automatizados</Alert>
                            }
                        </TabPanel>
                        <TabPanel value={Tabs.VIEW}>
                            {
                                (!loadingDocument && documentURL)
                                    ?
                                    <>
                                        <LoadingButton
                                            variant="contained"
                                            sx={{mb: 3}}
                                            loading={loadingDownloadDocument}
                                            onClick={() => {
                                                setLoadingDownloadDocument(true)
                                                axios.get(documentURL, {
                                                    responseType: "blob"
                                                })
                                                    .then(downloadedFile => {
                                                        const filename = source.documentType === IssuedContractDocumentType.WORD
                                                            ? `${source.id}.docx` : `${source.id}.pdf`

                                                        // create file link in browser's memory
                                                        const href = URL.createObjectURL(downloadedFile.data);

                                                        // create "a" HTML element with href to file & click
                                                        const link = document.createElement('a');
                                                        link.href = href;
                                                        link.setAttribute('download', filename); //or any other extension
                                                        document.body.appendChild(link);
                                                        link.click();

                                                        // clean up "a" element & remove ObjectURL
                                                        document.body.removeChild(link);
                                                        URL.revokeObjectURL(href);
                                                    })
                                                    .finally(() => {
                                                        setLoadingDownloadDocument(false)
                                                    })
                                            }}
                                        >
                                            Baixar contrato externo
                                        </LoadingButton>
                                        {
                                            source.documentType === IssuedContractDocumentType.WORD
                                                ?
                                                <iframe
                                                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(documentURL)}`}
                                                    style={{ height: "92vh", width: "100%", marginTop: "20px" }}
                                                />
                                                :
                                                <embed
                                                    src={`${documentURL}#toolbar=0&navpanes=0&scrollbar=0`}
                                                    type="application/pdf"
                                                    width="100%"
                                                    height="875"
                                                />
                                        }
                                    </>
                                    :
                                    <Skeleton width={"100%"} height={"875px"} />
                            }
                        </TabPanel>
                    </TabContext>
                </DialogContent>
            </Container>

            <ExternalContractMenu anchorElement={menuAE} setAnchorElement={setMenuAE} source={source} setTags={setTags} />

        </Dialog>
    )
}

export default ViewExternalContractDialog;