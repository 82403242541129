import { CheckCircle, Close, Description, DocumentScanner, Person, Storage, ViewModule } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, CircularProgress, Container, LinearProgress, SxProps, Tab, Theme, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import OrganizationContractsQuotaService from "../services/organization-quota";
import { ContractsQuota } from "../models/organizations";
import ProgressBar from "../components/ProgressBar";
import { CurrentContext } from "../appctx/webappContext";

interface QuotaTabsInterface {
    label: string,
    icon: JSX.Element,
}

enum TabValues {STORAGE = "Armazenamento", USER = "Usuário", CONTRACT = "Contratos", TEMPLATE = "Modelos", MODULES = "Modulos"}

const QuotaInfo = ():JSX.Element => {

    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Cotas da Organização"
    }, []);

    const tabList: QuotaTabsInterface[] = [
        {
            label: TabValues.STORAGE,
            icon: <Storage />
        },
        {
            label: TabValues.USER,
            icon: <Person />
        },
        {
            label: TabValues.CONTRACT,
            icon: <Description />
        }, 
        {
            label: TabValues.TEMPLATE,
            icon: <DocumentScanner />
        },
        {
            label: TabValues.MODULES,
            icon: <ViewModule />
        }
    ]

    const [tabValue, setTabValue] = useState(tabList[0].label)

    const [organizationQuota, setOrganizationQuota] = useState<ContractsQuota | undefined>(undefined)

    const [storageConsumptionPercentage, setStorageConsumptionPercentage] = useState("0")
    const [storageConsumptionString, setStorageConsumptionString] = useState("")
    const [maxStorageString, setMaxStorageString] = useState("")

    const [userConsumptionPercentage, setUserConsumptionPercetage] = useState("0")
    const [userConsumption, setUserConsumption] = useState(0)
    const [maxUser, setMaxUser] = useState(0)

    const [issuedContractsPercentage, setIssuedContractsPercentage] = useState("0")
    const [issuedContracts, setIssuedContracs] = useState(0)
    const [maxIssuedContracts, setMaxIssuedContracs] = useState(0)

    const [templatesPercentage, setTemplatesPercentage] = useState("0")
    const [currentTemplate, setCurrentTemplate] = useState(0)
    const [maxTemplates, setMaxTemplates] = useState(0)

    const [fieldAutomatization, setFieldAutomatization] = useState(false)
    const [attachment, setAttachment] = useState(false)
    const [finance, setFinance] = useState(false)
    const [personalization, setPersonalization] = useState(false)

    const [loading, setLoading] = useState(true)

    const goToAccounts = () => {
        window.location.replace(`${CurrentContext.authWebappUrl}/login?service=accounts&redirectUrl=/organization-licenses`);
    }

    useEffect(() => {
        fetchOrganizationQuota()
    }, [])

    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setInnerWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    function fetchOrganizationQuota() {
        setLoading(true)
        OrganizationContractsQuotaService.fetchContractsQuota().then(response => {

            setOrganizationQuota(response)

            // Set the percentage of storage quota usage
            let storagePercentage = 
                ((response.currentStorageConsumptionInBytes / response.customerContractsQuota.maxStorageBytes) * 100)
                    .toFixed(4);
            if (Number(storagePercentage) >= 100) storagePercentage = "100"
            setStorageConsumptionPercentage(storagePercentage)

            // Set the value of the current usage of storage, checking if it's GB, MB, KB or B
            if (response.currentStorageConsumptionInBytes >= 1073741824) {
                setStorageConsumptionString(`${(response.currentStorageConsumptionInBytes / 1073741824).toFixed(0)} GB`)
            } else if (response.currentStorageConsumptionInBytes >= 1048576 &&
                response.currentStorageConsumptionInBytes < 1073741824) {
                setStorageConsumptionString(`${(response.currentStorageConsumptionInBytes / 1048576).toFixed(0)} MB`)
            } else if (response.currentStorageConsumptionInBytes >= 1024 &&
                response.currentStorageConsumptionInBytes < 1048576) {
                setStorageConsumptionString(`${(response.currentStorageConsumptionInBytes / 1024).toFixed(0)} KB`)
            } else {
                setStorageConsumptionString(`${response.currentStorageConsumptionInBytes} B`)
            }

            // Set the value of the maximum storage, checking if it's GB, MB, KB or B
            if (response.customerContractsQuota.maxStorageBytes >= 1073741824) {
                setMaxStorageString(`${(response.customerContractsQuota.maxStorageBytes / 1073741824).toFixed(0)} GB`)
            } else if (response.customerContractsQuota.maxStorageBytes >= 1048576 &&
                response.customerContractsQuota.maxStorageBytes < 1073741824) {
                setMaxStorageString(`${(response.customerContractsQuota.maxStorageBytes / 1048576).toFixed(0)} MB`)
            } else if (response.customerContractsQuota.maxStorageBytes >= 1024 &&
                response.customerContractsQuota.maxStorageBytes < 1048576) {
                setMaxStorageString(`${(response.customerContractsQuota.maxStorageBytes / 1024).toFixed(0)} KB`)
            } else {
                setMaxStorageString(`${response.customerContractsQuota.maxStorageBytes} B`)
            }
            
            // Set the percentage of user quota usage
            let userPercentage = 
                ((response.currentSubusersCount / response.customerContractsQuota.maxSubusers) * 100)
                    .toFixed(4);
            if (Number(userPercentage) >= 100) userPercentage = "100"
            setUserConsumptionPercetage(userPercentage)
            
            setUserConsumption(response.currentSubusersCount)
            setMaxUser(response.customerContractsQuota.maxSubusers)

            const maxContractsIssuedPerMonth = response.customerContractsQuota.contractsIssuedPerMonth
            
            if (maxContractsIssuedPerMonth !== null) {
                let contractsPercentage = 
                ((response.contractsIssuedPerMonth / maxContractsIssuedPerMonth) * 100).toFixed(4)
                if (Number(contractsPercentage) >= 100) contractsPercentage = "100"
                setIssuedContractsPercentage(contractsPercentage)
            }

            setIssuedContracs(response.contractsIssuedPerMonth)
            setMaxIssuedContracs(maxContractsIssuedPerMonth)

            let templatePercentage =
                ((response.currentTemplateCount / response.customerContractsQuota.maxTemplateCount) * 100).toFixed(4)
            if (Number(templatePercentage) >= 100) templatePercentage = "100"
            setTemplatesPercentage(templatePercentage)

            setCurrentTemplate(response.currentTemplateCount)
            setMaxTemplates(response.customerContractsQuota.maxTemplateCount)

            setFieldAutomatization(response.customerContractsQuota.contractsFieldsAutomationEnabled)
            setAttachment(response.customerContractsQuota.contractAttachmentsEnabled)
            setFinance(response.customerContractsQuota.financialAnalyticsModuleEnabled)
            setPersonalization(response.customerContractsQuota.interfaceThemesCustomizationEnabled)

        })
        .finally(() => setLoading(false))
    }

    const handleTabChangeValue = (event: React.SyntheticEvent, value: string) => {
        setTabValue(value);
    };

    const TabListTheme: SxProps<Theme> = { 
            display: "flex", 
            alignItems: "center", 
            height: "40px", 
            maxWidth: (innerWidth > 400) ? "1000px" : "300px"
        }

    const QuotaBanner = (): JSX.Element => {

        let showBanner: boolean = false;
        let imageSource: any = "";
        let titleText: string = "";
        let bodyText: string = "";

        switch (tabValue) {
            case TabValues.STORAGE:
                showBanner = Number(storageConsumptionPercentage) > 80;
                titleText = "Não perca negócios por falta de armazenamento!"
                bodyText = "Ao alterar seu plano de armazenamento você impede que a falta de armazenamento impeça o crescimento do seu negócio!"
                imageSource = "/images/assets/storage.svg"
                break;
            case TabValues.USER:
                showBanner = Number(userConsumptionPercentage) > 80;
                titleText = "Melhore o crescimento da suas empresa!"
                bodyText = "Não deixe que a limitação do seu plano afete o petencial da sua empresa. Atulize agora mesmo e veja o seu nogócio crescer!"
                imageSource = "/images/assets/users.svg"
                break;
            case TabValues.CONTRACT:
                showBanner = issuedContracts ? Number(issuedContractsPercentage) > 80 : false;
                titleText = Number(issuedContractsPercentage) === 100 ? "Sua cota chegou ao fim" : "Sua cota está chegando ao fim"
                bodyText = "Necessidade mudam e ao alterar seu plano você economiza substituindo as compras de contratos avulsos por uma cota maior!"
                imageSource = "/images/assets/contracts.svg"
                break;
            case TabValues.TEMPLATE:
                break;
            case TabValues.MODULES:
                break;
            default:
                console.error(`tabValue: ${tabValue} is not supported`)
        }

        return (
            <>
                {
                    showBanner
                    ?
                        <Box p={2} sx=
                            {{
                                display: "flex", 
                                width: "100%", 
                                height: "250px", 
                                background: "radial-gradient(circle, #64067f, #432add);", 
                                alignItems: "center", 
                                justifyContent: "space-evenly",
                                
                            }}
                        >
                            <Box sx={{maxWidth: "500px"}}>
                                <Typography variant="h4" mb={2} sx={{color: "#FFF", fontFamily: "Montserrat Black Italic"}}>
                                    {titleText}
                                </Typography>
                                <Typography mb={2} variant="h6" sx={{color: "#FFF", fontFamily: "Montserrat Regular"}}>
                                    {bodyText}
                                </Typography>
                                <Button variant="contained" onClick={goToAccounts} sx={{backgroundColor: "#FFF", color: "primary.main", '&:hover': {backgroundColor: "#DFDFDF"}}} >Alterar meu plano</Button>
                            </Box>
                            <Box>
                                <img src={imageSource} alt={tabValue} style={{width: "225px", height: "225px"}} />
                            </Box>
                        </Box>
                    :
                        <></>
                }
            </>
        )
    }

    return (
        // <Box  sx={{display: "flex", flexDirection: "column", height: "100%"}}>
        <Container> 
            <Box p={2}>
                <Typography variant="h5" color="primary.main">Cotas da Organização</Typography>
                <Typography variant="subtitle1">Confira o consumo de todas as cotas que sua organização possui</Typography>
            </Box>
                
                    {
                        !loading
                        ?
                            <TabContext value={tabValue}>
                            <Box px={2} mt={3} sx={{display: "flex", placeItems: "center", flexDirection: "column"}}>
                            
                                <TabList onChange={handleTabChangeValue} variant="scrollable" scrollButtons allowScrollButtonsMobile sx={TabListTheme} >
                                    {tabList.map(tab => (
                                        <Tab icon={tab.icon} iconPosition="start" label={tab.label} value={tab.label} sx={{minHeight: "40px"}}></Tab>
                                    ))}
                                </TabList>
                            </Box>
                            <Box px={2}>
                                <TabPanel value={TabValues.STORAGE} sx={{maxWidth: "1000px", minHeight: "40vh"}}>
                                    <Box mb={3}>
                                        <Typography variant="h6">Cota de Armazenamento</Typography>
                                        <Typography variant="subtitle2" sx={{maxWidth: "700px"}} >
                                            Esta cota indica a quantidade de armazenamento atualmente utilizada 
                                            pela organização, incluindo contratos, anexos e outros arquivos.
                                        </Typography>
                                    </Box>
                                    <ProgressBar percentageString={storageConsumptionPercentage} currentValue={storageConsumptionString} maxValue={maxStorageString} />
                                </TabPanel>
                                <TabPanel value={TabValues.USER} sx={{maxWidth: "1000px", minHeight: "40vh"}}>
                                    <Box mb={3}>
                                        <Typography variant="h6">Cota de Usuários</Typography>
                                        <Typography variant="subtitle2" sx={{maxWidth: "700px"}} >
                                            Esta cota indica a quantidade de usuários atualmente utilizada pela organização.
                                        </Typography>
                                    </Box>
                                    <ProgressBar 
                                        percentageString={userConsumptionPercentage} 
                                        currentValue={userConsumption} 
                                        maxValue={maxUser}
                                    />
                                </TabPanel>
                                <TabPanel value={TabValues.CONTRACT} sx={{maxWidth: "1000px", minHeight: "40vh"}}>
                                    <Box mb={3}>
                                        <Typography variant="h6">Cota de Contratos Gratuitos Emitidos</Typography>
                                        <Typography variant="subtitle2" sx={{maxWidth: "700px"}} >
                                            Esta cota indica a quantidade de contratos gratuitos foram emitidos no mês pela organização.
                                        </Typography>
                                    </Box>
                                    {
                                        maxIssuedContracts
                                        ?
                                            <ProgressBar 
                                                percentageString={issuedContractsPercentage} 
                                                currentValue={issuedContracts < maxIssuedContracts ? issuedContracts : maxIssuedContracts} 
                                                maxValue={maxIssuedContracts}
                                            />
                                        :
                                        <Typography>Ilimitado</Typography>
                                    }
                                    {
                                        (maxIssuedContracts && issuedContracts > maxIssuedContracts)
                                        ?
                                            <Box mt={3}>
                                                <Box mb={3}>
                                                    <Typography variant="h6" >Contratos Emitidos em excesso</Typography>
                                                    <Typography variant="subtitle2" >Indica a quantidade de contratos emitidos além da cota mensal</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography>Contratos em excesso: {issuedContracts - maxIssuedContracts}</Typography>
                                                </Box>
                                            </Box>
                                        :
                                            <></>
                                    }
                                </TabPanel>
                                <TabPanel value={TabValues.TEMPLATE} sx={{maxWidth: "1000px", minHeight: "40vh"}}>
                                    <Box mb={3}>
                                        <Typography variant="h6">Cota de Modelos</Typography>
                                        <Typography variant="subtitle2" sx={{maxWidth: "700px"}} >
                                            Esta cota indica a quantidade de modelos de contratos criados pela organização.
                                        </Typography>
                                    </Box>
                                    <ProgressBar 
                                        percentageString={templatesPercentage} 
                                        currentValue={currentTemplate} 
                                        maxValue={maxTemplates}
                                    />

                                </TabPanel>
                                <TabPanel value={TabValues.MODULES} sx={{maxWidth: "1000px", minHeight: "40vh"}}>
                                    <Box mb={3}>
                                        <Box mb={3}>
                                            <Typography variant="h6">Acesso ao modulo de Campos Automatizados</Typography>
                                            <Typography variant="subtitle2" sx={{maxWidth: "700px"}} >
                                                Essa é a permissão para acessar o modulo de automatização de campos no contrato
                                            </Typography>
                                        </Box>
                                        <Box p={1} sx={{display: "flex", backgroundColor: fieldAutomatization ? "#60d84d" : "#D84D4D", maxWidth: "400px", borderRadius: "5px", placeItems: "center"}}>
                                            {fieldAutomatization ? <CheckCircle sx={{color: "white"}} /> : <Close sx={{color: "white"}} /> }
                                            <Typography ml={2} color="white" > {fieldAutomatization ? "Você possui acesso a esse modulo" : "Você não possui acesso a esse modulo"} </Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={3} >
                                        <Box mb={3}>
                                            <Typography variant="h6">Acesso ao modulo de Anexos</Typography>
                                            <Typography variant="subtitle2" sx={{maxWidth: "700px"}} >
                                                Essa é a permissão para acessar o modulo de inserção de arquivos em anexo no contrato
                                            </Typography>
                                        </Box>
                                        <Box p={1} sx={{display: "flex", backgroundColor: attachment ? "#60d84d" : "#D84D4D", maxWidth: "400px", borderRadius: "5px", placeItems: "center"}}>
                                            {attachment ? <CheckCircle sx={{color: "white"}} /> : <Close sx={{color: "white"}} /> }
                                            <Typography ml={2} color="white" > {attachment ? "Você possui acesso a esse modulo" : "Você não possui acesso a esse modulo"} </Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={3} >
                                        <Box mb={3}>
                                            <Typography variant="h6">Acesso ao modulo de Gestão Financeira</Typography>
                                            <Typography variant="subtitle2" sx={{maxWidth: "700px"}} >
                                                Essa é a permissão para acessar o modulo gestão financeira de contratos
                                            </Typography>
                                        </Box>
                                        <Box p={1} sx={{display: "flex", backgroundColor: finance ? "#60d84d" : "#D84D4D", maxWidth: "400px", borderRadius: "5px", placeItems: "center"}}>
                                            {finance ? <CheckCircle sx={{color: "white"}} /> : <Close sx={{color: "white"}} /> }
                                            <Typography ml={2} color="white" > {finance ? "Você possui acesso a esse modulo" : "Você não possui acesso a esse modulo"} </Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={3} >
                                        <Box mb={3}>
                                            <Typography variant="h6">Acesso ao modulo de Personalização</Typography>
                                            <Typography variant="subtitle2" sx={{maxWidth: "700px"}} >
                                                Essa é a permissão para acessar o modulo personalização de email e tela de assinatura
                                            </Typography>
                                        </Box>
                                        <Box p={1} sx={{display: "flex", backgroundColor: personalization ? "#60d84d" : "#D84D4D", maxWidth: "400px", borderRadius: "5px", placeItems: "center"}}>
                                            <CheckCircle sx={{color: "white"}} />
                                            <Typography ml={2} color="white" > {personalization ? "Você possui acesso a esse modulo" : "Você não possui acesso a esse modulo"} </Typography>
                                        </Box>
                                    </Box>
                                    
                                </TabPanel>
                            </Box>
                            <QuotaBanner/>
                            
                    
                        </TabContext>
                    :
                        <Box p={2} sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "85vh"}}>
                            <CircularProgress size={50} sx={{ color: 'primary.main' }} />
                        </Box>
                    }

                
        </Container>
    )
}

export default QuotaInfo;