import { Button, DialogActions, Drawer, TextField, Typography } from "@mui/material";
import { UserAccount } from "../../models/user";
import { useContext } from "react";
import AuthenticationContext from "../../contexts/authentication";

//deno-lint-ignore-file no-explicit-any
const SubuserInfo = (props: React.ComponentProps<any>) => {

    //Props
    const { open, close, subuser } = props;

    // authenticated user
    const auth = useContext(AuthenticationContext);

    //Force type
    const _subuser: UserAccount = subuser;

    /**
     * Open a new tab with Klaus Accounts webapp to update selected user  
     */
    const goToAccounts = () => {
        window.location.replace(`${process.env.REACT_APP_AUTH_WEBAPP}/login?service=accounts&requiredEmail=${auth.user().account.email}&redirectUrl=/user-and-teams`);
    }

    return (
        <Drawer anchor='right' open={open} onClose={close} sx={{ '& .MuiDrawer-paper': { width: { sm: 550, xs: 350 }, p: { sm: 5, xs: 3 } } }}>
            <Typography variant='h6' mb={5} color='primary.main'>Informações <strong>pessoais</strong></Typography>
            <Typography mb={5}>Caso queira alterar alguma informação, você será direcionado para o Klaus Accounts</Typography>
            <TextField value={_subuser?.preferredName} label='Nome de preferência' />
            <TextField value={_subuser?.fullname} label='Nome completo' sx={{ my: 5 }} />
            <TextField value={_subuser?.email} label='Email' />
            <DialogActions sx={{ mt: 10 }}>
                <Button onClick={close}>Voltar</Button>
                <Button variant='contained' onClick={goToAccounts}>Alterar no Klaus Accounts</Button>
            </DialogActions>
        </Drawer >
    )
}

export default SubuserInfo;
