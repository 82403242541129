import { AttachFile, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, IconButton, ListItemIcon, Typography } from "@mui/material";
import { ContractPartie, RequiredAttachment } from "../../../../../../models/contracts";
import AddFieldButton from "../../../components/AddFieldButton";
import AttachmentForm from "../forms/AttachmentForm";

// Interfaces and Types
interface AttachmentProps extends React.ComponentProps<any> {
    attachments: RequiredAttachment[],
    addAttachment: boolean,
    currentAttach: RequiredAttachment | null,
    parties: ContractPartie[],
    maxHeight: string
    setAttachments: (value: RequiredAttachment[]) => void,
    setAddAttachment: (value: boolean) => void,
    setCurrentAttach: (value: RequiredAttachment | null) => void,
}

const Attachments = (props: AttachmentProps): JSX.Element => {

    // Destructure props object
    const { attachments, addAttachment, currentAttach, parties, maxHeight, setAttachments, setAddAttachment, setCurrentAttach } = props;

    // Handler functions
    function handleAddAttachment(attach: RequiredAttachment) {
        const updatedAttachments = [...attachments, attach];
        setAttachments(updatedAttachments.sort((a, b) => a.name.localeCompare(b.name)));
        setAddAttachment(false);
    }

    function handleEditAttachment(attach: RequiredAttachment) {
        if (!currentAttach) return;

        const updatedAttachments = attachments.map(at => {
            if (at.name === currentAttach.name) return attach;
            return at;
        })

        setAttachments(updatedAttachments);
        setCurrentAttach(null);
    }

    function handleOpenAttachmentEdit(attach: RequiredAttachment) {
        setCurrentAttach(attach);
    }

    function handleDeleteAttachment(attach: RequiredAttachment) {
        const filter = attachments.filter(at => at.name !== attach.name);

        setAttachments(filter);
    }

    return (
        <Box sx={{ maxHeight, overflow: "auto", mb: 3 }}>
            {
                addAttachment
                    ?
                    <AttachmentForm
                        currentAttach={currentAttach}
                        parties={parties}
                        attachments={attachments}
                        cancelAction={() => setAddAttachment(false)}
                        addAttachment={handleAddAttachment}
                    />
                    :
                    <AddFieldButton
                        onClick={() => setAddAttachment(true)}
                        disabled={currentAttach !== null || addAttachment}
                        title="Adicionar Anexo"
                    />
            }
            {
                attachments.map(at => (
                    currentAttach && currentAttach.name === at.name
                        ?
                        <AttachmentForm
                            currentAttach={currentAttach}
                            parties={parties}
                            attachments={attachments}
                            cancelAction={() => setCurrentAttach(null)}
                            addAttachment={handleEditAttachment}
                        />
                        :
                        <Box
                            key={at.name}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                py: 1,
                                px: 2,
                                border: "1px solid rgba(0, 0, 0, 0.25)",
                                borderRadius: "5px",
                                mb: 3
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <ListItemIcon>
                                    <AttachFile />
                                </ListItemIcon>
                                <Typography>{at.name}</Typography>
                            </Box>
                            <Box>
                                <IconButton onClick={() => handleOpenAttachmentEdit(at)} >
                                    <EditOutlined color="primary" />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteAttachment(at)} >
                                    <DeleteOutline color="error" />
                                </IconButton>
                            </Box>
                        </Box>
                ))
            }
        </Box>
    )
}

export default Attachments;