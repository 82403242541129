import { SxProps, Theme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PinInput from 'react-pin-input';

interface CodeInputProps {
  totalDigits: number;
  onComplete: (pin: string) => void;
  isMobile: boolean;
}

const CodeInput: React.FC<CodeInputProps> = ({ totalDigits, onComplete, isMobile }) => {
  const [code, setCode] = useState('');

  useEffect(() => {
    if (code.length === totalDigits) {
      onComplete(code);
    }
  }, [code, totalDigits, onComplete]);

  const handleCodeChange = (value: string) => {
    value = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    setCode(value.slice(0, totalDigits));
  };

  const pinInputTheme = {
    borderRadius: 12,
    border: "2px solid #64067f",
    boxShadow: "2px 2px 2px #ddd",
    fontSize: "24px",
    padding: "8px"
  }

  const mobilePinInputTheme = {
    borderRadius: 12,
    border: "2px solid #64067f",
    boxShadow: "2px 2px 2px #ddd",
    fontSize: "16px",
    padding: "8px",
    height: "24px",
    width: "24px",
  }

  return (
    <PinInput
      inputStyle={isMobile ? mobilePinInputTheme : pinInputTheme}
      inputFocusStyle={{boxShadow: "2px 2px 2px rgba(100, 6, 127, 0.6)"}}
      length={totalDigits}
      type={'custom'}
      onChange={handleCodeChange}
      onComplete={handleCodeChange}
    />
  );
};

export default CodeInput;
