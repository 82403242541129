import { Theme } from "@emotion/react";
import { Box, Divider, Input, SxProps, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { RequiredInformationInputFieldProps } from "../rifields";

const Email = (props: RequiredInformationInputFieldProps): JSX.Element => {
    const [value, setValue] = useState(props.requiredInformation.value);
    const [isValid, setIsValid] = useState(false);

    const boxStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            padding: "1rem 0.8rem"
        },
        ['@media(max-width:300px)']: {
            padding: "1rem 0.8rem"
        },
        width: "100%",
        padding: "20px 28px",
        backgroundColor: "#ffffff",
        marginBottom: "14px",
        borderRadius: "10px",
        boxSizing: "border-box",
    }
    const titleStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            fontSize: "0.875rem",
            padding: "0.375rem 0.625rem"
        },
        ['@media(max-width:300px)']: {
            fontSize: "0.5rem",
            padding: "0.125rem 0.25rem"
        },
        color: "#555555",
        marginBottom: "30px",
        fontSize: "20px"
    }

    useEffect(() => {
        setIsValid(/^\S+@\S+\.\S+$/.test(value))
    }, [value])

    useEffect(() => {
        props.onChange(
            value,
            isValid
        );
    }, [value, isValid]);

    function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
        setValue(event.target.value);
    }

    return (
        <Fragment key={props.requiredInformation.requiredInformationName}>
            <Box sx={boxStyle} >
                <Typography sx={titleStyle}>{props.requiredInformation.requiredInformationName}</Typography>
                <Tooltip title={(isValid) ? null : "Esse email não é válido"}>
                    <Input
                        error={!isValid}
                        placeholder="Insira um email"
                        type="text"
                        value={value}
                        fullWidth
                        onChange={handleOnChange}
                    />
                </Tooltip>
            </Box>
            <Divider />
        </Fragment>
    );
}

export default Email;
