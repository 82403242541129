import { Close, Draw, Edit, ExpandLess, ExpandMore, NoteAdd, TextFields, TimerOutlined } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, IconButton, Tab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LoadingButton } from '../../components/LoadingButton';
import { EmailTypes, MailTemplate } from '../../models/contracts';
import { InterfaceTheme } from '../../models/organizations';
import { show } from '../../redux/features/app-global-notification/app-global-notification-slice';
import { useAppDispatch } from '../../redux/hooks';
import OrganizationService from '../../services/organization';
import ErrorWrapper from '../../utils/ErrorWrapper';
import { LoadingState } from '../../utils/types';

// Default values
const defaultInterfaceTheme: InterfaceTheme = {
    primaryColor: "#370068",
    secondaryColor: "#7C24BE",
    logoUrl: "https://accountsblob.blob.core.windows.net/publicassets/cartorizi-logo.webp"
}

const toolbarOptions = [
    [{ 'bold': true }, { 'italic': true }, { 'underline': true }, { 'strike': true }],
    [{ 'header': 1 }, { 'header': 2 }, 'blockquote'],
    [{ 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }],
    ['clean']
];

const EmailTemplateEditor = (): JSX.Element => {

    // Contexts
    const notification = useAppDispatch();

    // Loading states
    const [customizeEmailLoading, setCustomizeEmailLoading] = useState<LoadingState>("loading");
    const [emailContentLoading, setEmailContentLoading] = useState<LoadingState>("loading");
    const [saveMailTemplateLoading, setSaveMailTemplateLoading] = useState<LoadingState>("loaded");

    // Boolean states
    const [openWildcardsTips, setOpenWildcardsTips] = useState(true);
    const [openMailPreview, setOpenMailPreview] = useState(false);

    // Data states
    const [mailType, setMailType] = useState<EmailTypes>(EmailTypes.ISSUE_CONTRACT);
    const [content, setContent] = useState("");
    const [mailTemplates, setMailTemplates] = useState<MailTemplate[]>([]);
    const [interfaceTheme, setInterfaceTheme] = useState<InterfaceTheme>(defaultInterfaceTheme);

    // useEffects
    useEffect(() => {
        initializeQuill();
        fetchMailTemplates();
        fetchInterfaceTheme();
    }, [])

    useEffect(() => {
        changeMailContent();
    }, [mailType, mailTemplates])

    // Functions
    function initializeQuill() {
        // Register Quill modules
        const backgroundStyle = Quill.import('attributors/style/background');
        const colorStyle = Quill.import('attributors/style/color');
        const sizeStyle = Quill.import('attributors/style/size');
        const alignStyle = Quill.import('attributors/style/align');
        Quill.register(backgroundStyle, true);
        Quill.register(colorStyle, true);
        Quill.register(sizeStyle, true);
        Quill.register(alignStyle, true);
    };

    function fetchMailTemplates() {
        setCustomizeEmailLoading("loading");
        OrganizationService.fetchOrganizationEmail()
            .then(response => {
                if (!response || response.length <= 0) return;
                setMailTemplates(response);
            })
            .finally(() => setCustomizeEmailLoading("loaded"));

    }

    function fetchInterfaceTheme() {
        setCustomizeEmailLoading("loading");
        OrganizationService.fetchOrganizationInterfaceTheme()
            .then(response => {
                setInterfaceTheme(response);
            })
            .finally(() => setCustomizeEmailLoading("loaded"));
    }

    function getEditMailTitle() {
        if (!mailType) return;

        switch (mailType) {
            case EmailTypes.ISSUE_CONTRACT:
                return (
                    <>
                        <Box display={"flex"} alignItems={"center"}>
                            <Edit color="primary" sx={{ fontSize: "32px" }} />
                            <Typography sx={{ ml: 2 }} >Personalização do e-mail de emissão</Typography>
                        </Box>
                        <Typography variant="caption">Esse e-mail é enviado quando um contrato é emitido utilizando um modelo</Typography>
                    </>
                )
            case EmailTypes.CHANGE_STATES:
                return (
                    <>
                        <Box display={"flex"} alignItems={"center"}>
                            <Edit color="primary" sx={{ fontSize: "32px" }} />
                            <Typography sx={{ ml: 2 }} >Personalização do e-mail de aguardando assinaturas</Typography>
                        </Box>
                        <Typography variant="caption">Esse e-mail é enviado quando um contrato está aguardando assinaturas dos participantes</Typography>
                    </>
                )
            case EmailTypes.FULLY_SIGNED:
                return (
                    <>
                        <Box display={"flex"} alignItems={"center"}>
                            <Edit color="primary" sx={{ fontSize: "32px" }} />
                            <Typography sx={{ ml: 2 }} >Contrato totalmente assinado</Typography>
                        </Box>
                        <Typography variant="caption">Esse e-mail é enviado quando um contrato foi assinado por todos os seus participantes</Typography>
                    </>
                )
            default:
                return (
                    <></>
                )
        }
    }

    function changeMailContent() {
        setEmailContentLoading("loading");
        const foundTemplate = mailTemplates.find(mt => mt.mailType === mailType);

        if (foundTemplate) {
            setContent(foundTemplate.mailTemplate);
        } else {
            setContent("");
        }
        setEmailContentLoading("loaded");
    }

    // Handler functions
    function handleUpdateMailTemplate() {
        setSaveMailTemplateLoading("loading");

        let updatedMailTemplates: MailTemplate[] = [];

        const foundMailTemplate = mailTemplates.find(mt => mt.mailType === mailType);

        if (foundMailTemplate) {
            if (content === "<p><br></p>") {
                updatedMailTemplates = mailTemplates.filter(mt => mt.mailType !== mailType);
                setMailTemplates(updatedMailTemplates);
                OrganizationService.updateGlobalMailTemplate({ mailTemplate: content, mailType })
                    .then(response => {
                        notification(show({type: "success", message: "Modelo de email global atualizado com sucesso"}))
                    })
                    .catch(e => {
                        const err = new ErrorWrapper(e);
                        notification(show({ type: "error", message: err.message }));
                    })
                    .finally(() => setSaveMailTemplateLoading("loaded"));
            }
            else {
                updatedMailTemplates = mailTemplates.map(mt => {
                    if (mt.mailType === mailType) return { mailTemplate: content, mailType };
                    return mt;
                })
                setMailTemplates(updatedMailTemplates);
                OrganizationService.updateGlobalMailTemplate({ mailTemplate: content, mailType })
                    .then(response => {
                        notification(show({type: "success", message: "Modelo de email global atualizado com sucesso"}))
                    })
                    .catch(e => {
                        const err = new ErrorWrapper(e);
                        notification(show({ type: "error", message: err.message }));
                    })
                    .finally(() => setSaveMailTemplateLoading("loaded"));
            }
        } else {
            if (content !== "<p><br></p>") {
                updatedMailTemplates = [...mailTemplates, { mailTemplate: content, mailType }];
                setMailTemplates(updatedMailTemplates);
                OrganizationService.updateGlobalMailTemplate({ mailTemplate: content, mailType })
                    .then(response => {
                        notification(show({type: "success", message: "Modelo de email global atualizado com sucesso"}))
                    })
                    .catch(e => {
                        const err = new ErrorWrapper(e);
                        notification(show({ type: "error", message: err.message }));
                    })
                    .finally(() => setSaveMailTemplateLoading("loaded"));
            }
        }

    }

    /**
    * Change the current select tab
    * @param event 
    * @param newValue 
    */
    function handleChangeTabs(event: React.SyntheticEvent, newValue: EmailTypes) {
        setMailType(newValue);
    }

    // JSX Components
    function DisplayPreview(): JSX.Element {
        return (
            <Dialog open={openMailPreview} onClose={() => setOpenMailPreview(false)} maxWidth="lg" fullWidth>
                <DialogTitle sx={{ textAlign: "end" }}>
                    <IconButton onClick={() => setOpenMailPreview(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div style={{ width: "700px", backgroundColor: "#fff", padding: "2rem", borderRadius: "15px", margin: "auto", boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.4)" }}>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ marginBottom: "2rem" }}>
                                <img src={interfaceTheme.logoUrl} alt="Cartorizi" style={{ width: "150px" }} />
                            </div>
                            <h1 style={{ color: interfaceTheme.primaryColor }}>Assinatura do contrato: {"{contract.name}"}</h1>
                            <p>Olá, seu contrato com a <b>{"{organization.name}"}</b> foi emitido.</p>
                            <p>
                                Você foi adicionado como <b>{"{contractParty.role}"}</b> do contrato <b>{"{contract.name}"}</b>, para visualizar o seu contrato clique no botão abaixo.
                            </p>
                            <p style={{ margin: "36px" }}>
                                <a href="#" style={{ backgroundColor: interfaceTheme.secondaryColor, color: "white", padding: "8px 16px", borderRadius: "4px", textDecoration: "none" }}>
                                    Clique aqui para ver o contrato
                                </a>
                            </p>
                            <div style={{ textAlign: "start" }}><p dangerouslySetInnerHTML={{ __html: content }} style={{ maxWidth: "700px", textOverflow: "ellipsis" }} ></p></div>
                            <hr style={{ margin: "16px" }} />
                            <p>
                                <b>Caso não reconheça ou não queira assinar este contrato, Apenas ignore-o. Sem o seu consentimento o contrato não terá nenhum valor jurídico.</b>
                            </p>
                            <p>Se tiver problemas ou em caso de dúvidas <a href={"#"}>contate nosso suporte</a>.</p>
                            <hr style={{ margin: "16px" }} />
                            <h4 style={{ color: "#d4a6fc" }}>Sobre o Cartorizi</h4>
                            <p style={{ color: "#a1a1a1", fontSize: "12px" }}>
                                A Cartorizi é uma plataforma de contratos digitais que permite a criação, assinatura e gerenciamento de documentos e acordos de forma rápida e segura.
                                Com a Cartorizi, é possível automatizar processos e garantir a validade jurídica dos contratos, já que todas as etapas são realizadas de maneira digital e registradas em blockchain.
                                Além disso, a plataforma oferece uma interface intuitiva e fácil de usar, tornando o processo de criação de contratos acessível para usuários com pouco conhecimento técnico.
                                Com a Cartorizi, é possível economizar tempo e dinheiro, além de garantir a segurança e a validade dos contratos.
                            </p>
                            <h4 style={{ color: "#d4a6fc" }}>Por quê assinaturas digitais são confiáveis?</h4>
                            <p style={{ color: "#a1a1a1", fontSize: "12px" }}>
                                A Cartorizi utiliza hashs para garantir a autenticidade de seus documentos. As assinaturas com hash são consideradas confiáveis porque utilizam uma função matemática para gerar um resumo único e imutável do documento original.
                                Esse resumo, chamado de "hash", é gerado a partir do conteúdo do documento e serve como uma "assinatura digital" do mesmo.
                                Quando um documento é assinado com hash, o assinante gera o hash do documento original e o assina digitalmente, confirmando sua autenticidade.
                                Se o documento for alterado posteriormente, o hash gerado será diferente do original e a assinatura tornar-se-á inválida.
                                Desta forma, as assinaturas com hash garantem a integridade e a autenticidade dos documentos, tornando-os confiáveis e seguros.
                            </p>
                        </div>
                    </div>
                </DialogContent >
            </Dialog >
        )
    }

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            {
                customizeEmailLoading === "loaded"
                    ?
                    <TabContext value={mailType}>
                        <TabList onChange={(e, newValue) => handleChangeTabs(e, newValue)} centered>
                            <Tab value={EmailTypes.ISSUE_CONTRACT} label="Emissão" icon={<NoteAdd />} />
                            <Tab value={EmailTypes.CHANGE_STATES} label="Aguardando Assinaturas" icon={<TimerOutlined />} />
                            <Tab value={EmailTypes.FULLY_SIGNED} label="Totalmente Assinado" icon={<Draw />} />
                        </TabList>
                        <TabPanel value={mailType}>
                            <Box>
                                {getEditMailTitle()}
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ mt: 3, width: "100%" }} >
                                    <Box display={"flex"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => setOpenWildcardsTips(!openWildcardsTips)}>
                                        <TextFields color="primary" sx={{ fontSize: "32px" }} />
                                        <Typography sx={{ ml: 2 }} >Coringas</Typography>
                                        {
                                            openWildcardsTips
                                                ?
                                                <ExpandLess sx={{ ml: 6 }} />
                                                :
                                                <ExpandMore sx={{ ml: 6 }} />
                                        }
                                    </Box>
                                    <Button variant="outlined" onClick={() => setOpenMailPreview(true)}>Visualizar Prévia de e-mail</Button>
                                </Box>
                                <Collapse in={openWildcardsTips} sx={{ mt: 2 }}>
                                    <Typography >Na edição dos e-mails podem utilizados alguns coringas para definir os valores
                                        com base no contrato</Typography>
                                    <Typography sx={{ mt: 1 }} ><b>{"{contract.name}"}</b> - Nome do contrato emitido.</Typography>
                                    <Typography sx={{ mt: 1 }} ><b>{"{contractParty.role}"}</b> - Nome do participante. ex: Contratante, Testemunha,
                                        Contratada, etc...</Typography>
                                    <Typography sx={{ mt: 1 }} ><b>{"{organization.name}"}</b> - Nome de apresentação da organização</Typography>
                                </Collapse>
                                {
                                    emailContentLoading === "loaded"
                                        ?
                                        <Box>
                                            <Box sx={{ mt: 3 }}>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={content}
                                                    onChange={(e) => setContent(e)}
                                                    modules={{
                                                        toolbar: toolbarOptions,
                                                    }}
                                                    style={{ minHeight: "300px", height: "100%" }}
                                                />
                                            </Box>
                                            <Box sx={{ width: "100", display: "flex", alignItems: "center", justifyContent: "flex-end", mt: 3 }}>
                                                <LoadingButton loading={saveMailTemplateLoading === "loading"} variant="contained" onClick={handleUpdateMailTemplate} >Salvar</LoadingButton>
                                            </Box>
                                        </Box>
                                        :
                                        <Box sx={{ mt: 3, width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <CircularProgress color="primary" sx={{ fontSize: "64px" }} />
                                        </Box>
                                }
                            </Box>
                        </TabPanel>
                    </TabContext>
                    :
                    <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress color="primary" sx={{ fontSize: "64px" }} />
                    </Box>
            }
            <DisplayPreview />
        </Box>
    )
}

export default EmailTemplateEditor;