import { useEffect, useState } from "react";
import { Button, DialogActions, Drawer, TextField, Typography } from "@mui/material";
import { LoadingButton } from "../../components/LoadingButton";
import TeamsService from "../../services/teams";

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../utils/ErrorWrapper";

const CreateTeams = (props: React.ComponentProps<any>) => {

    //Props
    const { open, close, totalRecords, onSuccess } = props;

    //Util - Indicates the maximum amount of teams a organization can have
    const MAX_ORGANIZATION_TEAMS = 999;

    //Context props
    const notification = useAppDispatch();

    //States
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('');

    //Reset name state when drawer is closed
    useEffect(() => { setName('') }, [close])

    //Events
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)

    /**
     * Create a new organization team
     * @returns 
     */
    const createTeam = () => {
        //Validate the amount of teams the organization has (can not exceed the max amount of 999)
        if (totalRecords + 1 > MAX_ORGANIZATION_TEAMS)
            return notification(show({ type: 'error', message: `O limite máximo de times desta organização foi atingido` }))

        //Create the team 
        setLoading(true)
        TeamsService.create(name)
            .then(newTeam => {
                //Callback functions when team is created
                onSuccess(newTeam);
                close();

                //Notify user
                notification(show({ message: `Time criado!`, type: 'success' }))
            })
            .catch(err => {
                const e = new ErrorWrapper(err)
                notification(show({
                    type: 'error',
                    message: e.httpStatus === 422 ? `Dados inválidos`
                        : e.httpStatus === 403 ? `Ação não autorizada`
                            : `Ocorreu um erro: ${e.message}`
                }))
            })
            .finally(() => setLoading(false))
    }

    return (
        <Drawer anchor='right' open={open} onClose={close} sx={{ '& .MuiDrawer-paper': { width: { sm: 550, xs: 350 }, p: { sm: 5, xs: 3 } } }}>
            <Typography variant='subtitle1' fontWeight={600} textAlign='center' mb={5}>Novo time</Typography>
            <TextField label='Nome do time' sx={{ borderColor: 'primary' }} value={name} onChange={handleChange} />
            <DialogActions sx={{ mt: 5 }}>
                <Button onClick={close} color='error'>Cancelar</Button>
                <LoadingButton variant='contained' loading={loading} onClick={createTeam}>Salvar</LoadingButton>
            </DialogActions>
        </Drawer>
    )
}

export default CreateTeams;
