import { Close, ArrowForward, Description, Email, InsertDriveFileOutlined, Preview, Sell, SellOutlined, AlternateEmail, AttachMoney, Business, CalendarMonth, Group, Today, Event, CheckCircle, Error, Check, CheckCircleOutline } from "@mui/icons-material";
import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Box, Button, Autocomplete, Tab, TextField, Typography, Tooltip, Grid, CircularProgress, Checkbox, ListItem, Select, TextareaAutosize } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BillingPeriodType, EndDatePolicyType, FileIssueDTO, IdentificationType, FileIssueContractPartie, StartDatePolicyType, IssuedDigitalContract, EmailTypes, MailTemplate } from "../../../../../../models/contracts";
import { InterfaceTheme } from "../../../../../../models/organizations";
import { AccountTypes, UserAccount } from "../../../../../../models/user";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { show } from "../../../../../../redux/features/app-global-notification/app-global-notification-slice";
import { TagsModel } from "../../../../../../services/tags";
import { LoadingState } from "../../../../../../utils/types";
import AuthenticationContext from "../../../../../../contexts/authentication";
import AuthorizationService from "../../../../../../services/authorization";
import OrganizationService from "../../../../../../services/organization";
import ErrorWrapper from "../../../../../../utils/ErrorWrapper";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TagsAdder from "../../../../../../components/TagsAdder";
import moment from "moment";
import { parseDate } from "pdf-lib";
import { SupportedCurrenciesValue } from "../../../../../../utils/currency";
import { billTypes } from "../../../../../../utils/editorUtils";
import ContractsService from "../../../../../../services/contracts";
import { useNavigate } from "react-router-dom";
import EmailEditor from "../../../components/EmailEditor";

// Interfaces and Types
interface FileIssueDataReviewProps extends React.ComponentProps<any> {
    open: boolean,
    issueDTO: FileIssueDTO,
    contractFile: File,
    setOpen: (value: boolean) => void,
    setIssueDTO: (value: FileIssueDTO) => void
}

// Enums
enum Tabs {
    NAME = "NAME",
    CONFIRMATION = "CONFIRMATION",
    TAGS = "TAGS",
    EMAIL = "EMAIL",
    REVIEW = "REVIEW"
};

// Default values
const defaultInterfaceTheme: InterfaceTheme = {
    primaryColor: "#370068",
    secondaryColor: "#7C24BE",
    logoUrl: "https://accountsblob.blob.core.windows.net/publicassets/cartorizi-logo.webp"
}

const FileIssueDataReview = (props: FileIssueDataReviewProps): JSX.Element => {

    // Destructure props object
    const { issueDTO, open, contractFile, setIssueDTO, setOpen } = props;

    // Contexts
    const notification = useAppDispatch();
    const navigate = useNavigate();
    const auth = useContext(AuthenticationContext);

    // Loading states
    const [issueLoading, setIssueLoading] = useState<LoadingState>("loading");

    // Data states
    const [contractName, setContractName] = useState(issueDTO ? issueDTO.name : "");
    const [suggestedNames, setSuggestedNames] = useState<string[]>([]);
    const [organization, setOrganization] = useState("");
    const [interfaceTheme, setInterfaceTheme] = useState<InterfaceTheme>(defaultInterfaceTheme);
    const [tags, setTags] = useState<TagsModel[]>([]);
    const [members, setMembers] = useState<UserAccount[]>([]);
    const [contract, setContract] = useState<IssuedDigitalContract | null>(null);
    const [mailTemplates, setMailTemplates] = useState<MailTemplate[]>(issueDTO.mailTemplates);
    const [readConfirmation, setReadConfirmation] = useState(false);
    const [clausesToConfirm, setClausesToConfirm] = useState("");

    // Tab states
    const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.NAME);

    // useEffects
    useEffect(() => {
        fetchDataForPreview();
        fetchMembers();
    }, [])

    // Functions
    /**
     * Fetches the data to display on the e-mail preview
     */
    async function fetchDataForPreview() {
        try {
            setIssueLoading("loading")
            if (auth.user().session.accountType === AccountTypes.Customer) {
                setOrganization(auth.user().account.fullname);
            } else {
                setOrganization((await AuthorizationService.getSubuserOrganizationOwner()).fullname);
            }

            setInterfaceTheme(await OrganizationService.fetchOrganizationInterfaceTheme());
        } catch (e) {
            const err = new ErrorWrapper(e);
            if (err.httpStatus !== 404) {
                notification(show({ type: "error", message: err.message }));
            }
        } finally {
            setIssueLoading("loaded");
        }

    }

    /**
     * Fetches the data of the organization members that are contract parties
     */
    function fetchMembers() {
        const foundMembers: UserAccount[] = [];

        issueDTO.contractParties.forEach(async (party) => {
            if (party.identification.uuid) {
                try {
                    const member = await OrganizationService.fetchOrganizationMemberByUuid(party.identification.uuid);
                    foundMembers.push(member);
                } catch (e) {
                    console.error(e);
                }
            }
        })

        setMembers(foundMembers);
    }

    /**
     * issues the contract
     */
    async function issueContract() {
        setIssueLoading("loading");

        let clauses: string[] = []
        if (clausesToConfirm !== "") {
            clauses = clausesToConfirm.split(";").map(s => s.trim());
            clauses = clauses.filter(s => s !== "");
        }

        const parsedTags = tags.map(tag => tag.tagName);

        const dto: FileIssueDTO = {
            ...issueDTO,
            clausesToConfirm: clauses,
            contractTags: {
                tags: parsedTags
            }
        }

        try {
            const response = await ContractsService.issueDigitalContractFromFile(dto, contractFile);
            setContract(response);
            setIssueLoading("success");
        } catch (e) {
            const err = new ErrorWrapper(e);
            notification(show({ type: "error", message: err.message }));
            setIssueLoading("error");
        }
    }

    /**
     * displays the correct identification of the party 
     * @param party 
     * @returns 
     */
    function getPartyIdentification(party: FileIssueContractPartie) {
        switch (party.contractPartyIdentificationType) {
            case IdentificationType.PLATFORM_USER:

                if (!party.identification.uuid) return "";

                const foundMember = members.find(member => member.uuid === party.identification.uuid);

                if (foundMember) return foundMember.fullname;
                else return party.identification.uuid;

            case IdentificationType.IDENTIFIED_BY_EMAIL:

                if (!party.identification.email) return "";
                return party.identification.email;

            default:
                return "";
        }
    }

    // Handler functions
    /**
     * changes the name of the contract
     * @param contractName 
     * @returns 
     */
    function handleChangeTemplateName(contractName: string) {
        if (contractName.length > 240) return;
        setContractName(contractName);
        setIssueDTO({ ...issueDTO, name: contractName.trim() });
    }

    /**
     * handle the addtion of contract tags
     * @param tag 
     */
    function handleAddTags(tag: TagsModel) {
        const isTagDuplicate = tags.some((t) => t.tagName === tag.tagName);

        if (!isTagDuplicate) {
            const updatedTags = [...tags, tag];
            setTags(updatedTags);
        }
    }

    function handleUpdateMailTemplates(newValue: string, type: EmailTypes) {
        if (newValue) {
            let updatedMailTemplates = [...issueDTO.mailTemplates];

            if (updatedMailTemplates.find(mt => mt.mailType === type)) {
                updatedMailTemplates = updatedMailTemplates.map(mt => {
                    if (mt.mailType === type) {
                        const newMt: MailTemplate = {
                            mailTemplate: newValue,
                            mailType: type
                        }

                        return newMt;
                    }
                    return mt;
                })
            } else {
                updatedMailTemplates.push({ mailTemplate: newValue, mailType: type });
            }

            setMailTemplates(updatedMailTemplates);
            setIssueDTO({ ...issueDTO, mailTemplates: updatedMailTemplates });
        } else {
            let updatedMailTemplates = issueDTO.mailTemplates.filter(mt => mt.mailType !== type);

            setMailTemplates(updatedMailTemplates);
            setIssueDTO({ ...issueDTO, mailTemplates: updatedMailTemplates });
        }

    }

    /**
    * Change the current select tab
    * @param event 
    * @param newValue 
    */
    function handleChangeTabs(event: React.SyntheticEvent, newValue: Tabs) {
        setCurrentTab(newValue);
    }

    /**
     * handles the close action of the dialog
     */
    function handleClose() {
        setCurrentTab(Tabs.NAME);
        setIssueLoading("loaded");
        setContractName("");
        setOpen(false);
    }

    /**
     * Handle the step order of the tabs
     */
    function handleGoFoward() {
        switch (currentTab) {
            case Tabs.NAME:
                setCurrentTab(Tabs.CONFIRMATION)
                break;
            case Tabs.CONFIRMATION:
                setCurrentTab(Tabs.TAGS)
                break;
            case Tabs.TAGS:
                setCurrentTab(Tabs.EMAIL)
                break;
            case Tabs.EMAIL:
                setCurrentTab(Tabs.REVIEW)
                break;
        }
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
            <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"flex-end"} width={"98vw"}>
                <IconButton onClick={handleClose} >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ width: "98vw" }} >
                {
                    issueLoading === "loaded"
                        ?
                        <>
                            <TabContext value={currentTab}>
                                <TabList centered onChange={(e, tab) => handleChangeTabs(e, tab)} >
                                    <Tab value={Tabs.NAME} label="Nome do modelo" icon={<Description />} />
                                    <Tab value={Tabs.CONFIRMATION} label="Confirmação de Leitura" icon={<Check />} disabled={issueDTO.name.length <= 0} />
                                    <Tab value={Tabs.TAGS} label="Tags do modelo" icon={<Sell />} disabled={issueDTO.name.length <= 0} />
                                    <Tab value={Tabs.EMAIL} label="Personalizar e-mails" icon={<Email />} disabled={issueDTO.name.length <= 0} />
                                    <Tab value={Tabs.REVIEW} label="Revisão de dados" icon={<Preview />} disabled={issueDTO.name.length <= 0} />
                                </TabList>
                                <TabPanel value={Tabs.NAME}>
                                    <Box sx={{ m: 2, width: "100%", height: "100%" }}>
                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                            <InsertDriveFileOutlined color="primary" sx={{ fontSize: "32px" }} />
                                            <Typography sx={{ ml: 2 }} >Nome do contrato</Typography>
                                        </Box>
                                        <TextField
                                            fullWidth
                                            label="Defina um nome para este contrato"
                                            required
                                            value={contractName}
                                            onChange={(e) => handleChangeTemplateName(e.target.value)}
                                            error={issueDTO.name.length <= 0}
                                            helperText={issueDTO.name.length <= 0 ? "Campo obrigatório" : `${issueDTO.name.length} / 240`}
                                            sx={{
                                                mb: 3
                                            }}
                                        />

                                        <Box>
                                            <Typography variant="h6" >Pré-visualização de E-mail</Typography>
                                            <Box sx={{ mt: 2, maxHeight: "375px", overflow: "auto" }} >
                                                <div style={{ width: "700px", backgroundColor: "#fff", padding: "2rem", borderRadius: "15px", margin: "2rem auto", boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.4)" }}>
                                                    <div style={{ textAlign: "center" }}>
                                                        <div style={{ marginBottom: "2rem" }}>
                                                            <img src={interfaceTheme.logoUrl} alt="Cartorizi" style={{ width: "150px" }} />
                                                        </div>
                                                        <h1 style={{ color: interfaceTheme.primaryColor }}>Assinatura do contrato: {issueDTO.name}</h1>
                                                        <p>Olá, seu contrato com a <b>{organization}</b> foi emitido.</p>
                                                        <p>
                                                            Você foi adicionado como <b>{issueDTO.contractParties[0] ? issueDTO.contractParties[0].role : "Contratante"}</b> do contrato
                                                            <b>{issueDTO.name}</b>, para visualizar o seu contrato clique no botão abaixo.
                                                        </p>
                                                        <p style={{ margin: "36px" }}>
                                                            <a href="#" style={{ backgroundColor: interfaceTheme.secondaryColor, color: "white", padding: "8px 16px", borderRadius: "4px", textDecoration: "none" }}>
                                                                Clique aqui para ver o contrato
                                                            </a>
                                                        </p>
                                                        <div v-if="issueMailTemplate" v-html="issueMailTemplate.mailTemplate"
                                                            style={{ textAlign: "start" }}></div>
                                                        <hr style={{ margin: "16px" }} />
                                                        <p><b>Caso não reconheça ou não queira assinar este contrato, Apenas ignore-o. Sem o seu
                                                            consentimento o
                                                            contrato não terá nenhum valor jurídico.</b></p>
                                                        <p>Se tiver problemas ou em caso de dúvidas <a href="#">contate nosso
                                                            suporte</a>.
                                                        </p>
                                                        <hr style={{ margin: "16px" }} />
                                                        <h4 style={{ color: "#d4a6fc" }}>Sobre o Cartorizi</h4>
                                                        <p style={{ color: "#a1a1a1", fontSize: "12px" }} >
                                                            A Cartorizi é uma plataforma de contratos digitais que permite a criação, assinatura
                                                            e
                                                            gerenciamento de
                                                            documentos e acordos de forma rápida e segura. Com a Cartorizi, é possível
                                                            automatizar
                                                            processos e garantir
                                                            a validade jurídica dos contratos, já que todas as etapas são realizadas de maneira
                                                            digital
                                                            e registradas em
                                                            blockchain. Além disso, a plataforma oferece uma interface intuitiva e fácil de
                                                            usar,
                                                            tornando o processo de
                                                            criação de contratos acessível para usuários com pouco conhecimento técnico. Com a
                                                            Cartorizi, é possível
                                                            economizar tempo e dinheiro, além de garantir a segurança e a validade dos
                                                            contratos.
                                                        </p>
                                                        <h4 style={{ color: "#d4a6fc" }}>Por quê assinaturas digitais são confiáveis?</h4>
                                                        <p style={{ color: "#a1a1a1", fontSize: "12px" }}>
                                                            A Cartorizi utiliza hashs para garantir a autenticidade de seus documentos. As
                                                            assinaturas
                                                            com hash são
                                                            consideradas confiáveis porque utilizam uma função matemática para gerar um resumo
                                                            único
                                                            e
                                                            imutável do
                                                            documento original. Esse resumo, chamado de "hash", é gerado a partir do conteúdo do
                                                            documento e serve como
                                                            uma "assinatura digital" do mesmo. Quando um documento é assinado com hash, o
                                                            assinante
                                                            gera
                                                            o hash do
                                                            documento original e o assina digitalmente, confirmando sua autenticidade. Se o
                                                            documento
                                                            for alterado
                                                            posteriormente, o hash gerado será diferente do original e a assinatura tornar-se-á
                                                            inválida. Desta forma,
                                                            as assinaturas com hash garantem a integridade e a autenticidade dos documentos,
                                                            tornando-os
                                                            confiáveis e
                                                            seguros.
                                                        </p>
                                                    </div>
                                                </div>
                                            </Box>
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={Tabs.CONFIRMATION}>
                                    <Box sx={{ m: 2, width: "100%", height: "100%" }} >
                                        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                            <CheckCircleOutline color="primary" sx={{ fontSize: "24px", mr: 1 }} />
                                            <Typography>Confirmação de Leitura</Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                            <Checkbox checked={readConfirmation} onChange={() => setReadConfirmation(!readConfirmation)} />
                                            <Typography>Os participantes do contrato terão que confirmar a leitura do documento.</Typography>
                                        </Box>

                                        {
                                            readConfirmation
                                                ?
                                                <Box>
                                                    <Select defaultValue={1} sx={{ mb: 2 }}>
                                                        <ListItem value={1}>Confirmação de leitura de cláusulas.</ListItem>
                                                    </Select>
                                                    <Typography>Digite quais cláusulas necessitam que os participantes confirmem a leitura.</Typography>
                                                    <Typography sx={{ fontWeight: 600, fontSize: "14px", mb: 2 }}>Separe as cláusulas por ';'</Typography>
                                                    <TextareaAutosize
                                                        style={{ width: "600px", border: "1px solid #DCDFE6", borderRadius: "4px", fontFamily: "Noto Sans", padding: "8px" }}
                                                        placeholder="Cláusula 1; Cláusula 2; Cláusula 3; ...etc"
                                                        value={clausesToConfirm}
                                                        onChange={(e) => setClausesToConfirm(e.target.value)}
                                                        minRows={3} />
                                                </Box>
                                                :
                                                <></>
                                        }
                                    </Box>
                                </TabPanel>
                                <TabPanel value={Tabs.TAGS} >
                                    <Box sx={{ m: 2, width: "100%", height: "100%" }}>
                                        <Box display={"flex"} alignItems={"center"}>
                                            <SellOutlined color="primary" sx={{ fontSize: "32px" }} />
                                            <Typography sx={{ ml: 2 }} >Tags do contrato</Typography>
                                        </Box>
                                        <Typography variant="subtitle2" >Crie e gerencie suas tags do contrato. <b>(Máximo de 5 tags por modelo)</b></Typography>
                                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", mt: 2 }}>
                                            {
                                                tags.map(t => (
                                                    <Box key={t.tagName} sx={{ display: "flex", alignItems: "center", backgroundColor: "primary.main", m: 1, borderRadius: "36px", width: "156px", justifyContent: "end", height: "36px", boxShadow: 3 }}>
                                                        <Tooltip title={t.tagName}>
                                                            <Typography sx={{ fontSize: "12px", color: "#fff", padding: "6px 16px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{t.tagName}</Typography>
                                                        </Tooltip>
                                                        <Button onClick={() => {
                                                            const updatedTags = tags.filter((tag) => t.tagName !== tag.tagName);
                                                            setTags(updatedTags);
                                                        }} startIcon={<Close sx={{ color: "#fff" }} />} />
                                                    </Box>))
                                            }
                                            <Box sx={{ width: "156px", m: 1 }}>
                                                {
                                                    tags.length < 5
                                                        ?
                                                        <TagsAdder
                                                            onTagAdded={handleAddTags}
                                                        />
                                                        :
                                                        <></>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={Tabs.EMAIL}>
                                    <Box sx={{ m: 2, width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <EmailEditor interfaceTheme={interfaceTheme} mailTemplates={mailTemplates} updateMailTemplates={handleUpdateMailTemplates} />
                                    </Box>
                                </TabPanel>
                                <TabPanel value={Tabs.REVIEW}>
                                    <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                        <Description color="primary" sx={{ fontSize: "32px" }} />
                                        <Typography sx={{ ml: 2 }} >Revisão dos dados do modelo</Typography>
                                    </Box>
                                    <Box sx={{ m: 2, width: "100%", height: "100%" }}>
                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                            <Group color="primary" sx={{ fontSize: "32px" }} />
                                            <Typography sx={{ ml: 2 }} >Participante(s)</Typography>
                                        </Box>
                                        <Grid container sx={{ mb: 6 }} spacing={3}>
                                            {
                                                issueDTO.contractParties.map(party => (
                                                    <Grid item xs={6} md={4} lg={3} key={party.role}>
                                                        <Box sx={{ mb: 3, p: 2, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px" }}>
                                                            <Box display={"flex"} alignItems={"center"} sx={{ mb: 2 }}>
                                                                {
                                                                    party.contractPartyIdentificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                                                        ?
                                                                        <AlternateEmail color="primary" />
                                                                        :
                                                                        <Business color="primary" />
                                                                }
                                                                <Typography sx={{ ml: 2 }} >{party.role}</Typography>
                                                            </Box>
                                                            <Typography variant="subtitle1">{getPartyIdentification(party)}</Typography>
                                                        </Box>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        {
                                            issueDTO.billingData.length > 0
                                                ?
                                                <>
                                                    <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                                        <AttachMoney color="primary" sx={{ fontSize: "32px" }} />
                                                        <Typography sx={{ ml: 2 }} >Cobrança(s) cadastrada(s) </Typography>
                                                    </Box>
                                                    <Grid container sx={{ mb: 6 }} spacing={3}>
                                                        {
                                                            issueDTO.billingData.map(b => (
                                                                <Grid item xs={12} md={6} lg={3}>
                                                                    <Box sx={{ mb: 3, p: 2, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px" }}>
                                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                            {
                                                                                billTypes.filter(bType => bType.value === b.billingPeriodType).map(bType => (
                                                                                    <Tooltip title={bType.label}>
                                                                                        {bType.icon}
                                                                                    </Tooltip>
                                                                                ))
                                                                            }
                                                                            <Typography sx={{ ml: 2 }} >{b.name}</Typography>
                                                                        </Box>
                                                                        <Typography sx={{ my: 3 }} >Valor total: <b>R${SupportedCurrenciesValue.BRL.fromSubunitToRegionValue(b.value.value)}</b></Typography>
                                                                        <Typography>Inicio do pagamento: <b>{moment(b.initialBillingPeriod).format("DD/MM/YYYY")}</b></Typography>
                                                                        {
                                                                            issueDTO.billingData.filter(bill => bill.name === b.name).map(bill => {
                                                                                if (bill.billingPeriodType === BillingPeriodType.INSTALLMENTS) {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography sx={{ my: 3 }}>Tipo: <b>Parcelado</b></Typography>
                                                                                            <Typography>
                                                                                                Em <b>{b.numberOfInstallments}</b> parcela(s) de
                                                                                                <b>R${(SupportedCurrenciesValue.BRL.fromSubunitToRegionValue(b.value.value) / b.numberOfInstallments).toFixed(2)}</b>
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                } else if (bill.billingPeriodType === BillingPeriodType.RECURRING) {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography sx={{ mt: 3 }}>Tipo: <b>Recorrent</b></Typography>
                                                                                        </>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography sx={{ mt: 3 }}>Tipo: <b>Único</b></Typography>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </>
                                                :
                                                <></>
                                        }
                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                            <CalendarMonth color="primary" sx={{ fontSize: "32px" }} />
                                            <Typography sx={{ ml: 2 }} >Período de Vigência</Typography>
                                        </Box>
                                        <Grid container sx={{ mb: 6 }} spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <Box sx={{ mb: 3, p: 2, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px" }}>
                                                    <Box display={"flex"} alignItems={"center"} sx={{ mb: 2 }}>
                                                        <Today color="primary" />
                                                        <Typography sx={{ ml: 2 }} >Data Inicial</Typography>
                                                    </Box>
                                                    {
                                                        issueDTO.validityPeriod.startDatePolicyType === StartDatePolicyType.STARTS_WHEN_SIGNED_BY_ALL_PARTIES
                                                            ?
                                                            <Typography>A data de início do período de vigência deste contrato será definida após a assinatura de todos os participantes.</Typography>
                                                            :
                                                            <Typography>{issueDTO.validityPeriod.startDate ? moment(issueDTO.validityPeriod.startDate).format("DD/MM/YYYY") : ""}</Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box sx={{ mb: 3, p: 2, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px" }}>
                                                    <Box display={"flex"} alignItems={"center"} sx={{ mb: 2 }}>
                                                        <Event color="primary" />
                                                        <Typography sx={{ ml: 2 }} >Data Final</Typography>
                                                    </Box>
                                                    {
                                                        issueDTO.validityPeriod.endDatePolicyType === EndDatePolicyType.UNDETERMINED
                                                            ?
                                                            <Typography>
                                                                Este contrato não possui uma data de termino do período de vigência, então seu termino seguirá de acordo com as leis da região de emissão do contrato.
                                                            </Typography>
                                                            :
                                                            issueDTO.validityPeriod.endDatePolicyType === EndDatePolicyType.SET_AUTOMATICALLY_AFTER_START_DATE
                                                                ?
                                                                <Typography>
                                                                    A data final será fixado após a data de início baseando-se no tempo definido no modelo deste contrato.
                                                                </Typography>
                                                                :
                                                                <Typography>{issueDTO.validityPeriod.endDate ? moment(issueDTO.validityPeriod.endDate).format("DD/MM/YYYY") : ""}</Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </TabPanel>
                            </TabContext>
                        </>
                        :
                        issueLoading === "success"
                            ?
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", height: "100%" }}>
                                <CheckCircle color="success" sx={{ fontSize: "64px" }} />
                                <Typography variant="h6" sx={{ mt: 3 }} >Contrato emitido com sucesso</Typography>
                                {
                                    contract
                                        ?
                                        <Box sx={{ display: "flex", alignItems: "center", mt: 6 }}>
                                            <Button variant="contained" onClick={() => window.location.replace(`${window.location.origin}?srid=${contract.id}&srt=issued-contract`)}  >Ver o contrato emitido</Button>
                                            <Button variant="contained" onClick={() => navigate(`/registry/${contract.id}`)} sx={{ mx: 3 }} >Ir para a tela de assinatura do contrato</Button>
                                            <Button variant="contained" onClick={() => navigate(`/contracts`)} >Ver todos os contratos</Button>
                                        </Box>
                                        :
                                        <></>
                                }
                            </Box>
                            :
                            issueLoading === "error"
                                ?
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", height: "100%" }}>
                                    <Error color="error" sx={{ fontSize: "64px" }} />
                                    <Typography variant="h6" sx={{ mt: 3 }} >Ocorreu um erro ao emitir o contrato</Typography>
                                </Box>
                                :
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                                    <CircularProgress color="primary" sx={{ fontSize: "64px" }} />
                                </Box>
                }
            </DialogContent>
            <DialogActions sx={{ mb: 3, width: "98vw" }} >
                {
                    issueLoading === "loaded"
                        ?
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} >
                            <Button variant="text" onClick={handleClose} >Cancelar</Button>
                            {
                                currentTab !== Tabs.REVIEW
                                    ?
                                    <Box>
                                        <Button variant="text" disabled={issueDTO.name.length <= 0} sx={{ mr: 2 }} onClick={issueContract} >Emitir</Button>
                                        <Button variant="contained" endIcon={<ArrowForward />} disabled={issueDTO.name.length <= 0} onClick={handleGoFoward} >Próximo passo</Button>
                                    </Box>
                                    :
                                    <Button variant="contained" disabled={issueDTO.name.length <= 0} onClick={issueContract} >Emitir</Button>
                            }
                        </Box>
                        :
                        <></>
                }
            </DialogActions>
        </Dialog>
    )
}

export default FileIssueDataReview;