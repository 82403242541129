import { useContext, useEffect, useState } from "react";
import { Box, Button, DialogActions, Drawer, Fab, Tooltip, Typography, LinearProgress, IconButton, useMediaQuery } from "@mui/material";
import { LoadingButton, LoadingSpeedDial } from "../../components/LoadingButton";
import { DataGrid, GridColumns, GridRenderCellParams, GridSelectionModel, ptBR } from '@mui/x-data-grid';
import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../utils/ErrorWrapper";
import { Pagination } from "../../models/pagination";
import TeamsService from "../../services/teams";
import { Add, Star, StarOutline, SupervisedUserCircle, SupervisedUserCircleOutlined } from "@mui/icons-material";
import { tableStyle } from "../../theme"
import { AccountRoles, Subuser, UserAccount } from "../../models/user";
import AddMembers from "./AddMember";
import AuthenticationContext from "../../contexts/authentication";
import { RolesContext } from "../../contexts/roles";
import { OrganizationTeam } from "../../models/teams";

interface MembersProps extends React.ComponentProps<any> {
    open: boolean,
    team: OrganizationTeam | null,
    close: () => void,
    onSuccess: (importedTeam: OrganizationTeam) => void,
    updateQuota: () => void
}

const TeamMembers = (props: MembersProps) => {

    const { open, close, team, onSuccess, updateQuota } = props;

    //Context props
    const auth = useContext(AuthenticationContext);
    const userRoles = useContext(RolesContext);
    const notification = useAppDispatch();
    const isSmallResolution = useMediaQuery("(max-height:800px)")

    //Utils - Indicates the maximum amount of members a organization team can have
    const MAX_ORGANIZATION_TEAM_MEMBERS = 999;

    //Utils - Indicated if authenticated user has required role
    const userHasRequiredRole = userRoles.assertIsOrganizationOrAdministratorOr(auth, AccountRoles.USER_AND_TEAMS_MANAGER);

    //States
    const [showAddMembers, setShowAddMembers] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const [selectedMembers, setSelectedMembers] = useState<GridSelectionModel>([]);

    const [loading, setLoading] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)

    const [page, setPage] = useState(1)
    const [members, setMembers] = useState<UserAccount[]>([]);
    const [pagination, setPagination] = useState<Pagination | null>(null);
    const [supervisors, setSupervisors] = useState<UserAccount[]>([]);
    const [paginationSupervisors, setPaginationSupervisors] = useState<Pagination | null>(null);

    //Fetch members pagination and data when drawer opens
    useEffect(() => { if (team) fetchPagination() }, [open])

    //Observe delete button to hide when there are no members selected from list
    useEffect(() => { setShowDelete(selectedMembers.length === 0 ? false : true) }, [selectedMembers])

    //Reset states values when drawer is closed
    useEffect(() => { resetStates() }, [team, close])

    //Fetch members when page or limit value changes 
    useEffect(() => { fetchMembers() }, [page])

    /**
     * Fetch pagination data of team members
     */
    const fetchPagination = () => {
        if (!open || !team) return;
        TeamsService.membersPagination(team.guid)
            .then(fetchedPagination => {
                setPagination(fetchedPagination ? fetchedPagination : null)

                //Fetch members after pagination is fetched
                fetchMembers();
            })
            .catch(e => notification(show({
                type: 'error',
                message: new ErrorWrapper(e).message
            })))

        TeamsService.supervisorsPagination(team.guid)
            .then(fetchPagination => {
                setPaginationSupervisors(fetchPagination ? fetchPagination : null)

                fetchSupervisors();
            })
            .catch(e => notification(show({
                type: 'error',
                message: new ErrorWrapper(e).message
            })))
    }

    /**
     * Fetch team members using pagination
     * @returns 
     */
    const fetchMembers = () => {

        //Return if there is no team selected
        if (!team) return;

        setLoading(true)

        TeamsService.fetchMembers(team.guid, page)
            .then(fetchedMembers => setMembers(fetchedMembers ? fetchedMembers : []))
            .catch(e => notification(show({
                type: 'error',
                message: new ErrorWrapper(e).message
            })))
            .finally(() => setLoading(false))
    }

    const fetchSupervisors = () => {
        if (!team) return;

        setLoading(true);

        TeamsService.fetchSupervisors(team.guid, page)
            .then(fetchedMembers => setSupervisors(fetchedMembers ? fetchedMembers : []))
            .catch(e => notification(show({
                type: 'error',
                message: new ErrorWrapper(e).message
            })))
            .finally(() => setLoading(false))
    }

    /**
     * Update the team and remove the selected members
     */
    const updateTeam = () => {
        //Return if user doesn't have the required role to update a organization team 
        if (!userHasRequiredRole || !team) return;

        //Remove the selected members from the list of the current members
        var membersUuid = members.map((m: UserAccount) => m.uuid);
        membersUuid = membersUuid.filter(val => !selectedMembers.includes(val));

        let supervisorsUuid = supervisors.map(s => s.uuid);
        supervisorsUuid = supervisorsUuid.filter(val => !selectedMembers.includes(val));

        setLoadingUpdate(true)
        TeamsService.update(team.guid, {
            name: team.name,
            members: membersUuid,
            supervisors: supervisorsUuid
        })
            .then(() => {
                //Reset states
                resetStates();

                //Update members state removing the selected members from it
                setMembers(members.filter(m => !selectedMembers.includes(m.uuid)))

                setSupervisors(supervisors)

                //Show notification
                notification(show({ type: 'success', message: 'Time atualizado' }))
            })
            .catch(err => {
                //Show notification error
                const e = new ErrorWrapper(err)
                notification(show({
                    type: 'error',
                    message: e.httpStatus === 422 ? `Dados inválidos` :
                        e.httpStatus === 403 ? `Ação não autorizada` :
                            `Ocorreu um erro: ${e.message}`

                }))
            })
            .finally(() => setLoadingUpdate(false))
    }

    /**
     * Handle new members that were just added to the team
     * @param newMembers 
     */
    const handleNewMembers = (newMembers: UserAccount[]) => {
        //Remove duplicates
        const membersWithNoDuplicates = [...newMembers, ...members].filter((value: UserAccount, index: number, self: any) =>
            index === self.findIndex((t: UserAccount) => (t.uuid === value.uuid)))

        //Set members with new data
        setMembers(membersWithNoDuplicates);
    }
    
    /**
     * Handle add a new team supervisor
     * @param newSupervisor 
     */
    const handleAddSupervisor = (newSupervisor: UserAccount) => {
        const isSupervisor = supervisors.find(s => s.uuid === newSupervisor.uuid);

        if (isSupervisor) return;

        setSupervisors([...supervisors, newSupervisor])
    }

    /**
     * Handle remove selected supervisor
     * @param supervisor 
     */
    const handleRemoveSupervisor = (supervisor: UserAccount) => {
        const isSupervisor = supervisors.find(s => s.uuid === supervisor.uuid);

        if (!isSupervisor) return;

        setSupervisors(supervisors.filter(s => s.uuid !== supervisor.uuid))
    }

    /**
     * Reset states to initial values
     */
    const resetStates = () => {
        setSelectedMembers([]);
        setMembers([]);
        setPagination(null)
        setPage(1)
        setShowDelete(false)
        setShowAddMembers(false)
        setSupervisors([])
    }

    //Team members grid column def
    const membersColumnDef: GridColumns = [
        { field: 'preferredName', headerName: 'Nome' },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'supervisor', headerName: "Supervisor", renderCell: (params: GridRenderCellParams) => {

            const isSupervisor = supervisors.find(s => s.uuid === params.row.uuid);

            return (
                <>
                    {
                        isSupervisor
                            ?
                            <IconButton
                                onClick={() => handleRemoveSupervisor(params.row as UserAccount)}
                            >
                                <Star color="primary" />
                            </IconButton>
                            :
                            <IconButton
                                onClick={() => handleAddSupervisor(params.row as UserAccount)}
                            >
                                <StarOutline color="secondary" />
                            </IconButton>
                    }
                </>
            )
        }}
    ];

    /**
     * Return default props used in pagination
     * @param pagination 
     * @returns 
     */
    function paginationProps(pagination: Pagination | null): Object {
        //Return empty object if pagination is null
        if (pagination === null) return {}
        return {
            rowCount: pagination?.totalRecords,
            pageSize: pagination?.recordsPerPage,
            paginationMode: 'server',
            page: page - 1,
            onPageChange: (page: number) => setPage(page + 1)
        }
    }

    return (
        <Drawer anchor='right' open={open} onClose={close} sx={{ '& .MuiDrawer-paper': { width: { sm: 550, xs: 350 }, p: { sm: 5, xs: 3 } , height: "100%", overflow: "auto"} }}>
            {
                showAddMembers ?
                    <AddMembers team={team}
                        members={members.map((m: UserAccount) => m.uuid)}
                        close={() => setShowAddMembers(false)}
                        onSuccess={handleNewMembers} 
                        supervisors={supervisors}    
                        updateQuota={updateQuota}

                    />
                    :
                    <Box height='inherit'>
                        <Typography variant='h6' color='primary.main'>Membros de <strong>{team?.name}</strong></Typography>
                        {
                            !userHasRequiredRole ? null :
                                <Box textAlign='end'>
                                    <Tooltip placement='top' title='Adicionar membros'>
                                        <Fab color='primary' aria-label='add' sx={{ width: 45, height: 45 }} onClick={() => setShowAddMembers(true)}><Add /></Fab>
                                    </Tooltip>
                                </Box>
                        }
                        
                        <Box height={650} width={1} mt={3} pb={3}>
                            <DataGrid
                                components={{
                                    LoadingOverlay: LinearProgress,
                                    NoRowsOverlay: () => <Typography mt={3} textAlign='center' color='text.secondary'> Nenhuma informação</Typography>
                                }}
                                checkboxSelection={userHasRequiredRole ? true : false}
                                disableSelectionOnClick
                                onSelectionModelChange={(sel) => setSelectedMembers(sel)}
                                selectionModel={selectedMembers}
                                sx={tableStyle}
                                disableColumnMenu
                                rowsPerPageOptions={[20]}
                                getRowId={(member: UserAccount) => member.uuid}
                                rows={members}
                                columns={membersColumnDef}
                                loading={loading}
                                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                {...paginationProps(pagination)}
                            />
                            <LoadingSpeedDial loading={loadingUpdate} show={showDelete} onClick={updateTeam} />
                        </Box>
                        <DialogActions sx={{ mt: 3, pb: 15 }}>
                            <Button onClick={close}>Voltar</Button>
                            <LoadingButton loading={loadingUpdate} onClick={updateTeam} variant="contained" >Salvar</LoadingButton>
                        </DialogActions>
                        
                    </Box>
            }
        </Drawer>
    )
}

export default TeamMembers;