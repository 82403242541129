import { AccessTime, ArrowDropDown, Close, CorporateFare, DocumentScanner, EventRepeat, FilterAlt, FilterList } from "@mui/icons-material";
import { Autocomplete, Box, Button, CircularProgress, Divider, Drawer, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Radio, RadioGroup, Select, Skeleton, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';
import { SxProps } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import 'moment/locale/pt-br'
import React, { useEffect, useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import PayWallDialog from "../../../components/PayWallDialog";
import { DigitalContractTemplate } from "../../../models/contracts";
import DigitalContractService from "../../../services/contracts";
import "./ContractsFinance.css";
import ContractsAnalytics from "./dashboards/ContractsAnalytics";
import OrganizationFinanceChargesAnalytics from "./dashboards/OrganizationFinanceChargesAnalytics";
import TagsService, { TagsModel } from "../../../services/tags";
import TagsSearch from "../../../components/TagsSearch";
import OrganizationSubscriptionChargesAnalytics from "./dashboards/OrganizationSubscriptionChargesAnalytics";

interface AnalyticalOperation {
    type: "organization-finance-charges-analytics" | "contracts-analytics" | "organization-subscription-charges-analytics"
    title: string,
    icon: JSX.Element,
    description?: string,
}

const StyledSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-select': {
        padding: theme.spacing(1),
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiAutocomplete-inputRoot': {
        padding: theme.spacing(1),
    },
    '& .MuiInput-root:before': {
        border: 'none',
    },
    '& .MuiInput-root:hover:before': {
        border: 'none',
    },
}));

export const FinanceAnalyticalOperations: AnalyticalOperation[] = [
    {
        type: "organization-finance-charges-analytics",
        title: "Dados Financeiros da Organização",
        description: "Busca dados de entradas financeiras da organização",
        icon: <CorporateFare />
    },
    {
        type: "contracts-analytics",
        title: "Dados Financeiros de Contratos",
        description: "Busca entradas financeiras baseando-se em dados financeiros de contratos (em construção, assinados e cancelados)",
        icon: <DocumentScanner />
    },
    {
        type: "organization-subscription-charges-analytics",
        title: "Dados de cobranças recorrentes",
        description: "Busca dados de cobranças recorrentes da organização",
        icon: <EventRepeat />
    }
]

interface ContractsFinancieViewProps extends React.ComponentProps<any> {
    financialModuleEnabled: Boolean
}

interface DateInterval {
    startDate: Date,
    endDate: Date,
}

interface DateIntervalSelection {
    label: string,
    interval: DateInterval | null,
}

const ContractsFinanceView = (props: ContractsFinancieViewProps): JSX.Element => {
    const isMobile = useMediaQuery("(max-width:900px)");
    
    moment.locale("pt-br");

    const datePickerRef = useRef<HTMLDivElement>(null);

    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Analytics Financeiro"
    }, []);

    const DateIntervalSelections: DateIntervalSelection[] = [
        {
            label: "Últimos 30 dias",
            interval: {
                startDate: moment().subtract(30, "days").toDate(),
                endDate: new Date(),
            }
        },
        {
            label: "Últimos 60 dias",
            interval: {
                startDate: moment().subtract(60, "days").toDate(),
                endDate: new Date(),
            }
        },
        {
            label: "Últimos 90 dias",
            interval: {
                startDate: moment().subtract(90, "days").toDate(),
                endDate: new Date(),
            }
        },
        {
            label: "Personalizado",
            interval: null
        },
    ]

    //presets
    const startDateMoment = moment().subtract(30, "days");
    const endDateMoment = moment();

    // states
    const [digitalContractTemplates, setDigitalContractTemplates] = useState<DigitalContractTemplate[]>([]);
    const [selectedDigitalContractTemplate, setSelectedDigitalContractTemplate] = useState<DigitalContractTemplate | null>(null);
    const [selectedAnalyticalOperation, setSelectedAnalyticalOperation] = useState(FinanceAnalyticalOperations[0]);
    const [selectedTag, setSelectedTag] = useState<TagsModel | null>(null);
    const [tagFilterMenuAnchor, setTagFilterMenuAnchor] = useState<null | HTMLElement>(null);
    const [startDate, setStartDate] = useState<Date>(startDateMoment.toDate());
    const [endDate, setEndDate] = useState<Date>(endDateMoment.toDate());
    const [loading, setLoading] = useState(false);

    const [openPayWallDialog, setOpenPayWallDialog] = useState(false)
    const [enableRedirectAfterCloseDialog, setEnableRedirectAfterCloseDialog] = useState(false)

    const [dateIntervalMenuAnchor, setDateIntervalMenuAnchor] = useState<null | HTMLElement>(null);
    const refSelectedDateInterval = DateIntervalSelections[0];
    const [selectedDateInterval, setSelectedDateInterval] = useState(refSelectedDateInterval);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [filterTextValue, setFilterTextValue] = useState('');

    const [selectedTags, setSelectedTags] = useState<TagsModel[]>([]);
    const [filterAndAndOr, setFilterAndAndOr] = useState("E");

    // effect that load all digital contract templates from the organization
    useEffect(() => {
        fetchAllDigitalContractTemplates();

        if (!props.financialModuleEnabled) {
            handleOpenPayWallDialog()
        }
    }, []);
    
    useEffect(() => {
        if (datePickerRef.current) {
            const targetAdornmentButton = datePickerRef.current.firstChild;
            if (targetAdornmentButton) {
                (targetAdornmentButton as HTMLButtonElement).click();
            }
        }
    }, [startDate])

    /**
     * Render an analytics dashboard component depending on the selectedAnalyticalOperation.type
     * @returns 
     */
    function AnalyticsDashboard(): JSX.Element {
        switch (selectedAnalyticalOperation.type) {
            case "organization-finance-charges-analytics":
                return <OrganizationFinanceChargesAnalytics digitalContractTemplate={selectedDigitalContractTemplate} startDate={startDate} endDate={endDate} digitalContractTemplates={digitalContractTemplates} />
            case "contracts-analytics":
                return (
                    <ContractsAnalytics
                        digitalContractTemplate={selectedDigitalContractTemplate}
                        startDate={startDate}
                        endDate={endDate}
                        digitalContractTemplates={digitalContractTemplates}
                        tags={selectedTags}
                    />
                );
            case "organization-subscription-charges-analytics":
                return (
                    <OrganizationSubscriptionChargesAnalytics
                        digitalContractTemplate={selectedDigitalContractTemplate}
                        startDate={startDate}
                        endDate={endDate}
                        digitalContractTemplates={digitalContractTemplates}
                    />
                );
        }
    };

    async function fetchAllDigitalContractTemplates(): Promise<void> {
        // fetch more templates on an loop
        let hasMoreContentToFetch = true;
        let page = 1;
        let digitalContractTemplates: DigitalContractTemplate[] = [];
        while (hasMoreContentToFetch) {
            setLoading(true);
            try {
                const response = await DigitalContractService.fetchTemplates(page++);
                // interrupt the loop if no data is returned from the API
                if (!response) {
                    hasMoreContentToFetch = false;
                    break;
                }
                // add the template into the state control
                digitalContractTemplates = [...digitalContractTemplates, ...response];
            } catch (error) {
                throw error;
            }

        }
        setDigitalContractTemplates(digitalContractTemplates);
        setLoading(false);
    }

    function handleClosePayWallDialog() {
        setOpenPayWallDialog(false);
        setEnableRedirectAfterCloseDialog(true)


    }

    function handleOpenPayWallDialog() {
        setOpenPayWallDialog(true)
        setEnableRedirectAfterCloseDialog(false)
    }

    const handleAnalyticalOperationSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedType = event.target.value as string;
        const selectedAo = FinanceAnalyticalOperations.find((ao) => ao.type === selectedType);
        setSelectedAnalyticalOperation(selectedAo as AnalyticalOperation);
    }

    const handleDigitalContractTemplateSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedId = event.target.value;
        const selectedTemplate = digitalContractTemplates.find((template) => template.id === selectedId);
        setSelectedDigitalContractTemplate(selectedTemplate || null);

    };

    function handleDateIntervalsRadioGroupOnChange(event: React.ChangeEvent<HTMLInputElement>, value: string): void {
        const selectedDateInterval = DateIntervalSelections.find(d => d.label === value);
        if (selectedDateInterval) {
            // update selected date interval state
            setSelectedDateInterval(selectedDateInterval);

            // if the interval is defined, set it to the start and end date state. This will trigger the contracts fetch function by the useEffect
            if (selectedDateInterval.interval) {
                setStartDate(selectedDateInterval.interval.startDate);
                setEndDate(selectedDateInterval.interval.endDate);
            }
        }
        else {
            throw new Error("Application could not resolve selected date interval selection: " + value);
        }
    }

    function handleFilterAndAndOr(value: string){
        setFilterAndAndOr(value);
    }

    const drawerClose = () => {
        setIsDrawerOpen(false);
        setFilterTextValue(`
                Análise: ${selectedAnalyticalOperation.title ? selectedAnalyticalOperation.title : "Dados Financeiros da Organização"}<br />
                Filtro: ${selectedDigitalContractTemplate?.name ? selectedDigitalContractTemplate?.name : "Geral"}<br />
                Período: ${new Date(startDate).toLocaleDateString()}-${new Date(endDate).toLocaleDateString()}
                `)
    }

    const handleTagSelected = (tags: TagsModel[]) => {
        //setSelectedTag(tag);
        setSelectedTags(tags);
    };

    const filterMenu = (
        <Grid container columns={{ xs: 12, md: 12 }} sx={{ mb: "20px", border: "1px solid rgb(230, 230, 230)", alignItems: "center", minHeight: "48px" }}>
            <Grid item xs={12} md={2}><Box sx={{ display: "flex", alignItems: "center" }}><FilterList sx={{ margin: "0px 12px" }} />Filtrar</Box></Grid>
            <Grid item xs={12} md={10}>
                <Grid container columns={{ xs: 12 }} sx={{ alignItems: "center", justifyContent: "flex-end" }}>

                    {/*Analysis*/}
                    <Grid item xs={12} md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <b>Análise:</b>
                        <StyledSelect
                            value={selectedAnalyticalOperation?.type}
                            onChange={(e) => handleAnalyticalOperationSelect(e as any)}
                            sx={{ overflow: "hidden", textOverflow: "ellipsis", fontSize: "12px" }}
                            renderValue={() => {
                                if (selectedAnalyticalOperation) {
                                    return (
                                        <Box>
                                            <ListItemText primary={selectedAnalyticalOperation.title} sx={{ fontSize: "12px" }} />
                                        </Box>
                                    );
                                } else {
                                    return (
                                        <Box>
                                            <ListItemText secondary="Selecione a análise" />
                                        </Box>
                                    );
                                }
                            }}
                        >
                            <InputLabel sx={{ p: 1, fontWeight: 600 }}>Selecione a análise</InputLabel>
                            <Divider sx={{ mb: 1, }} />
                            {
                                FinanceAnalyticalOperations.map(ao => (
                                    <MenuItem sx={{ display: "flex", alignItems: "center" }} key={ao.type} value={ao.type}>
                                        <Box>
                                            <ListItemText primary={ao.title} sx={{ fontSize: "12px" }} />
                                            <ListItemText secondary={ao.description} />
                                        </Box>
                                    </MenuItem>
                                ))
                            }
                        </StyledSelect>
                    </Grid>

                    {/*Template Filter*/}
                    <Grid item xs={12} md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        {loading ?
                            <List>
                                <CircularProgress size={32} sx={{ display: "block", margin: "auto" }} />
                            </List>
                            : (
                                <>
                                    <b>Modelo:</b>
                                    <StyledSelect
                                        value={selectedDigitalContractTemplate?.id || "Geral"}
                                        onChange={(e) => handleDigitalContractTemplateSelect(e as any)}
                                        label="Selecione o filtro"
                                        inputProps={{ id: "digital-contract-template-select" }}
                                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                    >
                                        <InputLabel sx={{ p: 1, fontWeight: 600 }}>Selecione o modelo</InputLabel>
                                        <Divider sx={{ mb: 1, }} />
                                        <MenuItem value="Geral">
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <ListItemText primary="Geral" />
                                            </div>
                                        </MenuItem>
                                        {digitalContractTemplates.map((template) => (
                                            <MenuItem key={template.id} value={template.id}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <ListItemText primary={template.name} sx={{ fontSize: "12px" }} />
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </StyledSelect>
                                </>
                            )}
                    </Grid>

                    {/*Tags*/}
                    <Grid item xs={12} md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <Button
                            endIcon={<ArrowDropDown />}
                            onClick={e => setTagFilterMenuAnchor(e.currentTarget)}>
                            <Typography><b>Tag</b>{selectedTag ? `: ${selectedTag.tagName}` : ""}</Typography>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={tagFilterMenuAnchor}
                            open={Boolean(tagFilterMenuAnchor)}
                            onClose={() => setTagFilterMenuAnchor(null)}
                        >
                            <TagsSearch onTagSelected={handleTagSelected} selectedTags={selectedTags} onAndAndOrChange={handleFilterAndAndOr} andAndOr={filterAndAndOr} />
                        </Menu>
                    </Grid>

                    {/*Date Interval*/}
                    <Grid item xs={12} md="auto" sx={{ maxWidth: "400px" }}>
                        <Box sx={{ display: "flex" }}>
                            <Button
                                onClick={e => setDateIntervalMenuAnchor(e.currentTarget)}>
                                Filtro por tempo <b style={{ marginLeft: 4, marginRight: 4 }}>{selectedDateInterval.label} </b> <small> ({startDate?.toLocaleDateString()} - {endDate?.toLocaleDateString()})</small>
                            </Button>
                        </Box>
                        <Menu
                            id="basic-menu"
                            anchorEl={dateIntervalMenuAnchor}
                            open={Boolean(dateIntervalMenuAnchor)}
                            onClose={() => setDateIntervalMenuAnchor(null)}
                        >
                            <FormControl sx={{ p: 2 }}>
                                <FormLabel id="intervals-radio-button-group">Intervalo de busca de contratos</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="intervals-radio-button-group"
                                    name="row-intervals-radio-button-group"
                                    onChange={handleDateIntervalsRadioGroupOnChange}
                                    value={selectedDateInterval.label}
                                >
                                    {
                                        DateIntervalSelections.map(d => (
                                            <FormControlLabel value={d.label} control={<Radio />} label={d.label} />
                                        ))
                                    }
                                </RadioGroup>
                            </FormControl>
                            {
                                // Render date pickers only if the date interval selection does not have an defined interval
                                (!selectedDateInterval.interval)
                                    ?
                                    <Box>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                                            <DatePicker
                                                label="Selecione uma data inicial"
                                                inputFormat="DD/MM/YYYY"
                                                value={startDate}
                                                onChange={(value: Moment | null) => { if (value) setStartDate(value.toDate()) }}
                                                renderInput={(params) => <TextField {...params} size='small' sx={{ mb: 2 }} />}
                                                maxDate={moment(endDate)}
                                            />
                                            <DatePicker
                                                label="Selecione uma data final"
                                                inputFormat="DD/MM/YYYY"
                                                value={endDate}
                                                onChange={(value: Moment | null) => { if (value) setEndDate(value.toDate()) }}
                                                renderInput={(params) => <TextField {...params} size='small' sx={{ ml: 2 }} />}
                                                minDate={moment(startDate)}
                                                InputAdornmentProps={{ ref: datePickerRef }}
                                    />
                                        </LocalizationProvider>
                                    </Box>
                                    :
                                    <></>
                            }
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <Box>
            {props.financialModuleEnabled ?
                <Grid container sx={{ padding: "20px" }}>
                    {
                        isMobile ? (
                            <>
                                <Button endIcon={<FilterAlt />} variant="outlined" sx={{ width: "100%", mb: "10px", mt: "10px" }} onClick={() => setIsDrawerOpen(true)}>
                                    {
                                        filterTextValue != ''
                                            ?
                                            filterTextValue.split("<br />").map((line, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        <br />
                                                    </React.Fragment>
                                                );
                                            })
                                            :
                                            "Filtro"
                                    }
                                </Button>
                                <Drawer
                                    open={isDrawerOpen}
                                    onClose={drawerClose}
                                    anchor="bottom"
                                    sx={{ borderRadius: "4px 4px 0px 0px", }}
                                >
                                    <Box sx={{ padding: "20px", height: "90vh" }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Button onClick={drawerClose}><Close /></Button>
                                            <Typography sx={{ margin: "auto", textAlign: "center", fontSize: "22px" }}>Filtro</Typography>
                                        </Box>
                                        <Divider sx={{ mb: "20px", mt: "10px" }} />
                                        {filterMenu}
                                    </Box>
                                </Drawer>
                            </>
                        )
                            :
                            (
                                filterMenu
                            )
                    }
                    <Grid xs={12}>
                        {<AnalyticsDashboard />}
                    </Grid>
                </Grid>
                :
                <Box>
                    <PayWallDialog open={openPayWallDialog} onClose={handleClosePayWallDialog} quotaName="Gestão Financeira" productName="Modulo de gestão financeira" type="module" />
                    {enableRedirectAfterCloseDialog ? <Navigate to="/contracts/create" /> : <></>}
                </Box>
            }

        </Box >
    );
}

export default ContractsFinanceView;