import { Event, Today } from "@mui/icons-material";
import { Box, FormControl, FormControlLabel, ListItemIcon, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { EndDatePolicyType, FileIssueValidityPeriod, StartDatePolicyType } from "../../../../../../../models/contracts";
import moment from "moment";

// Interface and Types
interface FileValidityPeriodProps extends React.ComponentProps<any> {
    validityPeriod: FileIssueValidityPeriod
    setValidityPeriod: (value: FileIssueValidityPeriod) => void
}

const FileValidityPeriod = (props: FileValidityPeriodProps): JSX.Element => {

    // Destructure props object
    const { setValidityPeriod, validityPeriod } = props;

    // Data states
    const [startDatePolicyType, setStartDatePolicyType] = useState<StartDatePolicyType>(validityPeriod.startDatePolicyType as StartDatePolicyType);
    const [endDatePolicyType, setEndDatePolicyType] = useState<EndDatePolicyType>(validityPeriod.endDatePolicyType as EndDatePolicyType);
    const [startDatePolicy, setStartDatePolicy] = useState<Date | null>(validityPeriod.startDate ? validityPeriod.startDate : null);
    const [endDatePolicy, setEndDatePolicy] = useState<Date | null>(validityPeriod.endDate ? validityPeriod.endDate : null);

    // useEffects
    useEffect(() => {
        updateValididtyPeriod();
    }, [startDatePolicy, startDatePolicyType, endDatePolicy, endDatePolicyType])
 
    // Functions
    function updateValididtyPeriod() {
        if (startDatePolicyType === StartDatePolicyType.SET_BY_ISSUER && !startDatePolicy) return;

        if (endDatePolicyType === EndDatePolicyType.SET_BY_ISSUER && !endDatePolicy) return;

        const updatedValidity: FileIssueValidityPeriod = {
            endDatePolicyType,
            startDatePolicyType,
            endDate:endDatePolicy ? endDatePolicy : undefined,
            startDate:startDatePolicy ? startDatePolicy : undefined
        }

        setValidityPeriod(updatedValidity);
    }

    return (
        <Box sx={{ maxHeight: "550px", overflow: "auto" }}>
            <Box sx={{ mb: 6 }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <ListItemIcon>
                        <Today sx={{ fontSize: "32px" }} />
                    </ListItemIcon>
                    <Typography variant="h6">Data Inicial</Typography>
                </Box>
                <FormControl sx={{ ml: 1, mb: 3 }}>
                    <RadioGroup
                        value={startDatePolicyType}
                        onChange={(e) => setStartDatePolicyType(e.target.value as StartDatePolicyType)}
                    >
                        <FormControlLabel value={StartDatePolicyType.STARTS_WHEN_SIGNED_BY_ALL_PARTIES} control={<Radio />} label="Quando todos os participantes assinarem" />
                        <FormControlLabel value={StartDatePolicyType.SET_BY_ISSUER} control={<Radio />} label="Definir durante a emissão do contrato" />
                    </RadioGroup>
                </FormControl>
                {
                    startDatePolicyType === StartDatePolicyType.SET_BY_ISSUER
                        ?
                        <Box sx={{ mt: 3 }}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                                <DatePicker
                                    label="Selecione a data inicial"
                                    inputFormat="DD/MM/YYYY"
                                    value={startDatePolicy}
                                    onChange={(value: Moment | null) => { if (value) setStartDatePolicy(value.toDate()) }}
                                    renderInput={(params) => <TextField {...params} fullWidth error={!startDatePolicy} helperText={!startDatePolicy ? "Campo obrigatório" : ""} />}
                                    maxDate={endDatePolicy ? moment(endDatePolicy) : undefined}
                                />
                            </LocalizationProvider>
                        </Box>
                        :
                        <></>
                }
            </Box>
            <Box>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <ListItemIcon>
                        <Event sx={{ fontSize: "32px" }} />
                    </ListItemIcon>
                    <Typography variant="h6">Data Final</Typography>
                </Box>
                <FormControl sx={{ ml: 1, mb: 3 }}>
                    <RadioGroup
                        value={endDatePolicyType}
                        onChange={(e) => setEndDatePolicyType(e.target.value as EndDatePolicyType)}
                    >
                        <FormControlLabel value={EndDatePolicyType.UNDETERMINED} control={<Radio />} label="Indetermindado" />
                        <FormControlLabel value={EndDatePolicyType.SET_BY_ISSUER} control={<Radio />} label="Definir durante a emissão do contrato" />
                    </RadioGroup>
                </FormControl>
                {
                    endDatePolicyType === EndDatePolicyType.SET_BY_ISSUER
                        ?
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                            <DatePicker
                                label="Selecione a data final"
                                inputFormat="DD/MM/YYYY"
                                value={endDatePolicy}
                                onChange={(value: Moment | null) => { if (value) setEndDatePolicy(value.toDate()) }}
                                renderInput={(params) => <TextField {...params} fullWidth error={!endDatePolicy} helperText={!endDatePolicy ? "Campo obrigatório" : ""} />}
                                minDate={startDatePolicy ? moment(startDatePolicy) : undefined}
                            />
                        </LocalizationProvider>
                        :
                        <></>
                }
            </Box>
        </Box>
    )
}

export default FileValidityPeriod;