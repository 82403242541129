import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useState } from "react";
import { downloadOriginalFile, downloadTheWatermarkAndSignatureContract } from "../models/generate-contract-blobs";
import { IssuedDigitalContract } from "../models/contracts";

interface EncryptedPDFDialogProps {
    open: boolean,
    onClose: () => void,
    originalContract: Blob | undefined,
    signatureCertificate?: Blob,
    contract: IssuedDigitalContract,
}

const EncryptedPDFDialog = (props: EncryptedPDFDialogProps): JSX.Element => {

    return (
        <Dialog maxWidth="md" open={props.open}>
            <DialogTitle>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: "20px" }}>
                        <b>Não foi possível baixar o contrato timbrado</b>
                    </Typography>
                    <Button onClick={props.onClose} ><Close /> </Button>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ mb: 2 }}>
                    O contrato utilizado para assinatura está protegido contra edição, provavelmente através de criptografia ou
                    assinatura digital feita previamente antes de adicioná-lo na plataforma.
                </Typography>
                <Typography>
                    Você pode baixar o contrato original e também o documento de garantia de assinatura do Cartorizi separadamente clicando nos botões abaixo
                </Typography>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: 2 }}>
                <Button onClick={() => downloadOriginalFile(props.originalContract, props.contract)} variant="contained">Baixar o contrato original</Button>
                <Button onClick={() => downloadTheWatermarkAndSignatureContract(props.signatureCertificate ? props.signatureCertificate : new Blob(), props.contract)} variant="contained">Baixar documento de garantia de assinatura</Button>
            </DialogActions>
        </Dialog >
    )
}

export default EncryptedPDFDialog;