import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useContext, useState } from "react";
import AuthenticationContext from "../../contexts/authentication";
import StlInterceptor from "../../contexts/stl";
import { ExternalDigitalContract } from "../../models/contracts";
import RenewAccessDialog from "../../views/authentication/RenewAccessDialog";
import { SecurityTierLevels } from "../../store/stl";
import ContractsService from "../../services/contracts";

interface OnCloseCallback {
    (contractWasDeleted : boolean) : void
}

interface DeleteDialogProps extends React.ComponentProps<any> {
    open : boolean;
    source : ExternalDigitalContract;
    onClose : OnCloseCallback;
}

const DeleteExternalContractDialog = (props: DeleteDialogProps): JSX.Element => {

    // Destructure props object
    const { open, source, onClose } = props

    // Contexts
    const stl = useContext(StlInterceptor);
    const auth = useContext(AuthenticationContext);

    // Boolean states
    const [loading, setLoading] = useState(false);
    const [showRenewAccessDialog, setShowRenewAccessDialog] = useState(false);

    // Functions
    /**
     * Function that will create the issued contract based on the editable contract
     */
    function checkSTLAndDeleteContract() {

        // check for STL level
        setLoading(true);
        stl.require(auth, SecurityTierLevels.MaxLevel)
        .then(() => {
            deleteContract();
        })
        .catch(() => {
            setShowRenewAccessDialog(true);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    function deleteContract() {
        setLoading(true);

        ContractsService.deleteExternalContract(source)
            .then(response => {
                onClose(true)
            })
            .finally(() => {setLoading(false)});
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Deletar contrato?
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Deseja deletar o contrato <b>{source.name}</b>?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)} disabled={loading}>Cancelar</Button>
                {
                    (!loading) 
                    ?
                    <Button onClick={checkSTLAndDeleteContract} autoFocus color="warning">
                        Deletar
                    </Button>
                    :
                    <CircularProgress size="small" />
                }
                
            </DialogActions>
            <RenewAccessDialog open={showRenewAccessDialog} sessionRenewed={deleteContract} />
        </Dialog>
    )
}

export default DeleteExternalContractDialog;