import { CorporateFare, Delete, Download, Edit, FiberPin, FindInPage, ForwardToInbox, Group, GroupAdd, Mail, Person, PictureAsPdf, Print, Sell, Share, Sync, ToggleOffOutlined } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import AuthenticationContext from "../../contexts/authentication";
import { RolesContext } from "../../contexts/roles";
import { DigitalContractStates, EditableDigitalContract, EncapsulatedContract, IdentifiedByEmailContractParty, IssuedContractDocumentType, IssuedDigitalContract, PlatformUserContractParty, TagOrigin } from "../../models/contracts";
import { createWatermarkVersion, downloadTheWatermarkAndSignatureContract, setOriginalContract, setSignatureCertificate } from "../../models/generate-contract-blobs";
import { AccountRoles, AccountTypes } from "../../models/user";
import { show as showNotification } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { show } from "../../redux/features/contract-view-dialog/contract-view-dialog";
import { useAppDispatch } from "../../redux/hooks";
import ContractsService from "../../services/contracts";
import OrganizationService from "../../services/organization";
import { TagsModel } from "../../services/tags";
import SignatureCertificate from "../../standalones/registry/certificate/SignatureCertificate";
import ErrorWrapper from "../../utils/ErrorWrapper";
import DeleteEditableDigitalContractDialog from "./DeleteEditableDigitalContractDialog";
import GenerateTemporaryAccessPin from "./GenerateTemporaryAccessPin";
import InactivateIssuedDigitalContractDialog from "./InactivateIssuedDigitalContract";
import RenewIssuedDigitalContractDialog from "./RenewIssuedDigitalContract";
import SendContractToIssuedStateDialog from "./SendContractToIssuedStateDialog";
import ShareContractDialog from "./ShareContractDialog";
import TagsDialog from "./TagsModal";
import ContactsService from "../../services/contacts";

interface ContractWasIssuedCallback {
    (sourceContract: EditableDigitalContract, newContract: IssuedDigitalContract): void;
}

interface ContractWasDeletedCallback {
    (deletedContract: EncapsulatedContract): void;
}


/**
 * Props used in ContractMenuItems component
 */
interface ContractMenuProps extends React.ComponentProps<any> {
    anchorElement: HTMLElement | null;
    source: EncapsulatedContract;
    setAnchorElement: (value: HTMLElement | null) => void;
    onContractWasIssued?: ContractWasIssuedCallback;
    onContractDeleted?: ContractWasDeletedCallback;
    setTags: (value: any[]) => void

}

const ContractMenu = (props: ContractMenuProps): JSX.Element => {

    // Destructure props object
    const { anchorElement, source, setAnchorElement, onContractWasIssued, onContractDeleted, setTags } = props

    // Contexts
    const authUser = useContext(AuthenticationContext)
    const userRoles = useContext(RolesContext);
    const dispatch = useAppDispatch();

    // helpers
    const UserCanSendContractToIssuedState = authUser.user().account.uuid === source.source.issuerUuid;
    const UserCanDeleteEditableDigitalContract =
        authUser.user().session.accountType === AccountTypes.Customer ||
        authUser.user().account.uuid === source.source.issuerUuid;

    const ContractIsAllowedToBeFilledByTheIssuer =
        (source.source as EditableDigitalContract).template &&
        (source.source as EditableDigitalContract).template.allowIssuerToSendRequiredData;

    const UserCanFillRequiredData =
        ContractIsAllowedToBeFilledByTheIssuer &&
        authUser.user().account.uuid === source.source.issuerUuid;

    const UserCanInactivateContract =
        authUser.user().account.uuid === source.source.issuerUuid ||
        userRoles.assertIsOrganizationOrAdministratorOr(authUser, AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER)


    const UserCanRenewContract =
        authUser.user().account.uuid === source.source.issuerUuid ||
        userRoles.assertIsOrganizationOrAdministratorOr(authUser, AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER)

    const UserCanDownloadContract =
        source.normalized.state === DigitalContractStates.WAITING_FOR_SIGNATURES ||
        source.normalized.state === DigitalContractStates.SIGNED ||
        source.normalized.state === DigitalContractStates.INACTIVE;

    const UserCanCreateContacts = userRoles.assertIsOrganizationOrAdministratorOr(authUser, AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER);

    const ContractHaveTemplate = (source.source as IssuedDigitalContract).templateId;

    // Helper states
    const [isAContractPartie, setIsAContractPartie] = useState(false);

    // Open states
    const [openShareAE, setOpenShareMenuAE] = useState<HTMLElement | null>(null);
    const [openShareAEParties, setOpenShareMenuAEParties] = useState<HTMLElement | null>(null);
    const [downloadMenuAE, setDownloadMenuAE] = useState<HTMLElement | null>(null);
    const [showShareContractDialog, setShowShareContractDialog] = useState(false);
    const [inactivateIssuedDigitalContractDialogOpen, setInactivateIssuedDigitalContractDialogOpen] = useState(false);
    const [sendContractToSignatureDialogOpen, setSendContractToSignatureDialogOpen] = useState(false);
    const [generateAccessPinDialog, setGenerateAccessPinDialog] = useState(false);
    const [deleteEditableDigitalContractDialogOpen, setDeleteEditableDigitalContractDialogOpen] = useState(false);
    const [renewIssuedDigitalContractDialogOpen, setRenewIssuedDigitalContractDialogOpen] = useState(false);
    const [tagsDialogIsOpen, setTagsDialogIsOpen] = useState(false);
    const [shareWithOrganization, setShareWithOrganization] = useState(false);

    // Loading states
    const [loading, setLoading] = useState(false);

    // Data states
    const [selectedPartyToShareEmail, setSelectedPartyToShareEmail] = useState<string | null>(null)

    // Use effects

    // Effect used to Iterates over all contracts and checks if the user is the participant of any contracts and if the participant is the issuer of any contracts
    useEffect(() => {
        const contract = source.source;
        for (const partie of contract.contractParties) {
            if (partie.contractPartyIdentificationType === "IDENTIFIED_BY_EMAIL") {
                if ((partie as IdentifiedByEmailContractParty).email.toLocaleLowerCase() === authUser.user().account.email.toLocaleLowerCase()) {
                    setIsAContractPartie(true);
                };
            }
            else if (partie.contractPartyIdentificationType === "PLATFORM_USER") {
                if ((partie as PlatformUserContractParty).uuid === authUser.user().account.uuid) {
                    setIsAContractPartie(true);
                }
            }
        }

    }, [])

    // Functions

    function handleOpenDownloadMenu(event: React.MouseEvent<HTMLLIElement>): void {
        setDownloadMenuAE(event.currentTarget);
    }

    function handleOpenShareMenu(event: React.MouseEvent<HTMLLIElement>): void {
        setOpenShareMenuAE(event.currentTarget)
    }

    function handleOpenShareMenuParties(event: React.MouseEvent<HTMLLIElement>): void {
        setOpenShareMenuAEParties(event.currentTarget)
    }
    
    async function getOriginalDocumentDownloadLink(contract: IssuedDigitalContract){
        const downloadUri = await ContractsService.downloadDocumentByHash(contract.digitalTrustData.documentHashAlgorithm, contract.digitalTrustData.documentHashValue);
        const downloadLink = downloadUri.toString();
       
        return downloadLink;
    }

    /**
     * Resend contract email to all contract parties
     * @param contractId 
     */
    async function resendDigitalContractEmail(contract: EditableDigitalContract | IssuedDigitalContract) {
        setLoading(true);
        switch (contract.state) {
            case "NOT_READY_TO_BE_ISSUED":
            case "READY_TO_BE_ISSUED":
                try {
                    await ContractsService.sendEditableDigitalContractEmailToContractParties(contract.id);
                    dispatch(showNotification({
                        type: 'success',
                        message: 'Contrato reenviado com sucesso.'
                    }));
                } catch (e) {
                    const err = new ErrorWrapper(e)
                    dispatch(showNotification({
                        type: 'error',
                        message: 'Não foi possível reenviar o contrato.'
                    }));
                    console.error(err)
                }
                break;
            case "WAITING_FOR_SIGNATURES":
            case "SIGNED":
                try {
                    await ContractsService.sendIssuedDigitalContractEmailToContractParties(contract.id);
                    dispatch(showNotification({
                        type: 'success',
                        message: 'Contrato reenviado com sucesso.'
                    }));
                } catch (e) {
                    const err = new ErrorWrapper(e)
                    dispatch(showNotification({
                        type: 'error',
                        message: 'Não foi possível reenviar o contrato.'
                    }));
                    console.error(err)
                }

                break;
        }
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    /**
     * Handle onClose event of the SendContractToIssuedStateDialog. This function will trigger the 'onContractWasIssued' if the contract is
     * issued successfully and the callback was given
     * @param contractWasIssued 
     * @param newContract 
     */
    function handleSendContractToIssuedStateDialogOnCloseEvent(contractWasIssued: boolean, newContract: IssuedDigitalContract | undefined) {
        if (contractWasIssued && newContract && onContractWasIssued) {
            onContractWasIssued(source.source as EditableDigitalContract, newContract);
        }
        setSendContractToSignatureDialogOpen(false);
    }

    // Dialog handlers function

    function handleOnClickSeeContractMenuItem() {
        // close the menu
        setAnchorElement(null);
        dispatch(show({
            visible: true,
            contract: source
        }))
    }

    async function handleSelectContractPartyToShare(cp: PlatformUserContractParty | IdentifiedByEmailContractParty) {
        handleCloseMenus()
        if (cp.contractPartyIdentificationType === "IDENTIFIED_BY_EMAIL") {
            setSelectedPartyToShareEmail((cp as IdentifiedByEmailContractParty).email)
        } else if (cp.contractPartyIdentificationType === "PLATFORM_USER") {
            const contractParty = cp as PlatformUserContractParty
            const foundUser = await OrganizationService.fetchOrganizationMemberByUuid(contractParty.uuid)

            if (foundUser === null) return;
            setSelectedPartyToShareEmail(foundUser.email)
        }

        setShowShareContractDialog(true)
    }

    async function handleSelectOrganizationShare() {
        handleCloseMenus();
        setShareWithOrganization(true);
        setShowShareContractDialog(true);
    }

    function handleInactivateIssuedDigitalContractDialogOnCloseEvent(contractWasInactivated: boolean) {
        if (contractWasInactivated && onContractDeleted) {
            onContractDeleted(source);
        }
        setInactivateIssuedDigitalContractDialogOpen(false);
    }

    function handleGenerateAccessPinDialogOnCloseEvent() {
        setGenerateAccessPinDialog(false);
    }

    /**
     * Handle onClose event for DeleteEditableDigitalContractDialog. This function will trigger the 'onContractDeleted' if the contract was
     * successfully deleted
     * @param contractWasDeleted 
     */
    function handleDeleteEditableDigitalContractDialogOnCloseEvent(contractWasDeleted: boolean) {
        if (contractWasDeleted && onContractDeleted) {
            onContractDeleted(source);
        }
        setDeleteEditableDigitalContractDialogOpen(false);
    }

    function handleRenewIssuedDigitalContractDialogOnCloseEvent() {
        setRenewIssuedDigitalContractDialogOpen(false);
    }

    function handleCloseShareContractDialog() {
        setShowShareContractDialog(false);
        setShareWithOrganization(false);
    }

    function handleTagsDialogOnCloseEvent(tagDialogClose: boolean, updatedTags: TagsModel[]) {
        setTagsDialogIsOpen(false);
        setTags(updatedTags.map(ut => (
            {
                tagName: ut.tagName,
                tagOrigin: TagOrigin.CONTRACT
            }
        )))
    }

    function handleCloseMenus() {
        setOpenShareMenuAE(null)
        setDownloadMenuAE(null)
        setAnchorElement(null)
        setOpenShareMenuAEParties(null)
    }

    function handleCreateContactsFromContract() {
        setAnchorElement(null);
        ContactsService.registerContactsFromContract(source.normalized.id)
            .then(() => dispatch(showNotification({type: "success", message: "Contatos gerados com sucesso"})))
            .catch((e) => {
                const err = new ErrorWrapper(e);
                dispatch(showNotification({type: "error", message: err.message}))
            })
    }

    return (
        <>
            {/* Main Card Menu */}
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorElement}
                open={anchorElement !== null}
                onClose={handleCloseMenus}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >

                <MenuItem
                    onClick={() => handleOnClickSeeContractMenuItem()}
                >
                    <ListItemIcon>
                        <FindInPage fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Ver contrato</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={handleOpenShareMenu}
                >
                    <ListItemIcon>
                        <Share fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Compartilhar</ListItemText>
                </MenuItem>
                {
                    // render 'send to issued state' if the contract is READY_TO_BE_ISSUED
                    (source.normalized.state === DigitalContractStates.READY_TO_BE_ISSUED)
                        ?
                        (
                            <MenuItem
                                onClick={() => setSendContractToSignatureDialogOpen(true)}
                                disabled={!UserCanSendContractToIssuedState}
                            >
                                <ListItemIcon>
                                    <ForwardToInbox fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Enviar para assinatura
                                    {
                                        (!UserCanSendContractToIssuedState)
                                            ?
                                            <Typography variant="subtitle2" sx={{ display: "block" }}>
                                                (Exclusivo para o usuário emissor do contrato)
                                            </Typography>
                                            :
                                            <></>
                                    }
                                </ListItemText>

                            </MenuItem>
                        )
                        :
                        <></>
                }
                {
                    (source.normalized.state === DigitalContractStates.SIGNED && UserCanRenewContract)
                        ?
                        <MenuItem onClick={() => setGenerateAccessPinDialog(true)} disabled={!UserCanRenewContract}>
                            <ListItemIcon><FiberPin /></ListItemIcon>
                            <ListItemText>PIN de acesso</ListItemText>
                        </MenuItem>
                        :
                        <></>
                }
                {
                    // render fill editable contract option 
                    ((source.source as EditableDigitalContract).template)
                        ?
                        <MenuItem
                            disabled={!UserCanFillRequiredData}
                            onClick={() => window.location.replace(`/registry/${source.source.id}`)}
                        >
                            <ListItemIcon>
                                <Edit fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Preencher informações requeridas
                                {
                                    (!ContractIsAllowedToBeFilledByTheIssuer)
                                        ?
                                        <Typography variant="subtitle2" sx={{ display: "block" }}>
                                            (Esse contrato não permite que o emissor insira informações requeridas)
                                        </Typography>
                                        :
                                        (!UserCanFillRequiredData)
                                            ?
                                            <Typography variant="subtitle2" sx={{ display: "block" }}>
                                                (Exclusivo para o usuário emissor do contrato)
                                            </Typography>
                                            :
                                            <></>
                                }
                            </ListItemText>
                        </MenuItem>
                        :
                        <></>
                }
                {
                    // render delete editable contract option
                    ((source.normalized.state !== DigitalContractStates.SIGNED && source.normalized.state !== DigitalContractStates.INACTIVE))
                        ?
                        <MenuItem
                            onClick={() => setDeleteEditableDigitalContractDialogOpen(true)}
                            disabled={!UserCanDeleteEditableDigitalContract}
                        >
                            <ListItemIcon>
                                <Delete fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Deletar contrato
                                {
                                    (!UserCanDeleteEditableDigitalContract)
                                        ?
                                        <Typography variant="subtitle2" sx={{ display: "block" }}>
                                            (Exclusivo para o usuário emissor do contrato ou dono da organização)
                                        </Typography>
                                        :
                                        <></>
                                }
                            </ListItemText>

                        </MenuItem>
                        :
                        <></>
                }
                <MenuItem onClick={() => resendDigitalContractEmail(source.source)} disabled={loading}>
                    <ListItemIcon>
                        <Mail />
                    </ListItemIcon>
                    <ListItemText>Reenviar e-mail de acesso ao contrato</ListItemText>
                </MenuItem>
                {
                    (source.normalized.state === DigitalContractStates.SIGNED)
                        ?
                        <MenuItem onClick={() => setInactivateIssuedDigitalContractDialogOpen(true)} disabled={!UserCanInactivateContract}>
                            <ListItemIcon><ToggleOffOutlined /></ListItemIcon>
                            <ListItemText>Inativar contrato
                                {
                                    (!UserCanInactivateContract)
                                        ?
                                        <Typography variant="subtitle2" sx={{ display: "block" }}>
                                            (Exclusivo para o usuário emissor do contrato ou dono da organização)
                                        </Typography>
                                        :
                                        <></>
                                }
                            </ListItemText>
                        </MenuItem >
                        :
                        <></>
                }
                {
                    (source.normalized.state === DigitalContractStates.SIGNED || source.normalized.state === DigitalContractStates.INACTIVE)
                        ?
                        <MenuItem onClick={() => setRenewIssuedDigitalContractDialogOpen(true)} disabled={!UserCanRenewContract || !ContractHaveTemplate}>
                            <ListItemIcon><Sync /></ListItemIcon>
                            <ListItemText>Renovar contrato
                                {
                                    (!UserCanRenewContract)
                                        ?
                                        <Typography variant="subtitle2" sx={{ display: "block" }}>
                                            (Exclusivo para o usuário emissor do contrato ou dono da organização)
                                        </Typography>
                                        :
                                        (!ContractHaveTemplate)
                                            ?
                                            <Typography variant="subtitle2" sx={{ display: "block" }}>
                                                (Não é possível renovar contratos que não tem um arquivo de template vinculado a ele)
                                            </Typography>
                                            :
                                            <></>
                                }
                            </ListItemText>
                        </MenuItem>
                        :
                        <></>
                }
                {
                    isAContractPartie
                        ?
                        <MenuItem onClick={() => window.location.replace(`/registry/${source.source.id}`)} disabled={!isAContractPartie}>
                            <ListItemIcon><Edit /></ListItemIcon>
                            <ListItemText>Ir para painel de assinatura
                                {
                                    (!isAContractPartie)
                                        ?
                                        <Typography variant="subtitle2" sx={{ display: "block" }}>
                                            (Exclusivo para o usuário participante do contrato)
                                        </Typography>
                                        :
                                        <></>
                                }


                            </ListItemText>
                        </MenuItem >
                        :
                        <></>
                }
                {
                    (UserCanDownloadContract)
                        ?
                        <MenuItem onClick={(e) => {
                            handleOpenDownloadMenu(e)
                        }}>
                            <ListItemIcon><Download /></ListItemIcon>
                            <ListItemText>
                                Baixar contrato
                            </ListItemText>
                        </MenuItem>
                        :
                        <></>
                }
                <MenuItem onClick={() => setTagsDialogIsOpen(true)}>
                    <ListItemIcon><Sell /></ListItemIcon>
                    <ListItemText>Editar tags</ListItemText>
                </MenuItem>
                {
                    (source.normalized.state === "SIGNED" && UserCanCreateContacts)
                        ?
                        <MenuItem onClick={handleCreateContactsFromContract}>
                            <ListItemIcon><GroupAdd /></ListItemIcon>
                            <ListItemText>Gerar contatos a partir do contrato</ListItemText>
                        </MenuItem>
                        :
                        <></>
                }
            </Menu>
            {/* Share Contract Submenu */}
            <Menu
                id="share-menu"
                aria-labelledby="share-menu-button"
                anchorEl={openShareAE}
                open={openShareAE !== null}
                onClose={() => { setOpenShareMenuAE(null) }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: "right",
                }}
                sx={{ overflow: "auto" }}
            >
                <MenuItem                     
                    onClick={handleOpenShareMenuParties}
                    >
                    <ListItemIcon><Group /></ListItemIcon>
                    <ListItemText>Compartilhar com Participantes</ListItemText>
                </MenuItem>
                
                <Menu 
                    id="share-menu-parties"
                    aria-labelledby="share-menu-button-parties"
                    anchorEl={openShareAEParties}
                    open={openShareAEParties !== null}
                    onClose={() => { setOpenShareMenuAEParties(null) }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: "right",
                    }}
                    sx={{ overflow: "auto" }}
                    >
                        {
                            source.source.contractParties.map(cp => (
                                <MenuItem
                                sx={{ pl: 4 }}
                                key={cp.role}
                                onClick={() => handleSelectContractPartyToShare(cp)}
                                >
                                    <ListItemIcon> {cp.contractPartyIdentificationType === "IDENTIFIED_BY_EMAIL" ? <Person /> : <Group />} </ListItemIcon>
                                    <ListItemText> {cp.role} </ListItemText>
                                </MenuItem>
                            ))
                        }
                </Menu>

                <MenuItem 
                    onClick={() => handleSelectOrganizationShare()}
                >
                    <ListItemIcon><CorporateFare /></ListItemIcon>
                    <ListItemText>Gerar link de compartilhamento para organização</ListItemText>
                </MenuItem>
            </Menu>
            {/* Download Contract Submenu */}
            <Menu
                id="download-menu"
                aria-labelledby="download-menu-button"
                anchorEl={downloadMenuAE}
                open={downloadMenuAE !== null}
                onClose={() => setDownloadMenuAE(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: "right",
                }}
            >
                {
                    (source.normalized.state === DigitalContractStates.SIGNED || source.normalized.state === DigitalContractStates.INACTIVE)
                        ?
                        <MenuItem
                            sx={{ pl: 4 }}
                            onClick={async () => {
                                handleCloseMenus()
                                const contract = (source.source as IssuedDigitalContract)
                                if (contract.documentType === IssuedContractDocumentType.WORD) {
                                    const uri = await ContractsService.generateDownloadURIForPrintableDigitalContract(contract.id)
                                    axios.get(`${uri.uri}`, {
                                        responseType: "blob"
                                    }).then(downloadedFile => {
                                        // create file link in browser's memory
                                        const href = URL.createObjectURL(downloadedFile.data);

                                        // create "a" HTML element with href to file & click
                                        const link = document.createElement('a');
                                        link.href = href;
                                        link.setAttribute('download', `${contract.id}-timbrado.docx`); //or any other extension
                                        document.body.appendChild(link);
                                        link.click();

                                        // clean up "a" element & remove ObjectURL
                                        document.body.removeChild(link);
                                        URL.revokeObjectURL(href);
                                    })
                                        .finally(() => {
                                            setLoading(false)
                                        })
                                } else {
                                    const originalDocumentDownloadLink = await getOriginalDocumentDownloadLink(contract)
                                    const signatureCertificate = <SignatureCertificate source={contract} originalDocumentDownloadLink={originalDocumentDownloadLink} />
                                    const signatureCertificateBlob = await setSignatureCertificate(signatureCertificate)
                                    const originalDocument = await ContractsService.getOriginalDocumentDownloadURI(contract.id)
                                    const originalDocumentBlob = await setOriginalContract(originalDocument.uri)
                                    const watermarkAndSignatureCertificateBlob = await createWatermarkVersion(originalDocumentBlob, signatureCertificateBlob, contract)

                                    downloadTheWatermarkAndSignatureContract(watermarkAndSignatureCertificateBlob, contract)
                                }
                            }}
                        >

                            <ListItemIcon>
                                <Print />
                            </ListItemIcon>
                            <ListItemText>
                                Baixar documento (Timbrado)
                            </ListItemText>
                        </MenuItem>
                        :
                        <></>
                }

                <MenuItem
                    sx={{ pl: 4 }}
                    onClick={async () => {
                        const contract = (source.source as IssuedDigitalContract)
                        const uri = await ContractsService.getOriginalDocumentDownloadURI(contract.id)
                        axios.get(`${uri.uri}`, {
                            responseType: "blob"
                        }).then(downloadedFile => {
                            const filename = contract.documentType === IssuedContractDocumentType.WORD
                                ? `${contract.id}.docx` : `${contract.id}.pdf`

                            // create file link in browser's memory
                            const href = URL.createObjectURL(downloadedFile.data);

                            // create "a" HTML element with href to file & click
                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', filename); //or any other extension
                            document.body.appendChild(link);
                            link.click();

                            // clean up "a" element & remove ObjectURL
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        })
                            .finally(() => {
                                setLoading(false)
                            })
                    }}
                >
                    <ListItemIcon>
                        <PictureAsPdf />
                    </ListItemIcon>
                    <ListItemText>
                        Baixar documento (Original)
                    </ListItemText>
                </MenuItem>
            </Menu>
            {/** Dialog to send an contract to waiting for signatures state */}
            <SendContractToIssuedStateDialog
                open={sendContractToSignatureDialogOpen}
                source={source.source as EditableDigitalContract}
                onClose={handleSendContractToIssuedStateDialogOnCloseEvent}
            />
            {/** Dialog to delete EditableDigitalContracts */}
            <DeleteEditableDigitalContractDialog
                open={deleteEditableDigitalContractDialogOpen}
                source={source.source}
                onClose={handleDeleteEditableDigitalContractDialogOnCloseEvent}
            />
            {/* Share contract dialog */}
            <ShareContractDialog
                open={showShareContractDialog}
                source={source.source}
                onClose={handleCloseShareContractDialog}
                targetContractPartyEmail={selectedPartyToShareEmail ? selectedPartyToShareEmail : ""}
                shareWithOrganization={shareWithOrganization}
            />
            {/* Inactive contract dialog */}
            <InactivateIssuedDigitalContractDialog
                open={inactivateIssuedDigitalContractDialogOpen}
                source={source.source as IssuedDigitalContract}
                onClose={handleInactivateIssuedDigitalContractDialogOnCloseEvent}
            />
            { /* Renew contract dialog */}
            <RenewIssuedDigitalContractDialog
                open={renewIssuedDigitalContractDialogOpen}
                source={source.source as IssuedDigitalContract}
                onClose={handleRenewIssuedDigitalContractDialogOnCloseEvent}
            />
            {/* Pin dialog */}
            <GenerateTemporaryAccessPin
                open={generateAccessPinDialog}
                source={source.source as IssuedDigitalContract}
                onClose={handleGenerateAccessPinDialogOnCloseEvent}
            />
            {/* Tag dialog */}
            <TagsDialog
                open={tagsDialogIsOpen}
                source={source.source}
                onClose={handleTagsDialogOnCloseEvent}
            />
        </>
    );
}

export default ContractMenu