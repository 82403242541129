import { Sell } from '@mui/icons-material';
import { Autocomplete, AutocompleteInputChangeReason, Box, Button, TextField } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import TagsService, { TagsModel } from '../services/tags';

interface TagsProps {
    onTagAdded: (tag: TagsModel) => void;
}

const TagsAdder: React.FC<TagsProps> = (props: TagsProps): JSX.Element => {
    const [selectedTag, setSelectedTag] = useState<TagsModel | null>(null);
    const [fetchedTags, setFetchedTags] = useState<TagsModel[]>([]);
    const [addNewTag, setAddNewTag] = useState(false);
    const [tagsWithMostHits, setTagsWithMostHits] = useState<TagsModel[]>([]);

    const searchTimerRef = useRef<NodeJS.Timeout | undefined>();

    useEffect(() => {
        fetchTags();
    }, []);

    async function fetchTags() {
        try {
            /**
             * This service is using 1 because he brings the most hits in the contract
             */
            const fetchedTags = await TagsService.fetchTagsUsingPagination(1);

            /**
             * You might be surprised that this request saves two states, but don't worry, it's being saved that way,
             *  because when a text search occurs, the fetchedTags state will be replaced by the one found,
             *  and in order not to have to redo that same request again, it's created a state to save the tags with more hits
             *  and after adding a tag, it sets the state back to the one with more hits.
             */
            setFetchedTags(fetchedTags);
            setTagsWithMostHits(fetchedTags)
        } catch (error) {
            console.error('Não foi possível buscar as tags');
        }
    }

    /**
     * Callback with the tag added
     */
    const handleAddTag = () => {
        if (selectedTag) {
            setSelectedTag(null);
            props.onTagAdded(selectedTag);
        }
        setAddNewTag(false);
        setFetchedTags(tagsWithMostHits);
    };

    const handleTagChange = (event: React.ChangeEvent<{}>, value: TagsModel | null) => {
        setSelectedTag(value);
        setFetchedTags(tagsWithMostHits);
    };

    const handleInputChange = async (
        event: React.ChangeEvent<{}>,
        value: string,
        reason: AutocompleteInputChangeReason
    ) => {
        const inputValue = value;

        if (inputValue === '') {
            setFetchedTags(tagsWithMostHits);
        } else {
            /**
             * The input has a timeout because he wait the user stop tiping to avoid excess requests
             */
            clearTimeout(searchTimerRef.current);
            const timer = setTimeout(async () => {
                try {
                    const searchResults = await TagsService.searchTagsByName(inputValue);
                    const normalizedTags = searchResults.map((tag: any) => ({
                        tagName: tag.data.tagName,
                        hits: tag.data.hits,
                    }));
                    setFetchedTags(normalizedTags);
                } catch (error) {
                    console.error('Não foi possível buscar as tags');
                }
            }, 500);
            searchTimerRef.current = timer;
        }

        setSelectedTag({ tagName: inputValue });
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {addNewTag ? (
                <Autocomplete
                    sx={{ width: '100%' }}
                    value={selectedTag}
                    onChange={(event, newValue) => {
                        setSelectedTag(newValue as TagsModel);
                        handleTagChange(event, newValue as TagsModel);
                    }}
                    inputValue={selectedTag ? selectedTag.tagName : ''}
                    onInputChange={handleInputChange}
                    options={fetchedTags}
                    getOptionLabel={(tag) => (tag as TagsModel).tagName}
                    freeSolo
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={!selectedTag ? 'Filtre por tag' : ''}
                            variant="standard"
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleAddTag();
                                    setFetchedTags(tagsWithMostHits);
                                }
                            }}
                            onBlur={() => {
                                handleAddTag();
                                setFetchedTags(tagsWithMostHits);
                            }}
                        />
                    )}
                />
            ) : (
                <Button
                    variant="contained"
                    sx={{ borderRadius: '24px', width: '100%' }}
                    startIcon={<Sell />}
                    onClick={() => setAddNewTag(true)}
                >
                    Adicionar tag
                </Button>
            )}
        </Box>
    );
};

export default TagsAdder;