import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Box, Divider, LinearProgress, IconButton, Menu, MenuItem, Typography, Tooltip, Fab, Autocomplete, TextField, Button } from "@mui/material";
import { Add, Gavel, ManageAccounts, MoreVert, Paid, Search, Shield } from "@mui/icons-material";
import { DataGrid, GridColumns, GridRenderCellParams, ptBR } from '@mui/x-data-grid';
import AuthenticationContext from "../../contexts/authentication";
import { RolesContext } from "../../contexts/roles";
import { AccountRoles, UserAccount } from "../../models/user";
import { tableStyle } from "../../theme"
import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../utils/ErrorWrapper";
import { Pagination } from "../../models/pagination";
import SubusersService from "../../services/subusers";
import DeleteSubuser from "./Delete";
import ImportSubuser from "./Import";
import SubuserRoles from "./Roles";
import SubuserInfo from "./Info";
import PayWallDialog from "../../components/PayWallDialog";

interface SubusersProps extends React.ComponentProps<any> {
    currentSubusersCount: Number
    maxSubuser: Number,
    updateUsersQuota: (addedSubusers: number) => void
}

const Subusers = (props: SubusersProps): JSX.Element => {

    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Usuários da Organização"
    }, []);

    //Context props
    const auth = useContext(AuthenticationContext);
    const userRoles = useContext(RolesContext);
    const notification = useAppDispatch();

    //Search variables
    const SEARCH_TIMEOUT = 1200;
    let lastTimeout = useRef<NodeJS.Timeout | null>(null);

    //States
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1);
    const [subusers, setSubusers] = useState<UserAccount[]>([]);
    const [pagination, setPagination] = useState<Pagination>(null!);
    const [showImportDrawer, setShowImportDrawer] = useState(false);
    const [showRolesDrawer, setShowRolesDrawer] = useState(false);
    const [showInfoDrawer, setShowInfoDrawer] = useState(false);
    const [showDeleteDrawer, setShowDeleteDrawer] = useState(false);
    const [openPayWallDialog, setOpenPayWallDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    //Fetch subusers pagination when component loads
    useEffect(() => { fetchPagination() }, [])

    // Responsivity effect handler
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    //Fetch subusers when page or limit value changes 
    useEffect(() => { fetchSubusers() }, [page])

    //Fetch subusers when search term is present
    useEffect(() => {
        if(searchTerm == "") {
            fetchSubusers()
        }
        
        if (lastTimeout.current) {
            clearTimeout(lastTimeout.current);
        }
        // put the search result on an timeout to prevent API usage abuse
        lastTimeout.current = setTimeout(() => {

            // ignore if its already fetching data or not search term is set
            if (searchTerm.length === 0 || loading) {
                return;
            }
            fetchSubusers()
        }, SEARCH_TIMEOUT);
    }, [searchTerm])

    /**
     * Fetch pagination data of subusers
     */
    const fetchPagination = () => {
        SubusersService.pagination()
            .then(pag => {
                setPagination(pag);
                fetchSubusers();
            })
            .catch(e =>
                notification(show({
                    type: 'error',
                    message: `Ocorreu um erro: ${new ErrorWrapper(e).message}`
                })))
    }

    /**
     * Fetch organization subusers using pagination
     */
    const fetchSubusers = () => {
        setLoading(true)
        SubusersService.fetchAll(page, searchTerm)
            .then(response => {
                if (!response) return;
                //Filter the deleted subusers 
                const filteredSubusers: UserAccount[] = []
                response.forEach(user => { if (!user.deletedAt) filteredSubusers.push(user) })
                setSubusers(filteredSubusers)
            })
            .catch(e =>
                notification(show({
                    type: 'error',
                    message: `Ocorreu um erro: ${new ErrorWrapper(e).message}`
                })))
            .finally(() => setLoading(false))
    }

    function handleClosePayWallDialog() {
        setOpenPayWallDialog(false);
    }

    //Store the anchor HTML element associated with the challenge context menu
    const [subuserMenuAnchor, setSubusersMenuAnchor] = useState<HTMLElement | null>(null);
    const [selectedSubuser, setSelectedSubuser] = useState<UserAccount | null>(null);

    //Subusers grid column def
    const subusersColumnDef: GridColumns = [
        { field: 'fullname', headerName: 'Nome', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'email', headerName: 'Email', sortable: false, flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'accountRoles', headerName: "Permissões", sortable: false, flex: 1, headerAlign: "center", align: "center",
            renderCell: (params: GridRenderCellParams) => {

                const subuserRoles = params.row.accountRoles as AccountRoles[];
                const isUserAdministrator = subuserRoles.includes(AccountRoles.ADMINISTRATOR);
                
                return (
                    <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} sx={{width: "100%"}}>
                        {
                            subuserRoles.map((role: AccountRoles) => (
                                <>
                                    {
                                        role === AccountRoles.ADMINISTRATOR
                                            ?
                                            <Tooltip title="Administrador">
                                                <Shield color="primary" />
                                            </Tooltip>
                                            :
                                            <></>
                                    }
                                    {
                                        role === AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER && !isUserAdministrator
                                            ?
                                            <Tooltip title="Gerente de Contratos">
                                                <Gavel color="primary" />
                                            </Tooltip>
                                            :
                                            <></>
                                    }
                                    {
                                        role === AccountRoles.USER_AND_TEAMS_MANAGER && !isUserAdministrator
                                            ?
                                            <Tooltip title="Gerente de usuários e times">
                                                <ManageAccounts color="primary" />
                                            </Tooltip>
                                            :
                                            <></>
                                    }
                                    {
                                        role === AccountRoles.FINANCE_MANAGER && !isUserAdministrator
                                            ?
                                            <Tooltip title="Gerente Financeiro">
                                                <Paid color="primary" />
                                            </Tooltip>
                                            :
                                            <></>
                                    }
                                </>
                            ))
                        }
                    </Box>
                )
            }
        },
        {
            field: '', headerName: '', align: 'center', sortable: false,
            renderCell: (params: GridRenderCellParams) => {

                const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    setSubusersMenuAnchor(event.currentTarget);
                    setSelectedSubuser(params.row);
                };

                return (
                    <Box>
                        <IconButton
                            onClick={onClick}
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={subuserMenuAnchor ? 'true' : undefined}
                        >
                            <MoreVert color='action' />
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    /**
     * Return default props used in pagination
     * @param pagination 
     * @returns 
     */
    function paginationProps(pagination: Pagination | null): Object {
        //Return empty object if pagination is null
        if (pagination === null) return {}
        return {
            rowCount: pagination?.totalRecords,
            pageSize: pagination?.recordsPerPage,
            paginationMode: 'server',
            page: page - 1,
            onPageChange: (page: number) => setPage(page + 1)
        }
    }

    function handleOpenPayWallDialog() {
        setOpenPayWallDialog(true)
    }
    
    // When user input search params, make the request with this optional search term
    function handleAutocompleteTextFieldChange(e : ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setSearchTerm(e.target.value);
        setSubusers([]);
    }

    return (
        <Box height='88vh' px={2} py={2} className="container">
            <Box sx={{display: screenWidth < 800 ? "block" : "flex", justifyContent:"space-between"}}>
                <Box>
                    <Typography variant='h6' color='primary.main'>Usuários de sua <strong>organização</strong></Typography>
                    <Typography variant='subtitle2'> Aqui você pode visualizar e gerenciar os usuários pertencentes à sua organização</Typography>
                </Box>
                <Autocomplete 
                sx={{mt: 2, width: screenWidth < 800 ? "100%" : "50%"}} 
                options={[]} 
                loading={loading}
                loadingText={"Buscando usuários..."}
                noOptionsText={"Nenhum usuário encontrado"}
                renderInput={(params) => (
                    <TextField 
                    {...params} 
                    onChange={handleAutocompleteTextFieldChange} 
                    InputProps={{
                        ...params.InputProps,
                        startAdornment : <Button sx={{m: 0, p: 0, minWidth: 0}} onClick={() => fetchSubusers()}><span><Search sx={{color : "black"}} />{params.InputProps.startAdornment}</span></Button>,
                        endAdornment: searchTerm == "" ? null : <Button onClick={()=> { setSearchTerm(""); }}>Limpar</Button>
                    }}
                    placeholder={"Busque um usuário"}
                    sx={{ color : "#212121" }}
                    />)}/>
            </Box>

            <Menu
                anchorEl={subuserMenuAnchor}
                open={!!subuserMenuAnchor}
                onClick={() => setSubusersMenuAnchor(null)}
            >
                <MenuItem onClick={() => setShowRolesDrawer(true)} >
                    Permissões
                </MenuItem>
                <MenuItem onClick={() => setShowInfoDrawer(true)} >
                    Informações
                </MenuItem>
                {
                    !userRoles.assertIsOrganizationOrAdministratorOr(auth, AccountRoles.USER_AND_TEAMS_MANAGER) ? null :
                        <Box>
                            <Divider />
                            <MenuItem onClick={() => setShowDeleteDrawer(true)}>
                                Deletar
                            </MenuItem>
                        </Box>
                }
            </Menu>

            <Box width={1} height={700} mt={5}>
                <DataGrid
                    sx={tableStyle}
                    disableColumnMenu
                    rowsPerPageOptions={[20]}
                    getRowId={(subuser) => subuser.uuid}
                    rows={subusers}
                    columns={subusersColumnDef}
                    loading={loading}
                    isRowSelectable={() => false}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    {...paginationProps(pagination)}
                    components={{
                        LoadingOverlay: LinearProgress,
                        NoRowsOverlay: () => <Typography mt={3} textAlign='center' color='text.secondary'> Nenhuma informação</Typography>
                    }}
                />
            </Box>
            {
                !userRoles.assertIsOrganizationOrAdministratorOr(auth, AccountRoles.USER_AND_TEAMS_MANAGER) ? null :
                    <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }} >
                        <Tooltip placement='top' title='Importar membros'>
                            {Number(props.currentSubusersCount) >= Number(props.maxSubuser) ? 
                                <Fab color='primary' aria-label='add' sx={{ position: 'absolute', bottom: { md: 675, sm: 42, xs: 42 }, right: 25 }}
                                    onClick={() => {
                                        notification(show({
                                            type: 'error', 
                                            message: `Limite de cota atingido não é possivel adicionar mais usuários`
                                        }))}}
                                >
                                    <Add />
                                </Fab>
                            :
                                <Fab color='primary' aria-label='add' sx={{ position: 'absolute', bottom: { md: 675, sm: 42, xs: 42 }, right: 25 }}
                                    onClick={() => setShowImportDrawer(true)}><Add /></Fab>
                            }
                        </Tooltip>
                    </Box>
            }
            {/* Import subuser drawer */}
            <ImportSubuser currentSubusers={props.currentSubusersCount} maxSubusers={props.maxSubuser} 
                open={showImportDrawer} close={() => setShowImportDrawer(false)}
                onSuccess={(newUser: UserAccount) => setSubusers([...subusers, newUser])} 
                updateUsersQuota={props.updateUsersQuota}
            />

            <SubuserRoles subuser={selectedSubuser} open={showRolesDrawer} close={() => setShowRolesDrawer(false)} />
            <SubuserInfo subuser={selectedSubuser} open={showInfoDrawer} close={() => setShowInfoDrawer(false)} />
            <DeleteSubuser auth={auth} subuser={selectedSubuser} open={showDeleteDrawer}
                close={() => setShowDeleteDrawer(false)} onSuccess={(deletedUserUuid: string) => {
                    setShowDeleteDrawer(false);
                    setSubusers(subusers.filter(s => s.uuid != deletedUserUuid))
                }}
                updateUsersQuota={props.updateUsersQuota}
            />
            <PayWallDialog open={openPayWallDialog} onClose={handleClosePayWallDialog} quotaName="Sub Usuários" productName="Usuários da Organização" type="limiter" />
        </Box>
    )
}

export default Subusers;
