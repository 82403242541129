import { Box, Button, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import {Menu as MenuIcon} from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";

import {EditableDigitalContract, NormalizedContract, IssuedDigitalContract, parseToNormalizedContract, DigitalContractStates, EncapsulatedContract} from "../../models/contracts"
import DigitalContractsService from "../../services/contracts";
import {ContractCard} from "./ContractCard";

const ContractsSearchList = (props : React.ComponentProps<any>) => {

    // constants
    const digitalContractStateFiltersAndLabels = [
        // {
        //     value : -1,
        //     label : "Exibir todos os contratos",
        // },
        {
            value : DigitalContractStates.NOT_READY_TO_BE_ISSUED,
            label : "Aguardando informações",
        },
        {
            value : DigitalContractStates.READY_TO_BE_ISSUED,
            label : "Prontos para enviar para assinatura",
        },
        {
            value : DigitalContractStates.WAITING_FOR_SIGNATURES,
            label : "Aguardando assinaturas",
        },
        {
            value : DigitalContractStates.SIGNED,
            label : "Assinados",
        },
        {
            value : DigitalContractStates.INACTIVE,
            label : "Inativos",
        },
    ];

    // states
    //  - EditableDigitalContract pagination control
    const [editableDigitalContracts, setEditableDigitalContracts] = useState<EditableDigitalContract[]>([]);
    const [editableDigitalContractsPage, setEditableDigitalContractsPage] = useState(1);
    const [editableDigitalContractsHasNoMoreContentToFetch, setEditableDigitalContractsHasNoMoreContentToFetch] = useState(true);

    //  - IssuedDigitalContract pagination control
    const [issuedDigitalContracts, setIssuedDigitalContracts] = useState<IssuedDigitalContract[]>([]);
    const [issuedDigitalContractsPage, setIssuedDigitalContractsPage] = useState(1);
    const [issuedDigitalContractsHasNoMoreContentToFetch, setIssuedDigitalContractsHasNoMoreContentToFetch] = useState(true);
    
    // - NormalizedContract
    const [normalizedContracts, setNormalizedContracts] = useState<EncapsulatedContract[]>([]);

    // - digital contract state filter (this prop is 'number' instead of 'DigitalContractState' because it was very difficult to manage an enum in React)
    const [digitalContractStateFilters, setDigitalContractStateFilters] = useState<DigitalContractStates[]>([
        DigitalContractStates.NOT_READY_TO_BE_ISSUED, 
        DigitalContractStates.READY_TO_BE_ISSUED , 
        DigitalContractStates.WAITING_FOR_SIGNATURES , 
        DigitalContractStates.SIGNED ,
        DigitalContractStates.INACTIVE //# does not include this one because we want to keep deactivated
    ]);

    // effects
    // - effect that observe page change for editable digital contract
    useEffect(() => {
        // prevent fetching if flag is false
        if (!editableDigitalContractsHasNoMoreContentToFetch) {
            return;
        }

        // fetch API
        DigitalContractsService.fetchEditableDigitalContracts(editableDigitalContractsPage)
            .then(contracts => {
                // set the flag if the editable digital contract has more content to fetch
                // and add the content into the state.
                // After that, call mergeNormalizedContracts to merge the two lists into one
                setEditableDigitalContractsHasNoMoreContentToFetch(contracts.length > 0);
                setEditableDigitalContracts([...editableDigitalContracts, ...contracts]);  
            })
            .catch(e => {
                console.error("Catched an error", e);
            })
    }, [editableDigitalContractsPage]);

    // - effect that observe page change for issued digital contract
    useEffect(() => {
        // prevent fetching if flag is false
        if (!issuedDigitalContractsHasNoMoreContentToFetch) {
            return;
        }

        // fetch API
        DigitalContractsService.fetchIssuedDigitalContracts(editableDigitalContractsPage)
            .then(contracts => {
                // set the flag if the editable digital contract has more content to fetch
                // and add the content into the state.
                // After that, call mergeNormalizedContracts to merge the two lists into one
                setIssuedDigitalContractsHasNoMoreContentToFetch(contracts.length > 0);
                setIssuedDigitalContracts([...issuedDigitalContracts, ...contracts]);  
            })
            .catch(e => {
                console.error("Catched an error", e);
            })
    }, [editableDigitalContractsPage]);

    // - effect that observer editable and issued contracts changes
    useEffect(() => {

        // will store all merged contracts
        let mergedNormalizedContracts : EncapsulatedContract[] = [];

        // merge the contracts
        mergedNormalizedContracts = [
            ...editableDigitalContracts.map<EncapsulatedContract>(c => {
                return {
                    normalized: parseToNormalizedContract(c),
                    source: c
                }
            }),
            ...issuedDigitalContracts.map<EncapsulatedContract>(c => {
                return {
                    normalized: parseToNormalizedContract(c),
                    source: c
                }
            })
        ];

        // include only checked elements
        mergedNormalizedContracts = mergedNormalizedContracts.filter(dc => {
            return digitalContractStateFilters.includes(dc.normalized.state);
        });

        // sort  them using issuanceDate DESC as reference
         mergedNormalizedContracts.sort((a, b) => b.normalized.issuanceDate.getTime() - a.normalized.issuanceDate.getTime());

        // set the state to render the cards
        setNormalizedContracts(mergedNormalizedContracts);

    }, [editableDigitalContracts, issuedDigitalContracts, digitalContractStateFilters]);

    /**
     * Advance the page states to the next value. This will trigger the effects
     * that observe the pages
     */
    function nextPage() {
        if (editableDigitalContractsHasNoMoreContentToFetch) setEditableDigitalContractsPage(editableDigitalContractsPage + 1);
        if (issuedDigitalContractsHasNoMoreContentToFetch) setIssuedDigitalContractsPage(issuedDigitalContractsPage + 1);
    }

    /**
     * handle 'onchange' event for digitalContractStateFiltersChange toggle button
     * @param event 
     * @param clickedFilterValue 
     */
    function handleDigitalContractStateFiltersChange(event : React.MouseEvent<HTMLElement>, clickedFilterValue : DigitalContractStates) : void {
        const currentFilters = [...digitalContractStateFilters];
        let clickedFilterIndex = currentFilters.indexOf(clickedFilterValue);

        if (clickedFilterIndex >= 0) {
            currentFilters.splice(clickedFilterIndex, 1);
        } else {
            currentFilters.push(clickedFilterValue);
        }
        setDigitalContractStateFilters(currentFilters);
    }

    /**
     * Component used to load more content into the list
     * @returns 
     */
     function LoadMoreButton() : JSX.Element {
        // show load more button if editable or issued contracts has more content to fetch
        if (editableDigitalContractsHasNoMoreContentToFetch || issuedDigitalContractsHasNoMoreContentToFetch) {
            return (
                <Button variant="outlined" onClick={nextPage} sx={{display : 'block', margin : 'auto'}} >Carregar mais</Button>
            );
        }
        else {
            return <></>
        }
     }

    return (
        <Box sx={{display : "block", margin : "auto", ...props?.sx}} {...props}>
            <ToggleButtonGroup
                color="primary"
                value={digitalContractStateFilters}
                exclusive
                onChange={handleDigitalContractStateFiltersChange}
                aria-label="Platform"
            >
                {
                    digitalContractStateFiltersAndLabels.map(filter => (
                        <ToggleButton value={filter.value}>{filter.label}</ToggleButton>
                    ))
                }
            </ToggleButtonGroup>
            
            {/* Cards list */}
            <Box sx={{maxHeight: "75vh", overflowY: 'auto'}}>
                {
                    // render normalized contracts cards
                    normalizedContracts.map(contract => {
                        return (
                            <ContractCard source={contract} key={contract.normalized.id} />
                        );
                    })
                }
            </Box>
            {/* Load more button */}
            <LoadMoreButton />
        </Box>
    )
}

export default ContractsSearchList;