import { Close, Sell } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { ExternalDigitalContract } from "../../models/contracts";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../redux/hooks";
import TagsService, { TagsBulk, TagsModel } from "../../services/tags";
import ErrorWrapper from "../../utils/ErrorWrapper";
import TagsAdder from "../TagsAdder";

interface OnCloseCallback {
    (tagDialogClose: boolean, tags: TagsModel[]): void
}

interface TagsDialogProps extends React.ComponentProps<any> {
    open: boolean,
    source: ExternalDigitalContract,
    onClose: OnCloseCallback
}

const ExternalContractTagsDialog = (props: TagsDialogProps): JSX.Element => {

    // Destructure props object
    const { open, source, onClose } = props

    // Context
    const notification = useAppDispatch();

    // Boolean States
    const [loadingTags, setLoadingTags] = useState(false);

    // Data States
    const [contractTags, setContractTags] = useState<TagsModel[]>(source.contractTags ? source.contractTags : []);

    // Functions
    function submitTags() {
        setLoadingTags(true);

        const tagsDTO: TagsBulk = {
            tags: contractTags.map(tag => tag.tagName)
        };

        TagsService.updateExternalContractTags(source, tagsDTO)
            .then(response => {
                notification(show({type: "success", message: "Tags atualizadas com sucesso"}));
            })
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({type: "error", message: err.message}));
            })
            .finally(() => {
                setLoadingTags(false);
                handleCloseDialog();
            });
    }

    // Handler functions
    function handleCloseDialog() {
        onClose(false, contractTags);
    }

    function handleAddedTag(tag: TagsModel) {
        const isTagDuplicate = contractTags.some((t) => t.tagName === tag.tagName);

        if (!isTagDuplicate) {
            const updatedTags = [...contractTags, tag];
            setContractTags(updatedTags);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title">Editar tags do contrato: <b>{source.name}</b></DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexWrap: "wrap", width: "600px", height: "100px", alignItems: "center" }}>
                    {
                        loadingTags
                            ?
                            <CircularProgress />
                            :
                            contractTags
                                ?
                                contractTags.map(t => (
                                    <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "primary.main", m: 1, borderRadius: "36px", flexBasis: "30%", maxWidth: "30%", justifyContent: "end", height: "36px", boxShadow: 3 }}>
                                        <Tooltip title={t.tagName}>
                                            <Typography sx={{ fontSize: "12px", color: "#fff", padding: "6px 16px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{t.tagName}</Typography>
                                        </Tooltip>
                                        <Button onClick={() => {
                                            const updatedTags = contractTags.filter((tag) => t.tagName !== tag.tagName);
                                            setContractTags(updatedTags);
                                        }} startIcon={<Close sx={{ color: "#fff" }} />} />
                                    </Box>))
                                :
                                <></>
                    }
                    <Box sx={{ width: "30%", m: 1 }}>
                        {
                            (contractTags.length >= 10)
                                ?
                                <Tooltip title="Máximo de 10 tags por contrato">
                                    <span>
                                        <Button variant="contained" disabled sx={{ borderRadius: '24px', width: "100%" }} startIcon={<Sell />}>
                                            Adicionar tag
                                        </Button>
                                    </span>
                                </Tooltip>
                                :
                                <TagsAdder onTagAdded={handleAddedTag} />
                        }
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
                <Button variant="contained" onClick={submitTags}>Salvar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ExternalContractTagsDialog;