import { Box, Grid, SxProps, Theme, Button, Typography, Container, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import PayWallDialog from "../../../components/PayWallDialog";
import { Navigate, NavLink } from "react-router-dom";
import { Paid, TrendingUp } from "@mui/icons-material";
import { FinanceAnalyticalOperations } from "./ContractsFinance";
import { PerformanceAnalyticalOperations } from "./AnalyticsPerformance";

interface AnalyticsProps extends React.ComponentProps<any> {
    financialModuleEnabled: Boolean
}

const AnalyticsHome = (props: AnalyticsProps): JSX.Element => {

    //Destructure props object
    const { financialModuleEnabled } = props

    // Boolean states
    const [openPayWallDialog, setOpenPayWallDialog] = useState(!financialModuleEnabled);
    const [enableRedirectAfterCloseDialog, setEnableRedirectAfterCloseDialog] = useState(false);

    // Handler functions
    function handleClosePayWallDialog() {
        setOpenPayWallDialog(false);
        setEnableRedirectAfterCloseDialog(true)
    }

    // Styles
    const themeButton: SxProps<Theme> = {
        ['@media(max-width:800px)']: { fontSize: "10px" },
        width: "100%", minHeight: "450px", borderRadius: "10px", display: "grid", p: 2, boxShadow: 3, textAlign: "center", mt: 3
    }

    const emphasisButtonDescription: SxProps<Theme> = {
        ['@media(max-width:800px)']: { display: "none" },
        color: "#fff", padding: "0px 16px", fontSize: "16px", mb: 3,
    }
    const emphasisButtonTitle: SxProps<Theme> = {
        ['@media(max-width:600px)']: { fontSize: "10px" },
        color: "#fff", my: 3, fontSize: "24px", fontWeight: "bold"
    }

    return (
        <Container>
            {
                financialModuleEnabled
                    ?
                    <Box>
                        <Grid container alignItems={"center"} justifyContent={"center"} columnSpacing={10} height={"88vh"}>
                            <Grid item xs={12} md={6}>
                                <Button sx={themeButton} variant="contained" component={NavLink} to={"/analytics/finance"}>
                                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} >
                                        <Paid sx={{ color: "#fff", margin: "auto", fontSize: "64px" }} />
                                        <Typography sx={emphasisButtonTitle}>Financeiro</Typography>
                                        <Typography sx={emphasisButtonDescription}>Faça a gestão de todos os seus dados financeiros baseado nas informações dos contratos.</Typography>
                                        <Typography color={"white"} sx={{ mb: 2 }}>Tenha acesso a análises como: </Typography>
                                        <List dense={true} sx={{ backgroundColor: "transparent" }}>
                                            {
                                                FinanceAnalyticalOperations.map(f => (
                                                    <ListItem>
                                                        <ListItemIcon style={{ color: "#FFF" }}>
                                                            {f.icon}
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            <Typography color="white">{f.title}</Typography>
                                                        </ListItemText>
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button sx={themeButton} variant="contained" component={NavLink} to={"/analytics/performance"}>
                                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} >
                                        <TrendingUp sx={{ color: "#fff", margin: "auto", fontSize: "64px" }} />
                                        <Typography sx={emphasisButtonTitle}>Performance</Typography>
                                        <Typography sx={emphasisButtonDescription}>Faça a comparação de dados financeiros baseado nas informações dos contratos entre dois períodos</Typography>
                                        <Typography color={"white"} sx={{ mb: 2 }}>Tenha acesso a análises como: </Typography>
                                        <List dense={true} sx={{ backgroundColor: "transparent" }}>
                                            {
                                                PerformanceAnalyticalOperations.map(p => (
                                                    <ListItem>
                                                        <ListItemIcon style={{ color: "#FFF" }}>
                                                            {p.icon}
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            <Typography color="white">{p.title}</Typography>
                                                        </ListItemText>
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    </Box>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <Box>
                        <PayWallDialog open={openPayWallDialog} onClose={handleClosePayWallDialog} quotaName="Gestão Financeira" productName="Modulo de gestão financeira" type="module" />
                        {enableRedirectAfterCloseDialog ? <Navigate to="/" /> : <></>}
                    </Box>
            }
        </Container>
    )
}

export default AnalyticsHome;