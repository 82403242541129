import { Theme } from "@emotion/react";
import { ArrowBack, Comment, Person } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControlLabel, FormGroup, Menu, Popover, Popper, Tab, TextField, Typography, useMediaQuery } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { AxiosError, AxiosResponse } from "axios";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CurrentContext } from "../../appctx/webappContext";
import CreateContractsAccountDialog from "../../components/CreateContractsAccountDialog";
import AuthenticationContext from "../../contexts/authentication";
import OrganizationService from "../../services/organization";
import { ContractsQuota, InterfaceTheme } from "../../models/organizations";
import { useAppDispatch } from "../../redux/hooks";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { createTheme, ThemeProvider } from "@mui/material";

import { AttachmentSpecification, DigitalContractStates, EditableDigitalContract, IdentifiedByEmailContractParty, IssuedDigitalContract, PlatformUserContractParty, RequiredContractPartyInformation } from "../../models/contracts";
import { AuthenticatedUser, UserAccount } from "../../models/user";
import { default as authorizationService, default as AuthorizationService } from "../../services/authorization";
import contractsService from "../../services/contracts";
import AuthenticationStore from "../../store/authentication";
import ErrorWrapper from "../../utils/ErrorWrapper";
import RequiredInformationTab from "./tabs/RequiredInformationsTab";
import Signed from "./tabs/Signed";
import WaitingForEmission from "./tabs/WaitingForEmission";
import WaitingForSignature from "./tabs/WaitingForSignature";
import { TokenTypes } from "../../models/authentication";
import { CreateUserAccount, Regions } from "../../models/organizations";
import UserAccountService from "../../services/user-account";
import jwt_decode from 'jwt-decode';
import { CleanCPFOrCNPJ } from "../../utils/stringUtils";
import { cnpj, cpf } from "cpf-cnpj-validator";

interface EidToken {
    sub: string;
    iss: string;
    iat: number;
    exp: number;
    eid_type: string;
    eid: string;
    roles: string[];
}

/**
 * This view is a standalone application. It serves to guarantee a user experience dedicated to facilitating the flow to sign the contract. 
 * It is extremely important to understand that this application *Cannot influence the logged in user*, since whenever the user arrives at this page he must log in.
 * 
 * The logged in user will only belong to this view, disregarding the use of /store/authentication. 
 * The logged in user should be stored in "memory" using state, ref, or whatever the developer prefers.
 * @returns 
 */
const StepsForContractSignatureView = (): JSX.Element => {

    const storage = localStorage;

    // contexts
    const authUser = React.useContext(AuthenticationContext);
    const navigate = useNavigate();

    const notification = useAppDispatch()

    // fetch the url params (contractId) and query params (requiredEmail)
    const [urlEmail] = useSearchParams();
    const eidToken = urlEmail.get("eidToken")
    const { contractId } = useParams();
    const [requiredEmail, setRequiredEmail] = useState(urlEmail.get('requiredEmail'));
    const [contract, setContract] = useState<EditableDigitalContract | IssuedDigitalContract>();
    const [editableContract, setEditableContract] = useState<EditableDigitalContract>();
    const [issuedContract, setIssuedContract] = useState<IssuedDigitalContract>();
    const [haveContract, setHaveContract] = useState(false);
    const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    //Authentication States
    const [showCreateContractsAccountDialog, setShowCreateContractsAccountDialog] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser>();
    const [searchParams, setSearchParams] = useSearchParams();

    // Tabs States
    const [value, setValue] = useState(0);
    const [tab, setTab] = useState("0");

    const [token, setToken] = useState(searchParams.get('token'));

    const [requiredInformationsTabIsReacheable, setRequiredInformationsTabIsReacheable] = useState(false);
    const [requiredInformationsFinished, setRequiredInformationsFinished] = useState(false);

    const [waitingForEmissionTabIsReacheable, setWaitingForEmissionTabIsReacheable] = useState(false);
    const [waitingForEmissionFinished, setWaitingForEmissionFinished] = useState(false);

    const [waitingForSignaturesTabIsReacheable, setWaitingForSignaturesTabIsReacheable] = useState(false);
    const [waitingForSignaturesFinished, setWaitingForSignaturesFinished] = useState(false);

    const [signedTabIsReacheable, setSignedTabIsReacheable] = useState(false);

    const [issuerOrganizationQuota, setIssuerOrganizationQuota] = useState<ContractsQuota>();
    const [inCacheUser, setInCacheUser] = useState<AuthenticatedUser>();

    const [isAContractParty, setIsAContractParty] = useState(false);
    const [verifiedContractParty, setVerifiedContractParty] = useState<IdentifiedByEmailContractParty | PlatformUserContractParty>();

    const [userIsNotAuthorizedToSeeTheContract, setUserIsNotAuthorizedToSeeTheContract] = useState(false)
    const [contractNotFound, setContractNotFound] = useState(false);

    const [showCreateUserAccountDialog, setShowCreateUserAccountDialog] = useState(false)

    const [eid, setEid] = useState<string | null>(null)

    const [interfaceTheme, setInterfaceTheme] = useState<InterfaceTheme | null>(null)

    const [openComment, setOpenComment] = useState(false);
    const [commentAE, setCommentAE] = useState<HTMLElement | null>(null)

    const [contractPartyComment, setContractPartyComment] = useState<string[] | undefined>(undefined)

    const fabRef = useRef<HTMLButtonElement | null>(null);

    const isMobile = useMediaQuery("(max-width:800px)")

    async function fetchUser() {
        const contractsSessions = await AuthorizationService.me();
        setInCacheUser(contractsSessions);
    }

    useEffect(() => {
        fetchUser()
    }, [])

    const tabTheme: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            fontSize: "0.75rem",
            padding: "0.25rem 0.5rem"
        },
        ['@media(max-width:350px)']: {
            fontSize: "0.5rem",
            padding: "0.125rem 0.25rem"
        },
        fontSize: "0.875rem",
        maxHeight: "60px",
        minHeight: "60px"
    }

    const signButtonDisabledTheme: SxProps<Theme> = {
        "&.Mui-disabled": {
            color: signedTabIsReacheable ? "#FFF" : "#333",
            fontWeight: "400"
        }
    }
    const riButtonDisabledTheme: SxProps<Theme> = {
        "&.Mui-disabled": {
            color: requiredInformationsFinished ? "#FFF" : "#333",
            fontWeight: "400"
        }
    }
    const emissionButtonDisabledTheme: SxProps<Theme> = {
        "&.Mui-disabled": {
            color: waitingForEmissionFinished ? "#FFF" : "#333",
            fontWeight: "400"
        }
    }
    const waitSignButtonDisabledTheme: SxProps<Theme> = {
        "&.Mui-disabled": {
            color: waitingForSignaturesFinished ? "#FFF" : "#333",
            fontWeight: "400"
        }
    }

    const tabFinishedTheme: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            fontSize: "0.9rem",
        },
        ['@media(max-width:350px)']: {
            fontSize: "0.7rem",
        },
        fontSize: "1.25rem",
        maxHeight: "60px",
        minHeight: "60px",
        marginLeft: "0.5rem",
        color: "#fff",
    }
    const responsivenessTheme: SxProps<Theme> = {
        ['@media(max-width:900px)']: {
            mr: "0px",
            ml: "0px"
        },
        ['@media(max-width:450px)']: {
            display: "flex",
        },
    }
    const responsivenessOutTheme: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            display: "none",
        },
    }
    const responsivenessInTheme: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            display: "flex",
        },
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: interfaceTheme ? interfaceTheme.primaryColor : "#64067f"
            },
            secondary: {
                main: interfaceTheme ? interfaceTheme.secondaryColor : "#64067f"
            }
        },
        typography: {

            allVariants: {

                fontFamily: 'Noto Sans',

                color: '#212121'

            },

            caption: {

                color: '#8A8A8A'

            },

            button: {

                textTransform: 'none'

            }

        },
    })

    // validate the token by the authentication token
    async function validateToken(authenticationToken: string) {
        setShowCreateContractsAccountDialog(false);
        setShowCreateUserAccountDialog(false);
        try {
            await authUser.authenticationWithAccountsToken(authenticationToken)
            await authorizeMe();
            await fetchContract();
        }
        catch (e) {
            const error = new ErrorWrapper(e);
            // if the user does not have an account created in contracts platform
            console.error("An error occur", error, token, requiredEmail);
            if (error.httpStatus === 403) {
                setShowCreateContractsAccountDialog(true);
            }
            else {
                console.error(e);
            }
        }
        return;
    }

    // return session data related to the token used in the request.
    async function authorizeMe() {
        setLoading(true)
        let authMe = await authorizationService.me();
        setInCacheUser(authMe);
        setAuthenticatedUser(authMe);
        setUserIsAuthenticated(true);
        await fetchContract();
        setLoading(false)
    }

    // fetch the contract in the editable contracts, if found, set the state "haveContract" true. If the contract is not found, search for the contract in the issued contracts
    async function fetchContract() {
        setLoading(true)
        if (contractId) {
            try {
                const response = await contractsService.fetchEditableOrIssuedDigitalContract(contractId);

                if (response.state === 'NOT_READY_TO_BE_ISSUED' || response.state === 'READY_TO_BE_ISSUED') {
                    setEditableContract(response as EditableDigitalContract)
                    setContract(response)
                    setHaveContract(true)
                    return
                }
                else if (response.state === 'WAITING_FOR_SIGNATURES' || response.state === 'SIGNED' || response.state === 'INACTIVE') {
                    setIssuedContract(response as IssuedDigitalContract)
                    setContract(response)
                    setHaveContract(true)
                    return
                }
                else {
                    setLoading(false)
                    throw new Error("Contract not found. Could not identify the contract state")
                }
            }
            catch (e) {
                //if the error is 403 unauthorized, setUserIsNotAuthorizedToSeeTheContract to true and show the respective page in return
                const error = new ErrorWrapper(e);
                if (error.httpStatus === 403) {
                    setUserIsNotAuthorizedToSeeTheContract(true)
                }
                //if there's any error trying to find the contract, setContractNotFound to true and show the respective page in return
                setContractNotFound(true)
            }
            finally {
                setLoading(false)
            }
        }
    }

    async function fetchIssuerOrganizationQuota(contract: EditableDigitalContract | IssuedDigitalContract) {
        try {
            const response = await OrganizationService.fetchPrivateApiIssuerOrganizationContractsQuota(contract.organizationOwnerId)

            setIssuerOrganizationQuota(response)
        } catch (e) {
            const error = new ErrorWrapper(e)
            console.error(error)
            notification(show({
                type: 'error',
                message: 'Erro ao buscar as cotas da organização'
            }))

        }
    }

    function fetchInterfaceTheme(contract: EditableDigitalContract | IssuedDigitalContract) {
        OrganizationService.publicFetchOrganizationInterfaceTheme(contract.organizationOwnerId).then(response => {
            setInterfaceTheme(response)
        }).finally(() => setLoading(false))
    }

    // Verify if the verified user already filled the informations and the attachments
    function verifyIfAllFieldsHasBeenFilledInForThisContractPartie(editableContract: EditableDigitalContract): boolean {
        if (!editableContract) {
            return false;
        }
        if (!authenticatedUser) {
            return false;
        }

        let count = 0;
        let requiredFields = [];

        (editableContract.template.requiredContractPartiesInformation as RequiredContractPartyInformation[]).filter(rcpi => {
            // only fetch required informations if:
            // 1) The authenticated user is the issuer and the template is allowed to recieve data from the issuer
            // 2) or, if the authenticated user is the target contract party role
            const filterResult =
                (editableContract.issuerUuid === authenticatedUser.account.uuid && editableContract.template.allowIssuerToSendRequiredData) ||
                (verifiedContractParty?.role === rcpi.contractParty.role)
            return filterResult;
        }).forEach(rcpi => {
            const userInput = editableContract.userInformation?.find(ui => ui.requiredInformation.requiredInformation.name === rcpi.requiredInformation.name);
            if (userInput) { count += 1 }
            else { count -= 1; requiredFields.push(userInput) };
        });

        (editableContract.template.attachmentSpecifications as AttachmentSpecification[])
            .filter(as => {
                // only fetch required informations if:
                // 1) The authenticated user is the issuer and the template is allowed to recieve data from the issuer
                // 2) or, if the authenticated user is the target contract party role
                return (editableContract.issuerUuid === authenticatedUser.account.uuid && editableContract.template.allowIssuerToSendRequiredData) ||
                    (verifiedContractParty?.role === as.contractParty.role)
            })
            .forEach(as => {
                const userAttachment = editableContract.attachments?.find(a => a.name === as.name);
                if (userAttachment) { count += 1 }
                else { count -= 1; requiredFields.push(userAttachment) };
            })

        return (
            (count >= 0 && requiredFields.length == 0)
        )
    }

    // function used to change the state of the current tabs
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    // fetch the contract parties
    useEffect(() => {
        if (contract) {
            contract.contractParties.forEach(contractParty => {

                // verify if the contract party is identified by email
                if (contractParty.contractPartyIdentificationType === "IDENTIFIED_BY_EMAIL") {
                    contractParty = contractParty as IdentifiedByEmailContractParty;

                    // Check if the contract party is the same as the contract by its email
                    if (contractParty.email === authenticatedUser?.account.email) {
                        setVerifiedContractParty(contractParty);

                        if (contractParty.comment) {
                            setContractPartyComment(contractParty.comment.split("\n"))
                        }
                    }
                }
                // verify if the contract party is a platform user
                else if (contractParty.contractPartyIdentificationType === "PLATFORM_USER") {
                    contractParty = contractParty as PlatformUserContractParty;

                    // Check if the contract party is the same as the contract by its uuid
                    if (contractParty.uuid === authenticatedUser?.account.uuid) {
                        setVerifiedContractParty(contractParty)
                        if (contractParty.comment) {
                            setContractPartyComment(contractParty.comment.split("\n"))
                        }
                    }
                }
            })
        }
    }, [contract])

    // check if the url has the required email, if not, redirect to login
    useEffect(() => {
        if (eidToken) {
            storage.clear()
            getUserAccount()
        } else if (requiredEmail) {
            window.location.replace(`${CurrentContext.authWebappUrl}/login?service=contracts&requiredEmail=${requiredEmail}&redirectUrl=/registry/${contractId}`)

        }
    }, []);

    // fetch the issuer organization quota
    useEffect(() => {
        if (contract) {
            setLoading(true)
            fetchIssuerOrganizationQuota(contract)
            if (contract.personalizationActive) {
                fetchInterfaceTheme(contract)
            } else {
                setLoading(false)
            }
        }
    }, [contract])

    useEffect(() => {
        if (token) {
            validateToken(token)
            searchParams.delete("token");
            searchParams.delete("accountType");
            setSearchParams(searchParams);
        }
        else {
            const tokenFromStore = AuthenticationStore.getAccountsToken();
            if (tokenFromStore) {
                setToken(tokenFromStore);
            }
            else {
                if (!eidToken) {
                    let url = `${CurrentContext.authWebappUrl}/login?service=contracts&redirectUrl=/registry/${contractId}`;
                    if (requiredEmail) url += "&requiredEmail=" + requiredEmail;
                    window.location.replace(url)
                }
            }
        }
    }, [token])

    const getUserAccount = async () => {
        if (eidToken) {
            searchParams.delete("eidToken")
            if (requiredEmail) {
                searchParams.delete("requiredEmail")
            }
            setSearchParams(searchParams)
            const validatedToken = await AuthorizationService.issueWithEidToken(eidToken).catch(e => {
                const err = new ErrorWrapper(e)
                if (err.httpStatus === 403) {
                    setShowCreateUserAccountDialog(true)

                    const decodedEidToken = jwt_decode(eidToken) as EidToken

                    if (decodedEidToken.eid_type === "Email") {
                        setRequiredEmail(decodedEidToken.eid)
                        setEid(decodedEidToken.eid)
                    }

                } else {
                    notification(show({
                        type: "error",
                        message: `Ocorreu um erro durante a verificação de conta: ${err.message}`
                    }))
                    console.error(e)
                    window.location.replace(`${CurrentContext.authWebappUrl}/login?service=contracts&redirectUrl=/registry/${contractId}`)
                }
            })

            if (validatedToken) {
                await AuthenticationStore.authenticationWithAccountsToken(validatedToken)
                authorizeMe()
            }
        }
    }

    // use effect to check current state of contract and change tabs
    useEffect(() => {
        if (contract) {
            if (contract.state === 'NOT_READY_TO_BE_ISSUED') {
                setRequiredInformationsTabIsReacheable(true)
                setWaitingForEmissionTabIsReacheable(verifyIfAllFieldsHasBeenFilledInForThisContractPartie((contract as EditableDigitalContract)))
                setWaitingForSignaturesTabIsReacheable(false)
                setSignedTabIsReacheable(false)
            }
            else if (contract.state === 'READY_TO_BE_ISSUED') {
                setRequiredInformationsTabIsReacheable(true)
                setWaitingForEmissionTabIsReacheable(true)
                setWaitingForSignaturesTabIsReacheable(false)
                setSignedTabIsReacheable(false)
            }
            else if (contract.state === 'WAITING_FOR_SIGNATURES') {
                setRequiredInformationsFinished(true)
                setRequiredInformationsTabIsReacheable(false)
                setWaitingForEmissionFinished(true)
                setWaitingForEmissionTabIsReacheable(false)
                setWaitingForSignaturesTabIsReacheable(true)
                setSignedTabIsReacheable(false)
            }
            else if (contract.state === 'SIGNED') {
                setRequiredInformationsFinished(true)
                setRequiredInformationsTabIsReacheable(false)
                setWaitingForEmissionFinished(true)
                setWaitingForEmissionTabIsReacheable(false)
                setWaitingForSignaturesFinished(true)
                setWaitingForSignaturesTabIsReacheable(false)
                setSignedTabIsReacheable(true)
            }
            else if (contract.state === 'INACTIVE') {
                setRequiredInformationsFinished(true)
                setRequiredInformationsTabIsReacheable(false)
                setWaitingForEmissionFinished(true)
                setWaitingForEmissionTabIsReacheable(false)
                setWaitingForSignaturesFinished(true)
                setWaitingForSignaturesTabIsReacheable(false)
                setSignedTabIsReacheable(true)
            }
        }
    }, [contract, waitingForEmissionTabIsReacheable])

    // when the contract its loaded, verify the state of the contract, and set the corresponding tab
    useEffect(() => {
        if (contract) {
            if (contract.state == 'NOT_READY_TO_BE_ISSUED') {
                // Thinking about improving the UX, when the contract is "NOT_READY_TO_BE_ISSUED" but the user has already 
                // filled in the required information, send it to the "WaitingForEmission" tab
                if (verifyIfAllFieldsHasBeenFilledInForThisContractPartie((contract as EditableDigitalContract))) {
                    setTab('2')
                }
                else {
                    setTab('1')
                }
            }
            else if (contract.state === 'READY_TO_BE_ISSUED') {
                setTab('2')
            }
            else if (contract.state === 'WAITING_FOR_SIGNATURES') {
                setTab('3')
            }
            else if (contract.state === 'SIGNED' || contract.state === 'INACTIVE') {
                setTab('4')
            }
        }
    }, [contract, waitingForEmissionTabIsReacheable])

    useEffect(() => {
        if (contract && inCacheUser) {
            checkIfContractPartie()
        }
    }, [contract, token, inCacheUser]);

    function checkIfContractPartie() {
        if (contract && inCacheUser) {
            contract.contractParties.forEach(cp => {
                if ((cp as PlatformUserContractParty).uuid) {
                    if ((cp as PlatformUserContractParty).uuid == inCacheUser.account.uuid) {
                        setIsAContractParty(true);
                    }
                }
                else if ((cp as IdentifiedByEmailContractParty).email) {
                    if ((cp as IdentifiedByEmailContractParty).email.toLocaleLowerCase() === inCacheUser.account.email.toLocaleLowerCase()) {
                        setIsAContractParty(true);
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (!contract || !fabRef || isMobile) return;

        if (
            contract.state === DigitalContractStates.NOT_READY_TO_BE_ISSUED ||
            contract.state === DigitalContractStates.READY_TO_BE_ISSUED
        ) {
            setCommentAE(fabRef.current)
            setOpenComment(true)
        }
    }, [fabRef, contract])

    function handleClickShowMessage(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (openComment) {
            setOpenComment(false)
            setCommentAE(null)
        } else {
            setOpenComment(true)
            setCommentAE(e.currentTarget)
        }
    }

    const CreateUserAccountDialog = (): JSX.Element => {

        const [name, setName] = useState<string | null>(null);
        const [prefferedName, setPrefferedName] = useState<string | null>(null);
        const [document, setDocument] = useState<string | null>(null);
        const [dirtyDocument, setDirtyDocument] = useState("");

        const [isGringo, setIsGringo] = useState(false);
        const [validDocument, setValidDocument] = useState(false);

        const [nameError, setNameError] = useState(false);
        const [prefferedNameError, setPrefferedNameError] = useState(false);

        const [dialogLoading, setDialogLoading] = useState(false);

        useEffect(() => {
            checkIfDocumentIsValid();
        }, [document])

        function checkIfDocumentIsValid() {
            if (!document) return;
            setValidDocument(cnpj.isValid(document) || cpf.isValid(document))
        }

        const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (e.target.value === "") {
                setNameError(true)
            } else {
                setNameError(false)
            }

            setName(e.target.value)
            setPrefferedName(e.target.value)
        }

        const handlePreferredChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (e.target.value === "") {
                setPrefferedNameError(true)
            } else {
                setPrefferedNameError(false)
            }

            setPrefferedName(e.target.value)
        }

        function handleChangeDocument(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            setDirtyDocument(e.target.value);
            const normalizedValue = CleanCPFOrCNPJ(e.target.value);
            setDocument(normalizedValue);
        }

        const handleCreateAccount = async () => {
            setDialogLoading(true)
            try {
                if (eid && name && prefferedName) {
                    const userAccount: CreateUserAccount = {
                        email: eid,
                        fullname: name,
                        preferredName: prefferedName,
                        preferredRegion: Regions.BRA,
                        roles: ["ROLE_CONTRACT_SAS_SIGN_CONTRACT"],
                        document: !isGringo ? document : null
                    }

                    const newAuthToken = await OrganizationService.createUserAccount(userAccount).catch(e => {
                        const err = new ErrorWrapper(e)

                        notification(show({
                            type: "error",
                            message: `Ocorreu um erro durante a criação da conta: ${err.message}`
                        }))
                        console.error(e)
                        window.location.replace(`${CurrentContext.authWebappUrl}/login?service=contracts&requiredEmail=${eid}&redirectUrl=/registry/${contractId}`)
                    })


                    if (newAuthToken) {
                        setShowCreateContractsAccountDialog(true)
                        setShowCreateUserAccountDialog(false)
                        setToken(newAuthToken)
                    }
                }
            } finally {
                setDialogLoading(false)
            }
        }

        return (
            <Box>
                <Dialog open={showCreateUserAccountDialog} >
                    <Box p={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <img src="/images/cartorize-logo.svg" alt="Cartorizi" style={{ maxWidth: "250px", marginBottom: "1rem" }} />
                        <Typography variant="h5" mb={1.5} >Essa é a sua primeira vez utilizando o Cartorizi</Typography>
                        <Typography variant="h6" >Por favor informe seu nome</Typography>
                        <TextField value={name} onChange={handleNameChange} label="Nome Completo" margin="normal" fullWidth required error={nameError} />
                        <TextField value={prefferedName} onChange={handlePreferredChange} label={prefferedName ? '' : "Nome de Apresentação"} placeholder="Nome da Apresentação" margin="normal" fullWidth required error={prefferedNameError} />

                        <FormGroup sx={{ my: 2 }} >
                            <FormControlLabel label="Sou estrangeiro" control={<Checkbox checked={isGringo} onChange={(e) => setIsGringo(e.target.checked)} />} />
                        </FormGroup>

                        {
                            !isGringo
                                ?
                                <TextField sx={{ mb: 2 }} value={document} label="CPF ou CNPJ" onChange={handleChangeDocument} fullWidth required error={!validDocument} />
                                :
                                <></>
                        }

                        <LoadingButton loading={dialogLoading} variant="contained" disabled={(!name && !prefferedName) || nameError || prefferedNameError || (!document && !isGringo)} onClick={handleCreateAccount} >Criar Conta</LoadingButton>
                    </Box>
                </Dialog>
            </Box>
        )
    }

    if (userIsNotAuthorizedToSeeTheContract == true) {
        return (
            <Box sx={{ display: 'table', height: "100vh", width: "100vw", backgroundColor: "#efd8fa" }}>
                <Box sx={{ verticalAlign: "middle", display: "table-cell", textAlign: "center" }}>
                    <Box sx={{ backgroundColor: "#fff", boxShadow: 3, width: "600px", height: "800px", borderRadius: "10px", display: "inline-grid", padding: "24px 48px", alignContent: "baseline", }}>
                        <img src="/images/cartorize-logo.svg" style={{ width: "30%", margin: "auto", marginTop: 10 }} />
                        <Divider sx={{ mt: 2, mb: 8 }} />
                        <img src="/images/cartorizi_err.svg" style={{ width: "70%", margin: "auto" }} />
                        <Typography color="primary" sx={{ fontSize: "30px", mt: 8, fontWeight: 600 }}>Você não tem permissão para visualizar esse contrato</Typography>
                        <Typography color="secondary" sx={{ fontSize: "20px", mt: 10 }}>Caso ache que está recebendo essa mensagem por engano, peça para que o emissor do contrato envie novamente o link do contrato com o participante requerido.</Typography>
                        <Button sx={{ mt: 8 }} variant="contained" onClick={() => navigate("/")}>Voltar</Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    if (contractNotFound) {
        <Box sx={{ display: 'table', height: "100vh", width: "100vw", backgroundColor: "#efd8fa" }}>
            <Box sx={{ verticalAlign: "middle", display: "table-cell", textAlign: "center" }}>
                <Box sx={{ backgroundColor: "#fff", boxShadow: 3, width: "600px", height: "800px", borderRadius: "10px", display: "inline-grid", padding: "24px 48px", alignContent: "baseline", }}>
                    <img src="/images/cartorize-logo.svg" style={{ width: "30%", margin: "auto", marginTop: 10 }} />
                    <Divider sx={{ mt: 2, mb: 8 }} />
                    <img src="/images/cartorizi_err.svg" style={{ width: "70%", margin: "auto" }} />
                    <Typography color="primary" sx={{ fontSize: "30px", mt: 8, fontWeight: 600 }}>Contrato não encontrado</Typography>
                    <Typography color="secondary" sx={{ fontSize: "20px", mt: 10 }}>Não foi possível encontrar o contrato.</Typography>
                    <Typography color="secondary" sx={{ fontSize: "20px" }}>O link que você está acessando pode estar incorreto ou o contrato pode ter sido deletado.</Typography>
                    <Typography color="secondary" sx={{ fontSize: "20px", mt: 4 }}>Caso tenha dúvidas, entre em contato com o suporte.</Typography>
                    <Button sx={{ mt: 8 }} variant="contained" onClick={() => navigate("/")}>Voltar</Button>
                </Box>
            </Box>
        </Box>
    }

    if (!userIsAuthenticated || loading) {
        return (
            <Box sx={{ display: 'table', height: "100vh", width: "100vw" }}>
                {
                    (token)
                        ?
                        <Box sx={{ display: 'table', height: "100vh", width: "100vw" }}>
                            <Box sx={{ verticalAlign: "middle", display: "table-cell", textAlign: "center" }}>
                                <CircularProgress color="secondary" size={100} sx={{ display: "block", margin: "auto", marginBottom: 3 }} />
                                <Typography color="secondary" sx={{ fontSize: "36px" }}>Resgatando informações do contrato...</Typography>
                            </Box>

                            <CreateContractsAccountDialog
                                open={showCreateContractsAccountDialog}
                                onAuthorizationTokenIssued={() => validateToken(token)}
                                klausAuthenticationToken={token}
                            />
                        </Box >
                        :
                        <Box sx={{ display: 'table', height: "100vh", width: "100vw" }}>
                            <Box sx={{ verticalAlign: "middle", display: "table-cell", textAlign: "center" }}>
                                <CircularProgress color="secondary" size={100} sx={{ display: "block", margin: "auto", marginBottom: 3 }} />
                                <Typography color="secondary" sx={{ fontSize: "36px" }}>Resgatando informações do contrato...</Typography>
                            </Box>

                            <CreateUserAccountDialog />
                        </Box>
                }

            </Box >
        )
    }

    if (haveContract) {
        if (isAContractParty || contract?.issuerUuid === authenticatedUser?.account.uuid) {
            return (
                < Box sx={{ height: "100vh", mb: 4 }} >
                    <Box sx={{ width: "100%", height: "50px", backgroundColor: interfaceTheme ? interfaceTheme.primaryColor : "#333333", display: "flex", alignItems: "center", justifyContent: "space-between", position: "sticky", bottom: "0" }}>
                        <Box>
                            <Button onClick={() => window.location.replace(`/`)} sx={[responsivenessTheme, { fontSize: "14px", color: "#fff", ml: "20px" }]}>
                                <ArrowBack sx={{ fontSize: "18px", mr: "4px" }} /><Typography sx={[responsivenessOutTheme, { fontSize: "14px", color: "#fff", }]}>Voltar</Typography>
                            </Button>
                        </Box>
                        <Box>
                            {
                                interfaceTheme
                                    ?
                                    <Box sx={{ width: "125px", height: "40px", display: "inline-block", marginRight: "1rem" }}>
                                        <img src={`${interfaceTheme.logoUrl}?randomText=${(Math.random() + 1).toString(36).substring(7)}`} alt="" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                    </Box>
                                    :
                                    <></>
                            }
                        </Box>
                        <Box sx={{ maxWidth: "33%" }}>
                            <Typography sx={{ fontSize: "14px", color: "#fff", display: "flex", alignItems: "center", mr: "20px", overflow: "auto" }}><Person />{inCacheUser?.account.email}</Typography>
                        </Box>
                    </Box>
                    <ThemeProvider theme={theme}>
                        <TabContext value={tab}>
                            <TabList variant="fullWidth" value={value} onChange={(e, selectedTab) => setTab(selectedTab)} centered sx={{ color: interfaceTheme ? interfaceTheme.secondaryColor : "#64067f", bgcolor: "#FFFFFF", boxShadow: 3 }} textColor="secondary" indicatorColor="secondary">
                                {/* Tab Zero */}
                                <Tab
                                    sx={{ minWidth: "0px", maxWidth: "0px", padding: "0px", height: "60px", maxHeight: "60px" }}
                                    value="0"
                                />
                                {/* Informações e Anexos Requeridos */}
                                <Tab
                                    sx={[
                                        tabTheme,
                                        { color: !requiredInformationsTabIsReacheable ? riButtonDisabledTheme : interfaceTheme ? interfaceTheme.secondaryColor : "#64067f" },
                                        {
                                            fontWeight: "600",
                                            backgroundColor: !requiredInformationsFinished ? '#ffffff' : interfaceTheme ? interfaceTheme.secondaryColor : '#64067F'
                                        }
                                    ]}
                                    label="Informações e Anexos Requeridos"
                                    disabled={!requiredInformationsTabIsReacheable}
                                    value="1"
                                    icon={(!requiredInformationsTabIsReacheable ? <CheckCircleIcon sx={tabFinishedTheme} /> : undefined)}
                                    iconPosition="end"
                                />

                                {/* Aguardando Emissão */}
                                <Tab sx={[
                                    tabTheme,
                                    { color: !waitingForEmissionTabIsReacheable ? emissionButtonDisabledTheme : interfaceTheme ? interfaceTheme.secondaryColor : "#64067f" },
                                    {
                                        fontWeight: "600",
                                        backgroundColor: !waitingForEmissionFinished ? '#ffffff' : interfaceTheme ? interfaceTheme.secondaryColor : '#64067F'
                                    }
                                ]}
                                    label="Aguardando Emissão"
                                    disabled={!waitingForEmissionTabIsReacheable}
                                    value="2"
                                    icon={(!waitingForEmissionTabIsReacheable ? <CheckCircleIcon sx={tabFinishedTheme} /> : undefined)}
                                    iconPosition="end"
                                />

                                {/* Aguardando Assinaturas */}
                                <Tab sx={[
                                    tabTheme,
                                    { color: !waitingForSignaturesTabIsReacheable ? waitSignButtonDisabledTheme : interfaceTheme ? interfaceTheme.secondaryColor : "#000" },
                                    {
                                        fontWeight: "600",
                                        backgroundColor: !waitingForSignaturesFinished ? '#ffffff' : interfaceTheme ? interfaceTheme.secondaryColor : '#64067F'
                                    }
                                ]}
                                    label="Aguardando Assinatura"
                                    disabled={!waitingForSignaturesTabIsReacheable}
                                    value="3"
                                    icon={(!waitingForSignaturesTabIsReacheable ? <CheckCircleIcon sx={tabFinishedTheme} /> : undefined)}
                                    iconPosition="end"
                                />

                                {/* Assinado */}
                                <Tab sx={[
                                    tabTheme,
                                    { color: !signedTabIsReacheable ? signButtonDisabledTheme : interfaceTheme ? interfaceTheme.secondaryColor : "#64067f" },
                                    {
                                        fontWeight: "600",
                                        backgroundColor: '#ffffff'
                                    }]}
                                    label="Assinado"
                                    disabled={!signedTabIsReacheable}
                                    value="4"
                                />
                            </TabList>
                            <Box>
                                {/* Informações e Anexos Requeridos */}
                                <TabPanel sx={{ height: "100%", bgcolor: "#efe2f0", minHeight: "100vh" }} value="1">
                                    <RequiredInformationTab source={editableContract} user={authenticatedUser} interfaceTheme={interfaceTheme} />
                                </TabPanel>

                                {/* Aguardando Emissão */}
                                <TabPanel sx={{ height: "100%", bgcolor: "#efe2f0", minHeight: "100vh" }} value="2">
                                    <WaitingForEmission source={editableContract} user={authenticatedUser} interfaceTheme={interfaceTheme} />
                                </TabPanel>

                                {/* Aguardando Assinaturas */}
                                <TabPanel sx={{ height: "100%", minHeight: "100vh" }} value="3" >
                                    {
                                        (issuedContract)
                                            ?
                                            <WaitingForSignature source={issuedContract} user={authenticatedUser} interfaceTheme={interfaceTheme} />
                                            :
                                            <CircularProgress />
                                    }

                                </TabPanel>

                                {/* Assinado */}
                                <TabPanel sx={{ height: "100%", minHeight: "100vh" }} value="4">
                                    {
                                        (issuedContract)
                                            ?
                                            <Signed source={issuedContract} user={authenticatedUser} interfaceTheme={interfaceTheme} />
                                            :
                                            <CircularProgress />
                                    }

                                </TabPanel>
                            </Box>
                        </TabContext>
                        {
                            (contractPartyComment)
                                ?
                                <>
                                    <Fab
                                        color="primary"
                                        aria-label="ver mensagem"
                                        sx={{
                                            position: "fixed",
                                            bottom: "48px",
                                            left: "16px"
                                        }}
                                        onClick={(e) => { handleClickShowMessage(e) }}
                                        ref={fabRef}
                                    >
                                        <Comment />
                                    </Fab>
                                    <Popper
                                        open={openComment}
                                        anchorEl={commentAE}

                                    >
                                        <Box
                                            sx={{
                                                ml: "16px",
                                                mb: "8px",
                                                maxWidth: "400px",
                                                maxHeight: "450px",
                                                overflow: "auto",
                                                backgroundColor: "#FFF",
                                                boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.25)"
                                            }}
                                        >
                                            <Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        width: "100%",
                                                        backgroundColor: (theme) => theme.palette.primary.main,
                                                        mb: 2,
                                                        py: 1,
                                                        position: "sticky",
                                                        top: 0
                                                    }}
                                                >
                                                    <Typography variant="h6" color="#FFF">Nota da Organização</Typography>
                                                </Box>
                                                <Box p={"24px"}>
                                                    {
                                                        (contractPartyComment)
                                                            ?
                                                            contractPartyComment.map(comment => (
                                                                <Typography variant="body1" sx={{ wordWrap: "break-word" }}>{comment}</Typography>
                                                            ))
                                                            :
                                                            <></>
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Popper>
                                </>
                                :
                                <></>
                        }
                    </ThemeProvider>
                    <Box sx={{ width: "100%", height: "32px", backgroundColor: "#333333", display: "flex", alignItems: "center", justifyContent: "center", position: "fixed", bottom: "0" }}>

                        <Box sx={[responsivenessOutTheme, { display: "flex", alignItems: "end" }]}>
                            <Typography sx={{ color: "#fff", fontSize: "10px", mr: "4px" }}>Secured by</Typography>
                            <img src="/images/cartorizi_white_logo.svg" height="24px" />
                        </Box>
                        <Box sx={[responsivenessInTheme, { display: "none" }]}>
                            <img src="/images/minimalist_logo.svg" height="24px" />
                        </Box>

                    </Box>
                </Box >
            )
        } else {
            return (
                <>Teste</>
            )
        }
    } else {
        return (
            <Box sx={{ display: 'table', height: "100vh", width: "100vw", backgroundColor: "#efd8fa" }}>
                <Box sx={{ verticalAlign: "middle", display: "table-cell", textAlign: "center" }}>
                    <Box sx={{ backgroundColor: "#fff", boxShadow: 3, width: "600px", height: "800px", borderRadius: "10px", display: "inline-grid", padding: "24px 48px", alignContent: "baseline", }}>
                        <img src="/images/cartorize-logo.svg" style={{ width: "30%", margin: "auto", marginTop: 10 }} />
                        <Divider sx={{ mt: 2, mb: 8 }} />
                        <img src="/images/cartorizi_err.svg" style={{ width: "70%", margin: "auto" }} />
                        <Typography color="primary" sx={{ fontSize: "30px", mt: 8, fontWeight: 600 }}>Contrato não encontrado</Typography>
                        <Typography color="secondary" sx={{ fontSize: "20px", mt: 10 }}>Não foi possível encontrar o contrato.</Typography>
                        <Typography color="secondary" sx={{ fontSize: "20px" }}>O link que você está acessando pode estar incorreto ou o contrato pode ter sido deletado.</Typography>
                        <Typography color="secondary" sx={{ fontSize: "20px", mt: 4 }}>Caso tenha dúvidas, entre em contato com o suporte.</Typography>
                        <Button sx={{ mt: 8 }} variant="contained" onClick={() => navigate("/")}>Voltar</Button>
                    </Box>
                </Box>
            </Box>)
    }
}


export default StepsForContractSignatureView;