import { Pagination } from "../models/pagination";
import { AccountRoles, UserAccount } from "../models/user";
import AccountsApi from "./accounts-api";
import contractsAPI from "./contracts-api";

interface ApiSearchResult {
    type: 'ORGANIZATION_ACCOUNT' | 'ORGANIZATION_SUBUSER_ACCOUNT' | 'ORGANIZATION_TEAM';
    data: any
}

interface ImportFromKlausAccountsDTO {
    targetUUID: string,
    roles: AccountRoles[]
}

interface UpdateRolesDTO {
    roles: AccountRoles[]
}

const SubusersService = {
    /**
    *  Fetch the pagination information of all organization subusers
    */
    pagination: async (): Promise<Pagination> => {
        //Send request
        return await (await contractsAPI.get(`/rest/v1/organization-subusers-accounts/pagination`)).data
    },

    /**
     * Fetch all subusers of a organization 
     */
    fetchAll: async (page: number, search?: string): Promise<UserAccount[] | null> => {
        //Create request
        let url = `/rest/v1/organization-subusers-accounts?page=${page}&limit=20`;
        if(search){
            url += `&search=${search}`;
        }
        //Send request
        const response = await contractsAPI.get(url)

        //Return null if response is 204
        if (response.status === 204) return null;
        return await response.data;
    },

    /**
     * Fetch all organization subusers from Klaus Accounts
     * @returns 
     */
    fetchAllFromKlausAccounts: async (query: string): Promise<ApiSearchResult[] | null> => {
        //Send request
        const response = await contractsAPI.get(`/rest/v1/organization-subusers-accounts/search-from-zaninte-accounts/all?q=${query}`);

        //If the response is empty, return null
        if (response.status === 204) return null;

        //Return data
        return await (response).data
    },

    /**
     * Import a organization subuser from Klaus Accounts 
     * @param dto 
     * @returns 
     */
    importFromKlausAccounts: async (dto: ImportFromKlausAccountsDTO): Promise<UserAccount[] | null> => {
        //Send request
        return await (await contractsAPI.post(`/rest/v1/organization-subusers-accounts/import-from-zaninte-accounts`, dto)).data
    },

    /**
     * Update organization subuser roles
     * @param uuid
     * @param dto 
     * @returns 
     */
    updateRoles: async (uuid: string, dto: UpdateRolesDTO): Promise<UserAccount[] | null> => {
        //Send request
        return await (await contractsAPI.put(`/rest/v1/organization-subusers-accounts/${uuid}/roles`, dto)).data
    },

    /**
     * Delete an organization subuser account
     * @param uuid 
     * @returns 
     */
    delete: async (uuid: string): Promise<UserAccount> => {
        //Send request
        return await (await contractsAPI.delete(`/rest/v1/organization-subusers-accounts/${uuid}`)).data
    },

    fetchUserFromKlausAccounts: async (uuid: string): Promise<UserAccount> => {
        const response = await AccountsApi.get(`/rest/v1/customer-subusers/${uuid}`)

        return await response.data;
    }
}

export default SubusersService;