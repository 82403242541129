
import { AxiosError, AxiosResponse } from "axios";
import { DataGranularity, PropertyFilter } from "../models/analytics";
import { AttachmentSpecification, DigitalContractTemplate, EditableDigitalContract, IssuedContractDocumentType, IssuedDigitalContract, TotalAmountOfBillingDataAnalyticsWithGranularity, TotalAmountOfBillingDataAnalyticsWithGranularityCount, TotalContractsDataAnalyticsWithGranularity, ApproveOrReproveAttachment, PerformanceComparanceData, ExternalDigitalContract, TemplateFileDTO, DigitalContractInputFileType, TemplateDTO, IssueTemplateDTO, FileIssueDTO, ExternalContractDTO, DigitalContractTemplateModificationLog } from "../models/contracts";
import { Pagination } from "../models/pagination";
import contractsAPI from "./contracts-api";
import { DigitalContractSignaturesCache, UserRecentViewedDigitalContractCache } from "../models/digital-contract-cache";
import ContractsApi from "./contracts-api";
import { DigitalContractTemporaryAccessPin } from "../models/digital-contract-temporary-access-pin";
import { OrganizationTeam } from "../models/teams";
import { TemplateWithAverageIssueTime } from "../views/contracts/finance/TemplateAverageIssueTime"
import { UpdateUser } from "../models/user";

interface UploadUserInformationPayload {
    requiredInformationName: string;
    value: string;
}

interface BulkUploadUserInformationPayload {
    data: UploadUserInformationPayload[]
}

interface UploadAttachment {
    file: File
}

interface UploadUserAttachmentPayload {
    name: string,
    file: File | undefined
}

interface URIRedirectDTO {
    uri: string;
}

const ContractsService = {

    async fetchTemplatesPaginationMetadata(limit: number = 20): Promise<Pagination> {
        //Send request
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/templates/pagination?limit=${limit}`)).data;
    },

    /**
     * Fetch all digital contract templates using pagination. if not result is found this method will return an empty body with 204 status code, otherwise
     * it will return 200
     * @param page - The page to fetch. Starts in 1
     * @returns 
     */
    async fetchTemplates(page: number): Promise<DigitalContractTemplate[]> {
        //Send request
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/templates?page=${page}`)).data;
    },

    /**
     * Fetch pagination metadata of editable digital contracts of the requester user organization.
     * @param limit 
     * @param startDate 
     * @param endDate 
     * @returns 
     */
    async fetchEditableDigitalContractsPaginationMetadata(
        limit: number = 10, startDate: Date | undefined = undefined,
        endDate: Date | undefined = undefined, team: OrganizationTeam | null = null
    ): Promise<Pagination> {
        let url = `/rest/v1/digital-contracts/editable-contracts/pagination?limit=${limit}`;
        if (startDate) url += `&startDate=${startDate.getTime()}`;
        if (endDate) url += `&endDate=${endDate.getTime()}`;
        if (team) url += `&team=${team.guid}`;

        return await (await contractsAPI.get(url)).data;
    },

    /**
     * Fetch all editable digital contracts from the authenticated user organization using pagination. if not result is found 
     * this method will return an empty body with 204 status code, otherwise it will return 200
     * @param page - The page to fetch. Starts in 1
     * @param limit - The limit of results per page
     * @returns 
     */
    async fetchEditableDigitalContracts(
        page: number, limit: number = 10, startDate: Date | undefined = undefined,
        endDate: Date | undefined = undefined, team: OrganizationTeam | null = null): Promise<EditableDigitalContract[]> {
        let url = `/rest/v1/digital-contracts/editable-contracts?page=${page}&limit=${limit}`;
        if (startDate) url += `&startDate=${startDate.getTime()}`;
        if (endDate) url += `&endDate=${endDate.getTime()}`;
        if (team) url += `&team=${team.guid}`;

        const response = await contractsAPI.get(url);
        if (response.status === 200) {
            const responseData = await response.data as any[];
            return responseData.map(r => {
                return {
                    creationDate: r.creationDate,
                    id: r.id,
                    issuerUuid: r.issuerUuid,
                    template: r.template,
                    legalRegionOfIssuance: r.legalRegionOfIssuance,
                    name: r.name,
                    organizationOwnerId: r.organizationOwnerId,
                    state: r.state,
                    contractParties: r.contractParties,
                    billingData: r.billingData,
                    userInformation: r.userInformation,
                    attachments: r.attachments,
                    personalizationActive: r.personalizationActive,
                    validityPeriod: r.validityPeriod,
                    contractTags: r.contractTags,
                    revokedBy: r.revokedBy,
                    accessHistory: r.accessHistory,
                }
            });
        }
        else {
            return [];
        }
    },

    /**
     * Return pagination metadata of the issued digital contracts of the organization
     * @param limit 
     * @param startDate 
     * @param endDate 
     * @returns 
     */
    async fetchIssuedDigitalContractsPaginationMetadata(
        limit: number = 10, startDate: Date | undefined = undefined, endDate: Date | undefined = undefined,
        daysUntilExpiration: number | undefined = undefined, team: OrganizationTeam | null = null): Promise<Pagination> {
        // request URL
        let url = `/rest/v1/digital-contracts/issued-contracts/pagination?limit=${limit}`;
        if (startDate) url += `&startDate=${startDate.getTime()}`;
        if (endDate) url += `&endDate=${endDate.getTime()}`;
        if (daysUntilExpiration !== undefined) url += `&daysUntilExpiration=${daysUntilExpiration}`;
        if (team) url += `&team=${team.guid}`;

        return await (await contractsAPI.get(url)).data;
    },

    /**
     * Fetch all editable digital contracts from the authenticated user organization using pagination. if not result is found 
     * this method will return an empty body with 204 status code, otherwise it will return 200
     * @param page - The page to fetch. Starts in 1
     * @param limit - The limit of results per page
     * @returns 
     */
    async fetchIssuedDigitalContracts(
        page: number, limit: number = 10, startDate: Date | undefined = undefined, endDate: Date | undefined = undefined,
        daysUntilExpiration: number | undefined = undefined, team: OrganizationTeam | null = null): Promise<IssuedDigitalContract[]> {
        let url = `/rest/v1/digital-contracts/issued-contracts?page=${page}&limit=${limit}`;
        if (startDate) url += `&startDate=${startDate.getTime()}`;
        if (endDate) url += `&endDate=${endDate.getTime()}`;
        if (daysUntilExpiration !== undefined) url += `&daysUntilExpiration=${daysUntilExpiration}`;
        if (team) url += `&team=${team.guid}`;

        const response = await contractsAPI.get(url);
        if (response.status === 200) {
            const responseData = await response.data as any[];
            let normalizedData = responseData.map(r => {
                return {
                    issueDate: r.issueDate,
                    id: r.id,
                    legalRegionOfIssuance: r.legalRegionOfIssuance,
                    name: r.name,
                    organizationOwnerId: r.organizationOwnerId,
                    state: r.state,
                    shardKey: r.shardKey,
                    issuerUuid: r.issuerUuid,
                    billingData: r.billingData,
                    userInformation: r.userInformation,
                    attachments: r.attachments,
                    signatures: r.signatures,
                    digitalTrustData: r.digitalTrustData,
                    contractParties: r.contractParties,
                    templateId: r.templateId,
                    documentType: r.documentType,
                    personalizationActive: r.personalizationActive,
                    fullySignedDate: r.fullySignedDate,
                    validityPeriod: r.validityPeriod,
                    inactiveAt: r.inactiveAt,
                    contractTags: r.contractTags,
                    revokedBy: r.revokedBy,
                    clausesToConfirm: r.clausesToConfirm,
                    contractPartiesSignatureOrder: r.contractPartiesSignatureOrder,
                    accessHistory: r.accessHistory,
                }
            });

            return normalizedData;
        }
        else {
            return [];
        }
    },

    /**
     * Return all IssuedDigitalContract owned by the authenticated user organization 
     * that matches the search term given on parameter 
     * @param searchTerm 
     * @returns 
     */
    async searchIssuedDigitalContracts(searchTerm: string): Promise<IssuedDigitalContract[]> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/issued-contracts/search?searchTerm=${searchTerm}`);
        if (response.status === 200) {
            return await response.data as IssuedDigitalContract[];
        }
        else {
            return [];
        }
    },

    /**
     * Return all EditableDigitalContract owned by the authenticated user organization 
     * that matches the search term given on parameter 
     * @param searchTerm 
     * @returns 
     */
    async searchEditableDigitalContracts(searchTerm: string): Promise<EditableDigitalContract[]> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/editable-contracts/search?searchTerm=${searchTerm}`);
        if (response.status === 200) {
            return await response.data as EditableDigitalContract[];
        }
        else {
            return [];
        }
    },

    /**
     * This service will check if the authenticated user can issue an contract by its template
     * @param templateId - The id of the digital contract template that will be validated
     * @returns 
     */
    async validateIssuer(templateId: string): Promise<any> {
        //Send request
        return await contractsAPI.get(`/rest/v1/digital-contracts/templates/${templateId}/can-i-issue`);
    },

    /**
     * Delete an digital contract template from the database
     * @param id - The id of the digital contract template that will be deleted
     * @returns 
     */
    async deleteTemplate(id: string) {
        //Send request
        return await (await contractsAPI.delete(`/rest/v1/digital-contracts/templates/${id}`)).data;
    },

    /**
     * Find an EditableDigitalContract identified by its id
     * @param id 
     * @returns 
     */
    async findEditableDigitalContractById(id: string): Promise<EditableDigitalContract> {
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/editable-contracts/${id}`)).data;
    },

    /**
     * Find an IssuedDigitalContract identified by its id
     * @param id 
     * @returns 
     */
    async findIssuedDigitalContractById(id: string): Promise<IssuedDigitalContract> {
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/issued-contracts/${id}`)).data;
    },

    /**
     * Upload user information into an EditableDigitalContract identified by its ID.
     * @param editableContractId 
     * @param payload 
     * @returns 
     */
    async uploadUserInformation(editableContractId: string, payload: BulkUploadUserInformationPayload): Promise<EditableDigitalContract> {
        return await contractsAPI.put(`/rest/v1/digital-contracts/editable-contracts/${editableContractId}/user-information`, payload);
    },

    /**
     * Upload user attachment file into a  EditableDigitalContract identified by its ID.
     */
    async uploadUserAttachments(editableContractId: string, attachment: UploadAttachment, filename: string, attachmentName: string) {
        let data = new FormData();
        data.append('file', attachment.file)
        data.append('filename', filename)
        data.append('attachmentName', attachmentName)
        return (await contractsAPI.put(`/rest/v1/digital-contracts/editable-contracts/${editableContractId}/attachments`, data)).data;

    },

    /**
     * issues an issued contract from an editable contract passing the contract id
     * @param editableContractId 
     * @returns 
     */
    async issueNewIssuedContract(editableContractId: string) {
        return await contractsAPI.post(`/rest/v1/digital-contracts/editable-contracts/${editableContractId}/issue-new-issued-contract`);
    },

    /** 
     * function to sign the contract using ttpca
     * @param issuedContractId 
     * @returns 
     */
    async signContract(issuedContractId: string, updateUser: UpdateUser): Promise<IssuedDigitalContract> {
        return await (await contractsAPI.post(`/rest/v1/digital-contracts/issued/${issuedContractId}/sign-with-ttpca/`, updateUser)).data;
    },

    /**
     * try to fetch a editable contract and if you don't find it, try to fetch a issued contract
     * @param contractId 
     */
    async fetchEditableOrIssuedDigitalContract(contractId: string): Promise<EditableDigitalContract | IssuedDigitalContract> {
        try {
            return await (await contractsAPI.get(`/rest/v1/digital-contracts/editable-contracts/${contractId}`)).data;
        }
        catch (error) {
            // ignore because it will try to fetch an issued contract
        }

        try {
            return await (await contractsAPI.get(`/rest/v1/digital-contracts/issued-contracts/${contractId}`)).data;
        }
        catch (e) {
            throw e;
        }
    },

    /**
     * Fetch analytical data from the billing data of the {@link EditableDigitalContract} and {@link IssuedDigitalContract} owned by
     * the requester organization.
     * @param startDate 
     * @param endDate 
     * @returns 
     */
    async analyticsOfTotalAmountOfBillingDataAnalyticsWithGranularity(
        startDate: Date,
        endDate: Date,
        filters: PropertyFilter[] | undefined = undefined,
        dateGranularity: DataGranularity,
        tagName: string | undefined = undefined,
    ): Promise<TotalAmountOfBillingDataAnalyticsWithGranularity[] | null> {

        // build the request URL with required and optional parameters
        let url = `/rest/v1/digital-contracts/analytics/organization/total-amount-billing-data`;
        url += `?startDate=${startDate.getTime()}`;
        url += `&endDate=${endDate.getTime()}`;
        url += `&dateGranularity=${dateGranularity}`
        if (filters) url += `&filter=${encodeURI(JSON.stringify(filters))}`
        if (tagName) url += `&tagName=${tagName}`

        const response = await contractsAPI.get(url);
        return (response.status === 200) ? await response.data : null;
    },

    /**
     * Create an new IssuedDigitalContract of an EditableDigitalContract identified by the parameter : editableContractId.
     * @see https://dev.azure.com/klausc2w/contracts/_wiki/wikis/contracts.wiki/164/Criar-um-contrato-em-fase-de-emiss%C3%A3o-a-partir-de-um-contrato-edit%C3%A1vel-com-metodologia-TTP_CA
     * @param editableContractId 
     * @returns 
     */
    async createIssuedContractFromEditable(editableContractId: string): Promise<IssuedDigitalContract> {
        return await (await contractsAPI.post(`/rest/v1/digital-contracts/editable-contracts/${editableContractId}/issue-new-issued-contract`)).data;
    },

    /**
     * Delete an EditableDigitalContract identified by the editableContractId parameter
     * @param editableContractId 
     * @returns 
     */
    async deleteEditableContract(editableContractId: string): Promise<AxiosResponse> {
        return await contractsAPI.delete(`/rest/v1/digital-contracts/editable-contracts/${editableContractId}`)
    },

    async deleteIssuedContract(issuedContractId: string): Promise<AxiosResponse> {
        return await contractsAPI.delete(`/rest/v1/digital-contracts/issued-contracts/${issuedContractId}`)
    },

    /**
     * 
     * @param contractId 
     * @returns 
     */
    async createIssuedDigitalContractDocumentDownloadURI(contract: EditableDigitalContract | IssuedDigitalContract): Promise<URIRedirectDTO> {
        if (contract.state === "SIGNED" && (contract as IssuedDigitalContract).documentType === IssuedContractDocumentType.WORD) {
            return await (await contractsAPI.get(`/rest/v1/digital-contracts/issued-contracts/${contract.id}/generate-printable-document`)).data;
        } else {
            return await (await contractsAPI.get(`/rest/v1/digital-contracts/issued-contracts/${contract.id}/document/download-uri`)).data;
        }
    },

    async downloadOrginalDigitalContract(contract: IssuedDigitalContract): Promise<URIRedirectDTO> {
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/issued-contracts/${contract.id}/document/download-uri`)).data;
    },

    async findIssuedDigitalContractByHash(hash: string): Promise<IssuedDigitalContract> {
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/public/issued-contracts/find-by-hash/SHA_256/${hash}`)).data;
    },

    async sendIssuedDigitalContractEmailToContractParties(contractId: string): Promise<IssuedDigitalContract> {
        return await contractsAPI.post(`/rest/v1/digital-contracts/issued-contracts/${contractId}/send-contract-to-parties-by-email`);
    },

    async sendEditableDigitalContractEmailToContractParties(contractId: string): Promise<EditableDigitalContract> {
        return await contractsAPI.post(`/rest/v1/digital-contracts/editable-contracts/${contractId}/send-contract-to-parties-by-email`);
    },
    /**
    * Fetch user recent viewed digital contracts 
    * @param limit - The limit of results
    * @returns 
    */
    async fetchUserRecentViewedDigitalContractCache(limit: number): Promise<UserRecentViewedDigitalContractCache[] | null> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/me/recent-contracts?limit=${limit}`);
        if (response.status === 204) {
            return null;
        }
        return await response.data;
    },

    /**
     * Fetch user digital contract signature cache
     * @param page - number of the page to fetch
     * @param limit - The limit of results
     * @returns 
     */
    async fetchDigitalContractSignaturesCache(page: number, limit: number): Promise<DigitalContractSignaturesCache[] | null> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/me/user-signatures-cache?page=${page}&limit=${limit}`);
        if (response.status === 204) {
            return null;
        }
        return await response.data;
    },

    async paginationOfDigitalContractSignaturesCache(limit: number): Promise<Pagination> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/me/user-signatures-cache/pagination?limit=${limit}`);

        return await response.data;
    },

    async searchDigitalTemplate(searchTerm: string): Promise<DigitalContractTemplate[]> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/templates?searchTerm=${searchTerm}`)

        if (response.status === 200) {
            return await response.data as DigitalContractTemplate[];
        }
        else {
            return [];
        }
    },
    async generateDownloadURIForDigitalContractAttachment(contractId: string, attachmentName: string): Promise<URIRedirectDTO> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/${contractId}/attachments/download-uri?attachmentName=${attachmentName}`)
        return await response.data
    },
    async generateDownloadURIForPrintableDigitalContract(contractId: string): Promise<URIRedirectDTO> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/issued-contracts/${contractId}/generate-printable-document`)
        return await response.data;
    },

    async createDigitalContractTemplateDownloadTemplateFileDownloadUri(contractTemplateId: string): Promise<URIRedirectDTO> {
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/templates/${contractTemplateId}/template-file/download-uri`)).data;
    },
    async inactivateIssuedDigitalContract(contractId: string): Promise<IssuedDigitalContract> {
        return await contractsAPI.post(`/rest/v1/digital-contracts/issued-contracts/${contractId}/inactive`)
    },
    async generateDownloadLinkUsingPin(pin: string, contractId: String): Promise<URIRedirectDTO> {
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/public/issued-contracts/access-pin/generate-download-uri-with-pin?pin=${pin}&contractId=${contractId}`)).data
    },
    async generateDigitalContractAccessPIN(contractId: string): Promise<DigitalContractTemporaryAccessPin> {
        const response = await contractsAPI.post(`/rest/v1/digital-contracts/issued-contracts/${contractId}/access-pin/granted-pin-access`);
        return response.data;
    },
    async fetchIssuedDigitalContractTemporaryAccessPins(contractId: string): Promise<DigitalContractTemporaryAccessPin[]> {
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/issued-contracts/${contractId}/access-pin/`)).data
    },
    async deleteIssuedDigitalContractTemporaryAccessPin(contractId: string, pin: string): Promise<DigitalContractTemporaryAccessPin> {
        return await (await contractsAPI.delete(`/rest/v1/digital-contracts/issued-contracts/${contractId}/access-pin/delete-pin-access/${pin}`)).data
    },

    async analyticsOfIssuedContractWithGranularity(
        startDate: Date,
        endDate: Date,
        filters: PropertyFilter[] | undefined = undefined,
        dateGranularity: DataGranularity,
        tagName: string | undefined = undefined,
    ): Promise<TotalContractsDataAnalyticsWithGranularity[] | null> {
        // build the request URL with required and optional parameters
        let url = `/rest/v1/digital-contracts/analytics/organization/total-issued-contracts`;
        url += `?startDate=${startDate.getTime()}`;
        url += `&endDate=${endDate.getTime()}`;
        if (filters) url += `&filter=${encodeURI(JSON.stringify(filters))}`
        url += `&dateGranularity=${dateGranularity}`
        if (tagName) url += `&tagName=${tagName}`

        const response = await contractsAPI.get(url);
        return (response.status === 200) ? await response.data : null;
    },
    async getOriginalDocumentDownloadURI(contractId: string): Promise<URIRedirectDTO> {
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/issued-contracts/${contractId}/document/download-uri`)).data;
    },

    async deleteAllRecentViewedContracts(): Promise<UserRecentViewedDigitalContractCache[]> {
        const url = "/rest/v1/digital-contracts/me/recent-contracts"

        const response = await contractsAPI.delete(url)

        return await response.data;
    },

    async deleteRecentViewedContract(contractId: string): Promise<UserRecentViewedDigitalContractCache> {
        const url = `/rest/v1/digital-contracts/me/recent-contracts/${contractId}`

        const response = await contractsAPI.delete(url)

        return await response.data;
    },

    async revokeIssuedDigitalContract(contractId: string): Promise<IssuedDigitalContract> {
        return await (await contractsAPI.put(`/rest/v1/digital-contracts/issued-contracts/${contractId}/revoke`)).data;
    },

    async revokeEditableDigitalContract(contractId: string): Promise<EditableDigitalContract> {
        return await (await contractsAPI.put(`/rest/v1/digital-contracts/editable-contracts/${contractId}/revoke`)).data;

    },

    async removeRevokeIssuedDigitalContract(contractId: string): Promise<IssuedDigitalContract> {
        return await (await contractsAPI.put(`/rest/v1/digital-contracts/issued-contracts/${contractId}/remove-revoke`)).data;
    },

    async removeRevokeEditableDigitalContract(contractId: string): Promise<EditableDigitalContract> {
        return await (await contractsAPI.put(`/rest/v1/digital-contracts/editable-contracts/${contractId}/remove-revoke`)).data;
    },

    async fetchPerformanceAnalyticalData(
        fpStartDate: Date,
        fpEndDate: Date,
        spStartDate: Date,
        spEndDate: Date,
        dateGranularity: DataGranularity,
        filters: PropertyFilter[] | undefined = undefined,
        tagName: string | undefined = undefined,
    ): Promise<PerformanceComparanceData> {
        let url = "/rest/v1/digital-contracts/analytics/organization/total-amount-billing-data/referencial";

        url += `?fpStartDate=${fpStartDate.getTime()}`
        url += `&fpEndDate=${fpEndDate.getTime()}`
        url += `&spStartDate=${spStartDate.getTime()}`
        url += `&spEndDate=${spEndDate.getTime()}`
        url += `&dateGranularity=${dateGranularity}`
        if (filters) url += `&filter=${encodeURI(JSON.stringify(filters))}`
        if (tagName) url += `&tagName=${tagName}`

        const response = await contractsAPI.get(url);

        return await response.data;
    },

    async approveOrReproveEditableDigitalContract(contractId: string, dto: ApproveOrReproveAttachment): Promise<AxiosResponse> {
        const response = await contractsAPI.put(`/rest/v1/digital-contracts/editable-contracts/${contractId}/approve-or-reprove-attachment`, dto);

        return response;
    },
    /**
     * Return all ExternalDigitalContract owned by the authenticated user organization 
     * that matches the search term given on parameter 
     * @param searchTerm 
     * @returns 
     */
    async searchExternalDigitalContracts(searchTerm: string): Promise<ExternalDigitalContract[]> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/external-contracts/search?searchTerm=${searchTerm}`);
        if (response.status === 200) {
            return await response.data as ExternalDigitalContract[];
        }
        else {
            return [];
        }
    },

    async fetchExternalContractsPaginationMetadata(limit: number, startDate: Date, endDate: Date): Promise<Pagination> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/external-contracts/pagination?limit=${limit}&startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`);
        return await response.data;
    },

    async fetchExternalContractsWithPagination(page: number, limit: number, startDate: Date, endDate: Date): Promise<ExternalDigitalContract[]> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/external-contracts?page=${page}&limit=${limit}&startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`);
        return await response.data;
    },

    async deleteExternalContract(contract: ExternalDigitalContract): Promise<ExternalDigitalContract> {
        const response = await ContractsApi.delete(`/rest/v1/digital-contracts/external-contracts/${contract.id}`);
        return await response.data;
    },

    async downloadExternalContractDocumentURI(contract: ExternalDigitalContract): Promise<URIRedirectDTO> {
        const response = await ContractsApi.get(`rest/v1/digital-contracts/external-contracts/${contract.id}/document/download-uri`);

        return await response.data;
    },

    async fetchExternalContractWithId(contractId: string): Promise<ExternalDigitalContract> {
        const response = await ContractsApi.get(`rest/v1/digital-contracts/external-contracts/${contractId}`);

        return await response.data;
    },

    async downloadDocumentByHash(hashAlg: string, hash: string): Promise<URIRedirectDTO> {
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/public/download-document-by-hash/${hashAlg}/${hash}`)).data;
    },

    async downloadPrintableDocumentByHash(hashAlg: string, hash: string): Promise<URIRedirectDTO> {
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/public/download-printable-document-by-hash/${hashAlg}/${hash}`)).data;
    },

    async paginationOfEditableContractsScheduledForDeletion(daysToBeDeleted: number = 30, limit: number = 20): Promise<Pagination> {
        //Send request
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/editable-contracts/scheduled-for-deletion/pagination?daysToBeDeleted=${daysToBeDeleted}&limit=${limit}`)).data;
    },

    async fetchEditableContractsScheduledForDeletionWithPagination(daysToBeDeleted: number = 30, page: number = 1, limit: number = 20): Promise<EditableDigitalContract[]> {
        //Send request
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/editable-contracts/scheduled-for-deletion?daysToBeDeleted=${daysToBeDeleted}&page=${page}&limit=${limit}`)).data;
    },

    async paginationOfIssuedContractsScheduledForDeletion(daysToBeDeleted: number = 30, limit: number = 20): Promise<Pagination> {
        //Send request
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/issued-contracts/scheduled-for-deletion/pagination?daysToBeDeleted=${daysToBeDeleted}&limit=${limit}`)).data;
    },

    async fetchIssuedContractsScheduledForDeletionWithPagination(daysToBeDeleted: number = 30, page: number = 1, limit: number = 20): Promise<IssuedDigitalContract[]> {
        //Send request
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/issued-contracts/scheduled-for-deletion?daysToBeDeleted=${daysToBeDeleted}&page=${page}&limit=${limit}`)).data;
    },

    async fetchTemplateById(
        templateId: string
    ): Promise<DigitalContractTemplate> {
        const response = await contractsAPI.get(`/rest/v1/digital-contracts/templates/${templateId}`);

        return await response.data;
    },

    async generateWildcardsFromTemplateFile(fileType: string, file: File): Promise<TemplateFileDTO> {
        const bodyFormData = new FormData();
        bodyFormData.append("file", file);

        const response = await contractsAPI.post(`/rest/v1/digital-contracts/compute-wildcards/${fileType}`, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        });

        return await response.data;
    },

    async registerTemplate(templateData: TemplateDTO): Promise<DigitalContractTemplate> {
        //Send request
        return await (await ContractsApi.post(`/rest/v1/digital-contracts/templates`, templateData)).data;
    },

    async uploadTemplateFile(templateGuid: string, templateBlob: Blob, inputFileType: DigitalContractInputFileType): Promise<DigitalContractTemplate> {
        const bodyFormData = new FormData();
        bodyFormData.append("template", templateBlob);
        //Send request
        return await ContractsApi.post(`/rest/v1/digital-contracts/templates/${templateGuid}/template-file?fileType=${inputFileType}`, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        })
    },

    async update(templateId: string, templateData: TemplateDTO): Promise<DigitalContractTemplate> {
        //Send request
        return await (await ContractsApi.put(`/rest/v1/digital-contracts/templates/${templateId}`, templateData)).data;
    },

    async issueNewEditableContract(id: string, templateData: IssueTemplateDTO): Promise<EditableDigitalContract> {
        //Send request
        return await (await ContractsApi.post(`/rest/v1/digital-contracts/templates/${id}/issue-new-editable-contract`, templateData)).data;
    },

    async issueDigitalContractFromFile(data: FileIssueDTO, file: File): Promise<IssuedDigitalContract> {
        const payload = new FormData();
        const contractInformationString = JSON.stringify(data);
        const contractInformationBlob = new Blob([contractInformationString], { type: 'application/json' });

        payload.append('file', file);
        payload.append('contractInformation', contractInformationBlob, 'contractInformation.json');

        const response = await ContractsApi.post(`/rest/v1/digital-contracts/issued-contracts/issue-from-file`, payload, {
            headers: { "Content-Type": "multipart/form-data" }
        });

        return await response.data;
    },

    async createExternalDigitalContractFromFile(data: ExternalContractDTO, file: File): Promise<ExternalDigitalContract> {
        const payload = new FormData();
        const contractInformationString = JSON.stringify(data);
        const contractInformationBlob = new Blob([contractInformationString], { type: 'application/json' });

        payload.append('file', file);
        payload.append('contractInformation', contractInformationBlob, 'contractInformation.json');

        const response = await ContractsApi.post(`/rest/v1/digital-contracts/external-contracts`, payload, {
            headers: { "Content-Type": "multipart/form-data" }
        });

        return await response.data;
    },

    async generateEditableDigitalContractFromIssuedContractBeingRenewed(previousContractId: string): Promise<EditableDigitalContract> {
        const response = await ContractsApi.get(`/rest/v1/digital-contracts/issued-contracts/${previousContractId}/fetch-contract-to-renew`);
        return await response.data;
    },

    async renewIssuedDigitalContract(contractIdToRenew: string, data: any): Promise<EditableDigitalContract> {
        const response = await ContractsApi.post(`/rest/v1/digital-contracts/issued-contracts/${contractIdToRenew}/renew`, data)
        return await response.data;
    }, 

    async saveUserEditorPrefencesMetric(data: any): Promise<AxiosResponse> {
        const response = await ContractsApi.post(`/rest/v1/metrics/editor-version-user`, JSON.parse(data))
        return await response.data;
    },
    async fetchTemplateModificationLogs(template: DigitalContractTemplate): Promise<DigitalContractTemplateModificationLog> {
        const response = await ContractsApi.get(`/rest/v1/digital-contracts/templates/${template.id}/modification-log`)

        return await response.data
    },
    async fetchOrganizationAverageIssueTime() : Promise<TemplateWithAverageIssueTime[]>{
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/analytics/organization/average-issue-time`)).data;
    },
}

export default ContractsService;    