import { MailTemplate } from "../models/contracts";
import { ContractsQuota, CreateUserAccount, CurrencyGroupingFinanceChargeAnalytics, CurrencyGroupingFinanceChargeCount, CurrencyGroupingSubscriptionChargeAnalytics, InterfaceColors, InterfaceTheme, OrganizationPreferences } from "../models/organizations";
import { Subuser, UserAccount } from "../models/user";
import AccountsApi from "./accounts-api";
import contractsAPI from "./contracts-api";

interface ApiSearchResult {
    type: 'ORGANIZATION_ACCOUNT' | 'ORGANIZATION_SUBUSER_ACCOUNT' | 'ORGANIZATION_TEAM';
    data: Subuser
}

const OrganizationService = {
    /**
    * Fetch all organization subusers
    * @param query 
    * @returns 
    */
    fetchAllUsers: async (query: string): Promise<ApiSearchResult[] | null> => {
        //Send request
        const response = await contractsAPI.get(`/rest/v1/organizations/my/search/users?q=${query}`);

        //If the response is empty, return null
        if (response.status === 204) return null;

        //Return data
        return await (response).data
    },

    /**
     * Fetch an UserAccount data identified by its UUID. The target user must be an member of the
     * requester organization. If the user is not found this method will thrown an HTTP error with status
     * 404. If the target user is not owned by the requester organization this method will return an 403 status
     * @param uuid 
     * @returns 
     */
    fetchOrganizationMemberByUuid : async (uuid : string) : Promise<UserAccount>  => {
        return await (await contractsAPI.get(`/rest/v1/organizations/my/search-member-by-uuid/${uuid}`)).data;
    },

    /**
     * Fetch total amount of charges credited|debited by the requester's organization.
     * @param financialChargeRole 
     * @param startDate 
     * @param endDate 
     * @param g 
     * @returns CurrencyGrouping[]
     */
    async fetchOrganizationFinanceChargesTotalAmountAnalytics(
        financialChargeRole : "debited" | "credited", 
        startDate : Date, 
        endDate : Date, 
        g : "YEAR" | "YEAR_AND_MONTH" | "YEAR_MONTH_AND_DAY",
        productId : null | string = null,
        licenceId : null | string = null,
        issuerUuid : null | string = null
        ) : Promise<CurrencyGroupingFinanceChargeAnalytics | null> {

            // fetch the API response
            let url = `/rest/v1/organizations/my/analytics/finance-charges/total-amount/${financialChargeRole}?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}&dateGranularity=${g}`;
            if (productId !== null) url += `&productId=${productId}`;
            if (licenceId !== null) url += `&licenceId=${licenceId}`;
            if (issuerUuid !== null) url += `&issuerUuid=${issuerUuid}`;
            const response = await contractsAPI.get(url);

            // return an empty array if the response is No Content
            if (response.status === 204) {
                return null;
            }

            // otherwise parse the response data
            return await response.data;
    },
    
    async uploadColorsTheme(colorsTheme: InterfaceColors) : Promise<InterfaceTheme> {
        let url = "/rest/v1/organizations/interface-theme/colors"
        const response = await contractsAPI.put(url, colorsTheme)

        return await response.data
    },

    async uploadLogoTheme(logoFile: File) : Promise<InterfaceTheme> {
        let url = "/rest/v1/organizations/interface-theme/images"
        let data = new FormData()
        data.append('logo', logoFile)
        const response = await contractsAPI.put(url, data)

        return await response.data;
    },
    
    async fetchPrivateApiIssuerOrganizationContractsQuota(issuerOrganizationUuid: string): Promise<ContractsQuota> {
        let url = `/rest/v1/organizations/contracts/${issuerOrganizationUuid}/quota`

        const response = await contractsAPI.get(url);

        return await response.data;
    },

    async updateOrganizationPreferences(organizationPreferences: OrganizationPreferences): Promise<UserAccount> {
        const response = await contractsAPI.put("/rest/v1/organizations/my/preferences", organizationPreferences)

        return await response.data
    },

    async createUserAccount(userAccount: CreateUserAccount): Promise<string> {
        const response = await contractsAPI.post("/rest/v1/user-accounts/public/create-with-eid?service=CONTRACTS", userAccount)

        return response.data.token
    },

    async fetchOrganizationInterfaceTheme(): Promise<InterfaceTheme> {
        const response = await contractsAPI.get("/rest/v1/organizations/interface-theme")

        return await response.data;
    },

    async publicFetchOrganizationInterfaceTheme(organizationUuid: string): Promise<InterfaceTheme> {
        const response = await contractsAPI.get(`/rest/v1/organizations/public/${organizationUuid}/interface-theme`)

        return await response.data;
    },

    /**
     * Count total amount of charges credited|debited by the requester's organization.
     * @param financialChargeRole 
     * @param startDate 
     * @param endDate 
     * @param g 
     * @returns CurrencyGrouping[]
     */
    async countOrganizationFinanceChargesTotalAmountAnalytics(
        financialChargeRole : "debited" | "credited", 
        startDate : Date, 
        endDate : Date, 
        g : "YEAR" | "YEAR_AND_MONTH" | "YEAR_MONTH_AND_DAY",
        productId : null | string = null,
        licenceId : null | string = null,
        issuerUuid : null | string = null
        ) : Promise<CurrencyGroupingFinanceChargeCount | null> {

            // fetch the API response
            let url = `/rest/v1/organizations/my/analytics/finance-charges/total-amount/${financialChargeRole}/count?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}&dateGranularity=${g}`;
            if (productId !== null) url += `&productId=${productId}`;
            if (licenceId !== null) url += `&licenceId=${licenceId}`;
            if (issuerUuid !== null) url += `&issuerUuid=${issuerUuid}`;
            const response = await contractsAPI.get(url);

            // return an empty array if the response is No Content
            if (response.status === 204) {
                return null;
            }

            // otherwise parse the response data
            return await response.data;
    },

    /**
     * Fetch the total amount of subscription charges from the requesting organization
     * @param financialChargeRole 
     * @param startDate 
     * @param endDate 
     * @param g 
     * @param productId 
     * @param licenceId 
     * @param issuerUuid 
     * @returns 
     */
    async fetchOrganizationSubscriptionChargesTotalAmountAnalytics(
        financialChargeRole : "debited" | "credited", 
        startDate : Date, 
        endDate : Date, 
        g : "YEAR" | "YEAR_AND_MONTH" | "YEAR_MONTH_AND_DAY",
        productId : null | string = null,
        licenceId : null | string = null,
        issuerUuid : null | string = null
    ): Promise<CurrencyGroupingSubscriptionChargeAnalytics | null> {
        // fetch the API response
        let url = `/rest/v1/organizations/my/analytics/subscription-charges/total-amount/${financialChargeRole}?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}&dateGranularity=${g}`;
        if (productId !== null) url += `&productId=${productId}`;
        if (licenceId !== null) url += `&licenceId=${licenceId}`;
        if (issuerUuid !== null) url += `&issuerUuid=${issuerUuid}`;
        const response = await contractsAPI.get(url);

        // return an empty array if the response is No Content
        if (response.status === 204) {
            return null;
        }

        // otherwise parse the response data
        return await response.data;
    },

    async fetchOrganizationEmail(): Promise<MailTemplate[]> {
        return await (await contractsAPI.get("/rest/v1/organizations/my/global-mail-template")).data.mailTemplates
    },

    async updateGlobalMailTemplate(mailTemplates: MailTemplate): Promise<MailTemplate[]> {
        return await (await contractsAPI.put("/rest/v1/organizations/my/global-mail-template", mailTemplates)).data;
    },

}

export default OrganizationService;