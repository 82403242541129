import { Close, EditNote, History, UploadFile } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DigitalContractTemplate, DigitalContractTemplateModificationLog, ModificationLog, ModificationType } from "../models/contracts";
import { show } from "../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../redux/hooks";
import ContractsService from "../services/contracts";
import ErrorWrapper from "../utils/ErrorWrapper";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import moment from "moment";

interface DialogProps extends React.ComponentProps<any> {
    open: boolean,
    template: DigitalContractTemplate,
    onClose: () => void,
}

const TemplateHistoryDialog = (props: DialogProps): JSX.Element => {

    // Destructure props object
    const { open, template, onClose } = props;

    // Contexts
    const notification = useAppDispatch();

    // Boolean states
    const [loading, setLoading] = useState(true);

    // Data states
    const [modificationLogs, setModificationLogs] = useState<DigitalContractTemplateModificationLog | null>(null);

    // useEffects
    useEffect(() => {
        fetchModificationLog();
    }, [])

    // Functions
    function fetchModificationLog() {
        setModificationLogs(null);
        setLoading(true);

        ContractsService.fetchTemplateModificationLogs(template)
            .then(response => {
                if (!response) return;
                let logs = response;
                logs.logs = logs.logs.reverse();
                setModificationLogs(logs);
            })
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({ type: "error", message: err.message }));
            })
            .finally(() => setLoading(false));
    }

    function parseLogMessageByModificationType(log: ModificationLog): string {
        switch (log.type) {
            case ModificationType.TEMPLATE:
                return `Informações do modelo foram modificados por `;
            case ModificationType.INPUT_FILE:
                return `Arquivo do modelo foi modificado por `;
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }} >
                    <History color="primary" sx={{fontSize: "32px", mr: 1 }} />
                    <Typography variant="h5" sx={{color: (theme) => theme.palette.primary.main}} >Histórico do Modelo: {template.name}</Typography>
                </Box>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                {
                    modificationLogs
                        ?
                        <Timeline position="alternate">
                            {
                                modificationLogs.logs.map((log, i) => (
                                    <TimelineItem key={i}>
                                        <TimelineSeparator>
                                            <TimelineDot color={i % 2 === 0 ? "primary" : "secondary"} >
                                                {
                                                    log.type === ModificationType.TEMPLATE
                                                        ?
                                                        <EditNote htmlColor="#FFF" />
                                                        :
                                                        <UploadFile htmlColor="#FFF" />
                                                }
                                            </TimelineDot>
                                            {
                                                (i + 1 < modificationLogs.logs.length)
                                                    ?
                                                    <TimelineConnector /> 
                                                    :
                                                    <></>
                                            }
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography variant="body1" >
                                                {parseLogMessageByModificationType(log)} <b>{log.name}</b> no dia <b>{moment(log.date).format("DD/MM/YYYY HH:mm:ss")}</b>
                                                </Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))
                            }
                        </Timeline>
                        :
                        <Typography variant="h6">Modelo não teve modificações gravadas desde sua criação</Typography>
                }
            </DialogContent>
        </Dialog>
    )
}

export default TemplateHistoryDialog;