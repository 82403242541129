import { AuthenticatedUser, SessionRoles, UpdateUser, UserAccount } from "../models/user";
import accountsApi from "./accounts-api";
import contractsAPI from "./contracts-api";

interface Session {
    token: string,
    expirationDate: Date
}

const AuthorizationService = {
    /**
     * Login on contracts with accounts token
     * @param authenticationToken
     */
    async contractsLogin(authenticationToken: string): Promise<Session> {
        //Try to authenticate the user
        const authResponse = await contractsAPI.post(`/rest/v1/auth/public/tokens/issue-with-zaninte-auth-token`, { authenticationToken })

        //Return the data
        return {
            token: authResponse.data.token,
            expirationDate: authResponse.data.expirationDate
        }
    },

    /**
     * Login on accounts 
     * @param email 
     * @param password 
     * @returns 
     */
    async accountsLogin(email: string, password: string): Promise<string> {
        //Try to authenticate the user
        const authResponse = await accountsApi.post(`/rest/v1/auth/public/tokens/create-with-email-and-password?trustDevice=true`, { email, password })
        //Return the data
        return authResponse.data.token;
    },

    /**
     * Get data from authenticated user
     */
    async me(): Promise<AuthenticatedUser> {
        //Send request
        const response = await contractsAPI.get(`/rest/v1/auth/me`);

        //Set STL session roles 
        const sessionRoles: SessionRoles[] = [], session = response.data.session;
        if (session.roles) session.roles.forEach((roleFromService: any) => {
            sessionRoles.push(SessionRoles[roleFromService.authority as keyof typeof SessionRoles]);
        })

        //Return the model data
        return {
            account: response.data.account,
            session: response.data.session
        }

    },

    async renewSessionWithPassword(password: string): Promise<string> {
        const authResponse = await accountsApi.post(`/rest/v1/auth/tokens/renew-with-password`, { password });

        //Return the data
        return authResponse.data.token;
    },

    async issueWithEidToken(token: string): Promise<string> {
        const response = await contractsAPI.get(`rest/v1/auth/public/tokens/issue-with-eid?eid=${token}`);
        
        const authToken = await response.data;

        return authToken.token;
    },

    async getSubuserOrganizationOwner() : Promise<any> {;
        const response = await accountsApi.get(`/rest/v1/auth/me/owner`);
        return await response.data;
    },

    async updateUser(dto: UpdateUser): Promise<AuthenticatedUser> {
        const response = await contractsAPI.put("/rest/v1/auth/me", dto);

        return await response.data;
    }

}

export default AuthorizationService;