import { Divider, FormControlLabel, FormGroup, Grid, IconButton, InputBase, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import SearchBar from "../../../../components/SearchBar";
import { PropertyFilter } from "../../../../models/analytics";
import { ContractPartyTemplateSpecification, DigitalContractStates, DigitalContractTemplate } from "../../../../models/contracts";
import { SearchResult } from "../../../../models/search-result";
import { FilterOperations } from "./Analytics";
import { Close } from "@mui/icons-material";

interface PropertyFiltersChangedCallback {
    (propertyFilters : PropertyFilter[]) : void;
}

interface CustomPropertyFiltersMap {
    [field : string] : string
}

interface DigitalContractTemplatePropertyFiltersProps extends React.ComponentProps<any> {
    digitalContractTemplate : DigitalContractTemplate | null;
    onChange? : PropertyFiltersChangedCallback;
}

interface ContractStates {
    value: DigitalContractStates,
    label: "Aguardando informações" | "Pronto para enviar para assinatura" | "Aguardando assinaturas" | "Assinado" | "Inativo"
}

const contractStates: ContractStates[] = [
    {
        value: DigitalContractStates.NOT_READY_TO_BE_ISSUED,
        label: "Aguardando informações"
    },
    {
        value: DigitalContractStates.READY_TO_BE_ISSUED,
        label: "Pronto para enviar para assinatura"
    },
    {
        value: DigitalContractStates.WAITING_FOR_SIGNATURES,
        label: "Aguardando assinaturas"
    },
    {
        value: DigitalContractStates.SIGNED,
        label: "Assinado"
    },
    {
        value: DigitalContractStates.INACTIVE,
        label: "Inativo"
    }
]

const DigitalContractTemplatePropertyFilters = (props : DigitalContractTemplatePropertyFiltersProps) : JSX.Element => {

    // states
    const [contractIssuer, setContractIssuer] = useState<SearchResult | null>(null);
    const [customPropertyFiltersFromContractParties, setCustomPropertyFiltersFromContractParties] = useState<CustomPropertyFiltersMap>({});

    const [selectedContractState, setSelectedContractState] = useState<DigitalContractStates | null>(null)

    const [issuerOperation, setIssuerOperation] = useState<FilterOperations>(FilterOperations.EQUALS)
    const [contractStateOperation, setContractStateOperation] = useState<FilterOperations>(FilterOperations.EQUALS)

    /**
     * Effect that watches changes on the filters and build the PropertyFilter[] that will be sent to the parent
     */
    useEffect(() => {
        // ignore the process if there is no callback defined on the component
        if (!props.onChange) return;

        // build the object that will be sent to the callback
        const propertyFilters : PropertyFilter[] = [];

        // include the contract issuer on the filters
        if (contractIssuer) {
            propertyFilters.push({field : "issuerUuid", value : contractIssuer.id, operations: issuerOperation});
        }

        if (selectedContractState) {
            propertyFilters.push({field: "state", value: selectedContractState, operations: contractStateOperation})
        }

        // include the values from the customPropertyFiltersFromContractParties state prop
        for (const key in customPropertyFiltersFromContractParties) {
            propertyFilters.push({field : "contractParties.role", value : key, operations: FilterOperations.EQUALS});
            propertyFilters.push({field : "contractParties.uuid", value : customPropertyFiltersFromContractParties[key], operations: FilterOperations.EQUALS});
        }

        // call the props callback
        props.onChange(propertyFilters);

    }, [contractIssuer, issuerOperation, selectedContractState, contractStateOperation, customPropertyFiltersFromContractParties])

    /**
     * Handles contract issuer search bar change events
     * @param _ 
     * @param searchResult 
     */
    function handleContractIssuerSearchBarOnChange(_ : any, searchResult : SearchResult | null) {
        setContractIssuer(searchResult);
    }

    function handleContractPartyPropertyFilterChangeEvent(searchResult : SearchResult | null, contractPartySpecification : ContractPartyTemplateSpecification) : void {
        // make an copy of the state property
        let copyOfcustomPropertyFiltersMap = {
            ...customPropertyFiltersFromContractParties
        };

        if (searchResult) {
            copyOfcustomPropertyFiltersMap[contractPartySpecification.role] = searchResult.id;
        }
        else {
            delete copyOfcustomPropertyFiltersMap[contractPartySpecification.role];
        }

        // update the state
        setCustomPropertyFiltersFromContractParties(copyOfcustomPropertyFiltersMap);
    }

    function handleSelectedContractStateChange(e: SelectChangeEvent<DigitalContractStates | null>) {
        if (e.target.value) {
            setSelectedContractState(e.target.value as DigitalContractStates)
        } else {
            setSelectedContractState(null)
        }
    }

    function handleIssuerOperationChange(e: React.ChangeEvent<HTMLInputElement>) {
        setIssuerOperation(e.target.value as FilterOperations)
    }
    
    function handleContractStateOperationChange(e: React.ChangeEvent<HTMLInputElement>) {
        setContractStateOperation(e.target.value as FilterOperations)
    }

    return (
        <React.Fragment>
            <Box>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <RadioGroup 
                            onChange={(e) => handleIssuerOperationChange(e)}
                            value={issuerOperation}
                        >
                            <FormControlLabel value={FilterOperations.EQUALS} control={<Radio />} label="Somente buscar pelo emissor" />
                            <FormControlLabel value={FilterOperations.NOT_EQUALS} control={<Radio />} label="Buscar todos exceto o emissor" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Box sx={{maxWidth: "75%"}}>
                            <Typography>Emissor do contrato:</Typography>
                            <SearchBar searchMode="contracts-users" onChange={handleContractIssuerSearchBarOnChange}/>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{my: 3.5}} />
            <Box>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <RadioGroup 
                            onChange={(e) => handleContractStateOperationChange(e)}
                            value={contractStateOperation}
                        >
                            <FormControlLabel value={FilterOperations.EQUALS} control={<Radio />} label="Somente buscar no estado de" />
                            <FormControlLabel value={FilterOperations.NOT_EQUALS} control={<Radio />} label="Buscar todos exceto no estado de" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Box sx={{maxWidth: "75%"}}>
                            <Typography>Estado do Contrato</Typography>
                            <Select
                                sx={{width: "100%"}}
                                value={selectedContractState}
                                onChange={(e) => handleSelectedContractStateChange(e)}
                                endAdornment={
                                    selectedContractState && (
                                        <IconButton sx={{mr: 3}} size="small" onClick={() => setSelectedContractState(null)}>
                                            <Close />
                                        </IconButton>
                                    )
                                }
                            >
                                {
                                    contractStates.map(state => (
                                        <MenuItem key={state.value} value={state.value}>
                                            {state.label}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export default DigitalContractTemplatePropertyFilters;