import { Description, ExpandMore, FilterAlt } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ContractsService from "../../../services/contracts";

interface ListOfTimePeriods {
  checked: boolean,
  value: string
}

export interface TemplateWithAverageIssueTime {
  templateId: string,
  name: string,
  avgTimeDifference: number
}

interface TemplateRow {
  id: string,
  name?: string,
  convertedAverageIssueTime: string
}

/** List of Time Periods filters */
const timePeriodsList: ListOfTimePeriods[] = [
  { checked: false, value: "Menos de 30 minutos" },
  { checked: false, value: "Entre 30 minutos e 1 hora" },
  { checked: false, value: "Entre 1 hora e 6 horas" },
  { checked: false, value: "Entre 6 horas e 24 horas" },
  { checked: false, value: "Entre 1 e 7 dias" },
  { checked: false, value: "Mais de 7 dias" }
];

interface Column {
  id: 'icon' | 'name' | 'averageSignatureTime';
  label: string;
  minWidth?: number;
  format?: (value: any) => string;
}

// Columns in table
const columns: Column[] = [
  { id: 'icon', label: '', minWidth: 20 },
  { id: 'name', label: 'Modelo de contrato', minWidth: 300 },
  { id: 'averageSignatureTime', label: 'Tempo médio de assinatura', minWidth: 400 },
];

const TemplateAverageIssueTime = (): JSX.Element => {
  const [listOfTimePeriods, setListOfTimePeriods] = useState<ListOfTimePeriods[]>(timePeriodsList);

  const [templatesWithAverageIssueTime, setTemplatesWithAverageIssueTime] = useState<TemplateWithAverageIssueTime[]>([]);
  const [templatesRows, setTemplateRows] = useState<TemplateRow[]>([]);
  const [templatesWithoutFilter, setTemplatesWithoutFilter] = useState<TemplateWithAverageIssueTime[]>([]);

  useEffect(() => {
    fetchTemplatesWithAverageIssueTime();
  }, [])

  useEffect(() => {
    const templatesRowsConverted = templatesWithAverageIssueTime.map((template)=> {
      return convertTemplatesToTemplatesRows(template);
    })
    setTemplateRows(templatesRowsConverted);
  }, [templatesWithAverageIssueTime])

  useEffect(() => {
    applyFilters();
  }, [listOfTimePeriods, templatesWithoutFilter]);

  /** Fetch the templates with average issue time */
  const fetchTemplatesWithAverageIssueTime = async () => {
    const response = await ContractsService.fetchOrganizationAverageIssueTime();

    setTemplatesWithoutFilter(response); // Save an copy
    setTemplatesWithAverageIssueTime(response)
  }

  /**
   * Normalize the contract templates to rows
   * @param template 
   * @returns 
   */
  const convertTemplatesToTemplatesRows = (template: TemplateWithAverageIssueTime) => {
    const convertedAverageIssueTime = convertAverageIssueTime(template.avgTimeDifference);

    const convertedTemplate : TemplateRow  = {
      name: template.name,
      id: template.templateId,
      convertedAverageIssueTime: convertedAverageIssueTime
    }

    return convertedTemplate;
  }

  /** Filter the templates and if doesnt have any filter */
  const applyFilters = () => {
    const filteredTemplates = templatesWithoutFilter.filter((template) => {
      const averageIssueTime = template.avgTimeDifference;
      const atLeastOneFilterSelected = listOfTimePeriods.some((item) => item.checked);

      if (atLeastOneFilterSelected) {
        return listOfTimePeriods.some((item) => {
          const filterValue = convertAverageIssueTime(averageIssueTime);
          return item.checked && item.value === filterValue;
        });
      }

      return true;
    });

    setTemplatesWithAverageIssueTime(filteredTemplates);
  };

  // Convert the miliseconds difference of the average issue time in a string
  const convertAverageIssueTime = (averageIssueTime: number) => {
    switch (true) {
      case averageIssueTime < 30 * 60 * 1000:
        return timePeriodsList[0].value;
      case averageIssueTime < 60 * 60 * 1000:
        return timePeriodsList[1].value;
      case averageIssueTime < 6 * 60 * 60 * 1000:
        return timePeriodsList[2].value;
      case averageIssueTime < 24 * 60 * 60 * 1000:
        return timePeriodsList[3].value;
      case averageIssueTime < 7 * 24 * 60 * 60 * 1000:
        return timePeriodsList[4].value;
      default:
        return timePeriodsList[5].value;
    }
  }
  
  const handleCheckboxChange = (index: number) => {
    setListOfTimePeriods((prevList) => {
      const updatedList = [...prevList];
      updatedList[index].checked = !updatedList[index].checked;
      return updatedList;
    });
  };

  return (
    <>
      {/** Filters Accordion */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
              <FilterAlt
                sx={{ transform: "translateY(6px); scaleY(-1)", mr: 1 }}
                color="primary"
              />
            Filtros por tempo médio de assinatura
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {listOfTimePeriods.map((item, index) => (
            <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <Checkbox checked={item.checked} onChange={() => handleCheckboxChange(index)   } sx={{ mr: 1 }} />
              <Typography>{item.value}</Typography>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
      
      {/** List of templates */}
      <TableContainer sx={{ border: "1px solid #EBEBEB", mt: 4  }}>
        <Table stickyHeader>
            <TableHead sx={{}}>
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} style={{ minWidth: column.minWidth, fontSize: "18px" }}><strong>{column.label}</strong></TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {templatesRows.map((item) => {
                    const icon = <Description sx={{color: "secondary.main", fontSize: "32px"}} />;
                    const name = item.name;
                    const averageIssueTime = item.convertedAverageIssueTime;
                    return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
                            <TableCell align="center">
                              {icon}
                            </TableCell>
                            <TableCell>
                              <Typography sx={{fontSize: "16px"}}>{name}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{fontSize: "16px"}}>{averageIssueTime}</Typography>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TemplateAverageIssueTime;