import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EncapsulatedContract } from '../../../models/contracts';
import type { RootState } from '../../store'


export interface ContractViewState {
    contract? : EncapsulatedContract,
    visible  : boolean
}

const initialState : ContractViewState = {
    contract : undefined,
    visible : false
}

export const ContractViewDialog = createSlice({
  name: 'contractViewDialog',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<ContractViewState>) => {
      state = {
        contract : action.payload.contract,
        visible : action.payload.contract !== undefined
      }
      return state;
    },
    hide: (state) => {
        state.contract = undefined;
        state.visible = false;
        return state;
    },
  },
})

// Action creators are generated for each case reducer function
export const { show, hide } = ContractViewDialog.actions

//Export the reducer
export const selectAppGlobalNotification = (state : RootState) => state.appGlobalNotification;

export default ContractViewDialog.reducer;
