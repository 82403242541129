import { Box, LinearProgress, Stack, Typography } from "@mui/material"
import { maxWidth } from "@mui/system"

interface SplashscreenProps extends React.ComponentProps<any> {
    hasErrors: boolean
}

const Splashscreen = (props: SplashscreenProps): JSX.Element => {
    return (
        <Box>
            <Box sx={{display: "flex", flexDirection: "column" , alignItems: "center", justifyContent: "center", height: "100vh"}}>
                {!props.hasErrors ? 
                    <>
                        <img src="/images/cartorize-logo.svg" alt="Cartorize" />
                        <Stack sx={{ width: "100%", maxWidth: "700px", color: "#5D00A4", marginTop: "2rem"}} >
                            <LinearProgress color="inherit" />
                        </Stack>
                    </>
                :
                    <>
                        <Typography variant="h4" sx={{p : 16}} fontSize={"1.6rem"}>Estamos com problemas para acessar o Cartorizi :/</Typography>
                        <img src="/images/cartorizi_err.svg" alt="Placeholder" width="480px" style={{maxWidth : "50%"}} />
                        <Box sx={{textAlign: "center", color: "#DC3545", marginTop: "2rem"}}>
                            <Typography sx={{fontSize: "1.25rem"}} >Se o problema persistir, contate o suporte</Typography>
                            <Typography sx={{fontSize: "1.25rem"}} >
                                <a color="primary" href="#" onClick={() => window.location.reload()}>Atualize a página </a> 
                                ou tente novamente em instantes.
                            </Typography>
                        </Box>
                    </>
                }
            </Box>
        </Box>
    )
}

export default Splashscreen