import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material";

interface UserEditorPreferenceProps extends React.ComponentProps<any> {
    open: boolean,
    close: () => void,
    userName: string,
    changeUserEditorPreference: (cuep: boolean) => void;
    userPreferenceEditorCache: (upec: boolean) => void;
}

export const UserEditorPreferenceDialog = (props: UserEditorPreferenceProps): JSX.Element => {
    const handleContinueClick = () => {
        props.changeUserEditorPreference(true);
        props.userPreferenceEditorCache(true);
        props.close();
    };

    const handleContinueOldEditorClick = () => {
        props.changeUserEditorPreference(false);
        props.userPreferenceEditorCache(false);
        props.close();
    };

    return (
        <Dialog open={props.open} maxWidth={"md"}>
            <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 6 }}>
                <DialogContentText sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                    <Typography sx={{ display: "inline", fontSize: "24px", fontWeight: 600 }}>
                        Olá, <Typography sx={{ display: "inline", color: "primary.main", fontSize: "24px", fontWeight: 600 }}>{props.userName}</Typography>. Temos uma grande novidade.
                    </Typography>
                    <Typography sx={{ mt: 4, display: "inline", fontSize: "18px" }}>
                        Para comemorar o aniversário de 1 ano do <Typography sx={{ display: "inline", color: "primary.main", fontSize: "18px", fontWeight: 600 }}>Cartorizi</Typography>, estamos lançando nosso <Typography sx={{ display: "inline", color: "primary.main", fontSize: "18px", fontWeight: 600 }}>Editor de Contratos 2.0</Typography>.
                    </Typography>
                    <Typography sx={{ mt: 2, display: "inline", fontSize: "18px" }}>
                        O editor foi construído com o melhor do que aprendemos nesse 1 ano de jornada.
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "0px 48px 48px 48px" }}>
                <Button
                    onClick={handleContinueClick}
                    autoFocus
                    sx={{ width: "100%", margin: 1, backgroundColor: "primary.main", color: "#fff", fontSize: "18px", fontWeight: 600, borderRadius: "12px" }}
                >
                    Clique aqui para acessar o novo editor
                </Button>
                <Button onClick={handleContinueOldEditorClick}>
                    Clique aqui para continuar utilizando o editor antigo
                </Button>
            </DialogActions>
        </Dialog>
    );
};
