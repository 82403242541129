import { CountsPerState, PropertyFilter } from "../models/analytics";
import { TotalMonthlyCountOfContracts } from "../models/contracts";
import contractsAPI from "./contracts-api";

const AnalyticsService = {
    async countsPerState(startDate: number, endDate: number): Promise<CountsPerState> {
        //Send request
        return await (await contractsAPI.get(`/rest/v1/digital-contracts/analytics/organization/counts-per-state?startDate=${startDate}&endDate=${endDate}`)).data;
    },

    async conversionAnalyticsData(
        startDate: Date,
        endDate: Date,
        filters: PropertyFilter[] | undefined = undefined,
        tagName: string | undefined = undefined ,
    ): Promise<TotalMonthlyCountOfContracts> {
        let url = "/rest/v1/digital-contracts/analytics/organization/total-count-of-contracts"
        url += `?startDate=${startDate.getTime()}`;
        url += `&endDate=${endDate.getTime()}`;
        if (filters) url += `&filter=${encodeURI(JSON.stringify(filters))}`
        if (tagName) url += `&tagName=${tagName}`

        return await (await contractsAPI.get(url)).data
    }

}

export default AnalyticsService;