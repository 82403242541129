import { useContext, useEffect, useState } from "react";
import { Box, Button, DialogActions, Drawer, Typography } from "@mui/material";
import { useAppDispatch } from '../../redux/hooks';
import { Info } from "@mui/icons-material";
import { LoadingButton } from "../../components/LoadingButton";
import {CurrentContext} from "../../appctx/webappContext";

import StlInterceptor from '../../contexts/stl'
import RenewAccessDialog from '../authentication/RenewAccessDialog'
import { SecurityTierLevels } from "../../store/stl";
import subusersService from "../../services/subusers";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import AppNotification from "../../components/AppNotification";
import { AuthenticationContextData } from "../../models/authentication";
import { UserAccount } from "../../models/user";

interface DeleteSubuserProps extends React.ComponentProps<any> {
    auth: AuthenticationContextData,
    subuser: UserAccount | null,
    open: boolean,
    close: () => void,
    onSuccess: (deletedUserUuid: string) => void,
    updateUsersQuota: (addedSubusers: number) => void
}

const DeleteSubuser = (props: DeleteSubuserProps) => {

    //Props
    const { auth, subuser, open, close, onSuccess, updateUsersQuota } = props;

    //Context props
    const notification = useAppDispatch();
    const stl = useContext(StlInterceptor);

    //Loading state
    const [loading, setLoading] = useState(false);

    //Renew access dialog 
    const [renewSessionDialog, setRenewSessionDialog] = useState(false);

    /**
      * Import a selected user from Klaus Accounts
      * @returns 
      */
    const deleteUser = async () => {
        if (!subuser) return;

        setRenewSessionDialog(false);
        setLoading(true);

        try {
            const deletedUser = await subusersService.delete(subuser.uuid);

            if (!deletedUser) return;

            notification(show({ type: 'success', message: "Usuário deletado" }));

            onSuccess(subuser.uuid)
        } catch (e) {
            notification(show({ type: 'error', message: new ErrorWrapper(e).message }))
        } finally {
            setLoading(false);
            updateUsersQuota(-1)
        }
    }

    return (
        <Drawer anchor='right' open={open} onClose={close} sx={{ '& .MuiDrawer-paper': { width: { sm: 550, xs: 350 }, p: { sm: 5, xs: 3 } } }}>
            <Typography variant='h6' mb={5} color='primary.main'> <strong>Deletar</strong> usuário</Typography>
            <Box display='flex' alignItems='center'>
                <Info color='info' sx={{ mr: 1 }} /><Typography variant='subtitle1'> Tem certeza que deseja deletar a conta de <strong>{subuser?.fullname}</strong>?</Typography>
            </Box>
            <br />
            <Typography variant='subtitle2'> 
                Deletando o usuário do Cartorizi ele <b>não será deletado da plataforma de contas</b>. Inclusive, é possível
                importá-lo novamente caso seja necessário.
            </Typography>
            <br />
            <Typography variant='subtitle2'> 
                Caso queira deletar o usuário de forma permanente, acesse a <a href={CurrentContext.pathToAccountsWebappUrl("/user-and-teams")}  target="_blank"> 
                página de contas</a> e delete-o lá.
            </Typography>

            <DialogActions sx={{ mt: 5 }}>
                <Button onClick={close}>Cancelar</Button>
                <LoadingButton variant='contained' color='error' loading={loading}
                    onClick={() => {
                        stl.require(auth, SecurityTierLevels.MaxLevel)
                            .then(() => deleteUser())
                            .catch(() => setRenewSessionDialog(true))
                    }}
                >Deletar</LoadingButton>
            </DialogActions>
            <RenewAccessDialog sessionRenewed={() => deleteUser()} open={renewSessionDialog} onClose={() => setRenewSessionDialog(false)} />
            <AppNotification />
        </Drawer >
    )
}

export default DeleteSubuser;
