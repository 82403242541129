import { AccessTime, ArrowDropDown, Dashboard, FilterList, FolderOff, Help, ListAlt, PieChart, Search, ViewComfy, ViewKanban } from "@mui/icons-material";
import { TabContext, TabList } from "@mui/lab";
import { Alert, AlertTitle, Autocomplete, Badge, Button, Checkbox, CircularProgress, Container, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, LinearProgress, Menu, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Stack, Switch, SxProps, Tab, TextField, Theme, ToggleButton, ToggleButtonGroup, Tooltip, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import 'moment/locale/pt-br';
import React, { useContext, useEffect, useRef, useState } from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import TagsSearch from "../../components/TagsSearch";
import { ContractCard } from "../../components/contracts/ContractCard";
import { DigitalContractStates, DigitalContractTemplate, EditableDigitalContract, EncapsulatedContract, IssuedDigitalContract, parseToNormalizedContract } from "../../models/contracts";
import { SearchResult } from "../../models/search-result";
import ContractsService from "../../services/contracts";
import { TagsModel } from "../../services/tags";
import { Colors } from "../../utils/colors";
import { ObjectUtils } from "../../utils/objects";
import { IIndexable } from "./finance/dashboards/analytics-utils";
import { OrganizationTeam } from "../../models/teams";
import TeamsService from "../../services/teams";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { useDispatch } from "react-redux";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import AuthenticationContext from "../../contexts/authentication";
import { RolesContext } from "../../contexts/roles";
import { AccountRoles } from "../../models/user";
import { Pagination } from "../../models/pagination";
import ContractsScheduleForDeletionDialog from "../../components/contracts/ContractsScheduledForDeletionDialog";

type ContractViewTypes = "list" | "kanban" | "dashboard" | "graphic";

interface ContractViewOption {
    label: string,
    viewType: ContractViewTypes
}

interface DigitalContractTemplateAutocomplete {
    label: string;
    source: DigitalContractTemplate;
}

interface GraphicDatasets {
    labels: string[];
    datasets: Dataset[];
}

interface Dataset {
    label: string;
    backgroundColor: string | string[];
    lineColor: string | string[];
    values: number[];
}

interface ContractsFinancieViewProps extends React.ComponentProps<any> {
    financialModuleEnabled: Boolean
}

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiAutocomplete-inputRoot': {
        padding: theme.spacing(1),
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    '& .MuiInput-root:before': {
        border: 'none',
    },
    '& .MuiInput-root:hover:before': {
        border: 'none',
    },
}));

const StyledSearchBar = styled(SearchBar)(({ theme }) => ({
    '& .MuiSelect-select': {
        padding: theme.spacing(1),
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
}));

function ContractViewTypeIcon(contractViewType: ContractViewTypes): JSX.Element {
    switch (contractViewType) {
        case "kanban":
            return (<ViewKanban />)
        case "list":
            return (<ListAlt />)
        case "dashboard":
            return (<Dashboard />)
        case "graphic":
            return (<PieChart />)
    }
}

interface DateInterval {
    startDate: Date,
    endDate: Date,
}

interface DateIntervalSelection {
    label: string,
    interval: DateInterval | null,
}

interface ExpirationDateSelection {
    label: string,
    daysUntilExpiration: number | null
}

interface ContractStatusFilter {
    label: string;
    state: DigitalContractStates
}

interface DateOrdenationSelection {
    label: string;
}

interface TotalBillingDataSet {
    label: string,
    value: number
}

interface AutocompleteOptions {
    id: string,
    label: string
}

/**
 * View used to show the authenticated user's organization contracts state organized
 * on an Kanban board
 * @returns 
 */
const Contracts = (props: ContractsFinancieViewProps): JSX.Element => {

    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Visualização de Contratos"
    }, []);

    // Context
    const notification = useDispatch();
    const authUser = useContext(AuthenticationContext);
    const userRoles = useContext(RolesContext);

    // static props
    // - Contract state filter options
    const ContractStatusFilterOptions: ContractStatusFilter[] = [
        {
            state: DigitalContractStates.NOT_READY_TO_BE_ISSUED,
            label: "Aguardando informações"
        },
        {
            state: DigitalContractStates.READY_TO_BE_ISSUED,
            label: "Prontos para enviar para assinatura"
        },
        {
            state: DigitalContractStates.WAITING_FOR_SIGNATURES,
            label: "Aguardando assinaturas"
        },
        {
            state: DigitalContractStates.SIGNED,
            label: "Assinado"
        },
        {
            state: DigitalContractStates.INACTIVE,
            label: "Inativo"
        }
    ];

    // - Contract view options
    const ContractViewOptions: ContractViewOption[] = [
        {
            viewType: "kanban",
            label: "Visualização Kanban",
        },
        {
            viewType: "list",
            label: "Visualização em lista",
        },
        {
            viewType: "dashboard",
            label: "Visualização Dashboard"
        },
        {
            viewType: "graphic",
            label: "Visualização de Gráfico"
        }
    ];
    // - Date inteval options
    const DateIntervalSelections: DateIntervalSelection[] = [
        {
            label: "Último dia",
            interval: {
                startDate: moment().subtract(1, "days").toDate(),
                endDate: new Date(),
            }
        },
        {
            label: "Últimos 7 dias",
            interval: {
                startDate: moment().subtract(7, "days").toDate(),
                endDate: new Date(),
            }
        },
        {
            label: "Últimos 30 dias",
            interval: {
                startDate: moment().subtract(30, "days").toDate(),
                endDate: new Date(),
            }
        },
        {
            label: "Personalizado",
            interval: null
        },
    ]

    const DateOrdenationSelections: DateOrdenationSelection[] = [
        {
            label: "Data de emissão",
        },
        {
            label: "Data de assinatura",
        }
    ]

    const ExpirationDateSelections: ExpirationDateSelection[] = [
        {
            label: "30 dias",
            daysUntilExpiration: 30
        },
        {
            label: "60 dias",
            daysUntilExpiration: 60
        },
        {
            label: "90 dias",
            daysUntilExpiration: 90
        },
        {
            label: "Personalizado",
            daysUntilExpiration: null
        },
    ]

    const DAYS_TO_BE_DELETED = 30;

    const PAGINATION_LIMIT = 20;

    const dummyPagination: Pagination = {
        recordsPerPage: 0,
        totalPages: 0,
        totalRecords: 0
    }

    const hasDateIntervalCache = localStorage.getItem("date_interval_cache");
    const hasDateOrdenation = localStorage.getItem("date_filter_ordenation");

    const dataIntervalJSON = hasDateIntervalCache ? JSON.parse(hasDateIntervalCache) : null

    const dataInterval: DateIntervalSelection = dataIntervalJSON ?

        {
            label: dataIntervalJSON.label,
            interval: {
                startDate: new Date(dataIntervalJSON.interval.startDate),
                endDate: new Date(dataIntervalJSON.interval.endDate)
            }
        } as DateIntervalSelection :

        DateIntervalSelections[1]

    //presets 
    const refSelectedDateInterval = dataInterval;
    const refSelectedDateOrdenation = hasDateOrdenation ? { label: hasDateOrdenation } : DateOrdenationSelections[1];

    //Screen size
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));

    // states
    // - filters
    const [selectedContractStatesFilters, setSelectedContractStatesFilters] = useState<DigitalContractStates[]>([
        DigitalContractStates.NOT_READY_TO_BE_ISSUED,
        DigitalContractStates.READY_TO_BE_ISSUED,
        DigitalContractStates.WAITING_FOR_SIGNATURES,
        DigitalContractStates.SIGNED,
        DigitalContractStates.INACTIVE,
    ]);
    const [selectedContractIssuerFilter, setSelectedContractIssuerFilter] = useState<string | undefined>(undefined);
    const [contractFilters, setContractFilters] = useState<IIndexable>({});
    const [selectedDigitalContractTemplateFilter, setSelectedDigitalContractTemplateFilter] = useState<DigitalContractTemplate | undefined>(undefined);
    const [selectedDateInterval, setSelectedDateInterval] = useState(refSelectedDateInterval);
    const [selectedDateOrdenation, setSelectedDateOrdenation] = useState(refSelectedDateOrdenation);
    const [selectedExpirationDate, setSelectedExpirationDate] = useState<ExpirationDateSelection | null>(null);
    const [startDate, setStartDate] = useState(refSelectedDateInterval.interval?.startDate);
    const [endDate, setEndDate] = useState(new Date());
    const [daysUntilExpiration, setDaysUntilExpiration] = useState<number | undefined>(undefined);
    const [expirationDate, setExpirationDate] = useState<Date>(new Date());
    const [selectedView, setSelectedView] = useState<ContractViewTypes>("kanban");

    const [searchByExpiration, setSearchByExpiration] = useState(false);
    const [onlySearchByExpiration, setOnlySearchByExpiration] = useState(false);

    const [loading, setLoading] = useState(true);

    const datePickerRef = useRef<HTMLDivElement>(null);
    moment.locale("pt-br");

    // anchors
    const [dateIntervalMenuAnchor, setDateIntervalMenuAnchor] = useState<null | HTMLElement>(null);
    const [contractIssuerFilterMenuAnchor, setContractIssuerFilterMenuAnchor] = useState<null | HTMLElement>(null);
    const [templateFilterMenuAnchor, setTemplateFilterMenuAnchor] = useState<null | HTMLElement>(null);
    const [stateFilterMenuAnchor, setStateFilterMenuAnchor] = useState<null | HTMLElement>(null);
    const [tagFilterMenuAnchor, setTagFilterMenuAnchor] = useState<null | HTMLElement>(null);
    const [teamMenuAnchor, setTeamMenuAnchor] = useState<null | HTMLElement>(null);

    //Filter selected options
    const [selectedTag, setSelectedTag] = useState<TagsModel | null>(null);    
    const [filterAndAndOr, setFilterAndAndOr] = useState("E");
    const [selectedTemplateFilter, setSelectedTemplateFilter] = useState('');
    const [selectedIssuerFilter, setSelectedIssuerFilter] = useState('');
    const [showContractTags, setShowContractTags] = useState(true);

    // - global sync state notifications
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);

    // - DigitalContractTemplate pagination control
    const [digitalContractTemplates, setDigitalContractTemplates] = useState<DigitalContractTemplate[]>([]);

    //  - EditableDigitalContract pagination control
    const [editableDigitalContracts, setEditableDigitalContracts] = useState<EditableDigitalContract[]>([]);

    //  - IssuedDigitalContract pagination control
    const [issuedDigitalContracts, setIssuedDigitalContracts] = useState<IssuedDigitalContract[]>([]);

    // - NormalizedContract
    const [normalizedContracts, setNormalizedContracts] = useState<EncapsulatedContract[]>([]);

    const [totalAmountsGroupedByBilling, setTotalAmountsGroupedByBilling] = useState<TotalBillingDataSet[]>([]);

    const [totalAmountBillingData, setTotalAmountBillingData] = useState(0);

    const [contractsTab, setContractsTab] = useState<ContractViewTypes>("kanban");

    const [teamsUserIsSupervisor, setTeamsUserIsSupervisor] = useState<OrganizationTeam[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<OrganizationTeam | null>(null);
    const [teamsAutocompleOptions, setTeamsAutocompleteOptions] = useState<AutocompleteOptions[]>([]);

    const [viewModeVisible, setViewModeVisible] = useState(true);

    const [selectedTags, setSelectedTags] = useState<TagsModel[]>([]);
    const [paginationEditableScheduleForDeletion, setPaginationEditableScheduleForDeletion] = useState<Pagination>(dummyPagination);
    const [paginationIssuedScheduleForDeletion, setPaginationIssuedScheduleForDeletion] = useState<Pagination>(dummyPagination);
    const [normalizedContractsToBeDeleted, setNormalizedContractsToBeDeleted] = useState<EncapsulatedContract[]>([]);
    const [editablesToBeDeleted, setEditablesToBeDeleted] = useState<EditableDigitalContract[]>([]);
    const [issuedsToBeDeleted, setIssuedsToBeDeleted] = useState<IssuedDigitalContract[]>([]);

    const [openDeletionAlert, setOpenDeletionAlert] = useState(true);
    const [openDeletionDialog, setOpenDeletionDialog] = useState(false);

    const financialModuleEnabled = props.financialModuleEnabled;//TODO

    const navigate = useNavigate()

    useEffect(() => {
        const hasDateIntervalCache = localStorage.getItem("date_interval_cache");
        const hasDateOrdenation = localStorage.getItem("date_filter_ordenation");

        if (hasDateIntervalCache && hasDateOrdenation) {
            setSelectedDateInterval(JSON.parse(hasDateIntervalCache))
            setSelectedDateOrdenation({ label: hasDateOrdenation });
        }
    }, [])

    useEffect(() => {
        let total = 0.0;
        const totalAmountsGroupedByBillingMap = new Map<string, number>();

        normalizedContracts.map(c => {
            c.normalized.billingData?.map(b => {
                const template = digitalContractTemplates.find(t => t.id === c.normalized.templateId);
                const key = `${(template) ? template.name : "Outros"} - ${b.name}`;
                if (!totalAmountsGroupedByBillingMap.has(key)) {
                    totalAmountsGroupedByBillingMap.set(key, 0);
                }
                let currentValue = totalAmountsGroupedByBillingMap.get(key) as number;
                totalAmountsGroupedByBillingMap.set(key, currentValue + b.value.regionalValue);

                total = total + b.value.regionalValue;
            })
        })

        const totalAmountGroupedByBilling: TotalBillingDataSet[] = [];
        totalAmountsGroupedByBillingMap.forEach((value, key) => {
            totalAmountGroupedByBilling.push({
                label: key,
                value: value
            })
        });

        setTotalAmountsGroupedByBilling(totalAmountGroupedByBilling);
        setTotalAmountBillingData(total)

    }, [normalizedContracts, digitalContractTemplates])

    // - effects that triggers every time the component is loaded. It calls the fetchDigitalContracts that fetches the contract from the API
    useEffect(() => {
        fetchDigitalContracts();
        localStorage.setItem("date_interval_cache", JSON.stringify({
            label: selectedDateInterval.label,
            interval: {
                startDate: startDate,
                endDate: endDate,
            }
        }))
        localStorage.setItem("date_filter_ordenation", selectedDateOrdenation.label);
        paginationOfContractsScheduleForDeletion();
    }, [startDate, endDate, selectedDateOrdenation, daysUntilExpiration, selectedTeam]);

    // - effect triggered when an front-end filter is changed
    useEffect(() => {

        const filters: IIndexable = {};

        // add the contract issuer filter
        if (selectedContractIssuerFilter) {
            filters["source.issuerUuid"] = selectedContractIssuerFilter;
        }

        // add the digital contract template filter
        if (selectedDigitalContractTemplateFilter) {
            filters["normalized.templateId"] = selectedDigitalContractTemplateFilter.id;
        }

        // set the filter state
        setContractFilters(filters);
    }, [selectedContractIssuerFilter, selectedDigitalContractTemplateFilter, selectedTags])

    // - effect that observer editable and issued contracts changes
    useEffect(() => {

        // will store all merged contracts
        let mergedNormalizedContracts: EncapsulatedContract[] = [];

        // merge the contracts
        mergedNormalizedContracts = [
            ...editableDigitalContracts.map<EncapsulatedContract>(c => {
                return {
                    normalized: parseToNormalizedContract(c),
                    source: c
                }
            }),
            ...issuedDigitalContracts.map<EncapsulatedContract>(c => {
                return {
                    normalized: parseToNormalizedContract(c),
                    source: c
                }
            })
        ];

        // add front-end filters
        mergedNormalizedContracts = mergedNormalizedContracts.filter(m => {
            // check for contract filter filters
            for (const filterName in contractFilters) {
                const filterValue = contractFilters[filterName];
                if (!ObjectUtils.deepEquals(m, filterName, filterValue)) {
                    return false;
                }
            }

            if (selectedTags.length > 0) {
                // check if contract has tags
                if (!m.source.contractTags || m.source.contractTags.length === 0) {
                    return false; // ignore contract without tags
                }

                const contractTags = m.source.contractTags;
                
                // "OR" logic - Find all contracts that have matching tags 
                if(filterAndAndOr === "OU") {
                    const matchingTag = contractTags.find(tag => selectedTags.some(t => tag.tagName === t.tagName));
                    if (!matchingTag) {
                        return false;
                    }
                }
                // "E" logic - All tags in selectedTags must match
                else {
                    const hasAllTags = selectedTags.every(selectedTag =>
                        contractTags.some(contractTag => contractTag.tagName === selectedTag.tagName)
                    );
                    if (!hasAllTags) {
                        return false;
                    }
                }
            }

            // end with the selected contract states filters
            return selectedContractStatesFilters.indexOf(m.normalized.state) >= 0;
        })

        if (selectedDateOrdenation.label === "Data de assinatura") {
            // verify if the fully signed date is present, if not use issuenceDate. 
            mergedNormalizedContracts.sort((a, b) => {
                // Get the fullySigendDate
                const fsdA = a.normalized.fullySignedDate;
                const fsdB = b.normalized.fullySignedDate;

                // Check if there is fullySignedDate for both a and b
                if (fsdA && fsdB) {
                    return fsdB.getTime() - fsdA.getTime();
                } else if (!fsdA && fsdB) {
                    return fsdB.getTime() - a.normalized.issuanceDate.getTime();
                } else if (fsdA && !fsdB) {
                    return b.normalized.issuanceDate.getTime() - fsdA.getTime();
                } else {
                    return b.normalized.issuanceDate.getTime() - a.normalized.issuanceDate.getTime();
                }
            })
        }
        else {
            mergedNormalizedContracts.sort((a, b) => {
                return b.normalized.issuanceDate.getTime() - a.normalized.issuanceDate.getTime();
            });
        }

        // set the state to render the cards
        setNormalizedContracts(mergedNormalizedContracts);

    }, [editableDigitalContracts, issuedDigitalContracts, contractFilters, selectedContractStatesFilters, filterAndAndOr]);

    useEffect(() => {
        if (datePickerRef.current) {
            const targetAdornmentButton = datePickerRef.current.firstChild;
            if (targetAdornmentButton) {
                (targetAdornmentButton as HTMLButtonElement).click();
            }
        }
    }, [startDate])

    useEffect(() => {
        fetchTeamsUserIsSupervisor();
    }, [])

    // useEffect for hidding the view mode box after a time has passed
    useEffect(() => {
        setViewModeVisible(true)
        const timer = setTimeout(() => {
            setViewModeVisible(false)
        }, 10000)

        return () => clearTimeout(timer);
    }, [selectedTeam])

    useEffect(() => {

        let normalized: EncapsulatedContract[] = []

        normalized = [
            ...editablesToBeDeleted.map<EncapsulatedContract>(c => {
                return {
                    normalized: parseToNormalizedContract(c),
                    source: c
                }
            }),
            ...issuedsToBeDeleted.map<EncapsulatedContract>(c => {
                return {
                    normalized: parseToNormalizedContract(c),
                    source: c
                }
            }),
        ]

        normalized.sort((a, b) => {
            return a.normalized.issuanceDate.getTime() - b.normalized.issuanceDate.getTime();
        });

        setNormalizedContractsToBeDeleted(normalized);

    }, [editablesToBeDeleted, issuedsToBeDeleted])

    async function fetchTeamsUserIsSupervisor() {

        setTeamsUserIsSupervisor([])

        if (userRoles.assertIsOrganizationOrAdministratorOr(authUser, AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER)) {
            const pagination = await TeamsService.pagination()
            let teams: OrganizationTeam[] = []

            for (let currentPage = 1; currentPage <= pagination.totalPages; currentPage++) {
                TeamsService.fetch(currentPage)
                    .then(response => {
                        if (!response || response.length <= 0) return;
                        teams = [...teams, ...response]
                        setTeamsUserIsSupervisor(teams)
                        const options: AutocompleteOptions[] = [];
                        teams.forEach(t => options.push({ id: t.guid, label: t.name }));
                        options.sort((a, b) => a.label.localeCompare(b.label))
                        options.unshift({id: "one_random_id", label: "Escolha um time"})
                        setTeamsAutocompleteOptions(options);
                    })
            }
        } else {
            TeamsService.fetchTeamsThatUserIsSupervisor()
                .then(response => {
                    setTeamsUserIsSupervisor(response ? response : []);
                    const options: AutocompleteOptions[] = [];
                    response.forEach(t => options.push({ id: t.guid, label: t.name }));
                    options.sort((a, b) => a.label.localeCompare(b.label))
                    options.unshift({id: "one_random_id", label: "Escolha um time"})
                    setTeamsAutocompleteOptions(options);
                })
                .catch(e => {
                    const err = new ErrorWrapper(e);
                    notification(show({ type: "error", message: err.message }));
                })
        }

    }

    /**
     * Function that handles the event when changes the tags filter AND / OR
     * @param value
     */
    function handleFilterAndAndOr(value: string){
        setFilterAndAndOr(value);
    }

    /**
     * Function that handles the event when an contract is created to sign state
     * @param sourceContract 
     * @param newContract 
     */
    function handleContractCardContractWasIssuedEvent(sourceContract: EditableDigitalContract, newContract: IssuedDigitalContract) {
        // refresh the state removing the editable digital contract identified as the source and
        // add the new contract on the issued digital contracts list
        setEditableDigitalContracts(editableDigitalContracts.filter(e => e.id !== sourceContract.id));
        setIssuedDigitalContracts([...issuedDigitalContracts, newContract]);
    }


    /**
     * Function that handles the event when the editable contract is deleted
     * @param deletedContract 
     */
    function handleContractCardContractDeletedEvent(deletedContract: EncapsulatedContract) {
        // refresh the normalized contracts state removing the deleted contract given by the parameter
        setNormalizedContracts(normalizedContracts.filter(n => n.normalized.id !== deletedContract.normalized.id));
    }

    /**
         * Toggle selectedContractStatesFilters state 
         * @param state 
         */
    function toggleContractStateFilter(state: DigitalContractStates) {
        // make an copy of the state array
        const newSelectedContractStateFilters = [
            ...selectedContractStatesFilters
        ];

        // if the array contains the element, remove it
        if (newSelectedContractStateFilters.indexOf(state) >= 0) {
            setSelectedContractStatesFilters(newSelectedContractStateFilters.filter(c => c !== state));
        }
        // otherwise, include it on the filters array
        else {
            newSelectedContractStateFilters.push(state);
            setSelectedContractStatesFilters(newSelectedContractStateFilters);
        }
    }

    /**
     * Custom props used for ContractsKanbanColumn component
     */
    interface ContractsKanbanColumnProps extends React.ComponentProps<any> {
        contractState: DigitalContractStates,
        columnTitle: string
    }

    /**
     * Function to verify if the date range its higher than a month and return true or false
     */
    function isMonthOrMore(): boolean {
        const startMoment = moment(startDate);
        const endMoment = moment(endDate);

        // Calculate the duration between the two dates
        const duration = moment.duration(endMoment.diff(startMoment));

        // Check if the duration is equal to or greater than 1 month
        return duration.asMonths() >= 1;
    }

    /**
     * Generate Data sets for graph
     * @param graphType 
     * @returns 
     */
    function generateDatasetForGraph(graphType: "issue" | "template") {
        // Create map for the contracts
        const contractGroup: Map<string, EncapsulatedContract[]> = new Map();

        // Set the variables for the dataset
        const datasets: Dataset[] = []
        const labels: string[] = []
        const values: number[] = []

        // Check if it's a issue type dataset
        if (graphType === "issue") {

            for (const contract of normalizedContracts) {

                // Check if the difference between start and end date is less than a month
                if (!isMonthOrMore()) {
                    // Map the contracts by the issue day
                    const issueDay = contract.normalized.issuanceDate.toISOString().split('T')[0];

                    if (contractGroup.has(issueDay)) {
                        contractGroup.get(issueDay)?.push(contract);
                    } else {
                        contractGroup.set(issueDay, [contract]);
                    }

                } else {

                    // Map the contracts by the issue month
                    const yearMonth = contract.normalized.issuanceDate.toLocaleString('default', { year: 'numeric', month: 'numeric' });
                    const year = yearMonth.split('/')[1]
                    const month = yearMonth.split('/')[0]
                    const normalizedIssueDate = `${year}-${month}`

                    if (contractGroup.has(normalizedIssueDate)) {
                        contractGroup.get(normalizedIssueDate)?.push(contract);
                    } else {
                        contractGroup.set(normalizedIssueDate, [contract]);
                    }
                }
            }

            // Sort the array in ascending order
            const sortedContractByIssueDateGroup = new Map(
                Array.from(contractGroup).sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime())
            );

            // Get labels and values for the dataset
            sortedContractByIssueDateGroup.forEach((contracts, label) => {
                labels.push(label)
                values.push(contracts.length)

            })

            const databaseColor = Colors.random.withSL(0.89, 0.72);

            const dataset = {
                label: "Contratos Emitidos",
                backgroundColor: databaseColor.toRGBHexadecimal(),
                lineColor: databaseColor.brighter(-0.1).toRGBHexadecimal(),
                values: values
            }

            datasets.push(dataset)

        }
        // Check if it's a template type dataset
        else if (graphType === "template") {
            for (const contract of normalizedContracts) {
                const templateName = digitalContractTemplates.find(template => template.id === contract.normalized.templateId);

                if (templateName) {
                    if (contractGroup.has(templateName.name)) {
                        contractGroup.get(templateName.name)?.push(contract)
                    } else {
                        contractGroup.set(templateName.name, [contract])
                    }
                } else {
                    if (contractGroup.has("Outros")) {
                        contractGroup.get("Outros")?.push(contract)
                    } else {
                        contractGroup.set("Outros", [contract])
                    }
                }
            }

            contractGroup.forEach((contracts, label) => {
                labels.push(label)
                values.push(contracts.length)
            })

            const backgroundColors: string[] = []
            const borderColors: string[] = []

            labels.forEach(label => {
                const color = Colors.random.withSL(0.89, 0.72)
                backgroundColors.push(color.toRGBHexadecimal())
                borderColors.push(color.brighter(-0.1).toRGBHexadecimal())

            })

            const dataset = {
                label: "Modelos emitidos",
                backgroundColor: backgroundColors,
                lineColor: borderColors,
                values: values
            }

            datasets.push(dataset)
        }

        return { labels, datasets }
    }

    /**
     * Render a column of the contracts based on the contracts state
     * @param props 
     * @returns 
     */
    const ContractsKanbanColumn = (props: ContractsKanbanColumnProps, columnColor: string = '#EEFFA3'): JSX.Element => {
        // filter the data only to the column state
        const data = normalizedContracts.filter(n => n.normalized.state === props.contractState);

        const showKanban = selectedContractStatesFilters.includes(props.contractState)
        return (
            <Box
                sx={{
                    minWidth: '240px',
                    marginLeft: '10px',
                    border: '2px solid #EEEEEE',
                    borderRadius: '4px',
                    padding: '4px 8px',
                    display: showKanban ? "block" : "none"
                }}
            >
                <Typography
                    sx={{
                        backgroundColor: columnColor,
                        padding: '2px 6px',
                        textAlign: 'center',
                        fontSize: '0.8em'
                    }}
                    variant="subtitle2"
                >
                    {props.columnTitle} <Badge badgeContent={data.length} color="primary" sx={{ ml: 2 }} />
                </Typography>
                {
                    data.map(n => (
                        <ContractCard
                            source={n}
                            showContractTags={showContractTags}
                            sx={{ maxWidth: '100px' }}
                            onContractWasIssued={handleContractCardContractWasIssuedEvent}
                            onContractDeleted={handleContractCardContractDeletedEvent}
                        />
                    ))
                }
            </Box>
        );
    }

    function KanbanView(): JSX.Element {
        return (
            <Stack
                direction="row"
                sx={{ marginBottom: '48px' }}
            >
                <ContractsKanbanColumn contractState={DigitalContractStates.NOT_READY_TO_BE_ISSUED} columnTitle="Aguardando informações" />
                <ContractsKanbanColumn contractState={DigitalContractStates.READY_TO_BE_ISSUED} columnTitle="Prontos para enviar para assinatura" />
                <ContractsKanbanColumn contractState={DigitalContractStates.WAITING_FOR_SIGNATURES} columnTitle="Aguardando assinaturas" />
                <ContractsKanbanColumn contractState={DigitalContractStates.SIGNED} columnTitle="Assinados" />
                <ContractsKanbanColumn contractState={DigitalContractStates.INACTIVE} columnTitle="Inativos" />
            </Stack>
        )
    }

    function ListView(): JSX.Element {

        /**
         * Toggle selectedContractStatesFilters state 
         * @param state 
         */
        function toggleContractStateFilter(state: DigitalContractStates) {
            // make an copy of the state array
            const newSelectedContractStateFilters = [
                ...selectedContractStatesFilters
            ];

            // if the array contains the element, remove it
            if (newSelectedContractStateFilters.indexOf(state) >= 0) {
                setSelectedContractStatesFilters(newSelectedContractStateFilters.filter(c => c !== state));
            }
            // otherwise, include it on the filters array
            else {
                newSelectedContractStateFilters.push(state);
                setSelectedContractStatesFilters(newSelectedContractStateFilters);
            }
        }

        return (
            <Container>
                {/** List all contracts using ContractCard */}
                <Box id="contract-list-container">
                    {
                        normalizedContracts
                            // exclude all contracts that is not on the filters
                            .map(contract => (
                                <ContractCard
                                    source={contract}
                                    showContractTags={showContractTags}
                                    onContractWasIssued={handleContractCardContractWasIssuedEvent}
                                    onContractDeleted={handleContractCardContractDeletedEvent}
                                />
                            ))
                    }
                </Box>
            </Container>
        );
    }

    function GraphicView(): JSX.Element {

        function isMonthOrMore(): boolean {
            const startMoment = moment(startDate);
            const endMoment = moment(endDate);

            // Calculate the duration between the two dates
            const duration = moment.duration(endMoment.diff(startMoment));

            // Check if the duration is equal to or greater than 1 month
            return duration.asMonths() >= 1;
        }

        function generateDatasetForGraph(graphType: "issue" | "template") {
            // Create map for the contracts
            const contractGroup: Map<string, EncapsulatedContract[]> = new Map();

            // Set the variables for the dataset
            const datasets: Dataset[] = []
            const labels: string[] = []
            const values: number[] = []

            // Check if it's a issue type dataset
            if (graphType === "issue") {

                for (const contract of normalizedContracts) {

                    // Check if the difference between start and end date is less than a month
                    if (!isMonthOrMore()) {
                        // Map the contracts by the issue day
                        const issueDay = contract.normalized.issuanceDate.toISOString().split('T')[0];

                        if (contractGroup.has(issueDay)) {
                            contractGroup.get(issueDay)?.push(contract);
                        } else {
                            contractGroup.set(issueDay, [contract]);
                        }

                    } else {

                        // Map the contracts by the issue month
                        const yearMonth = contract.normalized.issuanceDate.toLocaleString('default', { year: 'numeric', month: 'numeric' });
                        const year = yearMonth.split('/')[1]
                        const month = yearMonth.split('/')[0]
                        const normalizedIssueDate = `${year}-${month}`

                        if (contractGroup.has(normalizedIssueDate)) {
                            contractGroup.get(normalizedIssueDate)?.push(contract);
                        } else {
                            contractGroup.set(normalizedIssueDate, [contract]);
                        }
                    }
                }

                // Sort the array in ascending order
                const sortedContractByIssueDateGroup = new Map(
                    Array.from(contractGroup).sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime())
                );

                // Get labels and values for the dataset
                sortedContractByIssueDateGroup.forEach((contracts, label) => {
                    labels.push(label)
                    values.push(contracts.length)

                })

                const databaseColor = Colors.random.withSL(0.89, 0.72);

                const dataset = {
                    label: "Contratos Emitidos",
                    backgroundColor: databaseColor.toRGBHexadecimal(),
                    lineColor: databaseColor.brighter(-0.1).toRGBHexadecimal(),
                    values: values
                }

                datasets.push(dataset)

            }
            // Check if it's a template type dataset
            else if (graphType === "template") {
                for (const contract of normalizedContracts) {
                    const templateName = digitalContractTemplates.find(template => template.id === contract.normalized.templateId);

                    if (templateName) {
                        if (contractGroup.has(templateName.name)) {
                            contractGroup.get(templateName.name)?.push(contract)
                        } else {
                            contractGroup.set(templateName.name, [contract])
                        }
                    } else {
                        if (contractGroup.has("Outros")) {
                            contractGroup.get("Outros")?.push(contract)
                        } else {
                            contractGroup.set("Outros", [contract])
                        }
                    }
                }

                contractGroup.forEach((contracts, label) => {
                    labels.push(label)
                    values.push(contracts.length)
                })

                const backgroundColors: string[] = []
                const borderColors: string[] = []

                labels.forEach(label => {
                    const color = Colors.random.withSL(0.89, 0.72)
                    backgroundColors.push(color.toRGBHexadecimal())
                    borderColors.push(color.brighter(-0.1).toRGBHexadecimal())

                })

                const dataset = {
                    label: "Modelos emitidos",
                    backgroundColor: backgroundColors,
                    lineColor: borderColors,
                    values: values
                }

                datasets.push(dataset)
            }

            return { labels, datasets }
        }

        const barChartDataset: GraphicDatasets = generateDatasetForGraph("issue");

        const pieChartDataset: GraphicDatasets = generateDatasetForGraph("template");

        return (
            <Container>
                <Grid container mt={3} spacing={2} >
                    <Grid item xs={12}>
                        <Typography variant="h6" my={2} color={"primary.main"}>Gráfico de contratos emitidos</Typography>
                        <Box sx={{ height: "300px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {
                                barChartDataset
                                    ?
                                    <Bar
                                        data={{
                                            labels: barChartDataset.labels,
                                            datasets: barChartDataset.datasets.map(d => ({
                                                label: d.label,
                                                data: d.values,
                                                fill: false,
                                                borderWidth: 3,
                                                backgroundColor: d.backgroundColor,
                                                borderColor: d.lineColor
                                            }))
                                        }}
                                        options={{
                                            maintainAspectRatio: false
                                        }}
                                    />
                                    :
                                    <></>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" my={2} color={"primary.main"}>Gráfico de contratos emitidos por modelo</Typography>
                        <Box sx={{ maxHeight: "450px", minHeight: "450px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {
                                pieChartDataset
                                    ?
                                    <Pie
                                        data={{
                                            labels: pieChartDataset.labels,
                                            datasets: pieChartDataset.datasets.map((d: Dataset) => ({
                                                label: d.label,
                                                data: d.values,
                                                fill: false,
                                                borderWidth: 3,
                                                backgroundColor: d.backgroundColor,
                                                borderColor: d.lineColor
                                            }))
                                        }}
                                    />
                                    :
                                    <></>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    function DashboardView(): JSX.Element {
        const fullScreen = (window.screen.width <= 900) ? true : false;
        const responsiveStyle: SxProps<Theme> = { ['@media(max-width:900px)']: { padding: "0", display: "block", width: "100%", fontSize: "24px" }, display: "flex" };
        const cardTotalStyle: SxProps<Theme> = { ['@media(max-width:900px)']: {}, display: "flex", border: "1px solid #ddd", borderRadius: "12px", margin: "12px", padding: "20px", height: 200, flexDirection: "column", justifyContent: "space-around" };
        const gridResponsiveStyle: SxProps<Theme> = { ['@media(max-width:900px)']: { display: "block", padding: "0" }, display: "flex", padding: "20px 40px" };

        /**
         * Graphs issued contracts
        */
        const barChartDataset: GraphicDatasets = generateDatasetForGraph("issue");

        let totalSigned: number = 0;
        let totalWaitingForIssued: number = 0;

        normalizedContracts.map(c => {
            if (c.normalized.state === "SIGNED") {
                totalSigned++;
            }
            else {
                totalWaitingForIssued++;
            }
        })

        // If the Organization doesnt have quota to visualize Analytics, show the paywall instead of the Dashboard
        if (!financialModuleEnabled) {
            return (
                <Container>
                    <Box sx={[responsiveStyle, { display: "flex" }]}>
                        {
                            fullScreen ? <></> :
                                <Box sx={[responsiveStyle, { width: 1000 }]}>
                                    <img src="../images/assets/analytics.svg" width={"100%"} />
                                </Box>
                        }
                        <Box sx={[responsiveStyle, { flexDirection: "column", justifyContent: "space-evenly", padding: "30px", alignItems: "center" }]}>
                            <Typography sx={[responsiveStyle, { fontSize: "30px", textAlign: "center", fontWeight: 600, margin: "20px 0" }]}>Sua organização não tem o plano necessário para ver o Analytics Financeiro</Typography>
                            {
                                fullScreen ?
                                    <Box sx={[responsiveStyle, { width: 1000 }]}>
                                        <img src="../images/assets/analytics.svg" width={"100%"} />
                                    </Box>
                                    : <></>
                            }
                            <Box>
                                <Button variant="contained" sx={[responsiveStyle, { fontSize: "20px", padding: "8px 60px", margin: "20px 0", boxShadow: 3 }]}>Contrate agora mesmo</Button>
                            </Box>
                        </Box>
                    </Box>
                </Container >
            )
        }
        return (
            <Box sx={[gridResponsiveStyle]}>
                <Grid container spacing={1}>
                    <Grid item lg={4} xs={12}>
                        <Box sx={cardTotalStyle}>
                            <Typography sx={{ textAlign: "center", fontSize: "24px" }}>Total de contratos</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography sx={{ fontSize: "64px", color: "primary.main", fontWeight: 600 }}>{totalSigned + totalWaitingForIssued}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Box sx={cardTotalStyle}>
                            <Typography sx={{ textAlign: "center", fontSize: "24px" }}>Contratos assinados</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography sx={{ fontSize: "64px", color: "primary.main", fontWeight: 600 }}>{totalSigned}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Box sx={cardTotalStyle}>
                            <Typography sx={{ textAlign: "center", fontSize: "24px" }}>Contratos Aguardando Assinatura</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography sx={{ fontSize: "64px", color: "primary.main", fontWeight: 600 }}>{totalWaitingForIssued}</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {/**Total billing per period*/}
                    <Grid item xs={12}>
                        <Box sx={[{ border: "1px solid #ddd", borderRadius: "12px", margin: "12px", padding: "20px", flexDirection: "column", justifyContent: "space-around" }]}>
                            <Box sx={{ position: "sticky" }}>
                                <Typography sx={{ fontSize: "24px" }}>Total de entradas financeiras no período selecionado</Typography>
                                <Box sx={{ display: "flex", mb: 2 }}>
                                    <Typography sx={{ fontSize: "48px", color: "primary.main", fontWeight: 600, wordBreak: "break-word" }}>{totalAmountBillingData.toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        minimumFractionDigits: 2,
                                    })}</Typography>
                                </Box>
                            </Box>
                            <Divider />
                            <Box sx={{ maxHeight: 200, overflowY: "auto" }}>
                                {
                                    totalAmountsGroupedByBilling.map(g => (
                                        <Box key={g.label}>
                                            <Typography sx={{ fontSize: "20px", color: "primary.main", fontWeight: 400, wordBreak: "break-word" }}>
                                                {g.label} -
                                                <b>
                                                    {g.value.toLocaleString('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                        minimumFractionDigits: 2,
                                                    })}
                                                </b>
                                            </Typography>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Grid>



                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", border: "1px solid #ddd", borderRadius: "12px", margin: "12px", padding: "20px", flexDirection: "column", justifyContent: "space-around" }}>
                            <Box sx={{ height: "400px", alignItems: "center", justifyContent: "center" }}>
                                <Typography variant="h6" my={2}>Gráfico de contratos emitidos</Typography>
                                <Box sx={{ height: "80%" }}>
                                    {
                                        barChartDataset
                                            ?
                                            <Line
                                                data={{
                                                    labels: barChartDataset.labels,
                                                    datasets: barChartDataset.datasets.map(d => ({
                                                        label: d.label,
                                                        data: d.values,
                                                        fill: false,
                                                        borderWidth: 3,
                                                        backgroundColor: d.backgroundColor,
                                                        borderColor: d.lineColor
                                                    }))
                                                }}
                                                options={{
                                                    maintainAspectRatio: false
                                                }}
                                            />
                                            :
                                            <></>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid >
            </Box >
        );
    }

    /**
     * Fetch all digital contracts from the organization between using the client filters
     */
    async function fetchDigitalContracts() {
        const CONTRACTS_PER_REQUEST = 20;

        // reset data states
        setEditableDigitalContracts([]);
        setIssuedDigitalContracts([]);

        // fetch pagination data from both editable and issued digital contracts
        const digitalContractTemplatesPagination = await ContractsService.fetchTemplatesPaginationMetadata(CONTRACTS_PER_REQUEST);

        // if the daysUntilExpiration state is defined there is not need to fetch editable contracts, so, the total pages will me manually set to 0
        const editableDigitalContractsPagination = (daysUntilExpiration === undefined) ?
            await ContractsService.fetchEditableDigitalContractsPaginationMetadata(CONTRACTS_PER_REQUEST, startDate, endDate, selectedTeam) :
            { totalPages: 0 };
        const issuedDigitalContractsPagination = await ContractsService.fetchIssuedDigitalContractsPaginationMetadata(CONTRACTS_PER_REQUEST, startDate, endDate, daysUntilExpiration, selectedTeam);

        // set the total pages as the combination of total records from both editable and issued contracts
        setTotalPages(
            digitalContractTemplatesPagination.totalPages +
            editableDigitalContractsPagination.totalPages +
            issuedDigitalContractsPagination.totalPages
        );

        setLoading(false)

        // for each page fetch the content of the editable contracts
        let totalPages = 0;

        let digitalContractTemplates: DigitalContractTemplate[] = [];
        for (let page = 1; page <= digitalContractTemplatesPagination.totalPages; page++) {
            ContractsService.fetchTemplates(page).then(response => {
                digitalContractTemplates = [...response, ...digitalContractTemplates];
                setDigitalContractTemplates(digitalContractTemplates);
                setCurrentPage(++totalPages);
            });
        }
        if (daysUntilExpiration === undefined) {
            let editableDigitalContracts: EditableDigitalContract[] = [];
            for (let page = 1; page <= editableDigitalContractsPagination.totalPages; page++) {
                ContractsService.fetchEditableDigitalContracts(page, CONTRACTS_PER_REQUEST, startDate, endDate, selectedTeam).then(response => {
                    editableDigitalContracts = [...response, ...editableDigitalContracts];
                    setEditableDigitalContracts(editableDigitalContracts);
                    setCurrentPage(++totalPages);
                });
            }
        }
        // for each page fetch the content of the issued contracts
        let issuedDigitalContracts: IssuedDigitalContract[] = [];
        for (let page = 1; page <= issuedDigitalContractsPagination.totalPages; page++) {
            ContractsService.fetchIssuedDigitalContracts(page, CONTRACTS_PER_REQUEST, startDate, endDate, daysUntilExpiration, selectedTeam).then(response => {
                issuedDigitalContracts = [...response, ...issuedDigitalContracts];
                setIssuedDigitalContracts(issuedDigitalContracts);
                setCurrentPage(++totalPages);
            });
        }
    }

    function handleDateIntervalsRadioGroupOnChange(event: React.ChangeEvent<HTMLInputElement>, value: string): void {
        const selectedDateInterval = DateIntervalSelections.find(d => d.label === value);
        if (selectedDateInterval) {
            // update selected date interval state
            setSelectedDateInterval(selectedDateInterval);
            // if the interval is defined, set it to the start and end date state. This will trigger the contracts fetch function by the useEffect
            if (selectedDateInterval.interval) {
                setStartDate(selectedDateInterval.interval.startDate);
                setEndDate(selectedDateInterval.interval.endDate);
            }
        }
        else {
            throw new Error("Application could not resolve selected date interval selection: " + value);
        }
    }

    function handleDateOrdenationRadioGroupOnChange(event: React.ChangeEvent<HTMLInputElement>, value: string): void {
        const selectedDateOrdenation = DateOrdenationSelections.find(d => d.label === value);
        if (selectedDateOrdenation) {
            // update selected date ordenation state
            setSelectedDateOrdenation(selectedDateOrdenation);
        }
        else {
            throw new Error("Application could not resolve selected date ordenation: " + value);
        }
    }

    function handleExpirationDateRadioGroupOnChange(event: React.ChangeEvent<HTMLInputElement>, value: string): void {
        const selectedExpirationDate = ExpirationDateSelections.find(d => d.label === value);
        if (selectedExpirationDate) {
            setSelectedExpirationDate(selectedExpirationDate)
        }

        if (selectedExpirationDate?.daysUntilExpiration) {
            setDaysUntilExpiration(selectedExpirationDate.daysUntilExpiration)
        }
    }

    function handleDigitalContractTemplateAutocompleteContractFilter(
        event: React.SyntheticEvent<Element, Event>,
        value: DigitalContractTemplateAutocomplete | null): void {

        setSelectedDigitalContractTemplateFilter((value) ? value.source : undefined);
        setSelectedTemplateFilter((value) ? value.source.name : '')
    }

    function handleChangeSearchByExpiration(e: React.ChangeEvent<HTMLInputElement>): void {
        setSearchByExpiration(e.target.checked)
        if (!e.target.checked) {
            setDaysUntilExpiration(undefined)
            setSelectedExpirationDate(null)
        }
    }

    function handleChangeOnlySearchByExpiration(e: React.ChangeEvent<HTMLInputElement>): void {
        setOnlySearchByExpiration(e.target.checked)
        if (!e.target.checked) {
            setSelectedDateInterval(DateIntervalSelections[1])
            if (selectedDateInterval.interval) {
                setStartDate(selectedDateInterval.interval.startDate);
                setEndDate(selectedDateInterval.interval.endDate);
            }
        } else {
            setStartDate(undefined)
            setEndDate(new Date());
        }
    }

    async function paginationOfContractsScheduleForDeletion() {
        const paginationEditable = await ContractsService.paginationOfEditableContractsScheduledForDeletion(DAYS_TO_BE_DELETED, PAGINATION_LIMIT);

        const paginationIssued = await ContractsService.paginationOfIssuedContractsScheduledForDeletion(DAYS_TO_BE_DELETED, PAGINATION_LIMIT);

        setPaginationEditableScheduleForDeletion(paginationEditable);
        setPaginationIssuedScheduleForDeletion(paginationIssued);

        let editables: EditableDigitalContract[] = [];
        for (let currentPage = 1; currentPage <= paginationEditable.totalPages; currentPage++) {
            ContractsService.fetchEditableContractsScheduledForDeletionWithPagination(DAYS_TO_BE_DELETED, currentPage, PAGINATION_LIMIT)
                .then(response => {
                    editables = [...editables, ...response];
                    setEditablesToBeDeleted(editables);
                })
                .catch(e => {
                    const err = new ErrorWrapper(e);
                    notification(show({ type: "error", message: err.message }));
                })
        }

        let issueds: IssuedDigitalContract[] = [];
        for (let currentPage = 1; currentPage <= paginationIssued.totalPages; currentPage++) {
            ContractsService.fetchIssuedContractsScheduledForDeletionWithPagination(DAYS_TO_BE_DELETED, currentPage, PAGINATION_LIMIT)
                .then(response => {
                    issueds = [...issueds, ...response];
                    setIssuedsToBeDeleted(issueds);
                })
                .catch(e => {
                    const err = new ErrorWrapper(e);
                    notification(show({ type: "error", message: err.message }));
                })
        }
    }

    function fetchContractsScheduleForDeletion() {


    }

    const onlySearchByExpirationTheme: SxProps<Theme> = {
        textDecoration: "line-through"
    }

    const handleTagSelected = (tags: TagsModel[]) => {
        //setSelectedTag(tag);
        setSelectedTags(tags);
    };

    const handleSelectTeam = (selectedTeamGuid: string) => {
        const team = teamsUserIsSupervisor.find(t => t.guid === selectedTeamGuid)
        if (!team) {
            setSelectedTeam(null);
            return;
        }

        setSelectedTeam(team);
    }

    return (
        <Box sx={{ position: "relative" }}>
            {
                !loading

                    ?

                    <>
                        <Box
                            sx={{
                                position: "fixed",
                                border: "1px solid",
                                borderBottom: "0",
                                borderColor: (theme) => theme.palette.primary.main,
                                py: 1,
                                px: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                bottom: 0,
                                left: "50%",
                                transform: "translateX(-50%)",
                                borderRadius: "5px 5px 0 0",
                                backgroundColor: "#FFF",
                                zIndex: 500,
                                transition: 'opacity 1s',
                                opacity: viewModeVisible ? 1 : 0,
                            }}

                        >
                            <ViewComfy style={{ fontSize: "30px" }} color="primary" />
                            <Typography sx={{ color: (theme) => theme.palette.primary.main, mx: 2 }} >Modo de visualização: <b>{selectedTeam ? `Time - ${selectedTeam.name}` : "Comum"}</b></Typography>
                            <Tooltip title={selectedTeam ? "Você poderá filtrar o time e caso seja o supervisor ver os contratos de todos os membros do time" : "Na visualização comum o usuário herda as configurações de visualização da organização."}>
                                <Help sx={{ fontSize: "18px" }} color="secondary" />
                            </Tooltip>
                        </Box>
                        {/** Render user filters */}
                        <Box sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            pl: "16px",
                            pr: "16px"
                        }}
                        >
                            {
                                (paginationEditableScheduleForDeletion.totalRecords > 0 || paginationIssuedScheduleForDeletion.totalRecords > 0) && openDeletionAlert
                                    ?
                                    <Alert severity="warning" sx={{ display: "flex", alignItems: "center", mt: 2 }} onClose={() => setOpenDeletionAlert(false)} variant="outlined">
                                        <Typography variant="subtitle1">
                                            Você possui {paginationEditableScheduleForDeletion.totalRecords + paginationIssuedScheduleForDeletion.totalRecords} contrato(s) que serão excluídos nos próximos 30 dias por falta de assinatura das partes.
                                            <Button variant="text" onClick={() => { setOpenDeletionDialog(true); fetchContractsScheduleForDeletion(); }} >Clique aqui e veja quais são</Button>
                                        </Typography>
                                        <Typography variant="caption">
                                            Somente contratos que não foram assinados no prazo de 90 dias desde sua criação serão excluidos
                                        </Typography>
                                    </Alert>
                                    :
                                    <></>
                            }
                            <TabContext value={selectedView}>
                                <TabList
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    onChange={(e, selectedTab) => setSelectedView(selectedTab)}
                                    sx={{ mt: 2, ml: 2 }}
                                >
                                    <Tab icon={ContractViewTypeIcon("kanban")} label="Quadro" value="kanban" />
                                    <Tab icon={ContractViewTypeIcon("list")} label="Lista" value="list" />
                                    <Tab icon={ContractViewTypeIcon("dashboard")} label="Faturamento" value="dashboard" />
                                    <Tab icon={ContractViewTypeIcon("graphic")} label="Volume" value="graphic" />
                                </TabList>

                                {/** Render user filters */}
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    pl: "16px",
                                    pr: "16px"
                                }}
                                >
                                    {/** Render radio buttons */}
                                    <Box sx={{ margin: 4, display: "flex", marginLeft: "auto", justifyContent: "right", alignItems: "flex-end" }}>
                                        <Box>
                                            <Button
                                                variant="outlined"
                                                startIcon={<AccessTime />}
                                                onClick={e => setDateIntervalMenuAnchor(e.currentTarget)}>
                                                <Box sx={{ textAlign: "start" }}>
                                                    {
                                                        !onlySearchByExpiration
                                                            ?
                                                            <Typography sx={{ color: (theme) => theme.palette.primary.main }} >
                                                                Filtro por tempo   <b style={{ marginLeft: 4, marginRight: 4 }}>{selectedDateInterval.label} </b> <small> ({startDate?.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).split(',')[0]} - {endDate?.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).split(',')[0]})</small>
                                                            </Typography>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        (searchByExpiration && daysUntilExpiration !== undefined && selectedExpirationDate)
                                                            ?
                                                            <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
                                                                Filtro por expiração
                                                                <b style={{ marginLeft: 4, marginRight: 4 }}>
                                                                    {selectedExpirationDate.label}
                                                                </b>
                                                                até
                                                                <small> ({moment().startOf('day').add(daysUntilExpiration, "days").format("DD/MM/YY")}) </small>
                                                            </Typography>
                                                            :
                                                            <></>
                                                    }
                                                </Box>
                                            </Button>
                                            <Box>
                                                <FormGroup sx={{ display: "flex" }}>
                                                    <FormControlLabel
                                                        control={<Switch size="small" defaultChecked onChange={() => setShowContractTags(!showContractTags)} />}
                                                        label={<Typography sx={{ fontSize: "14px" }} >Mostrar tags</Typography>} />

                                                </FormGroup>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={dateIntervalMenuAnchor}
                                        open={Boolean(dateIntervalMenuAnchor)}
                                        onClose={() => setDateIntervalMenuAnchor(null)}
                                    >
                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                            <FormControl
                                                sx={onlySearchByExpiration ? { ...onlySearchByExpirationTheme, p: 2 } : { p: 2 }}
                                                disabled={onlySearchByExpiration}
                                            >
                                                <FormLabel id="intervals-radio-button-group">Intervalo de busca de contratos</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="intervals-radio-button-group"
                                                    name="row-intervals-radio-button-group"
                                                    onChange={handleDateIntervalsRadioGroupOnChange}
                                                    value={selectedDateInterval.label}
                                                >
                                                    {
                                                        DateIntervalSelections.map(d => (
                                                            <FormControlLabel value={d.label} control={<Radio />} label={d.label} />
                                                        ))
                                                    }

                                                </RadioGroup>
                                            </FormControl>
                                            <Divider sx={{ mb: 2 }} />
                                            <FormControl sx={{ p: 2 }}>
                                                <FormLabel>Ordenar por</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="intervals-radio-button-group"
                                                    name="row-intervals-radio-button-group"
                                                    onChange={handleDateOrdenationRadioGroupOnChange}
                                                    value={selectedDateOrdenation.label}
                                                >
                                                    {
                                                        DateOrdenationSelections.map(d => (
                                                            <FormControlLabel value={d.label} control={<Radio />} label={d.label} />
                                                        ))
                                                    }
                                                </RadioGroup>
                                            </FormControl>
                                            <Divider sx={{ mt: 2 }} />
                                            {
                                                // Render date pickers only if the date interval selection does not have an defined interval
                                                (!selectedDateInterval.interval || selectedDateInterval.label === "Personalizado")
                                                    ?
                                                    <Box sx={{ p: 2 }}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                                label="Selecione uma data inicial"
                                                                inputFormat="DD/MM/YYYY"
                                                                value={startDate}
                                                                onChange={(value: Moment | null) => { if (value) setStartDate(value.toDate()) }}
                                                                renderInput={(params) => <TextField {...params} size='small' sx={{ mb: 2 }} />}
                                                                maxDate={moment(endDate)}
                                                            />
                                                            <DatePicker
                                                                label="Selecione uma data final"
                                                                inputFormat="DD/MM/YYYY"
                                                                value={endDate}
                                                                onChange={(value: Moment | null) => { if (value) setEndDate(value.toDate()) }}
                                                                renderInput={(params) => <TextField {...params} size='small' sx={{ ml: 2 }} />}
                                                                minDate={moment(startDate)}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                    :
                                                    <></>
                                            }
                                            <FormControl sx={{ p: 2 }}>
                                                <FormControlLabel
                                                    id="intervals-radio-button-group"
                                                    control={
                                                        <Checkbox
                                                            checked={searchByExpiration}
                                                            onChange={handleChangeSearchByExpiration}
                                                        />
                                                    }
                                                    label={
                                                        !searchByExpiration
                                                            ?
                                                            "Buscar contratos para expirar"
                                                            :
                                                            (selectedExpirationDate && selectedExpirationDate.label === "Personalizado")
                                                                ?
                                                                "Buscar contratos que expirão até dia"
                                                                :
                                                                "Buscar contratos que expirão em"
                                                    }
                                                />

                                                {
                                                    searchByExpiration
                                                        ?
                                                        <Box>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="intervals-radio-button-group"
                                                                name="row-intervals-radio-button-group"
                                                                onChange={handleExpirationDateRadioGroupOnChange}
                                                                value={selectedExpirationDate?.label || ""}
                                                            >
                                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                                                    {
                                                                        ExpirationDateSelections.map(d => (
                                                                            <FormControlLabel value={d.label} control={<Radio />} label={d.label} />
                                                                        ))
                                                                    }
                                                                </Box>

                                                            </RadioGroup>

                                                        </Box>
                                                        :
                                                        <></>
                                                }
                                            </FormControl>
                                            {
                                                // Render date pickers only if the date interval selection does not have an defined interval
                                                (selectedExpirationDate && !selectedExpirationDate.daysUntilExpiration)
                                                    ?
                                                    <Box sx={{ p: 2 }}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                                label="Selecione uma data"
                                                                inputFormat="DD/MM/YYYY"
                                                                value={expirationDate}
                                                                onChange={(value: Moment | null) => {
                                                                    if (value) {
                                                                        const today = moment().startOf('day');
                                                                        const expiration = moment(value).startOf('day');
                                                                        const days = expiration.diff(today, 'days');
                                                                        setDaysUntilExpiration(days);
                                                                        setExpirationDate(value.toDate());
                                                                    }
                                                                }}
                                                                renderInput={(params) => <TextField {...params} size='small' />}
                                                                minDate={moment(new Date())}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                    :
                                                    <></>
                                            }
                                            {
                                                searchByExpiration
                                                    ?
                                                    <FormControlLabel
                                                        sx={{ p: 2 }}
                                                        id="intervals-radio-button-group"
                                                        control={
                                                            <Checkbox
                                                                checked={onlySearchByExpiration}
                                                                onChange={handleChangeOnlySearchByExpiration}
                                                                disabled={!daysUntilExpiration}
                                                            />
                                                        }
                                                        label="Ignorar intervalo e buscar somente pela expiração"
                                                    />
                                                    :
                                                    <></>
                                            }
                                        </Box>
                                    </Menu>
                                </Box >
                                <Grid container columns={{ xs: 12, md: 12 }} sx={{ border: "1px solid rgb(230, 230, 230)", alignItems: "center", minHeight: "48px", mb: 2, p: "0 16px" }}>
                                    <Grid item md={2}><Box sx={{ display: (isSmallScreen ? "none" : "flex"), alignItems: "center" }}><FilterList sx={{ margin: "0px 12px" }} />Filtrar</Box></Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container columns={{ xs: 12 }} sx={{ alignItems: "center", justifyContent: "flex-end" }}>
                                            {
                                                teamsUserIsSupervisor.length > 0
                                                    ?
                                                    <Grid item xs="auto" md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                        {/**Emissor*/}
                                                        <Button
                                                            endIcon={<ArrowDropDown />}
                                                            onClick={e => setTeamMenuAnchor(e.currentTarget)}>
                                                            <b>Time</b>{selectedTeam ? `: ${selectedTeam.name}` : ""}
                                                        </Button>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={teamMenuAnchor}
                                                            open={Boolean(teamMenuAnchor)}
                                                            onClose={() => setTeamMenuAnchor(null)}
                                                        >
                                                            <Box sx={{ p: 2 }}>
                                                                <Typography sx={{ fontWeight: 600, mb: 2 }}>Buscar contratos emitidos pelo time:</Typography>
                                                                <Autocomplete
                                                                    options={teamsAutocompleOptions}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                    onChange={(e, selectedTeamObj) => handleSelectTeam(selectedTeamObj ? selectedTeamObj.id : "")}
                                                                    renderOption={(props, option) => {
                                                                        return (
                                                                            <Box sx={{ '&:hover': { bgcolor: '#E7F3FF' } }} key={option.id}>
                                                                                <li {...props} style={{ borderBottom: '1px solid #E9E9E9' }}>
                                                                                    <Box display='flex' flexDirection='row' alignItems='center' >
                                                                                        <Box display='flex' flexDirection='column' ml={3}>
                                                                                            <Typography color='text.primary' >
                                                                                                {option.label}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </li>
                                                                            </Box>
                                                                        );
                                                                    }
                                                                    }
                                                                />
                                                            </Box>
                                                        </Menu>
                                                    </Grid>
                                                    :
                                                    <></>
                                            }
                                            <Grid item xs="auto" md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                {/**Emissor*/}
                                                <Button
                                                    endIcon={<ArrowDropDown />}
                                                    onClick={e => setContractIssuerFilterMenuAnchor(e.currentTarget)}>
                                                    <b>Emissor</b>{selectedIssuerFilter ? `: ${selectedIssuerFilter}` : ""}
                                                </Button>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={contractIssuerFilterMenuAnchor}
                                                    open={Boolean(contractIssuerFilterMenuAnchor)}
                                                    onClose={() => setContractIssuerFilterMenuAnchor(null)}
                                                >
                                                    <Box sx={{ p: 2 }}>
                                                        <Typography sx={{ fontWeight: 600, mb: 2 }}>Filtrar por emissor do contrato</Typography>
                                                        <StyledSearchBar
                                                            sx={{ width: "600px", border: "1px solid lightgray" }}
                                                            searchMode="contracts-users"
                                                            onChange={(_: any, searchResult: SearchResult | null) => {
                                                                setSelectedIssuerFilter((searchResult) ? searchResult.description : '')
                                                                setSelectedContractIssuerFilter((searchResult) ? searchResult.id : undefined)
                                                            }}
                                                        />
                                                    </Box>
                                                </Menu>
                                            </Grid>
                                            <Grid item xs="auto" md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                {/**Modelo do contrato*/}
                                                <Button
                                                    endIcon={<ArrowDropDown />}
                                                    onClick={e => setTemplateFilterMenuAnchor(e.currentTarget)}>
                                                    <b>Modelo</b>{selectedTemplateFilter ? `: ${selectedTemplateFilter}` : ""}
                                                </Button>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={templateFilterMenuAnchor}
                                                    open={Boolean(templateFilterMenuAnchor)}
                                                    onClose={() => setTemplateFilterMenuAnchor(null)}
                                                >
                                                    <Box sx={{ p: 2 }}>
                                                        <Typography sx={{ fontWeight: 600, mb: 2 }}>Filtrar por modelo de contrato</Typography>
                                                        <StyledAutocomplete
                                                            options={digitalContractTemplates.map(d => ({
                                                                label: d.name,
                                                                source: d
                                                            }))}
                                                            noOptionsText="Sem resultados"
                                                            sx={{ minWidth: "300px" }}
                                                            onChange={(e, v) => handleDigitalContractTemplateAutocompleteContractFilter(e, (v as DigitalContractTemplateAutocomplete | null))}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Filtre por modelo"
                                                                    variant="standard"
                                                                />
                                                            )} />
                                                    </Box>
                                                </Menu>
                                            </Grid>
                                            <Grid item xs="auto" md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                {/**Filtros por estado */}
                                                <FormGroup>
                                                    <Button
                                                        endIcon={<ArrowDropDown />}
                                                        onClick={e => setStateFilterMenuAnchor(e.currentTarget)}>
                                                        <b>Estado</b>
                                                    </Button>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={stateFilterMenuAnchor}
                                                        open={Boolean(stateFilterMenuAnchor)}
                                                        onClose={() => setStateFilterMenuAnchor(null)}
                                                    >
                                                        <Box sx={{ p: 2 }}>
                                                            <Typography sx={{ fontWeight: 600, mb: 2 }}>Filtrar por estado do contrato</Typography>
                                                            {
                                                                ContractStatusFilterOptions.map(c => (
                                                                    <Box sx={{ display: "block" }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={selectedContractStatesFilters.indexOf(c.state) >= 0}
                                                                                    onChange={() => toggleContractStateFilter(c.state)}
                                                                                    size="small"
                                                                                />
                                                                            }
                                                                            label={c.label}
                                                                            value={c.state}
                                                                        />
                                                                        <Typography
                                                                            variant="caption"
                                                                            sx={{ mr: 2, transform: "translateY(8px) translateX(-4px)", }}
                                                                            color={(normalizedContracts.filter(n => n.normalized.state === c.state).length > 0) ? "primary" : "default"}
                                                                        >
                                                                            ({normalizedContracts.filter(n => n.normalized.state === c.state).length})
                                                                        </Typography>
                                                                    </Box>
                                                                ))
                                                            }
                                                        </Box>
                                                    </Menu>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs="auto" md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                {/**Tags */}
                                                <Button
                                                    endIcon={<ArrowDropDown />}
                                                    onClick={e => setTagFilterMenuAnchor(e.currentTarget)}>
                                                    <b>Tag</b>{selectedTag ? `: ${selectedTag.tagName}` : ""}
                                                </Button>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={tagFilterMenuAnchor}
                                                    open={Boolean(tagFilterMenuAnchor)}
                                                    onClose={() => setTagFilterMenuAnchor(null)}
                                                >
                                                    <TagsSearch onTagSelected={handleTagSelected} selectedTags={selectedTags} onAndAndOrChange={handleFilterAndAndOr} andAndOr={filterAndAndOr} />
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    // Render linear progress
                                    (totalPages && currentPage < totalPages)
                                        ?
                                        <Box>
                                            Buscando contratos {currentPage} / {totalPages}
                                            <LinearProgress value={currentPage / totalPages * 100} variant="determinate" sx={{ height: 16 }} />
                                        </Box>
                                        :
                                        <></>
                                }

                                {
                                    (totalPages && (totalPages > 0))

                                        ?
                                        // render specific view depending on the selected view by the client
                                        (selectedView === "kanban") ? <KanbanView /> :
                                            (selectedView === "list") ? <ListView /> :
                                                (selectedView === "dashboard") ? <DashboardView /> :
                                                    (selectedView === "graphic") ? <GraphicView /> :
                                                        <></>

                                        :
                                        <Box sx={{ display: "flex", width: "100%", height: "50%", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                            <Box sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: 150,
                                                height: 150,
                                                borderRadius: 5,
                                                backgroundColor: "primary.main",
                                                marginBottom: 5
                                            }}>
                                                <FolderOff color="disabled" sx={{ fontSize: 100 }} />
                                            </Box>
                                            <Typography variant="h4" sx={{ marginBottom: 3 }}>Você não possui contratos emitidos</Typography>
                                            <Button variant="contained" onClick={() => navigate("/contracts/create")} >Emitir novo contrato</Button>
                                        </Box>
                                }
                            </TabContext>
                        </Box>
                    </>

                    :
                    <Box sx={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress color="primary" size={100} />
                    </Box>
            }
            <ContractsScheduleForDeletionDialog open={openDeletionDialog} contracts={normalizedContractsToBeDeleted} loading={false} onClose={() => { setOpenDeletionDialog(false) }} />
        </Box>

    )
}

export default Contracts;