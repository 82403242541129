import { Event, Today } from "@mui/icons-material";
import { Box, FormControl, FormControlLabel, ListItemIcon, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { EndDatePolicyType, FileIssueValidityPeriod } from "../../../../../../models/contracts";
import { useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

// Interface and Types
interface ExternalValidityProps extends React.ComponentProps<any> {
    validityPeriod: FileIssueValidityPeriod
    setValidityPeriod: (value: FileIssueValidityPeriod) => void
}

const ExternalValidity = (props: ExternalValidityProps): JSX.Element => {

    // Destructure props object
    const { setValidityPeriod, validityPeriod } = props;

    // Data states
    const [endDatePolicyType, setEndDatePolicyType] = useState<EndDatePolicyType>(validityPeriod.endDatePolicyType as EndDatePolicyType);
    const [startDatePolicy, setStartDatePolicy] = useState<Date | null>(validityPeriod.startDate ? validityPeriod.startDate : null);
    const [endDatePolicy, setEndDatePolicy] = useState<Date | null>(validityPeriod.endDate ? validityPeriod.endDate : null);

    // useEffects
    useEffect(() => {
        updateValidity();
    }, [endDatePolicy, endDatePolicyType, startDatePolicy])

    // Functions
    function updateValidity() {

        if (!startDatePolicy) return;

        const vp: FileIssueValidityPeriod = {
            startDatePolicyType: validityPeriod.startDatePolicyType,
            endDatePolicyType,
            endDate: endDatePolicy ? endDatePolicy : undefined,
            startDate: startDatePolicy
        }

        setValidityPeriod(vp);
    }

    return (
        <Box sx={{ maxHeight: "550px", overflow: "auto" }}>
            <Box sx={{ mb: 6 }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <ListItemIcon>
                        <Today sx={{ fontSize: "32px" }} />
                    </ListItemIcon>
                    <Typography variant="h6">Data de Assinatura</Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                        <DatePicker
                            label="Selecione a data de assinatura"
                            inputFormat="DD/MM/YYYY"
                            value={startDatePolicy}
                            onChange={(value: Moment | null) => { if (value) setStartDatePolicy(value.toDate()) }}
                            renderInput={(params) => <TextField {...params} fullWidth error={!startDatePolicy} helperText={!startDatePolicy ? "Campo obrigatório" : ""} />}
                            maxDate={endDatePolicy ? moment(endDatePolicy) : undefined}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            <Box>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <ListItemIcon>
                        <Event sx={{ fontSize: "32px" }} />
                    </ListItemIcon>
                    <Typography variant="h6">Data Final</Typography>
                </Box>
                <FormControl sx={{ ml: 1, mb: 3 }}>
                    <RadioGroup
                        value={endDatePolicyType}
                        onChange={(e) => setEndDatePolicyType(e.target.value as EndDatePolicyType)}
                    >
                        <FormControlLabel value={EndDatePolicyType.UNDETERMINED} control={<Radio />} label="Indetermindado" />
                        <FormControlLabel value={EndDatePolicyType.SET_BY_ISSUER} control={<Radio />} label="Definir durante a emissão do contrato" />
                    </RadioGroup>
                </FormControl>
                {
                    endDatePolicyType === EndDatePolicyType.SET_BY_ISSUER
                        ?
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                            <DatePicker
                                label="Selecione a data final"
                                inputFormat="DD/MM/YYYY"
                                value={endDatePolicy}
                                onChange={(value: Moment | null) => { if (value) setEndDatePolicy(value.toDate()) }}
                                renderInput={(params) => <TextField {...params} fullWidth error={!endDatePolicy} helperText={!endDatePolicy ? "Campo obrigatório" : ""} />}
                                minDate={startDatePolicy ? moment(startDatePolicy) : undefined}
                            />
                        </LocalizationProvider>
                        :
                        <></>
                }
            </Box>
        </Box>
    )
}

export default ExternalValidity;