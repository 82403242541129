import React, { cloneElement } from "react";

export interface SivedicoRoute {
    route: string,
    hasParam: boolean,
    defaultComponent: JSX.Element,
    newComponent: JSX.Element
}

export interface SivedicoRouterProps {
    routes: SivedicoRoute[],
    userSelectedVersions: string[]
}

export const SivedicoRouter = ({ routes, userSelectedVersions }: SivedicoRouterProps): JSX.Element => {
    if (!routes || routes.length === 0) return <></>;

    const currentUrlPath = window.location.pathname;
    const componentSelectedInPath = routes.find((r) => matchRoute(r.route, currentUrlPath));

    if (componentSelectedInPath) {
        const onUserSelectedVersionedRoute = userSelectedVersions.find((sv) => sv === componentSelectedInPath.route);

        if (onUserSelectedVersionedRoute) {
            return componentSelectedInPath.hasParam 
            ? 
            (cloneElement(componentSelectedInPath.newComponent, { param: extractParamFromRoute(currentUrlPath, componentSelectedInPath.route) })) 
            : (componentSelectedInPath.newComponent);
        } else {
            return componentSelectedInPath.defaultComponent;
        }
    } else {
        return <></>;
    }
};

function matchRoute(route: string, currentUrlPath: string): boolean {
    const routeSegments = route.split('/');
    const pathSegments = currentUrlPath.split('/');

    if (routeSegments.length !== pathSegments.length) {
        return false;
    }

    for (let i = 0; i < routeSegments.length; i++) {
        if (routeSegments[i] !== pathSegments[i] && !routeSegments[i].startsWith(':')) {
            return false;
        }
    }

    return true;
}

function extractParamFromRoute(currentUrlPath: string, route: string): string | undefined {
    const routeSegments = route.split('/');
    const pathSegments = currentUrlPath.split('/');

    for (let i = 0; i < routeSegments.length; i++) {
        if (routeSegments[i].startsWith(':')) {
            return pathSegments[i];
        }
    }

    return undefined;
}