import { createContext } from "react";
import { AuthenticationContextData } from "../models/authentication";
import { AccountRoles, AccountTypes } from "../models/user";

//The context interface
export interface RolesContextInterface {
    hasRole(authContext: AuthenticationContextData, role: AccountRoles): boolean
    assertIsOrganizationOrAdministratorOr(auth: AuthenticationContextData, role: AccountRoles): boolean
    assertIsOrganizationOrAdministrator(auth: AuthenticationContextData): boolean
}

//The react context
export const RolesContext = createContext<RolesContextInterface>({
    hasRole: (auth: AuthenticationContextData, role: AccountRoles): boolean => {

        //Check if user is authenticated
        if (!auth.isAuthenticated()) return false;

        //Check if user has the role (if index value > 0)
        if (auth.user().account.accountRoles.indexOf(AccountRoles[role]) >= 0) return true;

        //Return false if it doesnt have the role
        return false;
    },

    assertIsOrganizationOrAdministratorOr: (auth: AuthenticationContextData, role: AccountRoles): boolean => {

        //Check if user is authenticated
        if (!auth.isAuthenticated()) return false;

        //Return true is account is from a customer
        if (auth.user().session.accountType === AccountTypes.Customer) return true;

        //Check if user has the role Administrator (if index value > 0)
        if (auth.user().account.accountRoles.indexOf(AccountRoles.ADMINISTRATOR) >= 0) return true;

        //Check if user has the required role (if index value > 0)
        if (auth.user().account.accountRoles.indexOf(AccountRoles[role]) >= 0) return true;

        return false;
    },

    assertIsOrganizationOrAdministrator: (auth: AuthenticationContextData): boolean => {
        //Check if user is authenticated
        if (!auth.isAuthenticated()) return false;

        //Return true is account is from a customer
        if (auth.user().session.accountType === AccountTypes.Customer) return true;

        //Check if user has the role Administrator (if index value > 0)
        if (auth.user().account.accountRoles.indexOf(AccountRoles.ADMINISTRATOR) >= 0) return true;

        return false;
    }
});