import { AttachMoney, CalendarMonth, PeopleAlt, Send, WarningAmberRounded } from "@mui/icons-material";
import { useMediaQuery, Box, Alert, AlertTitle, Collapse, Grid, Tab, Container, Typography, CircularProgress, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../redux/hooks";
import { ActionButton, Perspective } from "../../../../../utils/editorUtils";
import EditorNavbar from "../../components/EditorNavbar";
import OrganizationContractsQuotaService from "../../../../../services/organization-quota";
import { ContractsQuota } from "../../../../../models/organizations";
import { LoadingState } from "../../../../../utils/types";
import { BillingSpecification, ContractPartie, DigitalContractTemplate, EmailTypes, EndDatePolicyType, IdentificationType, IdentifiedByEmailContractParty, IssueBilling, IssueContractPartie, IssueTemplateDTO, IssueValidityPeriod, MailTemplate, PlatformUserContractParty, StartDatePolicyType, ValidityPeriodSpecification } from "../../../../../models/contracts";
import ContractsService from "../../../../../services/contracts";
import ErrorWrapper from "../../../../../utils/ErrorWrapper";
import { show } from "../../../../../redux/features/app-global-notification/app-global-notification-slice";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import IssueFinanceForm from "./components/forms/IssueFinanceForm";
import IssuePartieForm from "./components/forms/IssuePartieForm";
import IssueValidityForm from "./components/forms/IssueValidityForm";
import ChangePerspectiveButtons from "../../components/ChangePerspectiveButtons";
import IssueDataReview from "./components/IssueDataReview";
import OrganizationService from "../../../../../services/organization";

// Enums
enum Tabs {
    PARTIES = "PARTIES",
    FINANCE = "FINANCE",
    VALIDITY = "VALIDITY"
}

// Default values
const defaultIssueValidity: IssueValidityPeriod = {
    endDate: null,
    startDate: null
}

const defaultValidityPeriod: ValidityPeriodSpecification = {
    endDatePolicyType: EndDatePolicyType.UNDETERMINED,
    startDatePolicyType: StartDatePolicyType.STARTS_WHEN_SIGNED_BY_ALL_PARTIES
}

const RenewContract = (): JSX.Element => {

    // Params
    const { contractId } = useParams();

    // Contexts
    const notification = useAppDispatch();
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:800px)");

    // Default variables
    const defaultActionButtons: ActionButton[] = [
        {
            label: "Emitir contrato",
            icon: <Send />,
            onClick: handleRenewContract,
            disabled: true,
            value: "issue"
        }
    ]

    // Loading states
    const [fetchContractLoading, setFetchContractLoading] = useState<LoadingState>("loading");

    // Boolean states
    const [canIssueContract, setCanIssueContract] = useState(false);
    const [openDataReviewDialog, setOpenDataReviewDialog] = useState(false);
    const [openIssuedContractAlert, setOpenIssuedContractAlert] = useState(true);

    // Data states
    const [quota, setQuota] = useState<ContractsQuota | null>(null);
    const [template, setTemplate] = useState<DigitalContractTemplate | null>(null);
    const [templateFileURI, setTemplateFileURI] = useState<string | null>(null);
    const [issueTemplateDTO, setIssueTemplateDTO] = useState<IssueTemplateDTO | null>(null);
    const [parties, setParties] = useState<ContractPartie[]>([]);
    const [parsedParties, setParsedParties] = useState<IssueContractPartie[]>([]);
    const [billings, setBillings] = useState<BillingSpecification[]>([]);
    const [parsedBillings, setParsedBillings] = useState<IssueBilling[]>([]);
    const [validityPeriod, setValidityPeriod] = useState<ValidityPeriodSpecification>(defaultValidityPeriod);
    const [parsedValidityPeriod, setParsedValidityPeriod] = useState<IssueValidityPeriod>(defaultIssueValidity);
    const [actionButtons, setActionButtons] = useState<ActionButton[]>(defaultActionButtons);
    const [mailTemplates, setMailTemplates] = useState<MailTemplate[]>([]);

    // Tab states
    const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.PARTIES);
    const [perspective, setPerspective] = useState<Perspective>("document");

    // useEffects
    useEffect(() => {
        fetchQuota();
        fetchTemplate();
    }, [])

    useEffect(() => {
        fetchMailTemplates();
    }, [template])

    useEffect(() => {
        produceDTO();
        enableButtons();
    }, [parsedParties, parsedBillings, parsedValidityPeriod])

    /**
     * useEffect for calling the review dialog
     */
    useEffect(() => {
        if (!canIssueContract) return;
        setOpenDataReviewDialog(true);
        setCanIssueContract(false);
    }, [canIssueContract])

    // Functions
    /**
     * Fetches the quota of the organization to display the contractsIssuedPerMonth quota
     */
    function fetchQuota() {
        OrganizationContractsQuotaService.fetchContractsQuota()
            .then(response => {
                setQuota(response);
            })
            .catch(e => {
                setOpenIssuedContractAlert(false);
            })
    }

    /**
     * Fetches the template to populate the data
     */
    async function fetchTemplate() {
        if (!contractId) {
            setFetchContractLoading("not-found");
            return;
        }

        try {
            const oldContract = await ContractsService.generateEditableDigitalContractFromIssuedContractBeingRenewed(contractId);

            const templateData = oldContract.template;

            const fileURI = await ContractsService.createDigitalContractTemplateDownloadTemplateFileDownloadUri(templateData.id);

            const identifiedParties: ContractPartie[] = oldContract.contractParties.map(party => {
                if ((party as PlatformUserContractParty).uuid) {
                    const cp = party as PlatformUserContractParty;
                    const parsedParty: ContractPartie = {
                        identificationType: cp.contractPartyIdentificationType as IdentificationType,
                        identification: {
                            uuid: cp.uuid
                        },
                        role: cp.role,
                        comment: cp.comment
                    }
                    return parsedParty;
                } else {
                    const cp = party as IdentifiedByEmailContractParty
                    const parsedParty: ContractPartie = {
                        identificationType: cp.contractPartyIdentificationType as IdentificationType,
                        identification: {
                            email: cp.email
                        },
                        role: cp.role,
                        comment: cp.comment
                    }
                    return parsedParty;
                }
            })

            setTemplate(templateData);
            setTemplateFileURI(fileURI.uri);
            setParties(identifiedParties);
            setBillings(templateData.billingSpecifications);
            setValidityPeriod(templateData.validityPeriodSpecification);

            setFetchContractLoading("loaded");

        } catch (e) {
            const err = new ErrorWrapper(e);

            switch (err.httpStatus) {
                case 404:
                    setFetchContractLoading("not-found");
                    break;
                default:
                    setFetchContractLoading("error");
                    notification(show({ type: "error", message: err.message }));
                    break;
            }
        }
    }

    function fetchMailTemplates() {
        if (!template) return;
        setFetchContractLoading("loading");


        if (template.mailTemplates) {
            const mTemplates: MailTemplate[] = []

            // Create a map to keep track of EmailTypes from the template
            const emailTypeMap: Record<EmailTypes, boolean> = {} as Record<EmailTypes, boolean>;

            template.mailTemplates.forEach(mt => {
                mTemplates.push(mt);
                emailTypeMap[mt.mailType] = true;
            })

            OrganizationService.fetchOrganizationEmail()
                .then(response => {
                    if (!response || response.length <= 0) return;
                    response.forEach(mt => {
                        if (!emailTypeMap[mt.mailType]) {
                            mTemplates.push(mt);
                        }
                    })
                    setMailTemplates(mTemplates);
                })
                .finally(() => setFetchContractLoading("loaded"));
        } else {
            OrganizationService.fetchOrganizationEmail()
                .then(response => {
                    if (!response || response.length <= 0) return;
                    setMailTemplates(response);
                })
                .finally(() => setFetchContractLoading("loaded"));
        }
    }

    /**
     * Update the parties of the contract while parsing them to send the request
     * @param contractParty 
     */
    function updateParties(contractParty: ContractPartie) {
        const updatedParties = parties.map(party => {
            if (party.role === contractParty.role) return contractParty;
            return party;
        });

        const updatedParsedParties: IssueContractPartie[] = updatedParties.map(party => {
            const parsedParty: IssueContractPartie = {
                duplicated: false,
                identification: party.identification,
                identificationType: party.identificationType,
                role: party.role,
                comment: party.comment
            }
            return parsedParty;
        })

        setParties(updatedParties);
        setParsedParties(updatedParsedParties);
    }

    /**
     * Update the billings of the contract while parsing them to send the request
     * @param billing 
     */
    function updateBillings(billing: IssueBilling) {
        let updatedBillings = [...parsedBillings];

        if (updatedBillings.length <= 0) {
            updatedBillings.push(billing);
        } else {
            const foundBilling = updatedBillings.find(b => b.name === billing.name);

            if (foundBilling) {
                updatedBillings = updatedBillings.map(b => {
                    if (b.name === billing.name) return billing;
                    return b;
                })
            } else {
                updatedBillings.push(billing);
            }
        }

        setParsedBillings(updatedBillings);
    }

    /**
     * Update the validity of the contract
     * @param validity 
     */
    function updateParsedValidity(validity: IssueValidityPeriod) {
        setParsedValidityPeriod(validity);
    }

    /**
     * Generate the dto to issue the contract
     */
    function produceDTO() {
        if (!template) return;

        const issueDTO: IssueTemplateDTO = {
            name: "",
            billingData: parsedBillings,
            contractParties: parsedParties,
            contractTags: {
                tags: []
            },
            id: template.id,
            mailTemplates: mailTemplates,
            validityPeriod: parsedValidityPeriod,
        };

        setIssueTemplateDTO(issueDTO);
    }

    /**
     * Update buttons to enable them
     */
    function enableButtons() {
        let canIssue = true;

        // check if the parsedBillings is the same length as the template billings
        if (billings.length !== parsedBillings.length) canIssue = false;

        // check if the parsedParties is the same length as the template parties
        if (parties.length !== parsedParties.length) canIssue = false;

        // check if each party has a identification and ensure no duplicates
        parsedParties.forEach(party => {
            if (Object.keys(party.identification).length <= 0) canIssue = false;
            if (
                party.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL &&
                (!party.identification.email || !/^[^\s@]+@[^\s@,]+\.[^\s@,]+$/.test(party.identification.email))
            ) canIssue = false;
        });

        // validate the validityDate policy
        if (validityPeriod.endDatePolicyType === EndDatePolicyType.SET_BY_ISSUER && !parsedValidityPeriod.endDate) canIssue = false;
        if (validityPeriod.startDatePolicyType === StartDatePolicyType.SET_BY_ISSUER && !parsedValidityPeriod.startDate) canIssue = false;

        // ensure that the dto exists
        if (!issueTemplateDTO) canIssue = false;

        // enable the buttons
        const buttons = actionButtons.map(button => {
            return {
                ...button,
                disabled: !canIssue
            }
        })

        setActionButtons(buttons);
    }

    // Handler functions
    /**
     * Changes flag to call review dialog
     */
    function handleRenewContract() {
        setCanIssueContract(true);
    }

    /**
     * Change the current select tab
     * @param event 
     * @param newValue 
     */
    function handleChangeTabs(event: React.SyntheticEvent, newValue: Tabs) {
        setCurrentTab(newValue);
    }

    return (
        <Box
            sx={{
                maxWidth: "100vw",
                maxHeight: "100vh",
                overflow: "auto",
            }}
        >
            <EditorNavbar title={isMobile || !template ? "Emissão" : "Emissão do modelo: "} subtitle={isMobile || !template ? "" : template.name} actionButtons={actionButtons} />
            <Box sx={{ height: "88vh", width: "100vw", mt: "64px" }}>
                <Collapse in={openIssuedContractAlert && Boolean(quota)}>
                    {
                        quota
                            ?
                            <Alert severity={quota.contractsIssuedPerMonth >= quota.customerContractsQuota.contractsIssuedPerMonth ? "warning" : "info"} onClose={() => setOpenIssuedContractAlert(false)} >
                                {
                                    quota.contractsIssuedPerMonth >= quota.customerContractsQuota.contractsIssuedPerMonth
                                        ?
                                        <>
                                            <AlertTitle>
                                                Cota mensal de contratos emitidos foi atingida - Este contrato será cobrado como excedente
                                            </AlertTitle>
                                            Pacote de contratos mensal: {quota.contractsIssuedPerMonth} / {quota.customerContractsQuota.contractsIssuedPerMonth}
                                        </>
                                        :
                                        <>
                                            <AlertTitle>
                                                Pacote de contratos mensal: {quota.contractsIssuedPerMonth} / {quota.customerContractsQuota.contractsIssuedPerMonth}
                                            </AlertTitle>
                                        </>
                                }
                            </Alert>
                            :
                            <></>
                    }
                </Collapse>
                {
                    fetchContractLoading === "loaded"
                        ?
                        template
                            ?
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                }}
                            >
                                {
                                    !isMobile
                                        ?
                                        <Grid container>
                                            <Grid item xs={5} >
                                                <Box sx={{ bgcolor: 'background.paper', display: 'flex' }}>
                                                    <TabContext value={currentTab}>
                                                        <TabPanel value={Tabs.PARTIES} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                            <Container>
                                                                <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Participantes do Contrato</Typography>
                                                                <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                    Defina quais são os participantes do contrato
                                                                </Typography>
                                                                <Box sx={{ maxHeight: "600px", overflow: "auto", mb: 3 }}>
                                                                    {
                                                                        parties.map(party => (
                                                                            <IssuePartieForm party={party} parties={parties} updateParties={updateParties} key={party.role} />
                                                                        ))
                                                                    }
                                                                </Box>
                                                            </Container>
                                                        </TabPanel>
                                                        <TabPanel value={Tabs.FINANCE} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                            <Container>
                                                                <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >
                                                                    Cobranças financeiras do contrato
                                                                </Typography>
                                                                <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                    Defina os valores e datas iniciais de pagamento das cobranças
                                                                </Typography>
                                                                {
                                                                    billings.map(bill => (
                                                                        <IssueFinanceForm billing={bill} issueBilling={parsedBillings.find(b => b.name === bill.name)} updateBilling={updateBillings} />
                                                                    ))
                                                                }
                                                            </Container>
                                                        </TabPanel>
                                                        <TabPanel value={Tabs.VALIDITY} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                            <Container>
                                                                <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >
                                                                    Período de vigência do contrato
                                                                </Typography>
                                                                <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                    Defina o período em que o contrato estará em vigência
                                                                </Typography>
                                                                <IssueValidityForm issueValidity={parsedValidityPeriod} validityPeriod={validityPeriod} updateIssueValidity={updateParsedValidity} />
                                                            </Container>
                                                        </TabPanel>
                                                        <TabList
                                                            variant="scrollable"
                                                            orientation="vertical"
                                                            scrollButtons="auto"
                                                            allowScrollButtonsMobile
                                                            sx={{ borderLeft: 1, borderColor: 'divider' }}
                                                            TabIndicatorProps={{
                                                                sx: {
                                                                    left: 0
                                                                }
                                                            }}
                                                            onChange={(e, newTab) => handleChangeTabs(e, newTab)}
                                                        >
                                                            <Tab value={Tabs.PARTIES} label="Participantes" icon={<PeopleAlt />} />
                                                            {
                                                                billings.length > 0
                                                                    ?
                                                                    <Tab value={Tabs.FINANCE} label="Financeiro" icon={<AttachMoney />} />
                                                                    :
                                                                    <></>
                                                            }
                                                            <Tab value={Tabs.VALIDITY} label="Vigência" icon={<CalendarMonth />} />
                                                        </TabList>
                                                    </TabContext>
                                                </Box>
                                            </Grid>
                                            <Grid item
                                                xs={7}
                                                sx={{
                                                    width: "100%",
                                                    height: "100vh",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    overflow: "auto"
                                                }}
                                            >
                                                {
                                                    templateFileURI
                                                        ?
                                                        <iframe
                                                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(templateFileURI)}`}
                                                            frameBorder={0}
                                                            style={{ width: "100%", height: "100%" }}
                                                        />
                                                        :
                                                        <CircularProgress color="primary" sx={{ fontSize: "64px" }} />
                                                }
                                            </Grid>
                                        </Grid>
                                        :
                                        <Box>
                                            {
                                                perspective === "document"
                                                    ?
                                                    <Box
                                                        sx={{
                                                            width: "100%",
                                                            height: "100vh",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            overflow: "auto"
                                                        }}
                                                    >
                                                        {
                                                            templateFileURI
                                                                ?
                                                                <iframe
                                                                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(templateFileURI)}`}
                                                                    frameBorder={0}
                                                                    style={{ width: "100%", height: "100%" }}
                                                                />
                                                                :
                                                                <CircularProgress color="primary" sx={{ fontSize: "64px" }} />
                                                        }
                                                    </Box>
                                                    :
                                                    <Box>
                                                        <Box sx={{ bgcolor: 'background.paper' }}>
                                                            <TabContext value={currentTab}>
                                                                <TabList
                                                                    variant="scrollable"
                                                                    scrollButtons="auto"
                                                                    allowScrollButtonsMobile
                                                                    onChange={(e, newTab) => handleChangeTabs(e, newTab)}
                                                                >
                                                                    <Tab value={Tabs.PARTIES} label="Participantes" icon={<PeopleAlt />} />
                                                                    {
                                                                        billings.length > 0
                                                                            ?
                                                                            <Tab value={Tabs.FINANCE} label="Financeiro" icon={<AttachMoney />} />
                                                                            :
                                                                            <></>
                                                                    }
                                                                    <Tab value={Tabs.VALIDITY} label="Vigência" icon={<CalendarMonth />} />
                                                                </TabList>
                                                                <TabPanel value={Tabs.PARTIES} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                                    <Container>
                                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Participantes do Contrato</Typography>
                                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                            Defina quais são os participantes do contrato
                                                                        </Typography>
                                                                        <Box sx={{ maxHeight: "600px", overflow: "auto", mb: 3 }}>
                                                                            {
                                                                                parties.map(party => (
                                                                                    <IssuePartieForm party={party} parties={parties} updateParties={updateParties} key={party.role} />
                                                                                ))
                                                                            }
                                                                        </Box>
                                                                    </Container>
                                                                </TabPanel>
                                                                <TabPanel value={Tabs.FINANCE} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                                    <Container>
                                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >
                                                                            Cobranças financeiras do contrato
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                            Defina os valores e datas iniciais de pagamento das cobranças
                                                                        </Typography>
                                                                        {
                                                                            billings.map(bill => (
                                                                                <IssueFinanceForm billing={bill} issueBilling={parsedBillings.find(b => b.name === bill.name)} updateBilling={updateBillings} />
                                                                            ))
                                                                        }
                                                                    </Container>
                                                                </TabPanel>
                                                                <TabPanel value={Tabs.VALIDITY} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                                    <Container>
                                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >
                                                                            Período de vigência do contrato
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                            Defina o período em que o contrato estará em vigência
                                                                        </Typography>
                                                                        <IssueValidityForm issueValidity={parsedValidityPeriod} validityPeriod={validityPeriod} updateIssueValidity={updateParsedValidity} />
                                                                    </Container>
                                                                </TabPanel>

                                                            </TabContext>
                                                        </Box>
                                                    </Box>
                                            }
                                        </Box>
                                }
                            </Box>
                            :
                            <></>
                        :
                        fetchContractLoading === "loading"
                            ?
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "100%"
                                }}
                            >
                                <CircularProgress color="primary" sx={{ fontSize: "64px" }} />
                            </Box>
                            :
                            fetchContractLoading === "not-found"
                                ?
                                <Box sx={{ width: "100vw", height: "88vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Box>
                                        <Box
                                            sx={{
                                                p: 4,
                                                border: "1px solid",
                                                borderColor: (theme) => theme.palette.warning.dark,
                                                backgroundColor: "rgba(255, 152, 0, 0.25)",
                                                textAlign: "center",
                                                borderRadius: "5px"
                                            }}
                                        >
                                            <WarningAmberRounded color="warning" sx={{ fontSize: "64px" }} />
                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.warning.main }} >Ocorreu um erro</Typography>
                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.warning.light }} >Não foi identificado um contrato com o <b>ID</b> informado</Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 6 }}>
                                            <Button variant="contained" onClick={() => navigate("/contracts")} >Voltar para tela de contratos</Button>
                                        </Box>
                                    </Box>
                                </Box>
                                :
                                <Box sx={{ width: "100vw", height: "88vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Box>
                                        <Box
                                            sx={{
                                                p: 4,
                                                border: "1px solid",
                                                borderColor: (theme) => theme.palette.error.dark,
                                                backgroundColor: "rgba(232, 93, 93, 0.25)",
                                                textAlign: "center",
                                                borderRadius: "5px"
                                            }}
                                        >
                                            <WarningAmberRounded color="error" sx={{ fontSize: "64px" }} />
                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.error.main }} >Ocorreu um erro</Typography>
                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.error.light }} >Ocorreu algum erro ao se comunicar com o servidor do cartorizi</Typography>
                                            <Typography variant="caption" sx={{ color: (theme) => theme.palette.error.light, mt: 1 }} >Tente novamente, caso o erro persista por favor entre em contato com o <a href="#">nosso suporte</a></Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 6 }}>
                                            <Button variant="contained" onClick={() => navigate("/contracts")} >Voltar para tela de contratos</Button>
                                        </Box>
                                    </Box>
                                </Box>
                }
            </Box>
            {
                isMobile
                    ?
                    <ChangePerspectiveButtons
                        perspective={perspective}
                        setPerspective={setPerspective}
                    />
                    :
                    <></>
            }
            {
                issueTemplateDTO && template && contractId
                    ?
                    <IssueDataReview open={openDataReviewDialog} setOpen={setOpenDataReviewDialog} issueDTO={issueTemplateDTO} setIssueDTO={setIssueTemplateDTO} template={template} mode="renew" contractId={contractId} />
                    :
                    <></>
            }
        </Box>
    )
}

export default RenewContract;