import { createTheme } from '@mui/material/styles';

const Theme = createTheme({

  typography: {

    allVariants: {

      fontFamily: 'Noto Sans',

      color: '#212121'

    },

    caption: {

      color: '#8A8A8A'

    },

    button: {

      textTransform: 'none'

    }

  },

  palette: {

    action: {
      active: '#64067F', //dark purple
      disabledBackground: '#EFE2F0', //light purple
      disabled: '#FFF'

    },

    primary: {
      main: '#64067F', //dark orange
      light: '#EFE2F0', //light purple
      contrastText: "#FFF"

    },

    secondary: {
      main: '#BF87C4', //purple
      light: '#F4F4F7', //light purple
    },

    text: {

      primary: '#212121',

      secondary: '#8A8A8A'

    },

    error: {

      main: '#D62828', //red

      light: '#E85D5D',

      dark: '#951C1C'

    },

    info: {
      main: '#DDE9E9' //orange
    }

  },

  components: {

    MuiAppBar: {

      styleOverrides: {

        root: {

          boxShadow: '0px 3px 7px #DDD',

          // backgroundColor: '#FFF'

        }

      }

    },

    MuiList: {

      styleOverrides: {

        root: {

          backgroundColor: '#FFF'

        }

      }

    },

    MuiTooltip: {

      styleOverrides: {

        tooltip: {

          fontSize: 13,

          maxWidth: 'none',

          fontFamily: 'Noto Sans',

        }

      }

    },

    MuiOutlinedInput: {

      styleOverrides: {

        notchedOutline: {
          borderColor: '#64067F'
        }

      }

    }

  }

});



const tableStyle = {

  '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {

    outline: 'none',

  },

  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {

    outline: 'none',

  },

  '&.MuiDataGrid-root': {

    border: '1px solid #EBEBEB'

  },

  '&.MuiDataGrid-root .MuiDataGrid-cell': {

    borderBottom: '1px solid #EBEBEB'

  },

  '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {

    borderTop: '1px solid #EBEBEB'

  },

  '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {

    borderBottom: '1px solid #EBEBEB',

    zIndex: 1

  },

  '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {

    fontWeight: 600

  },

  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {

    outline: 'none',

  },

  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {

    outline: 'none',

  },

  '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': {

    display: 'none',

  },

  '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
    backgroundColor: '#EFE2F0',
  },

  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:hover': {

    borderBottom: '1px solid #EBEBEB'

  },

  '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {

    // background: '#FFF'

  }

}



export { Theme, tableStyle };