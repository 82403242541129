import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"

// Interfaces and Types
interface EditTemplateFileDialogProps extends React.ComponentProps<any> {
    open: boolean,
    setOpen: (value: boolean) => void,
    confirmEdit: () => void
}

const EditTemplateFileDialog = (props: EditTemplateFileDialogProps): JSX.Element => {

    // Destructure props object
    const { open, setOpen, confirmEdit } = props;

    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
            <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main }} >Tem certeza que deseja editar o contrato?</Typography>
                <IconButton onClick={() => setOpen(false)} >
                    <Close color="primary" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Para editar o arquivo do modelo é necessário enviar novamente o documento word e também será necessario inserir novamente os dados do modelo
                </Typography>
            </DialogContent>
            <DialogActions>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width="100%" sx={{m: 1}}>
                    <Button variant="text" onClick={() => setOpen(false)} >Cancelar</Button>
                    <Button variant="contained" onClick={confirmEdit} >Editar modelo</Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default EditTemplateFileDialog;