export interface Address {
    zipCode : string;
    publicPlace : string;
    complement? : string;
    district : string;
    locality : string;
    state : string;
    country : string;
    addressNumber : string;
}

function addressToString(address : Address) : string{
    const _complement = (address.complement && address.complement.trim().length > 0) ? " " + address.complement.trim() + "," :  "";
    return `${address.publicPlace}, ${address.addressNumber}, ${address.district},${_complement} ${address.locality}, ${address.state}, ${address.country} - ${address.zipCode}`;
}

export {
    addressToString
}