import { DateRange, ExpandMore, FilterAlt, FilterAltOff, VerticalSplit } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Badge, Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Menu, Radio, RadioGroup, Skeleton, SxProps, TextField, Theme, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { ChartDataset, CustomGranularityMatchGroup, DataGranularity, GranularityMatchGroupFields, PropertyFilter } from "../../../../models/analytics";
import { DigitalContractTemplate, PerformanceComparanceData } from "../../../../models/contracts";
import { show } from "../../../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../../../redux/hooks";
import ContractsService from "../../../../services/contracts";
import ErrorWrapper from "../../../../utils/ErrorWrapper";
import { Colors } from "../../../../utils/colors";
import { CurrencyValue, SupportedCurrencies } from "../../../../utils/currency";
import { PerformanceAnalyticsProps, TotalAmountOfBillingDataAnalyticsContractStates, translateTotalAmountOfBillingDataAnalyticsContractStates } from "./Analytics";
import DigitalContractTemplatePropertyFilters from "./DigitalContractTemplatePropertyFilters";
import { ArrayUtils, Dataset, XYAxisDatasets } from "./analytics-utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Bar } from "react-chartjs-2";

interface DatePeriod {
    startDate: Date,
    endDate: Date,
}

type DatasetData = "value" | "count";

function replaceLabelTemplateIdForTemplateName(input: string, digitalContractTemplates: DigitalContractTemplate[]): string {
    digitalContractTemplates.forEach(template => {
        if (input.indexOf(template.id) >= 0) {
            input = input.replaceAll(template.id, template.name);
        }
    });
    return input;
}

class ChartDatasets extends XYAxisDatasets {
    datasets: ChartDataset[];

    constructor(xyAxisDatasets: XYAxisDatasets, digitalContractTemplates: DigitalContractTemplate[] | undefined = undefined) {
        super(xyAxisDatasets.labels, xyAxisDatasets.datasets);

        // callback used to normalize the label for a human more readable one
        const normalizeLabel = (input: Dataset, digitalContractTemplates: DigitalContractTemplate[] | undefined): string => {
            let label = input.label;

            // replace the digital contract templates ID for its name
            if (digitalContractTemplates) label = replaceLabelTemplateIdForTemplateName(label, digitalContractTemplates);

            // replace all the state enumeration values from an string literal
            const states: TotalAmountOfBillingDataAnalyticsContractStates[] = ["WAITING_FOR_SIGNATURES", "INACTIVE", "SIGNED", "WAITING_FOR_ISSUED"];
            states.forEach(state => {
                if (label.indexOf(state) >= 0) {
                    label = label.replace(state, translateTotalAmountOfBillingDataAnalyticsContractStates(state as TotalAmountOfBillingDataAnalyticsContractStates));
                }
            });
            return label;
        }

        this.datasets = xyAxisDatasets.datasets.map(d => {
            const datasetColor = Colors.random.withSL(0.89, 0.72);
            return {
                ...d,
                currency: (d as any).currency as SupportedCurrencies,
                backgroundColor: datasetColor.toRGBHexadecimal(),
                lineColor: datasetColor.brighter(-0.1).toRGBHexadecimal(),
                label: normalizeLabel(d, digitalContractTemplates)
            }
        });
    }
}

const PerformanceContracts = (props: PerformanceAnalyticsProps): JSX.Element => {

    // Destructure props object
    const { digitalContractTemplate, digitalContractTemplates, tag } = props;

    // Context
    const notification = useAppDispatch();

    //presets 
    const FixedGranularityMatchGroup: GranularityMatchGroupFields[] = ["granularity", "currency"];
    const FixedContractGranularityMatchGroup: GranularityMatchGroupFields[] = ["granularity"];
    const CustomGranularityMatchGroups: CustomGranularityMatchGroup[] = [
        {
            label: "Modelo de Contrato",
            value: "templateId"
        },
        {
            label: "Estado do Contrato",
            value: "state"
        },
        {
            label: "Cobrança",
            value: "billingName"
        },
    ];

    const now = moment()

    const refSecondPeriod: DatePeriod = {
        startDate: now.clone().subtract(3, "month").startOf("month").toDate(),
        endDate: now.clone().endOf("month").toDate(),
    }

    const refFirstPeriod: DatePeriod = {
        startDate: moment(refSecondPeriod.startDate).subtract(1, "day").subtract(3, "month").startOf("month").toDate(),
        endDate: moment(refSecondPeriod.startDate).subtract(1, "day").endOf("month").toDate(),
    }

    // Refs
    const fpDatePickerRef = useRef<HTMLDivElement>(null);
    const spDatePickerRef = useRef<HTMLDivElement>(null);

    // Boolean states
    const [loadingData, setLoadingData] = useState(true);

    // Data States
    const [analyticsData, setAnalyticsData] = useState<PerformanceComparanceData | null>(null)

    const [firstPeriod, setFirstPeriod] = useState<DatePeriod>(refFirstPeriod);
    const [secondPeriod, setSecondPeriod] = useState<DatePeriod>(refSecondPeriod);

    const [minDiffInMonths, setMinDiffInMonths] = useState(moment(refFirstPeriod.endDate).diff(moment(refFirstPeriod.startDate), "month"));

    /**
     * this prop will store witch fields will be used to group/split the data from the analyticsData state
     */
    const [customGranularityMatchGroup, setCustomGranularityMatchGroup] = useState<GranularityMatchGroupFields[]>(["templateId", "state"]);

    /**
     * This state will store the API filters
     */
    const [apiFilters, setApiFilters] = useState<PropertyFilter[]>([]);

    const [fpChartDatasets, setFpChartDatasets] = useState<ChartDatasets | null>(null)
    const [spChartDatasets, setSpChartDatasets] = useState<ChartDatasets | null>(null)

    const [datasetDataType, setDatasetDataType] = useState<DatasetData>("value")

    // Ref States
    const [fpDateIntervalMenuAnchor, setFpDateIntervalMenuAnchor] = useState<null | HTMLElement>(null);
    const [spDateIntervalMenuAnchor, setSpDateIntervalMenuAnchor] = useState<null | HTMLElement>(null);

    // UseEffects

    useEffect(() => {
        fetchAnalyticalData();
    }, [firstPeriod, secondPeriod, apiFilters])

    useEffect(() => {
        generateChartsDatasets();
    }, [analyticsData, customGranularityMatchGroup, datasetDataType])

    // Functions

    function fetchAnalyticalData() {

        setAnalyticsData(null);

        if (!firstPeriod || !secondPeriod) return;

        // Get the first period dates for validation and comparison
        const fpStartDateMoment = moment(firstPeriod.startDate);
        const fpEndDateMoment = moment(firstPeriod.endDate);
        const fpDifferenceInMonths = fpEndDateMoment.diff(fpStartDateMoment, "month")

        // Get the second period dates for validation and comparison
        const spStartDateMoment = moment(secondPeriod.startDate);
        const spEndDateMoment = moment(secondPeriod.endDate);
        const spDifferenceInMonths = spEndDateMoment.diff(spStartDateMoment, "month")

        // If the periods are different return
        if (fpDifferenceInMonths !== spDifferenceInMonths) return;

        // Set the granularity based on the period being fetched
        let granularity: DataGranularity = "YEAR_AND_MONTH";
        if (fpDifferenceInMonths <= 3) granularity = "WEEKLY";
        else if (fpDifferenceInMonths > 12) granularity = "YEAR";
        // If the period is longer than 6 years show error to user and return
        else if (fpDifferenceInMonths > 72) {
            notification(show({
                type: "error",
                message: "Não é possivel comparar mais do que 6 anos"
            }))
            return;
        }

        setLoadingData(true);

        ContractsService.fetchPerformanceAnalyticalData(
            firstPeriod.startDate,
            firstPeriod.endDate,
            secondPeriod.startDate,
            secondPeriod.endDate,
            granularity,
            apiFilters,
            tag?.tagName
        )
            .then(response => {
                 // make front-end filters on the data provided from the API
                 if (digitalContractTemplate) {
                    response.firstPeriod = response.firstPeriod.filter(r => r.templateId === props.digitalContractTemplate?.id);
                    response.secondPeriod = response.secondPeriod.filter(r => r.templateId === props.digitalContractTemplate?.id);
                }
                if (!(response.firstPeriod.length <= 0 && response.secondPeriod.length <= 0)) {
                    setAnalyticsData(response)
                }
            })
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({
                    type: "error",
                    message: err.message
                }))
            })
            .finally(() => setLoadingData(false));
    }

    function generateChartsDatasets() {

        setFpChartDatasets(null);
        setSpChartDatasets(null);

        if (!analyticsData) return;

        setLoadingData(true);

        if (datasetDataType === "value") {
            const fpAccumulatorMatchGroups = ArrayUtils
                .accumulativeGrouping(
                    analyticsData.firstPeriod,
                    [...FixedGranularityMatchGroup, ...customGranularityMatchGroup],
                    (r) => new CurrencyValue(r.totalSum, r.currency as SupportedCurrencies).regionalValueDecimal
                );

            const fpXyAxisDatasets = fpAccumulatorMatchGroups.toXYAxisDatasets(FixedGranularityMatchGroup[0]);
            setFpChartDatasets(new ChartDatasets(fpXyAxisDatasets, digitalContractTemplates));

            const spAccumulatorMatchGroups = ArrayUtils
                .accumulativeGrouping(
                    analyticsData.secondPeriod,
                    [...FixedGranularityMatchGroup, ...customGranularityMatchGroup],
                    (r) => new CurrencyValue(r.totalSum, r.currency as SupportedCurrencies).regionalValueDecimal
                );

            const spXyAxisDatasets = spAccumulatorMatchGroups.toXYAxisDatasets(FixedGranularityMatchGroup[0]);
            setSpChartDatasets(new ChartDatasets(spXyAxisDatasets, digitalContractTemplates));
        } else {
            const fpAccumulatorMatchGroups = ArrayUtils
                .accumulativeGrouping(
                    analyticsData.firstPeriod,
                    [...FixedGranularityMatchGroup, ...customGranularityMatchGroup],
                    (r) => r.count
                );

            const fpXyAxisDatasets = fpAccumulatorMatchGroups.toXYAxisDatasets(FixedGranularityMatchGroup[0]);
            setFpChartDatasets(new ChartDatasets(fpXyAxisDatasets, digitalContractTemplates));

            const spAccumulatorMatchGroups = ArrayUtils
                .accumulativeGrouping(
                    analyticsData.secondPeriod,
                    [...FixedGranularityMatchGroup, ...customGranularityMatchGroup],
                    (r) => r.count
                );

            const spXyAxisDatasets = spAccumulatorMatchGroups.toXYAxisDatasets(FixedGranularityMatchGroup[0]);
            setSpChartDatasets(new ChartDatasets(spXyAxisDatasets, digitalContractTemplates));
        }

        setLoadingData(false);
    }

    // Handler functions

    /**
     * Function used to handle checkbox 'onChange' events to set the state of the customGranularityMatchGroup state prop
     * @param event 
     */
    function handleCustomGroupingCheckboxOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        // check if is a deletion
        const elementIndex = customGranularityMatchGroup.indexOf(event.target.value as GranularityMatchGroupFields);
        if (elementIndex >= 0) {
            setCustomGranularityMatchGroup(customGranularityMatchGroup.filter(cgm => cgm !== (event.target.value as GranularityMatchGroupFields)));
        }
        // it is a inclusion
        else {
            const newSelections: GranularityMatchGroupFields[] = [];
            CustomGranularityMatchGroups.forEach(cgm => {
                // if the current value is already set on the current state, add it
                if (customGranularityMatchGroup.indexOf(cgm.value) >= 0) newSelections.push(cgm.value);
                //otherwise, check if the value from the event target is the new selection, if it is, add it 
                else if ((event.target.value as GranularityMatchGroupFields) === cgm.value) newSelections.push(cgm.value);

                // finally, change react state
                setCustomGranularityMatchGroup(newSelections);
            });
        }
    }

    /**setApiFilters
     * handle the property filters change event of DigitalContractTemplatePropertyFilters component
     * @param propertyFilters
     */
    function handlePropertyFiltersChange(propertyFilters: PropertyFilter[]): void {
        setApiFilters(propertyFilters);
    }

    function handleFpStartDateChange(value: Moment | null) {
        if (!value) return;
        setFirstPeriod({
            ...firstPeriod,
            startDate: value.startOf("month").toDate()
        });
        const diffInMonths = moment(firstPeriod.endDate).diff(value, "month");
        setMinDiffInMonths(diffInMonths);
        const spEnd = moment(secondPeriod.startDate).add(diffInMonths, "month").endOf("month").toDate()
        setSecondPeriod({
            ...secondPeriod,
            endDate: spEnd
        });
    }

    function handleFpEndDateChange(value: Moment | null) {
        if (!value) return;
        setFirstPeriod({
            ...firstPeriod,
            endDate: value.endOf("month").toDate()
        });
        const diffInMonths = value.diff(moment(firstPeriod.startDate), "month");
        setMinDiffInMonths(diffInMonths);


        if (moment(secondPeriod.startDate).isSameOrBefore(value)) {
            const spStart = value.add(1, "month").startOf("month").toDate();
            setSecondPeriod({
                startDate: spStart,
                endDate: moment(spStart).add(diffInMonths, "month").endOf("month").toDate()
            })
        } else {
            const spEnd = moment(secondPeriod.startDate).add(diffInMonths, "month").endOf("month").toDate()
            setSecondPeriod({
                ...secondPeriod,
                endDate: spEnd
            });
        }
    }

    function handleSpStartDateChange(value: Moment | null) {
        if (!value) return;
        setSecondPeriod({
            startDate: value.startOf("month").toDate(),
            endDate: value.add(minDiffInMonths, "months").endOf("month").toDate()
        });
    }

    function handleSpEndDateChange(value: Moment | null) {
        if (!value) return;
        setSecondPeriod({
            startDate: value.subtract(minDiffInMonths, "month").startOf("month").toDate(),
            endDate: value.endOf("month").toDate()
        });
    }

    function handleChangeDatasetDataType(e: React.ChangeEvent<HTMLInputElement>) {
        setDatasetDataType(e.target.value as DatasetData)
    }

    // Styles
    const chartContainer: SxProps<Theme> = {
        ['@media(max-width:650px)']: {
            height: "375px"
        },
        padding: "16px",
        border: "2px solid rgb(230, 230, 230)",
        marginBottom: "16px",
        width: "90%",
        height: "100%",
    }

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        <VerticalSplit sx={{ transform: "translateY(6px); scaleY(-1)", mr: 1 }} color="primary" /> Agrupamentos <Badge sx={{ marginLeft: 2 }} badgeContent={customGranularityMatchGroup.length} color="primary" />
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <FormGroup>
                            {
                                CustomGranularityMatchGroups.map(cmg => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={customGranularityMatchGroup.indexOf(cmg.value) >= 0}
                                                onChange={handleCustomGroupingCheckboxOnChange}
                                                value={cmg.value}
                                            />
                                        }
                                        label={cmg.label}
                                    />
                                ))
                            }
                        </FormGroup>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            {/** Accordion with API filter options */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        {
                            (apiFilters.length > 0)
                                ?
                                <FilterAlt sx={{ transform: "translateY(6px); scaleY(-1)", mr: 1 }} color="primary" />
                                :
                                <FilterAltOff sx={{ transform: "translateY(6px); scaleY(-1)", mr: 1 }} color="primary" />
                        }
                        Filtros do modelo de contrato
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DigitalContractTemplatePropertyFilters digitalContractTemplate={digitalContractTemplate} onChange={handlePropertyFiltersChange} />
                </AccordionDetails>
            </Accordion>
            <Box display="flex" sx={{mt: 2}} alignItems={"center"}>
                <Typography variant="h6" sx={{mr: 2}}>Comparar:</Typography>
                <RadioGroup
                    row={true}
                    value={datasetDataType}
                    onChange={(e) => handleChangeDatasetDataType(e)}
                >
                    <FormControlLabel value={"value"} control={<Radio />} label="Valor" />
                    <FormControlLabel value={"count"} control={<Radio />} label="Quantidade" />
                </RadioGroup>
            </Box>
            <Grid container>
                <Grid item xs={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", p: "20px" }}>
                        <Typography>1º Período para comparação:</Typography>
                        <Button
                            variant="outlined"
                            sx={{ maxWidth: "300px", mt: 2 }}
                            startIcon={<DateRange />}
                            onClick={e => setFpDateIntervalMenuAnchor(e.currentTarget)}
                        >
                            <Typography> ({firstPeriod.startDate.toLocaleDateString()} - {firstPeriod.endDate.toLocaleDateString()})</Typography>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={fpDateIntervalMenuAnchor}
                            open={Boolean(fpDateIntervalMenuAnchor)}
                            onClose={() => setFpDateIntervalMenuAnchor(null)}
                        >
                            <Box sx={{ p: 2 }}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                                    <DatePicker
                                        label="Selecione uma data inicial"
                                        inputFormat="DD/MM/YYYY"
                                        value={firstPeriod.startDate}
                                        onChange={(value: Moment | null) => { handleFpStartDateChange(value) }}
                                        renderInput={(params) => <TextField {...params} size='small' />}
                                        maxDate={moment(firstPeriod.endDate).startOf("month")}
                                    />
                                    <DatePicker
                                        label="Selecione uma data final"
                                        inputFormat="DD/MM/YYYY"
                                        value={firstPeriod.endDate}
                                        onChange={(value: Moment | null) => { handleFpEndDateChange(value) }}
                                        renderInput={(params) => <TextField {...params} size='small' sx={{ ml: 2 }} />}
                                        minDate={moment(firstPeriod.startDate).endOf("month")}
                                        InputAdornmentProps={{ ref: fpDatePickerRef }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Menu>
                        {
                            !loadingData
                                ?
                                (fpChartDatasets && spChartDatasets)
                                    ?
                                    <Box sx={{ ...chartContainer, mt: 4 }}>
                                        <Bar
                                            style={{ width: "100%", maxHeight: "850px" }}
                                            data={{
                                                labels: fpChartDatasets.labels,
                                                datasets: fpChartDatasets.datasets.map(d => ({
                                                    label: d.label,
                                                    data: d.values,
                                                    fill: false,
                                                    borderWidth: 2,
                                                    backgroundColor: d.backgroundColor,
                                                    borderColor: d.lineColor,
                                                }))
                                            }}
                                            options={{ maintainAspectRatio: false, indexAxis: "y" }}
                                        />
                                    </Box>
                                    :
                                    <Alert variant="outlined" severity="info" sx={{ p: 4, mt: 4, width: "90%" }}>Não há dados para serem buscados com os parâmetros de busca utilizados</Alert>
                                :
                                <Box>
                                    <Skeleton variant="rectangular" animation="wave" width="90%" height={350} sx={{ marginTop: 3 }} />
                                </Box>

                        }
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", p: "20px" }}>
                        <Typography>2º Período para comparação:</Typography>
                        <Button
                            variant="outlined"
                            sx={{ maxWidth: "300px", mt: 2 }}
                            startIcon={<DateRange />}
                            onClick={e => setSpDateIntervalMenuAnchor(e.currentTarget)}
                        >
                            <Typography> ({secondPeriod.startDate.toLocaleDateString()} - {secondPeriod.endDate.toLocaleDateString()})</Typography>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={spDateIntervalMenuAnchor}
                            open={Boolean(spDateIntervalMenuAnchor)}
                            onClose={() => setSpDateIntervalMenuAnchor(null)}
                        >
                            <Box sx={{ p: 2 }}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                                    <DatePicker
                                        label="Selecione uma data inicial"
                                        inputFormat="DD/MM/YYYY"
                                        views={['year', 'month']} 
                                        value={secondPeriod.startDate}
                                        onChange={(value: Moment | null) => { handleSpStartDateChange(value) }}
                                        renderInput={(params) => <TextField {...params} size='small' />}
                                        minDate={moment(firstPeriod.endDate).add(1, "day")}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Menu>
                        {
                            !loadingData
                                ?
                                (fpChartDatasets && spChartDatasets)
                                    ?
                                    <Box sx={{ ...chartContainer, mt: 4 }}>
                                        <Bar
                                            style={{ width: "100%", maxHeight: "850px" }}
                                            data={{
                                                labels: spChartDatasets.labels,
                                                datasets: spChartDatasets.datasets.map(d => ({
                                                    label: d.label,
                                                    data: d.values,
                                                    fill: false,
                                                    borderWidth: 6,
                                                    backgroundColor: d.backgroundColor,
                                                    borderColor: d.lineColor
                                                }))
                                            }}
                                            options={{ maintainAspectRatio: false, indexAxis: "y" }}
                                        />
                                    </Box>
                                    :
                                    <Alert variant="outlined" severity="info" sx={{ p: 4, mt: 4, width: "90%" }}>Não há dados para serem buscados com os parâmetros de busca utilizados</Alert>
                                :
                                <Box>
                                    <Skeleton variant="rectangular" animation="wave" width="90%" height={350} sx={{ marginTop: 3 }} />
                                </Box>
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default PerformanceContracts;