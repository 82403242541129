import { Container, Typography } from "@mui/material";
import { IssueValidityPeriod, ValidityPeriodSpecification } from "../../../../../../../models/contracts";
import IssueValidityForm from "../forms/IssueValidityForm";

// Interfaces and Types
interface IssueValidityProps extends React.ComponentProps<any> {
    issueValidity: IssueValidityPeriod,
    validityPeriod: ValidityPeriodSpecification,
    updateIssueValidity: (value: IssueValidityPeriod) => void
}

const IssueValidity = (props: IssueValidityProps): JSX.Element => {

    // Destructure props object
    const { issueValidity, updateIssueValidity, validityPeriod } = props;

    return (
        <Container>
            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >
                Período de vigência do contrato
            </Typography>
            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                Defina o período em que o contrato estará em vigência
            </Typography>
            <IssueValidityForm issueValidity={issueValidity} validityPeriod={validityPeriod} updateIssueValidity={updateIssueValidity} />
        </Container>
    )
}

export default IssueValidity;