import { Grading, Search, Subject, Description, DocumentScanner, UploadFile, Contacts as ContactsIcon } from "@mui/icons-material";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { DigitalContractTemplate, EditableDigitalContract, ExternalDigitalContract, IssuedDigitalContract } from "../models/contracts";
import { SearchResultIdentification } from "../models/search-result";
import DigitalContractService from "../services/contracts";
import {useSearchParams} from "react-router-dom"
import "./styles/GlobalResourceSearchBar.css";
import { ContactInformation } from "../models/contacts";
import ContactsService from "../services/contacts";

type SearchResultTypes = "EDITABLE_DIGITAL_CONTRACT" | "ISSUED_DIGITAL_CONTRACT" | "DIGITAL_CONTRACT_TEMPLATE" | "EXTERNAL_DIGITAL_CONTRACT" | "CONTACT";
/**
 * Store search results
 */
interface SearchResult {
    id : string,
    data : EditableDigitalContract | IssuedDigitalContract | DigitalContractTemplate | ExternalDigitalContract | ContactInformation,
    label : string,
    type : SearchResultTypes,
    resourceIdentification : SearchResultIdentification,
}

function CustomAvatar(searchResult : SearchResult) : JSX.Element | null {
    switch (searchResult.type) {
        case 'EDITABLE_DIGITAL_CONTRACT':
            return <Subject color="primary" />;
        case 'ISSUED_DIGITAL_CONTRACT':
            return <Grading color="secondary" />;
        case "DIGITAL_CONTRACT_TEMPLATE":
            return <Description color="secondary" />;
        case "EXTERNAL_DIGITAL_CONTRACT":
            return <UploadFile color="primary" />;
        case "CONTACT":
            return <ContactsIcon color="primary" />;
        default:
            return <></>;
    }
}

interface OnResourceClickCallback {
    (resource : SearchResultIdentification, template? : DigitalContractTemplate) : void
}

interface GlobalSearchBarProps extends React.ComponentProps<any> {
    onResourceClick? : OnResourceClickCallback,
    searchLimiter? : SearchResultTypes,
    style?: string,
}

const GlobalSearchBar = (props : GlobalSearchBarProps) : JSX.Element => {
    // States
    const [searchTerm, setSearchTerm] = useState("");
    const [fetching, setFetching] = useState(false);
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [newFetchedResults, setNewFetchedResults] = useState<SearchResult[]>([]);
    const [templates, setTemplates] = useState<DigitalContractTemplate[]>([])

    // React Router Search parameters
    const [searchParams, setSearchParams] = useSearchParams();

    // effect that fetch data from the given search term
    const SEARCH_TIMEOUT = 1200;
    let lastTimeout = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {

        if (lastTimeout.current) {
            clearTimeout(lastTimeout.current);
        }

        // put the search result on an timeout to prevent API usage abuse
        lastTimeout.current = setTimeout(() => {

            // ignore if its already fetching data or not search term is set
            if (searchTerm.length === 0 || fetching) {
                return;
            }
            setFetching(true);
            // Check if the search is limited to templates
            if (props.searchLimiter === "DIGITAL_CONTRACT_TEMPLATE") {
                DigitalContractService.searchDigitalTemplate(searchTerm)
                .then(response => {
                    // map the response from the API into an normalized format
                    const mappedResponse : SearchResult[] = 
                    response
                        .map(r => ({
                            id : r.id,
                            data : r,
                            label : r.name,
                            type : "DIGITAL_CONTRACT_TEMPLATE",
                            resourceIdentification : {
                                resourceType : "digital-contract-template",
                                resourceId : r.id
                            }
                        }));

                    setNewFetchedResults(mappedResponse);
                    setTemplates(response)
                })
                .finally(() => {
                    setFetching(false);
                });
            }
            else if (props.searchLimiter === "CONTACT") {
                ContactsService.searchContacts(searchTerm)
                    .then((response) => {
                        // map the response from the API into an normalized format
                        const mappedResponse : SearchResult[] = 
                        response
                            .map(r => ({
                                id : r.id,
                                data : r,
                                label : r.name,
                                type : "CONTACT",
                                resourceIdentification : {
                                    resourceType : "contact",
                                    resourceId : r.id
                                }
                            }));

                        setNewFetchedResults(mappedResponse);
                    })
                    .finally(() => setFetching(false))
            }
            // Check if there is no search limitation
            else if (!props.searchLimiter) {
                let loadedEditableDigitalContracts = false;
                let loadedIssuedEditableContracts = false;
                let finishAll = () => loadedEditableDigitalContracts && loadedIssuedEditableContracts;

                // fetch editable digital contracts
                DigitalContractService.searchEditableDigitalContracts(searchTerm)
                .then(response => {

                    // map the response from the API into an normalized format
                    const mappedResponse : SearchResult[] = 
                    response
                        .map(r => ({
                            id : r.id,
                            data : r,
                            label : r.name,
                            type : "EDITABLE_DIGITAL_CONTRACT",
                            resourceIdentification : {
                                resourceType : "editable-contract",
                                resourceId : r.id
                            }
                        }));

                    setNewFetchedResults(mappedResponse);
                })
                .finally(() => {
                    loadedEditableDigitalContracts = true;
                    setFetching(!finishAll());
                });

                // fetch issued digital contracts
                DigitalContractService.searchIssuedDigitalContracts(searchTerm)
                .then(response => {

                    // map the response from the API into an normalized format
                    const mappedResponse : SearchResult[] = 
                    response
                        .map(r => ({
                            id : r.id,
                            data : r,
                            label : r.name,
                            type : "ISSUED_DIGITAL_CONTRACT",
                            resourceIdentification : {
                                resourceType : "issued-contract",
                                resourceId : r.id
                            }
                        }))
                    
                    setNewFetchedResults(mappedResponse);
                })
                .finally(() => {
                    loadedIssuedEditableContracts = true;
                    setFetching(!finishAll());
                })

                DigitalContractService.searchExternalDigitalContracts(searchTerm)
                    .then(response => {
                        const mappedResponse : SearchResult[] = 
                        response.map(r => ({
                            id: r.id,
                            data: r,
                            label: r.name,
                            type: "EXTERNAL_DIGITAL_CONTRACT",
                            resourceIdentification: {
                                resourceId: r.id,
                                resourceType: "external-contract"
                            }
                        }))
                        setNewFetchedResults(mappedResponse);
                    })
                    .finally(() => {
                        loadedEditableDigitalContracts = true;
                        setFetching(!finishAll());
                    });
            }

        }, SEARCH_TIMEOUT);
    }, [searchTerm]);

    // effect that will control async changes in search Results
    useEffect(() => {
        const nonDuplicatedResults = newFetchedResults.filter(n => searchResults.findIndex(s => s.id === n.id) < 0);
        const mergedResults = [...searchResults, ...nonDuplicatedResults];
        setSearchResults(mergedResults);
    }, [newFetchedResults]);

    // event that handles search field input value changes
    function handleAutocompleteTextFieldChange(e : ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setSearchTerm(e.target.value);
        setSearchResults([]);
    }

    // event that handles search resource click
    function handleResourceOnClick(resource : SearchResult) {
        
        // add the resources identification on the app URL. This will trigger the App.tsx to fetch the data from the select resource
        setSearchParams({
            "srt" : resource.resourceIdentification.resourceType,
            "srid" : resource.resourceIdentification.resourceId
        });

        // trigger the callback when the item is clicked
        if (props.onResourceClick) {
            if (props.searchLimiter === "DIGITAL_CONTRACT_TEMPLATE") {
                const selectedTemplate = templates.find(template => template.id === resource.id)
                if (selectedTemplate) props.onResourceClick(resource.resourceIdentification, selectedTemplate)
            }
            props.onResourceClick(resource.resourceIdentification);
        }

        //reset search engine component
        setSearchTerm("");
        setSearchResults([]);
    }

    return (
        <Autocomplete
            id={props.searchLimiter === "DIGITAL_CONTRACT_TEMPLATE" ? "global-search-bar-template" : props.style ? "global-search-bar-template" :"global-search-bar"}
            options={searchResults}
            sx={{ width: 300, color: "white" }}
            filterOptions={(x) => x}
            disableCloseOnSelect
            noOptionsText={"Sem resultados"}
            placeholder={props.searchLimiter === "DIGITAL_CONTRACT_TEMPLATE" ? "Encontre um template" : props.searchLimiter === "CONTACT" ? "Encontre um contato" : "Encontre um contrato"}
            loading={fetching}
            loadingText={'Buscando...'}
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    onChange={handleAutocompleteTextFieldChange} 
                    InputProps={{
                        ...params.InputProps,
                        startAdornment : <span><Search sx={{color : props.style ? "black" : "white"}} />{params.InputProps.startAdornment}</span>
                    }}
                    placeholder={props.searchLimiter === "DIGITAL_CONTRACT_TEMPLATE" ? "Encontre um template" : props.searchLimiter === "CONTACT" ? "Encontre um contato" : "Encontre um contrato"}
                    sx={{
                        color : props.searchLimiter === "DIGITAL_CONTRACT_TEMPLATE" ? "#212121" : "#fff"
                    }}
                />
            )}
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props} onClick={() => handleResourceOnClick(option)}>
                        <Box display={'flex'} flexDirection={'row'}>
                            {CustomAvatar(option)}
                            <Box display={'flex'} ml={3} flexDirection={'column'}>
                                <Typography color={'text.primary'} fontSize={'0.9em'}>
                                    {option.label}
                                </Typography>
                            </Box>
                        </Box>
                    </li>
                );
               }
            }
        />
    )
}

export default GlobalSearchBar;