import { createContext } from "react";
import queryString from "query-string"

export enum ServiceResponses {
    TOKEN = 'token',
    ACCOUNT_TYPE = 'accountType'
}

//The context interface
export interface RouteParamContextInterface {
    get(value: ServiceResponses): string | null;
    delete(queryParams: ServiceResponses[]): void
}

//The react context
export const RouteParamContext = createContext<RouteParamContextInterface>({
    /**
     * Get the value of a query param
     * @param value 
     * @returns 
     */
    get(value: ServiceResponses) {
        if (window.location.search.length <= 1) return null;
        
        //Current URL parsed
        const queryURL = queryString.parse(window.location.search)
        //Get the param value
        const paramValue = queryURL[value]

        //Return it 
        return (paramValue) ? paramValue.toString() : null;
    },

    delete(queryParams: ServiceResponses[]) {
        const queryURL = queryString.parse(window.location.search);

        queryParams.forEach(value => delete queryURL[value])

        const newUrl = queryString.stringifyUrl({
        url: window.location.pathname,
        query: queryURL,
        });

        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, '', newUrl);
    }
})