import { Box, Button, Grid, Popover, SxProps, Typography, Dialog, CircularProgress, Container } from "@mui/material";
import { Theme } from "@mui/system";
import { ChangeEvent, useEffect, useState } from "react";
import { ChromePicker, ColorResult } from "react-color";
import { colord, extend} from "colord";
import harmonies from "colord/plugins/harmonies";
import mixPlugin from "colord/plugins/mix";
import { Navigate } from "react-router-dom";

import { EmailPreview, SignScreenPreview } from "./Preview";
import PayWallDialog from "../../components/PayWallDialog";
import OrganizationService from "../../services/organization";

import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../redux/hooks";

import { InterfaceColors } from "../../models/organizations";
import ErrorWrapper from "../../utils/ErrorWrapper";

//include plugins in colord
extend([harmonies, mixPlugin])


interface LogoFile {
    name: string,
    file: File | undefined
    blob: string
};

interface CustomizeProps extends React.ComponentProps<any> {
    interfaceThemesCustomizationEnabled: boolean
}

const Customize = (props: CustomizeProps):JSX.Element => {

    const [logoFile, setLogoFile] = useState<LogoFile>();
    const [primaryColor, setPrimaryColor] = useState("");
    const [secondaryColor, setSecondaryColor] = useState("");
    const [colors, setColors] = useState<string[]>([])
    const [activeColor, setActiveColor] = useState("")
    const [selectedColors, setSelectedColors] = useState<InterfaceColors>({
        primaryColor: "",
        secondaryColor: ""
    })
    const [selectedPalette, setSelectedPalette] = useState<number | null>(null)

    const [logoUrl, setLogoUrl] = useState<string | null>(null)

    const [colorPickerAnchor, setColorPickerAnchor] = useState<HTMLButtonElement | null>(null);
    const [openPrimaryColorPicker, setOpenPrimaryColorPicker] = useState(false);
    const [openSecondaryColorPicker, setOpenSecondaryColorPicker] = useState(false);
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false)

    const [openPayWallDialog, setOpenPayWallDialog] = useState(false)
    const [enableRedirectAfterCloseDialog, setEnableRedirectAfterCloseDialog] = useState(false)

    const [openPreviewDialog, setOpenPreviewDialog] = useState(false)

    const notification = useAppDispatch();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [loading, setLoading] = useState(false);
    const [logoHasBeenChanged, setLogoHasBeenChanged] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
        window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
        OrganizationService.fetchOrganizationInterfaceTheme().then(response => {
            setLogoUrl(`${response.logoUrl}?randomText=${(Math.random() + 1).toString(36).substring(7)}`)
            setPrimaryColor(response.primaryColor)
            setSecondaryColor(response.secondaryColor)
            setSelectedColors({
                primaryColor: response.primaryColor,
                secondaryColor: response.secondaryColor
            })
        })
        .catch(e => {
            const error = new ErrorWrapper(e);
            if (error.httpStatus != 404) {
                console.error(e);
                notification(show({
                    type: 'error',
                    message: 'An unexpected error occur while trying to fetch organization theme: ' + error.message
                }))
            }
        })
    }, [])


    useEffect(() => {
        if (!props.interfaceThemesCustomizationEnabled) {
            handleOpenPayWallDialog()
        }
    }, [])

    useEffect(()=>{
        if((primaryColor && secondaryColor && logoUrl) || (primaryColor && secondaryColor && logoFile)){
            setSaveButtonEnabled(true)
        }
        else {
            setSaveButtonEnabled(false)
        }
    }, [primaryColor, secondaryColor, logoUrl])

    function generatePalette(colorHex: string) {
        const color = colord(colorHex)

        const tetradicColor = color.harmonies("tetradic").map((c) => c.toHex())

        const colorsPalette:string[] = []

        //Generate an array with 3 adjacent colors and push this array to the colors
        colorsPalette.push(...color.harmonies("analogous").map(c => c.toHex()))

        //Generate a new array a with a mix between the selected color and a tetradic color
        colorsPalette.push(...colord(tetradicColor[1]).harmonies("analogous").map(c => c.toHex()))
        //Generate a new array a with a mix between the selected color and a tetradic color
        colorsPalette.push(...colord(tetradicColor[2]).harmonies("analogous").map(c => c.toHex()))
        
        colorsPalette.shift()

        setColors(colorsPalette)
    }

    const buttomBoxTheme: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            padding: "0.375rem 0.625rem",
            margin: "auto"
        },
        ['@media(max-width:300px)']: {
            padding: "0.125rem 0.25rem",
            height: "1.5rem",
            width: "6.5rem",
            margin: "auto"
        },
        height: "36px",
        width: "170px"
    }

    const buttonTheme: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            fontSize: "0.75rem"
        },
        ['@media(max-width:300px)']: {
            fontSize: "0.5rem"
        },
        fontSize: "1rem",
        color: "#fff",
    }

    const colorButtonTheme: SxProps<Theme> = {
        borderRadius: 0,
        borderBottom: "1px solid #000",
        textAlign: "center",
        minWidth: "170px"
    }

    const logoContainerTheme: SxProps<Theme> = { 
        
        ['@media(max-width:600px)']: {
            flexDirection: "column",
            gap: "1rem"
        },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }

    const previewContainerTheme: SxProps<Theme> = {
        marginLeft: "4rem",
        marginTop: "2rem",
        ['@media(max-width:1300px)']: {
            marginLeft: "2rem",
        },
        ['@media(max-width:600px)']: {
            marginLeft: 0,
            overflow: "scroll",
        },
    }

    const previewSectionTheme: SxProps<Theme> = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ['@media(max-width:600px)']: {
            alignItems: "flex-start"
        }
    }

    function uploadLogoFile(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files) { 
            const file = e.target.files[0]
            if (file.type !== "image/jpeg" && file.type !== "image/png") {
                notification(show({
                    type: 'error',
                    message: 'Imagem de logo precisa ser .png ou .jpeg'
                }))
            } else {
                const blob = URL.createObjectURL(file)
                const logoFile: LogoFile = {
                    name: e.target.files[0].name,
                    file: e.target.files[0],
                    blob: blob.toString()
                }
    
                setLogoFile(logoFile)
                setSaveButtonEnabled(true)
                setLogoHasBeenChanged(true)
            }
        }
    }

    function handlePrimaryColorChange(color: ColorResult) {
        setPrimaryColor(color.hex)
        setSelectedColors({...selectedColors, primaryColor: color.hex})
    }
    
    function handleSecondaryColorChange(color: ColorResult) {
        setSecondaryColor(color.hex)
        setSelectedColors({...selectedColors, secondaryColor: color.hex})
    }

    function handleCloseColorPicker() {
        setColorPickerAnchor(null)
        setOpenPrimaryColorPicker(false)
        setOpenSecondaryColorPicker(false)
        generatePalette(primaryColor)
        setActiveColor(primaryColor)
    }

    function handleSelectColors(primaryColor: string, secondaryColor: string, index: number) {
        setSelectedPalette(index)
        setSelectedColors({primaryColor, secondaryColor})
        setSecondaryColor(secondaryColor)
    }

    async function handleSaveTheme() {
        setLoading(true);
        try {
            await OrganizationService.uploadColorsTheme(selectedColors)
            if(logoFile?.file && logoHasBeenChanged) {
                await OrganizationService.uploadLogoTheme(logoFile.file)
                setLogoHasBeenChanged(false);
            }
            notification(show({
                type: 'success',
                message: 'Tema aplicado com sucesso.'
            }))
        } catch(e) {
            const err = new ErrorWrapper(e)
            notification(show({
                type: 'error',
                message: (err.httpStatus != 200) ? `Não foi possível aplicar o tema.` : undefined
            }))
        } finally{
            setLoading(false);
        }
    }

    function handleClosePayWallDialog() {
        setOpenPayWallDialog(false);
        setEnableRedirectAfterCloseDialog(true)
    }

    function handleOpenPayWallDialog() {
        setOpenPayWallDialog(true)
        setEnableRedirectAfterCloseDialog(false)
    }

    return (
        <Container sx={{py: 4}}>
            {props.interfaceThemesCustomizationEnabled ?
                <Box>
                    <Box mb={4}>
                        <Typography variant='h6' color='primary.main'>
                            <b>Personalize</b> a experiência do <b>usuário</b>
                        </Typography>
                        <Typography variant="subtitle2">
                        Aqui você pode personalizar a experiência que um participante vai ter ao assinar um contrato da sua organização.
                        </Typography>
                    </Box>
                    <Grid container>
                        <Grid lg={6} md={12}>
                            <Box>
                                <Typography variant="subtitle1" color="primary.main">
                                    Personalize com seu logo
                                </Typography>
                                <Typography variant="subtitle2">
                                    Envie seu logo para personalizar as telas de assinatura e o e-mail.
                                </Typography>
                                <Box mt={2} sx={logoContainerTheme} >
                                    <Button sx={buttomBoxTheme}
                                        variant="contained"
                                        component="label">
                                        <Typography sx={buttonTheme}>
                                            Envie o seu logo
                                        </Typography>
                                        <input
                                            type="file"
                                            id="select-image"
                                            accept="image/png, image/jpeg"
                                            onChange={(e) => {uploadLogoFile(e)}}
                                            hidden
                                        />
                                    </Button>
                                    {
                                        (logoFile && logoFile.blob && logoFile.file) 
                                        ? 
                                            <Box>
                                                <Box sx={{ display: "inline-block", borderRadius: "12px" }}>
                                                    <img src={logoFile.blob} style={{ maxWidth: "300px", width: "100%", maxHeight: "150px", height: "100%" }} />
                                                </Box>
                                                <Typography sx={{ textAlign: "center", maxWidth: "150px", maxHeight: "24px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", color: "#555555" }}>
                                                    {logoFile.file.name}
                                                </Typography>
                                            </Box>
                                        :
                                            logoUrl
                                            ?
                                                <Box>
                                                    <Box sx={{ display: "inline-block", borderRadius: "12px" }}>
                                                        <img src={logoUrl} style={{ maxWidth: "300px", width: "100%", maxHeight: "150px", height: "100%" }} />
                                                    </Box>
                                                </Box>
                                            :
                                                <></>
                                    }
                                </Box>
                                    <Typography variant="subtitle1" color="primary.main" mt={6}>
                                        Personalize com suas cores
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Escolha as para personalizar as telas de assinatura e e-mail
                                    </Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Typography variant="subtitle2" mt={2}>
                                            Cor Primária
                                        </Typography>
                                        <Box mt={2} sx={{display: "flex", alignItems:"center", justifyContent:"space-between", maxWidth: "270px" }}>
                                            <Button 
                                                aria-describedby="colorPicker" 
                                                onClick={e => {
                                                    setColorPickerAnchor(e.currentTarget)
                                                    setOpenPrimaryColorPicker(true)
                                                }}
                                                sx={colorButtonTheme}
                                            >
                                                {primaryColor !== "" ? primaryColor.toUpperCase() : "Escolha uma cor"}

                                                <Box sx={{width: "50px", height: "35px", backgroundColor: primaryColor, borderRadius: "5px", border: "1px solid #000000DD", marginLeft: "8px"}} /> 
                                            </Button>
                                            <Popover 
                                                id="colorPicker" 
                                                open={openPrimaryColorPicker} 
                                                anchorEl={colorPickerAnchor} 
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                onClose={() => handleCloseColorPicker()}
                                            >
                                            <ChromePicker color={primaryColor} onChange={e => handlePrimaryColorChange(e)} disableAlpha />
                                            </Popover>
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                    <Typography variant="subtitle2" mt={2}>
                                            Cor Secundária
                                        </Typography>
                                        <Box mt={2} sx={{display: "flex", alignItems:"center", justifyContent:"space-between", maxWidth: "270px" }}>
                                            <Button 
                                                aria-describedby="colorPicker" 
                                                onClick={e => {
                                                    setColorPickerAnchor(e.currentTarget)
                                                    setOpenSecondaryColorPicker(true)
                                                }}
                                                sx={colorButtonTheme}
                                                disabled={primaryColor === ""}
                                            >
                                                {secondaryColor !== "" ? secondaryColor.toUpperCase() : "Escolha uma cor"}

                                                <Box sx={{width: "50px", height: "35px", backgroundColor: secondaryColor, borderRadius: "5px", border: "1px solid #000000DD", marginLeft: "8px"}} /> 
                                            </Button>
                                            <Popover 
                                                id="colorPicker" 
                                                open={openSecondaryColorPicker} 
                                                anchorEl={colorPickerAnchor} 
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                onClose={() => handleCloseColorPicker()}
                                            >
                                            <ChromePicker color={secondaryColor} onChange={e => handleSecondaryColorChange(e)} disableAlpha />
                                            </Popover>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box mt={6}>
                                    {primaryColor !== "" 
                                    ?
                                        <>
                                            <Typography variant="subtitle2">Selecione uma paleta de cor para ser aplicada</Typography>
                                            <Box mt={4}>
                                                        <Grid container spacing={2}>
                                                            {colors.map((generatedColor, i: number) => (
                                                                <Grid item sm={3} xs={6} sx={{display: "flex"}}>
                                                                    <Button 
                                                                        key={i} 
                                                                        sx={selectedPalette === i ? {border: "1px solid #64067F"} : {border: 0}} 
                                                                        onClick={() => handleSelectColors(activeColor, generatedColor, i)}
                                                                    >
                                                                        <Box sx={{width: "50px", height: "33px", backgroundColor: activeColor, borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"}} />
                                                                        <Box sx={{width: "50px", height: "33px", backgroundColor: generatedColor, borderTopRightRadius: "5px", borderBottomRightRadius: "5px"}} />
                                                                    </Button>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                            </Box>
                                        </>
                                    :
                                        <></>
                                    }   
                                </Box>

                                <Button 
                                    variant="contained" 
                                    sx={{marginTop: "2rem"}}
                                    onClick={() => handleSaveTheme()}
                                    disabled={!saveButtonEnabled || secondaryColor === "" || loading}
                                >
                                { loading ? <CircularProgress sx={{color:"#fff"}} size={24} /> : "Salvar" }
                                </Button>
                            </Box>
                        </Grid>
                        <Grid lg={6} md={12} sx={previewSectionTheme}>
                            {windowWidth > 550 ? 
                                <Box sx={previewContainerTheme}>
                                    <Box>
                                        <EmailPreview primaryColor={selectedColors.primaryColor} secondaryColor={selectedColors.secondaryColor} fileUrl={logoFile?.blob ? logoFile.blob : logoUrl ? logoUrl : undefined} />   
                                    </Box>
                                    <Box mt={2}>
                                        <SignScreenPreview primaryColor={selectedColors.primaryColor} secondaryColor={selectedColors.secondaryColor} fileUrl={logoFile?.blob ? logoFile.blob : logoUrl ? logoUrl : undefined}/>
                                    </Box>
                                </Box>
                            :
                                <Box>
                                    <Button variant="contained" sx={{marginTop: "2rem"}} onClick={() => setOpenPreviewDialog(true)}>
                                    Abrir Pré-Vizualização
                                    </Button>
                                    <Dialog open={openPreviewDialog} onClose={() => setOpenPreviewDialog(false)}>
                                        <Box sx={previewContainerTheme}>
                                            <Box>
                                                <EmailPreview primaryColor={selectedColors.primaryColor} secondaryColor={selectedColors.secondaryColor} fileUrl={logoFile?.blob ? logoFile.blob : logoUrl ? logoUrl : undefined} />   
                                            </Box>
                                            <Box mt={2}>
                                                <SignScreenPreview primaryColor={selectedColors.primaryColor} secondaryColor={selectedColors.secondaryColor} fileUrl={logoFile?.blob ? logoFile.blob : logoUrl ? logoUrl : undefined}/>
                                            </Box>
                                        </Box>
                                    </Dialog>
                                </Box>
                            }               
                        </Grid>
                    </Grid>
                </Box>
            :
                <Box>
                    <PayWallDialog open={openPayWallDialog} onClose={handleClosePayWallDialog} quotaName="Personalização" productName="Modulo de personalização de interface" type="module" />
                    {enableRedirectAfterCloseDialog ? <Navigate to="/contracts/create" /> : <></>}
                </Box>
            }
            
        </Container>
    )
}

export default Customize;