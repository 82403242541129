import { AccessTime, AlternateEmail, Badge, Business, CalendarMonth, Check, Checklist, DateRange, Event, EventRepeat, Home, Numbers, Remove, Today } from "@mui/icons-material";
import { BillingPeriodType, RequiredInformationType } from "../models/contracts";

export const ContractPartiesSuggestions: string[] = [
    'Contratante',
    'Contratada',
    'Vendedor',
    'Comprador',
    'Testemunha',
    'Advogado Responsável',
    'Representante Legal',
    'Fiador',
    'Beneficiário',
    'Prestador de Serviços',
    'Empreiteiro',
    'Arrendatário',
    'Arrendador',
    'Locatário',
    'Locador',
    'Cessionário',
    'Cedente',
    'Promitente Comprador',
    'Promitente Vendedor',
    'Depositante',
    'Depositário',
    'Outorgante',
    'Outorgado',
    'Proprietário',
    'Fornecedor',
    'Mutuário',
    'Mutuante',
    'Concessionária',
    'Concedente',
    'Licenciado',
    'Licenciante',
    'Comodante',
    'Comodatário',
    'Corretor',
    'Comissão',
    'Notário',
    'Testamenteiro',
    'Herdeiro',
    'Legatário',
    'Procurador',
    'Subcontratado',
    'Subcontratante',
    'Cedente de Direitos',
    'Cessionário de Direitos',
    'Promotor',
    'Promovido',
    'Mandante',
    'Mandatário',
    'Devedor',
    'Credor'
];

export enum EditorSearchResultType {
    ORGANIZATION_ACCOUNT = "ORGANIZATION_ACCOUNT",
    ORGANIZATION_SUBUSER_ACCOUNT = "ORGANIZATION_SUBUSER_ACCOUNT",
    ORGANIZATION_TEAM = "ORGANIZATION_TEAM"
}

export interface EditorSearchResult {
    id: string;
    description: string;
    subdescription: string | null;
    type: EditorSearchResultType;
    object: any;
}

export interface ActionButton {
    value: string,
    label: string,
    onClick: () => void,
    icon: JSX.Element,
    disabled: boolean,
}

export type Step = "import" | "fill-data";

export type Perspective = "document" | "data";

interface RIType {
    value: RequiredInformationType,
    label: string,
    icon: JSX.Element
}

interface BillType {
    value: BillingPeriodType,
    label: string,
    icon: JSX.Element
}

export const riTypes: RIType[] = [
    {
        value: "SINGLE_LINE_TEXT",
        label: "Texto de linha única",
        icon: <Remove />
    },
    {
        value: "BRA_CPF",
        label: "CPF",
        icon: <Badge />
    },
    {
        value: "BRA_CNPJ",
        label: "CNPJ",
        icon: <Business />
    },
    {
        value: "BRA_CPF_OR_CNPJ",
        label: "CPF ou CNPJ",
        icon: <Badge />
    },
    {
        value: "EMAIL",
        label: "E-mail",
        icon: <AlternateEmail />
    },
    {
        value: "DATE",
        label: "Data",
        icon: <CalendarMonth />
    },
    {
        value: "TIME",
        label: "Horário",
        icon: <AccessTime />
    },
    {
        value: "TIMESTAMP",
        label: "Data e Horário",
        icon: <Event />
    },
    {
        value: "ADDRESS",
        label: "Endereço",
        icon: <Home />
    },
    {
        value: "INTEGER",
        label: "Número inteiro",
        icon: <Numbers />
    },
    {
        value: "DECIMAL",
        label: "Número decimal",
        icon: <Numbers />
    },
    {
        value: "SINGLE_SELECT",
        label: "Seleção única",
        icon: <Check />
    },
    {
        value: "MULTIPLE_SELECT",
        label: "Seleção múltipla",
        icon: <Checklist />
    },
    
]

export const billTypes: BillType[] = [
    {
        value: BillingPeriodType.ONE_TIME_PAYMENT,
        label: "Cobrança Única",
        icon: <Today />
    },
    {
        value: BillingPeriodType.INSTALLMENTS,
        label: "Cobrança Parcelada",
        icon: <DateRange />
    },
    {
        value: BillingPeriodType.RECURRING,
        label: "Cobrança Recorrente",
        icon: <EventRepeat />
    }
]