import axios, { AxiosError } from "axios";
import { CurrentContext } from "../appctx/webappContext";
import authenticationStorage from "../store/authentication";

//Marks if the application is running on development
const DEV = process.env.NODE_ENV === 'development';

//Create the AccountsApi Axios instance
const AccountsApi = axios.create({
    baseURL: CurrentContext.accountsAPI.baseURL,
    timeout: CurrentContext.accountsAPI.timeout,
    withCredentials : true
})

//Adding request interceptors
AccountsApi.interceptors.request.use((config) => {

    config.headers = { 
        'Authorization' : `Bearer ${authenticationStorage.getAccountsToken()}`,
        "Accept" : "application/json"
    }
    return config;
});

AccountsApi.interceptors.response.use(
    (response) => {
        return response
    },
    (error: AxiosError) => {
        
        // If there is no response just reject the error
        if (!error.response) return Promise.reject(error);
        
        // If there is and the status is 5xx change the x-reason to default message
        if (Math.floor(error.response.status / 100) === 5) {
            error.response.headers["x-reason"] = "Ocorreu um erro no servidor"
            console.error(error)
        }

        return Promise.reject(error);
    }
)

export default AccountsApi;