import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, IconButton, ListItemIcon, Tooltip, Typography } from "@mui/material";
import { FileIssueBilling, FileIssueContractPartie } from "../../../../../../models/contracts";
import { billTypes } from "../../../../../../utils/editorUtils";
import AddFieldButton from "../../../components/AddFieldButton";
import IssueBillingForm from "../../../issue/issue-from-file/components/forms/IssueBillingForm";


// Interfaces and Types
interface ExternalBillingsProps extends React.ComponentProps<any> {
    addBilling: boolean,
    parties: FileIssueContractPartie[],
    billings: FileIssueBilling[],
    currentBill: FileIssueBilling | null,
    setBillings: (billings: FileIssueBilling[]) => void,
    setAddBillings: (addBill: boolean) => void,
    setCurrentBill: (currentBill: FileIssueBilling | null) => void,
}

const ExternalBillings = (props: ExternalBillingsProps): JSX.Element => {

    // Destructure props object
    const { addBilling, billings, currentBill, parties, setAddBillings, setBillings, setCurrentBill } = props;

    // Handler functions
    function handleAddBilling(bill: FileIssueBilling) {
        const updatedBillings = [...billings, bill];
        setBillings(updatedBillings.sort((a, b) => a.name.localeCompare(b.name)));
        setAddBillings(false);
    }

    function handleEditBilling(bill: FileIssueBilling) {
        if (!currentBill) return;

        const updatedBillings = billings.map(b => {
            if (b.name === currentBill.name) return bill;
            return b;
        })

        setBillings(updatedBillings.sort((a, b) => a.name.localeCompare(b.name)));
        setCurrentBill(null);
    }

    function handleOpenBillingEdit(bill: FileIssueBilling) {
        setCurrentBill(bill);
    }

    function handleDeleteBilling(bill: FileIssueBilling) {
        const filter = billings.filter(b => b.name !== bill.name);

        setBillings(filter);
    }

    return (
        <Box sx={{ maxHeight: "550px", overflow: "auto" }}>
            {
                addBilling
                    ?
                    <IssueBillingForm
                        addBilling={handleAddBilling}
                        billings={billings}
                        cancelAction={() => setAddBillings(false)}
                        parties={parties}
                    />
                    :
                    <AddFieldButton disabled={addBilling || currentBill !== null} onClick={() => setAddBillings(true)} title="Adicionar cobrança" />
            }

            {
                billings.map(bill => (
                    <Box key={bill.name}>
                        {
                            currentBill && currentBill.name === bill.name
                                ?
                                <IssueBillingForm
                                    addBilling={handleEditBilling}
                                    billings={billings}
                                    cancelAction={() => setAddBillings(false)}
                                    parties={parties}
                                    currentBill={currentBill}
                                />
                                :
                                <Box
                                    key={bill.name}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        py: 1,
                                        px: 2,
                                        border: "1px solid rgba(0, 0, 0, 0.25)",
                                        borderRadius: "5px",
                                        mb: 3
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {
                                            billTypes.filter(billType => billType.value === bill.billingPeriodType).map(billType => (
                                                <Tooltip title={billType.label}>
                                                    <ListItemIcon>
                                                        {billType.icon}
                                                    </ListItemIcon>
                                                </Tooltip>
                                            ))
                                        }
                                        <Typography >{bill.name}</Typography>
                                    </Box>
                                    <Box>
                                        <IconButton onClick={() => handleOpenBillingEdit(bill)} >
                                            <EditOutlined color="primary" />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteBilling(bill)} >
                                            <DeleteOutline color="error" />
                                        </IconButton>
                                    </Box>
                                </Box>
                        }
                    </Box>

                ))}
        </Box>
    )
}

export default ExternalBillings;