import { Box, Fab } from "@mui/material"
import { Perspective } from "../../../../utils/editorUtils"
import { Notes, InsertDriveFileOutlined } from "@mui/icons-material";

// Interfaces and Types


interface ChangePerspectiveButtonsProps extends React.ComponentProps<any> {
    perspective: Perspective,
    setPerspective: (value: Perspective) => void
}

const ChangePerspectiveButtons = (props: ChangePerspectiveButtonsProps): JSX.Element => {

    // Destructure props object
    const { perspective, setPerspective } = props;

    return (
        <Box
            sx={{ position: "fixed", left: 20, bottom: 50, zIndex: 1000 }}
        >
            {
                perspective === "document"
                    ?
                    <Fab
                        variant="extended"
                        color="primary"
                        onClick={() => setPerspective("data")}
                    >
                        <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                            <Notes sx={{ textAlign: "center", display: "flex" }} />
                        </Box>
                    </Fab>
                    :
                    <Fab
                        variant="extended"
                        color="primary"
                        onClick={() => setPerspective("document")}
                    >
                        <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                            <InsertDriveFileOutlined sx={{ textAlign: "center", display: "flex" }} />
                        </Box>
                    </Fab>
            }
        </Box>
    )
}

export default ChangePerspectiveButtons;