interface AccumlativeFieldCallback<T> {
    (o : T) : number
}

export interface IIndexable {
    [key: string]: any;
}

export interface DeflatedSingleMatchGroup extends IIndexable{
    groupId : string
}

export class Dataset { 
    groupId : string;
    label : string;
    values : number[];

    constructor(groupId : string, label : string = groupId, values : number[] = []) {
        this.groupId = groupId;
        this.label = label;
        this.values = values;
    }
}

export class XYAxisDatasets {
    labels : string[];
    datasets : Dataset[];

    constructor(labels : string[] = [], datasets : Dataset[] = []) {
        this.labels = labels;
        this.datasets = datasets;
    }

    getOrAddLabel(label : string) : string {
        if (!this.labels.find(l => l === label)) {
            this.labels.push(label);
        }

        return label;
    }
    
}

export class AccumlativeMatchGroup{
    totalSum : number;
    count : number;
    matchGroup : IIndexable;

    constructor() {
        this.totalSum = 0;
        this.count = 0;
        this.matchGroup = {};
    }

    add(amount : number) : number {
        this.count += 1;
        return this.totalSum += amount;
    }
}

function deepGetFieldValue<T>(input : any, fieldName : string) : T {
    // validate field name
    if (fieldName.trim().length === 0) {
        throw new Error("Field name can not be empty");
    }

    const splittedFields = fieldName.split(".");
    let lastValue = input;

    for (let i = 0; i < splittedFields.length; i++) {
        const field = splittedFields[i];
        lastValue = (lastValue as IIndexable)[field];
        if (typeof lastValue !== "object" && i < splittedFields.length - 1) {
            throw new Error(`An undefined value was found while trying to fetch the field '${field}' from composed field ${fieldName}`);
        }
    }

    return lastValue;
}

export class AccumulativeMatchGroups {
    matchGroups : AccumlativeMatchGroup[];

    constructor() {
        this.matchGroups = [];
    }

    getOrCreate(matchGroup : AccumlativeMatchGroup) : AccumlativeMatchGroup{
        // if this collection does not have any match groups, automatically add it and return it
        if (this.matchGroups.length === 0) {
            this.matchGroups.push(matchGroup);
            return matchGroup;
        }

        // iterate over each match group in this collection to see if the given match group already matches with an existing one
        const alreadyMatchedGroup = this.matchGroups.find(mg => {
            // list the props from the given match group
            let itDoesMatch = true;
            for (const mgKey in matchGroup.matchGroup) {
                if (matchGroup.matchGroup[mgKey] !== mg.matchGroup[mgKey]) {
                    itDoesMatch = false;
                    break;
                }
            }
            return itDoesMatch;
        });

        // if an already matched group is found, return it
        if (alreadyMatchedGroup) {
            return alreadyMatchedGroup;
        }
        // otherwise add the new group into the array
        else {
            this.matchGroups.push(matchGroup);
            return matchGroup;
        }
    }

    toXYAxisDatasets(labelCandidateField : string) : XYAxisDatasets {
        // create an deflated match group using the label candidate as deflate identifier
        const deflatedMatchGroups = this.deflateToSingleFieldMatchGroup(labelCandidateField);

        // fetch the unique group ids and labels
        const uniqueGroupIds : string[] = [];
        const uniqueLabels : string[] = [];
        deflatedMatchGroups.matchGroups.forEach(deflatedMatchGorup => {
            const matchGroup = deflatedMatchGorup.matchGroup as DeflatedSingleMatchGroup;
            if (uniqueGroupIds.indexOf(matchGroup.groupId) < 0) uniqueGroupIds.push(matchGroup.groupId);
            if (uniqueLabels.indexOf(matchGroup[labelCandidateField]) < 0) uniqueLabels.push(matchGroup[labelCandidateField]);
        });

        const xyAxisDatasets = new XYAxisDatasets(uniqueLabels);

        // now that we have all unique groups and labels we can create the XY datasets...
        // One dataset will be created for each groupId
        const datasets : Dataset[] = [];
        uniqueGroupIds.forEach(groupId => {
            const dataset = new Dataset(groupId);

            // now, iterate for each label to add the dataset values...
            // if the groupId does not exists for the given label, it will add the value 0
            uniqueLabels.forEach(label => {
                const foundMatchGroup = deflatedMatchGroups.matchGroups.find(
                    matchGroup => matchGroup.matchGroup[labelCandidateField] === label && matchGroup.matchGroup.groupId === groupId);
                if (foundMatchGroup) {
                    dataset.values.push(foundMatchGroup.totalSum);
                }
                else {
                    dataset.values.push(0);
                }
            });

            // include the dataset into the return collection
            xyAxisDatasets.datasets.push(dataset);
        });

        return xyAxisDatasets;
    }

    deflateToSingleFieldMatchGroup(matchGroupField : string) : AccumulativeMatchGroups {
        const deflatedArray : IIndexable[] = [];
        this.matchGroups.forEach(matchGroup => {
            const deflatedObj : IIndexable = {};

            // add the matchGroup field as an field to the group
            deflatedObj[matchGroupField] = matchGroup.matchGroup[matchGroupField];
        
            // add the accumulative field into the deflated object
            deflatedObj.totalSum = matchGroup.totalSum;

            // group all matchGroup field from the match group object into an single object called "groupId"
            let groupId = "";
            for (const matchGroupKey in matchGroup.matchGroup) {

                // ignore the group identified as the match group field
                if (matchGroupKey === matchGroupField) {
                    continue;
                }

                // concatenate all other field values into the groupId string
                // also, use an " - " separator
                if (groupId.length > 0) groupId += " - ";
                groupId += matchGroup.matchGroup[matchGroupKey];
            }

            // if the groupId length is 0 (witch can happen if there is only one matchGroup field in the object) set it as the matchGroupField
            if (groupId.length === 0) groupId = matchGroup.matchGroup[matchGroupField];
            
            // include the groupId into the deflated object and add it to the collection
            deflatedObj.groupId = groupId;
            deflatedArray.push(deflatedObj);
        });

        // create another accumaltive grouping object using the deflated array object
        return ArrayUtils.accumulativeGrouping(deflatedArray, [matchGroupField, "groupId"], (o) => o.totalSum);
    }
}

const ArrayUtils =  {
    /**
     * Make an grouping with the given input array creating an AccumulativeMatchGroups object. The grouping/accumalation
     * will use the 'groupingFields' parameter to match each unique occurrence. For each unique occurrence of the 
     * grouping combination the accumator will sum the vale returned from the accumulativeFieldCallback using the values
     * from the input as parameter to it.
     * @param input 
     * @param groupingFields 
     * @param accumulativeFieldCallback 
     * @returns 
     */
    accumulativeGrouping<T>(input : T[], groupingFields : string[], accumulativeFieldCallback : AccumlativeFieldCallback<T>) : AccumulativeMatchGroups {
        // validate if an grouping field was given
        if (groupingFields.length === 0) {
            throw new Error("grouping fields should have at least 1 element");
        }
        
        // ignore if the input array length is 0
        if (input.length === 0) {
            return new AccumulativeMatchGroups();
        } 

        // iterate over each element from array
        let matchedGroups = new AccumulativeMatchGroups();

        input.forEach(i => {
            // extract the grouping fields from the input
            let matchGroup : AccumlativeMatchGroup  = new AccumlativeMatchGroup();
            groupingFields.forEach(groupingField => {
                //matchGroup.matchGroup[groupingField] = (i as IIndexable)[groupingField];
                matchGroup.matchGroup[groupingField] = deepGetFieldValue(i, groupingField);
            })

            matchedGroups.getOrCreate(matchGroup).add(accumulativeFieldCallback(i));
        });

        return matchedGroups;
    },
}

export {
    ArrayUtils
}