import { Theme } from "@emotion/react";
import { Box, Button, Divider, SxProps, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { RequiredInformationInputFieldProps } from "../rifields";
import { Help } from "@mui/icons-material";

const Timestamp = (props: RequiredInformationInputFieldProps): JSX.Element => {
    //states
    const [value, setValue] = useState(props.requiredInformation.value);
    const [isValid, setIsValid] = useState(false);

    const boxStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            padding: "1rem 0.8rem"
        },
        ['@media(max-width:300px)']: {
            padding: "1rem 0.8rem"
        },
        width: "100%",
        padding: "20px 28px",
        backgroundColor: "#ffffff",
        marginBottom: "14px",
        borderRadius: "10px",
        boxSizing: "border-box",
    }
    const titleStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            fontSize: "0.875rem",
            padding: "0.375rem 0.625rem"
        },
        ['@media(max-width:300px)']: {
            fontSize: "0.5rem",
            padding: "0.125rem 0.25rem"
        },
        color: "#555555",
        fontSize: "20px"
    }

    useEffect(() => {
        setIsValid(moment(parseInt(value)).isValid());
    }, [value])

    useEffect(() => {
        props.onChange(
            value,
            isValid
        );
    }, [value, isValid]);

    return (
        <Fragment key={props.requiredInformation.requiredInformationName}>
            <Box sx={boxStyle} >
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
                    <Typography sx={titleStyle}>{props.requiredInformation.requiredInformationName}</Typography>
                    {
                        props.requiredInformation.requiredInformation.requiredInformation.description
                            ?
                            <Tooltip title={props.requiredInformation.requiredInformation.requiredInformation.description}>
                                <Help sx={{ ml: 1, fontSize: "18px", color: "#555" }} />
                            </Tooltip>
                            :
                            <></>
                    }
                </Box>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={(isValid) ? null : "Preencha este campo: dia/mês/ano hora:minuto:segundo exemplo: 01/02/2003 04:05:06"}>
                            <Box style={{ display: "flex", alignItems: "center", border: (isValid) ? undefined : "1px solid red", borderRadius: "7px" }}>
                                <DatePicker
                                    value={moment(parseInt(value))}
                                    inputFormat="DD/MM/YYYY HH:mm:ss"
                                    label="dia/mês/ano hora:minuto:segundo"
                                    disableOpenPicker={true}
                                    onChange={(value: any) => {
                                        setValue(moment(value).valueOf().toString());
                                    }}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                </LocalizationProvider>
            </Box>
            <Divider />
        </Fragment>


    );
}


export default Timestamp;