import { Box, CircularProgress, Divider, Grid, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactSketchCanvasRef } from "react-sketch-canvas";
import { CurrentContext } from "../appctx/webappContext";
import { LoadingButton } from "../components/LoadingButton";
import AuthenticationContext from "../contexts/authentication";
import { RolesContext } from "../contexts/roles";
import { OrganizationPreferences } from "../models/organizations";
import { AccountTypes, AuthenticatedUser, UpdateUser } from "../models/user";
import { show } from "../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../redux/hooks";
import AuthorizationService from "../services/authorization";
import OrganizationService from "../services/organization";
import UserAccountService from "../services/user-account";
import ErrorWrapper from "../utils/ErrorWrapper";
import { CleanCPFOrCNPJ } from "../utils/stringUtils";

const dummyPreferencesObject: OrganizationPreferences = {
    contractsRestrictViewModeEnabled: false,
    organizationPresentationName: "",
}

const defaultUpdateUserObject: UpdateUser = {
    signatureDocument: "",
    signatureName: "",
}

const PersonalInfo = (): JSX.Element => {

    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Meu perfil"
    }, []);

    // contexts
    const userRoles = useContext(RolesContext)
    const auth = useContext(AuthenticationContext);

    // notification
    const notification = useAppDispatch();

    // HTTP query parameters
    const [searchParams] = useSearchParams();

    // states
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [userHasHandwrittenSignature, setUserHasHandwrittenSignature] = useState(auth.user().account.hasHandwrittenSignature);
    const [dialogToOpen] = useState(searchParams.get("dialog"));
    const [showHandwrittenSignatureDrawerDialog, setShowHandwrittenSignatureDrawerDialog] = useState(dialogToOpen === "draw-handwritten-signature");
    const [preferences, setPreferences] = useState<OrganizationPreferences>(dummyPreferencesObject);
    const [updateUser, setUpdateUser] = useState<UpdateUser>(defaultUpdateUserObject);
    const [dirtyDocumentValue, setDirtyDocumentValue] = useState("");
    const [validDocument, setValidDocument] = useState(true);

    const [user, setUser] = useState<AuthenticatedUser>(auth.user());

    /**
        * Open a new tab with Klaus Accounts webapp to update selected user  
        */
    const goToAccounts = () => {
        window.location.replace(`${CurrentContext.authWebappUrl}/login?service=accounts&requiredEmail=${auth.user().account.email}&redirectUrl=/my-profile`)
    }

    useEffect(() => {
        auth.updatedUser().then(user => {
            setUser(user);
            if (user.session.accountType === AccountTypes.Customer) {
                const restrict = user.account.contractsRestrictViewModeEnabled;
                const organizationPresentationName = user.account.organizationPresentationName;
                setPreferences({
                    ...preferences,
                    contractsRestrictViewModeEnabled: restrict,
                    organizationPresentationName: organizationPresentationName ? organizationPresentationName : ""
                });
            }
            setUpdateUser({ signatureDocument: user.account.document ? user.account.document : "", signatureName: user.account.signatureName ? user.account.signatureName : "" });
            setDirtyDocumentValue(user.account.document ? user.account.document : "");
        }).finally(() => setLoading(false));
    }, [])

    useEffect(() => {
        checkIfDocumentIsValid();
    }, [updateUser])
    
    function checkIfDocumentIsValid() {
        if (updateUser.signatureDocument === null || updateUser.signatureDocument === "") {
            setValidDocument(true);
            return;
        };
        setValidDocument(cnpj.isValid(updateUser.signatureDocument) || cpf.isValid(updateUser.signatureDocument))
    }

    async function handleSaveOrganizationConfiguration() {
        setButtonLoading(true)

        try {
            const response = await OrganizationService.updateOrganizationPreferences(preferences)
            auth.user().account.contractsRestrictViewModeEnabled = preferences.contractsRestrictViewModeEnabled
            auth.user().account.organizationPresentationName = preferences.organizationPresentationName

            notification(show({ type: "success", message: "Preferencias atualizadas com sucesso" }))
        } catch (error) {
            notification(show({ type: "error", message: "Não foi possivel atualizar as prefentecias" }))
        } finally {
            setButtonLoading(false)
        }
    }

    async function handleUpdateUser() {
        setButtonLoading(true);

        AuthorizationService.updateUser(updateUser)
            .then(response => {
                setUser(response);
                notification(show({ type: "success", message: "Conta atualizada com sucesso" }))
            })
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({ type: "error", message: err.message }));
            })
            .finally(() => {
                setButtonLoading(false);
            });
    }

    function handleChangeDocument(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setDirtyDocumentValue(e.target.value);
        const normalizedValue = CleanCPFOrCNPJ(e.target.value);
        setUpdateUser({ ...updateUser, signatureDocument: normalizedValue });
    }

    /**
     * @returns Component used to show the handwritten signature used by the user
     */
    const HandwrittenSignature = (): JSX.Element => {
        // Subcomponent states
        const [showDeleteHandwrittenSignatureDialog, setShowDeleteHandwrittenSignatureDialog] = useState(false);

        // Refs
        const canvasRef = useRef(null);

        /**
         * Delete the user handwritten signature using the contracts API
         * and update the authenticated user state
         */
        const deleteUserHandwrittenSignature = async () => {
            try {
                await UserAccountService.deleteHandwrittenSignature();
                await auth.updatedUser();
                setUserHasHandwrittenSignature(false);
                setShowDeleteHandwrittenSignatureDialog(false);
            } catch (e) {
                const error = new ErrorWrapper(e)
                notification(show({
                    type: 'error',
                    message: error.message
                }))
                console.error("An error occur while trying to delete the user handwritten signature", e);
            }
        }

        /**
         * Delete the current handwritten signature drawn on canvas
         */
        const deleteHandwrittenSignatureOnCanvas = () => {
            if (canvasRef?.current) {
                let canvas = canvasRef.current as ReactSketchCanvasRef;
                canvas.clearCanvas();
            }
            else {
                throw "Application could not receive canvasRef current value"
            }
        }

        /**
         * Upload the current signature image drawn on canvas in the authenticated user account
         */
        const uploadHandwrittenSignature = async () => {
            if (canvasRef.current) {
                // get the PNG image from canvas
                try {
                    // Get the PNG image from the canvas
                    let canvas = canvasRef.current as ReactSketchCanvasRef;
                    let pngImageFromCanvasBase64 = await canvas.exportImage("png");
                    let pngImageFromCanvasBlob = await (await fetch(pngImageFromCanvasBase64)).blob();

                    // Upload it using contracts API
                    await UserAccountService.uploadHandwrittenSignature(pngImageFromCanvasBlob);

                    // update the current authenticated user state in the application and this page state props
                    await auth.updatedUser();
                    setUserHasHandwrittenSignature(true);
                    setShowHandwrittenSignatureDrawerDialog(false);
                } catch (e) {
                    const error = new ErrorWrapper(e);
                    notification(show({
                        type: 'error',
                        message: error.message
                    }))
                    console.error("An error occur while trying to upload an handwritten signature", e);
                }

            }
            else {
                throw "Application could not receive canvasRef current value"
            }
        }

        return <></>

        // return <div style={{display : "none"}}>

        //     <Typography variant='h6' color='primary.main' mb={2}>Sua assinatura manuscrita digitalizada</Typography>
        //     {
        //         (userHasHandwrittenSignature) ?
        //         // the user have an handwritten signature
        //         <>
        //             <img src={`${process.env.REACT_APP_USER_CDN}/${auth.user().session.rasUuid}-handwsignimage?nocache=${Date.now()}`} style={{maxHeight : '256px', marginTop : 8, marginBottom : 8}}/>
        //         </>
        //         // the user do not have an handwritten signature
        //         :
        //         <Alert severity="info" sx={{mb : 2}}>Você ainda não tem uma assinatura. Clique no botão de desenhar uma assinatura para criar uma.</Alert>
        //     }
        //     <Stack spacing={2} direction="row">
        //         <Button variant="contained" onClick={() => {setShowHandwrittenSignatureDrawerDialog(true)}}>Desenhar nova assinatura</Button>
        //         <Button variant="contained" disabled={!userHasHandwrittenSignature}
        //         onClick={() => {
        //             setShowDeleteHandwrittenSignatureDialog(true)            
        //         }}
        //         >Deletar assinatura atual</Button>
        //     </Stack>
        //     { /*Delete signature dialog*/ }
        //     <Dialog
        //         open={showDeleteHandwrittenSignatureDialog}
        //         onClose={() => setShowDeleteHandwrittenSignatureDialog(false)}
        //     >
        //         <DialogTitle>
        //             Deletar assinatura manuscrita
        //         </DialogTitle>
        //         <DialogContent>
        //             <DialogContentText id="alert-dialog-description">
        //                 Tem certeza que deseja deletar sua assinatura manuscrita?
        //             </DialogContentText>
        //         </DialogContent>
        //         <DialogActions>
        //             <Button onClick={() => setShowDeleteHandwrittenSignatureDialog(false)}>Cancelar</Button>
        //             <LoadingButton loading={loading} variant='contained' color="error" 
        //                 onClick={deleteUserHandwrittenSignature}>
        //                 Deletar assinatura
        //             </LoadingButton>
        //         </DialogActions>
        //     </Dialog>

        //     { /*Handwritten signature drawer*/ }
        //     <Dialog
        //         fullScreen
        //         open={showHandwrittenSignatureDrawerDialog}
        //         onClose={() => {setShowHandwrittenSignatureDrawerDialog(false)}}
        //     >
        //     <AppBar sx={{ position: 'relative' }}>
        //     <Toolbar>
        //         <IconButton
        //             edge="start"
        //             color="inherit"
        //             onClick={() => {setShowHandwrittenSignatureDrawerDialog(false)}}
        //             aria-label="close"
        //         >
        //             <GridCloseIcon />
        //         </IconButton>
        //         <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color="white">
        //             Desenhe abaixo sua assinatura
        //         </Typography>
        //         <Stack direction="row" spacing={4}>
        //             <IconButton
        //                 edge="start"
        //                 color="inherit"
        //                 onClick={deleteHandwrittenSignatureOnCanvas}
        //                 aria-label="close"
        //             >
        //                 <DeleteForeverOutlined />
        //             </IconButton>
        //             <IconButton
        //                 edge="start"
        //                 color="inherit"
        //                 onClick={uploadHandwrittenSignature}
        //                 aria-label="close"
        //             >
        //                 <Save />
        //             </IconButton>
        //         </Stack>
        //     </Toolbar>
        //     </AppBar>
        //     <ReactSketchCanvas
        //         width="100%"
        //         height="100%"
        //         strokeWidth={5}
        //         strokeColor="black"
        //         ref={canvasRef}
        //     />
        // </Dialog>
        // </div>
    }

    const OrganizationSettings = (): JSX.Element => {
        return (
            <>
                <Box>
                    <Typography variant='h6' color='primary.main' mb={5}>Configurações da organização</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, alignItems: 'center' }}>
                        <Typography>Limitar visualização de contratos</Typography>
                        <Switch checked={preferences?.contractsRestrictViewModeEnabled} onChange={(e) => setPreferences({ ...preferences, contractsRestrictViewModeEnabled: e.target.checked })} />
                    </Box>
                    <Typography mb={3} color='text.secondary' variant='body2'>
                        Restringir a visualização de contratos emitidos para apenas administradores ou gerente de contratos e os emissores possam ver
                    </Typography>
                </Box>
                <Box>
                    <Typography>Definir nome de apresentação</Typography>
                    <Typography mb={3} color='text.secondary' variant='body2'>
                        Definir um nome para organização que será exibido em e-mails enviado para os participantes
                    </Typography>
                    <TextField fullWidth value={preferences.organizationPresentationName}
                        onChange={(e) => {
                            setPreferences({ ...preferences, organizationPresentationName: e.target.value })
                        }}
                    />
                </Box>
                <LoadingButton
                    sx={{ mt: 4 }}
                    variant='contained'
                    loading={buttonLoading}
                    onClick={handleSaveOrganizationConfiguration}
                >
                    Salvar configurações da organização
                </LoadingButton>
            </>
        )
    }

    return (
        <>
            {!loading ?
                <Box p={2} maxWidth={750} sx={{ margin: "auto", display: "block" }}>
                    <Grid container spacing={{ xs: 1, md: 4 }}>

                        <Grid item xs={12}>
                            <Typography variant='h6' color='primary.main' mb={5}>Configurações do usuário</Typography>
                            <Box>
                                <Typography>Nome Completo do documento</Typography>
                                <Typography mb={3} color='text.secondary' variant='body2'>
                                    Adicionar o nome completo para ser exibido nas assinaturas
                                </Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={updateUser.signatureName}
                                    label="Nome Completo (conforme documento)"
                                    error={!updateUser.signatureName}
                                    helperText={!updateUser.signatureName ? "Campo obrigatório" : ""}
                                    onChange={(e) => setUpdateUser({...updateUser, signatureName: e.target.value})}
                                    sx={{ mb: 4 }}
                                    />

                                <Typography>Número do documento</Typography>
                                <Typography mb={3} color='text.secondary' variant='body2'>
                                    Adicionar um documento como CPF/CNPJ para ser exibido nas assinaturas
                                </Typography>
                                <TextField fullWidth value={dirtyDocumentValue}
                                    onChange={(e) => {
                                        handleChangeDocument(e)
                                    }}
                                    error={!validDocument}
                                    helperText={validDocument ? "" : "Documento não é valido"}
                                />
                            </Box>
                            <Tooltip placement="left-start" title={!validDocument ? "Documento não é válido" : !updateUser.signatureName ? "Nome não é válido" : "" }>
                                <Box sx={{ mt: 4 }}>
                                    <LoadingButton
                                        variant='contained'
                                        loading={buttonLoading}
                                        onClick={handleUpdateUser}
                                        disabled={!validDocument || !updateUser.signatureName}
                                    >
                                        Salvar configurações
                                    </LoadingButton>
                                </Box>
                            </Tooltip>
                            <Divider sx={{ mt: 4, mb: 2 }} />
                        </Grid>
                        {
                            userRoles.assertIsOrganizationOrAdministrator(auth) ?
                                <Grid item xs={12}>
                                    <OrganizationSettings />
                                </Grid>
                                :
                                <></>
                        }

                        <Grid item xs={12}>
                            <HandwrittenSignature />
                        </Grid>
                    </Grid>
                </Box>
                :
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                    <CircularProgress size={50} sx={{ color: "primary.main" }} />
                </Box>
            }
        </>
    );
}

export default PersonalInfo;
