import { useEffect, useState } from "react";
import { AnalyticsProps } from "./Analytics";
import { Alert, Box, Grid, Skeleton, SxProps, Theme, Typography } from "@mui/material";
import AnalyticsService from "../../../../services/analytics";
import { PropertyFilter } from "../../../../models/analytics";
import ErrorWrapper from "../../../../utils/ErrorWrapper";
import { TotalMonthlyCountOfContracts } from "../../../../models/contracts";
import { Colors } from "../../../../utils/colors";
import { Bar, Doughnut } from "react-chartjs-2";

const ConversionAnalytics = (props: AnalyticsProps): JSX.Element => {

    // Destructure props object
    const { digitalContractTemplate, endDate, startDate, digitalContractTemplates, issuerId, tags } = props;

    // Boolean states
    const [loading, setLoading] = useState(true);

    // Data states
    const [apiFilters, setApiFilters] = useState<PropertyFilter[]>([]);
    const [conversionAnalyticsData, setConversionAnalyticsData] = useState<TotalMonthlyCountOfContracts | null>(null);
    const [labels, setLabels] = useState<string[]>([]);
    const [datasetsBarChart, setDatasetsBarChart] = useState<any[]>([]);
    const [doughnutData, setDoughnutData] = useState<any[]>([]);
    // useEffects
    useEffect(() => {
        fetchAnalyticsData();
    }, [apiFilters])

    useEffect(() => {
        processAnalyticalData();
    }, [conversionAnalyticsData])

    // Functions
    /**
     * Fetch the analitycs data from the server
     */
    function fetchAnalyticsData() {
        setLoading(true);

        const tagNames = props.tags ? props.tags.map(tag => tag.tagName) : [];

        AnalyticsService.conversionAnalyticsData(startDate, endDate, apiFilters, tagNames.join(" ,"))
            .then(response => {
                setConversionAnalyticsData(response);
            })
            .finally(() => setLoading(false));
    }

    /**
     * Process the analytics data to generate the charts data
     */
    function processAnalyticalData() {
        // If there is no data just return
        if (!conversionAnalyticsData) return;

        // Get the keys for the label
        const labels = Object.keys(conversionAnalyticsData);

        // Generate the bar chart data
        const updatedDatasets = Object.keys(conversionAnalyticsData[labels[0]]).reduce((acc: any[], key) => {
            if (key !== 'referenceMonth') {
                const datasetColor = Colors.random.withSL(0.89, 0.72);
                acc.push({
                    label: getDatasetLabel(key),
                    data: labels.map((month) => (conversionAnalyticsData[month] as any)[key]),
                    backgroundColor: datasetColor.toRGBHexadecimal(),
                    borderColor: datasetColor.brighter(-0.1).toRGBHexadecimal(),
                    borderWidth: 1,
                    fill: false
                });
            }
            return acc;
        }, []);

        // Initialize the counter for each value in the data
        let updatedTotalContracts = 0;
        let updatedTotalSigned = 0;
        let updatedTotalWaitingForSignature = 0;
        let updatedTotalNotReadyForIssue = 0;
        let updatedTotalReadyForIssue = 0;
        let updatedTotalDeleted = 0;
        let updatedTotalNotSigned = 0;

        // Iterate over the keys to get total of each count
        Object.keys(conversionAnalyticsData).forEach((month) => {
            const monthData = conversionAnalyticsData[month] as any;
            Object.keys(monthData).forEach((key) => {
                if (key !== 'referenceMonth') {
                    updatedTotalContracts += monthData[key];
                    switch (key) {
                        case "countOfDeleted":
                            updatedTotalDeleted += monthData[key];
                            break;
                        case "countOfNotReadyForIssue":
                            updatedTotalNotReadyForIssue += monthData[key];
                            break;
                        case "countOfNotSigned":
                            updatedTotalNotSigned += monthData[key];
                            break;
                        case "countOfReadyForIssue":
                            updatedTotalReadyForIssue += monthData[key];
                            break;
                        case "countOfSigned":
                            updatedTotalSigned += monthData[key];
                            break;
                        case "countOfWaitingForSignature":
                            updatedTotalWaitingForSignature += monthData[key];
                            break;
                    }
                }
            });
        });

        const dChartData: any[] = [];

        // Iterate over the data keys to generate the doughnut chart data for each key
        Object.keys(conversionAnalyticsData[labels[0]]).forEach(key => {
            if (key !== 'referenceMonth') {
                const datasetColor = Colors.random.withSL(0.89, 0.72);
                switch (key) {
                    case "countOfDeleted":
                        dChartData.push({
                            title: getDatasetLabel(key),
                            labels: ["Total de contratos", getDatasetLabel(key)],
                            datasets: [
                                {
                                    data: [updatedTotalContracts - updatedTotalDeleted, updatedTotalDeleted],
                                    backgroundColor: ["#AAA", datasetColor.brighter(-0.1).toRGBHexadecimal()],
                                    hoverBackgroundColor: ["#DDD", datasetColor.toRGBHexadecimal()]
                                }
                            ]
                        });
                        break;
                    case "countOfNotReadyForIssue":
                        dChartData.push({
                            title: getDatasetLabel(key),
                            labels: ["Total de contratos", getDatasetLabel(key)],
                            datasets: [
                                {
                                    data: [updatedTotalContracts - updatedTotalNotReadyForIssue, updatedTotalNotReadyForIssue],
                                    backgroundColor: ["#AAA", datasetColor.brighter(-0.1).toRGBHexadecimal()],
                                    hoverBackgroundColor: ["#DDD", datasetColor.toRGBHexadecimal()]
                                }
                            ]
                        });
                        break;
                    case "countOfNotSigned":
                        dChartData.push({
                            title: getDatasetLabel(key),
                            labels: ["Total de contratos", getDatasetLabel(key)],
                            datasets: [
                                {
                                    data: [updatedTotalContracts - updatedTotalNotSigned, updatedTotalNotSigned],
                                    backgroundColor: ["#AAA", datasetColor.brighter(-0.1).toRGBHexadecimal()],
                                    hoverBackgroundColor: ["#DDD", datasetColor.toRGBHexadecimal()]
                                }
                            ]
                        });
                        break;
                    case "countOfReadyForIssue":
                        dChartData.push({
                            title: getDatasetLabel(key),
                            labels: ["Total de contratos", getDatasetLabel(key)],
                            datasets: [
                                {
                                    data: [updatedTotalContracts - updatedTotalReadyForIssue, updatedTotalReadyForIssue],
                                    backgroundColor: ["#AAA", datasetColor.brighter(-0.1).toRGBHexadecimal()],
                                    hoverBackgroundColor: ["#DDD", datasetColor.toRGBHexadecimal()]
                                }
                            ]
                        });
                        break;
                    case "countOfSigned":
                        dChartData.push({
                            title: getDatasetLabel(key),
                            labels: ["Total de contratos", getDatasetLabel(key)],
                            datasets: [
                                {
                                    data: [updatedTotalContracts - updatedTotalSigned, updatedTotalSigned],
                                    backgroundColor: ["#AAA", datasetColor.brighter(-0.1).toRGBHexadecimal()],
                                    hoverBackgroundColor: ["#DDD", datasetColor.toRGBHexadecimal()]
                                }
                            ]
                        });
                        break;
                    case "countOfWaitingForSignature":
                        dChartData.push({
                            title: getDatasetLabel(key),
                            labels: ["Total de contratos", getDatasetLabel(key)],
                            datasets: [
                                {
                                    data: [updatedTotalContracts - updatedTotalWaitingForSignature, updatedTotalWaitingForSignature],
                                    backgroundColor: ["#AAA", datasetColor.brighter(-0.1).toRGBHexadecimal()],
                                    hoverBackgroundColor: ["#DDD", datasetColor.toRGBHexadecimal()]
                                }
                            ]
                        });
                        break;
                }
            }
        })

        setDatasetsBarChart(updatedDatasets);
        setDoughnutData(dChartData);
        setLabels(labels);
    }

    /**
     * Use the key of the conversion analytics data to get the user friendly label
     * @param key 
     * @returns 
     */
    function getDatasetLabel(key: string) {
        switch (key) {
            case "countOfDeleted":
                return "Deletados";
            case "countOfNotReadyForIssue":
                return "Aguardando Informação";
            case "countOfNotSigned":
                return "Não Assinados";
            case "countOfReadyForIssue":
                return "Aguardando Emissão";
            case "countOfSigned":
                return "Assinados";
            case "countOfWaitingForSignature":
                return "Aguardando Assinatura";
        }
    }

    // Styles
    const chartContainer: SxProps<Theme> = {
        ['@media(max-width:650px)']: {
            height: "375px"
        },
        padding: "16px",
        border: "2px solid rgb(230, 230, 230)",
        marginBottom: "16px",
        width: "100%",
        height: "350px",
    }

    return (
        <>
            {
                !loading
                    ?
                    <>
                        {
                            conversionAnalyticsData
                                ?
                                <div className="chart-container">
                                    <Typography variant="h5" sx={{ my: 2 }}>Gráfico de conversão</Typography>
                                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                        {/* Bar Chart */}
                                        <Typography variant="subtitle1" sx={{ mb: 2 }}>Quantidade de contratos / mês</Typography>
                                        <Box sx={{ ...chartContainer }}>
                                            <Bar
                                                style={{ width: "100%", maxHeight: "350px" }}
                                                data={{
                                                    labels: labels,
                                                    datasets: datasetsBarChart.map(data => ({
                                                        ...data,
                                                    }))
                                                }}
                                                options={{ maintainAspectRatio: false }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                        {/* Doughnut Chart */}
                                        <Grid container spacing={2} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            {
                                                doughnutData.map(data => (
                                                    <Grid item sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                                                        <Doughnut
                                                            style={{width: "25%", maxHeight: "150px"}}
                                                            data={{
                                                                labels: data.labels, datasets: data.datasets
                                                            }}
                                                            options={{
                                                                plugins: {
                                                                    legend: {
                                                                        display: false,
                                                                        position: 'bottom',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                        <Typography variant="caption" sx={{mt: 1}}>{data.title}</Typography>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Box>

                                </div>
                                :
                                <Alert variant="outlined" severity="info" sx={{ p: 4, mt: 4 }}>Não há dados para serem buscados com os parâmetros de busca utilizados</Alert>
                        }
                    </>
                    :
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 2
                    }}>
                        <Skeleton variant="rectangular" animation="wave" width="100%" height={150} />
                        <Skeleton variant="rectangular" animation="wave" width="100%" height={550} sx={{ marginTop: 3 }} />
                    </Box>
            }
        </>
    )
}

export default ConversionAnalytics;