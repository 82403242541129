import { AlternateEmail, Business } from "@mui/icons-material";
import { Autocomplete, Box, Button, FormControl, FormHelperText, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import AuthenticationContext from "../../../../../../contexts/authentication";
import { ContractPartie, ContractPartyIdentification, IdentificationType } from "../../../../../../models/contracts";
import { AccountTypes } from "../../../../../../models/user";
import AuthorizationService from "../../../../../../services/authorization";
import SubusersService from "../../../../../../services/subusers";
import TeamsService from "../../../../../../services/teams";
import ErrorWrapper from "../../../../../../utils/ErrorWrapper";
import { ContractPartiesSuggestions, EditorSearchResult, EditorSearchResultType } from "../../../../../../utils/editorUtils";
import SearchUsers from "../../../../../../components/SearchUsers";
import OrganizationService from "../../../../../../services/organization";

// Interfaces and Types
interface PartyFormProps extends React.ComponentProps<any> {
    currentCP?: ContractPartyIdentification | null,
    parties: ContractPartie[],
    addParty: (contractParty: ContractPartyIdentification) => void,
    cancelAction: () => void,
}

interface PartyIdentification {
    value: IdentificationType,
    label: string
}

// Default variables values
const dummyNewCP: ContractPartyIdentification = {
    email: "",
    identificationType: null,
    role: "",
    identification: undefined,
    comment: ""
}

const partyIdentificationOptions: PartyIdentification[] = [
    {
        value: IdentificationType.IDENTIFIED_BY_EMAIL,
        label: "Identificado por E-mail"
    },
    {
        value: IdentificationType.PLATFORM_USER,
        label: "Membro da Organização"
    }
]

const PartyForm = (props: PartyFormProps): JSX.Element => {

    // Destructure props object
    const { currentCP, parties, addParty, cancelAction } = props;

    // Contexts
    const auth = useContext(AuthenticationContext);

    // Data states
    const [newCP, setNewCP] = useState<ContractPartyIdentification>(currentCP ? currentCP : dummyNewCP);
    const [newCPErrors, setNewCPErrors] = useState<any>({});
    const [partyRole, setPartyRole] = useState("");
    const [selectedMember, setSelectedMember] = useState("");

    // UseEffects
    useEffect(() => {
        fetchOrganizationMember();
    }, [])

    useEffect(() => {
        checkForErrors();
    }, [newCP]);

    // Functions
    function checkForErrors() {

        const newErrors = { ...newCPErrors }

        // delete all errors
        for (const key in newErrors) {
            delete newErrors[key];
        }

        if (currentCP?.role !== newCP.role) {

            //check if a party with the same role exists
            //if it does display error
            const filter = parties.filter(party => party.role.toLocaleLowerCase() === newCP.role.toLocaleLowerCase());
            if (filter.length > 0) newErrors["role"] = "Participante já adicionado";
            else if (newCP.role.length === 0) newErrors["role"] = "Campo obrigatório"
        }

        // check if the identificationType is present
        if (!newCP.identificationType) newErrors["identificationType"] = "Selecione uma opção de assinatura";

        // check if the identificationType is a platform user and if the identification is present
        // if the identificationType is not platform user there is no need to check if the identification is present
        if (newCP.identificationType !== IdentificationType.IDENTIFIED_BY_EMAIL && !newCP.identification) newErrors["identification"] = "Selecione um membro"

        setNewCPErrors(newErrors);
    }

    function fetchOrganizationMember() {
        if (!currentCP || !currentCP.identification) return;


        if (currentCP.identification.uuid) {
            if (auth.user().account.uuid === currentCP.identification.uuid) {
                setSelectedMember(`${auth.user().account.preferredName} (Você)`);
            }
            OrganizationService.fetchOrganizationMemberByUuid(currentCP.identification.uuid)
                .then(response => {
                    setSelectedMember(response.preferredName);
                })
                .catch(e => {
                    console.error(e);

                });
        } else if (currentCP.identification.teamId) {
            TeamsService.fetchTeamByGuid(currentCP.identification.teamId)
                .then(response => {
                    setSelectedMember(response.name);
                })
                .catch(e => console.error(e));
        }
    }

    // Handler functions
    function handleChangeContractPartyIdentification(newValue: string | null) {
        if (!newValue) {
            setPartyRole("");
            return;
        }

        let role = newValue.trim();

        const filter = parties.filter(party => party.role.includes(role));

        if (filter.length === 0) setPartyRole(role);
        else {
            role = `${role} ${filter.length + 1}`
            setPartyRole(role);
        }
    }

    function handleChangeIdentificationType(event: SelectChangeEvent<any>) {

        const identification = event.target.value;

        if (!identification) return;

        setNewCP({ ...newCP, identificationType: identification });
    }

    function handleAutocompleteTextFieldChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setSelectedMember(e.target.value);
    }

    function handleSelectMember(option: EditorSearchResult) {
        switch (option.type) {
            case EditorSearchResultType.ORGANIZATION_ACCOUNT:
            case EditorSearchResultType.ORGANIZATION_SUBUSER_ACCOUNT:
                setNewCP({ ...newCP, identification: { uuid: option.id }, identificationType: IdentificationType.ORGANIZATION_USER });
                break;
            case EditorSearchResultType.ORGANIZATION_TEAM:
                setNewCP({ ...newCP, identification: { teamId: option.id }, identificationType: IdentificationType.ORGANIZATION_TEAM });
                break;
        }

        setSelectedMember(option.description);
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                p: 4,
                border: "1px solid rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
                mt: 2,
                mb: 3
            }}
        >
            <Autocomplete
                fullWidth
                value={newCP.role}
                options={ContractPartiesSuggestions}
                groupBy={(option) => "Sugestões de Participantes"}
                onChange={(event: any, newValue: string | null) => handleChangeContractPartyIdentification(newValue)}
                onBlur={() => {
                    setNewCP({ ...newCP, role: partyRole.trim() });
                }}
                inputValue={partyRole}
                onInputChange={(event, newInputValue) => {
                    setPartyRole(newInputValue);
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label="Identificação do Participante"
                        required
                        error={Boolean(newCPErrors["role"])}
                        helperText={newCPErrors["role"]}
                    />
                }
            />
            <FormControl fullWidth sx={{ my: 6 }} required error={Boolean(newCPErrors["identificationType"])} >
                <InputLabel id="party-identication-type" >Como o contrato será assinado</InputLabel>
                <Select
                    labelId="party-identication-type"
                    label="Como o contrato será assinado"
                    fullWidth
                    value={newCP.identificationType}
                    renderValue={(value) => {
                        switch (value) {
                            case IdentificationType.IDENTIFIED_BY_EMAIL:
                                return "Identificado por E-mail"
                            default:
                                return "Membro da Organização";
                        }
                    }}
                    placeholder="Como o contrato será assinado"
                    onChange={handleChangeIdentificationType}
                    startAdornment={
                        newCP.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                            ?
                            <AlternateEmail sx={{ mr: 2 }} color="primary" />
                            :
                            newCP.identificationType
                                ?
                                <Business sx={{ mr: 2 }} color="primary" />
                                :
                                <></>

                    }
                    required
                    error={Boolean(newCPErrors["identificationType"])}
                >
                    {
                        partyIdentificationOptions.map(option => (
                            <MenuItem value={option.value} key={option.value}>
                                {
                                    option.value === IdentificationType.IDENTIFIED_BY_EMAIL
                                        ?
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                            <ListItemIcon>
                                                <AlternateEmail sx={{ mr: 2 }} />
                                            </ListItemIcon>
                                            <ListItemText >{option.label}</ListItemText>
                                        </Box>
                                        :
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                            <ListItemIcon>
                                                <Business sx={{ mr: 2 }} />
                                            </ListItemIcon>
                                            <ListItemText >{option.label}</ListItemText>
                                        </Box>
                                }
                            </MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText>{newCPErrors["identificationType"] ? newCPErrors["identificationType"] : ""}</FormHelperText>
            </FormControl>
            {
                newCP.identificationType && newCP.identificationType !== IdentificationType.IDENTIFIED_BY_EMAIL
                    ?
                    <SearchUsers
                        errors={newCPErrors}
                        inputChange={handleAutocompleteTextFieldChange}
                        onResourceClick={handleSelectMember}
                        value={selectedMember}
                    />
                    :
                    <></>
            }
            <TextField
                fullWidth
                multiline
                minRows={3}
                maxRows={5}
                label="Adicionar comentário para o participante"
                value={newCP.comment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewCP({ ...newCP, comment: event.target.value })}
                inputProps={{ maxLength: 1500 }}
                helperText={`${newCP.comment ? newCP.comment.length : 0} / 1500`}
                sx={{ mb: 3 }}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                <Button variant="text" onClick={cancelAction} >Cancelar</Button>
                <Button variant="contained" disabled={Object.keys(newCPErrors).length > 0} onClick={() => addParty(newCP)} >Salvar</Button>
            </Box>
        </Box>
    );
}

export default PartyForm;