import { AccountBox, Close, Delete } from "@mui/icons-material";
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Skeleton, SxProps, Theme, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import GlobalSearchBar from "../../components/GlobalResourceSearchBar";
import { LoadingButton } from "../../components/LoadingButton";
import AuthenticationContext from "../../contexts/authentication";
import { ContactInformation } from "../../models/contacts";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../redux/hooks";
import ContactsService from "../../services/contacts";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { RolesContext } from "../../contexts/roles";
import { AccountRoles } from "../../models/user";
import { Link, useNavigate } from "react-router-dom";
import { SearchResultIdentification } from "../../models/search-result";

interface DeleteDialogProps extends React.ComponentProps<any> {
    contact: ContactInformation | undefined
}

const Contacts = (): JSX.Element => {

    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Contatos"
    }, []);

    // Contexts
    const authUser = useContext(AuthenticationContext);
    const userRoles = useContext(RolesContext);
    const notification = useAppDispatch();
    const navigate = useNavigate();

    // Default values
    const limit = 10;

    // Loading states
    const [contactsLoading, setContactsLoading] = useState(true);

    // Data states
    const [contacts, setContacts] = useState<ContactInformation[]>([]);
    const [contactsPage, setContactsPage] = useState(1);
    const [contactsTotalPages, setContactsTotalPages] = useState(0);
    const [selectedContact, setSelectedContact] = useState<ContactInformation | undefined>(undefined);

    // State
    const [showDeleteButton, setShowDeleteButton] = useState<number | null>(null);

    // Open States
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    // Use Effects
    useEffect(() => {
        fetchContacts();
    }, [])

    // Functions

    async function fetchContacts() {
        setContactsLoading(true)
        try {
            const pagination = await ContactsService.fetchContactsPagination(limit);
            setContactsTotalPages(pagination.totalPages);

            ContactsService.fetchContactsWithPagination(contactsPage, limit)
                .then(response => {
                    setContacts(response);
                })
                .catch(e => {
                    const err = new ErrorWrapper(e);
                    notification(show({ type: "error", message: err.message }))
                })
                .finally(() => setContactsLoading(false));

        } catch (e) {
            const err = new ErrorWrapper(e);
            notification(show({ type: "error", message: err.message }));
        }
    }

    function handleMouseEnter(index: number) {
        setShowDeleteButton(index);
    };

    function handleMouseLeave() {
        setShowDeleteButton(null);
    };

    function handleOpenDialog(contact: ContactInformation) {
        setSelectedContact(contact);
        setOpenDeleteDialog(true)
    }

    function handleGoToContact(contact: ContactInformation) {
        navigate(`/organization/contacts/${contact.id}`);
    }

    function triggerSearchResultAction(resource: SearchResultIdentification) {
        navigate(resource.resourceId);
    }

    function fetchMoreContacts() {
        setContactsLoading(true)

        if (contactsPage >= contactsTotalPages) return;

        ContactsService.fetchContactsWithPagination(contactsPage + 1, limit)
            .then(response => {
                setContacts([...contacts, ...response]);
            })
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({ type: "error", message: err.message }))
            })
            .finally(() => setContactsLoading(false));
        setContactsPage(contactsPage + 1)
    }

    // Styles

    const contactListItemTheme: SxProps<Theme> = {
        mb: 3,
        border: '1px solid #EBEBEB',
        borderRadius: "5px",
        transition: 'all 0.3s',
        '&:hover': {
            backgroundColor: '#DDDDDD',
            cursor: "pointer"
        },
    };

    // Components

    const DeleteContactDialog = (props: DeleteDialogProps): JSX.Element => {
        // Destructure props object
        const { contact } = props;

        // Loading States
        const [deleteLoading, setDeleteLoading] = useState(false);

        // Functions

        function handleDeleteContact(contactId: string) {

            if (!userRoles.assertIsOrganizationOrAdministratorOr(authUser, AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER)) {
                notification(show({ type: "error", message: "Você não pode realizar esta ação" }));
                return;
            }

            setDeleteLoading(true)
            ContactsService.deleteContact(contactId)
                .then(response => {
                    const updatedContacts = contacts.filter(c => c.id !== contactId)
                    setContacts(updatedContacts);
                })
                .catch(e => {
                    const err = new ErrorWrapper(e);
                    notification(show({ type: "error", message: err.message }));
                })
                .finally(() => {
                    notification(show({ type: "success", message: "Contato deletado com sucesso" }));
                    setOpenDeleteDialog(false);
                    setDeleteLoading(false);
                })
        }

        return (
            <>
                {
                    contact
                        ?
                        <Dialog
                            open={openDeleteDialog}
                            onClose={() => setOpenDeleteDialog(false)}
                        >
                            <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", my: 2, mx: 1 }}>
                                <Typography variant="h6" color="primary.main">Deletar contato: {contact.name}</Typography>
                                <IconButton onClick={() => setOpenDeleteDialog(false)}>
                                    <Close />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>Você realmente deseja deletar esse contato?</Typography>
                                <Typography variant="body2" >Ao fazer isso você perderá qualquer informação adicionada neste contato</Typography>
                            </DialogContent>
                            <DialogActions sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", m: 2 }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => setOpenDeleteDialog(false)}
                                >
                                    Cancelar
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    color="error"
                                    onClick={() => handleDeleteContact(contact.id)}
                                    loading={deleteLoading}
                                >
                                    Deletar
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                        :
                        <></>
                }
            </>
        );
    }

    return (
        <Container sx={{ mt: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                <Typography variant="h6" color="primary.main" >Lista de Contatos</Typography>
                <GlobalSearchBar searchLimiter="CONTACT" onResourceClick={triggerSearchResultAction} style="black" />
            </Box>
            {
                (!contactsLoading)
                    ?
                    (contacts.length ==  0)
                    ?
                    <Alert color="info" sx={{mt : 4}}>
                        Os contatos são adicionados automaticamente na plataforma toda vez que um contrato 
                        emitido por sua organização é assinado por todas as partes. 
                        <Typography variant="caption" sx={{mt : 2, display: 'block'}}>
                            Contatos providos de contratos assinados antes do lançamento desta funcionalidade não serão adicionados aqui automaticamente. 
                            Caso você deseje adicionar os participantes de contratos antigos como contatos veja nosso 
                            tutorial <a href="https://help.cartorizi.com/kb/article/431677/lista-de-contatos" target="_blank">
                                clicando neste link
                            </a>.
                        </Typography>

                        <Typography variant="caption" sx={{mt : 2, display: 'block'}}>
                            Para emitir um contrato acesse <Link to="/contracts/create">esta página</Link>.
                        </Typography>
                    </Alert>
                    :
                    <>
                        <List>
                            {
                                contacts.map((contact, index) => {
                                    return (
                                        <ListItem
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={() => handleMouseLeave()}
                                            sx={contactListItemTheme}
                                            key={contact.id}
                                        >
                                            <Box display={"flex"} alignItems={"center"} sx={{width: "100%"}} onClick={() => handleGoToContact(contact)}>
                                                <ListItemAvatar>
                                                    <AccountBox color="primary" sx={{ fontSize: "48px" }} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={contact.name}
                                                    secondary={contact.customIdentificationField ? contact.customIdentificationField : null}
                                                />
                                            </Box>
                                            {
                                                (showDeleteButton === index &&
                                                    userRoles.assertIsOrganizationOrAdministratorOr(authUser, AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER))
                                                    ?
                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleOpenDialog(contact)}>
                                                        <Delete color="error" />
                                                    </IconButton>
                                                    :
                                                    <></>
                                            }
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                        {
                            (contactsTotalPages > 1 && contactsPage < contactsTotalPages)
                                ?
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%", my: 2 }}>
                                    <Button variant="text" color="primary" onClick={fetchMoreContacts}>
                                        Buscar mais
                                    </Button>
                                </Box>
                                :
                                <></>
                        }
                    </>
                    :
                    <>
                        <Skeleton sx={{ height: "136px" }} />
                        <Skeleton sx={{ height: "136px" }} />
                        <Skeleton sx={{ height: "136px" }} />
                        <Skeleton sx={{ height: "136px" }} />
                        <Skeleton sx={{ height: "136px" }} />
                    </>
            }
            <DeleteContactDialog contact={selectedContact} />
        </Container>
    );
}

export default Contacts;