import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import { AnalyticsProps } from "./Analytics";
import moment from "moment";
import { DataGranularity, NormalizedGranularityAndDataset } from "../../../../models/analytics";
import OrganizationService from "../../../../services/organization";
import { Alert, Box, Divider, IconButton, Paper, Skeleton, SxProps, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Theme, Typography } from "@mui/material";
import { fromRegionalValue, SupportedCurrencies } from "../../../../utils/currency";
import React from "react";
import { Chart, Line, } from "react-chartjs-2";
import { GranularityDataset, SubscriptionChargeAnalytics } from "../../../../models/organizations";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { EventRepeat, FindInPage, Timeline } from "@mui/icons-material";
import FinanceService, { SubscriptionCharge } from "../../../../services/finance";
import { parseToNormalizedContract } from "../../../../models/contracts";
import { show } from "../../../../redux/features/contract-view-dialog/contract-view-dialog";
import ContractsService from "../../../../services/contracts";
import ContractNotFoundDialog from "../../../../components/ContractNotFoundDialog";

type FinancialRoles = "credited" | "debited";

enum Tabs {
    GRAPH = "GRAPH",
    STATEMENT = "STATEMENT"
}

const OrganizationSubscriptionChargesAnalytics = (props: AnalyticsProps): JSX.Element => {

    // Destructure the props object
    const { digitalContractTemplate, digitalContractTemplates, startDate, endDate, issuerId, tag } = props;

    // Redux dispatcher
    const dispatch = useAppDispatch();

    // Data states
    const [selectedFinancialRole, setSelectedFinancialRole] = useState<FinancialRoles>("credited");
    const [selectedDataGranularity, setSelectedDataGranularity] = useState<"YEAR" | "YEAR_AND_MONTH" | "YEAR_MONTH_AND_DAY">("YEAR_AND_MONTH");

    const [organizationSubscriptionChargesTotalAmounAnalyticsDatasets, setOrganizationSubscriptionChargesTotalAmountAnalyticsDatasets] = useState<NormalizedGranularityAndDataset[] | null>(null);
    const [organizationSubscriptionChargesTotalAmounAnalyticsLabels, setOrganizationSubscriptionChargesTotalAmounAnalyticsLabels] = useState<string[]>([]);

    const [subscriptionCharges, setSubscriptionCharges] = useState<SubscriptionCharge[] | undefined>(undefined);

    // Table states
    const [subscriptionChargeListPage, setSubscriptionChargeListPage] = useState(0);
    const [subscriptionChargeListRowsPerPage, setSubscriptionChargeListRowsPerPage] = useState(10);
    const [subscriptionChargeTotalRecords, setSubscriptionChargeTotalRecords] = useState(0);


    // Loading states
    const [loading, setLoading] = useState(true);

    // Tab states
    const [tabValue, setTabValue] = useState<Tabs>(Tabs.GRAPH);

    // Dialog states
    const [contractNotFoundDialog, setContractNotFoundDialog] = useState(false)

    // Use Effects

    useEffect(() => {
        fetchOrganizationSubscriptionChargesTotalAmountAnalytics();
        fetchSubscriptionCharges();
    }, [])

    // Functions

    /**
     * fetch the total amount of subscription charges
     */
    function fetchOrganizationSubscriptionChargesTotalAmountAnalytics() {
        setLoading(true);

        // Get the start and end date in a moment object
        const momentStartDate = moment(startDate);
        const momentEndDate = moment(endDate);

        // Get the difference between the start and end date in days and months
        const daysDifference = momentEndDate.diff(momentStartDate, 'days');
        const monthsDifference = momentEndDate.diff(momentStartDate, 'months')

        let dateGranularity: DataGranularity = "YEAR_AND_MONTH"

        // Check if there is less than 31 days or 13 months in difference between the two dates
        if (daysDifference < 31) {
            //dateGranularity = "YEAR_MONTH_AND_DAY" // commented as there is no for this granularity
        } else if (monthsDifference > 13) {
            dateGranularity = "YEAR"
        }

        // request the data
        OrganizationService.fetchOrganizationSubscriptionChargesTotalAmountAnalytics(
            selectedFinancialRole,
            startDate,
            endDate,
            dateGranularity,
            digitalContractTemplate?.id,
            null,
            issuerId
        ).then((response) => {
            // if it's null just return null
            if (response === null) return null;

            let labels: string[] = [];

            // iterate over each data from the API to extract the labels...
            // iterate over each 'currency' granularity
            Object.keys(response).forEach(currencyKey => {



                // grab the first key to use as a start
                const keys = Object.keys(response[currencyKey]);
                keys.sort(); // Sort the keys in ascending order (oldest to newest).
                const firstKey = keys[0];
                const firstKeyMoment = moment(firstKey);

                // Determine the granularity based on the provided dateGranularity
                let granularity: moment.unitOfTime.StartOf = 'year'; // Default to 'year'

                if (dateGranularity === "YEAR_AND_MONTH") {
                    granularity = 'month';
                } else if (dateGranularity === "YEAR_MONTH_AND_DAY") {
                    granularity = 'day';
                }

                // Loop through dates from start date to limit date based on granularity
                while (firstKeyMoment.isSameOrBefore(momentEndDate, granularity)) {

                    // format the key to the detected granularity
                    const key = firstKeyMoment.format(granularity === 'year' ? 'YYYY' : granularity === 'month' ? 'YYYY-MM' : 'YYYY-MM-DD')

                    if (!labels.includes(key)) {
                        labels.push(key)
                    }

                    // increment the date to generate the next key
                    firstKeyMoment.add(1, granularity);
                }
            });

            // sort the labels and set it on the state
            labels = labels.sort();

            setOrganizationSubscriptionChargesTotalAmounAnalyticsLabels(labels);

            // this part will create an normalized object that the front-end can easily 
            // manage the data provided from the API...  
            const currencyAndDatasets: NormalizedGranularityAndDataset[] = [];

            // iterate over each currency layer from the response (BRL, USD)
            for (const currencyKey in response) {

                const updatedDataset: GranularityDataset<SubscriptionChargeAnalytics> = {};

                // create the normalized currency and dataset
                const datasetFromCurrencyGranularity = response[currencyKey];
                const currencyAndDataset: NormalizedGranularityAndDataset = {
                    currency: currencyKey,
                    dataset: []
                };

                // iterate over each sorted label....
                labels.forEach(label => {

                    const datasetObject = datasetFromCurrencyGranularity[label];

                    // ensure that there is a object
                    if (datasetObject) {

                        datasetObject.forEach(d => {
                            // get the dates for the endDate of the subscription charge
                            const momentObjEndDate = moment(d.endDate);

                            // get the label that references the initial billing period of the subscription charge
                            const momentKey = moment(label)

                            // Determine the granularity based on the provided dateGranularity
                            let granularity: moment.unitOfTime.StartOf = 'year'; // Default to 'year'

                            if (dateGranularity === "YEAR_AND_MONTH") {
                                granularity = 'month';
                            } else if (dateGranularity === "YEAR_MONTH_AND_DAY") {
                                granularity = 'day';
                            }

                            // iterate over the labels again to fill the progression dataset
                            labels.forEach(lb => {

                                // get the date for the label to compare agains the the other dates
                                const momentLabel = moment(lb);

                                // check if the label date is the same or before the subscription charge end date 
                                //and if the label date is the same or after the initial billing period date
                                if (momentLabel.isSameOrBefore(momentObjEndDate, granularity) && momentLabel.isSameOrAfter(momentKey, granularity)) {

                                    // check if the object doesn't exist
                                    // if it's true add the object to the updated dataset
                                    // if it's false update the current object
                                    if (updatedDataset[label] === undefined) {

                                        updatedDataset[lb] = d

                                    } else {

                                        const currentObject = updatedDataset[lb]

                                        // Check to see if there is no object with the current lb
                                        // if it's true set to the d
                                        // if it's false update the current lb object
                                        if (currentObject === undefined) {
                                            updatedDataset[lb] = d
                                        } else {
                                            updatedDataset[lb] = {
                                                currencyValue: {
                                                    currency: currentObject.currencyValue.currency,
                                                    value: currentObject.currencyValue.value + d.currencyValue.value,
                                                    regionalValue: currentObject.currencyValue.regionalValue + d.currencyValue.regionalValue,
                                                    regionalString: fromRegionalValue(currentObject.currencyValue.regionalValue + d.currencyValue.regionalValue, currencyKey as SupportedCurrencies).regionalValueString
                                                },
                                                endDate: (currentObject.endDate > d.endDate) ? currentObject.endDate : d.endDate,
                                                totalEntries: currentObject.totalEntries + d.totalEntries
                                            }
                                        }

                                    }
                                }
                            })

                        })

                    }
                });

                // iterate over the label to generate the normalized dataset
                labels.forEach(label => {
                    // if the currenct dataset granularity does not have the label, push an empty value
                    if (!updatedDataset[label]) {
                        currencyAndDataset.dataset.push({
                            presentation: "--",
                            value: 0,
                        })
                    }
                    // otherwise push the value from the API
                    else {
                        currencyAndDataset.dataset.push({
                            presentation: updatedDataset[label].currencyValue.regionalString,
                            value: updatedDataset[label].currencyValue.regionalValue,
                            count: updatedDataset[label].totalEntries,
                        })
                    }
                })

                // include the normalized dataset into the array
                currencyAndDatasets.push(currencyAndDataset);
            }

            // update the view state
            setOrganizationSubscriptionChargesTotalAmountAnalyticsDatasets(currencyAndDatasets);
        })
            .finally(() => setLoading(false))
    }

    /**
     * fetch the user subscription charges
     * @param currentPage - the number of the page to be fetched
     * @param limit - the limit of objects to fetch
     */
    async function fetchSubscriptionCharges(currentPage: number = 1, limit: number = 10) {

        const subscriptionChargePagination = await FinanceService.fetchSubscriptionChargesPagination("credited", startDate.getTime(), endDate.getTime(), limit, digitalContractTemplate?.id);

        setSubscriptionChargeTotalRecords(subscriptionChargePagination.totalRecords);

        FinanceService.fetchSubscriptionCharges("credited", startDate.getTime(), endDate.getTime(), currentPage, limit, digitalContractTemplate?.id).then(response => {
            if (issuerId) {
                const filteredData = response.filter((c) =>
                    c.debitedAccount.uuid === issuerId
                );
                setSubscriptionCharges(filteredData);
            }
            else {
                setSubscriptionCharges(response);
            }
        });

    }

    /**
     * This function will render an dataset value label
     * @param gDataset 
     * @param fontColor 
     * @returns 
     */
    function TotalDatasetValueLabel(gDataset: NormalizedGranularityAndDataset, fontColor: string = "primary"): JSX.Element {
        // sum the values inside the normalized dataset
        let total = 0;
        gDataset.dataset.forEach(d => {
            total += d.value;
        });

        let value = fromRegionalValue(total, gDataset.currency as SupportedCurrencies).regionalValueString;

        // return the rendered component
        return (
            <Box>
                <Typography variant="h4" color={fontColor}>
                    {gDataset.currency}: <b>{value}</b>
                </Typography>
            </Box>
        )
    }

    /**
     * handles the user changing tabs
     * @param event 
     * @param value 
     */
    function handleTabChange(event: React.SyntheticEvent, value: Tabs) {
        setTabValue(value);
    }

    /**
     * handle the user changing the page on the subscription charge table
     * @param event 
     * @param newPage 
     */
    function handleChangeSubscriptionChargesPage(event: unknown, newPage: number) {
        // To fetch susbscription charges need to pass newPage + 1 because the pagination of the table starts at 0
        // and the pagination of the back-end starts at 1
        fetchSubscriptionCharges(newPage + 1, subscriptionChargeListRowsPerPage)
        setSubscriptionChargeListPage(newPage)
    }

    function handleOpenContract(event: React.MouseEvent<HTMLButtonElement>, contractId: string): void {
        fetchContractAndOpenContractDialog(contractId)
    }

    // function used to fetch the contract and open the dialog of the contract
    async function fetchContractAndOpenContractDialog(contractId: string): Promise<void> {
        try {
            // try to find in the editable contract
            try {
                let contract = await ContractsService.findEditableDigitalContractById(contractId);
                dispatch(show({
                    contract: {
                        normalized: parseToNormalizedContract(contract),
                        source: contract
                    },
                    visible: true
                }));
                return;
            } catch (error) {
                // ignore because it will try to fetch an issued contract
            }

            // try to find in the issued contract
            try {
                let contract = await ContractsService.findIssuedDigitalContractById(contractId);
                dispatch(show({
                    contract: {
                        normalized: parseToNormalizedContract(contract),
                        source: contract
                    },
                    visible: true
                }));
                return
            } catch (error) {
                // throw error
                throw error;
            }

        } catch (error) {
            setContractNotFoundDialog(true)

        }
    }

    /**
     * Generate the datasets for the graph
     */
    function generateDatasets() {
        if (!organizationSubscriptionChargesTotalAmounAnalyticsDatasets) return [];

        const dataset: any[] = []

        // iterate over the dataset
        organizationSubscriptionChargesTotalAmounAnalyticsDatasets.forEach(d => {
            // dataset representing the value of the charges
            const recurringChargesValue = {
                type: "line" as const,
                label: d.currency,
                data: d.dataset.map(ds => ds.value),
                yAxisID: "line-y-axis",
                backgroundColor: "rgba(154, 208, 245, 0.75)",
                borderColor: "rgba(54, 162, 235, 1)",
            }


            const newRecurringChargesData: any[] = []
            const totalRecurringChargesData: any[] = []

            // the previous number of recurring charges
            let previous = d.dataset[0].count || 0

            // the total recurring charges
            let total = 0;

            d.dataset.forEach(d => {
                // if count is undefined just return
                if (!d.count) return;
                total += d.count;
                totalRecurringChargesData.push(total)
                newRecurringChargesData.push(d.count - previous)
                previous = d.count
            })

            // dataset representing the new or lost recurring charges
            const newRecurringChargesDataset = {
                type: "bar" as const,
                label: `${d.currency} - Recorrência nova ou encerrada neste período`,
                // data: d.dataset.map(ds => ds.count),
                data: newRecurringChargesData,
                yAxisID: "bar-y-axis",
                borderWidth: 4,
                backgroundColor: "rgba(213, 99, 117, 0.75)",
                borderColor: "rgba(171, 55, 65, 1)",
            }

            // dataset representing the total recurring charges in the period
            const totalRecurringChargesDataset = {
                type: "bar" as const,
                label: `${d.currency} - Recorrências neste período`,
                data: d.dataset.map(ds => ds.count),
                yAxisID: "bar-y-axis",
                borderWidth: 4,
                backgroundColor: "rgba(99, 213, 117, 0.75)",
                borderColor: "rgba(55, 171, 65, 1)",
            }


            // dataset representing the accumulated total of recurring charges
            const totalDataset = {
                type: "line" as const,
                label: `${d.currency} - Recorrências acumuladas`,
                data: totalRecurringChargesData,
                yAxisID: "accum-y-axis",
                backgroundColor: "rgba(255, 112, 67, 0.75)",
                borderColor: "rgba(216, 67, 21, 1)",
            }

            dataset.push(recurringChargesValue, newRecurringChargesDataset, totalRecurringChargesDataset, totalDataset)
        })

        return dataset;
    }

    // Styling

    const chartContainer: SxProps<Theme> = {
        '@media(max-width:650px)': {
            height: "475px"
        },
        padding: "16px",
        border: "2px solid rgb(230, 230, 230)",
        marginBottom: "16px",
        width: "100%",
        height: "650px",
    };

    return (
        <React.Fragment>
            {
                !loading
                    ?
                    <React.Fragment>
                        {
                            organizationSubscriptionChargesTotalAmounAnalyticsDatasets
                                ?
                                <React.Fragment>
                                    <div className="chart-container">
                                        <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Total de recorrências no período selecionado</Typography>
                                        {
                                            organizationSubscriptionChargesTotalAmounAnalyticsDatasets.map(gDataset => (
                                                <React.Fragment>
                                                    {TotalDatasetValueLabel(gDataset)}
                                                    <Divider />
                                                </React.Fragment>

                                            ))
                                        }
                                    </div>
                                    <TabContext value={tabValue}>

                                        <TabList onChange={handleTabChange}>
                                            <Tab icon={<Timeline />} iconPosition="start" label="Gráfico" value={Tabs.GRAPH} />
                                            <Tab icon={<Timeline />} iconPosition="start" label="Extrato" value={Tabs.STATEMENT} />
                                        </TabList>

                                        {/* Tab for the graph component */}
                                        <TabPanel value={Tabs.GRAPH}>
                                            <Box sx={chartContainer}>
                                                <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Progressão de recorrências no periodo</Typography>
                                                <Chart
                                                    style={{ width: "100%", maxHeight: "600px" }}
                                                    type="bar"
                                                    data={{
                                                        labels: organizationSubscriptionChargesTotalAmounAnalyticsLabels,
                                                        datasets: generateDatasets()
                                                    }}
                                                    options={{
                                                        maintainAspectRatio: false,
                                                        scales: {
                                                            'bar-y-axis': {
                                                                type: 'linear',
                                                                position: 'left',
                                                                title: {
                                                                    display: true,
                                                                    text: 'Recorrências no período',
                                                                },
                                                            },
                                                            'line-y-axis': {
                                                                type: 'linear',
                                                                position: 'right',
                                                                title: {
                                                                    display: true,
                                                                    text: 'Valor',
                                                                },
                                                            },
                                                            'accum-y-axis': {
                                                                type: 'linear',
                                                                position: 'left',
                                                                title: {
                                                                    display: true,
                                                                    text: 'Recorrências acumuladas',
                                                                },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </TabPanel>

                                        { /* Tab for the statement component */}
                                        <TabPanel value={Tabs.STATEMENT}>
                                            <Typography sx={{ display: "flex", alignItems: "center", mt: "20px", mb: 1 }}><EventRepeat /> Cobranças recorrentes</Typography>

                                            {
                                                subscriptionCharges
                                                    ?
                                                    <Paper>
                                                        <TableContainer>
                                                            <Table stickyHeader >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <Typography><b>Nome da recorrência</b></Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography><b>Início</b></Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography><b>Fim</b></Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography><b>Dia do faturamento</b></Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography><b>Valor</b></Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography><b>Visualizar contrato</b></Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        subscriptionCharges
                                                                            .map((sc, index) => {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell>
                                                                                            <Typography>{sc.name}</Typography>
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <Typography>{moment(sc.initialBillingPeriod).format("L")}</Typography>
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <Typography>{sc.finalBillingPeriod ? moment(sc.finalBillingPeriod).format("L") : "-"}</Typography>
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <Typography>{sc.billingDueDay}</Typography>
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <Typography>{fromRegionalValue(sc.value / 100, sc.currency as SupportedCurrencies).regionalValueString}</Typography>
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <IconButton color='primary' onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                                                                if (!sc.licenseId) { sc.licenseId = "null" }
                                                                                                handleOpenContract(e, sc.licenseId)
                                                                                            }}
                                                                                                sx={{ mr: "40px" }}
                                                                                            >
                                                                                                <FindInPage fontSize="small" />
                                                                                            </IconButton>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            })
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        <TablePagination
                                                            rowsPerPageOptions={[10]}
                                                            component="div"
                                                            count={subscriptionChargeTotalRecords}
                                                            rowsPerPage={subscriptionChargeListRowsPerPage}
                                                            page={subscriptionChargeListPage}
                                                            onPageChange={handleChangeSubscriptionChargesPage}
                                                            labelRowsPerPage="Linhas por página"
                                                        />
                                                    </Paper>
                                                    :
                                                    <></>
                                            }
                                        </TabPanel>

                                    </TabContext>
                                </React.Fragment>
                                :
                                <Alert variant="outlined" severity="info" sx={{ p: 4, mt: 4 }}>Não há dados para serem buscados com os parâmetros de busca utilizados</Alert>
                        }
                    </React.Fragment>
                    :
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 2
                    }}>
                        <Skeleton variant="rectangular" animation="wave" width="100%" height={150} />
                        <Skeleton variant="rectangular" animation="wave" width="100%" height={550} sx={{ marginTop: 3 }} />
                    </Box>
            }
            <ContractNotFoundDialog open={contractNotFoundDialog} close={() => setContractNotFoundDialog(false)} />
        </React.Fragment>
    )
}

export default OrganizationSubscriptionChargesAnalytics;