import { Close, DateRange, Download, EventRepeat, FindInPage, ReportProblemRounded, Subject, Timeline } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Alert, Box, Button, Dialog, DialogTitle, Divider, Grid, IconButton, Paper, Skeleton, SxProps, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Theme, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { parseToNormalizedContract } from "../../../../models/contracts";
import { show } from "../../../../redux/features/contract-view-dialog/contract-view-dialog";
import { useAppDispatch } from "../../../../redux/hooks";
import ContractsService from "../../../../services/contracts";
import FinanceService, { FinanceCharge, SubscriptionCharge } from "../../../../services/finance";
import OrganizationService from "../../../../services/organization";
import { SupportedCurrencies, fromRegionalValue } from "../../../../utils/currency";
import { AnalyticsProps } from "./Analytics";
import ExportFinancialManagementData from "./ExportFinancialManagementData";
import moment from "moment";
import { NormalizedGranularityAndDataset, DataGranularity } from "../../../../models/analytics";

type FinancialRoles = "credited" | "debited"

type DatasetsDataType = "sum" | "count"

interface FinancialRoleOption {
    label: string,
    value: FinancialRoles,
}

interface ExcelCell {
    value: number,
    label: string
}

const FinancialRolesOptions: FinancialRoleOption[] = [
    {
        label: "Entradas",
        value: "credited"
    },
    {
        label: "Saidas",
        value: "debited"
    }
]

interface TextAndIcon {
    text: string;
    icon: JSX.Element;
}

const OrganizationFinanceChargesAnalytics = (props: AnalyticsProps): JSX.Element => {
    //states
    const [selectedFinancialRole, setSelectedFinancialRole] = useState<FinancialRoles>("credited");
    const [granularity, setGranularity] = useState<"YEAR" | "YEAR_AND_MONTH" | "YEAR_MONTH_AND_DAY">("YEAR_AND_MONTH");
    const [organizationFinanceChargesTotalAmounAnalyticsDatasets, setOrganizationFinanceChargesTotalAmountAnalyticsDatasets] = useState<NormalizedGranularityAndDataset[] | null>(null);
    const [organizationFinanceChargesTotalAmounAnalyticsLabels, setOrganizationFinanceChargesTotalAmounAnalyticsLabels] = useState<string[]>([]);
    const [organizationFinanceChargesTotalAmountCountDatasets, setOrganizationFinanceChargesTotalAmountCountDatasets] = useState<NormalizedGranularityAndDataset[] | null>(null);
    const [organizationFinanceChargesTotalAmountCountLabels, setOrganizationFinanceChargesTotalAmountCountLabels] = useState<string[]>([]);
    const [financeCharges, setFinanceCharges] = useState<FinanceCharge[] | undefined>(undefined);
    const [subscriptionCharges, setSubscriptionCharges] = useState<SubscriptionCharge[] | undefined>(undefined);
    const [contractNotFoundDialog, setContractNotFoundDialog] = useState(false)
    const [excelCells, setExcelCells] = useState<ExcelCell[] | null>(null);

    const [financeChargeListPage, setFinanceChargeListPage] = useState(0)
    const [subscriptionChargeListPage, setSubscriptionChargeListPage] = useState(0)
    const [financeChargeListRowsPerPage, setFinanceChargeListRowsPerPage] = useState(10)
    const [subscriptionChargeListRowsPerPage, setSubscriptionChargeListRowsPerPage] = useState(10)

    const [financeChargeTotalRecords, setFinanceChargeTotalRecords] = useState(0)
    const [subscriptionChargeTotalRecords, setSubscriptionChargeTotalRecords] = useState(0)

    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);

    // Redux dispatcher
    const dispatch = useAppDispatch();

    //tab state
    const [tabValue, setTabValue] = useState("1");
    const handleTabChangeValue = (event: React.SyntheticEvent, value: string) => {
        setTabValue(value);
    };

    // Call the export finance management data
    const handleExportClick = () => {
        setExporting(true);
    }
    const handleExportComplete = () => {
        setExporting(false);
    }

    const chartContainer: SxProps<Theme> = {
        ['@media(max-width:650px)']: {
            height: "475px"
        },
        padding : "16px",
        border: "2px solid rgb(230, 230, 230)",
        marginBottom: "16px",
        width: "100%",
        height: "400px",
    }

    // Create the normalized excel cells to export
    useEffect(() => {
        if (organizationFinanceChargesTotalAmounAnalyticsDatasets) {
            const newCells = organizationFinanceChargesTotalAmounAnalyticsDatasets[0].dataset.map(
                (data, index) => {
                    const value = data.value;
                    const label = organizationFinanceChargesTotalAmounAnalyticsLabels[index];
                    const templates = props.digitalContractTemplates;
                    return { value, label, templates };
                }
            );

            setExcelCells(newCells);
        }
    }, [organizationFinanceChargesTotalAmounAnalyticsDatasets, organizationFinanceChargesTotalAmounAnalyticsLabels]);

    // effect used to fetch the finance charges data from the API
    useEffect(() => {
        // reset the currency and dataset state to null
        setOrganizationFinanceChargesTotalAmountAnalyticsDatasets(null);
        fetchOrganizationFinanceChargesTotalAmountAnalytics();
        fetchFinanceCharges(1);
        fetchSubscriptionCharges(1);
    }, [selectedFinancialRole, granularity]);

    // fetch the user finance charges
    async function fetchFinanceCharges(currentPage: number, limit: number = 10) {
        const startDate = props.startDate.getTime();
        const endDate = props.endDate.getTime();
        const issuerId = props.issuerId;

        const financeChargePagination = await FinanceService.fetchFinanceChargesPagination("credited", startDate, endDate, limit, props.digitalContractTemplate?.id)
        setFinanceChargeTotalRecords(financeChargePagination.totalRecords)
        FinanceService.fetchFinanceCharges("credited", startDate, endDate, currentPage, limit, props.digitalContractTemplate?.id).then(response => {
            if (issuerId) {
                const filteredData = response.filter((c) =>
                    c.debitedAccount.uuid === issuerId
                );
                setFinanceCharges(filteredData)
            }
            else {
                setFinanceCharges(response)
            }
        })
    }

    // fetch the user subscription charges
    async function fetchSubscriptionCharges(currentPage: number, limit: number = 10) {
        const startDate = props.startDate.getTime();
        const endDate = props.endDate.getTime();
        const issuerId = props.issuerId;

        const subscriptionChargePagination = await FinanceService.fetchSubscriptionChargesPagination("credited", startDate, endDate, limit, props.digitalContractTemplate?.id)

        setSubscriptionChargeTotalRecords(subscriptionChargePagination.totalRecords);

        FinanceService.fetchSubscriptionCharges("credited", startDate, endDate, currentPage, limit, props.digitalContractTemplate?.id).then(response => {
            if (issuerId) {
                const filteredData = response.filter((c) =>
                    c.debitedAccount.uuid === issuerId
                );
                setSubscriptionCharges(filteredData)
            }
            else {
                setSubscriptionCharges(response)
            }
        })

    }

    function handleOpenContract(event: React.MouseEvent<HTMLButtonElement>, contractId: string): void {
        fetchContractAndOpenContractDialog(contractId)
    }

    // function used to fetch the contract and open the dialog of the contract
    async function fetchContractAndOpenContractDialog(contractId: string): Promise<void> {
        try {
            // try to find in the editable contract
            try {
                let contract = await ContractsService.findEditableDigitalContractById(contractId);
                dispatch(show({
                    contract: {
                        normalized: parseToNormalizedContract(contract),
                        source: contract
                    },
                    visible: true
                }));
                return;
            } catch (error) {
                // ignore because it will try to fetch an issued contract
            }

            // try to find in the issued contract
            try {
                let contract = await ContractsService.findIssuedDigitalContractById(contractId);
                dispatch(show({
                    contract: {
                        normalized: parseToNormalizedContract(contract),
                        source: contract
                    },
                    visible: true
                }));
                return
            } catch (error) {
                // throw error
                throw error;
            }

        } catch (error) {
            setContractNotFoundDialog(true)

        }
    }

    /**
 * 
 * @param financeCharge 
 * @returns 
 */
    function getFinanceChargeTextAndIcon(financeCharge: FinanceCharge): TextAndIcon {
        let name = undefined;
        let icon = <DateRange />;
        switch (financeCharge.chargeOrigin) {
            case "PLATFORM_CHARGE":
                name = "Cobrança";
                icon = <DateRange />;
                break;
            case "PLATFORM_SUBSCRIPTION":
                name = "Cobrança de recorrência";
                icon = <EventRepeat />;
                break;
        }
        if (financeCharge.name && financeCharge.name.trim().length > 0) {
            name = financeCharge.name;
        }

        // try to get the contract template that originated the finance charge
        const templateOfFinanceCharge =
            (props.digitalContractTemplates)
                ? props.digitalContractTemplates.find(dct => dct.id === financeCharge.productId)
                : undefined;

        // concat the template to the charge name if exists
        if (templateOfFinanceCharge) {
            name = templateOfFinanceCharge.name + " - " + name;
        }

        return {
            text: name,
            icon: icon
        }
    }

    function fetchOrganizationFinanceChargesTotalAmountAnalytics() {
        setLoading(true)

        const momentEndDate = moment(props.endDate)
        const momentStartDate = moment(props.startDate)

        const daysDifference = momentEndDate.diff(momentStartDate, 'days');
        const monthsDifference = momentEndDate.diff(momentStartDate, 'months')

        let dateGranularity: DataGranularity = "YEAR_AND_MONTH"

        if (daysDifference < 31) {
            dateGranularity = "YEAR_MONTH_AND_DAY"
        } else if(monthsDifference > 13) {
            dateGranularity = "YEAR"
        }

        OrganizationService.fetchOrganizationFinanceChargesTotalAmountAnalytics(
            selectedFinancialRole,
            props.startDate,
            props.endDate,
            dateGranularity,
            props.digitalContractTemplate?.id,
            null,
            props.issuerId
        )
        .then(response => {
            // map all the granularity labels
            if (response !== null) {
                let labels: string[] = [];

                // iterate over each data from the API to extract the labels...
                // iterate over each 'currency' granularity
                Object.keys(response).forEach(currencyKey => {

                    // iterate over each 'date' granularity
                    Object.keys(response[currencyKey]).forEach(granularityKey => {

                        // only add non duplicate items
                        if (labels.indexOf(granularityKey) < 0) {
                            labels.push(granularityKey);
                        }
                    })
                });

                // sort the labels and set it on the state
                labels = labels.sort();
                setOrganizationFinanceChargesTotalAmounAnalyticsLabels(labels);

                // this part will create an normalized object that the front-end can easily 
                // manage the data provided from the API...  
                const currencyAndDatasets: NormalizedGranularityAndDataset[] = [];

                // iterate over each currency layer from the response (BRL, USD)
                for (const currencyKey in response) {

                    // create the normalized currency and dataset
                    const datasetFromCurrencyGranularity = response[currencyKey];
                    const currencyAndDataset: NormalizedGranularityAndDataset = {
                        currency: currencyKey,
                        dataset: []
                    };

                    // iterate over each sorted label....
                    labels.forEach(label => {
                        // if the currenct dataset granularity does not have the label, push an empty value
                        if (!datasetFromCurrencyGranularity[label]) {
                            currencyAndDataset.dataset.push({
                                presentation: "--",
                                value: 0,
                            })
                        }
                        // otherwise push the value from the API
                        else {
                            currencyAndDataset.dataset.push({
                                presentation: datasetFromCurrencyGranularity[label].regionalString,
                                value: datasetFromCurrencyGranularity[label].regionalValue,
                            })
                        }
                    });

                    // include the normalized dataset into the array
                    currencyAndDatasets.push(currencyAndDataset);
                }

                // update the view state
                setOrganizationFinanceChargesTotalAmountAnalyticsDatasets(currencyAndDatasets);
            }

        })
        .finally(() => {
            setLoading(false)
        })

        OrganizationService.countOrganizationFinanceChargesTotalAmountAnalytics(
            selectedFinancialRole,
            props.startDate,
            props.endDate,
            dateGranularity,
            props.digitalContractTemplate?.id,
            null,
            props.issuerId
        )
        .then(response => {
            // map all the granularity labels
            if (response !== null) {
                let labels: string[] = [];

                // iterate over each data from the API to extract the labels...
                // iterate over each 'currency' granularity
                Object.keys(response).forEach(currencyKey => {

                    // iterate over each 'date' granularity
                    Object.keys(response[currencyKey]).forEach(granularityKey => {

                        // only add non duplicate items
                        if (labels.indexOf(granularityKey) < 0) {
                            labels.push(granularityKey);
                        }
                    })
                });

                // sort the labels and set it on the state
                labels = labels.sort();
                setOrganizationFinanceChargesTotalAmountCountLabels(labels);

                // this part will create an normalized object that the front-end can easily 
                // manage the data provided from the API...  
                const currencyAndDatasets: NormalizedGranularityAndDataset[] = [];

                // iterate over each currency layer from the response (BRL, USD)
                for (const currencyKey in response) {

                    // create the normalized currency and dataset
                    const datasetFromCurrencyGranularity = response[currencyKey];
                    const currencyAndDataset: NormalizedGranularityAndDataset = {
                        currency: currencyKey,
                        dataset: []
                    };

                    // iterate over each sorted label....
                    labels.forEach(label => {
                        // if the currenct dataset granularity does not have the label, push an empty value
                        if (!datasetFromCurrencyGranularity[label]) {
                            currencyAndDataset.dataset.push({
                                presentation: "--",
                                value: 0,
                            })
                        }
                        // otherwise push the value from the API
                        else {
                            currencyAndDataset.dataset.push({
                                presentation: datasetFromCurrencyGranularity[label].toString(),
                                value: datasetFromCurrencyGranularity[label],
                            })
                        }
                    });

                    // include the normalized dataset into the array
                    currencyAndDatasets.push(currencyAndDataset);
                }

                // update the view state
                setOrganizationFinanceChargesTotalAmountCountDatasets(currencyAndDatasets);
            }

        })
        .finally(() => {
            setLoading(false)
        })
    }

    function handleChangeFinanceChargesPage(event: unknown, newPage: number) {
        // To fetch finance charges need to pass newPage + 1 because the pagination of the table starts at 0
        // and the pagination of the back-end starts at 1
        fetchFinanceCharges(newPage + 1, financeChargeListRowsPerPage)
        setFinanceChargeListPage(newPage)
    }

    function handleChangeFinanceChargesRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setFinanceChargeListRowsPerPage(+event.target.value);
        setFinanceChargeListPage(0);

        // Fetch the first page again
        fetchFinanceCharges(1, +event.target.value)
    }
    function handleChangeSubscriptionChargesPage(event: unknown, newPage: number) {
        // To fetch susbscription charges need to pass newPage + 1 because the pagination of the table starts at 0
        // and the pagination of the back-end starts at 1
        fetchSubscriptionCharges(newPage + 1, subscriptionChargeListRowsPerPage)
        setSubscriptionChargeListPage(newPage)
    }

    function handleChangeSubscriptionChargesRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSubscriptionChargeListRowsPerPage(+event.target.value);
        setSubscriptionChargeListPage(0);

        // Fetch the first page again
        fetchSubscriptionCharges(1, +event.target.value)
    }

    /**
     * This function will render an dataset value label
     * @param gDataset 
     * @param fontColor 
     * @returns 
     */
    function TotalDatasetValueLabel(gDataset: NormalizedGranularityAndDataset, dataType: DatasetsDataType = "sum", fontColor: string = "primary"): JSX.Element {
        // sum the values inside the normalized dataset
        let total = 0;
        gDataset.dataset.forEach(d => {
            total += d.value;
        });

        let value = fromRegionalValue(total, gDataset.currency as SupportedCurrencies).regionalValueString

        if (dataType === "count") value = `${total} entradas`

        // return the rendered component
        return (
            <Box>
                <Typography variant="h4" color={fontColor}>
                    {gDataset.currency}: <b>{value}</b>
                </Typography>
            </Box>
        )
    }
    const ContractNotFoundDialog = (): JSX.Element => {
        return (
            <Dialog open={contractNotFoundDialog} onClose={() => setContractNotFoundDialog(false)}  >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => setContractNotFoundDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme: any) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <Box m={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
                    <Typography variant="h6" >Contrato não encontrado</Typography>
                    <Box
                        my={2}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 100,
                            height: 100,
                            backgroundColor: "red",
                            borderRadius: 5
                        }}
                    >
                        <ReportProblemRounded color="disabled" sx={{ fontSize: 75 }} />
                    </Box>
                    <Typography>O contrato pode ter sido deletado pela organização</Typography>
                </Box>
            </Dialog>
        )
    }

    return (
        <>
            {
                excelCells
                    ?
                    <Box sx={{ display: "flex", mb: "10px", border: "1px solid rgb(230, 230, 230)", padding: "10px" }}>
                        <Button variant="contained" onClick={() => handleExportClick()}><img width={26} style={{ marginRight: "10px" }} src="/images/assets/excel-white.svg" />Exportar para o Excel</Button>
                        {exporting && (
                            <ExportFinancialManagementData
                                subscriptionCharges={subscriptionCharges}
                                financeCharges={financeCharges}
                                excelCells={excelCells}
                                startDate={props.startDate}
                                endDate={props.endDate}
                                templates={props.digitalContractTemplates}
                                onComplete={handleExportComplete}
                            />
                        )}
                    </Box>
                    :
                    <></>
            }
            {
                !loading
                    ?
                    <>
                        {
                            (organizationFinanceChargesTotalAmounAnalyticsDatasets)
                                ?
                                <React.Fragment>
                                    <div className="chart-container">
                                        <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Total de entradas financeiras no período selecionado</Typography>
                                        {
                                            organizationFinanceChargesTotalAmounAnalyticsDatasets.map(gDataset => (
                                                <React.Fragment>
                                                    {TotalDatasetValueLabel(gDataset)}
                                                    <Divider />
                                                </React.Fragment>

                                            ))
                                        }
                                    </div>
                                    {/**Tab toggle between Graphic and extract  */}
                                    <TabContext value={tabValue} >
                                        <TabList onChange={handleTabChangeValue} sx={{ display: "flex", alignItems: "center", height: "40px" }}>
                                            <Tab icon={<Timeline />} iconPosition="start" label="Gráfico" value="1" sx={{ minHeight: "40px" }}></Tab>
                                            <Tab icon={<Subject />} iconPosition="start" label="Extrato" value="2" sx={{ minHeight: "40px" }}></Tab>
                                        </TabList>

                                        {/**Graphics */}
                                        <TabPanel value="1">
                                            <Grid container spacing={5} >
                                                <Grid item xs={12} md={6} sx={{width: `${window.screen.availWidth / 2}px`}}>
                                                    <Box sx={chartContainer}>
                                                        <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Progressão de entradas financeiras no periodo</Typography>
                                                        <Line
                                                            style={{ width: "100%", maxHeight: "350px" }}
                                                            data={{
                                                                labels: organizationFinanceChargesTotalAmounAnalyticsLabels,
                                                                datasets: organizationFinanceChargesTotalAmounAnalyticsDatasets.map(d => ({
                                                                    label: d.currency,
                                                                    data: d.dataset.map(ds => ds.value),
                                                                }))
                                                            }}
                                                            options={{maintainAspectRatio: false}}
                                                        />
                                                    </Box>

                                                </Grid>
                                                {
                                                    (organizationFinanceChargesTotalAmountCountDatasets)
                                                        ?
                                                        <React.Fragment>
                                                            <Grid item xs={12} md={6} sx={{width: `${window.screen.availWidth / 2}px`}}>
                                                                <Box sx={chartContainer}>
                                                                    <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Quantidade de entradas financeiras no periodo</Typography>
                                                                    <Bar
                                                                        style={{ width: "100%", maxHeight: "350px" }}
                                                                        data={{
                                                                            labels: organizationFinanceChargesTotalAmountCountLabels,
                                                                            datasets: organizationFinanceChargesTotalAmountCountDatasets.map(d => ({
                                                                                label: d.currency,
                                                                                data: d.dataset.map(ds => ds.value),
                                                                                borderWidth: 4,
                                                                            }))
                                                                        }}
                                                                        options={{maintainAspectRatio: false}}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <ContractNotFoundDialog />
                                                        </React.Fragment >
                                                        :
                                                        <Alert variant="outlined" severity="info" sx={{ p: 4, mt: 4 }}>Não há dados para serem buscados com os parâmetros de busca utilizados</Alert>
                                                }
                                            </Grid>
                                        </TabPanel>

                                        {/**Statement */}
                                        <TabPanel value="2">
                                            <Box sx={{ padding: "16px", border: "2px solid rgb(230,230,230)", minWidth: "600px", overflow: "auto" }}>
                                                <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Detalhes do período selecionado</Typography>

                                                {/**Finance Charge */}
                                                <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}><DateRange /> Cobranças por período</Typography>

                                                {
                                                    financeCharges
                                                    ?
                                                        <Paper>
                                                            <TableContainer >
                                                                <Table stickyHeader >
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                {/* Empty cell for icon */}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography>Data de cobrança</Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography>Valor</Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography>Cobrança</Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography>Visualizar Contrato</Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                                {financeCharges.map((fc, index) => {
                                                                                        const textAndIcon = getFinanceChargeTextAndIcon(fc)
                                                                                        return (
                                                                                            <TableRow key={index}>
                                                                                                <TableCell>
                                                                                                    {textAndIcon.icon}
                                                                                                </TableCell>
                                                                                                <TableCell sx={{maxWidth: "100px"}}>        
                                                                                                    <Typography sx={{ wordWrap: "break-word" }} >{new Date(fc.dueDate).toLocaleString().substring(0, 10)}</Typography>
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    <Typography sx={{ wordWrap: "break-word" }} >{(fc.value / 100).toLocaleString('pt-BR', { style: 'currency', currency: fc.currency })}</Typography>
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    <Typography sx={{ wordWrap: "break-word" }} >{textAndIcon.text}</Typography>
                                                                                                </TableCell>
                                                                                                <TableCell sx={{maxWidth: "100px"}}>
                                                                                                    <IconButton color='primary' onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                                                                            if (!fc.licenseId) { fc.licenseId = "null" }
                                                                                                            handleOpenContract(e, fc.licenseId)
                                                                                                        }}
                                                                                                        sx={{ mr: "40px" }}
                                                                                                    >
                                                                                                        <FindInPage fontSize="small" />
                                                                                                    </IconButton>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        )
                                                                                    })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            <TablePagination 
                                                                rowsPerPageOptions={[10, 20]}
                                                                component="div"
                                                                count={financeChargeTotalRecords}
                                                                rowsPerPage={financeChargeListRowsPerPage}
                                                                page={financeChargeListPage}
                                                                onPageChange={handleChangeFinanceChargesPage}
                                                                onRowsPerPageChange={handleChangeFinanceChargesRowsPerPage}
                                                                labelRowsPerPage="Linhas por página"
                                                            />
                                                        </Paper>
                                                    :
                                                        <></>
                                                    
                                                }

                                                {/**Finance Subscription */}
                                                <Typography sx={{ display: "flex", alignItems: "center", mt: "20px", mb: 1 }}><EventRepeat /> Cobranças recorrentes</Typography>

                                                {
                                                    subscriptionCharges
                                                    ?
                                                        <Paper>
                                                            <TableContainer>
                                                                <Table stickyHeader >
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <Typography><b>Dia do vencimento</b></Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography><b>Valor</b></Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography><b>Cobrança</b></Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography><b>Visualizar Contrato</b></Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            subscriptionCharges
                                                                                .map((sc, index) => {
                                                                                    return (
                                                                                        <TableRow key={index}>
                                                                                            <TableCell>
                                                                                                <Typography sx={{ wordWrap: "break-word" }} >{sc.billingDueDay}</Typography>
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                <Typography sx={{ wordWrap: "break-word" }} >{(sc.value / 100).toLocaleString('pt-BR', { style: 'currency', currency: sc.currency })}</Typography>
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                <Typography sx={{ wordWrap: "break-word" }} >{sc.name}</Typography>
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                            <IconButton color='primary' onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                                                                    if (!sc.licenseId) { sc.licenseId = "null" }
                                                                                                    handleOpenContract(e, sc.licenseId)
                                                                                                }}
                                                                                                sx={{ mr: "40px" }}
                                                                                            >
                                                                                                <FindInPage fontSize="small" />
                                                                                            </IconButton>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                })
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            <TablePagination 
                                                                rowsPerPageOptions={[10, 20]}
                                                                component="div"
                                                                count={subscriptionChargeTotalRecords}
                                                                rowsPerPage={subscriptionChargeListRowsPerPage}
                                                                page={subscriptionChargeListPage}
                                                                onPageChange={handleChangeSubscriptionChargesPage}
                                                                onRowsPerPageChange={handleChangeSubscriptionChargesRowsPerPage}
                                                                labelRowsPerPage="Linhas por página"
                                                            />
                                                        </Paper>
                                                    :
                                                        <></>
                                                }
                                            </Box>
                                        </TabPanel>
                                        <ContractNotFoundDialog />
                                    </TabContext>
                                </React.Fragment >
                                :
                                <Alert variant="outlined" severity="info" sx={{ p: 4, mt: 4 }}>Não há dados para serem buscados com os parâmetros de busca utilizados</Alert>
                        }
                    </>
                    :
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 2
                    }}>
                        <Skeleton variant="rectangular" animation="wave" width="100%" height={150} />
                        <Skeleton variant="rectangular" animation="wave" width="100%" height={550} sx={{ marginTop: 3 }} />
                    </Box>
            }
        </>
    );
}

export default OrganizationFinanceChargesAnalytics;