import { EditableDigitalContract, ExternalDigitalContract, IssuedDigitalContract } from "../models/contracts";
import { Pagination } from "../models/pagination";
import contractsApi from "./contracts-api";

export interface TagsModel {
    tagName: string,
    hits?: number
}

export interface TagsBulk {
    tags: string[]
}

export interface SearchTag {
    data: TagsModel,
    type: "TAGS"
}

const TagsService = {

    fetchTagsUsingPagination: async (page: number, limit: number = 20): Promise<TagsModel[]> => {
        let url = `/rest/v1/digital-contracts/tags?page=${page}&limit=${limit}`;
        return await (await contractsApi.get(url)).data;
    },

    fetchTotalTags: async (limit: number = 20): Promise<Pagination> => {
        let url = `/rest/v1/digital-contracts/tags/pagination?limit=${limit}`;
        return await (await contractsApi.get(url)).data;
    },

    searchTagsByName: async (searchTerm: string): Promise<SearchTag[]> => {
        let url = `/rest/v1/digital-contracts/search/tags?searchTerm=${searchTerm}`;
        return await (await contractsApi.get(url)).data;
    },

    updateContractTags: async (contractId: string, tags: TagsBulk): Promise<IssuedDigitalContract | EditableDigitalContract> => {
        let url = `/rest/v1/digital-contracts/${contractId}/tags`;
        return await contractsApi.put(url, tags);
    },

    updateExternalContractTags: async (contract: ExternalDigitalContract, tags: TagsBulk): Promise<ExternalDigitalContract> => {
        const response = await contractsApi.put(`/rest/v1/digital-contracts/external-contracts/${contract.id}/tags`, tags);

        return await response.data;
    },
}

export default TagsService;