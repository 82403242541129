import { AlternateEmail, Business } from "@mui/icons-material";
import { Box, Button, FormControl, FormHelperText, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { BillingPeriodType, FileIssueBilling, IdentificationType, FileIssueContractPartie } from "../../../../../../../models/contracts";
import { SupportedCurrenciesValue } from "../../../../../../../utils/currency";
import { billTypes } from "../../../../../../../utils/editorUtils";
import { BILLING_MAXIMUN_VALUE } from "../../../../../../../utils/numberUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";

// Interfaces and Types
interface IssueBillingFormProps extends React.ComponentProps<any> {
    currentBill?: FileIssueBilling | null,
    billings: FileIssueBilling[],
    parties: FileIssueContractPartie[],
    addBilling: (bill: FileIssueBilling) => void,
    cancelAction: () => void,
}

// Default variables
const defaultBill: FileIssueBilling = {
    billingDueDay: 0,
    billingPeriodType: BillingPeriodType.ONE_TIME_PAYMENT,
    contractParty: "",
    initialBillingPeriod: new Date(),
    name: "",
    numberOfInstallments: 1,
    value: {
        currency: "BRL",
        value: 0
    }
}

const IssueBillingForm = (props: IssueBillingFormProps): JSX.Element => {

    // Destructure props object
    const { currentBill, billings, parties, addBilling, cancelAction } = props;

    // Data states
    const [newBill, setNewBill] = useState<FileIssueBilling>(currentBill ? currentBill : defaultBill);
    const [newBillErrors, setNewBillErrors] = useState<any>({});
    const [billName, setBillName] = useState(newBill.name);
    const [numberOfInstallments, setNumberOfInstallments] = useState(newBill.numberOfInstallments);
    const [billingValue, setBillingValue] = useState(Number((newBill.value.value / 100).toFixed(2)));
    const [billingDate, setBillingDate] = useState<Date | null>(currentBill ? currentBill.initialBillingPeriod : null);

    // useEffects
    useEffect(() => {
        checkForErrors();
    }, [newBill])

    // Function
    function checkForErrors() {
        const newErrors = { ...newBillErrors }

        // delete all errors
        for (const key in newErrors) {
            delete newErrors[key];
        }

        if (!currentBill || currentBill.name !== newBill.name) {
            const filter = billings.filter(b => b.name.toLocaleLowerCase() === newBill.name.toLocaleLowerCase());

            if (filter.length > 0) newErrors["name"] = "Cobrança já adicionada";
            else if (!newBill.name) newErrors["name"] = "Campo obrigatório";
        }

        if (!newBill.contractParty) newErrors["role"] = "Campo obrigatório";


        if (newBill.numberOfInstallments <= 0) newErrors["installments"] = "Campo obrigatório";

        setNewBillErrors(newErrors);
        return newErrors;
    }

    // Handler functions
    function handleChangeBillName(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setBillName(e.target.value);
        setNewBill({ ...newBill, name: e.target.value });
    }

    function handleChangeParty(e: SelectChangeEvent) {
        setNewBill({ ...newBill, contractParty: e.target.value })
    }

    function handleChangeBillingDate(value: Moment | null) {
        if (!value) return;

        setBillingDate(value.toDate());
        setNewBill({...newBill, initialBillingPeriod: value.toDate(), billingDueDay: value.toDate().getDate()})
    }

    function handleChangeBillType(e: SelectChangeEvent) {
        let b: FileIssueBilling | null = null;
        if (e.target.value === BillingPeriodType.INSTALLMENTS) {
            b = {
                ...newBill,
                numberOfInstallments,
                billingPeriodType: e.target.value as BillingPeriodType
            };
        } else {
            b = {
                ...newBill,
                billingPeriodType: e.target.value as BillingPeriodType,
                numberOfInstallments: 1
            };
        }

        setNewBill(b);
    }

    function handleChangeNumberValues(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: "value" | "installments") {

        if (isNaN(Number(e.target.value))) return;

        let value = Number(e.target.value);

        switch (type) {
            case "value":

                // check that the value is complient to the rules            
                if (value < 0) value = 0;
                if (value > BILLING_MAXIMUN_VALUE) value = BILLING_MAXIMUN_VALUE;

                setNewBill({ ...newBill, value: { currency: "BRL", value: SupportedCurrenciesValue.BRL.fromRegionValueToSubunit(value) } });
                setBillingValue(value);
                break;
            case "installments":

                // ensure that the billingPeriodType is Installments
                if (newBill.billingPeriodType !== BillingPeriodType.INSTALLMENTS) return;

                // check that the value is complient to the rules
                if (value < 1) value = 1;
                else if (value > BILLING_MAXIMUN_VALUE) value = BILLING_MAXIMUN_VALUE;

                setNewBill({ ...newBill, numberOfInstallments: value });
                setNumberOfInstallments(value);
                break;
        }
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                p: 4,
                border: "1px solid rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
                mt: 2,
                mb: 3
            }}
        >
            <TextField
                fullWidth
                value={billName}
                inputProps={{
                    maxLength: 30,
                }}
                onChange={(e) => handleChangeBillName(e)}
                required
                label="Nome da Cobrança"
                sx={{ mt: 3 }}
                error={Boolean(newBillErrors["name"])}
                helperText={newBillErrors["name"] ? newBillErrors["name"] : `${newBill.name.length} / 30`}
            />
            <FormControl fullWidth sx={{ my: 6 }} required error={Boolean(newBillErrors["role"])}>
                <InputLabel id="contract-party-selection" >Participante responsável por efetuar o pagamento</InputLabel>
                <Select
                    fullWidth
                    labelId="contract-party-selection"
                    value={newBill.contractParty}
                    label="Participante responsável por efetuar o pagamento"
                    renderValue={(value) => (
                        <Box display={"flex"} alignItems={"center"}>
                            {
                                parties.filter(p => p.role === newBill.contractParty).map(party => (
                                    <ListItemIcon>
                                        {
                                            party.contractPartyIdentificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                                ?
                                                <AlternateEmail />
                                                :
                                                <Business />

                                        }
                                    </ListItemIcon>
                                ))
                            }
                            <Typography >{value}</Typography>
                        </Box>
                    )}
                    onChange={(e) => handleChangeParty(e)}
                    error={Boolean(newBillErrors["role"])}
                >
                    {
                        parties.map(party => (
                            <MenuItem key={party.role} value={party.role} >
                                <ListItemIcon>
                                    {
                                        party.contractPartyIdentificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                            ?
                                            <AlternateEmail />
                                            :
                                            <Business />

                                    }
                                </ListItemIcon>
                                <ListItemText>{party.role}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText >{newBillErrors["role"] ? newBillErrors["role"] : ""}</FormHelperText>
            </FormControl>
            <Box sx={{width: "100%"}}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                    <DatePicker
                        label="Data inicial de pagamento"
                        inputFormat="DD/MM/YYYY"
                        value={billingDate}
                        onChange={(value: Moment | null) => handleChangeBillingDate(value)}
                        renderInput={(params) => <TextField {...params} fullWidth error={!billingDate} helperText={!billingDate ? "Campo obrigatório" : ""} />}
                    />
                </LocalizationProvider>
            </Box>
            <FormControl fullWidth required sx={{ my: 6 }}>
                <InputLabel id="bill-type" >Tipo da cobrança</InputLabel>
                <Select
                    fullWidth
                    labelId="bill-type"
                    value={newBill.billingPeriodType}
                    renderValue={(value) => (
                        billTypes.filter(bill => bill.value === value).map(bill => (
                            <Box display={"center"} alignItems={"center"}>
                                {bill.icon}
                                <Typography sx={{ ml: 2 }} >{bill.label}</Typography>
                            </Box>
                        ))
                    )}
                    label="Tipo da cobrança"
                    onChange={handleChangeBillType}
                >
                    {
                        billTypes.map(bill => (
                            <MenuItem value={bill.value}>
                                <ListItemIcon>
                                    {bill.icon}
                                </ListItemIcon>
                                <ListItemText>{bill.label}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            {
                newBill.billingPeriodType === BillingPeriodType.INSTALLMENTS
                    ?
                    <TextField
                        fullWidth
                        required
                        label="Número máximo de parcelas"
                        value={numberOfInstallments}
                        type="number"
                        inputProps={{
                            max: BILLING_MAXIMUN_VALUE,
                            min: 1
                        }}
                        onChange={(e) => handleChangeNumberValues(e, "installments")}
                        sx={{ mb: 6 }}
                        error={newBillErrors["installments"]}
                        helperText={newBillErrors["installments"] ? newBillErrors["installments"] : ""}
                    />
                    :
                    <></>
            }
            <TextField
                fullWidth
                required
                label="Valor da cobrança"
                value={billingValue}
                type="number"
                inputProps={{
                    max: BILLING_MAXIMUN_VALUE,
                    min: 0
                }}
                onChange={(e) => handleChangeNumberValues(e, "value")}
                error={newBillErrors["value"]}
                helperText={newBillErrors["value"] ? newBillErrors["value"] : ""}
            />
            <TextField
                fullWidth
                multiline
                minRows={3}
                maxRows={5}
                label="Adicionar descrição da cobrança"
                value={newBill.description}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewBill({ ...newBill, description: event.target.value })}
                inputProps={{ maxLength: 200 }}
                helperText={`${newBill.description ? newBill.description.length : 0} / 200`}
                sx={{ mb: 3, mt: 6 }}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                <Button variant="text" onClick={cancelAction} >Cancelar</Button>
                <Button variant="contained" disabled={Object.keys(newBillErrors).length > 0} onClick={() => addBilling(newBill)} >Salvar</Button>
            </Box>
        </Box>
    )
}

export default IssueBillingForm;