import { ExpandMore, FilterAlt, FilterAltOff, VerticalSplit } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Badge, Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Skeleton, SxProps, Theme, Typography } from "@mui/material";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { ChartDataset, CustomGranularityMatchGroup, DataGranularity, GranularityMatchGroupFields, PropertyFilter } from "../../../../models/analytics";
import { DigitalContractTemplate, TotalAmountOfBillingDataAnalyticsWithGranularity, TotalAmountOfBillingDataAnalyticsWithGranularityCount, TotalContractsDataAnalyticsWithGranularity } from "../../../../models/contracts";
import ContractsService from "../../../../services/contracts";
import { Colors } from "../../../../utils/colors";
import { CurrencyValue, SupportedCurrencies } from "../../../../utils/currency";
import { AnalyticsProps, TotalAmountOfBillingDataAnalyticsContractStates, translateTotalAmountOfBillingDataAnalyticsContractStates } from "./Analytics";
import DigitalContractTemplatePropertyFilters from "./DigitalContractTemplatePropertyFilters";
import ExportFinancialManagementData from "./ExportFinancialManagementData";
import { ArrayUtils, Dataset, XYAxisDatasets } from "./analytics-utils";

interface ExcelCell {
    value: number,
    label: string
}

function replaceLabelTemplateIdForTemplateName(input: string, digitalContractTemplates: DigitalContractTemplate[]): string {
    digitalContractTemplates.forEach(template => {
        if (input.indexOf(template.id) >= 0) {
            input = input.replaceAll(template.id, template.name);
        }
    });
    return input;
}

class ChartDatasets extends XYAxisDatasets {
    datasets: ChartDataset[];

    constructor(xyAxisDatasets: XYAxisDatasets, digitalContractTemplates: DigitalContractTemplate[] | undefined = undefined) {
        super(xyAxisDatasets.labels, xyAxisDatasets.datasets);

        // callback used to normalize the label for a human more readable one
        const normalizeLabel = (input: Dataset, digitalContractTemplates: DigitalContractTemplate[] | undefined): string => {
            let label = input.label;

            // replace the digital contract templates ID for its name
            if (digitalContractTemplates) label = replaceLabelTemplateIdForTemplateName(label, digitalContractTemplates);

            // replace all the state enumeration values from an string literal
            const states: TotalAmountOfBillingDataAnalyticsContractStates[] = ["WAITING_FOR_SIGNATURES", "INACTIVE", "SIGNED", "WAITING_FOR_ISSUED"];
            states.forEach(state => {
                if (label.indexOf(state) >= 0) {
                    label = label.replace(state, translateTotalAmountOfBillingDataAnalyticsContractStates(state as TotalAmountOfBillingDataAnalyticsContractStates));
                }
            });
            return label;
        }

        this.datasets = xyAxisDatasets.datasets.map(d => {
            const datasetColor = Colors.random.withSL(0.89, 0.72);
            return {
                ...d,
                currency: (d as any).currency as SupportedCurrencies,
                backgroundColor: datasetColor.toRGBHexadecimal(),
                lineColor: datasetColor.brighter(-0.1).toRGBHexadecimal(),
                label: normalizeLabel(d, digitalContractTemplates)
            }
        });
    }
}

const ContractsAnalytics = (props: AnalyticsProps): JSX.Element => {
    //presets 
    const FixedGranularityMatchGroup: GranularityMatchGroupFields[] = ["granularity", "currency"];
    const FixedContractGranularityMatchGroup: GranularityMatchGroupFields[] = ["granularity"];
    const CustomGranularityMatchGroups: CustomGranularityMatchGroup[] = [
        {
            label: "Modelo de Contrato",
            value: "templateId"
        },
        {
            label: "Estado do Contrato",
            value: "state"
        },
        {
            label: "Cobrança",
            value: "billingName"
        },
    ];

    // states
    /**
     * This state store the API response from analytics data
     */
    const [analyticsData, setAnalyticsData] = useState<TotalAmountOfBillingDataAnalyticsWithGranularity[] | null>(null);
    
    const [analyticsCountData, setAnalyticsCountData] = useState<TotalAmountOfBillingDataAnalyticsWithGranularityCount[] | null>(null);

    const [contractsAnalyticsData, setContractsAnalyticsData] = useState<TotalContractsDataAnalyticsWithGranularity[] | null>(null)
    /**
     * this prop will store witch fields will be used to group/split the data from the analyticsData state
     */
    const [customGranularityMatchGroup, setCustomGranularityMatchGroup] = useState<GranularityMatchGroupFields[]>(["templateId", "state"]);
    /**
     * This state will store the result of the group/split process. This state is used to produce the charts
     */
    const [chartDatasets, setChartDatasets] = useState<ChartDatasets | null>(null);

    const [contractChartDatasets, setContractsChartDatasets] = useState<ChartDatasets | null>(null);
    /**
     * This state will store the API filters
     */
    const [apiFilters, setApiFilters] = useState<PropertyFilter[]>([]);

    const [loading, setLoading] = useState(true)
    const [exporting, setExporting] = useState(false);

    // Normalized data to export to Excel
    const [excelCells, setExcelCells] = useState<ExcelCell[] | null>(null);

    // Call the export finance management data
    const handleExportClick = () => {
        setExporting(true);
    }
    const handleExportComplete = () => {
        setExporting(false);
    }

    const chartContainer: SxProps<Theme> = {
        ['@media(max-width:650px)']: {
            height: "375px"
        },
        padding : "16px",
        border: "2px solid rgb(230, 230, 230)",
        marginBottom: "16px",
        width: "100%",
        height: "350px",
    }

    useEffect(() => {
        if (analyticsData) {
            const newCells = analyticsData.map(
                (data) => {
                    const value = data.totalSum / 100;
                    let template = "";
                    if (props.digitalContractTemplates) {
                        props.digitalContractTemplates.forEach(temp => {
                            if (temp.id === data.templateId) template = temp.name
                        })
                    } else {
                        template = data.templateId
                    }
                    const label = data.granularity;
                    const billingName = data.billingName;
                    const state = getStateFromExcelData(data.state);
                    return { value, label, template, billingName, state };
                }
            );
            setExcelCells(newCells);
        }
    }, [analyticsData]);

    // effect used to fetch the API data
    useEffect(() => {
        setLoading(true);
        // reset analytics data state
        setAnalyticsData(null);
        setContractsAnalyticsData(null)

        const momentEndDate = moment(props.endDate)
        const momentStartDate = moment(props.startDate)

        const daysDifference = momentEndDate.diff(momentStartDate, 'days');
        const monthsDifference = momentEndDate.diff(momentStartDate, 'months')

        let dateGranularity: DataGranularity = "YEAR_AND_MONTH"

        if (daysDifference < 31) {
            dateGranularity = "YEAR_MONTH_AND_DAY"
        } else if(monthsDifference > 13) {
            dateGranularity = "YEAR"
        }

        const tagNames = props.tags ? props.tags.map(tag => tag.tagName) : []

        ContractsService.analyticsOfTotalAmountOfBillingDataAnalyticsWithGranularity(
            props.startDate,
            props.endDate,
            apiFilters,
            dateGranularity,
            tagNames.join(", ")
        )
            .then(response => {
                // do not process if the response is null
                if (response === null) {
                    setLoading(false)
                    return;
                }

                // make front-end filters on the data provided from the API
                if (props.digitalContractTemplate) response = response.filter(r => r.templateId === props.digitalContractTemplate?.id);

                // after making front-end filters, check if the response already has data, if not, ignore the post-process
                if (response.length === 0) {
                    setLoading(false)
                    return;
                }

                // update the state
                setAnalyticsData(response);

                // adjust the scroll to the end (prevent re-render bugs)
                window.scroll(document.body.scrollWidth, 0);
            })
            .finally(() => {
                setLoading(false);
            })

        ContractsService.analyticsOfIssuedContractWithGranularity(
            props.startDate,
            props.endDate,
            apiFilters,
            dateGranularity,
            tagNames.join(", ")
        )
            .then(response => {
                // do not process if the response is null
                if (response === null) {
                    setLoading(false)
                    return;
                }

                // make front-end filters on the data provided from the API
                if (props.digitalContractTemplate) response = response.filter(r => r.templateId === props.digitalContractTemplate?.id);

                // after making front-end filters, check if the response already has data, if not, ignore the post-process
                if (response.length === 0) {
                    setLoading(false)
                    return;
                }

                // update the state
                setContractsAnalyticsData(response)

                // adjust the scroll to the end (prevent re-render bugs)
                window.scroll(document.body.scrollWidth, 0);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [apiFilters]);

    // effect used to create the list of filters that will be shown to the client based on the data provided on the props.digitalContractTemplate
    useEffect(() => {
        // ignore the there is no digital contract template on the props
        if (!props.digitalContractTemplate) {
            return;
        }

        // create filters for each contract party specification from the template
        props.digitalContractTemplate.contractPartySpecifications.forEach(cp => {

        });
    }, []);

    // effect used to normalize the data of the analyticsData state
    useEffect(() => {

        // reset the chart dataset state
        setChartDatasets(null);
        setContractsChartDatasets(null)

        // if the analytics data is null ignore the post-processing
        if (!analyticsData) {
            return;
        }

        // process de accumulator matching groups
        const accumulatorMatchGroups = ArrayUtils
            .accumulativeGrouping(
                analyticsData,
                [...FixedGranularityMatchGroup, ...customGranularityMatchGroup],
                (r) => new CurrencyValue(r.totalSum, r.currency as SupportedCurrencies).regionalValueDecimal
            );

        const xyAxisDatasets = accumulatorMatchGroups.toXYAxisDatasets(FixedGranularityMatchGroup[0]);
        setChartDatasets(new ChartDatasets(xyAxisDatasets, props.digitalContractTemplates));
        
        /* --------------------------------- */
        
        // if the analytics data is null ignore the post-processing
        if (!contractsAnalyticsData) return;
        
        const filteredCustomGranularityMatchGroup = customGranularityMatchGroup.filter(customMG => customMG !== "currency" && customMG !== "billingName")

        const accumulatorContractsMatchGroup = ArrayUtils.accumulativeGrouping(
            contractsAnalyticsData,
            [...FixedContractGranularityMatchGroup, ...filteredCustomGranularityMatchGroup],
            (r) => r.totalCount
        )

        const xyAxisContractsDatasets = accumulatorContractsMatchGroup.toXYAxisDatasets(FixedContractGranularityMatchGroup[0]);
        setContractsChartDatasets(new ChartDatasets(xyAxisContractsDatasets, props.digitalContractTemplates))

        setLoading(false)

    }, [analyticsData, contractsAnalyticsData, customGranularityMatchGroup])

    /**setApiFilters
     * handle the property filters change event of DigitalContractTemplatePropertyFilters component
     * @param propertyFilters
     */
    function handlePropertyFiltersChange(propertyFilters: PropertyFilter[]): void {
        setApiFilters(propertyFilters);
    }

    /**
     * Function used to handle checkbox 'onChange' events to set the state of the customGranularityMatchGroup state prop
     * @param event 
     */
    function handleCustomGroupingCheckboxOnChange(event: ChangeEvent<HTMLInputElement>) {
        // check if is a deletion
        const elementIndex = customGranularityMatchGroup.indexOf(event.target.value as GranularityMatchGroupFields);
        if (elementIndex >= 0) {
            setCustomGranularityMatchGroup(customGranularityMatchGroup.filter(cgm => cgm !== (event.target.value as GranularityMatchGroupFields)));
        }
        // it is a inclusion
        else {
            const newSelections: GranularityMatchGroupFields[] = [];
            CustomGranularityMatchGroups.forEach(cgm => {
                // if the current value is already set on the current state, add it
                if (customGranularityMatchGroup.indexOf(cgm.value) >= 0) newSelections.push(cgm.value);
                //otherwise, check if the value from the event target is the new selection, if it is, add it 
                else if ((event.target.value as GranularityMatchGroupFields) === cgm.value) newSelections.push(cgm.value);

                // finally, change react state
                setCustomGranularityMatchGroup(newSelections);
            });
        }
    }

    function getStateFromExcelData(state: string) {
        switch (state) {
            case "WAITING_FOR_SIGNATURES":
                return "Aguardando assinaturas";
            case "WAITING_FOR_ISSUED":
                return "Aguardando ser emitido";
            case "SIGNED":
                return "Assinado";
            case "INACTIVE":
                return "Inativo";
        }
    }

    // Main component
    return (
        <>
            {/** Accordion with grouping options */}
            {
                excelCells
                    ?
                    <Box sx={{ display: "flex", mb: "10px", border: "1px solid rgb(230, 230, 230)", padding: "10px" }}>
                        <Button variant="contained" onClick={() => handleExportClick()}><img width={26} style={{ marginRight: "10px" }} src="/images/assets/excel-white.svg" />Exportar para o Excel</Button>
                        {exporting && (
                            <ExportFinancialManagementData
                                excelCells={excelCells}
                                startDate={props.startDate}
                                endDate={props.endDate}
                                onComplete={handleExportComplete}
                            />
                        )}
                    </Box>
                    :
                    <></>
            }
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        <VerticalSplit sx={{ transform: "translateY(6px); scaleY(-1)", mr: 1 }} color="primary" /> Agrupamentos <Badge sx={{ marginLeft: 2 }} badgeContent={customGranularityMatchGroup.length} color="primary" />
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <FormGroup>
                            {
                                CustomGranularityMatchGroups.map(cmg => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={customGranularityMatchGroup.indexOf(cmg.value) >= 0}
                                                onChange={handleCustomGroupingCheckboxOnChange}
                                                value={cmg.value}
                                            />
                                        }
                                        label={cmg.label}
                                    />
                                ))
                            }
                        </FormGroup>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            {/** Accordion with API filter options */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        {
                            (apiFilters.length > 0)
                                ?
                                <FilterAlt sx={{ transform: "translateY(6px); scaleY(-1)", mr: 1 }} color="primary" />
                                :
                                <FilterAltOff sx={{ transform: "translateY(6px); scaleY(-1)", mr: 1 }} color="primary" />
                        }
                        Filtros do modelo de contrato
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DigitalContractTemplatePropertyFilters digitalContractTemplate={props.digitalContractTemplate} onChange={handlePropertyFiltersChange} />
                </AccordionDetails>
            </Accordion>

            {
                !loading

                    ?
                    (chartDatasets)
                        ?
                        <div className="chart-container">
                            <Typography variant="h5" sx={{ my: 2 }}>Total de entradas financeiras dos contratos</Typography>
                            <Grid container spacing={5} sx={{overflow: "auto"}}>
                                <Grid item sx={{width: `${window.screen.availWidth / 2}px`}} xs={12} md={6}> 
                                    <Box sx={{...chartContainer}}>
                                        <Line
                                            style={{ width: "100%", maxHeight: "350px" }}
                                            data={{
                                                labels: chartDatasets.labels,
                                                datasets: chartDatasets.datasets.map(d => ({
                                                    label: d.label,
                                                    data: d.values,
                                                    fill: false,
                                                    borderWidth: 2,
                                                    backgroundColor: d.backgroundColor,
                                                    borderColor: d.lineColor
                                                }))
                                            }}
                                            options={{maintainAspectRatio: false}}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item sx={{width: `${window.screen.availWidth / 2}px`}} xs={12} md={6}>
                                    <Box sx={{...chartContainer}}>
                                        <Bar
                                            style={{ width: "100%", maxHeight: "350px" }}
                                            data={{
                                                labels: chartDatasets.labels,
                                                datasets: chartDatasets.datasets.map(d => ({
                                                    label: d.label,
                                                    data: d.values,
                                                    fill: false,
                                                    borderWidth: 6,
                                                    backgroundColor: d.backgroundColor,
                                                    borderColor: d.lineColor
                                                }))
                                            }}
                                            options={{maintainAspectRatio: false}}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        :
                        <Alert variant="outlined" severity="info" sx={{ p: 4, mt: 4 }}>Não há dados para serem buscados com os parâmetros de busca utilizados</Alert>
                    :
                    <Box>
                        <Skeleton variant="rectangular" animation="wave" width="100%" height={350} sx={{ marginTop: 3 }} />
                    </Box>
            }
            {
                !loading

                ?
                    <div className="chart-container">
                        {
                            (contractChartDatasets)
                            ?
                                <>
                                    <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Total de contratos criados durante o periodo</Typography>
                                    <Box sx={{width: "100%", overflow: "auto"}}>
                                        <Box sx={{...chartContainer}}>
                                            <Bar
                                                style={{ width: "100%", maxHeight: "350px" }}
                                                data={{
                                                    labels: contractChartDatasets.labels,
                                                    datasets: contractChartDatasets.datasets.map(d => ({
                                                        label: d.label,
                                                        data: d.values,
                                                        fill: false,
                                                        borderWidth: 6,
                                                        backgroundColor: d.backgroundColor,
                                                        borderColor: d.lineColor
                                                    }))
                                                }}
                                                options={{maintainAspectRatio: false}}
                                            />
                                        </Box>
                                    </Box>
                                </>
                            :
                                <></>
                        }
                    </div>
                :
                <Box>
                    <Skeleton variant="rectangular" animation="wave" width="100%" height={350} sx={{ marginTop: 3 }} />
                </Box>
            }
        </>
    )
}

export default ContractsAnalytics;