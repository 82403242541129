import { Box, Checkbox, Dialog, FormControlLabel, TextField, Typography } from "@mui/material";
import { GppMaybe, Login } from "@mui/icons-material";
import { useContext, useState } from "react";
import AuthenticationContext from "../../contexts/authentication";
import ErrorWrapper from "../../utils/ErrorWrapper";

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import AppNotification from "../../components/AppNotification";
import { LoadingButton } from "../../components/LoadingButton";
import authService from "../../services/authorization";

function RenewAccessDialog(props: React.ComponentProps<any>) {

    const notification = useAppDispatch();

    //Parent props
    const { sessionRenewed, ...others } = props;

    //State props
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');

    //Context props
    const useAuthentication = () => { return useContext(AuthenticationContext) }
    const authUser = useAuthentication();

    //Functions
    const updateSession = async () => {
        setLoading(true);

        try {
            const email = authUser.user().account.email;
            const accountsToken = await authService.renewSessionWithPassword(password);

            if (!accountsToken) return;

            //Then authenticate the user if the received token
            await authUser.authenticationWithAccountsToken(accountsToken)

            sessionRenewed()
        }
        catch (e) {
            const err = new ErrorWrapper(e)
            notification(show({
                type: 'error',
                message: (err.httpStatus === 404) ? `Conta não encontrada` : (err.httpStatus === 403) ? `Credenciais inválidas` : err.message
            }))
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Dialog {...others}>
            <Box height={5} bgcolor='action.active' />
            <Box p={3} textAlign='center' maxWidth={365}>

                <GppMaybe color='info' sx={{ fontSize: 40 }} />
                <Typography variant='h6'>Confirmação de identidade necessária</Typography>
                <Typography variant='subtitle2' mt={1} mb={2}>Autentique-se novamente com o <strong>Klaus Accounts</strong> para utilizar o serviço solicitado</Typography>

                <TextField value={password} fullWidth sx={{ mb: 5 }} onChange={(e) => setPassword(e.target.value)}
                    label='Senha' type='password' variant='standard' />

                <LoadingButton fullWidth loading={loading} variant="contained" onClick={updateSession}>
                    Autenticar
                </LoadingButton>

            </Box>
            <AppNotification />
        </Dialog>
    );
}

export default RenewAccessDialog;