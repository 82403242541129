import { Theme } from "@emotion/react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Input, SxProps, TextField, Tooltip, Typography } from "@mui/material";
import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { Address as AddressModel, addressToString } from "../../../../models/address";
import BRAServices from "../../../../services/bra_services";
import { RequiredInformationInputFieldProps } from "../rifields"
import { Help } from "@mui/icons-material";


interface ChangedAddressDialogFieldCallback {
    (address: AddressModel): AddressModel
};

const Address = (props: RequiredInformationInputFieldProps): JSX.Element => {

    //states
    const [value, setValue] = useState(props.requiredInformation.value);
    const [isValid, setIsValid] = useState(false);
    const [isDialogContentValid, setIsDialogContentValid] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addressFromDialog, setAddressFromDialog] = useState<AddressModel>({
        addressNumber: "",
        country: "",
        district: "",
        locality: "",
        publicPlace: "",
        state: "",
        zipCode: "",
    });

    const boxStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            padding: "1rem 0.8rem"
        },
        ['@media(max-width:300px)']: {
            padding: "1rem 0.8rem"
        },
        width: "100%",
        padding: "20px 28px",
        backgroundColor: "#ffffff",
        marginBottom: "14px",
        borderRadius: "10px",
        boxSizing: "border-box",
    }
    const titleStyle: SxProps<Theme> = {
        ['@media(max-width:450px)']: {
            fontSize: "0.875rem",
            padding: "0.375rem 0.625rem"
        },
        ['@media(max-width:300px)']: {
            fontSize: "0.5rem",
            padding: "0.125rem 0.25rem"
        },
        color: "#555555",
        fontSize: "20px"
    }

    useEffect(() => {

        setIsDialogContentValid(
            addressFromDialog.zipCode.length > 0 &&
            addressFromDialog.publicPlace.length > 0 &&
            addressFromDialog.addressNumber.length > 0 &&
            addressFromDialog.district.length > 0 &&
            addressFromDialog.locality.length > 0 &&
            addressFromDialog.state.length > 0 &&
            addressFromDialog.country.length > 0
        );

    }, [addressFromDialog])

    useEffect(() => {
        setIsValid(/.+/.test(value))
    }, [value])

    useEffect(() => {
        props.onChange(
            value,
            isValid
        );
    }, [value, isValid]);

    function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
        setValue(event.target.value);
    }

    function handleAddressHelperDialogFieldOnChange(callback: ChangedAddressDialogFieldCallback) {
        const updatedAddressFromDialog = callback({
            ...addressFromDialog
        });
        setAddressFromDialog(updatedAddressFromDialog);
    }

    function handleAddressHelperDialogZipCodeTextFieldOnChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        // make a copy of the state
        const updatedAddressFromDialog = {
            ...addressFromDialog
        };

        // change the zip code
        updatedAddressFromDialog.zipCode = event.target.value;

        setAddressFromDialog(updatedAddressFromDialog);

        if (/\d{8}/.test(event.target.value)) {
            setLoading(true);
            BRAServices.fetchAddressFromAPI(event.target.value)
                .then(response => {
                    setAddressFromDialog({
                        ...response,
                        complement: ""
                    });
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    function handleCloseDialog() {
        const address = addressToString(addressFromDialog);
        setValue(address)
        setShowDialog(false);
    };

    return (

        <React.Fragment key={props.requiredInformation.requiredInformationName} >
            <Box sx={boxStyle}>
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
                    <Typography sx={titleStyle}>{props.requiredInformation.requiredInformationName}</Typography>
                    {
                        props.requiredInformation.requiredInformation.requiredInformation.description
                            ?
                            <Tooltip title={props.requiredInformation.requiredInformation.requiredInformation.description}>
                                <Help sx={{ ml: 1, fontSize: "18px", color: "#555" }} />
                            </Tooltip>
                            :
                            <></>
                    }
                </Box>
                <Tooltip title={(isValid) ? null : "Preencha este campo"}>
                    <Input
                        type="string"
                        value={value}
                        onClick={() => setShowDialog(true)}
                        readOnly={loading}
                        placeholder="Clique aqui para inserir o endereço"
                        error={(!isValid)}
                        onChange={handleOnChange}
                        fullWidth
                    />
                </Tooltip>
            </Box>
            {/** BRA dialog helper */}
            <Dialog
                open={showDialog}
                onClose={() => handleCloseDialog()}
            >
                <DialogTitle>Buscar endereço</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                error={addressFromDialog.zipCode.trim().length == 0}
                                label="Digite o CEP"
                                type="text"
                                variant="standard"
                                value={addressFromDialog.zipCode}
                                onChange={handleAddressHelperDialogZipCodeTextFieldOnChange}
                                InputProps={{
                                    inputProps: { maxLength: 8 },
                                    readOnly: loading,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                error={addressFromDialog.publicPlace.trim().length == 0}
                                label="Logradouro/rua"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={addressFromDialog.publicPlace}
                                onChange={(event) => handleAddressHelperDialogFieldOnChange((address => {
                                    address.publicPlace = event.target.value;
                                    return address;
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                error={addressFromDialog.addressNumber.trim().length == 0}
                                label="Número"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event) => handleAddressHelperDialogFieldOnChange((address => {
                                    address.addressNumber = event.target.value;
                                    return address;
                                }))}
                                value={addressFromDialog.addressNumber}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Complemento"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={addressFromDialog.complement}
                                onChange={(event) => handleAddressHelperDialogFieldOnChange((address => {
                                    address.complement = event.target.value;
                                    return address;
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={addressFromDialog.district.trim().length == 0}
                                label="Bairro"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={addressFromDialog.district}
                                onChange={(event) => handleAddressHelperDialogFieldOnChange((address => {
                                    address.district = event.target.value;
                                    return address;
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={addressFromDialog.locality.trim().length == 0}
                                label="Cidade"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={addressFromDialog.locality}
                                onChange={(event) => handleAddressHelperDialogFieldOnChange((address => {
                                    address.locality = event.target.value;
                                    return address;
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                error={addressFromDialog.state.trim().length == 0}
                                label="Estado"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={addressFromDialog.state}
                                onChange={(event) => handleAddressHelperDialogFieldOnChange((address => {
                                    address.state = event.target.value;
                                    return address;
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                error={addressFromDialog.country.trim().length == 0}
                                label="País"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={addressFromDialog.country}
                                onChange={(event) => handleAddressHelperDialogFieldOnChange((address => {
                                    address.country = event.target.value;
                                    return address;
                                }))}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog()}>Cancelar</Button>
                    <Button
                        disabled={loading || !isDialogContentValid}
                        onClick={() => handleCloseDialog()}
                    >
                        {
                            (!loading)
                                ?
                                "Pronto"
                                :
                                <CircularProgress />
                        }
                    </Button>
                </DialogActions>
            </Dialog>
            <Divider />
        </React.Fragment>

    )
}

export default Address;