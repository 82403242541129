import { Box,  Dialog, TextField, Typography } from "@mui/material";
import { GppMaybe } from "@mui/icons-material";
import { useContext, useState } from "react";

import ErrorWrapper from "../../utils/ErrorWrapper";
import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import AppNotification from "../../components/AppNotification";
import { LoadingButton } from "../../components/LoadingButton";
import authService from "../../services/authorization";
import AuthorizationService from "../../services/authorization";
import AuthenticationContext from "../../contexts/authentication"
import AuthenticationStore from "../../store/authentication";

interface SessionRenewedCallback {
    (contractsAuthorizationToken : string) : void
}

interface OnCloseCallback {
    () : void
}

interface ConfirmLoginToSignContractProps extends React.ComponentProps<any> {
    sessionRenewed : SessionRenewedCallback;
    onClose : OnCloseCallback;
    open : boolean;
}

function ConfirmLoginToSignContract(props: ConfirmLoginToSignContractProps) {
    const notification = useAppDispatch();
    const authUser = useContext(AuthenticationContext);

    //Parent props

    //State props
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');

    //Context props
    const useAuthentication = () => { return useContext(AuthenticationContext) }

    //Functions
    const updateSession = async () => {
        setLoading(true);

        try {
            const accountsToken = await authService.accountsLogin(authUser.user().account.email, password);
            await AuthenticationStore.authenticationWithAccountsToken(accountsToken);
            const contractsToken = await AuthenticationStore.getContractsToken();
            if (!contractsToken) {
                throw new Error("An fatal error occur while authenticating the user. The contracts token is not stored");
            }
            props.sessionRenewed(contractsToken);
        }
        catch (e) {
            const err = new ErrorWrapper(e)
            notification(show({
                type: 'error',
                message: (err.httpStatus === 404) ? `Conta não encontrada` : (err.httpStatus === 403) ? `Credenciais inválidas` : err.message
            }))
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Dialog {...props} onClose={props.onClose}>
            <Box height={5} bgcolor='action.active' />
            <Box p={3} textAlign='center' maxWidth={365}>

                <GppMaybe color='info' sx={{ fontSize: 40 }} />
                <Typography variant='h6'>Confirmação de identidade necessária</Typography>
                <Typography variant='subtitle2' mt={1} mb={2}>Autentique-se novamente para assinar o contrato</Typography>

                <TextField value={password} fullWidth sx={{ mb: 5 }} onChange={(e) => setPassword(e.target.value)}
                    label='Senha' type='password' variant='standard' />

                <LoadingButton fullWidth loading={loading} variant="contained" onClick={updateSession}>
                    Autenticar
                </LoadingButton>

            </Box>
            <AppNotification />
        </Dialog>
    );

}
export default ConfirmLoginToSignContract;