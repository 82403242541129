export interface UpdateItemAuthor {
    name: string;
    url: string;
    avatar: string;
}

export interface UpdateItem {
    id: string;
    url: string;
    title: string;
    content_html: string;
    content_text: string;
    date_published: string;
    date_modified: string;
    authors: UpdateItemAuthor[];
    author: UpdateItem;
    image: string;
    tags: string[];
    summary: string;
}

export interface Updates {
    version: string;
    user_comment: string;
    next_url: string;
    home_page_url: string;
    feed_url: string;
    language: string;
    title: string;
    description: string;
    items: UpdateItem[];
}

const UpdateService = {

    async fetchUpdates() {
        return await fetch("https://klausfiscal.com.br/blog/category/atualizacoes-cartorizi/feed/json");
    },
}

export default UpdateService;