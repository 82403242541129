import contractsAPI from "./contracts-api";
import { Pagination } from "../models/pagination";
import { OrganizationTeam } from "../models/teams";
import { UserAccount } from "../models/user";
import accountsApi from "./accounts-api";

interface UpdateCustomerTeamDTO {
    name: string,
    members: string[],
    supervisors: string[],
}

interface ImportFromKlausAccountsDTO {
    teamId: string,
    membersUuid: string[]
}

interface ApiSearchResult {
    type: 'ORGANIZATION_ACCOUNT' | 'ORGANIZATION_SUBUSER_ACCOUNT' | 'ORGANIZATION_TEAM';
    data: any
}

const TeamsService = {
    /**
     * Fetch the pagination information of organization teams
     */
    pagination: async (): Promise<Pagination> => {
        return await (await contractsAPI.get(`/rest/v1/organization-teams/pagination`)).data;
    },

    /**
     * Fetch the pagination information of a organization team members
     * @param guid 
     */
    membersPagination: async (guid: string): Promise<Pagination> => {
        return await (await contractsAPI.get(`/rest/v1/organization-teams/${guid}/members/pagination`)).data;
    },

    /**
     * Fetch organization teams with page number and limit of 20 records per page
     * @param page 
     */
    fetch: async (page: number): Promise<OrganizationTeam[] | null> => {
        const response = await contractsAPI.get(`/rest/v1/organization-teams?limit=20&page=${page}`)

        //Return null if response is 204
        if (response.status === 204) return null;
        return await response.data;
    },

    /**
     * Fetch members from a organization team 
     * @param guid 
     * @param page 
     * @returns 
     */
    fetchMembers: async (guid: string, page: number): Promise<UserAccount[] | null> => {
        const response = await contractsAPI.get(`/rest/v1/organization-teams/${guid}/members?limit=20&page=${page}`)

        //Return null if response is 204
        if (response.status === 204) return null;
        return await response.data;
    },

    /**
     * Create a organization team
     * @param teamName
     */
    create: async (teamName: string): Promise<OrganizationTeam> => {
        //Send request
        return await (await contractsAPI.post(`/rest/v1/organization-teams`, { teamName })).data
    },

    /**
     * Update a organization team
     * @param dto 
     * @returns 
     */
    update: async (guid: string, dto: UpdateCustomerTeamDTO): Promise<OrganizationTeam> => {
        //Send request
        return await (await contractsAPI.put(`/rest/v1/organization-teams/${guid}`, dto)).data
    },

    /**
     * Import a organization team from Klaus Accounts
     * @param dto 
     * @returns 
     */
    import: async (dto: ImportFromKlausAccountsDTO): Promise<OrganizationTeam> => {
        //Send request
        return await (await contractsAPI.post(`/rest/v1/organization-teams/import-from-zaninte-accounts`, dto)).data
    },

    /**
     * Fetch all organization teams from Klaus Accounts
     * @param query 
     * @returns 
     */
    fetchAllFromAccounts: async (query: string): Promise<ApiSearchResult[] | null> => {
        //Send request
        const response = await contractsAPI.get(`/rest/v1/organization-teams/search-from-zaninte-accounts/all?q=${query}`);

        //If the response is empty, return null
        if (response.status === 204) return null;

        //Return data
        return await (response).data
    },

    /**
     * Search all members of the requested team from Klaus Accounts
     * @param guid 
     * @returns 
     */
    searchAllMembersFromAccounts: async (guid: string): Promise<UserAccount[]> => {
        return await (await accountsApi.get(`/rest/v1/customer-teams/${guid}/members/all`)).data;
    },

    /**
     * Delete an team identified by its GUID
     * @param guid 
     * @returns 
     */
    deleteTeam: async (guid: string) : Promise<OrganizationTeam> => {
        return await (await contractsAPI.delete(`/rest/v1/organization-teams/${guid}`)).data;
    },

    /**
     * Fetch members from a organization team 
     * @param guid 
     * @param page 
     * @returns 
     */
    fetchSupervisors: async (guid: string, page: number): Promise<UserAccount[] | null> => {
        const response = await contractsAPI.get(`/rest/v1/organization-teams/${guid}/supervisors?limit=20&page=${page}`)

        //Return null if response is 204
        if (response.status === 204) return null;
        return await response.data;
    },

    /**
     * Fetch the pagination information of a organization team members
     * @param guid 
     */
    supervisorsPagination: async (guid: string): Promise<Pagination> => {
        return await (await contractsAPI.get(`/rest/v1/organization-teams/${guid}/supervisors/pagination`)).data;
    },

    /**
     * Fetch the teams the user is a supervisor of
     * @param guid 
     */
    fetchTeamsThatUserIsSupervisor: async (): Promise<OrganizationTeam[]> => {
        const response = await contractsAPI.get(`/rest/v1/organization-teams/my-teams/supervisor/all`);

        if (response.status === 204) return [];

        return await response.data;

    },

    fetchTeamByGuid: async (guid: string): Promise<OrganizationTeam> => {
        const response = await contractsAPI.get(`/rest/v1/organization-teams/${guid}`);

        return await response.data
    },
}

export default TeamsService;