import { AccessTime, ArrowDropDown, FilterAlt, FilterList, TrendingUp } from "@mui/icons-material";
import { Box, Button, CircularProgress, Divider, Drawer, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItemText, Menu, MenuItem, Radio, RadioGroup, Select, TextField, Typography, styled, useMediaQuery } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import PayWallDialog from "../../../components/PayWallDialog";
import TagsSearch from "../../../components/TagsSearch";
import { DigitalContractTemplate } from "../../../models/contracts";
import ContractsService from "../../../services/contracts";
import { TagsModel } from "../../../services/tags";
import TemplateAverageIssueTime from "./TemplateAverageIssueTime";
import PerformanceContracts from "./dashboards/PerformanceContracts";
import ConversionAnalytics from "./dashboards/ConversionAnalytics";


// Interface for avalible AnalyticalOperations in this Analytics
interface AnalyticalOperation {
    type: "contracts-performance" | "contracts-conversion" | "template-average-issue-time"
    title: string,
    icon: JSX.Element,
    description?: string,
}

// Default AnalyticalOperations
export const PerformanceAnalyticalOperations: AnalyticalOperation[] = [
    {
        type: "contracts-performance",
        title: "Comparação Financeira de Contratos",
        description: "Compara dados entre dois períodos baseando-se nos dados financeiros de contratos (em construção, assinados e cancelados)",
        icon: <TrendingUp />
    },
    {
        type: "template-average-issue-time",
        title: "Tempo médio de Assinatura por modelo",
        description: "Visualize o tempo médio que leva para que um contrato seja totalmente assinado, comparando o tempo entre emissão e assinatura completa.",
        icon: <AccessTime />
    },
    {
        type: "contracts-conversion",
        title: "Dados de conversão de contratos",
        description: "Dados sobre a conversão de contratos que foram assinados, pendentes e deletados",
        icon: <TrendingUp />
    },
]

// Type for limiting length of filter array
type FilterArray<T> = {
    0: T;
    1: T;
    2: T;
    length: 3;
} & Array<T>;

// Interface for the filter display
interface FilterDisplay {
    name: "Análise" | "Modelo" | "Tag",
    value: string | null
}

// Dummy filter object
const dummyFilter: FilterArray<FilterDisplay> = [
    {
        name: "Análise",
        value: PerformanceAnalyticalOperations[0].title
    },
    {
        name: "Modelo",
        value: null,
    },
    {
        name: "Tag",
        value: null,
    },
]

const StyledSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-select': {
        padding: theme.spacing(1),
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
}));

interface AnalyticsPerformanceViewProps extends React.ComponentProps<any> {
    financialModuleEnabled: Boolean
}

interface DateInterval {
    startDate: Date,
    endDate: Date,
}

interface DateIntervalSelection {
    label: string,
    interval: DateInterval | null,
}

// Static variables
const DateIntervalSelections: DateIntervalSelection[] = [
    {
        label: "Últimos 30 dias",
        interval: {
            startDate: moment().subtract(30, "days").toDate(),
            endDate: new Date(),
        }
    },
    {
        label: "Últimos 60 dias",
        interval: {
            startDate: moment().subtract(60, "days").toDate(),
            endDate: new Date(),
        }
    },
    {
        label: "Últimos 90 dias",
        interval: {
            startDate: moment().subtract(90, "days").toDate(),
            endDate: new Date(),
        }
    },
    {
        label: "Personalizado",
        interval: null
    },
]

const AnalyticsPerformance = (props: AnalyticsPerformanceViewProps): JSX.Element => {

    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Analytics Performance"
    }, []);

    // Context
    const isMobile = useMediaQuery("(max-width:900px)");

    // Default values
    const refSelectedDateInterval = DateIntervalSelections[0];

    //presets
    const startDateMoment = moment().subtract(30, "days");
    const endDateMoment = moment();

    moment.locale("pt-br");

    const datePickerRef = useRef<HTMLDivElement>(null);

    // Destructure props object
    const { financialModuleEnabled } = props;

    // Boolean states
    const [openPayWallDialog, setOpenPayWallDialog] = useState(!financialModuleEnabled);
    const [enableRedirectAfterCloseDialog, setEnableRedirectAfterCloseDialog] = useState(false);
    const [openMobileFilter, setOpenMobileFilter] = useState(false);
    const [loadingTemplate, setLoadingTemplate] = useState(true);
    const [openTagFilter, setOpenTagFilter] = useState(false);

    // Data states
    const [digitalContractTemplates, setDigitalContractTemplates] = useState<DigitalContractTemplate[]>([]);
    const [selectedDigitalContractTemplate, setSelectedDigitalContractTemplate] = useState<DigitalContractTemplate | null>(null);
    const [selectedAnalyticalOperation, setSelectedAnalyticalOperation] = useState(PerformanceAnalyticalOperations[0]);
    const [selectedTag, setSelectedTag] = useState<TagsModel | null>(null);
    const [selectedTags, setSelectedTags] = useState<TagsModel[]>([]);
    const [filterAndAndOr, setFilterAndAndOr] = useState("E");
    const [startDate, setStartDate] = useState<Date>(startDateMoment.toDate());
    const [endDate, setEndDate] = useState<Date>(endDateMoment.toDate());
    const [selectedDateInterval, setSelectedDateInterval] = useState(refSelectedDateInterval);

    const [filters, setFilters] = useState(dummyFilter);

    // Ref states
    const [tagFilterMenuAnchor, setTagFilterMenuAnchor] = useState<null | HTMLElement>(null);
    const [dateIntervalMenuAnchor, setDateIntervalMenuAnchor] = useState<null | HTMLElement>(null);

    // Use Effects

    useEffect(() => {
        fetchAllDigitalContractTemplates();
    }, [])

    useEffect(() => {
        if (datePickerRef.current) {
            const targetAdornmentButton = datePickerRef.current.firstChild;
            if (targetAdornmentButton) {
                (targetAdornmentButton as HTMLButtonElement).click();
            }
        }
    }, [startDate])

    // Functions
    async function fetchAllDigitalContractTemplates(): Promise<void> {
        setLoadingTemplate(true);
        const pagination = await ContractsService.fetchTemplatesPaginationMetadata(10);

        let digitalContractTemplates: DigitalContractTemplate[] = [];
        for (let currentPage = 1; currentPage <= pagination.totalPages; currentPage++) {
            try {
                const response = await ContractsService.fetchTemplates(currentPage);

                digitalContractTemplates = [...digitalContractTemplates, ...response];
            } catch (e) {
                throw e;
            }
        }
        setDigitalContractTemplates(digitalContractTemplates);
        setLoadingTemplate(false);
    }

    // Handler functions
    function handleClosePayWallDialog() {
        setOpenPayWallDialog(false);
        setEnableRedirectAfterCloseDialog(true)
    }

    function handleOpenMobileFilter() {
        if (openMobileFilter) setOpenMobileFilter(false);
        else setOpenMobileFilter(true);
    }

    function handleAnalyticalOperationSelect(event: React.ChangeEvent<{ value: unknown }>) {
        const selectedType = event.target.value as string;
        const selectedAo = PerformanceAnalyticalOperations.find((ao) => ao.type === selectedType);
        setSelectedAnalyticalOperation(selectedAo as AnalyticalOperation);
    }

    function handleDigitalContractTemplateSelect(event: React.ChangeEvent<{ value: unknown }>) {
        const selectedId = event.target.value;
        const selectedTemplate = digitalContractTemplates.find((template) => template.id === selectedId);
        setSelectedDigitalContractTemplate(selectedTemplate || null);
    };

    function handleTagSelected(tags: TagsModel[]) {
        setSelectedTags(tags);
    };

    function handleFilterAndAndOr(value: string){
        setFilterAndAndOr(value);
    }
    
    function handleOpenTagFilter(e: React.MouseEvent<HTMLElement>) {
        setTagFilterMenuAnchor(e.currentTarget);
        setOpenTagFilter(true);
    }

    function handleDateIntervalsRadioGroupOnChange(event: React.ChangeEvent<HTMLInputElement>, value: string): void {
        const selectedDateInterval = DateIntervalSelections.find(d => d.label === value);
        if (selectedDateInterval) {
            // update selected date interval state
            setSelectedDateInterval(selectedDateInterval);

            // if the interval is defined, set it to the start and end date state. This will trigger the contracts fetch function by the useEffect
            if (selectedDateInterval.interval) {
                setStartDate(selectedDateInterval.interval.startDate);
                setEndDate(selectedDateInterval.interval.endDate);
            }
        }
        else {
            throw new Error("Application could not resolve selected date interval selection: " + value);
        }
    }

    const AnalyticsDashboard = (): JSX.Element => {

        switch (selectedAnalyticalOperation.type) {
            case "contracts-performance":
                return (
                    <PerformanceContracts
                        digitalContractTemplate={selectedDigitalContractTemplate}
                        digitalContractTemplates={digitalContractTemplates}
                        tag={(selectedTag ? selectedTag : undefined)}
                    />
                );
            case "template-average-issue-time":
                return <TemplateAverageIssueTime/>
            case "contracts-conversion":
                return (
                    <ConversionAnalytics
                        digitalContractTemplate={selectedDigitalContractTemplate}
                        startDate={startDate}
                        endDate={endDate}
                        digitalContractTemplates={digitalContractTemplates}
                        tags={selectedTags}
                    />
                )
        }
    }


    return (
        <Box>
            {
                financialModuleEnabled
                    ?
                    <Box sx={{ p: "20px" }} >
                        {
                            isMobile
                                ?
                                <>
                                    <Button
                                        onClick={handleOpenMobileFilter}
                                        endIcon={<FilterAlt />}
                                        variant="outlined"
                                        sx={{ width: "100%", mb: "10px", mt: "10px" }}
                                    >
                                        {
                                            // Display the filters that have value
                                            filters.map((filter, index) => {
                                                if (filter.value) {
                                                    return (
                                                        <Typography variant="button" key={index}>
                                                            {filter.name}: {filter.value}
                                                        </Typography>
                                                    )
                                                }
                                            })
                                        }
                                    </Button>
                                    <Drawer
                                        open={openMobileFilter}
                                        onClose={() => setOpenMobileFilter(false)}
                                        anchor="bottom"
                                        sx={{ borderRadius: "4px 4px 0px 0px", }}
                                    >
                                        <Box sx={{ padding: "20px", height: "90vh" }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Typography sx={{ margin: "auto", textAlign: "center", fontSize: "22px" }}>Filtro</Typography>
                                            </Box>
                                            <Divider sx={{ mb: "20px", mt: "10px" }} />
                                            <Grid container columns={{ xs: 12, md: 12 }} sx={{ mb: "20px", border: "1px solid rgb(230, 230, 230)", alignItems: "center", minHeight: "48px", px: 1 }}>
                                                <Grid item xs={12} md={2}><Box sx={{ display: "flex", alignItems: "center" }}><FilterList sx={{ margin: "0px 12px" }} />Filtrar</Box></Grid>
                                                <Grid item xs={12} md={10}>
                                                    <Grid container columns={{ xs: 12 }} sx={{ alignItems: "center", justifyContent: "flex-end" }}>

                                                        {/*Analysis*/}
                                                        <Grid item xs={12} md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                            <b>Análise:</b>
                                                            <StyledSelect
                                                                value={selectedAnalyticalOperation?.type}
                                                                onChange={(e) => handleAnalyticalOperationSelect(e as any)}
                                                                sx={{ overflow: "hidden", textOverflow: "ellipsis", fontSize: "12px" }}
                                                                renderValue={() => {
                                                                    if (selectedAnalyticalOperation) {
                                                                        return (
                                                                            <Box>
                                                                                <ListItemText primary={selectedAnalyticalOperation.title} sx={{ fontSize: "12px" }} />
                                                                            </Box>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <Box>
                                                                                <ListItemText secondary="Selecione a análise" />
                                                                            </Box>
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <InputLabel sx={{ p: 1, fontWeight: 600 }}>Selecione a análise</InputLabel>
                                                                <Divider sx={{ mb: 1, }} />
                                                                {
                                                                    PerformanceAnalyticalOperations.map(ao => (
                                                                        <MenuItem sx={{ display: "flex", alignItems: "center" }} key={ao.type} value={ao.type}>
                                                                            <Box>
                                                                                <ListItemText primary={ao.title} sx={{ fontSize: "12px" }} />
                                                                                <ListItemText secondary={ao.description} />
                                                                            </Box>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        </Grid>

                                                        {/*Template Filter*/}
                                                        <Grid item xs={12} md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                            {loadingTemplate
                                                                ?
                                                                <List>
                                                                    <CircularProgress size={32} sx={{ display: "block", margin: "auto" }} />
                                                                </List>
                                                                :
                                                                <>
                                                                    <b>Modelo:</b>
                                                                    <StyledSelect
                                                                        value={selectedDigitalContractTemplate?.id || "Geral"}
                                                                        onChange={(e) => handleDigitalContractTemplateSelect(e as any)}
                                                                        label="Selecione o filtro"
                                                                        inputProps={{ id: "digital-contract-template-select" }}
                                                                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                                                    >
                                                                        <InputLabel sx={{ p: 1, fontWeight: 600 }}>Selecione o modelo</InputLabel>
                                                                        <Divider sx={{ mb: 1, }} />
                                                                        <MenuItem value="Geral">
                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                <ListItemText primary="Geral" />
                                                                            </div>
                                                                        </MenuItem>
                                                                        {digitalContractTemplates.map((template) => (
                                                                            <MenuItem key={template.id} value={template.id}>
                                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                                    <ListItemText primary={template.name} sx={{ fontSize: "12px" }} />
                                                                                </div>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </StyledSelect>
                                                                </>

                                                            }
                                                        </Grid>

                                                        {/*Tags*/}
                                                        <Grid item xs={12} md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                            <Button
                                                                endIcon={<ArrowDropDown />}
                                                                onClick={e => handleOpenTagFilter(e)}>
                                                                <Typography><b>Tag</b>{selectedTag ? `: ${selectedTag.tagName}` : ""}</Typography>
                                                            </Button>
                                                            <Menu
                                                                id="basic-menu"
                                                                anchorEl={tagFilterMenuAnchor}
                                                                open={openTagFilter}
                                                                onClose={() => {
                                                                    setOpenTagFilter(false)
                                                                    setTagFilterMenuAnchor(null)
                                                                }}
                                                            >
                                                                <TagsSearch onTagSelected={handleTagSelected} selectedTags={selectedTags} onAndAndOrChange={handleFilterAndAndOr} andAndOr={filterAndAndOr} />
                                                            </Menu>
                                                        </Grid>

                                                        {/*Date Interval*/}
                                                        {
                                                            selectedAnalyticalOperation.type !== "contracts-performance"
                                                                ?
                                                                <Grid item xs={12} md="auto" sx={{ maxWidth: "400px" }}>
                                                                    <Box sx={{ display: "flex" }}>
                                                                        <Button
                                                                            onClick={e => setDateIntervalMenuAnchor(e.currentTarget)}>
                                                                            Filtro por tempo <b style={{ marginLeft: 4, marginRight: 4 }}>{selectedDateInterval.label} </b> <small> ({moment(startDate).format('MM/YYYY')} - {moment(endDate).format('MM/YYYY')})</small>
                                                                        </Button>
                                                                    </Box>
                                                                    <Menu
                                                                        id="basic-menu"
                                                                        anchorEl={dateIntervalMenuAnchor}
                                                                        open={Boolean(dateIntervalMenuAnchor)}
                                                                        onClose={() => setDateIntervalMenuAnchor(null)}
                                                                    >
                                                                        <FormControl sx={{ p: 2 }}>
                                                                            <FormLabel id="intervals-radio-button-group">Intervalo de busca de contratos</FormLabel>
                                                                            <RadioGroup
                                                                                row
                                                                                aria-labelledby="intervals-radio-button-group"
                                                                                name="row-intervals-radio-button-group"
                                                                                onChange={handleDateIntervalsRadioGroupOnChange}
                                                                                value={selectedDateInterval.label}
                                                                            >
                                                                                {
                                                                                    DateIntervalSelections.map(d => (
                                                                                        <FormControlLabel value={d.label} control={<Radio />} label={d.label} />
                                                                                    ))
                                                                                }
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                        {
                                                                            // Render date pickers only if the date interval selection does not have an defined interval
                                                                            (!selectedDateInterval.interval)
                                                                                ?
                                                                                <Box>
                                                                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                                                                                        <DatePicker
                                                                                            label="Selecione uma data inicial"
                                                                                            inputFormat="MM/YYYY"
                                                                                            value={startDate}
                                                                                            onChange={(value: Moment | null) => { if (value) setStartDate(value.toDate()) }}
                                                                                            renderInput={(params) => <TextField {...params} size='small' sx={{ mb: 2 }} />}
                                                                                            maxDate={moment(endDate)}
                                                                                        />
                                                                                        <DatePicker
                                                                                            label="Selecione uma data final"
                                                                                            inputFormat="MM/YYYY"
                                                                                            value={endDate}
                                                                                            onChange={(value: Moment | null) => { if (value) setEndDate(value.toDate()) }}
                                                                                            renderInput={(params) => <TextField {...params} size='small' sx={{ ml: 2 }} />}
                                                                                            minDate={moment(startDate)}
                                                                                            InputAdornmentProps={{ ref: datePickerRef }}
                                                                                        />
                                                                                    </LocalizationProvider>
                                                                                </Box>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </Menu>
                                                                </Grid>
                                                                :
                                                                <></>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Drawer>
                                </>
                                :
                                <Grid container columns={{ xs: 12, md: 12 }} sx={{ mb: "20px", border: "1px solid rgb(230, 230, 230)", alignItems: "center", minHeight: "48px", px: 1 }}>
                                    <Grid item xs={12} md={2}><Box sx={{ display: "flex", alignItems: "center" }}><FilterList sx={{ margin: "0px 12px" }} />Filtrar</Box></Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container columns={{ xs: 12 }} sx={{ alignItems: "center", justifyContent: "flex-end" }}>

                                            {/*Analysis*/}
                                            <Grid item xs={12} md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                <b>Análise:</b>
                                                <StyledSelect
                                                    value={selectedAnalyticalOperation?.type}
                                                    onChange={(e) => handleAnalyticalOperationSelect(e as any)}
                                                    sx={{ overflow: "hidden", textOverflow: "ellipsis", fontSize: "12px" }}
                                                    renderValue={() => {
                                                        if (selectedAnalyticalOperation) {
                                                            return (
                                                                <Box>
                                                                    <ListItemText primary={selectedAnalyticalOperation.title} sx={{ fontSize: "12px" }} />
                                                                </Box>
                                                            );
                                                        } else {
                                                            return (
                                                                <Box>
                                                                    <ListItemText secondary="Selecione a análise" />
                                                                </Box>
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <InputLabel sx={{ p: 1, fontWeight: 600 }}>Selecione a análise</InputLabel>
                                                    <Divider sx={{ mb: 1, }} />
                                                    {
                                                        PerformanceAnalyticalOperations.map(ao => (
                                                            <MenuItem sx={{ display: "flex", alignItems: "center" }} key={ao.type} value={ao.type}>
                                                                <Box>
                                                                    <ListItemText primary={ao.title} sx={{ fontSize: "12px" }} />
                                                                    <ListItemText secondary={ao.description} />
                                                                </Box>
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </StyledSelect>
                                            </Grid>

                                            {/*Template Filter*/}
                                            <Grid item xs={12} md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                {loadingTemplate
                                                    ?
                                                    <List>
                                                        <CircularProgress size={32} sx={{ display: "block", margin: "auto" }} />
                                                    </List>
                                                    :
                                                    <>
                                                        <b>Modelo:</b>
                                                        <StyledSelect
                                                            value={selectedDigitalContractTemplate?.id || "Geral"}
                                                            onChange={(e) => handleDigitalContractTemplateSelect(e as any)}
                                                            label="Selecione o filtro"
                                                            inputProps={{ id: "digital-contract-template-select" }}
                                                            sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                                        >
                                                            <InputLabel sx={{ p: 1, fontWeight: 600 }}>Selecione o modelo</InputLabel>
                                                            <Divider sx={{ mb: 1, }} />
                                                            <MenuItem value="Geral">
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <ListItemText primary="Geral" />
                                                                </div>
                                                            </MenuItem>
                                                            {digitalContractTemplates.map((template) => (
                                                                <MenuItem key={template.id} value={template.id}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <ListItemText primary={template.name} sx={{ fontSize: "12px" }} />
                                                                    </div>
                                                                </MenuItem>
                                                            ))}
                                                        </StyledSelect>
                                                    </>

                                                }
                                            </Grid>

                                            {/*Tags*/}
                                            <Grid item xs={12} md="auto" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                <Button
                                                    endIcon={<ArrowDropDown />}
                                                    onClick={e => handleOpenTagFilter(e)}>
                                                    <Typography><b>Tag</b>{selectedTag ? `: ${selectedTag.tagName}` : ""}</Typography>
                                                </Button>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={tagFilterMenuAnchor}
                                                    open={openTagFilter}
                                                    onClose={() => {
                                                        setOpenTagFilter(false)
                                                        setTagFilterMenuAnchor(null)
                                                    }}
                                                >
                                                    <TagsSearch onTagSelected={handleTagSelected} selectedTags={selectedTags} onAndAndOrChange={handleFilterAndAndOr} andAndOr={filterAndAndOr} />
                                                </Menu>
                                            </Grid>
                                            {/*Date Interval*/}
                                            {
                                                selectedAnalyticalOperation.type !== "contracts-performance"
                                                    ?
                                                    <Grid item xs={12} md="auto" sx={{ maxWidth: "400px" }}>
                                                        <Box sx={{ display: "flex" }}>
                                                            <Button
                                                                onClick={e => setDateIntervalMenuAnchor(e.currentTarget)}>
                                                                Filtro por tempo <b style={{ marginLeft: 4, marginRight: 4 }}>{selectedDateInterval.label} </b> <small> ({moment(startDate).format('MM/YYYY')} - {moment(endDate).format('MM/YYYY')})</small>
                                                            </Button>
                                                        </Box>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={dateIntervalMenuAnchor}
                                                            open={Boolean(dateIntervalMenuAnchor)}
                                                            onClose={() => setDateIntervalMenuAnchor(null)}
                                                        >
                                                            <FormControl sx={{ p: 2 }}>
                                                                <FormLabel id="intervals-radio-button-group">Intervalo de busca de contratos</FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="intervals-radio-button-group"
                                                                    name="row-intervals-radio-button-group"
                                                                    onChange={handleDateIntervalsRadioGroupOnChange}
                                                                    value={selectedDateInterval.label}
                                                                >
                                                                    {
                                                                        DateIntervalSelections.map(d => (
                                                                            <FormControlLabel value={d.label} control={<Radio />} label={d.label} />
                                                                        ))
                                                                    }
                                                                </RadioGroup>
                                                            </FormControl>
                                                            {
                                                                // Render date pickers only if the date interval selection does not have an defined interval
                                                                (!selectedDateInterval.interval)
                                                                    ?
                                                                    <Box>
                                                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                                                                            <DatePicker
                                                                                label="Selecione uma data inicial"
                                                                                inputFormat="DD/MM/YYYY"
                                                                                value={startDate}
                                                                                onChange={(value: Moment | null) => { if (value) setStartDate(value.toDate()) }}
                                                                                renderInput={(params) => <TextField {...params} size='small' sx={{ mb: 2 }} />}
                                                                                maxDate={moment(endDate)}
                                                                            />
                                                                            <DatePicker
                                                                                label="Selecione uma data final"
                                                                                inputFormat="DD/MM/YYYY"
                                                                                value={endDate}
                                                                                onChange={(value: Moment | null) => { if (value) setEndDate(value.toDate()) }}
                                                                                renderInput={(params) => <TextField {...params} size='small' sx={{ ml: 2 }} />}
                                                                                minDate={moment(startDate)}
                                                                                InputAdornmentProps={{ ref: datePickerRef }}
                                                                            />
                                                                        </LocalizationProvider>
                                                                    </Box>
                                                                    :
                                                                    <></>
                                                            }
                                                        </Menu>
                                                    </Grid>
                                                    :
                                                    <></>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                        }
                        <Box>
                            <AnalyticsDashboard />
                        </Box>
                    </Box>
                    :
                    <Box>
                        <PayWallDialog open={openPayWallDialog} onClose={handleClosePayWallDialog} quotaName="Gestão Financeira" productName="Modulo de gestão financeira" type="module" />
                        {enableRedirectAfterCloseDialog ? <Navigate to="/contracts/create" /> : <></>}
                    </Box>
            }
        </Box >
    )
}

export default AnalyticsPerformance;