import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, IconButton, ListItemIcon, Tooltip, Typography } from "@mui/material";
import { BillingSpecification, ContractPartie } from "../../../../../../models/contracts";
import { billTypes } from "../../../../../../utils/editorUtils";
import AddFieldButton from "../../../components/AddFieldButton";
import BillingForm from "../forms/BillingForm";

// Interfaces and Types
interface BillingsProps extends React.ComponentProps<any> {
    addBilling: boolean,
    parties: ContractPartie[],
    billings: BillingSpecification[],
    currentBill: BillingSpecification | null,
    setBillings: (billings: BillingSpecification[]) => void,
    setAddBillings: (addBill: boolean) => void,
    setCurrentBill: (currentBill: BillingSpecification | null) => void,
    maxHeight: string
}

const Billings = (props: BillingsProps): JSX.Element => {

    // Destructure props object
    const { addBilling, billings, currentBill, maxHeight, parties, setAddBillings, setBillings, setCurrentBill } = props;

    // Handler functions
    function handleAddBilling(bill: BillingSpecification) {
        const updatedBillings = [...billings, bill];
        setBillings(updatedBillings.sort((a, b) => a.name.localeCompare(b.name)));
        setAddBillings(false);
    }

    function handleEditBilling(bill: BillingSpecification) {
        if (!currentBill) return;

        const updatedBillings = billings.map(b => {
            if (b.name === currentBill.name) return bill;
            return b;
        })

        setBillings(updatedBillings.sort((a, b) => a.name.localeCompare(b.name)));
        setCurrentBill(null);
    }

    function handleOpenBillingEdit(bill: BillingSpecification) {
        setCurrentBill(bill);
    }

    function handleDeleteBilling(bill: BillingSpecification) {
        const filter = billings.filter(b => b.name !== bill.name);

        setBillings(filter);
    }

    return (
        <Box sx={{ maxHeight, overflow: "auto", mb: 3 }}>
            {
                addBilling
                    ?
                    <BillingForm
                        billings={billings}
                        parties={parties}
                        addBilling={handleAddBilling}
                        cancelAction={() => setAddBillings(false)}
                    />
                    :
                    <AddFieldButton
                        onClick={() => { setAddBillings(true) }}
                        disabled={currentBill !== null || addBilling}
                        title="Adicionar Cobrança"
                    />
            }
            {
                billings.map(bill => (
                    <Box key={bill.name}>
                        {
                            currentBill && currentBill.name === bill.name
                                ?
                                <BillingForm
                                    currentBill={currentBill}
                                    billings={billings}
                                    parties={parties}
                                    addBilling={handleEditBilling}
                                    cancelAction={() => setCurrentBill(null)}
                                />
                                :
                                bill.debtorContractParty.role
                                    ?
                                    <Box
                                        key={bill.name}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            py: 1,
                                            px: 2,
                                            border: "1px solid rgba(0, 0, 0, 0.25)",
                                            borderRadius: "5px",
                                            mb: 3
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {
                                                billTypes.filter(billType => billType.value === bill.billingPeriodType).map(billType => (
                                                    <Tooltip title={billType.label}>
                                                        <ListItemIcon>
                                                            {billType.icon}
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                ))
                                            }
                                            <Typography >{bill.name}</Typography>
                                        </Box>
                                        <Box>
                                            <IconButton onClick={() => handleOpenBillingEdit(bill)} >
                                                <EditOutlined color="primary" />
                                            </IconButton>
                                            {
                                                bill.isWildcard
                                                    ?
                                                    <></>
                                                    :
                                                    <IconButton onClick={() => handleDeleteBilling(bill)} >
                                                        <DeleteOutline color="error" />
                                                    </IconButton>
                                            }
                                        </Box>
                                    </Box>
                                    :
                                    <Tooltip title="Falta adicionar o participante responsável pelo pagamento desta cobrança">
                                        <Box
                                            key={bill.name}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                py: 1,
                                                px: 2,
                                                border: "1px solid #d62828",
                                                borderRadius: "5px",
                                                mb: 3
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {
                                                    billTypes.filter(billType => billType.value === bill.billingPeriodType).map(billType => (
                                                        <Tooltip title={billType.label}>
                                                            <ListItemIcon>
                                                                {billType.icon}
                                                            </ListItemIcon>
                                                        </Tooltip>
                                                    ))
                                                }
                                                <Typography >{bill.name}</Typography>
                                            </Box>
                                            <Box>
                                                <IconButton onClick={() => handleOpenBillingEdit(bill)} >
                                                    <EditOutlined color="primary" />
                                                </IconButton>
                                                {
                                                    bill.isWildcard
                                                        ?
                                                        <></>
                                                        :
                                                        <IconButton onClick={() => handleDeleteBilling(bill)} >
                                                            <DeleteOutline color="error" />
                                                        </IconButton>
                                                }
                                            </Box>
                                        </Box>
                                    </Tooltip>
                        }
                    </Box>
                ))
            }
        </Box>
    )
}

export default Billings;