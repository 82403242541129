const LAST_SEEN_TIMESTMAP_SN = "tmst_lastSeen";

const LastSeenNotificationStore = {
    /**
     * Save the current UTC timestamp into the application local storage
     */
    set() : void {
        localStorage.setItem(LAST_SEEN_TIMESTMAP_SN, new Date().getTime().toString());
    },

    /**
     * Return the date that was stored in the last seen notification store. If the store was never saved before 
     * this method will return null
     * @returns 
     */
    get() : Date | null {
        const valueFromLocalStorage = localStorage.getItem(LAST_SEEN_TIMESTMAP_SN);
        if (valueFromLocalStorage === null) {
            return null;
        }
        else {
            const timestamp = parseInt(valueFromLocalStorage);
            return new Date(timestamp);
        }
    }   
}

export default LastSeenNotificationStore;