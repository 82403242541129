import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { IssuedDigitalContract } from '../../../models/contracts';
import QRCode from "../../../models/qrcodegenerator";
import { CurrentContext } from '../../../appctx/webappContext';
import ContractsService from '../../../services/contracts';
import { downloadOriginalFile } from '../../../models/generate-contract-blobs';

const version = "1.0.0";

function getClientTimezone() {
    const fulltimeText = new Date().toString();
    const timezoneLocationIndex = fulltimeText.indexOf("GMT");
    if (timezoneLocationIndex >= 0) {
        return fulltimeText.substring(timezoneLocationIndex);
    }
    else {
        return "All dates are represented in you timezone";
    }

}

interface SignatureCertificateContract {
    source: IssuedDigitalContract,
    originalDocumentDownloadLink: string
}

let currentContext = "";
if (CurrentContext.authWebappUrl === "http://localhost:22002") {
    currentContext = "http://localhost:22001/validation"
} else {
    currentContext = "https://app.cartorizi.com/validation"
}

const SignatureCertificate = (props: SignatureCertificateContract) => {

    return (
        <Document>
            <Page style={styles.body}>
                {/**Header*/}
                <View style={styles.viewHeader} fixed>
                    <View>
                        <Image
                            style={styles.imageHeader}
                            src="/images/logo_64.png"
                            fixed
                        />
                    </View>
                    <View>
                        <Text style={styles.header} fixed>
                            Data e horário em {getClientTimezone()}
                        </Text>
                        <Text style={[styles.header]} fixed>
                            Log Gerado em {new Date().toLocaleString()} . Versão v{version}.
                        </Text>
                    </View>
                </View>
                <View style={styles.divider} fixed />

                {/**Identification */}
                <Text style={styles.title}>{props.source.name}</Text>
                <Text style={styles.author}>Documento número {props.source.id}</Text>
                <Text style={styles.author}>Data de emissão do contrato {new Date(props.source.issueDate).toLocaleString()}</Text>
                <Text style={styles.author}>Hash do documento original {props.source.digitalTrustData.documentHashValue}</Text>
                <View style={styles.divider} />

                {/**Signature Logs */}
                <Text style={[styles.title, { marginBottom: 32 }]}>
                    Log de Assinaturas
                </Text>

                {
                    props.source.signatures?.map((s, index) => (
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 40 }} break={index % 4 === 0 && index > 0 ? true : undefined}>
                            <Image
                                style={{ width: 24, height: 24, marginRight: 10 }}
                                src="/images/assets/checkmark.png"
                            />
                            <View>
                                <Text style={[styles.title]}>
                                    {s.contractParty.role}
                                </Text>
                                <Text style={[styles.author]}>
                                    Assinou em: {new Date(s.signatureDate).toLocaleString()}
                                </Text>
                                <Text style={[styles.author]}>
                                    Endereço IP do assinante: {s.ipAddress}
                                </Text>
                                <Text style={[styles.author]}>
                                    Email utilizado na assinatura: {s.userEmail}
                                </Text>
                                {
                                    s.userFullname
                                        ?
                                        <Text style={[styles.author]}>
                                            Nome do assinante: {s.userFullname}
                                        </Text>
                                        :
                                        <></>
                                }
                                {
                                    s.userDocument
                                        ?
                                        <Text style={[styles.author]}>
                                            Documento do assinante: {s.userDocument}
                                        </Text>
                                        :
                                        <></>
                                }
                            </View>
                        </View>
                    ))
                }

                {/**Transparency*/}
                <View style={styles.validationTransparency}>
                    <View style={[styles.divider, { marginTop: 8 }]} />
                    {/* <View style={{ flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}> */}
                    <View style={{ display: "flex", alignContent: "center", alignItems: "center", margin: "auto" }}>
                        <Text style={{ fontSize: "14px", color: "#777777" }}>
                            Acesse o contrato original
                        </Text>
                        <View style={{ width: 80, height: 80, marginRight: 8, marginBottom: 8 }}>
                            <Image src={QRCode.generatePNG(`${currentContext}?documentHash=${props.source.digitalTrustData.documentHashValue}`)} />
                        </View>
                    </View>
                    <View style={[styles.divider, { marginTop: 8 }]} />
                    <View>
                        <View style={{ width: "100%" }}>
                            <Text style={[styles.footerText, { marginBottom: 6 }]}>
                                A Plataforma Cartorizi se compromete a fornecer aos seus clientes serviços de alta qualidade e transparência em todos os processos.
                                A validação dos documentos é uma das formas que temos para garantir a satisfação de nossos usuários e a credibilidade de nossos serviços.
                                Se você precisa validar um documento, utilize o QR code para baixar o documento original e valide acessando o link https://app.cartorizi.com/validation.
                            </Text>
                            <Text style={styles.footerText}>
                                Além disso, é importante ressaltar que o documento original é o único válido para validação na Plataforma Cartorizi.
                                O documento que possuí anexo de certificado de assinatura não será considerado como um documento autêntico e válido para a validação.
                                Portanto, sempre verifique se está validando o documento original para garantir a autenticidade e validade do mesmo.
                            </Text>
                        </View>
                    </View>
                </View>

                {/**Footer */}
                < View style={styles.footer} fixed >
                    <View style={[styles.divider, { textAlign: "center" }]} />
                    <View style={{ flexDirection: 'row', alignItems: 'center', display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Image style={[{ width: 64, height: 12 }]}
                            src="/images/logo_64.png"
                            fixed />
                        <Text style={{ fontSize: 6, color: 'grey' }}>Id do contrato: {props.source.id}</Text>
                        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`Página ${pageNumber} / ${totalPages}`)} />
                    </View>
                </View>
            </Page>
        </Document >
    );
}

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    header: {
        fontSize: 8,
        textAlign: "right",
        color: 'grey',
    },
    viewHeader: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        fontSize: 14,
        marginBottom: 2,
    },
    author: {
        fontSize: 8,
        marginBottom: 2,
        color: "#555555",
        fontWeight: "bold"
    },
    imageHeader: {
        width: 120,
        height: 24
    },
    pageNumber: {
        fontSize: 8,
        textAlign: 'right',
        color: 'grey',
    },
    divider: {
        borderBottomWidth: 1,
        borderBottomColor: '#EEEEEE',
        borderBottomStyle: 'solid',
        marginTop: 10,
        marginBottom: 10,
    },
    log: {
        display: "flex"
    },
    validationTransparency: {
        position: 'absolute',
        bottom: 90,
        left: 0,
        right: 10,
        paddingHorizontal: 35,
        marginTop: 40,
    },
    footer: {
        display: "flex",
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 10,
        paddingHorizontal: 35,
    },
    footerText: {
        fontSize: 8,
        color: 'grey',
        textAlign: 'justify',
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
});

export default SignatureCertificate;