import { AttachMoney, CalendarMonth, Edit, FormatListNumbered, InsertDriveFileOutlined, Notes, PeopleAlt, Send } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Alert, AlertTitle, Box, CircularProgress, Collapse, Container, Fab, Grid, Tab, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { FileWithPath } from "react-dropzone";
import FileUpload from "../../../../../components/FileUpload";
import { FileIssueContractPartie, EndDatePolicyType, FileIssueBilling, FileIssueDTO, FileIssueValidityPeriod, IdentificationType, IssueTemplateDTO, StartDatePolicyType, MailTemplate, ContractPartie } from "../../../../../models/contracts";
import { ContractsQuota } from "../../../../../models/organizations";
import { useAppDispatch } from "../../../../../redux/hooks";
import { ActionButton, Perspective } from "../../../../../utils/editorUtils";
import { LoadingState } from "../../../../../utils/types";
import EditorNavbar from "../../components/EditorNavbar";
import FileIssueFinance from "./components/tabs/FileIssueFinance";
import FileIssueParties from "./components/tabs/FileIssueParties";
import FileValidityPeriod from "./components/tabs/FileValidityPeriod";
import OrganizationContractsQuotaService from "../../../../../services/organization-quota";
import FileIssueDataReview from "./components/FileIssueDataReview";
import ChangePerspectiveButtons from "../../components/ChangePerspectiveButtons";
import OrganizationService from "../../../../../services/organization";
import SignatureOrder from "../../templates/components/tabs/SignatureOrder";

// Enums
enum Tabs {
    PARTIES = "PARTIES",
    FINANCE = "FINANCE",
    VALIDITY = "VALIDITY",
    SIGNATURE_ORDER = "SIGNATURE_ORDER",
}

// Default values
const defaultValidityPeriod: FileIssueValidityPeriod = {
    endDatePolicyType: EndDatePolicyType.UNDETERMINED,
    startDatePolicyType: StartDatePolicyType.STARTS_WHEN_SIGNED_BY_ALL_PARTIES
}

const IssueFromFile = (): JSX.Element => {

    // Contexts
    const notification = useAppDispatch();
    const isMobile = useMediaQuery("(max-width:800px)");

    // Default variables
    const defaultActionButtons: ActionButton[] = [
        {
            label: "Editar arquivo do contrato",
            icon: <Edit />,
            onClick: handleEditFile,
            disabled: true,
            value: "edit"
        },
        {
            label: "Emitir contrato",
            icon: <Send />,
            onClick: handleIssueContract,
            disabled: true,
            value: "issue"
        },
    ]

    // Loading states
    const [uploadFileLoading, setUploadFileLoading] = useState<LoadingState>("loaded");

    // Boolean states
    const [canIssueContract, setCanIssueContract] = useState(false);
    const [openDataReviewDialog, setOpenDataReviewDialog] = useState(false);
    const [openIssuedContractAlert, setOpenIssuedContractAlert] = useState(true);
    const [addPartie, setAddPartie] = useState(false);
    const [addBilling, setAddBilling] = useState(false);

    // Data states
    const [issueDTO, setIssueDTO] = useState<FileIssueDTO | null>(null);
    const [actionButtons, setActionButtons] = useState<ActionButton[]>(defaultActionButtons);
    const [parties, setParties] = useState<FileIssueContractPartie[]>([]);
    const [currentCP, setCurrentCP] = useState<FileIssueContractPartie | null>(null);
    const [billings, setBillings] = useState<FileIssueBilling[]>([]);
    const [currentBilling, setCurrentBilling] = useState<FileIssueBilling | null>(null);
    const [validityPeriod, setValidityPeriod] = useState<FileIssueValidityPeriod>(defaultValidityPeriod);
    const [quota, setQuota] = useState<ContractsQuota | null>(null);
    const [contractFile, setContractFile] = useState<File | null>(null);
    const [fileURI, setFileURI] = useState<string | null>(null);
    const [mailTemplates, setMailTemplates] = useState<MailTemplate[]>([]);
    const [signatureOrdenation, setSignatureOrdenation] = useState('0');
    const [contractPartiesListSignatureOrdenation, setContractPartiesListSignatureOrdenation] = useState<string[]>([]);
    const [normalizedParties, setPartiesNormalized] = useState<ContractPartie[]>([]);

    // Tab states
    const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.PARTIES);
    const [perspective, setPerspective] = useState<Perspective>("document");

    // useEffects
    useEffect(() => {
        fetchQuota();
        fetchMailTemplates();
    }, [])

    useEffect(()=> {
        adaptFileIssuesArrayToContractsArray(parties);
    }, [parties])

    useEffect(() => {
        produceDTO();
        enableButtons();
    }, [parties, billings, validityPeriod, contractPartiesListSignatureOrdenation])

    useEffect(() => {
        disableIssueButton();
    }, [addPartie, addBilling, currentCP, currentBilling, contractPartiesListSignatureOrdenation])

    /**
     * useEffect for calling the review dialog
     */
    useEffect(() => {
        if (!canIssueContract) return;
        setOpenDataReviewDialog(true);
        setCanIssueContract(false);
    }, [canIssueContract])

    // Functions
    /**
     * Fetches the quota of the organization to display the contractsIssuedPerMonth quota
     */
    function fetchQuota() {
        OrganizationContractsQuotaService.fetchContractsQuota()
            .then(response => {
                setQuota(response);
            })
            .catch(e => {
                setOpenIssuedContractAlert(false);
            })
    }

    /**
     * Generate the dto to issue the contract
     */
    function produceDTO() {
        const dto: FileIssueDTO = {
            billingData: billings,
            contractParties: parties,
            contractTags: {
                tags: []
            },
            mailTemplates,
            name: "",
            region: "BRA",
            validityPeriod: validityPeriod,
            contractPartiesSignatureOrder: contractPartiesListSignatureOrdenation.length > 0 ? contractPartiesListSignatureOrdenation : null ,
            clausesToConfirm: []
        }

        setIssueDTO(dto);
    }

    function fetchMailTemplates() {
        setUploadFileLoading("loading");
        OrganizationService.fetchOrganizationEmail()
            .then(response => {
                if (!response || response.length <= 0) return;
                setMailTemplates(response);
            })
            .finally(() => setUploadFileLoading("loaded"));

    }

    /**
     * Update buttons to enable them
     */
    function enableButtons() {
        let canIssue = true;

        // check if there is at least one party
        if (parties.length <= 0) canIssue = false;

        // check if each party has a identification and ensure no duplicates
        parties.forEach(party => {
            if (Object.keys(party.identification).length <= 0) canIssue = false;
            if (
                party.contractPartyIdentificationType === IdentificationType.IDENTIFIED_BY_EMAIL &&
                (!party.identification.email || !/^[^\s@]+@[^\s@,]+\.[^\s@,]+$/.test(party.identification.email))
            ) canIssue = false;
        });

        // validate the validityDate policy
        if (validityPeriod.endDatePolicyType === EndDatePolicyType.SET_BY_ISSUER && !validityPeriod.endDate) canIssue = false;
        if (validityPeriod.startDatePolicyType === StartDatePolicyType.SET_BY_ISSUER && !validityPeriod.startDate) canIssue = false;

        // enable the buttons
        const buttons = actionButtons.map(button => {
            if (button.value === "issue") {
                return {
                    ...button,
                    disabled: !canIssue
                }
            }
            return button;
        })

        setActionButtons([...buttons]);
    }

    /**
     * Disables the buttons if the users adds or edits a information
     */
    function disableIssueButton() {
        if (!addPartie || !currentCP || !addBilling || !currentBilling) return;
        const updatedButtons = actionButtons.map(button => {
            if (button.value === "issue") {
                return {
                    ...button,
                    disabled: true
                }
            }
            return button;
        })

        setActionButtons(updatedButtons);
    }

    /**
     * Resets the values of the contract
     */
    function resetValues() {
        setParties([]);
        setBillings([]);
        setValidityPeriod(defaultValidityPeriod);
        setContractFile(null);
        setFileURI(null);
        setActionButtons(defaultActionButtons);
        setSignatureOrdenation("0");
        setContractPartiesListSignatureOrdenation([]);
    }

    // Handler functions
    /**
     * Update all fields attribute to the old party role to the new role
     * @param current 
     * @param newCP 
     */
    function handleUpdateFields(current: FileIssueContractPartie, newCP: FileIssueContractPartie) {
        const updatedBillings = billings.map(b => {
            if (b.contractParty === current.role) {
                return {
                    ...b,
                    contractParty: newCP.role
                };
            }
            return b;
        })

        setBillings(updatedBillings);
    }
    
    /**
     * Handles the file uploaded by the user
     * @param acceptedFiles 
     * @returns 
     */
    function handleFileUpload(acceptedFiles: FileWithPath[]) {
        // since the user can only upload one file get the first
        const file = acceptedFiles[0];

        // if the file is not present change state to not-found
        if (!file) {
            setUploadFileLoading("not-found");
            return;
        }

        // if file is not a .docx display a error
        if (file.type !== "application/pdf") {
            setUploadFileLoading("error");
            return;
        }

        setContractFile(file);

        const fileBlob = new Blob([file], { type: file.type });

        setFileURI(URL.createObjectURL(fileBlob));

        const updatedButtons = actionButtons.map(button => {
            if (button.value === "edit") {
                return {
                    ...button,
                    disabled: false
                }
            }
            return button;
        })

        setActionButtons(updatedButtons);
    }

    /**
     * Updates the parties of the contract
     * @param updatedParties 
     */
    function handleUpdateParties(updatedParties: FileIssueContractPartie[]) {
        setParties(updatedParties);
    }

    /**
     * Changes flag to call review dialog
     */
    function handleIssueContract() {
        setCanIssueContract(true);
    }

    /**
     * Reset all values of the contract
     */
    function handleEditFile() {
        resetValues();
    }

    /**
     * Change the current select tab
     * @param event 
     * @param newValue 
     */
    function handleChangeTabs(event: React.SyntheticEvent, newValue: Tabs) {
        setCurrentTab(newValue);
    }

    /** Handle signature ordenation change */
    function handleSignatureOrdenationChange(value: string) {
        setSignatureOrdenation(value);
    };

    /** Handle contract partie signature ordenation list */
    function handleContractPartiesSignatureOrdenation(contractPartiesListSignatureOrdenation: string[]){
        setContractPartiesListSignatureOrdenation(contractPartiesListSignatureOrdenation);
    }

    function adaptFileIssuesArrayToContractsArray(fileIssuesArray: FileIssueContractPartie[]){
        const parties : ContractPartie[] = fileIssuesArray.map((fileIssue) => ({
            role: fileIssue.role,
            identificationType: fileIssue.contractPartyIdentificationType,
            identification: fileIssue.identification,
            comment: fileIssue.comment,
        }));

        setPartiesNormalized(parties);
    }

    return (
        <Box
            sx={{
                maxWidth: "100vw",
                maxHeight: "100vh",
                overflow: "auto",
            }}
        >
            <EditorNavbar title="Emissão de contrato por PDF" actionButtons={actionButtons} />
            <Box sx={{ height: "88vh", width: "100vw", mt: "64px" }}>
                <Collapse in={openIssuedContractAlert && Boolean(quota)}>
                    {
                        quota
                            ?
                            <Alert severity={quota.contractsIssuedPerMonth >= quota.customerContractsQuota.contractsIssuedPerMonth ? "warning" : "info"} onClose={() => setOpenIssuedContractAlert(false)} >
                                {
                                    quota.contractsIssuedPerMonth >= quota.customerContractsQuota.contractsIssuedPerMonth
                                        ?
                                        <>
                                            <AlertTitle>
                                                Cota mensal de contratos emitidos foi atingida - Este contrato será cobrado como excedente
                                            </AlertTitle>
                                            Pacote de contratos mensal: {quota.contractsIssuedPerMonth} / {quota.customerContractsQuota.contractsIssuedPerMonth}
                                        </>
                                        :
                                        <>
                                            <AlertTitle>
                                                Pacote de contratos mensal: {quota.contractsIssuedPerMonth} / {quota.customerContractsQuota.contractsIssuedPerMonth}
                                            </AlertTitle>
                                        </>
                                }
                            </Alert>
                            :
                            <></>
                    }
                </Collapse>
                {
                    !isMobile
                        ?
                        <Grid container>
                            <Grid item xs={5} >
                                <Box sx={{ bgcolor: 'background.paper', display: 'flex' }}>
                                    <TabContext value={currentTab}>
                                        <TabPanel value={Tabs.PARTIES} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                            <Container>
                                                {
                                                    contractFile
                                                        ?
                                                        <>
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Participantes do Contrato</Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                Defina quais são os participantes do contrato
                                                            </Typography>
                                                            <FileIssueParties
                                                                addPartie={addPartie}
                                                                setAddPartie={setAddPartie}
                                                                parties={parties}
                                                                setParties={handleUpdateParties}
                                                                currentCP={currentCP}
                                                                setCurrentCP={setCurrentCP}
                                                                updateFields={handleUpdateFields}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Participantes do Contrato</Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                Para adicionar os participantes é necessário enviar o arquivo do contrato.
                                                            </Typography>
                                                        </>
                                                }
                                            </Container>
                                        </TabPanel>
                                        <TabPanel value={Tabs.FINANCE} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                            <Container>
                                                <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Cobranças Financeiras</Typography>
                                                <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                    Aqui você pode adicionar campos de cobranças financeiras que são utilizadas para gerar dados analíticos da sua organização na plataforma. <br /> Lembre-se de confirir se todas as informações estão corretas.
                                                </Typography>
                                                <FileIssueFinance
                                                    addBilling={addBilling}
                                                    billings={billings}
                                                    currentBill={currentBilling}
                                                    parties={parties}
                                                    setAddBillings={setAddBilling}
                                                    setBillings={setBillings}
                                                    setCurrentBill={setCurrentBilling}
                                                />
                                            </Container>
                                        </TabPanel>
                                        <TabPanel value={Tabs.VALIDITY} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                            <Container>
                                                <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Periódo de vida do contrato</Typography>
                                                <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                    Aqui você pode definir o periódo de vigência do contratos que utilizam este modelo
                                                </Typography>
                                                <FileValidityPeriod
                                                    setValidityPeriod={setValidityPeriod}
                                                    validityPeriod={validityPeriod}
                                                />
                                            </Container>
                                        </TabPanel>
                                        <TabPanel value={Tabs.SIGNATURE_ORDER}sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                            <Container>
                                                <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >
                                                    Ordem de assinatura
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                    Defina a ordem em que os contratos precisam ser assinados.
                                                </Typography>
                                                <SignatureOrder parties={normalizedParties} signatureOrdenation={signatureOrdenation} 
                                                    onSignatureOrdenationChange={handleSignatureOrdenationChange} onContractPartiesSignatureOrdenation={handleContractPartiesSignatureOrdenation}/>
                                            </Container>
                                        </TabPanel>
                                        <TabList
                                            variant="scrollable"
                                            orientation="vertical"
                                            scrollButtons="auto"
                                            allowScrollButtonsMobile
                                            sx={{ borderLeft: 1, borderColor: 'divider' }}
                                            TabIndicatorProps={{
                                                sx: {
                                                    left: 0
                                                }
                                            }}
                                            onChange={(e, newTab) => handleChangeTabs(e, newTab)}
                                        >
                                            <Tab value={Tabs.PARTIES} label="Participantes" icon={<PeopleAlt />} />
                                            <Tab value={Tabs.FINANCE} disabled={parties.length <= 0} label="Financeiro" icon={<AttachMoney />} />
                                            <Tab value={Tabs.VALIDITY} disabled={parties.length <= 0} label="Vigência" icon={<CalendarMonth />} />
                                            <Tab value={Tabs.SIGNATURE_ORDER} label="Ordem de Assinaturas" icon={<FormatListNumbered />} disabled={parties.length <= 1 || addPartie} />
                                        </TabList>
                                    </TabContext>
                                </Box>
                            </Grid>
                            <Grid item xs={7}
                                sx={{
                                    width: "100%",
                                    height: "100vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    overflow: "auto"
                                }}
                            >
                                {
                                    fileURI
                                        ?
                                        <embed
                                            src={`${fileURI}#toolbar=0&navpanes=0&scrollbar=0`}
                                            type="application/pdf"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                        :
                                        <FileUpload fileLoading={uploadFileLoading} onFileUpload={handleFileUpload} acceptedFileExtension={[".pdf"]} />
                                }
                            </Grid>
                        </Grid>
                        :
                        <Box>
                            {
                                perspective === "document"
                                    ?
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "100vh",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            overflow: "auto"
                                        }}
                                    >
                                        {
                                            fileURI
                                                ?
                                                <embed
                                                    src={`${fileURI}#toolbar=0&navpanes=0&scrollbar=0`}
                                                    type="application/pdf"
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                                :
                                                <FileUpload fileLoading={uploadFileLoading} onFileUpload={handleFileUpload} acceptedFileExtension={[".pdf"]} />
                                        }
                                    </Box>
                                    :
                                    <Box>
                                        <Box sx={{ bgcolor: 'background.paper' }}>
                                            <TabContext value={currentTab}>
                                                <TabList
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    allowScrollButtonsMobile
                                                    onChange={(e, newTab) => handleChangeTabs(e, newTab)}
                                                >
                                                    <Tab value={Tabs.PARTIES} label="Participantes" icon={<PeopleAlt />} />
                                                    <Tab value={Tabs.FINANCE} disabled={parties.length <= 0} label="Financeiro" icon={<AttachMoney />} />
                                                    <Tab value={Tabs.VALIDITY} disabled={parties.length <= 0} label="Vigência" icon={<CalendarMonth />} />
                                                    <Tab value={Tabs.SIGNATURE_ORDER} label="Ordem de Assinaturas" icon={<FormatListNumbered />} disabled={parties.length <= 1 || addPartie} />
                                                </TabList>
                                                <TabPanel value={Tabs.PARTIES} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container>
                                                        {
                                                            contractFile
                                                                ?
                                                                <>
                                                                    <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Participantes do Contrato</Typography>
                                                                    <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                        Defina quais são os participantes do contrato
                                                                    </Typography>
                                                                    <FileIssueParties
                                                                        addPartie={addPartie}
                                                                        setAddPartie={setAddPartie}
                                                                        parties={parties}
                                                                        setParties={handleUpdateParties}
                                                                        currentCP={currentCP}
                                                                        setCurrentCP={setCurrentCP}
                                                                        updateFields={handleUpdateFields}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Participantes do Contrato</Typography>
                                                                    <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                        Para adicionar os participantes é necessário enviar o arquivo do contrato.
                                                                    </Typography>
                                                                </>
                                                        }
                                                    </Container>
                                                </TabPanel>
                                                <TabPanel value={Tabs.FINANCE} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container>
                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Cobranças Financeiras</Typography>
                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                            Aqui você pode adicionar campos de cobranças financeiras que são utilizadas para gerar dados analíticos da sua organização na plataforma. <br /> Lembre-se de confirir se todas as informações estão corretas.
                                                        </Typography>
                                                        <FileIssueFinance
                                                            addBilling={addBilling}
                                                            billings={billings}
                                                            currentBill={currentBilling}
                                                            parties={parties}
                                                            setAddBillings={setAddBilling}
                                                            setBillings={setBillings}
                                                            setCurrentBill={setCurrentBilling}
                                                        />
                                                    </Container>
                                                </TabPanel>
                                                <TabPanel value={Tabs.VALIDITY} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container>
                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Periódo de vida do contrato</Typography>
                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                            Aqui você pode definir o periódo de vigência do contratos que utilizam este modelo
                                                        </Typography>
                                                        <FileValidityPeriod
                                                            setValidityPeriod={setValidityPeriod}
                                                            validityPeriod={validityPeriod}
                                                        />
                                                    </Container>
                                                </TabPanel>
                                                <TabPanel value={Tabs.SIGNATURE_ORDER}sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container>
                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >
                                                            Ordem de assinatura
                                                        </Typography>
                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                            Defina a ordem em que os contratos precisam ser assinados.
                                                        </Typography>
                                                        <SignatureOrder parties={normalizedParties} signatureOrdenation={signatureOrdenation} 
                                                            onSignatureOrdenationChange={handleSignatureOrdenationChange} onContractPartiesSignatureOrdenation={handleContractPartiesSignatureOrdenation}/>
                                                    </Container>
                                                </TabPanel>
                                            </TabContext>
                                        </Box>
                                    </Box>
                            }
                        </Box>
                }
            </Box>
            {
                isMobile
                    ?
                    <ChangePerspectiveButtons 
                        perspective={perspective}
                        setPerspective={setPerspective}
                    />
                    :
                    <></>
            }
            {
                issueDTO && contractFile
                    ?
                    <FileIssueDataReview
                        issueDTO={issueDTO}
                        open={openDataReviewDialog}
                        setIssueDTO={setIssueDTO}
                        setOpen={setOpenDataReviewDialog}
                        contractFile={contractFile}
                    />
                    :
                    <></>
            }
        </Box>
    )
}

export default IssueFromFile;