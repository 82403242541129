
/**
 * Store all supported currencies
 */
export type SupportedCurrencies = "BRL" | "USD";

type SupportedRegions = "BRA";

export class CurrencyValue {

    value : number;

    currency : SupportedCurrencies;

    constructor(value : number, currency : SupportedCurrencies) {
        this.value = value;
        this.currency = currency;
    }

    /**
     * Return an regional string representation of the currency value
     */
    get regionalValueString() : string {
        switch (this.currency) {
            case "BRL":
                return this.regionalValueDecimal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            case "USD":
                return this.regionalValueDecimal.toLocaleString('en-us',{style: 'currency', currency: 'USD'})
        }
    }

    get regionalValueDecimal() : number {
        switch (this.currency) {
            case "BRL":
                return (this.value / 100);
            case "USD":
                return (this.value / 100);
        }
    }
}

class Currency {
    region : SupportedRegions;
    currencyName : SupportedCurrencies;
    subunit : number;

    constructor(region : SupportedRegions, currency : SupportedCurrencies, subunit : number) {
        this.region = region;
        this.subunit = subunit;
        this.currencyName = currency;
    }

    /**
     * Parse an subunit value to regional value
     * @param subunitValue 
     * @returns 
     */
    fromSubunitToRegionValue(subunitValue : number) : number {
        return subunitValue * this.subunit;
    }

    /**
     * Parse an regional value to its subunit equivalent
     * @param regionValue 
     * @returns 
     */
    fromRegionValueToSubunit(regionValue : number) : number {
        return regionValue / this.subunit;
    }
}

const SupportedCurrenciesValue = {
    BRL : new Currency("BRA", "BRL", 1 / 100)
}

/**
 * Parse an regional value to an CurrencyValue object
 * @param value 
 * @param currency 
 * @returns 
 */
const fromRegionalValue = (value : number, currency : SupportedCurrencies) : CurrencyValue => {
    switch (currency) {
       case "BRL" : return new CurrencyValue(value * 100, currency);
       case "USD" : return new CurrencyValue(value * 100, currency);
       default: throw new Error("Unknown currency: " + currency);
    }
}

export {
    fromRegionalValue,
    SupportedCurrenciesValue
}