import { Add, ArrowBack, AttachMoney, Badge, Contacts, Delete, Description, ExpandLess, ExpandMore, FindInPage, Grading, Info, Paid, Search } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Autocomplete, Avatar, Box, Button, Collapse, Container, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthenticationContext from "../../contexts/authentication";
import { RolesContext } from "../../contexts/roles";
import { ContactBillingInformation, ContactDigitalContract, ContactInformation, Property, UpdateContactDTO } from "../../models/contacts";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { show as showContract } from "../../redux/features/contract-view-dialog/contract-view-dialog"
import { useAppDispatch } from "../../redux/hooks";
import ContactsService from "../../services/contacts";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { LoadingButton } from "../../components/LoadingButton";
import { AccountRoles } from "../../models/user";
import ContractsService from "../../services/contracts";
import { parseToNormalizedContract } from "../../models/contracts";
import moment from "moment";

enum Tabs {
    RESUME = "RESUME",
    INFO = "INFO",
    BILL = "BILL",
    CONTRACT = "CONTRACT"
};

type Order = "asc" | "desc";

interface RowProps extends React.ComponentProps<any> {
    key: string;
    value: string[]
}

const ContactInfo = (): JSX.Element => {


    // preflight
    useEffect(() => {
        document.title = "Cartorizi - Contatos"
    }, []);

    // Params
    const { contactId } = useParams();

    // Contexts
    const authUser = useContext(AuthenticationContext);
    const userRoles = useContext(RolesContext);
    const notification = useAppDispatch();
    const navigate = useNavigate();

    // Data States
    const [contact, setContact] = useState<ContactInformation | undefined>(undefined);
    const [newInformationValue, setNewInformationValue] = useState("");
    const [newInformationKey, setNewInformationKey] = useState("");
    const [filteredContracts, setFilteredContracts] = useState<ContactDigitalContract[]>([]);
    const [filteredBills, setFilteredBills] = useState<ContactBillingInformation[]>([]);
    const [isBillsFiltered, setIsBillsFiltered] = useState(false);
    const [filteredContractValue, setFilteredContractName] = useState("")

    // Loading States
    const [loading, setLoading] = useState(true);
    const [updateLoading, setUpdateLoading] = useState(false)

    // Tab States
    const [tabValue, setTabValue] = useState<Tabs>(Tabs.INFO);
    const [sortOrder, setSortOrder] = useState<Order>("asc");

    const [openShowMore, setOpenShowMore] = useState("");
    const [addMoreInformation, setAddMoreInformation] = useState("");
    const [addNewInformation, setAddNewInformation] = useState(false);

    const [showDeleteButton, setShowDeleteButton] = useState("");

    const [enableSaveButton, setEnableSaveButton] = useState(false);

    // Use Effects

    useEffect(() => {
        fetchContact()
    }, []);

    useEffect(() => {

    }, [contact]);

    // Functions

    function fetchContact() {
        if (!contactId) return;
        setLoading(true)
        ContactsService.fetchContactById(contactId)
            .then(response => {
                setContact(response);
                setFilteredBills(response.billingInformation);
                if (response.contracts.length > 5) {
                    setFilteredContracts(response.contracts.slice(0, 5));
                } else {
                    setFilteredContracts(response.contracts);
                }
            })
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({ type: "error", message: err.message }));
            })
            .finally(() => {
                setLoading(false)
            });
    }

    function stringToColor(string: string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: 80,
                height: 80,
                fontSize: "32px",
                border: "2px solid #6b6b6b"
            },
            children: name.length > 1 ? name.substring(0, 2).toLocaleUpperCase() : name[0].toLocaleUpperCase()
        };
    }

    function formatSingleInformation(value: string): string {
        const regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?$/
        if (regex.test(value)) {
            // try to parse value to Moment
            const momentValue = moment(value);

            // If it's a valid date return the formatted string if not just return the value
            if (momentValue.isValid()) {
                return momentValue.format("DD/MM/YYYY");
            } else {
                return value;
            }
        }
        return value;
    }

    function formatMultipleInformations(values: string[]): string {
        const regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?$/
        const formattedValues = values.map(value => {
            if (regex.test(value)) {
                // try to parse value to Moment
                const momentValue = moment(value);

                // If it's a valid date return the formatted string if not just return the value
                if (momentValue.isValid()) {
                    return momentValue.format("DD/MM/YYYY");
                } else {
                    return value;
                }
            }
            return value;
        })

        return formattedValues.join(", ");
    }

    // Handler function

    function handleChangeSortDirection() {
        const isAsc = sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
    }

    function handleAddInformation(key: string, e: any) {

        if (!contact) return;

        if (e.key === 'Enter') {
            // Prevent the default behavior of the Enter key (e.g., form submission)
            e.preventDefault();

            if (newInformationValue === "") return;

            const updateInformation = contact.customProperties;
            updateInformation[key].push(newInformationValue);

            setContact({
                ...contact,
                customProperties: updateInformation
            });
            setAddMoreInformation("");
            setNewInformationValue("");
            setEnableSaveButton(true);
            return;
        }

        if (e.key === 'Escape') {
            setAddMoreInformation("");
            setNewInformationValue("");
            return;
        }
    }

    function handleMouseEnter(key: string) {
        setShowDeleteButton(key);
    };

    function handleMouseLeave() {
        setShowDeleteButton("");
    };

    function handleDeleteInformation(key: string, value: string) {
        if (!contact) return;

        let updatedInformation = contact.customProperties;
        updatedInformation[key] = updatedInformation[key].filter(i => i !== value)

        if (updatedInformation[key].length <= 0) {
            delete updatedInformation[key];
        }

        setContact({
            ...contact,
            customProperties: updatedInformation
        });
        setEnableSaveButton(true);
        setOpenShowMore("");
        setAddMoreInformation("");
        setNewInformationValue("");
    }

    function handleAddNewInformation(e: any) {
        if (!contact) return;

        if (e.key === 'Enter') {
            // Prevent the default behavior of the Enter key (e.g., form submission)
            e.preventDefault();

            if (newInformationValue === "" || newInformationKey === "") return;

            const updateInformation = contact.customProperties;
            updateInformation[newInformationKey] = [newInformationValue];

            setContact({
                ...contact,
                customProperties: updateInformation
            });
            setAddNewInformation(false);
            setNewInformationValue("");
            setNewInformationKey("");
            setEnableSaveButton(true);
            return;
        }

        if (e.key === 'Escape') {
            setAddNewInformation(false);
            setNewInformationValue("");
            setNewInformationKey("");
            return;
        }
    }

    function handleUpdateContact() {
        if (!contact) return;

        setUpdateLoading(true);

        const dto: UpdateContactDTO = {
            customInformation: contact.customProperties
        };

        ContactsService.updateContact(contact.id, dto)
            .then(() => notification(show({ type: "success", message: "Contato editado com sucesso" })))
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({ type: "error", message: err.message }))
            })
            .finally(() => setUpdateLoading(false));

    }

    function handleFindContract(bill: ContactBillingInformation): string {
        if (!contact) return "";

        const contract = contact.contracts.find(c => c.contractId === bill.contractId)

        if (contract) {
            return contract.contractName;
        } else {
            return bill.contractId;
        }
    }

    function handleChangeAutocomplete(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (!contact) return;
        setFilteredContractName(e.target.value);

        if (e.target.value === "") {
            handleClearFilter();
            if (contact.contracts.length > 5) {
                setFilteredContracts(contact.contracts.slice(0, 5));
            } else {
                setFilteredContracts(contact.contracts);
            }
        } else {
            const filter = contact.contracts.filter(c => (c.contractId.includes(e.target.value) || c.contractName.includes(e.target.value)))
            setFilteredContracts(filter);
        }

    }

    function handleResourceOnClick(resource: ContactDigitalContract) {
        if (!contact) return;

        setIsBillsFiltered(true);
        setFilteredBills(contact.billingInformation.filter(bill => bill.contractId === resource.contractId));
    }

    function handleClearFilter() {
        if (!contact) return;
        setFilteredBills(contact.billingInformation);
    }

    function viewContract(contractId: string) {
        ContractsService.findIssuedDigitalContractById(contractId)
            .then((response) => notification(showContract({
                contract: {
                    normalized: parseToNormalizedContract(response),
                    source: response
                },
                visible: true
            })))
            .catch((e) => {
                const err = new ErrorWrapper(e);

                if (err.is4xx) {
                    notification(show({ type: "error", message: "Contrato não encontrado" }))
                } else {
                    notification(show({ type: "error", message: err.message }))
                }
            })
    }

    // JSX components

    return (
        <Container sx={{ mt: 2, overflow: "auto" }}>
            <IconButton>
                <ArrowBack htmlColor="#000" onClick={() => navigate(-1)} />
            </IconButton>
            {
                !loading
                    ?
                    contact
                        ?
                        <>
                            <Typography variant="h6" color="primary.main" sx={{ my: 2 }} ><b>{contact.name}</b></Typography>
                            <Box sx={{ width: "100%", height: "88vh", border: '1px solid #EBEBEB', borderRadius: "5px", mb: 4 }} >
                                <Grid container sx={{ p: 3, height: "100%", widht: "100%", gap: "24px" }} >
                                    {/* <Grid item xs sx={{ display: {md : 'flex', sm : 'none'}, alignItems: "center", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                                        <Avatar {...stringAvatar(contact.name)} />
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "flex-start", height: "100%", width: "94%", maxWidth: "330px", border: '1px solid #EBEBEB', borderRadius: "5px", mt: 4, p: 2 }}>
                                            <Typography sx={{ mb: 2 }}>Nome: {contact.name}</Typography>
                                            {
                                                contact.customIdentificationField
                                                    ?
                                                    <Typography>Identificação: {contact.customIdentificationField}</Typography>
                                                    :
                                                    <></>
                                            }
                                        </Box>
                                    </Grid> */}
                                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "start", height: "100%", width: "100%" }}>
                                        <TabContext value={tabValue}>
                                            <TabList onChange={(e, tabValue) => setTabValue(tabValue)}>
                                                <Tab icon={<Contacts />} iconPosition="start" value={Tabs.RESUME} label="Resumo" />
                                                <Tab icon={<Info />} iconPosition="start" value={Tabs.INFO} label="Informações do Contato" />
                                                {/* 
                                                We are working in a way to show finance data of the contact in this tab. Wait for it :)
                                                <Tab icon={<AttachMoney />} iconPosition="start" value={Tabs.BILL} label="Financeiro" /> 
                                                */}
                                                <Tab icon={<Description />} iconPosition="start" value={Tabs.CONTRACT} label="Contratos" />
                                            </TabList>
                                            <TabPanel value={Tabs.RESUME} sx={{ width: "96%" }}>
                                                <Box>
                                                    <Avatar {...stringAvatar(contact.name)} />
                                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "flex-start", height: "100%", width: "94%", maxWidth: "330px", border: '1px solid #EBEBEB', borderRadius: "5px", mt: 4, p: 2 }}>
                                                        <Typography sx={{ mb: 2 }}>Nome: {contact.name}</Typography>
                                                        {
                                                            contact.customIdentificationField
                                                                ?
                                                                <Typography>Identificação: {contact.customIdentificationField}</Typography>
                                                                :
                                                                <></>
                                                        }
                                                    </Box>
                                                </Box>
                                            </TabPanel>
                                            <TabPanel value={Tabs.INFO} sx={{ width: "96%" }}>
                                                <TableContainer component={Paper} sx={{ maxHeight: "600px", overflow: "auto" }}>
                                                    <Table >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="center">
                                                                    <TableSortLabel
                                                                        active={true}
                                                                        direction={sortOrder}
                                                                        onClick={() => handleChangeSortDirection()}
                                                                    >
                                                                        Informação
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    Valor
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                Object.keys(contact.customProperties).map(key => (
                                                                    <>
                                                                        <TableRow key={key}>
                                                                            <TableCell>
                                                                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2 }}>
                                                                                    <Badge />
                                                                                    <Typography>{key}</Typography>
                                                                                    <Typography> </Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            {
                                                                                contact.customProperties[key].length > 1
                                                                                    ?
                                                                                    <TableCell>
                                                                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2 }}>
                                                                                            <Typography> </Typography>
                                                                                            <Typography>{formatMultipleInformations(contact.customProperties[key])}</Typography>
                                                                                            <IconButton
                                                                                                aria-label="expand row"
                                                                                                size="small"
                                                                                                onClick={() => { openShowMore === "" ? setOpenShowMore(key) : setOpenShowMore("") }}
                                                                                            >
                                                                                                {openShowMore === "" ? <ExpandMore /> : <ExpandLess />}
                                                                                            </IconButton>
                                                                                        </Box>
                                                                                    </TableCell>
                                                                                    :
                                                                                    <TableCell
                                                                                        onMouseEnter={() => handleMouseEnter(key)}
                                                                                        onMouseLeave={() => handleMouseLeave()}
                                                                                    >
                                                                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2 }}>
                                                                                            <Typography> </Typography>
                                                                                            <Typography>{formatMultipleInformations(contact.customProperties[key])}</Typography>

                                                                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                                                {
                                                                                                    (userRoles.assertIsOrganizationOrAdministratorOr(authUser, AccountRoles.DIGITAL_CONTRACT_TEMPLATE_MANAGER))
                                                                                                        ?
                                                                                                        <>
                                                                                                            <IconButton
                                                                                                                size="small"
                                                                                                                onClick={() => setAddMoreInformation(key)}
                                                                                                            >
                                                                                                                <Add />
                                                                                                            </IconButton>
                                                                                                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteInformation(key, contact.customProperties[key][0])} sx={{ ml: 1 }} size="small">
                                                                                                                <Delete color="error" />
                                                                                                            </IconButton>
                                                                                                        </>
                                                                                                        :
                                                                                                        <></>
                                                                                                }
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </TableCell>
                                                                            }
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                                <Collapse in={openShowMore === key} timeout="auto" unmountOnExit>
                                                                                    <Box >
                                                                                        <Table >
                                                                                            <TableBody>
                                                                                                {
                                                                                                    contact.customProperties[key].map(value => (
                                                                                                        <TableRow>
                                                                                                            <TableCell align="right"
                                                                                                                onMouseEnter={() => handleMouseEnter(value)}
                                                                                                                onMouseLeave={() => handleMouseLeave()}
                                                                                                            >
                                                                                                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                                                                                    <Typography>{formatSingleInformation(value)}</Typography>
                                                                                                                    {
                                                                                                                        showDeleteButton === value
                                                                                                                            ?
                                                                                                                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteInformation(key, value)} sx={{ ml: 1 }} size="small">
                                                                                                                                <Delete color="error" sx={{ fontSize: "20px" }} />
                                                                                                                            </IconButton>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }
                                                                                                                </Box>
                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    ))
                                                                                                }
                                                                                                <TableRow>
                                                                                                    <TableCell align="right">
                                                                                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2 }}>
                                                                                                            <Typography>
                                                                                                                Adicionar mais informação para {key}
                                                                                                            </Typography>
                                                                                                            <IconButton
                                                                                                                size="small"
                                                                                                                onClick={() => setAddMoreInformation(key)}
                                                                                                            >
                                                                                                                <Add />
                                                                                                            </IconButton>
                                                                                                        </Box>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </Box>
                                                                                </Collapse>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                                <Collapse in={addMoreInformation === key} timeout="auto" unmountOnExit>
                                                                                    <Box >
                                                                                        <Table >
                                                                                            <TableBody>
                                                                                                <TableRow>
                                                                                                    <TableCell align="center">
                                                                                                        <Typography>Informação</Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center">
                                                                                                        <TextField
                                                                                                            value={newInformationValue}
                                                                                                            onChange={e => setNewInformationValue(e.target.value)}
                                                                                                            onKeyDown={(e) => handleAddInformation(key, e)}
                                                                                                            autoFocus
                                                                                                        />
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </Box>
                                                                                </Collapse>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                ))
                                                            }
                                                            <TableRow>
                                                                <TableCell align="right" colSpan={6}>
                                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2 }}>
                                                                        <Typography>
                                                                            Adicionar mais informação
                                                                        </Typography>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => setAddNewInformation(true)}
                                                                        >
                                                                            <Add />
                                                                        </IconButton>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                    <Collapse in={addNewInformation} timeout="auto" unmountOnExit>
                                                                        <Box >
                                                                            <Table >
                                                                                <TableBody>
                                                                                    <TableRow>
                                                                                        <TableCell align="center">
                                                                                            <Typography>Nome do Campo</Typography>
                                                                                        </TableCell>
                                                                                        <TableCell align="center">
                                                                                            <TextField
                                                                                                value={newInformationKey}
                                                                                                onChange={e => setNewInformationKey(e.target.value)}
                                                                                                onKeyDown={(e) => handleAddNewInformation(e)}
                                                                                                autoFocus
                                                                                            />
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell align="center">
                                                                                            <Typography>Informação</Typography>
                                                                                        </TableCell>
                                                                                        <TableCell align="center">
                                                                                            <TextField
                                                                                                value={newInformationValue}
                                                                                                onChange={e => setNewInformationValue(e.target.value)}
                                                                                                onKeyDown={(e) => handleAddNewInformation(e)}
                                                                                            />
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </TableBody>
                                                                            </Table>
                                                                        </Box>
                                                                    </Collapse>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table >
                                                </TableContainer>
                                                <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    <LoadingButton variant="contained" disabled={!enableSaveButton} onClick={handleUpdateContact} loading={updateLoading}>
                                                        Salvar
                                                    </LoadingButton>
                                                </Box>
                                            </TabPanel>
                                            <TabPanel value={Tabs.BILL} sx={{ width: "96%" }}>
                                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", mb: 1 }}>
                                                    {
                                                        isBillsFiltered
                                                            ?
                                                            <Button
                                                                variant="text"
                                                                sx={{ fontSize: "0.75rem" }}
                                                                onClick={handleClearFilter}
                                                            >
                                                                Limpar filtro
                                                            </Button>
                                                            :
                                                            <></>
                                                    }
                                                    <Autocomplete
                                                        disablePortal
                                                        options={filteredContracts}
                                                        noOptionsText={"Sem resultados"}
                                                        sx={{ maxWidth: "300px", width: "100%" }}
                                                        filterOptions={(x) => x}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    startAdornment: <span><Search />{params.InputProps.startAdornment}</span>
                                                                }}
                                                                value={filteredContractValue}
                                                                label="Filtrar por contrato"
                                                                onChange={handleChangeAutocomplete}
                                                            />
                                                        )}
                                                        renderOption={(props, option, { selected }) => {
                                                            return (
                                                                <li {...props} onClick={() => handleResourceOnClick(option)}>
                                                                    <Box display={'flex'} flexDirection={'row'}>
                                                                        <Grading color="secondary" />
                                                                        <Box display={'flex'} ml={3} flexDirection={'column'}>
                                                                            <Typography color={'text.primary'} fontSize={'0.9em'}>
                                                                                {option.contractName}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </li>
                                                            );
                                                        }
                                                        }
                                                    />
                                                </Box>
                                                <TableContainer component={Paper} sx={{ maxHeight: "575px", overflow: "auto" }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="center">
                                                                    Nome da Cobrança
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    Valor
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    Contrato
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                filteredBills.map(bill => (
                                                                    <TableRow key={`${bill.name}-${bill.contractId}`}>
                                                                        <TableCell align="center">
                                                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2 }}>
                                                                                <Paid />
                                                                                <Typography>{bill.name}</Typography>
                                                                                <Typography> </Typography>
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Typography>{bill.value.regionalString}</Typography>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {handleFindContract(bill)}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </TabPanel>
                                            <TabPanel value={Tabs.CONTRACT} sx={{ width: "96%" }}>
                                                <List>
                                                    {
                                                        contact.contracts.map(contract => (
                                                            <>
                                                                <ListItem sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2 }}>
                                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                        <ListItemIcon>
                                                                            <Description />
                                                                        </ListItemIcon>
                                                                        <ListItemText>
                                                                            {contract.contractName}
                                                                        </ListItemText>
                                                                    </Box>
                                                                    <IconButton onClick={() => viewContract(contract.contractId)}>
                                                                        <FindInPage />
                                                                    </IconButton>
                                                                </ListItem>
                                                                <Divider variant="inset" component="li" />
                                                            </>
                                                        ))
                                                    }
                                                </List>
                                            </TabPanel>
                                        </TabContext>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                        :
                        <></>
                    :
                    <Skeleton sx={{ height: "88vh" }} />
            }
        </Container>
    );
}

export default ContactInfo;