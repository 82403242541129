import { ArrowBack, ArrowForward, AttachFile, AttachMoney, CalendarMonth, Download, Edit, FormatListNumbered, InsertDriveFileOutlined, Menu, Notes, PeopleAlt, Save, Tune, WarningAmberRounded } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Container, Fab, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { FileWithPath } from "react-dropzone";
import FileUpload from "../../../../../components/FileUpload";
import { BillingPeriodType, BillingSpecification, ComputedWildcard, ContractPartie, ContractPartyIdentification, EndDatePolicyType, MailTemplate, RequiredAttachment, RequiredInformationSpecification, StartDatePolicyType, TemplateDTO, ValidityPeriodSpecification, WildcardMetadata, WildcardType } from "../../../../../models/contracts";
import { show } from "../../../../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../../../../redux/hooks";
import ContractsService from "../../../../../services/contracts";
import ErrorWrapper from "../../../../../utils/ErrorWrapper";
import { ActionButton, Perspective, Step } from "../../../../../utils/editorUtils";
import { BILLING_MAXIMUN_VALUE } from "../../../../../utils/numberUtils";
import { LoadingState } from "../../../../../utils/types";
import OrganizationService from "../../../../../services/organization";
import SignatureOrder from "../components/tabs/SignatureOrder";
import Parties from "../components/tabs/Parties";
import RequiredInformations from "../components/tabs/RequiredInformations";
import Billings from "../components/tabs/Billings";
import Validity from "../components/tabs/Validity";
import Attachments from "../components/tabs/Attachments";
import TemplateDataReview from "../components/TemplateDataReview";
import EditTemplateFileDialog from "../components/EditTemplateFileDialog";
import ChangePerspectiveButtons from "../../components/ChangePerspectiveButtons";
import EditorNavbar from "../../components/EditorNavbar";

// Enums
enum Tabs {
    IMPORT = "IMPORT",
    SET_WILDCARDS = "SET_WILDCARDS",
    PARTIES = "PARTIES",
    RI = "RI",
    BILLINGS = "BILLINGS",
    ATTACHMENT = "ATTACHMENT",
    VALIDITY = "VALIDITY",
    ERROR = "ERROR",
    SIGNATURE_ORDER = "SIGNATURE_ORDER",
};

// Default Variables
const defaultValidityPeriod: ValidityPeriodSpecification = {
    endDatePolicyType: EndDatePolicyType.UNDETERMINED,
    startDatePolicyType: StartDatePolicyType.STARTS_WHEN_SIGNED_BY_ALL_PARTIES
}

const CreateTemplate = (): JSX.Element => {

    // Contexts
    const notification = useAppDispatch();
    const isMobile = useMediaQuery("(max-width:800px)");

    // Static variables
    const actionButtons: ActionButton[] = [
        {
            value: "save",
            label: "Salvar modelo",
            onClick: handleSaveTemplate,
            icon: <Save />,
            disabled: true
        },
        {
            value: "edit",
            label: "Editar arquivo",
            onClick: handleOpenEditTemplateFile,
            icon: <Edit />,
            disabled: true
        },
        {
            value: "download",
            label: "Baixar arquivo",
            onClick: handleDownloadFile,
            icon: <Download />,
            disabled: true
        }
    ]

    // Loading states
    const [loadingImport, setLoadingImport] = useState<LoadingState>("loaded");

    // Boolean states
    const [addPartie, setAddPartie] = useState(false);
    const [addRI, setAddRI] = useState(false);
    const [addBilling, setAddBilling] = useState(false);
    const [addAttachment, setAddAttachment] = useState(false);
    const [hasWildcards, setHasWildcards] = useState(false);
    const [allWildcardsAreTyped, setAllWildcardsAreTyped] = useState(false);
    const [areAllRIValid, setAreAllRIValid] = useState(false);
    const [areAllBillingsValid, setAreAllBillingsValid] = useState(false);
    const [openTemplateDataReview, setOpenTemplateDataReview] = useState(false);
    const [openEditTemplateFile, setOpenEditTemplateFile] = useState(false);
    const [canDownloadFile, setCanDownloadFile] = useState(false);

    // Data states
    const [importedFileURI, setImportedFileURI] = useState<string | null>(null);
    const [parties, setParties] = useState<ContractPartie[]>([]);
    const [currentCP, setCurrentCP] = useState<ContractPartyIdentification | null>(null);
    const [currentRI, setCurrentRI] = useState<RequiredInformationSpecification | null>(null);
    const [currentBill, setCurrentBill] = useState<BillingSpecification | null>(null);
    const [currentAttach, setCurrentAttach] = useState<RequiredAttachment | null>(null);
    const [computedWildcards, setComputedWildcards] = useState<ComputedWildcard[]>([]);
    const [wildcardsErrors, setWildcardsErrors] = useState<string[]>([]);
    const [requiredInformations, setRequiredInformations] = useState<RequiredInformationSpecification[]>([]);
    const [billings, setBillings] = useState<BillingSpecification[]>([]);
    const [attachments, setAttachments] = useState<RequiredAttachment[]>([]);
    const [validityPeriod, setValidityPeriod] = useState<ValidityPeriodSpecification>(defaultValidityPeriod);
    const [templateActionButtons, setTemplateActionButtons] = useState<ActionButton[]>(actionButtons.reverse());
    const [templateDTO, setTemplateDTO] = useState<TemplateDTO | null>(null);
    const [templateFile, setTemplateFile] = useState<Blob | null>(null);
    const [mailTemplates, setMailTemplates] = useState<MailTemplate[]>([]);
    const [signatureOrdenation, setSignatureOrdenation] = useState('0');
    const [contractPartiesListSignatureOrdenation, setContractPartiesListSignatureOrdenation] = useState<string[]>([]);

    // Tabs states
    const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.IMPORT);
    const [currentStep, setCurrentStep] = useState<Step>("import");
    const [perspective, setPerspective] = useState<Perspective>("document");

    // useEffects
    useEffect(() => {
        fetchMailTemplates();
    }, [])

    useEffect(() => {
        updateWildcards();
    }, [computedWildcards, parties])

    useEffect(() => {
        checkRIForErrors();
    }, [requiredInformations])

    useEffect(() => {
        checkBillingsForErrors();
    }, [billings])

    useEffect(() => {

        if (!checkRIForErrors()) return;
        if (!checkBillingsForErrors()) return;

        populateTemplateDTO()
    }, [parties, requiredInformations, billings, attachments, validityPeriod, contractPartiesListSignatureOrdenation])

    useEffect(() => {
        if (!canDownloadFile) return;
        downloadFile();
    }, [canDownloadFile])

    // Functions
    /**
     * Update the computedWildcards
     */
    function updateWildcards() {
        // Check if there is no wildcards or if it's not in the import step
        if (computedWildcards.length <= 0 || currentStep === "fill-data") return;

        // check if the template has required information
        const reqInfs: RequiredInformationSpecification[] = computedWildcards
            .filter(wc => wc.wildcardType === WildcardType.REQUIRED_INFORMATION)
            .map(wc => {
                let ri: RequiredInformationSpecification = {
                    isWildcard: true,
                    requiredInformation: {
                        description: "",
                        name: wc.name,
                        required: true,
                        type: "SINGLE_LINE_TEXT",
                    },
                    contractParty: {
                        role: parties.length === 1 ? parties[0].role : ""
                    },
                    contractPartyRole: parties.length === 1 ? parties[0].role : "",
                }

                return ri;
            });
        setRequiredInformations(reqInfs.sort((a, b) => a.requiredInformation.name.localeCompare(b.requiredInformation.name)));

        const finance: BillingSpecification[] = computedWildcards
            .filter(wc => wc.wildcardType === WildcardType.BILLING)
            .map(wc => {
                let b: BillingSpecification = {
                    name: wc.name,
                    billingPeriodType: BillingPeriodType.ONE_TIME_PAYMENT,
                    maximumValue: BILLING_MAXIMUN_VALUE,
                    minimumValue: 0,
                    required: true,
                    isWildcard: true,
                    debtorContractParty: {
                        role: parties.length === 1 ? parties[0].role : ""
                    },
                    debtorContractPartyRole: parties.length === 1 ? parties[0].role : "",
                }

                return b;
            });

        setBillings(finance.sort((a, b) => a.name.localeCompare(b.name)));

        setAllWildcardsAreTyped((reqInfs.length + finance.length) === computedWildcards.length);
    }

    /**
     * check for requiredInformation errors
     */
    function checkRIForErrors() {
        let errors = 0;

        requiredInformations.forEach(ri => {
            if (!ri.requiredInformation.name) errors++;
            if (!ri.contractParty.role || !parties.find(p => p.role === ri.contractParty.role)) errors++;
            if (!ri.requiredInformation.type) errors++;
        })

        setAreAllRIValid(errors === 0)
        return errors === 0;
    }

    /**
     * check for billing errors
     */
    function checkBillingsForErrors() {
        let errors = 0;

        billings.forEach(bill => {
            if (!bill.name) errors++;
            if (!bill.debtorContractParty.role || !parties.find(p => p.role === bill.debtorContractParty.role)) errors++;
            if (!bill.billingPeriodType) errors++;
            if (
                bill.billingPeriodType === BillingPeriodType.INSTALLMENTS &&
                (!bill.billingPeriod || !bill.billingPeriod.maxInstallments || bill.billingPeriod.maxInstallments <= 0)
            ) errors++;
            if (bill.minimumValue < 0) errors++;
            if (bill.maximumValue > BILLING_MAXIMUN_VALUE) errors++;
        })

        setAreAllBillingsValid(errors === 0);
        return errors === 0;
    }

    /**
     * generate the template dto
     */
    function populateTemplateDTO() {

        // Create wildcard metadata
        const wildcardMetadata: WildcardMetadata[] = [];
        requiredInformations.forEach(ri => {
            if (ri.isWildcard) {
                const wcMeta: WildcardMetadata = {
                    name: ri.requiredInformation.name,
                    type: WildcardType.REQUIRED_INFORMATION
                }
                wildcardMetadata.push(wcMeta)
            }
        });

        billings.forEach(b => {
            if (b.isWildcard) {
                const wcMeta: WildcardMetadata = {
                    name: b.name,
                    type: WildcardType.BILLING
                }
                wildcardMetadata.push(wcMeta)
            }
        });

        const dto: TemplateDTO = {
            billingSpecifications: billings,
            allowIssuerToSendRequiredData: false,
            contractIssuers: [],
            contractParties: parties,
            contractTags: {
                tags: []
            },
            id: "",
            legalRegionOfIssuance: "BRA",
            name: "",
            requiredAttachments: attachments,
            requiredContractPartiesInformation: requiredInformations,
            templateInputFile: null,
            validityPeriod: validityPeriod,
            wildcardMetadata,
            mailTemplates,
            clausesToConfirm: [],
            contractPartiesSignatureOrder: contractPartiesListSignatureOrdenation.length > 0 ? contractPartiesListSignatureOrdenation : null ,
        };

        setTemplateDTO(dto);
    }

    function fetchMailTemplates() {
        setLoadingImport("loading");
        OrganizationService.fetchOrganizationEmail()
            .then(response => {
                if (!response || response.length <= 0) return;
                setMailTemplates(response);
            })
            .finally(() => setLoadingImport("loaded"));

    }

    /**
     * Download the template file
     */
    function downloadFile() {
        if (!templateFile) return;

        // create file link in browser's memory
        const href = URL.createObjectURL(templateFile);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `arquivo_do_modelo.docx`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    /**
     * Update all fields attribute to the old party role to the new role
     * @param currentParty 
     * @param newPartie 
     */
    function updateFields(currentParty: ContractPartyIdentification, newPartie: ContractPartyIdentification) {

        const updatedRIs = requiredInformations.map(ri => {
            if (ri.contractPartyRole === currentParty.role) {
                return {
                    ...ri,
                    contractParty: newPartie,
                    contractPartyRole: newPartie.role
                }
            }
            return ri;
        })

        const updatedBillings = billings.map(bill => {
            if (bill.debtorContractPartyRole === currentParty.role) {
                return {
                    ...bill,
                    debtorContractParty: newPartie,
                    debtorContractPartyRole: newPartie.role
                }
            }
            return bill;
        })

        const updatedAttachments = attachments.map(at => {
            if (at.contractPartyRole === currentParty.role) {
                return {
                    ...at,
                    contractParty: newPartie,
                    contractPartyRole: newPartie.role
                }
            }
            return at;
        })

        setRequiredInformations(updatedRIs);
        setBillings(updatedBillings);
        setAttachments(updatedAttachments);
    }

    /**
     * Reset the values of the template
     */
    function resetValues() {
        setComputedWildcards([]);
        setWildcardsErrors([]);
        setImportedFileURI(null);
        setTemplateFile(null);
        setTemplateDTO(null);
        setAllWildcardsAreTyped(false);
        setAreAllBillingsValid(false);
        setAreAllRIValid(false);
        setBillings([]);
        setRequiredInformations([]);
        setCurrentCP(null);
        setCurrentRI(null);
        setCurrentBill(null);
        setSignatureOrdenation("0");
        setContractPartiesListSignatureOrdenation([]);
    }

    // Handler functions
    /**
     * Handles the upload of the template file
     * @param acceptedFiles 
     * @returns 
     */
    function handleFileUpload(acceptedFiles: FileWithPath[]) {
        setLoadingImport("loading");

        // Reset values
        resetValues();

        const file = acceptedFiles[0];

        if (!file) {
            setLoadingImport("not-found");
            return;
        }

        if (file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            setLoadingImport("error");
            return;
        }

        ContractsService.generateWildcardsFromTemplateFile("WORD", file)
            .then(response => {
                setImportedFileURI(response.uri.uri);
                setComputedWildcards(response.wildcards.wildcards);
                setWildcardsErrors(response.wildcards.wildcardErrors);

                if (response.wildcards.wildcardErrors.length <= 0) {
                    setCurrentTab(Tabs.PARTIES);
                } else {
                    setCurrentTab(Tabs.ERROR);
                }

                // check if the template has wildcards
                setHasWildcards(response.wildcards.wildcards.length > 0);
                setTemplateFile(new Blob([file], { type: file.type }));
            })
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({ type: "error", message: err.message }));
            })
            .finally(() => {
                setLoadingImport("loaded");
            })

    }

    /**
     * Change the current select tab
     * @param event 
     * @param newValue 
     */
    function handleChangeTabs(event: React.SyntheticEvent, newValue: Tabs) {
        setCurrentTab(newValue);
    }

    /**
     * Update the type of a given wildcard
     * @param wildcardName 
     * @param newValue 
     */
    function handleUpdateWildcardType(wildcardName: string, newValue: string) {

        const updatedWildcards = computedWildcards.map(wc => {
            if (wc.name === wildcardName) {
                const updatedWc: ComputedWildcard = {
                    name: wc.name,
                    wildcardType: newValue as WildcardType
                }
                return updatedWc;
            }
            return wc;
        });

        setComputedWildcards(updatedWildcards);
    }

    /**
     * Opens the review dialog to save the template
     */
    function handleSaveTemplate() {
        setOpenTemplateDataReview(true);
    }

    /**
     * Reset the screen to the upload file screen
     */
    function handleEditFile() {
        resetValues();
        setOpenEditTemplateFile(false);
        setCurrentStep("import");
        const updatedButtons = templateActionButtons.map(button => {
            return {
                ...button,
                disabled: true
            }
        })

        setTemplateActionButtons(updatedButtons);
    }

    /**
     * Shows the dialog for the user to confirm the file edit
     */
    function handleOpenEditTemplateFile() {
        setOpenEditTemplateFile(true);
    }

    /**
     * Downloads the template file
     * @returns 
     */
    function handleDownloadFile() {
        setCanDownloadFile(true);
    }

    /**
     * Handle the backstep order of the tabs
     */
    function handleGoBack() {
        switch (currentTab) {
            case Tabs.SET_WILDCARDS:
                setCurrentTab(Tabs.PARTIES);
                break;
            case Tabs.PARTIES:
                setCurrentTab(Tabs.IMPORT);
                break;
            case Tabs.RI:
                setCurrentTab(Tabs.SET_WILDCARDS);
                break;
            case Tabs.BILLINGS:
                setCurrentTab(Tabs.RI);
                break;
        }
    }

    /**
     * Handle the step order of the tabs
     */
    function handleGoFoward() {
        switch (currentTab) {
            case Tabs.IMPORT:
                setCurrentTab(Tabs.PARTIES);
                break;
            case Tabs.SET_WILDCARDS:
                if (requiredInformations.length > 0) {
                    setCurrentTab(Tabs.RI);
                } else if (billings.length > 0) {
                    setCurrentTab(Tabs.BILLINGS);
                }
                break;
            case Tabs.PARTIES:
                if (currentStep === "fill-data") {
                    setCurrentTab(Tabs.RI)
                } else {
                    setCurrentTab(Tabs.SET_WILDCARDS);
                }
                break;
            case Tabs.RI:
                setCurrentTab(Tabs.BILLINGS);
                break;
            case Tabs.BILLINGS:
                setCurrentTab(Tabs.ATTACHMENT);
                break;
            case Tabs.ATTACHMENT:
                setCurrentTab(Tabs.VALIDITY);
                break;

        }
    }

    /**
     * Handles the final step of the import
     */
    function handleFinalizeImportStep() {
        setCurrentStep("fill-data");
        setCurrentTab(Tabs.PARTIES);

        const updatedButtons = templateActionButtons.map(button => {
            return {
                ...button,
                disabled: false
            }
        })

        setTemplateActionButtons(updatedButtons);
    }

    /** Handle signature ordenation change */
    function handleSignatureOrdenationChange(value: string) {
        setSignatureOrdenation(value);
    };

    /** Handle contract partie signature ordenation list */
    function handleContractPartiesSignatureOrdenation(contractPartiesListSignatureOrdenation: string[]){
        setContractPartiesListSignatureOrdenation(contractPartiesListSignatureOrdenation);
    }

    return (
        <Box>
            <EditorNavbar actionButtons={templateActionButtons} title="Criação de modelo" />
            <Box sx={{ height: "88vh", width: "100vw", mt: "64px" }}>
                {
                    currentStep === "import"
                        ?
                        <Box sx={{ width: "100%", height: "100%" }}>
                            {
                                currentTab === Tabs.IMPORT
                                    ?
                                    <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                        <Typography variant="h4" sx={{ color: (theme) => theme.palette.primary.main }} >Bem vindo!</Typography>
                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.secondary.main }} mb={3} >
                                            Vamos começar a criação do seu modelo de contrato
                                        </Typography>
                                        <FileUpload onFileUpload={handleFileUpload} fileLoading={loadingImport} acceptedFileExtension={[".docx"]} />
                                    </Box>
                                    :
                                    <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
                                        {
                                            currentTab === Tabs.ERROR
                                                ?
                                                <Container sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", mt: 3 }}>
                                                    <Typography variant="h4" sx={{ color: (theme) => theme.palette.error.main }} >Erros no arquivo</Typography>
                                                    <Typography variant="h6" sx={{ color: (theme) => theme.palette.error.light }} >
                                                        Foram detectados alguns erros no arquivo que impedem o funcionamento correto dos coringas, abaixo você pode ver quais são os corigas que apresentam problemas.
                                                    </Typography>
                                                    <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.error.light, textAlign: "center" }} my={2} >
                                                        Para tirar duvidas de como se deve criar os arquivos com coringas basta <a href="https://klaus-fiscal.movidesk.com/kb/article/406396/definicao-de-informacoes-editaveis-do-modelo-de-contrato">acessar esta página!</a>
                                                    </Typography>
                                                    <Grid container spacing={4} sx={{ mt: 1, maxHeight: "200px", overflow: "auto" }}>
                                                        {
                                                            wildcardsErrors.map((wcE, i) => (
                                                                <Grid item key={i} >
                                                                    <Box
                                                                        sx={{
                                                                            border: "1px solid #d62828",
                                                                            backgroundColor: "rgba(214, 40, 40, 0.25)",
                                                                            py: 1,
                                                                            px: 2,
                                                                            borderRadius: "8px",
                                                                            display: "flex",
                                                                            alignItems: "center"
                                                                        }}
                                                                    >
                                                                        <WarningAmberRounded color="error" />
                                                                        <Typography sx={{ ml: 2 }}>{wcE}</Typography>
                                                                    </Box>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                    <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, my: 3 }} >
                                                        Envie o arquivo novamente com os coringas consertados.
                                                    </Typography>
                                                    <FileUpload fileLoading={loadingImport} onFileUpload={handleFileUpload} acceptedFileExtension={[".docx"]} />
                                                </Container>
                                                :
                                                <TabContext value={currentTab}>
                                                    <TabList onChange={(e, tab) => handleChangeTabs(e, tab)} centered variant={isMobile ? "scrollable" : "standard"} scrollButtons="auto" allowScrollButtonsMobile >
                                                        <Tab value={Tabs.PARTIES} label="Participantes" icon={<PeopleAlt />} />
                                                        <Tab value={Tabs.SET_WILDCARDS} label="Definir coringas" icon={<Tune />} disabled={parties.length <= 0 || addPartie} />
                                                        <Tab
                                                            value={Tabs.RI}
                                                            label="Campos"
                                                            icon={<Menu />}
                                                            disabled={parties.length <= 0 || requiredInformations.length <= 0 || !allWildcardsAreTyped || addPartie}
                                                        />
                                                        <Tab
                                                            value={Tabs.BILLINGS}
                                                            label="Financeiro"
                                                            icon={<AttachMoney />}
                                                            disabled={parties.length <= 0 || billings.length <= 0 || !allWildcardsAreTyped || addPartie || addRI}
                                                        />
                                                    </TabList>
                                                    <TabPanel value={Tabs.PARTIES} sx={{ maxHeight: "75vh" }}>
                                                        <Container sx={{ my: 3 }} >
                                                            <Typography variant="h4" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} mt={3} >Adicionar Participantes</Typography>
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                                Agora você precisa adicionar os participantes do contrato
                                                            </Typography>
                                                            <Parties
                                                                addPartie={addPartie}
                                                                currentCP={currentCP}
                                                                maxHeight="425px"
                                                                parties={parties}
                                                                setAddPartie={setAddPartie}
                                                                setCurrentCP={setCurrentCP}
                                                                setParties={setParties}
                                                                updateFields={updateFields}
                                                            />
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                                <Button variant="text" startIcon={<ArrowBack />} onClick={handleGoBack} >Voltar</Button>
                                                                {
                                                                    hasWildcards
                                                                        ?
                                                                        <Button variant="contained" endIcon={<ArrowForward />} disabled={parties.length <= 0 || addPartie} onClick={handleGoFoward} >
                                                                            Avançar
                                                                        </Button>
                                                                        :
                                                                        <Button
                                                                            variant="contained"
                                                                            disabled={parties.length <= 0}
                                                                            onClick={handleFinalizeImportStep}
                                                                        >
                                                                            Finalizar
                                                                        </Button>
                                                                }

                                                            </Box>
                                                        </Container>
                                                    </TabPanel>
                                                    <TabPanel value={Tabs.SET_WILDCARDS} sx={{ maxHeight: "75vh" }}>
                                                        {
                                                            !hasWildcards
                                                                ?
                                                                <Container sx={{ my: 3 }} >
                                                                    <Typography variant="h4" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} mt={3}>Nenhum coringa foi detectado no arquivo enviado</Typography>
                                                                    <Typography variant="h6" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} my={2} >
                                                                        Caso queira saber mais sobre como utilizar coringas no seu modelo <a href="https://klaus-fiscal.movidesk.com/kb/article/406396/definicao-de-informacoes-editaveis-do-modelo-de-contrato" target="_blank">acesse esta página</a>
                                                                    </Typography>
                                                                    <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                        O arquivo enviado não possui nenhum coringa, caso você tenha inserido coringas no arquivo e eles não estão aparecendo
                                                                        por favor entre em contato com o nosso suporte clicando <a href="https://cartorizi.com" target="_blank">aqui</a>
                                                                    </Typography>
                                                                </Container>
                                                                :
                                                                <Container sx={{ my: 3 }} >
                                                                    <Typography variant="h4" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} mt={3} >Definir tipo dos coringas</Typography>
                                                                    <Typography variant="h6" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                                        Agora vamos definir quais são os tipos dos corigas que foram detectados, esse passo pode ser pulado
                                                                        caso já tenha feito isso no arquivo do modelo, saiba mais sobre o uso de corigas acessando esta
                                                                        <a href="https://klaus-fiscal.movidesk.com/kb/article/406396/definicao-de-informacoes-editaveis-do-modelo-de-contrato"> página</a>
                                                                    </Typography>
                                                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                                                        <TableContainer sx={{ maxHeight: "450px" }}>
                                                                            <Table stickyHeader >
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell align="center" >
                                                                                            <Typography variant="subtitle1" >Nome do coringa</Typography>
                                                                                        </TableCell>
                                                                                        <TableCell align="center" >
                                                                                            <Typography variant="subtitle1" >Tipo do coringa</Typography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {
                                                                                        computedWildcards.map(wc => (
                                                                                            <TableRow
                                                                                                key={wc.name}
                                                                                                sx={{
                                                                                                    backgroundColor: wc.wildcardType === WildcardType.REQUIRED_INFORMATION
                                                                                                        ? "#ebe698"
                                                                                                        : wc.wildcardType === WildcardType.BILLING ? "#c6ffd6" : "#FFF"
                                                                                                }}
                                                                                            >
                                                                                                <TableCell align="center" >
                                                                                                    <Typography variant="body1" >{wc.name}</Typography>
                                                                                                </TableCell>
                                                                                                <TableCell align="center" >
                                                                                                    <FormControl>
                                                                                                        <RadioGroup
                                                                                                            row
                                                                                                            defaultValue={wc.wildcardType}
                                                                                                            onChange={(e, newValue) => handleUpdateWildcardType(wc.name, newValue)}
                                                                                                        >
                                                                                                            <FormControlLabel
                                                                                                                value={WildcardType.REQUIRED_INFORMATION}
                                                                                                                control={<Radio />}
                                                                                                                label="Campo"
                                                                                                            />
                                                                                                            <FormControlLabel
                                                                                                                value={WildcardType.BILLING}
                                                                                                                control={<Radio />}
                                                                                                                label="Financeiro"
                                                                                                            />
                                                                                                        </RadioGroup>
                                                                                                    </FormControl>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        ))
                                                                                    }
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </Paper>
                                                                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ mt: 3 }}>
                                                                        <Button variant="text" startIcon={<ArrowBack />} onClick={handleGoBack} >Voltar</Button>
                                                                        {
                                                                            hasWildcards
                                                                                ?
                                                                                <Button
                                                                                    variant="contained"
                                                                                    endIcon={<ArrowForward />}
                                                                                    disabled={parties.length <= 0 || !allWildcardsAreTyped}
                                                                                    onClick={handleGoFoward}
                                                                                >
                                                                                    Avançar
                                                                                </Button>
                                                                                :
                                                                                <Button
                                                                                    variant="contained"
                                                                                    disabled={parties.length <= 0}
                                                                                    onClick={handleFinalizeImportStep}
                                                                                >
                                                                                    Finalizar
                                                                                </Button>
                                                                        }

                                                                    </Box>
                                                                </Container>
                                                        }
                                                    </TabPanel>
                                                    <TabPanel value={Tabs.RI} sx={{ maxHeight: "75vh" }}>
                                                        <Container sx={{ mt: 3 }}>
                                                            <Typography variant="h4" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} mt={3} >Adicionar Campos</Typography>
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                                Aqui você pode adicionar campos que serão preenchidos com as informações do participante selecionado. <br /> Lembre-se de confirir se todas as informações estão corretas.
                                                            </Typography>
                                                            <RequiredInformations
                                                                addRI={addRI}
                                                                currentRI={currentRI}
                                                                parties={parties}
                                                                requiredInformations={requiredInformations}
                                                                setAddRI={setAddRI}
                                                                setCurrentRI={setCurrentRI}
                                                                setRequiredInformations={setRequiredInformations}
                                                                maxHeight="425px"
                                                            />
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                                <Button variant="text" startIcon={<ArrowBack />} onClick={handleGoBack} >Voltar</Button>
                                                                {
                                                                    billings.length > 0
                                                                        ?
                                                                        <Button variant="contained" endIcon={<ArrowForward />} disabled={!areAllRIValid} onClick={handleGoFoward} >
                                                                            Avançar
                                                                        </Button>
                                                                        :
                                                                        <Button
                                                                            variant="contained"
                                                                            disabled={!areAllRIValid}
                                                                            onClick={handleFinalizeImportStep}
                                                                        >
                                                                            Finalizar
                                                                        </Button>
                                                                }

                                                            </Box>
                                                        </Container>
                                                    </TabPanel>
                                                    <TabPanel value={Tabs.BILLINGS} sx={{ maxHeight: "75vh" }}>
                                                        <Container sx={{ mt: 3 }}>
                                                            <Typography variant="h4" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} mt={3} >Adicionar Cobranças Financeiras</Typography>
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                                Aqui você pode adicionar campos de cobranças financeiras que são utilizadas para gerar dados analíticos da sua organização na plataforma. <br /> Lembre-se de confirir se todas as informações estão corretas.
                                                            </Typography>
                                                            <Billings
                                                                addBilling={addBilling}
                                                                billings={billings}
                                                                currentBill={currentBill}
                                                                maxHeight="425px"
                                                                parties={parties}
                                                                setAddBillings={setAddBilling}
                                                                setBillings={setBillings}
                                                                setCurrentBill={setCurrentBill}
                                                            />
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                                <Button variant="text" startIcon={<ArrowBack />} onClick={handleGoBack} >Voltar</Button>
                                                                <Button
                                                                    variant="contained"
                                                                    disabled={!areAllBillingsValid}
                                                                    onClick={handleFinalizeImportStep}
                                                                >
                                                                    Finalizar
                                                                </Button>
                                                            </Box>
                                                        </Container>
                                                    </TabPanel>
                                                </TabContext>
                                        }
                                    </Box>
                            }
                        </Box>
                        :
                        <Box sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
                            {
                                !isMobile
                                    ?
                                    <Grid container>
                                        <Grid item
                                            xs={7}
                                            sx={{
                                                width: "100%",
                                                height: "100vh",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                overflow: "auto"
                                            }}
                                        >
                                            <iframe
                                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(importedFileURI ? importedFileURI : "")}`}
                                                frameBorder={0}
                                                style={{ width: "100%", height: "100%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Box sx={{ bgcolor: 'background.paper', display: 'flex', }}>
                                                <TabContext value={currentTab}>
                                                    <TabList
                                                        onChange={(e, tab) => handleChangeTabs(e, tab)}
                                                        orientation="vertical"
                                                        scrollButtons="auto"
                                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                                        variant="scrollable"
                                                        allowScrollButtonsMobile

                                                    >
                                                        <Tab value={Tabs.PARTIES} label="Participantes" icon={<PeopleAlt />} />
                                                        <Tab
                                                            value={Tabs.RI}
                                                            label="Campos"
                                                            icon={<Menu />}
                                                            disabled={parties.length <= 0 || addPartie}
                                                        />
                                                        <Tab
                                                            value={Tabs.BILLINGS}
                                                            label="Financeiro"
                                                            icon={<AttachMoney />}
                                                            disabled={parties.length <= 0 || addPartie}
                                                        />
                                                        <Tab
                                                            value={Tabs.ATTACHMENT}
                                                            label="Anexos"
                                                            icon={<AttachFile />}
                                                            disabled={parties.length <= 0 || addPartie}
                                                        />
                                                        <Tab
                                                            value={Tabs.VALIDITY}
                                                            label="Vigência"
                                                            icon={<CalendarMonth />}
                                                            disabled={parties.length <= 0 || addPartie}
                                                        />
                                                        <Tab 
                                                            value={Tabs.SIGNATURE_ORDER}
                                                            label="Ordem de Assinaturas"
                                                            icon={<FormatListNumbered/>}
                                                            disabled={parties.length <= 1 || addPartie} 
                                                        />
                                                    </TabList>
                                                    <TabPanel value={Tabs.PARTIES} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                        <Container >
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Participantes</Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                Agora você precisa adicionar os participantes do contrato
                                                            </Typography>
                                                            <Parties
                                                                addPartie={addPartie}
                                                                currentCP={currentCP}
                                                                maxHeight="600px"
                                                                parties={parties}
                                                                setAddPartie={setAddPartie}
                                                                setCurrentCP={setCurrentCP}
                                                                setParties={setParties}
                                                                updateFields={updateFields}
                                                            />
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                                                                <Button variant="outlined" endIcon={<ArrowForward />} disabled={parties.length <= 0 || addPartie} onClick={handleGoFoward} >
                                                                    Ir para campos
                                                                </Button>
                                                            </Box>
                                                        </Container>
                                                    </TabPanel>
                                                    <TabPanel value={Tabs.RI} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                        <Container >
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Campos</Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                                Aqui você pode adicionar campos que serão preenchidos com as informações do participante selecionado. <br /> Lembre-se de confirir se todas as informações estão corretas.
                                                            </Typography>
                                                            <RequiredInformations
                                                                addRI={addRI}
                                                                currentRI={currentRI}
                                                                parties={parties}
                                                                requiredInformations={requiredInformations}
                                                                setAddRI={setAddRI}
                                                                setCurrentRI={setCurrentRI}
                                                                setRequiredInformations={setRequiredInformations}
                                                                maxHeight="550px"
                                                            />
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                                                                <Button variant="outlined" endIcon={<ArrowForward />} disabled={parties.length <= 0 || addPartie} onClick={handleGoFoward} >
                                                                    Ir para financeiro
                                                                </Button>
                                                            </Box>
                                                        </Container>
                                                    </TabPanel>
                                                    <TabPanel value={Tabs.BILLINGS} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                        <Container >
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Cobranças Financeiras</Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                                Aqui você pode adicionar campos de cobranças financeiras que são utilizadas para gerar dados analíticos da sua organização na plataforma. <br /> Lembre-se de confirir se todas as informações estão corretas.
                                                            </Typography>
                                                            <Billings
                                                                addBilling={addBilling}
                                                                billings={billings}
                                                                currentBill={currentBill}
                                                                maxHeight="550px"
                                                                parties={parties}
                                                                setAddBillings={setAddBilling}
                                                                setBillings={setBillings}
                                                                setCurrentBill={setCurrentBill}
                                                            />
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                                                                <Button variant="outlined" endIcon={<ArrowForward />} disabled={parties.length <= 0 || addPartie} onClick={handleGoFoward} >
                                                                    Ir para anexos
                                                                </Button>
                                                            </Box>
                                                        </Container>
                                                    </TabPanel>
                                                    <TabPanel value={Tabs.ATTACHMENT} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                        <Container >
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Anexos</Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                Você pode adicionar anexos que serão enviados pelos participantes responsáveis
                                                            </Typography>
                                                            <Attachments
                                                                addAttachment={addAttachment}
                                                                attachments={attachments}
                                                                currentAttach={currentAttach}
                                                                parties={parties}
                                                                setAddAttachment={setAddAttachment}
                                                                setAttachments={setAttachments}
                                                                setCurrentAttach={setCurrentAttach}
                                                                maxHeight="550px"
                                                            />
                                                        </Container>
                                                    </TabPanel>
                                                    <TabPanel value={Tabs.VALIDITY} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                        <Container>
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Periódo de vida do contrato</Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                Aqui você pode definir o periódo de vigência do contratos que utilizam este modelo
                                                            </Typography>
                                                            <Validity setValidityPeriod={setValidityPeriod} validityPeriod={validityPeriod} />
                                                        </Container>
                                                    </TabPanel>
                                                    <TabPanel value={Tabs.SIGNATURE_ORDER}sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                        <Container>
                                                            <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >
                                                                Ordem de assinatura
                                                            </Typography>
                                                            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                                Defina a ordem em que os contratos precisam ser assinados.
                                                            </Typography>
                                                            <SignatureOrder parties={parties} signatureOrdenation={signatureOrdenation} 
                                                                onSignatureOrdenationChange={handleSignatureOrdenationChange} onContractPartiesSignatureOrdenation={handleContractPartiesSignatureOrdenation}/>
                                                        </Container>
                                                    </TabPanel>
                                                </TabContext>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    :
                                    perspective === "document"
                                        ?
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "100vh",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                overflow: "auto"
                                            }}
                                        >
                                            <iframe
                                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(importedFileURI ? importedFileURI : "")}`}
                                                frameBorder={0}
                                                style={{ width: "100%", height: "100%" }}
                                            />
                                        </Box>
                                        :
                                        <Box sx={{ bgcolor: 'background.paper' }}>
                                            <TabContext value={currentTab}>
                                                <TabList
                                                    onChange={(e, tab) => handleChangeTabs(e, tab)}
                                                    scrollButtons="auto"
                                                    orientation="horizontal"
                                                    variant="scrollable"
                                                    allowScrollButtonsMobile

                                                >
                                                    <Tab value={Tabs.PARTIES} label="Participantes" icon={<PeopleAlt />} />
                                                    <Tab
                                                        value={Tabs.RI}
                                                        label="Campos"
                                                        icon={<Menu />}
                                                        disabled={parties.length <= 0 || addPartie}
                                                    />
                                                    <Tab
                                                        value={Tabs.BILLINGS}
                                                        label="Financeiro"
                                                        icon={<AttachMoney />}
                                                        disabled={parties.length <= 0 || addPartie}
                                                    />
                                                    <Tab
                                                        value={Tabs.ATTACHMENT}
                                                        label="Anexos"
                                                        icon={<AttachFile />}
                                                        disabled={parties.length <= 0 || addPartie}
                                                    />
                                                    <Tab
                                                        value={Tabs.VALIDITY}
                                                        label="Vigência"
                                                        icon={<CalendarMonth />}
                                                        disabled={parties.length <= 0 || addPartie}
                                                    />
                                                </TabList>
                                                <TabPanel value={Tabs.PARTIES} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container >
                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Participantes</Typography>
                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                            Agora você precisa adicionar os participantes do contrato
                                                        </Typography>
                                                        <Parties
                                                            addPartie={addPartie}
                                                            currentCP={currentCP}
                                                            maxHeight="600px"
                                                            parties={parties}
                                                            setAddPartie={setAddPartie}
                                                            setCurrentCP={setCurrentCP}
                                                            setParties={setParties}
                                                            updateFields={updateFields}
                                                        />
                                                        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                                                            <Button variant="outlined" endIcon={<ArrowForward />} disabled={parties.length <= 0 || addPartie} onClick={handleGoFoward} >
                                                                Ir para campos
                                                            </Button>
                                                        </Box>
                                                    </Container>
                                                </TabPanel>
                                                <TabPanel value={Tabs.RI} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container >
                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Campos</Typography>
                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                            Aqui você pode adicionar campos que serão preenchidos com as informações do participante selecionado. <br /> Lembre-se de confirir se todas as informações estão corretas.
                                                        </Typography>
                                                        <RequiredInformations
                                                            addRI={addRI}
                                                            currentRI={currentRI}
                                                            parties={parties}
                                                            requiredInformations={requiredInformations}
                                                            setAddRI={setAddRI}
                                                            setCurrentRI={setCurrentRI}
                                                            setRequiredInformations={setRequiredInformations}
                                                            maxHeight="550px"
                                                        />
                                                        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                                                            <Button variant="outlined" endIcon={<ArrowForward />} disabled={parties.length <= 0 || addPartie} onClick={handleGoFoward} >
                                                                Ir para financeiro
                                                            </Button>
                                                        </Box>
                                                    </Container>
                                                </TabPanel>
                                                <TabPanel value={Tabs.BILLINGS} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container >
                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Cobranças Financeiras</Typography>
                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mt={2} mb={3} >
                                                            Aqui você pode adicionar campos de cobranças financeiras que são utilizadas para gerar dados analíticos da sua organização na plataforma. <br /> Lembre-se de confirir se todas as informações estão corretas.
                                                        </Typography>
                                                        <Billings
                                                            addBilling={addBilling}
                                                            billings={billings}
                                                            currentBill={currentBill}
                                                            maxHeight="550px"
                                                            parties={parties}
                                                            setAddBillings={setAddBilling}
                                                            setBillings={setBillings}
                                                            setCurrentBill={setCurrentBill}
                                                        />
                                                        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                                                            <Button variant="outlined" endIcon={<ArrowForward />} disabled={parties.length <= 0 || addPartie} onClick={handleGoFoward} >
                                                                Ir para anexos
                                                            </Button>
                                                        </Box>
                                                    </Container>
                                                </TabPanel>
                                                <TabPanel value={Tabs.ATTACHMENT} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container >
                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Adicionar Anexos</Typography>
                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                            Você pode adicionar anexos que serão enviados pelos participantes responsáveis
                                                        </Typography>
                                                        <Attachments
                                                            addAttachment={addAttachment}
                                                            attachments={attachments}
                                                            currentAttach={currentAttach}
                                                            parties={parties}
                                                            setAddAttachment={setAddAttachment}
                                                            setAttachments={setAttachments}
                                                            setCurrentAttach={setCurrentAttach}
                                                            maxHeight="550px"
                                                        />
                                                    </Container>
                                                </TabPanel>
                                                <TabPanel value={Tabs.VALIDITY} sx={{ maxHeight: "80vh", maxWidth: "600px", width: "100%", overflow: "auto" }}>
                                                    <Container>
                                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, textAlign: "center" }} >Periódo de vida do contrato</Typography>
                                                        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }} mb={3} >
                                                            Aqui você pode definir o periódo de vigência do contratos que utilizam este modelo
                                                        </Typography>
                                                        <Validity setValidityPeriod={setValidityPeriod} validityPeriod={validityPeriod} />
                                                    </Container>
                                                </TabPanel>

                                            </TabContext>
                                        </Box>
                            }
                        </Box>

                }
            </Box>
            {
                isMobile && currentStep !== "import"
                    ?
                    <ChangePerspectiveButtons
                        perspective={perspective}
                        setPerspective={setPerspective}
                    />
                    :
                    <></>
            }
            {
                templateDTO
                    ?
                    <TemplateDataReview
                        mode="create"
                        open={openTemplateDataReview}
                        setOpen={setOpenTemplateDataReview}
                        setTemplateDTO={setTemplateDTO}
                        templateDTO={templateDTO}
                        templateFile={templateFile}
                    />
                    :
                    <></>
            }
            <EditTemplateFileDialog open={openEditTemplateFile} setOpen={setOpenEditTemplateFile} confirmEdit={handleEditFile} />
        </Box>
    )
}

export default CreateTemplate;