/**
 * Remove the special characters of the provided string
 * @param value 
 * @returns 
 */
export function SpecialCharacterRemover(value: string) {
    return value.replace(/[^\w\s]/gi, "")
}

/**
 * Only return the numbers of the CPF or CNPJ
 * @param value 
 * @returns 
 */
export function CleanCPFOrCNPJ(value: string) {
    return value.replace(/\D/g, '')
}