import ExcelJS, { Alignment } from 'exceljs';
import { FinanceCharge, SubscriptionCharge } from '../../../../services/finance';
import { DigitalContractTemplate, TotalAmountOfBillingDataAnalyticsWithGranularity } from '../../../../models/contracts';
import React, { useEffect } from "react";

interface ExcelCell {
    value: number,
    label: string,
    template?: string,
    billingName?: string,
    state?: string,
}

interface NormalizedExcellCells {
    excelCells: ExcelCell[],
    startDate: Date,
    endDate: Date,
    financeCharges?: FinanceCharge[],
    subscriptionCharges?: SubscriptionCharge[],
    analyticsData?: TotalAmountOfBillingDataAnalyticsWithGranularity[],
    templates?: DigitalContractTemplate[],
    onComplete: () => void;
}

/**
 * Receive the normalized Excel Cells and create an Excel file with the data received.
 * @param props 
 * @returns 
 */
const ExportFinancialManagementData: React.FC<NormalizedExcellCells> = (props) => {
    useEffect(() => {
        // Header pattern
        const period = 'Período ' + "- (" + new Date(props.startDate).toLocaleDateString() + " - " + new Date(props.endDate).toLocaleDateString() + ")";
        const billingDateFormat = 'Data de cobrança ' + "- (" + new Date(props.startDate).toLocaleDateString() + " - " + new Date(props.endDate).toLocaleDateString() + ")";
        const dueDateFormat = 'Dia do vencimento ' + "- (" + new Date(props.startDate).toLocaleDateString() + " - " + new Date(props.endDate).toLocaleDateString() + ")";

        // Style
        const columnAligment: Partial<Alignment> = { vertical: 'middle', horizontal: 'center' };
        const headerFont = { name: 'Calibri', family: 2, size: 12, italic: true };
        const headerBorder: Partial<ExcelJS.Borders> = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        const headerTheme: ExcelJS.Fill = { type: 'pattern', pattern: 'darkTrellis', fgColor: { argb: '999999ff' }, bgColor: { argb: '999999ff' } };
        const billingFormat = '_(R$* #,##0.00_);_(R$* (#,##0.00);_(R$* "-"??_);_(@_)';

        // Worksheets
        const workbook = new ExcelJS.Workbook();
        const financeData = workbook.addWorksheet('Total de entradas por período');

        // If the finance charge was received as a prop, create the Finance Charge worksheet
        if (props.financeCharges) {
            const statementPerPeriod = workbook.addWorksheet('Extrato - Cobranças por período');

            statementPerPeriod.columns = [
                { header: billingDateFormat, key: "date", width: 46, style: { numFmt: 'dd/mm/yyyy' } },
                { header: 'Valor', key: 'financeValue', width: 20, style: { numFmt: billingFormat } },
                { header: 'Cobrança', key: 'name', width: 20 },
                { header: 'Modelo', key: 'template', width: 30 },
            ];

            // Fill the statement per period with the data received
            props.financeCharges?.forEach((fc) => {
                const date = new Date(fc.dueDate).toLocaleDateString();
                const financeValue = fc.value / 100;
                let template = "";
                if (props.templates) {
                    props.templates.forEach(temp => {
                        if (temp.id === fc.productId) {
                            template = temp.name;
                        }
                    })
                } else {
                    template = fc.productId
                }
                statementPerPeriod.addRow({ financeValue, date, template, ...fc });
            })

            // Personalization
            statementPerPeriod.getColumn(1).alignment = columnAligment;
            statementPerPeriod.getColumn(2).alignment = columnAligment;
            statementPerPeriod.getColumn(3).alignment = columnAligment;
            statementPerPeriod.getColumn(4).alignment = columnAligment;
            statementPerPeriod.getCell('A1').font = headerFont;
            statementPerPeriod.getCell('B1').font = headerFont;
            statementPerPeriod.getCell('C1').font = headerFont;
            statementPerPeriod.getCell('D1').font = headerFont;
            statementPerPeriod.getCell('A1').border = headerBorder;
            statementPerPeriod.getCell('B1').border = headerBorder;
            statementPerPeriod.getCell('C1').border = headerBorder;
            statementPerPeriod.getCell('D1').border = headerBorder;
            statementPerPeriod.getCell('A1').fill = headerTheme;
            statementPerPeriod.getCell('B1').fill = headerTheme;
            statementPerPeriod.getCell('C1').fill = headerTheme;
            statementPerPeriod.getCell('D1').fill = headerTheme;
        }

        // If the subscription charge was received as a prop, create the Subscription Charge worksheet
        if (props.subscriptionCharges) {
            const statementRecurring = workbook.addWorksheet('Extrato - Cobranças recorrentes');

            statementRecurring.columns = [
                { header: dueDateFormat, key: 'billingDueDay', width: 46 },
                { header: 'Valor', key: 'subscriptionValue', width: 20, style: { numFmt: billingFormat } },
                { header: 'Cobrança', key: 'name', width: 20 },
                { header: 'Modelo', key: 'template', width: 30 },
            ];
            // Fill the statement recurring period with the data received
            props.subscriptionCharges?.forEach((sc) => {
                const subscriptionValue = sc.value / 100;
                let template = "";
                if (props.templates) {
                    props.templates.forEach(temp => {
                        if (temp.id === sc.productId) {
                            template = temp.name;
                        }
                    })
                } else {
                    template = sc.productId
                }
                statementRecurring.addRow({ subscriptionValue, template, ...sc })
            })

            // Personalization
            statementRecurring.getColumn(1).alignment = columnAligment;
            statementRecurring.getColumn(2).alignment = columnAligment;
            statementRecurring.getColumn(3).alignment = columnAligment;
            statementRecurring.getColumn(4).alignment = columnAligment;
            statementRecurring.getCell('A1').font = headerFont;
            statementRecurring.getCell('B1').font = headerFont;
            statementRecurring.getCell('C1').font = headerFont;
            statementRecurring.getCell('D1').font = headerFont;
            statementRecurring.getCell('A1').border = headerBorder;
            statementRecurring.getCell('B1').border = headerBorder;
            statementRecurring.getCell('C1').border = headerBorder;
            statementRecurring.getCell('D1').border = headerBorder;
            statementRecurring.getCell('A1').fill = headerTheme;
            statementRecurring.getCell('B1').fill = headerTheme;
            statementRecurring.getCell('C1').fill = headerTheme;
            statementRecurring.getCell('D1').fill = headerTheme;
        }
        // Worksheets Headers and respective values attribuation  
        if (props.excelCells[0].template && props.excelCells[0].billingName && props.excelCells[0].state) {
            financeData.columns = [
                { header: period, key: 'label', width: 40, style: { numFmt: 'mmmm-yy' } },
                { header: 'Valor', key: 'value', width: 20, style: { numFmt: billingFormat } },
                { header: 'Nome da cobrança', key: 'billingName', width: 20, },
                { header: 'Modelo', key: 'template', width: 30 },
                { header: 'Estado', key: 'state', width: 40 },
            ];

            financeData.getColumn(3).alignment = columnAligment;
            financeData.getColumn(4).alignment = columnAligment;
            financeData.getColumn(5).alignment = columnAligment;
            financeData.getCell('C1').font = headerFont;
            financeData.getCell('D1').font = headerFont;
            financeData.getCell('E1').font = headerFont;
            financeData.getCell('C1').border = headerBorder;
            financeData.getCell('D1').border = headerBorder;
            financeData.getCell('E1').border = headerBorder;
            financeData.getCell('C1').fill = headerTheme;
            financeData.getCell('D1').fill = headerTheme;
            financeData.getCell('E1').fill = headerTheme;
        }
        else {
            financeData.columns = [
                { header: period, key: 'label', width: 40, style: { numFmt: 'mmmm-yy' } },
                { header: 'Valor', key: 'value', width: 20, style: { numFmt: billingFormat } },
            ];
        }


        // Fill the finance data per period with the data received
        props.excelCells.forEach((cell) => {
            financeData.addRow(cell);
        });

        // Style
        financeData.getColumn(1).alignment = columnAligment;
        financeData.getColumn(2).alignment = columnAligment;
        financeData.getCell('A1').font = headerFont;
        financeData.getCell('B1').font = headerFont;
        financeData.getCell('A1').border = headerBorder;
        financeData.getCell('B1').border = headerBorder;
        financeData.getCell('A1').fill = headerTheme;
        financeData.getCell('B1').fill = headerTheme;

        // Save excel file
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Dados Financeiros Exportados.xlsx';
            a.click();
            // Libera o objeto URL criado
            URL.revokeObjectURL(url);
        });
        props.onComplete()
    }, []);

    return null;
}
export default ExportFinancialManagementData;