import { AlternateEmail, Business, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, IconButton, ListItemIcon, Typography } from "@mui/material";
import { useState } from "react";
import { FileIssueContractPartie, IdentificationType } from "../../../../../../models/contracts";
import AddFieldButton from "../../../components/AddFieldButton";
import IssuePartyForm from "../../../issue/issue-from-file/components/forms/IssuePartyForm";


// Interfaces and Types
interface ExternalPartiesProps extends React.ComponentProps<any> {
    addPartie: boolean,
    currentCP: FileIssueContractPartie | null,
    parties: FileIssueContractPartie[],
    setAddPartie: (value: boolean) => void
    setCurrentCP: (value: FileIssueContractPartie | null) => void;
    setParties: (value: FileIssueContractPartie[]) => void,
    updateFields: (currentValue: FileIssueContractPartie, newValue: FileIssueContractPartie) => void
}

const ExternalParties = (props: ExternalPartiesProps): JSX.Element => {

    // Destructure props object
    const { addPartie, currentCP, parties, setAddPartie, setCurrentCP, setParties, updateFields } = props;

    // Data states
    const [partiesIdentification, setPartiesIdentification] = useState<string[]>([]);

    // Handler functions
    function handleDeleteParty(party: FileIssueContractPartie) {
        const filteredParties = parties.filter(p => p.role !== party.role);
        setParties(filteredParties);
    }

    function handleAddParty(contractParty: FileIssueContractPartie, id: string) {
        const sortedParties = [...parties, contractParty];
        
        const updatedIds = [...partiesIdentification, id];

        setPartiesIdentification(updatedIds);
        setParties(sortedParties.sort((a, b) => a.role.localeCompare(b.role)));
        setAddPartie(false);
    }

    function handleEditParty(contractParty: FileIssueContractPartie, id: string) {
        if (!currentCP) return;

        const updatedParties = parties.map(cp => {
            if (cp.role === currentCP.role) {
                const updatedParty: FileIssueContractPartie = {
                    identification: contractParty.identification,
                    contractPartyIdentificationType: contractParty.contractPartyIdentificationType,
                    role: contractParty.role,
                    comment: contractParty.comment,
                }

                return updatedParty;
            }
            return cp;
        });
        const updatedIds = [...partiesIdentification, id];

        updateFields(currentCP, contractParty);
        setPartiesIdentification(updatedIds);
        setParties(updatedParties);
        setCurrentCP(null);
    }


    return (
        <Box sx={{ maxHeight: "600px", overflow: "auto" }}>
            {
                addPartie
                    ?
                    <IssuePartyForm
                        addParty={handleAddParty}
                        cancelAction={() => setAddPartie(false)}
                        parties={parties}
                        partiesId={partiesIdentification}
                    />
                    :
                    <AddFieldButton
                        disabled={addPartie || currentCP !== null}
                        onClick={() => setAddPartie(true)}
                        title="Adicionar Participante"
                    />
            }
            {
                parties.map(party => (
                    <>
                        {
                            currentCP && currentCP.role === party.role
                                ?
                                <IssuePartyForm
                                    addParty={handleEditParty}
                                    cancelAction={() => setCurrentCP(null)}
                                    parties={parties}
                                    currentCP={currentCP}
                                    partiesId={partiesIdentification}
                                />
                                :
                                <Box
                                    key={party.role}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        py: 1,
                                        px: 2,
                                        border: "1px solid rgba(0, 0, 0, 0.25)",
                                        borderRadius: "5px",
                                        mb: 3
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <ListItemIcon>
                                            {
                                                party.contractPartyIdentificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                                    ?
                                                    <AlternateEmail color="primary" />
                                                    :
                                                    <Business color="primary" />
                                            }
                                        </ListItemIcon>
                                        <Typography >{party.role}</Typography>
                                    </Box>
                                    <Box>
                                        <IconButton onClick={() => setCurrentCP(party)} >
                                            <EditOutlined color="primary" />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteParty(party)} >
                                            <DeleteOutline color="error" />
                                        </IconButton>
                                    </Box>
                                </Box>
                        }
                    </>
                ))
            }
        </Box>
    )
}

export default ExternalParties;