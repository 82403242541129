import { AlternateEmail, Business } from "@mui/icons-material";
import { Autocomplete, Box, Button, FormControl, FormHelperText, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import SearchUsers from "../../../../../../../components/SearchUsers";
import AuthenticationContext from "../../../../../../../contexts/authentication";
import { FileIssueContractPartie, IdentificationType, } from "../../../../../../../models/contracts";
import OrganizationService from "../../../../../../../services/organization";
import { ContractPartiesSuggestions, EditorSearchResult, EditorSearchResultType } from "../../../../../../../utils/editorUtils";

// Interfaces and Types
interface IssuePartyFormProps extends React.ComponentProps<any> {
    currentCP?: FileIssueContractPartie,
    parties: FileIssueContractPartie[],
    partiesId: string[],
    addParty: (contractParty: FileIssueContractPartie, id: string) => void,
    cancelAction: () => void,
}

interface PartyIdentification {
    value: IdentificationType,
    label: string
}

// Default values
const partyIdentificationOptions: PartyIdentification[] = [
    {
        value: IdentificationType.IDENTIFIED_BY_EMAIL,
        label: "Identificado por E-mail"
    },
    {
        value: IdentificationType.PLATFORM_USER,
        label: "Membro da Organização"
    }
]

const defaultIssueCP: FileIssueContractPartie = {
    identification: {},
    contractPartyIdentificationType: IdentificationType.IDENTIFIED_BY_EMAIL,
    role: "",
    comment: "",
}

const IssuePartyForm = (props: IssuePartyFormProps): JSX.Element => {

    // Destructure props object
    const { addParty, cancelAction, parties, currentCP, partiesId } = props;

    // Contexts
    const auth = useContext(AuthenticationContext);

    // Data states
    const [newCP, setNewCP] = useState<FileIssueContractPartie>(currentCP ? currentCP : defaultIssueCP);
    const [newCPErrors, setNewCPErrors] = useState<any>({});
    const [partyRole, setPartyRole] = useState("");
    const [selectedMember, setSelectedMember] = useState("");
    const [partyId, setPartyId] = useState("");

    // UseEffects
    useEffect(() => {
        fetchPartyIdentification();
    }, [])

    useEffect(() => {
        checkForErrors();
    }, [newCP]);

    // Functions
    function checkForErrors() {

        const newErrors = { ...newCPErrors }

        // delete all errors
        for (const key in newErrors) {
            delete newErrors[key];
        }

        if (currentCP?.role !== newCP.role) {

            //check if a party with the same role exists
            //if it does display error
            const filter = parties.filter(party => party.role.toLocaleLowerCase() === newCP.role.toLocaleLowerCase());
            if (filter.length > 0) newErrors["role"] = "Participante já adicionado";
            else if (newCP.role.length === 0) newErrors["role"] = "Campo obrigatório";
        }

        // check if the contractPartyIdentificationType is present
        if (!newCP.contractPartyIdentificationType) newErrors["contractPartyIdentificationType"] = "Selecione uma opção de assinatura";

        // check if the contractPartyIdentificationType is a organization user and if the identification is present
        if (newCP.contractPartyIdentificationType === IdentificationType.PLATFORM_USER && !newCP.identification.uuid) newErrors["identification"] = "Selecione um membro";

        // check if the contractPartyIdentificationType is identified by email
        if (newCP.contractPartyIdentificationType === IdentificationType.IDENTIFIED_BY_EMAIL) {
            // check if there is a e-mail and if the value is a valid e-mail format
            if (!newCP.identification.email) newErrors["identification"] = "Campo obrigatório";
            else if (!/^[^\s@]+@[^\s@,]+\.[^\s@,]+$/.test(newCP.identification.email)) newErrors["identification"] = "Insira um e-mail válido";
        }

        parties.forEach(party => {
            const partyId = Object.values(party.identification);
            const newCPId = Object.values(newCP.identification);

            if (party.role !== newCP.role && newCPId.find(id => partyId.includes(id))) newErrors["identification"] = "Participante já adicionado";
            else if (party.role !== newCP.role && newCPId.find(id => partiesId.includes(id))) newErrors["identification"] = "Participante já adicionado";
        })

        setNewCPErrors(newErrors);
    }

    function fetchPartyIdentification() {
        if (!currentCP) return;


        if (currentCP.identification.uuid) {
            if (auth.user().account.uuid === currentCP.identification.uuid) {
                setSelectedMember(`${auth.user().account.preferredName} (Você)`);
            }
            OrganizationService.fetchOrganizationMemberByUuid(currentCP.identification.uuid)
                .then(response => {
                    setSelectedMember(response.preferredName);
                })
                .catch(e => {
                    console.error(e);

                });
        } else if (currentCP.identification.email) {
            setSelectedMember(currentCP.identification.email);
        }
    }

    // Handler functions
    function handleChangeContractPartyIdentification(newValue: string | null) {
        if (!newValue) {
            setPartyRole("");
            return;
        }

        let role = newValue.trim();

        const filter = parties.filter(party => party.role.includes(role));

        if (filter.length === 0) setPartyRole(role);
        else {
            role = `${role} ${filter.length + 1}`
            setPartyRole(role);
        }
    }

    function handleChangeIdentificationType(event: SelectChangeEvent<any>) {

        const idType = event.target.value;

        if (!idType) return;

        setNewCP({ ...newCP, contractPartyIdentificationType: idType, identification: {} });
        setSelectedMember("");
    }

    function handleAutocompleteTextFieldChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setSelectedMember(e.target.value);
    }

    function handleSelectMember(option: EditorSearchResult) {
        switch (option.type) {
            case EditorSearchResultType.ORGANIZATION_ACCOUNT:
            case EditorSearchResultType.ORGANIZATION_SUBUSER_ACCOUNT:
                setNewCP({ ...newCP, identification: { uuid: option.id }, contractPartyIdentificationType: IdentificationType.PLATFORM_USER });
                setPartyId(option.object.email);
                break;
        }

        setSelectedMember(option.description);
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                p: 4,
                border: "1px solid rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
                mt: 2,
                mb: 3
            }}
        >
            <Autocomplete
                fullWidth
                value={newCP.role}
                options={ContractPartiesSuggestions}
                groupBy={(option) => "Sugestões de Participantes"}
                onChange={(event: any, newValue: string | null) => handleChangeContractPartyIdentification(newValue)}
                onBlur={() => {
                    setNewCP({ ...newCP, role: partyRole.trim() });
                }}
                inputValue={partyRole}
                onInputChange={(event, newInputValue) => {
                    setPartyRole(newInputValue);
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label="Identificação do Participante"
                        required
                        error={Boolean(newCPErrors["role"])}
                        helperText={newCPErrors["role"]}
                    />
                }
            />
            <FormControl fullWidth sx={{ my: 6 }} required error={Boolean(newCPErrors["contractPartyIdentificationType"])} >
                <InputLabel id="party-identication-type" >Como o contrato será assinado</InputLabel>
                <Select
                    labelId="party-identication-type"
                    label="Como o contrato será assinado"
                    fullWidth
                    value={newCP.contractPartyIdentificationType}
                    renderValue={(value) => {
                        switch (value) {
                            case IdentificationType.IDENTIFIED_BY_EMAIL:
                                return "Identificado por E-mail"
                            default:
                                return "Membro da Organização";
                        }
                    }}
                    placeholder="Como o contrato será assinado"
                    onChange={handleChangeIdentificationType}
                    startAdornment={
                        newCP.contractPartyIdentificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                            ?
                            <AlternateEmail sx={{ mr: 2 }} color="primary" />
                            :
                            newCP.contractPartyIdentificationType
                                ?
                                <Business sx={{ mr: 2 }} color="primary" />
                                :
                                <></>

                    }
                    required
                    error={Boolean(newCPErrors["contractPartyIdentificationType"])}
                >
                    {
                        partyIdentificationOptions.map(option => (
                            <MenuItem value={option.value} key={option.value}>
                                {
                                    option.value === IdentificationType.IDENTIFIED_BY_EMAIL
                                        ?
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                            <ListItemIcon>
                                                <AlternateEmail sx={{ mr: 2 }} />
                                            </ListItemIcon>
                                            <ListItemText >{option.label}</ListItemText>
                                        </Box>
                                        :
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                            <ListItemIcon>
                                                <Business sx={{ mr: 2 }} />
                                            </ListItemIcon>
                                            <ListItemText >{option.label}</ListItemText>
                                        </Box>
                                }
                            </MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText>{newCPErrors["contractPartyIdentificationType"] ? newCPErrors["contractPartyIdentificationType"] : ""}</FormHelperText>
            </FormControl>
            {
                newCP.contractPartyIdentificationType === IdentificationType.PLATFORM_USER
                    ?
                    <SearchUsers
                        errors={newCPErrors}
                        inputChange={handleAutocompleteTextFieldChange}
                        onResourceClick={handleSelectMember}
                        value={selectedMember}
                        limiter="platform-users"
                    />
                    :
                    <TextField
                        fullWidth
                        required
                        label="Insira o e-mail deste participante"
                        type="email"
                        value={selectedMember}
                        onChange={(e) => {
                            setSelectedMember(e.target.value.trim())
                            setNewCP({ ...newCP, identification: { email: e.target.value.trim() } })
                        }}
                        error={newCPErrors["identification"]}
                        helperText={newCPErrors["identification"]}
                        sx={{ mb: 6 }}
                    />

            }
            <TextField
                fullWidth
                multiline
                minRows={3}
                maxRows={5}
                label="Adicionar comentário para o participante"
                value={newCP.comment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewCP({ ...newCP, comment: event.target.value })}
                inputProps={{ maxLength: 1500 }}
                helperText={`${newCP.comment ? newCP.comment.length : 0} / 1500`}
                sx={{ mb: 3 }}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                <Button variant="text" onClick={cancelAction} >Cancelar</Button>
                <Button variant="contained" disabled={Object.keys(newCPErrors).length > 0} onClick={() => addParty(newCP, partyId)} >Salvar</Button>
            </Box>
        </Box>
    )
}

export default IssuePartyForm;