import { Box, SxProps, Theme, Typography } from "@mui/material"

interface ProgressBarProps {
    percentageString: string,
    currentValue: string | number,
    maxValue: string | number
}

const ProgressBar = (props: ProgressBarProps): JSX.Element => {

    const handleColorChangeByPercentage = (): string => {
        const percentage = Number(props.percentageString)
        if (percentage >= 90) {
            return "#e62e30"
        } else if (percentage >= 75 && percentage < 90) {
            return "#f18032"
        } else if (percentage >= 50 && percentage < 75) {
            return "#ffd334"
        } else if (percentage >= 25 && percentage < 50) {
            return "#81c02b"
        } else {
            return "#01ad23"
        }
    }


    const barTheme: SxProps<Theme> = {
        width: 0 || props.percentageString + "%",
        height: "24px",
        backgroundColor: handleColorChangeByPercentage(),
        borderRadius: "5px"
    }

    return (
        <Box sx={{display: "flex", width: "80%"}}>
        <Box sx={{width: "80%", height: "24px", backgroundColor: "#CCCCCC", borderRadius: "5px", marginLeft: "2rem", border: "1px solid #00000066"}}>
            <Box sx={barTheme}></Box>
        </Box>
            <Typography ml={2}>{props.currentValue} / {props.maxValue}</Typography>
        </Box>
    )
}

export default ProgressBar