import { AttachFile, AttachMoney, CheckOutlined, CloseOutlined, FileDownload, Loop, Mail, Share, VerifiedUser } from "@mui/icons-material";
import { TabContext, TabList, TabPanel, TimelineOppositeContent } from "@mui/lab";
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Alert, Avatar, Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Tab, TextField, Tooltip, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { ContractPartyActivityModel, DigitalContractPartyActivityModel } from "../../models/contract-parties-activities";
import { ApproveOrReproveAttachment, AttachmentSpecification, AttachmentStatus, DigitalContractAttachment, DigitalContractBilling, DigitalContractInputFileType, DigitalContractTemplate, EditableDigitalContract, IdentifiedByEmailContractParty, PlatformUserContractParty, RequiredContractPartyInformation, UserInformation } from "../../models/contracts";
import { AccountRoles, UserAccount } from "../../models/user";
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../redux/hooks";
import ContractPartiesActivitiesService from "../../services/contract-party-activity";
import ContractsService from "../../services/contracts";
import OrganizationService from "../../services/organization";
import authentication from "../../store/authentication";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { CurrencyValue, SupportedCurrencies } from "../../utils/currency";
import ShareContractDialog from "./ShareContractDialog";

interface EditableDigitalContractDialogContentProps extends React.ComponentProps<any> {
    source: EditableDigitalContract
}

interface OrganizationMemberMap {
    [uuid: string]: UserAccount
}

interface RequiredInformation {
    requiredInformation: RequiredContractPartyInformation,
    userInformation: UserInformation | undefined,
}

interface RequiredAttachment {
    requiredAttachment: AttachmentSpecification;
    userAttachment: DigitalContractAttachment | undefined;
}

function ContractPartyActivitiesType(contractPartyActivity: ContractPartyActivityModel | undefined): JSX.Element {
    if ((contractPartyActivity as DigitalContractPartyActivityModel).contractId) {

        switch (contractPartyActivity?.type) {
            case 'EDITABLE_CONTRACT_CREATED':
                return (
                    <Typography sx={{ color: (theme) => theme.palette.primary.main, fontWeight: "600", marginRight: "10px" }}><b>[{contractPartyActivity.contractParty.role}] - </b> Data de criação do contrato editável: </Typography>
                )
            case 'ATTACHMENT_UPDATED':
                return (
                    <Typography sx={{ color: "#212121", fontWeight: "600", marginRight: "10px" }}><b>[{contractPartyActivity.contractParty.role}] - </b> Anexo Atualizado: </Typography>
                )
            case 'USER_INFORMATION_UPDATED':
                return (
                    <Typography sx={{ color: "#212121", fontWeight: "600", marginRight: "10px" }}><b>[{contractPartyActivity.contractParty.role}] - </b> Informações do usuário atualizadas: </Typography>
                )
            case 'CONTRACT_PARTY_SIGNED_CONTRACT':
                return (
                    <Typography sx={{ color: "#212121", fontWeight: "600", marginRight: "10px" }}><b>[{contractPartyActivity.contractParty.role}] - </b> Contrato assinado: </Typography>
                )
            case 'CONTRACT_WAS_ISSUED':
                return (
                    <Typography sx={{ color: "#212121", fontWeight: "600", marginRight: "10px" }}><b>[{contractPartyActivity.contractParty.role}] - </b> O estado do contrato foi alterado: </Typography>
                )
            default: return <></>
        }
    }
    else {
        return <></>
    }
}

const EditableDigitalContractDialogContent = (props: EditableDigitalContractDialogContentProps): JSX.Element => {
    // states
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState("0");
    const [userInformationTab, setUserInformationTab] = useState<string>("");
    const [organizationMembersMap, setOrganizationMembersMap] = useState<OrganizationMemberMap>({});
    const [userInformationContractPartiesRoles, setUserInformationContractPartiesRoles] = useState<string[]>([]);
    const [requiredInformations, setRequiredInformations] = useState<RequiredInformation[]>([]);
    const [requiredAttachments, setRequiredAttachments] = useState<RequiredAttachment[]>([]);
    const [loadingTemplateFile, setLoadingTemplateFile] = useState(true);
    const [contractPartyActivitiesLogs, setContractPartyActivitiesLogs] = useState<ContractPartyActivityModel[]>([]);
    const [templateDownloadLink, setTemplateDownloadLink] = useState("");
    const [reproveReasonDialog, setReproveReasonDialog] = useState<{ [name: string]: boolean }>({});
    const [reproveReason, setReproveReason] = useState('');
    const [loadingAttachmentApproved, setLoadingAttachmentApproved] = useState(false);
    const [loadingAttachmentReproved, setLoadingAttachmentReproved] = useState(false);

    React.useEffect(() => {
        if (props.source.template.templateInputFile.type === DigitalContractInputFileType.WORD_1_0) {
            fetchTemplateDownloadLink();
        }
    }, [])

    const fetchTemplateDownloadLink = async () => {
        const url = (await ContractsService.createDigitalContractTemplateDownloadTemplateFileDownloadUri(props.source.template.id as string)).uri
        const encodedUrl = encodeURIComponent(url)
        setTemplateDownloadLink(encodedUrl);
    }
    const contract = props.source;

    //Context props
    const notification = useAppDispatch();

    // effect used to load the userInformationContractParties state prop
    React.useEffect(() => {
        // ignore if the user information array is null or empty
        const requiredInformations = (props.source.template.requiredContractPartiesInformation as RequiredContractPartyInformation[]);
        if (!requiredInformations || requiredInformations.length == 0) return;

        // map the contract parties roles from the required informations from the template
        // also do a filter to remove duplicated entries
        let contractPartiesRoles = requiredInformations
            .map(ui => ui.contractParty.role)
            .filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });


        // update the state value
        setUserInformationContractPartiesRoles(contractPartiesRoles);
        setUserInformationTab(contractPartiesRoles[0]);
    }, []);

    // effect used to load the requiredInformations state prop
    React.useEffect(() => {
        // ignore if the user information array is null or empty
        if (!props.source.template.requiredContractPartiesInformation ||
            props.source.template.requiredContractPartiesInformation.length == 0) {
            return;
        }

        // get the user information already set on the contract
        const userInformations = (props.source.userInformation) ? props.source.userInformation : [];

        // set the required informations state based on the union of the 
        // required informations from the template and the user information already set on the contract
        const requiredInformations =
            (props.source.template.requiredContractPartiesInformation as RequiredContractPartyInformation[])
                .map(requiredInformation => ({
                    requiredInformation,
                    userInformation: userInformations.find(ui => ui.requiredInformation.requiredInformation.name === requiredInformation.requiredInformation.name)
                }));

        setRequiredInformations(requiredInformations);
    }, []);

    // effect used to load the requiredAttachments state prop
    React.useEffect(() => {
        // ignore if the required attachments array is null or empty
        if (!props.source.template.attachmentSpecifications ||
            props.source.template.attachmentSpecifications.length == 0) {
            return;
        }

        // get the attachments uploaded on the contract
        const attachments = (props.source.attachments) ? props.source.attachments : [];
        // set the required attachments state based on the union of the 
        // required attachments from the template and the uploaded attachments already set on the contract
        const requiredAttachments: RequiredAttachment[] =
            (props.source.template.attachmentSpecifications as AttachmentSpecification[])
                .map(attachmentSpec => ({
                    requiredAttachment: attachmentSpec,
                    userAttachment: attachments.find(a => a.name === attachmentSpec.name)
                }));

        // update the state of the loaded required attachments
        setRequiredAttachments(requiredAttachments);
    }, []);

    React.useEffect(() => {
        ContractPartiesActivitiesService.fetchContractPartyActivitiesByContractId(props.source.id)
            .then(response => {
                setContractPartyActivitiesLogs([...contractPartyActivitiesLogs, ...response])
            }
            ).catch(error => {
                console.error(error)
            })
    }, []);

    /**
     * Properties used in BillingDataListItem component
     */
    interface BillingDataListViewItemProps extends React.ComponentProps<any> {
        billingData: DigitalContractBilling
    }

    /**Function to copy the link*/
    function copyToClipboardButton(textToCopy: string) {
        navigator.clipboard.writeText(textToCopy);
    }

    function formatRequiredInformationValue(ri: RequiredInformation): string | undefined {
        if (!ri.userInformation) return;
        let riValue = ri.userInformation.value

        if (ri.requiredInformation.requiredInformation.type === "DATE") {
            return moment(riValue).format("DD/MM/YYYY")
        }
        return riValue
    }

    // function to download the attachment file
    async function handleDownloadAttachmentFile(contractId: string, attachmentName: string, attachmentFileName: string | undefined) {
        if (attachmentFileName) {
            setLoading(true)
            try {
                const downloadURI = await (await ContractsService.generateDownloadURIForDigitalContractAttachment(contractId, attachmentName)).uri

                // access the URI provided and create the blob of the attachment file
                axios.get(`${downloadURI}`, {
                    responseType: "blob"
                }).then(downloadedFile => {
                    // create file link in browser's memory
                    const href = URL.createObjectURL(downloadedFile.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', attachmentFileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
                    .finally(() => {
                        setLoading(false)
                    })

            } catch (e) {
                const error = new ErrorWrapper(e)
                notification(show({ type: "error", message: error.message }))
            }
        }
    }

    function normalizedValidityPeriodType(template: DigitalContractTemplate, type: string) {
        switch (type) {
            case 'STARTS_WHEN_SIGNED_BY_ALL_PARTIES':
                return "Inicia quando todas as partes assinarem";
            case 'SET_BY_ISSUER':
                return "Definido pelo emissor";
            case 'UNDETERMINED':
                return "Indeterminado";
            case 'SET_AUTOMATICALLY_AFTER_START_DATE':
                let dateInterval = '';
                switch (template.validityPeriodSpecification.endDatePolicy?.dateInterval) {
                    case 'DAY':
                        dateInterval = "dia(s).";
                        break;
                    case 'WEEK':
                        dateInterval = "semana(s).";
                        break;
                    case 'MONTH':
                        dateInterval = "mes(es).";
                        break;
                    case 'YEAR':
                        dateInterval = "ano(s).";
                        break;
                }
                return "Definido automaticamente para após " + template.validityPeriodSpecification.endDatePolicy?.value + " " + dateInterval;
            default:
                return type;
        }
    }

    /**
     * Render ListItem with billing data
     * @param props 
     * @returns 
     */
    function BillingDataListItem(props: BillingDataListViewItemProps): JSX.Element {
        switch (props.billingData.billingPeriodType) {
            case "RECURRING":
                return (
                    <React.Fragment>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Loop />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={props.billingData.name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Forma de pagamento" secondary="Recorrente" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Responsável pelo pagamento" secondary={props.billingData.debtorContractParty.role} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Data inicial de cobrança" secondary={new Date(props.billingData.initialBillingPeriod).toLocaleDateString()} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Vencimento da cobrança" secondary={props.billingData.billingDueDay} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Valor de cobrança" secondary={props.billingData.value.regionalString} />
                        </ListItem>
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <AttachMoney />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={props.billingData.name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Forma de pagamento" secondary="Parcelado / à vista" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Responsável pelo pagamento" secondary={props.billingData.debtorContractParty.role} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Data inicial de cobrança" secondary={new Date(props.billingData.initialBillingPeriod).toLocaleDateString()} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Valor total de cobrança" secondary={props.billingData.value.regionalString} />
                        </ListItem>
                        {
                            (props.billingData.numberOfInstallments > 1)
                                ?
                                <ListItem>
                                    <ListItemText
                                        primary="Parcelas"
                                        secondary={
                                            `${props.billingData.numberOfInstallments}x de ${new CurrencyValue(props.billingData.value.value / props.billingData.numberOfInstallments, props.billingData.value.currency as SupportedCurrencies).regionalValueString}`
                                        }
                                    />
                                </ListItem>
                                :
                                <ListItem>
                                    <ListItemText
                                        primary="Pagamento será à vista"
                                    />
                                </ListItem>
                        }
                    </React.Fragment>
                );
        }
    }

    /**
     *  Contract status badge
     * @returns 
     */
    function ContractStatusBadge(): JSX.Element {
        switch (props.source.state) {
            case "NOT_READY_TO_BE_ISSUED":
                return <Chip label="Aguardando informações" color="error" variant="outlined" size="small" />;
            case "READY_TO_BE_ISSUED":
                return <Chip label="Pronto para enviar para assinatura" color="success" variant="outlined" size="small" />;
            default:
                return <b>Status desconhecido</b>
        }
    }

    /**
     * Props for AsyncOrganizationMemberComponent 
     */
    interface AsyncOrganizationMemberComponentProps extends React.ComponentProps<any> {
        uuid: string
    }

    /**
         * Component that renders data of an organization member identified by its UUID. This component handles an async data fetch from the API
         * @param props 
         * @returns 
         */
    function AsyncOrganizationMemberComponent(props: AsyncOrganizationMemberComponentProps): JSX.Element {
        let member = organizationMembersMap[props.uuid];

        // return the member if it is already loaded on the organization members map cache
        if (member) {
            return (
                <List>
                    <ListItem>
                        <ListItemText primary="Nome do emissor" secondary={member.fullname} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="E-mail do emissor" secondary={member.email} />
                    </ListItem>
                </List>
            );
        }

        // otherwise, put an dummy object into the member variable and store it into the map cache
        member = {
            accountRoles: [AccountRoles.USER_AND_TEAMS_MANAGER],
            creationDate: new Date(),
            deletedAt: null,
            email: "...",
            fullname: "...",
            hasHandwrittenSignature: false,
            preferredName: "...",
            uuid: "...",
            contractsRestrictViewModeEnabled: false,
        }

        // update the map cache with the dummy data
        let organizationMembersMapCopy = Object.assign(organizationMembersMap);
        organizationMembersMapCopy[props.uuid] = member;
        setOrganizationMembersMap(organizationMembersMapCopy);

        // make an async call to the API to fetch the user data into the map cache
        setLoading(true);
        OrganizationService.fetchOrganizationMemberByUuid(props.uuid)
            .then(member => {
                organizationMembersMapCopy[props.uuid] = member;
                setOrganizationMembersMap(organizationMembersMapCopy);
            })
            .catch(error => {
                console.error("Could not fetch organization members data from the API", error);
            })
            .finally(() => {
                setLoading(false);
            });

        // return an dummy object
        return <CircularProgress />
    }

    interface ContractPartyListItemProps extends React.ComponentProps<any> {
        contractParty: IdentifiedByEmailContractParty | PlatformUserContractParty
    }

    function ContractPartyListItem(props: ContractPartyListItemProps): JSX.Element {
        const [shareDialog, setShareDialog] = useState(false);
        const [lastAccess, setLastAccess] = useState("Ainda não acessou o contrato");

        React.useEffect(() => {
            getLastAccessDate();
        }, [])

        function getLastAccessDate() {

            const accessHistory = contract.accessHistory;

            Object.keys(accessHistory).forEach(cpRole => {
                if (cpRole === props.contractParty.role) {
                    setLastAccess(moment(accessHistory[cpRole].accessedAt).format("DD/MM/YYYY - HH:mm:ss"));
                }
            })
        }

        // if the contract is IDENTIFIED_BY_EMAIL
        if (props.contractParty.contractPartyIdentificationType === "IDENTIFIED_BY_EMAIL") {
            const contractParty = props.contractParty as IdentifiedByEmailContractParty;
            return (
                <>
                    <ListItem>
                        <ListItemButton>
                            <ListItemIcon>
                                <Mail />
                            </ListItemIcon>
                            <ListItemText primary="Identificado por e-mail" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Último acesso em: " secondary={lastAccess} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Endereço de e-mail utilizado para assinatura" secondary={contractParty.email} />
                        <Tooltip title="Compartilhar contrato">
                            <IconButton onClick={() => setShareDialog(true)}><Share /></IconButton>
                        </Tooltip>
                        <ShareContractDialog open={shareDialog} onClose={() => setShareDialog(false)} source={contract} targetContractPartyEmail={contractParty.email} />
                    </ListItem>
                </>
            )
        }
        // if the contract is PLATFORM_USER
        else if (props.contractParty.contractPartyIdentificationType === "PLATFORM_USER") {
            const contractParty = props.contractParty as PlatformUserContractParty;
            let member = organizationMembersMap[contractParty.uuid];

            // if the member is on the map cache return the true component
            if (member) {
                return (
                    <>
                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon>
                                    <VerifiedUser />
                                </ListItemIcon>
                                <ListItemText primary="Membro da plataforma" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Nome" secondary={member.fullname} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="E-mail" secondary={member.email} />
                            <Button onClick={() => setShareDialog(true)}>Compartilhar contrato</Button>
                            <ShareContractDialog open={shareDialog} onClose={() => setShareDialog(false)} source={contract} targetContractPartyEmail={member.email} />
                        </ListItem>
                    </>
                )
            }

            // otherwise, put an dummy object into the member variable and store it into the map cache
            member = {
                accountRoles: [AccountRoles.USER_AND_TEAMS_MANAGER],
                creationDate: new Date(),
                deletedAt: null,
                email: "...",
                fullname: "...",
                hasHandwrittenSignature: false,
                preferredName: "...",
                uuid: "...",
                contractsRestrictViewModeEnabled: false
            }

            let organizationMembersMapCopy = Object.assign(organizationMembersMap);
            organizationMembersMapCopy[contractParty.uuid] = member;
            setOrganizationMembersMap(organizationMembersMapCopy);

            // make an async call to the API to fetch the user data into the map cache
            setLoading(true);
            OrganizationService.fetchOrganizationMemberByUuid(contractParty.uuid)
                .then(member => {

                    organizationMembersMapCopy[contractParty.uuid] = member;
                    setOrganizationMembersMap(organizationMembersMapCopy);
                })
                .catch(error => {
                    console.error("Could not fetch organization members data from the API", error);
                })
                .finally(() => {
                    setLoading(false);
                });

            // return an CircularProgress to indicate that the data is being fetched
            return <CircularProgress />
        }
        else {
            return <Alert severity="error">Não foi possível identificar a participação deste membro do contrato</Alert>
        }
    }

    /**
     * checks if the contract party activity log type is the editable contract created, if so, returns the first timeline element, 
     * otherwise adds the timeline element with the connector
     * 
     */
    function contractPartyActivitiesLogsCard(contractPartyActivityLog: ContractPartyActivityModel): JSX.Element {
        const type = contractPartyActivityLog.type.toString();

        if (type === "EDITABLE_CONTRACT_CREATED") {
            return (
                <TimelineItem>
                    <TimelineOppositeContent display="none" />
                    <TimelineSeparator>
                        <TimelineDot color="warning" />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography display="flex" sx={{ fontWeight: "600", color: (theme) => theme.palette.primary.main }}>{ContractPartyActivitiesType(contractPartyActivityLog)} {new Date(contractPartyActivityLog.creationDate).toLocaleString()}</Typography>
                    </TimelineContent>
                </TimelineItem>
            )
        }
        else {
            return (
                <TimelineItem>
                    <TimelineOppositeContent display="none" />
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography display="flex" sx={{ fontWeight: "600", color: "#212121" }}>{ContractPartyActivitiesType(contractPartyActivityLog)} {new Date(contractPartyActivityLog.creationDate).toLocaleString()}</Typography>
                    </TimelineContent>
                </TimelineItem>
            )
        }
    }

    const handleOpenModalReproveAttachment = (name: string | undefined) => {
        if (name === undefined || !name) return
        setReproveReasonDialog((prev) => ({
            ...prev,
            [name]: true,
        }));
    };

    const handleCloseModalReproveAttachment = (name: string | undefined) => {
        if (name === undefined || !name) return
        setReproveReason('');

        setReproveReasonDialog((prev) => ({
            ...prev,
            [name]: false,
        }));
    };

    async function approveAttachment(attachment: DigitalContractAttachment | undefined) {
        if (!attachment || attachment.status == AttachmentStatus.APPROVED) return;

        setLoadingAttachmentApproved(true);

        try {

            const updatedAttachment: DigitalContractAttachment = {
                ...attachment,
                status: AttachmentStatus.APPROVED
            };

            setRequiredAttachments(prevAttachments => prevAttachments.map(a =>
                a.userAttachment && a.userAttachment.name === updatedAttachment.name ? { ...a, userAttachment: updatedAttachment } : a
            ));

            const attachmentToApprove: ApproveOrReproveAttachment = {
                name: updatedAttachment.name,
                status: AttachmentStatus.APPROVED,
                description: ''
            };

            const res = await ContractsService.approveOrReproveEditableDigitalContract(props.source.id, attachmentToApprove);
            notification(show({ type: 'success', message: 'Aprovado com sucesso.' }));
        } catch (e) {
            const error = new ErrorWrapper(e);
            notification(show({ type: 'error', message: error.message }));
        } finally {
            setLoadingAttachmentApproved(false);
        }
    }

    async function reproveAttachment(attachment: DigitalContractAttachment | undefined, reason: string) {
        if (!attachment) return;

        if (reason.length < 3) {
            notification(show({ type: 'error', message: "É obrigatório inserir o motivo da reprovação" }));
            return;
        }

        setLoadingAttachmentReproved(true);

        try {
            const updatedAttachment: DigitalContractAttachment = {
                ...attachment,
                status: AttachmentStatus.REPROVED
            };

            setRequiredAttachments(prevAttachments => prevAttachments.map(a =>
                a.userAttachment && a.userAttachment.name === updatedAttachment.name ? { ...a, userAttachment: updatedAttachment } : a
            ));

            const attachmentToReprove: ApproveOrReproveAttachment = {
                name: updatedAttachment.name,
                status: AttachmentStatus.REPROVED,
                description: reason
            };

            const res = await ContractsService.approveOrReproveEditableDigitalContract(props.source.id, attachmentToReprove);
            notification(show({ type: 'success', message: 'Reprovado com sucesso.' }));
        } catch (e) {
            const error = new ErrorWrapper(e);
            notification(show({ type: 'error', message: error.message }));
        } finally {
            // Restaure o estado e redefina as variáveis necessárias
            handleCloseModalReproveAttachment(attachment.name);
            setReproveReason('');
            setLoadingAttachmentReproved(false);
        }
    }

    return (
        <Container>
            <DialogContent>
                <TabContext value={tab}>
                    <TabList variant="scrollable" scrollButtons="auto" onChange={(e, selectedTab) => { setTab(selectedTab); setLoadingTemplateFile(true) }} aria-label="Navegação dos dados do contrato">
                        <Tab label="Geral" value="0" />
                        <Tab label="Participantes do Contrato" value="1" />
                        <Tab label="Financeiro" value="2" />
                        <Tab label="Informações requeridas" value="3" />
                        <Tab label="Anexos" value="4" />
                        <Tab label="Visualizar Contrato" value="5" />
                        <Tab label="Histórico de Atividades" value="6" />
                    </TabList>
                    {/* Geral */}
                    <TabPanel value="0">
                        <Typography variant="h6">Informações do contrato</Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="Nome do contrato" secondary={props.source.name} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Status do contrato" />
                                <ContractStatusBadge />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Data de emissão" secondary={new Date(props.source.creationDate).toLocaleString()} />
                            </ListItem>
                        </List>
                        <Divider />
                        <Typography variant="h6">Vigência do contrato</Typography>
                        <List>
                            <ListItem>
                                <Typography>Início</Typography>
                            </ListItem>
                            <ListItem>
                                {
                                    (props.source.validityPeriod.startDate && props.source.validityPeriod.startDate.timestamp)
                                        ?
                                        <>
                                            <ListItemText primary="Data de início" secondary={new Date(props.source.validityPeriod.startDate.timestamp).toLocaleString()} />
                                        </>
                                        :
                                        <></>
                                }
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Tipo de vigência: " secondary={normalizedValidityPeriodType(props.source.template, props.source.validityPeriod.startDatePolicyType)} />
                            </ListItem>
                            <ListItem>
                                <Divider sx={{ width: "100%" }} />
                            </ListItem>
                            <ListItem>
                                <Typography>Término</Typography>
                            </ListItem>
                            <ListItem>
                                {
                                    (props.source.validityPeriod.endDate && props.source.validityPeriod.endDate.timestamp)
                                        ?
                                        <>
                                            <ListItemText primary="Data de término" secondary={new Date(props.source.validityPeriod.endDate.timestamp).toLocaleString()} />
                                        </>
                                        :
                                        <></>
                                }
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Tipo de vigência: " secondary={normalizedValidityPeriodType(props.source.template, props.source.validityPeriod.endDatePolicyType)} />
                            </ListItem>
                        </List>
                        <Divider />
                        <Typography variant="h6">Emissor do contrato</Typography>
                        <AsyncOrganizationMemberComponent uuid={props.source.issuerUuid} />
                        <Divider />
                        <Typography variant="h6">Identificações do Cartorizi</Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="ID" secondary={props.source.id} />
                            </ListItem>
                        </List>
                        <Divider />
                        <Typography variant="h6" sx={{ mb: 2 }}>Tags</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {
                                (props.source.contractTags)
                                    ?
                                    props.source.contractTags.map(tag => {
                                        return (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: "flex", alignItems: "center", backgroundColor: "primary.main", m: 1, borderRadius: "24px", justifyContent: "center", minWidth: "18%", height: "36px", maxWidth: "18%"
                                                    }}>
                                                    <Tooltip title={tag.tagName}>
                                                        <Typography sx={{ fontSize: "12px", color: "#fff", margin: "0 16px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{tag.tagName}</Typography>
                                                    </Tooltip>
                                                </Box>
                                            </>
                                        )
                                    })
                                    :
                                    <></>
                            }
                        </Box>
                    </TabPanel>
                    {/** Participantes do contrato */}
                    <TabPanel value="1">
                        <List>
                            {
                                props.source.contractParties.map(cp => (
                                    <React.Fragment>
                                        <Typography variant="h6">{cp.role}</Typography>
                                        <ContractPartyListItem contractParty={cp} />
                                        <Divider />
                                    </React.Fragment>
                                ))
                            }
                        </List>
                    </TabPanel>
                    {/** Financeiro */}
                    <TabPanel value="2">
                        <List>
                            {
                                (props.source.billingData && props.source.billingData.length > 0)
                                    ?
                                    <List>
                                        {
                                            props.source.billingData.map(b => (
                                                <React.Fragment>
                                                    <BillingDataListItem billingData={b} />
                                                    <Divider sx={{ mb: '16px', mt: '16px' }} />
                                                </React.Fragment>
                                            ))
                                        }
                                    </List>
                                    :
                                    <Alert severity="info">Esse contrato não possui dados financeiros automatizados</Alert>
                            }
                        </List>
                    </TabPanel>
                    {/** Informacoes requeridas */}
                    <TabPanel value="3">
                        {
                            (requiredInformations.length > 0)
                                ?
                                //This part will be render only if the contract has user information:
                                // render an TabContext to show the user information grouping by contract party role from userInformationContractPartiesRoles
                                <TabContext value={userInformationTab}>
                                    <TabList
                                        onChange={(e, selectedTab) => setUserInformationTab(selectedTab)}
                                        aria-label="Navegação dos dados de informações requeridas categorizados por participante"
                                    >
                                        {

                                            userInformationContractPartiesRoles.map(u => (
                                                <Tab label={u} value={u} />
                                            ))
                                        }
                                    </TabList>
                                    {
                                        userInformationContractPartiesRoles.map(u => (
                                            <TabPanel value={u}>
                                                <List>
                                                    {
                                                        (requiredInformations.length > 0)
                                                            ?
                                                            // only render the user information from the selected user information tab (.filter)
                                                            requiredInformations
                                                                .filter(ri => ri.requiredInformation.contractParty.role === userInformationTab)
                                                                .map(ri => (
                                                                    <React.Fragment>
                                                                        <ListItem>
                                                                            {
                                                                                (ri.userInformation)
                                                                                    ?
                                                                                    <Box>
                                                                                        <ListItemText primary={ri.requiredInformation.requiredInformation.name} secondary={formatRequiredInformationValue(ri)} />
                                                                                        {
                                                                                            ri.requiredInformation.requiredInformation.description ?
                                                                                                <Typography sx={{ mt: 2 }} variant="caption"><b>Descrição:</b> {ri.requiredInformation.requiredInformation.description}</Typography>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                    </Box>
                                                                                    :
                                                                                    <div style={{ display: 'block' }}>
                                                                                        <Typography variant="body1">{ri.requiredInformation.requiredInformation.name}</Typography> <br />
                                                                                        <Alert variant="outlined" severity="info">Informação ainda não preenchida pelo participante <b>{`${ri.requiredInformation.contractParty.role}`}</b></Alert>
                                                                                    </div>
                                                                            }
                                                                        </ListItem>
                                                                        <Divider sx={{ mb: '16px', mt: '16px' }} />
                                                                    </React.Fragment>
                                                                ))
                                                            :
                                                            <></>
                                                    }
                                                </List>
                                            </TabPanel>
                                        ))
                                    }
                                </TabContext>
                                :
                                <Alert severity="info">Esse contrato não possuí nenhuma informação requerida</Alert>
                        }
                    </TabPanel>
                    {/** Anexos */}
                    <TabPanel value="4">
                        {
                            (requiredAttachments.length > 0)
                                ?
                                <List>
                                    {
                                        requiredAttachments.map(a => (
                                            <React.Fragment>
                                                <ListItem>
                                                    {
                                                        (a.userAttachment)
                                                            ?
                                                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                                <>
                                                                    <ListItemAvatar>
                                                                        <Avatar>
                                                                            <AttachFile />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary={a.userAttachment.name} secondary={`FID: ${a.userAttachment.guid}`} />
                                                                </>
                                                                {
                                                                    (a.userAttachment.status == AttachmentStatus.PENDING ||
                                                                        a.userAttachment.status == AttachmentStatus.APPROVED ||
                                                                        a.userAttachment.status == AttachmentStatus.REPROVED)
                                                                        ?
                                                                        <Box sx={{ height: "32px" }}>
                                                                            <Button
                                                                                variant={a.userAttachment.status == AttachmentStatus.APPROVED ? "contained" : "outlined"}
                                                                                startIcon={<CheckOutlined />}
                                                                                color={a.userAttachment.status == AttachmentStatus.APPROVED ? "success" : "primary"}
                                                                                sx={{ mr: 1 }}
                                                                                onClick={() => approveAttachment(a.userAttachment)}
                                                                                disabled={loadingAttachmentApproved}
                                                                            >
                                                                                {loadingAttachmentApproved ? <CircularProgress size={24} /> : (a.userAttachment.status == AttachmentStatus.APPROVED) ? "Aprovado" : "Aprovar"}
                                                                            </Button>
                                                                            <Button
                                                                                variant={a.userAttachment.status == AttachmentStatus.REPROVED ? "contained" : "outlined"}
                                                                                startIcon={<CloseOutlined />}
                                                                                onClick={() => {
                                                                                    if (a.userAttachment && a.userAttachment.status == AttachmentStatus.REPROVED) return;
                                                                                    handleOpenModalReproveAttachment(a.userAttachment?.name);
                                                                                }}
                                                                                color={a.userAttachment.status == AttachmentStatus.REPROVED ? "error" : "primary"}
                                                                                disabled={loadingAttachmentReproved}
                                                                            >
                                                                                {loadingAttachmentReproved ? <CircularProgress size={24} /> : (a.userAttachment.status == AttachmentStatus.REPROVED) ? "Reprovado" : "Reprovar"}
                                                                            </Button>
                                                                        </Box>
                                                                        :
                                                                        <></>
                                                                }
                                                                <Dialog
                                                                    open={reproveReasonDialog[a.userAttachment.name]}
                                                                    onClose={() => handleCloseModalReproveAttachment(a.userAttachment?.name)}
                                                                >
                                                                    <DialogTitle><b>Reprovar anexo  - {a.userAttachment.name}</b></DialogTitle>
                                                                    <DialogContent>
                                                                        <Typography sx={{ mb: 3 }}>Informe o motivo da reprovação do anexo</Typography>
                                                                        <TextField
                                                                            sx={{ width: "100%" }}
                                                                            variant="standard"
                                                                            placeholder="Por que o anexo foi reprovado?"
                                                                            value={reproveReason}
                                                                            onChange={(e) => setReproveReason(e.target.value)}
                                                                        />
                                                                        <Typography sx={{ display: "flex", fontSize: "10px", mt: 1 }}><Typography sx={{ color: "#f00", fontSize: "10px" }}>*</Typography> O motivo da reprovação deve ter pelo menos 3 caracteres.</Typography>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button onClick={() => handleCloseModalReproveAttachment(a.userAttachment?.name)}>Cancelar</Button>
                                                                        <Button variant="contained" onClick={() => reproveAttachment(a.userAttachment, reproveReason)}>Reprovar anexo</Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </Box>
                                                            :
                                                            <div style={{ display: 'block' }}>
                                                                <Typography variant="body1">{a.requiredAttachment.name}</Typography> <br />
                                                                <Alert variant="outlined" severity="info">Anexo ainda não adicionado pelo participante <b>{`${a.requiredAttachment.contractParty.role}`}</b>
                                                                </Alert>
                                                            </div>
                                                    }

                                                </ListItem>
                                                {
                                                    (a.userAttachment)
                                                        ?
                                                        <ListItemButton
                                                            disabled={loading}
                                                            onClick={() => handleDownloadAttachmentFile(props.source.id, a.requiredAttachment.name, a.userAttachment?.filename)}
                                                        >
                                                            <ListItemIcon>
                                                                <FileDownload />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Baixar arquivo" secondary={a.userAttachment?.filename} />
                                                        </ListItemButton>
                                                        :
                                                        <></>
                                                }
                                                <Divider sx={{ mt: 4, mb: 4 }} />
                                            </React.Fragment>
                                        ))
                                    }
                                </List>
                                :
                                <Alert severity="info">Esse contrato não possui anexos</Alert>
                        }
                    </TabPanel>
                    {/** Visualizar contrato */}
                    <TabPanel value="5">
                        {
                            (props.source.template.templateInputFile.type === DigitalContractInputFileType.WORD_1_0) ?
                                <Box sx={{ height: "75vh" }}>
                                    <iframe
                                        onLoad={() => setLoadingTemplateFile(false)} frameBorder="0"
                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${templateDownloadLink}`}
                                        style={{ height: "92vh", width: "100%", marginTop: "20px" }}
                                    />
                                </Box>
                                :
                                <Box sx={{ height: "75vh" }}>
                                    {loadingTemplateFile ? <Box height={1} alignItems='center' display='flex' justifyContent='center'><CircularProgress /></Box> : null}
                                    <iframe
                                        onLoad={() => setLoadingTemplateFile(false)} frameBorder="0"
                                        src={`${process.env.REACT_APP_REGISTRY_WEBAPP}/templates/${props.source.template.id}/template-file-viwer?authToken=${authentication.getAccountsToken()}`}
                                        style={{ overflow: 'hidden', height: '170%', width: '100%' }}
                                    />
                                </Box>
                        }
                    </TabPanel>
                    {/**Histórico de Atividades*/}
                    <TabPanel value="6">
                        <Typography variant="h6">Histórico</Typography>
                        <Timeline position="right">

                            {/* Every time a contract party activity is added to the contract, it adds a new Time Line Item */}
                            {
                                //se houver contract party activities, adicionar o time line item
                                (contractPartyActivitiesLogs.map(contractPartyActivityLog => (contractPartyActivitiesLogsCard(contractPartyActivityLog))).reverse())
                            }

                        </Timeline>
                    </TabPanel>
                </TabContext>
            </DialogContent>
        </Container >
    )
}

export default EditableDigitalContractDialogContent;