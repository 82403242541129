import { AlternateEmail, ArrowForward, AttachFile, AttachMoney, Business, BusinessOutlined, Check, CheckCircle, CheckCircleOutline, Close, Delete, Description, Edit, EditOutlined, Email, Group, GroupOutlined, InsertDriveFileOutlined, Menu, PersonOutline, Preview, Sell, SellOutlined } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, ListItem, Select, Tab, TextField, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import SearchUsers from "../../../../../components/SearchUsers";
import TagsAdder from "../../../../../components/TagsAdder";
import AuthenticationContext from "../../../../../contexts/authentication";
import StlInterceptor from "../../../../../contexts/stl";
import { ContractIssuer, DigitalContractInputFileType, EmailTypes, IdentificationType, MailTemplate, TemplateDTO } from "../../../../../models/contracts";
import { AccountTypes } from "../../../../../models/user";
import { show } from "../../../../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../../../../redux/hooks";
import AuthorizationService from "../../../../../services/authorization";
import ContractsService from "../../../../../services/contracts";
import SubusersService from "../../../../../services/subusers";
import { TagsModel } from "../../../../../services/tags";
import TeamsService from "../../../../../services/teams";
import { SecurityTierLevels } from "../../../../../store/stl";
import ErrorWrapper from "../../../../../utils/ErrorWrapper";
import { EditorSearchResult, EditorSearchResultType, billTypes, riTypes } from "../../../../../utils/editorUtils";
import { LoadingState } from "../../../../../utils/types";
import RenewAccessDialog from "../../../../authentication/RenewAccessDialog";
import OrganizationService from "../../../../../services/organization";
import { InterfaceTheme } from "../../../../../models/organizations";
import EmailEditor from "../../components/EmailEditor";

// Interfaces and Types
interface DataReviewProps extends React.ComponentProps<any> {
    open: boolean,
    setOpen: (open: boolean) => void,
    templateDTO: TemplateDTO,
    setTemplateDTO: (dto: TemplateDTO) => void
    templateFile: Blob | null,
    mode: "create" | "edit",
}

interface DisplayIssuer {
    name: string,
    description: string,
    type: EditorSearchResultType,
    id: string
}

// Enums
enum Tabs {
    NAME = "NAME",
    ISSUE = "ISSUE",
    CONFIRMATION = "CONFIRMATION",
    TAGS = "TAGS",
    EMAIL = "EMAIL",
    REVIEW = "REVIEW"
};

// Static variables
const UPDATE_TEMPLATE_NAME_TIMEOUT = 500;

const defaultInterfaceTheme: InterfaceTheme = {
    primaryColor: "#370068",
    secondaryColor: "#7C24BE",
    logoUrl: "https://accountsblob.blob.core.windows.net/publicassets/cartorizi-logo.webp"
}

const TemplateDataReview = (props: DataReviewProps): JSX.Element => {

    // Destructure props object
    const { open, templateDTO, templateFile, mode, setTemplateDTO, setOpen } = props;

    // Contexts
    const notification = useAppDispatch();
    const auth = useContext(AuthenticationContext);
    const stl = useContext(StlInterceptor);

    // Refs
    let lastTimeout = useRef<NodeJS.Timeout | null>(null);

    // Loading states
    const [saveLoading, setSaveLoading] = useState<LoadingState>("loaded");

    // Boolean states
    const [openRenewAccessDialog, setOpenRenewAccessDialog] = useState(false);

    // Data states
    const [templateName, setTemplateName] = useState(templateDTO.name);
    const [allowIssuer, setAllowIssuer] = useState(templateDTO.allowIssuerToSendRequiredData);
    const [searchTerm, setSearchTerm] = useState("");
    const [displayIssuers, setDisplayIssuers] = useState<DisplayIssuer[]>([]);
    const [tags, setTags] = useState<TagsModel[]>([]);
    const [mailTemplates, setMailTemplates] = useState<MailTemplate[]>(templateDTO.mailTemplates ? templateDTO.mailTemplates : []);
    const [interfaceTheme, setInterfaceTheme] = useState<InterfaceTheme>(defaultInterfaceTheme);
    const [organization, setOrganization] = useState("");
    const [readConfirmation, setReadConfirmation] = useState(false);
    const [clausesToConfirm, setClausesToConfirm] = useState("");    

    // Tab states
    const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.NAME);

    // useEffects
    useEffect(() => {
        parseTemplateDTO();
        fetchDataForPreview();
    }, [])

    // Functions
    /**
     * Fetches the data to display on the e-mail preview
     */
    async function fetchDataForPreview() {
        try {
            setSaveLoading("loading")
            
            if (auth.user().session.accountType === AccountTypes.Customer) {
                setOrganization(auth.user().account.fullname);
            } else {
                setOrganization((await AuthorizationService.getSubuserOrganizationOwner()).fullname);
            }

            setInterfaceTheme(await OrganizationService.fetchOrganizationInterfaceTheme());
        } catch (e) {
            const err = new ErrorWrapper(e);
            if (err.httpStatus !== 404) {
                notification(show({ type: "error", message: err.message }));
            }
        } finally {
            setSaveLoading("loaded");
        }

    }
    /**
     * Formats the dto so it can be sent to the server
     */
    async function parseTemplateDTO() {
        const issuers: DisplayIssuer[] = [];

        templateDTO.contractIssuers.forEach(async (issuer) => {

            if (issuer.identification.teamId) {
                const response = await TeamsService.fetchTeamByGuid(issuer.identification.teamId);
                const parsedIssuer: DisplayIssuer = {
                    description: response.guid,
                    id: response.guid,
                    name: response.name,
                    type: EditorSearchResultType.ORGANIZATION_TEAM
                }
                issuers.push(parsedIssuer);
            } else if (issuer.identification.uuid) {
                if (auth.user().account.uuid === issuer.identification.uuid) {
                    const parsedIssuer: DisplayIssuer = {
                        description: auth.user().account.email,
                        id: auth.user().account.uuid,
                        name: auth.user().account.preferredName,
                        type: auth.user().session.accountType === AccountTypes.Customer ? EditorSearchResultType.ORGANIZATION_ACCOUNT : EditorSearchResultType.ORGANIZATION_SUBUSER_ACCOUNT
                    }
                    issuers.push(parsedIssuer);
                } else {
                    try {
                        const response = await OrganizationService.fetchOrganizationMemberByUuid(issuer.identification.uuid);
                        const parsedIssuer: DisplayIssuer = {
                            description: response.email,
                            id: response.uuid,
                            name: response.preferredName,
                            type: EditorSearchResultType.ORGANIZATION_SUBUSER_ACCOUNT
                        }
                        issuers.push(parsedIssuer);
                    } catch (e) {
                        const err = new ErrorWrapper(e);
                        switch (err.httpStatus) {
                            case 404:
                                try {
                                    const response = await AuthorizationService.getSubuserOrganizationOwner();
                                    const parsedIssuer: DisplayIssuer = {
                                        description: response.email,
                                        id: response.uuid,
                                        name: response.preferredName,
                                        type: EditorSearchResultType.ORGANIZATION_ACCOUNT
                                    }
                                    issuers.push(parsedIssuer);
                                } catch (e) {
                                    const err = new ErrorWrapper(e);
                                    notification(show({ type: "error", message: err.message }));
                                }
                                break;
                            default:
                                notification(show({ type: "error", message: err.message }));
                        }
                    }
                }
            }
        })

        setDisplayIssuers(issuers);

        const parsedTags: TagsModel[] = templateDTO.contractTags.tags.map(tag => {
            const parsedTag: TagsModel = {
                tagName: tag
            }
            return parsedTag;
        })
        setTags(parsedTags);

        // Set the clauses to confirm
        if(templateDTO.clausesToConfirm.length > 0){
            const clauses = templateDTO.clausesToConfirm.join(';');
            setReadConfirmation(templateDTO.clausesToConfirm.length > 0);
            setClausesToConfirm(clauses);
        }
    }

    /**
     * Saves the template
     */
    async function saveTemplate() {

        setOpenRenewAccessDialog(false);

        if (!templateDTO || !templateFile) return;

        setSaveLoading("loading");

        let clauses: string[] = []
        if (clausesToConfirm !== "") {
          clauses = clausesToConfirm.split(";").map(s => s.trim());
          clauses = clauses.filter(s => s !== "");
        }

        const templateTags = tags.map(tag => tag.tagName);

        const templateIssuers: ContractIssuer[] = displayIssuers.map(issuer => {
            const newIssuer: ContractIssuer = {
                identification: issuer.type === EditorSearchResultType.ORGANIZATION_TEAM ? { teamId: issuer.id } : { uuid: issuer.id },
                identificationType: issuer.type === EditorSearchResultType.ORGANIZATION_TEAM ? IdentificationType.ORGANIZATION_TEAM : IdentificationType.ORGANIZATION_USER
            }

            return newIssuer;
        })

        const dto: TemplateDTO = {
            ...templateDTO,
            contractTags: {
                tags: templateTags
            },
            clausesToConfirm: clauses,
            contractIssuers: templateIssuers,
            allowIssuerToSendRequiredData: allowIssuer,
            contractPartiesSignatureOrder: templateDTO.contractPartiesSignatureOrder
        }

        try {

            let template
            if (mode === "create") {
                template = await ContractsService.registerTemplate(dto);
            }
            else {
                template = await ContractsService.update(templateDTO.id, dto);
            }

            if (!template) throw new Error("Falha ao criar modelo");

            await ContractsService.uploadTemplateFile(template.id, templateFile, DigitalContractInputFileType.WORD_1_0);
            setOpen(false);
            notification(show({ type: "success", message: mode === "create" ? "Modelo criado com sucesso" : "Modelo editado com sucesso" }));
        } catch (e) {
            console.error(e);
            const err = new ErrorWrapper(e);
            notification(show({ type: "error", message: err.message }));
        }
        setSaveLoading("loaded");

    }

    /**
     * Check if the user can sabe the contract
     */
    function checkIfUserCanSave() {
        if (mode === "edit") {
            setSaveLoading("loading");
            stl.require(auth, SecurityTierLevels.SamePerson)
                .then(() => saveTemplate())
                .catch(() => setOpenRenewAccessDialog(true))
                .finally(() => setSaveLoading("loaded"));
        } else {
            saveTemplate();
        }
    }

    // Handler functions
    /**
     * Change the current select tab
     * @param event 
     * @param newValue 
     */
    function handleChangeTabs(event: React.SyntheticEvent, newValue: Tabs) {
        setCurrentTab(newValue);
    }

    /**
     * Changes the name of the template
     * @param e 
     */
    function handleChangeTemplateName(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setTemplateName(e.target.value);

        if (lastTimeout.current) {
            clearTimeout(lastTimeout.current)
        }

        lastTimeout.current = setTimeout(() => {
            setTemplateDTO({ ...templateDTO, name: e.target.value.trim() });
        }, UPDATE_TEMPLATE_NAME_TIMEOUT)
    }

    /**
     * Controls the input of the user search bar
     * @param e 
     */
    function handleChangeInput(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setSearchTerm(e.target.value);
    }

    /**
     * handles the user search bar selection
     * @param option 
     * @returns 
     */
    function handleSelectMember(option: EditorSearchResult) {
        setSearchTerm("");

        if (displayIssuers.find(i => i.id === option.id)) return;

        const newDisplay: DisplayIssuer = {
            name: option.description,
            description: option.subdescription ? option.subdescription : "",
            type: option.type,
            id: option.id
        };

        setDisplayIssuers([...displayIssuers, newDisplay]);
    }

    /**
     * handle the addition of contract tags
     * @param tag 
     */
    function handleAddTags(tag: TagsModel) {
        const isTagDuplicate = tags.some((t) => t.tagName === tag.tagName);

        if (!isTagDuplicate) {
            const updatedTags = [...tags, tag];
            setTags(updatedTags);
        }
    }

    function handleUpdateMailTemplates(newValue: string, type: EmailTypes) {
        if (!templateDTO.mailTemplates) return;

        if (newValue) {
            let updatedMailTemplates = [...templateDTO.mailTemplates];

            if (updatedMailTemplates.find(mt => mt.mailType === type)) {
                updatedMailTemplates = updatedMailTemplates.map(mt => {
                    if (mt.mailType === type) {
                        const newMt: MailTemplate = {
                            mailTemplate: newValue,
                            mailType: type
                        }

                        return newMt;
                    }
                    return mt;
                })
            } else {
                updatedMailTemplates.push({ mailTemplate: newValue, mailType: type });
            }

            setMailTemplates(updatedMailTemplates);
            setTemplateDTO({ ...templateDTO, mailTemplates: updatedMailTemplates });
        } else {
            let updatedMailTemplates = templateDTO.mailTemplates.filter(mt => mt.mailType !== type);

            setMailTemplates(updatedMailTemplates);
            setTemplateDTO({ ...templateDTO, mailTemplates: updatedMailTemplates });
        }

    }

    /**
     * Handle the step order of the tabs
     */
    function handleGoFoward() {
        switch (currentTab) {
            case Tabs.NAME:
                setCurrentTab(Tabs.ISSUE)
                break;
            case Tabs.ISSUE:
                setCurrentTab(Tabs.CONFIRMATION)
                break;
            case Tabs.CONFIRMATION:
                setCurrentTab(Tabs.TAGS)
                break;
            case Tabs.TAGS:
                setCurrentTab(Tabs.EMAIL)
                break;
            case Tabs.EMAIL:
                setCurrentTab(Tabs.REVIEW)
                break;
        }
    }

    /**
     * handles the deletion of a issuer
     * @param issuer 
     */
    function handleDeleteIssuer(issuer: DisplayIssuer) {
        const filter = displayIssuers.filter(is => is.id !== issuer.id);
        setDisplayIssuers(filter);
    }

    /**
     * handle the close action of the dialog
     */
    function handleClose() {
        setCurrentTab(Tabs.NAME);
        setTemplateName("");
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            fullScreen
            onClose={handleClose}
        >
            {
                saveLoading === "loaded"
                    ?
                    <>
                        <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"flex-end"} width={"98vw"}>
                            <IconButton onClick={handleClose} >
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <TabContext value={currentTab}>
                                <TabList centered onChange={(e, tab) => handleChangeTabs(e, tab)} >
                                    <Tab value={Tabs.NAME} label="Nome do modelo" icon={<Edit />} />
                                    <Tab value={Tabs.ISSUE} label="Permissões de Emissão" icon={<Group />} disabled={templateDTO.name.length <= 0} />
                                    <Tab value={Tabs.CONFIRMATION} label="Confirmação de Leitura" icon={<Check />} disabled={templateDTO.name.length <= 0} />
                                    <Tab value={Tabs.TAGS} label="Tags do modelo" icon={<Sell />} disabled={templateDTO.name.length <= 0} />
                                    <Tab value={Tabs.EMAIL} label="Personalizar e-mails" icon={<Email />} disabled={templateDTO.name.length <= 0} />
                                    <Tab value={Tabs.REVIEW} label="Revisão de dados" icon={<Preview />} disabled={templateDTO.name.length <= 0} />
                                </TabList>
                                <TabPanel value={Tabs.NAME}>
                                    <Box sx={{ m: 2, width: "100%", height: "100%" }}>
                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                            <InsertDriveFileOutlined color="primary" sx={{ fontSize: "32px" }} />
                                            <Typography sx={{ ml: 2 }} >Nome do modelo</Typography>
                                        </Box>
                                        <TextField
                                            fullWidth
                                            required
                                            label="Defina um nome para este modelo de contrato"
                                            value={templateName}
                                            onChange={handleChangeTemplateName}
                                            inputProps={{
                                                maxLength: 80
                                            }}
                                            helperText={`${templateName.length} / 80`}
                                            error={templateName.length <= 0}
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value={Tabs.ISSUE}>
                                    <Box sx={{ m: 2, width: "100%", height: "100%" }}>
                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                            <EditOutlined color="primary" sx={{ fontSize: "32px" }} />
                                            <Typography sx={{ ml: 2 }} >Permitir preenchimento de dados pelo emissor</Typography>
                                        </Box>
                                        <FormControlLabel control={<Checkbox checked={allowIssuer} onChange={(e) => setAllowIssuer(e.target.checked)} />} label="Permitir que o emissor preencha os dados requiridos em nome dos participantes do contrato" />
                                        <Box sx={{ mt: 6 }}>
                                            <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                                <GroupOutlined color="primary" sx={{ fontSize: "32px" }} />
                                                <Typography sx={{ ml: 2 }} >Emitentes do contrato</Typography>
                                            </Box>
                                            <Typography sx={{ mb: 2 }}>Selecione os usuários que poderão emitir este modelo de contrato</Typography>
                                            <SearchUsers
                                                errors={{}}
                                                inputChange={handleChangeInput}
                                                onResourceClick={handleSelectMember}
                                                value={searchTerm}
                                            />
                                        </Box>
                                        <Grid container spacing={3}>
                                            {
                                                displayIssuers.map(issuer => (
                                                    <Grid item >
                                                        <Box key={issuer.id} sx={{ p: 2, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <Box sx={{ display: "flex", alignItems: "center", mr: 3 }}>
                                                                {
                                                                    issuer.type === EditorSearchResultType.ORGANIZATION_TEAM
                                                                        ?
                                                                        <BusinessOutlined color="primary" sx={{ fontSize: "32px" }} />
                                                                        :
                                                                        <PersonOutline color="primary" sx={{ fontSize: "32px" }} />
                                                                }
                                                                <Box sx={{ ml: 3 }}>
                                                                    <Typography>{issuer.name}</Typography>
                                                                    <Typography variant="caption">{issuer.description}</Typography>
                                                                </Box>
                                                            </Box>
                                                            <IconButton onClick={() => handleDeleteIssuer(issuer)}>
                                                                <Delete color="error" />
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={Tabs.CONFIRMATION}>
                                    <Box sx={{ m: 2, width: "100%", height: "100%"}}>
                                        <Box sx={{display:"flex", alignItems: "center", mb: 2}}>
                                            <CheckCircleOutline color="primary" sx={{ fontSize: "24px", mr: 1}} />
                                            <Typography>Confirmação de Leitura</Typography>
                                        </Box>
                                        <Box sx={{display:"flex", alignItems: "center", mb: 2}}>
                                            <Checkbox checked={readConfirmation} onChange={() => setReadConfirmation(!readConfirmation)}/>
                                            <Typography>Os participantes do contrato terão que confirmar a leitura do documento.</Typography>
                                        </Box>
                                        
                                        {
                                            readConfirmation 
                                            ?
                                            <Box>
                                                <Select defaultValue={1} sx={{mb: 2}}>
                                                    <ListItem value={1}>Confirmação de leitura de cláusulas.</ListItem>
                                                </Select>
                                                <Typography>Digite quais cláusulas necessitam que os participantes confirmem a leitura.</Typography>
                                                <Typography sx={{fontWeight: 600, fontSize: "14px", mb: 2}}>Separe as cláusulas por ';'</Typography>
                                                <TextareaAutosize 
                                                    style={{width: "600px", border: "1px solid #DCDFE6", borderRadius: "4px", fontFamily: "Noto Sans", padding: "8px"}}
                                                    placeholder="Cláusula 1; Cláusula 2; Cláusula 3; ...etc" 
                                                    value={clausesToConfirm} 
                                                    onChange={(e) => setClausesToConfirm(e.target.value)}
                                                    minRows={3}/>
                                            </Box>
                                                :
                                            <></>
                                        }
                                    </Box>
                                </TabPanel>
                                <TabPanel value={Tabs.TAGS} >
                                    <Box sx={{ m: 2, width: "100%", height: "100%" }}>
                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                            <SellOutlined color="primary" sx={{ fontSize: "32px" }} />
                                            <Typography sx={{ ml: 2 }} >Tags do modelo de contrato</Typography>
                                        </Box>
                                        <Typography variant="subtitle2" >Crie e gerencie suas tags do modelo de contrato. <b>(Máximo de 5 tags por modelo)</b></Typography>
                                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", mt: 2 }}>
                                            {
                                                tags.map(t => (
                                                    <Box key={t.tagName} sx={{ display: "flex", alignItems: "center", backgroundColor: "primary.main", m: 1, borderRadius: "36px", width: "156px", justifyContent: "end", height: "36px", boxShadow: 3 }}>
                                                        <Tooltip title={t.tagName}>
                                                            <Typography sx={{ fontSize: "12px", color: "#fff", padding: "6px 16px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{t.tagName}</Typography>
                                                        </Tooltip>
                                                        <Button onClick={() => {
                                                            const updatedTags = tags.filter((tag) => t.tagName !== tag.tagName);
                                                            setTags(updatedTags);
                                                        }} startIcon={<Close sx={{ color: "#fff" }} />} />
                                                    </Box>))
                                            }
                                            <Box sx={{ width: "156px", m: 1 }}>
                                                {
                                                    tags.length < 5
                                                        ?
                                                        <TagsAdder
                                                            onTagAdded={handleAddTags}
                                                        />
                                                        :
                                                        <></>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={Tabs.EMAIL}>
                                    <Box sx={{ m: 2, width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <EmailEditor interfaceTheme={interfaceTheme} mailTemplates={mailTemplates} updateMailTemplates={handleUpdateMailTemplates} />
                                    </Box>
                                </TabPanel>
                                <TabPanel value={Tabs.REVIEW}>
                                    <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                        <Description color="primary" sx={{ fontSize: "32px" }} />
                                        <Typography sx={{ ml: 2 }} >Revisão dos dados do modelo</Typography>
                                    </Box>
                                    <Box sx={{ m: 2, width: "100%", height: "100%" }}>
                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                            <Group color="primary" sx={{ fontSize: "32px" }} />
                                            <Typography sx={{ ml: 2 }} >{templateDTO.contractParties.length} Participante(s) adicionado(s)</Typography>
                                        </Box>
                                        <Grid container sx={{ mb: 6 }} spacing={3}>
                                            {
                                                templateDTO.contractParties.map(party => (
                                                    <Grid item xs={6} md={4} lg={3} key={party.role}>
                                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3, px: 2, py: 1, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px" }}>
                                                            {
                                                                party.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                                                    ?
                                                                    <AlternateEmail color="primary" />
                                                                    :
                                                                    <Business color="primary" />
                                                            }
                                                            <Typography sx={{ ml: 2 }} >{party.role}</Typography>
                                                        </Box>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                            <Menu color="primary" sx={{ fontSize: "32px" }} />
                                            <Typography sx={{ ml: 2 }} >{templateDTO.requiredContractPartiesInformation.length} Campo(s) de informação adicionado(s) </Typography>
                                        </Box>
                                        <Grid container sx={{ mb: 6 }} spacing={3}>
                                            {
                                                templateDTO.requiredContractPartiesInformation.map(ri => (
                                                    <Grid item xs={6} md={4} lg={3} key={ri.requiredInformation.name}>
                                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3, px: 2, py: 1, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px" }}>
                                                            {
                                                                riTypes.filter(riType => riType.value === ri.requiredInformation.type).map(riType => (
                                                                    <Tooltip title={riType.label}>
                                                                        {riType.icon}
                                                                    </Tooltip>
                                                                ))
                                                            }
                                                            <Typography sx={{ ml: 2 }} >{ri.requiredInformation.name}</Typography>
                                                        </Box>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                            <AttachMoney color="primary" sx={{ fontSize: "32px" }} />
                                            <Typography sx={{ ml: 2 }} >{templateDTO.billingSpecifications.length} Cobrança(s) adicionada(s) </Typography>
                                        </Box>
                                        <Grid container sx={{ mb: 6 }} spacing={3}>
                                            {
                                                templateDTO.billingSpecifications.map(b => (
                                                    <Grid item xs={6} md={4} lg={3} key={b.name}>
                                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3, px: 2, py: 1, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px" }}>
                                                            {
                                                                billTypes.filter(bType => bType.value === b.billingPeriodType).map(riType => (
                                                                    <Tooltip title={riType.label}>
                                                                        {riType.icon}
                                                                    </Tooltip>
                                                                ))
                                                            }
                                                            <Typography sx={{ ml: 2 }} >{b.name}</Typography>
                                                        </Box>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3 }}>
                                            <AttachFile color="primary" sx={{ fontSize: "32px" }} />
                                            <Typography sx={{ ml: 2 }} >{templateDTO.requiredAttachments.length} Anexo(s) adicionado(s) </Typography>
                                        </Box>
                                        <Grid container sx={{ mb: 6 }} spacing={3}>
                                            {
                                                templateDTO.requiredAttachments.map(b => (
                                                    <Grid item xs={6} md={4} lg={3} key={b.name}>
                                                        <Box display={"flex"} alignItems={"center"} sx={{ mb: 3, px: 2, py: 1, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px" }}>
                                                            <Typography sx={{ ml: 2 }} >{b.name}</Typography>
                                                        </Box>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Box>
                                </TabPanel>
                            </TabContext>
                            <DialogActions>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} >
                                    <Button variant="text" onClick={handleClose} >Cancelar</Button>
                                    {
                                        currentTab !== Tabs.REVIEW
                                            ?
                                            <Box>
                                                <Button variant="text" disabled={templateDTO.name.length <= 0} sx={{ mr: 2 }} onClick={checkIfUserCanSave} >Finalizar</Button>
                                                <Button variant="contained" endIcon={<ArrowForward />} disabled={templateDTO.name.length <= 0} onClick={handleGoFoward} >Próximo passo</Button>
                                            </Box>
                                            :
                                            <Button variant="contained" disabled={templateDTO.name.length <= 0} onClick={checkIfUserCanSave} >Finalizar</Button>
                                    }
                                </Box>
                            </DialogActions>
                        </DialogContent>
                    </>
                    :
                    <DialogContent>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width="100%" height="100%">
                            <CircularProgress color="primary" sx={{ fontSize: "64px" }} />
                        </Box>
                    </DialogContent>
            }
            <RenewAccessDialog open={openRenewAccessDialog} sessionRenewed={saveTemplate} />
        </Dialog>
    )
}

export default TemplateDataReview;