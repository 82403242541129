import { Add, AlternateEmail, Business, Delete } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { ContractPartie, IdentificationType, RequiredInformationSpecification, RequiredInformationType } from "../../../../../../models/contracts";
import { riTypes } from "../../../../../../utils/editorUtils";

// Interface and Types
interface RIFormProps extends React.ComponentProps<any> {
    currentRI?: RequiredInformationSpecification | null,
    requiredInformations: RequiredInformationSpecification[],
    parties: ContractPartie[],
    addRequiredInformation: (ri: RequiredInformationSpecification) => void,
    cancelAction: () => void,
}

// Default variables
const dummyField: RequiredInformationSpecification = {
    requiredInformation: {
        description: "",
        name: "",
        required: true,
        type: "SINGLE_LINE_TEXT",
    },
    isWildcard: false,
    contractParty: {
        role: ""
    },
    contractPartyRole: ""
}

const RIForm = (props: RIFormProps): JSX.Element => {

    // Destructure props object
    const { parties, requiredInformations, currentRI, cancelAction, addRequiredInformation } = props;

    // Data states
    const [newRI, setNewRI] = useState<RequiredInformationSpecification>(currentRI ? currentRI : dummyField);
    const [newRIErrors, setNewRIErrors] = useState<any>({});
    const [riName, setRIName] = useState(newRI.requiredInformation.name);
    const [riOptions, setRIOptions] = useState<string[]>(currentRI && currentRI.requiredInformation.options ? currentRI.requiredInformation.options : []);
    const [optionValue, setOptionValue] = useState("");

    // useEffects
    useEffect(() => {
        checkForErrors();
    }, [newRI])

    // Functions
    function checkForErrors() {
        const newErrors = { ...newRIErrors };

        // delete all errors
        for (const key in newErrors) {
            delete newErrors[key];
        }

        if (!currentRI || currentRI.requiredInformation.name !== newRI.requiredInformation.name) {
            const filter = requiredInformations.filter(f => f.requiredInformation.name.toLocaleLowerCase() === newRI.requiredInformation.name.toLocaleLowerCase());

            if (filter.length > 0) newErrors["name"] = "Campo já adicionado";
            else if (newRI.requiredInformation.name.length <= 0) newErrors["name"] = "Campo obrigatório";
        }

        if (!newRI.contractParty.role) newErrors["role"] = "Campo obrigatório";
        else if (!parties.find(p => p.role === newRI.contractParty.role)) newErrors["role"] = "Participante não existe";

        if (
            (newRI.requiredInformation.type === "SINGLE_SELECT" || newRI.requiredInformation.type === "MULTIPLE_SELECT") &&
            (!newRI.requiredInformation.options || newRI.requiredInformation.options.length <= 0)
        ) newErrors["options"] = "Campo obrigátorio";

        setNewRIErrors(newErrors);
        return newErrors;
    }

    // Handler Functions
    function handleChangeFieldName(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setRIName(e.target.value);
        setNewRI({ ...newRI, requiredInformation: { ...newRI.requiredInformation, name: e.target.value.trim() } })
    }

    function handleChangeParty(e: SelectChangeEvent) {
        setNewRI({ ...newRI, contractParty: { role: e.target.value }, contractPartyRole: e.target.value })
    }

    function handleAddOption(option: string) {
        if (!option) return;

        const formattedOption = option.trim();

        const alreadyExists = riOptions.find(o => o.toLocaleLowerCase() === formattedOption.toLocaleLowerCase());

        if (alreadyExists) return;

        const updatedOptions = [...riOptions, formattedOption];
        setRIOptions(updatedOptions.sort((a, b) => a.localeCompare(b)));
        setNewRI({...newRI, requiredInformation: {...newRI.requiredInformation, options: updatedOptions.sort((a, b) => a.localeCompare(b))}});
        setOptionValue("");
    }

    function handleDeleteOption(option: string) {
        const filter = riOptions.filter(o => o !== option);
        setRIOptions(filter);
    }

    function handleChangeRIType(e: SelectChangeEvent<RequiredInformationType>) {
        if (e.target.value === "SINGLE_SELECT" || e.target.value === "MULTIPLE_SELECT") {
            setNewRI({ ...newRI, requiredInformation: { ...newRI.requiredInformation, type: e.target.value as RequiredInformationType, options: riOptions } });
        } else {
            setNewRI({ ...newRI, requiredInformation: { ...newRI.requiredInformation, type: e.target.value as RequiredInformationType, options: undefined } });
        }

    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                p: 4,
                border: "1px solid rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
                mt: 2,
                mb: 3
            }}
        >
            {
                currentRI && currentRI.isWildcard
                    ?
                    <></>
                    :
                    <FormGroup sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                        <FormControlLabel
                            control={<Checkbox checked={newRI.requiredInformation.required} onChange={(e) => setNewRI({ ...newRI, requiredInformation: { ...newRI.requiredInformation, required: e.target.checked } })} />}
                            label="Preenchimento obrigatório"
                        />
                    </FormGroup>
            }
            <TextField
                fullWidth
                value={riName}
                inputProps={{
                    maxLength: 30,
                }}
                onChange={(e) => handleChangeFieldName(e)}
                required
                label="Nome do Campo"
                sx={{ mt: 3 }}
                error={Boolean(newRIErrors["name"])}
                helperText={newRIErrors["name"] ? newRIErrors["name"] : !newRI.isWildcard ? `${newRI.requiredInformation.name.length} / 30` : ""}
                disabled={newRI.isWildcard}
            />
            <FormControl fullWidth sx={{ my: 6 }} required error={Boolean(newRIErrors["role"])}>
                <InputLabel id="contract-party-selection" >Participante responsável por preencher o campo</InputLabel>
                <Select
                    fullWidth
                    labelId="contract-party-selection"
                    value={newRI.contractParty.role}
                    label="Participante responsável por preencher o campo"
                    renderValue={(value) => (
                        <Box display={"flex"} alignItems={"center"}>
                            {
                                parties.filter(p => p.role === newRI.contractParty.role).map(party => (
                                    <ListItemIcon>
                                        {
                                            party.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                                ?
                                                <AlternateEmail />
                                                :
                                                <Business />

                                        }
                                    </ListItemIcon>
                                ))
                            }
                            <Typography>{value}</Typography>
                        </Box>
                    )}
                    onChange={(e) => handleChangeParty(e)}
                    error={Boolean(newRIErrors["role"])}
                >
                    {
                        parties.map(party => (
                            <MenuItem key={party.role} value={party.role} >
                                <ListItemIcon>
                                    {
                                        party.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                                            ?
                                            <AlternateEmail />
                                            :
                                            <Business />

                                    }
                                </ListItemIcon>
                                <ListItemText>{party.role}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText >{newRIErrors["role"] ? newRIErrors["role"] : ""}</FormHelperText>
            </FormControl>
            <FormControl fullWidth required>
                <InputLabel id="ri-type" >Tipo de dado que será inserido</InputLabel>
                <Select
                    fullWidth
                    labelId="ri-type"
                    value={newRI.requiredInformation.type}
                    renderValue={(value) => (
                        riTypes.filter(ri => ri.value === value).map(ri => (
                            <Box display={"center"} alignItems={"center"}>
                                {ri.icon}
                                <Typography sx={{ ml: 2 }} >{ri.label}</Typography>
                            </Box>
                        ))
                    )}
                    label="Tipo de dado que será inserido"
                    onChange={(e) => handleChangeRIType(e)}
                >
                    {
                        riTypes.map(ri => (
                            <MenuItem value={ri.value}>
                                <ListItemIcon>
                                    {ri.icon}
                                </ListItemIcon>
                                <ListItemText>{ri.label}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            {
                newRI.requiredInformation.type === "SINGLE_SELECT" || newRI.requiredInformation.type === "MULTIPLE_SELECT"
                    ?
                    <Box sx={{ mt: 6, width: "100%" }}>
                        <FormControl required error={newRIErrors["options"]} fullWidth>
                            <InputLabel htmlFor="ri-option">Adicionar opção</InputLabel>
                            <OutlinedInput
                                label="Adicionar opção"
                                id="ri-option"
                                required
                                value={optionValue}
                                onChange={(e) => setOptionValue(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') handleAddOption(optionValue);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleAddOption(optionValue)}>
                                            <Add />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                error={newRIErrors["options"]}
                            />
                            <FormHelperText>{newRIErrors["options"] ? newRIErrors["options"] : ""}</FormHelperText>
                        </FormControl>
                        <Box sx={{ mt: 3 }}>
                            <Grid container spacing={3}>
                                {
                                    riOptions.map(option => (
                                        <Grid
                                            item
                                            key={option}
                                        >
                                            <Box
                                                sx={{
                                                    border: "1px solid rgba(0, 0, 0, 0.25)",
                                                    borderRadius: "5px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    px: 2
                                                }}
                                            >
                                                <Typography>{option}</Typography>
                                                <IconButton onClick={() => handleDeleteOption(option)} sx={{ ml: 2 }} >
                                                    <Delete color="error" />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    </Box>
                    :
                    <></>
            }
            <TextField
                fullWidth
                multiline
                minRows={3}
                maxRows={5}
                label="Adicionar descrição do campo"
                value={newRI.requiredInformation.description}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewRI({ ...newRI, requiredInformation: { ...newRI.requiredInformation, description: event.target.value } })}
                inputProps={{ maxLength: 200 }}
                helperText={`${newRI.requiredInformation.description ? newRI.requiredInformation.description.length : 0} / 200`}
                sx={{ mb: 3, mt: 6 }}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                <Button variant="text" onClick={cancelAction} >Cancelar</Button>
                <Button variant="contained" disabled={Object.keys(newRIErrors).length > 0} onClick={() => addRequiredInformation(newRI)} >Salvar</Button>
            </Box>
        </Box>
    )
}

export default RIForm;