import { ContractsQuota } from "../models/organizations";
import contractsApi from "./contracts-api";


const OrganizationContractsQuotaService = {

    async fetchContractsQuota(): Promise<ContractsQuota> {
        return await (await contractsApi.get("/rest/v1/organizations/my/contracts/quotas")).data;
    }
}

export default OrganizationContractsQuotaService