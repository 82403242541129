import { AddComment, AlternateEmail, Business, Close, Person, PersonOutline } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ContractPartie, IdentificationType } from "../../../../../../../models/contracts";
import { UserAccount } from "../../../../../../../models/user";
import { show } from "../../../../../../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import OrganizationService from "../../../../../../../services/organization";
import TeamsService from "../../../../../../../services/teams";
import ErrorWrapper from "../../../../../../../utils/ErrorWrapper";

// Interface and Types
interface IssuePartieFormProps extends React.ComponentProps<any> {
    party: ContractPartie,
    parties: ContractPartie[],
    updateParties: (updatedParty: ContractPartie) => void
}

const IssuePartieForm = (props: IssuePartieFormProps): JSX.Element => {

    // Destructure props object
    const { party, parties, updateParties } = props;

    // Contexts
    const notification = useAppDispatch();

    // Boolean states
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [openCommentDialog, setOpenCommentDialog] = useState(false);

    // Data states
    const [members, setMembers] = useState<UserAccount[]>([]);
    const [selectedMember, setSelectedMember] = useState<UserAccount | null>(null);
    const [identification, setIdentification] = useState("");
    const [partyComment, setPartyComment] = useState("");
    const [partiesIdentification, setPartiesIdentification] = useState<string[]>([]);

    // useEffects
    useEffect(() => {
        fetchMember();
        fetchTeamMembers();
        fetchIdentification();
        fetchComment();
    }, [])

    useEffect(() => {
        checkForDuplicates();
        updateParty();
    }, [identification, selectedMember])

    // Functions
    async function fetchTeamMembers() {
        if (!party.identification || !party.identification.teamId) return;

        const teamId = party.identification.teamId;

        try {
            const pagination = await TeamsService.membersPagination(teamId);

            let members: UserAccount[] = [];

            for (let currentPage = 1; currentPage <= pagination.totalPages; currentPage++) {
                TeamsService.fetchMembers(teamId, currentPage)
                    .then(response => {
                        if (!response) return;
                        members = [...response, ...members];

                        setMembers(members);

                        if (party.identification.uuid) {
                            const foundMember = members.find(member => member.uuid === party.identification.uuid);

                            if (foundMember) {
                                setIdentification(foundMember.fullname);
                            }
                        }
                    })
                    .catch(e => {
                        const err = new ErrorWrapper(e);
                        notification(show({ type: "error", message: err.message }));
                    })
            }




        } catch (e) {
            const err = new ErrorWrapper(e);
            notification(show({ type: "error", message: err.message }));
        }

    }

    function fetchMember() {
        if (!party.identification || !party.identification.uuid) return;

        OrganizationService.fetchOrganizationMemberByUuid(party.identification.uuid)
            .then(response => {
                setSelectedMember(response);
                setIdentification(response.fullname);
                const updatedIds = [...partiesIdentification, response.email];
                setPartiesIdentification(updatedIds);
            })
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({ type: "error", message: err.message }));
            })
    }

    function checkForDuplicates() {

        let hasDuplicates = false;

        switch (party.identificationType) {
            case IdentificationType.ORGANIZATION_USER:
            case IdentificationType.ORGANIZATION_TEAM:
                parties.forEach(cp => {
                    if (!cp.identification || !selectedMember || cp.role === party.role) return;

                    const cpIdentification = Object.values(cp.identification);

                    setIsDuplicate(cpIdentification.includes(selectedMember.uuid));

                    if (!hasDuplicates) {
                        hasDuplicates = cpIdentification.includes(selectedMember.uuid);
                    }
                });
                break;

            case IdentificationType.IDENTIFIED_BY_EMAIL:
                parties.forEach(cp => {
                    if (!cp.identification) return;
                    const cpIdentification = Object.values(cp.identification);

                    setIsDuplicate(cpIdentification.includes(identification));

                    if (!hasDuplicates) {
                        hasDuplicates = cpIdentification.includes(identification);
                    }
                });
                break;
        }
        return hasDuplicates;
    }

    function updateParty() {
        const updatedParty: ContractPartie = party;

        switch (party.identificationType) {
            case IdentificationType.ORGANIZATION_USER:
            case IdentificationType.ORGANIZATION_TEAM:
                if (!selectedMember) return;
                updatedParty.identification = { uuid: selectedMember.uuid };
                break;
            case IdentificationType.IDENTIFIED_BY_EMAIL:
                updatedParty.identification = { email: identification };
                break;
        }

        updateParties(updatedParty);
    }

    function fetchIdentification() {
        switch (party.identificationType) {
            case IdentificationType.IDENTIFIED_BY_EMAIL:
                if (!party.identification || !party.identification.email) return;
                const foundParty = parties.find(cp => cp.role === party.role);
                if (foundParty && foundParty.identification && foundParty.identification.email) {
                    setIdentification(foundParty.identification.email);
                }
                break;
        }
    }

    function fetchComment() {
        parties.forEach(cp => {
            if (cp.role === party.role) setPartyComment(cp.comment ? cp.comment : "");
        })
    }

    // Handler functions
    function handleSelectTeamMember(member: UserAccount) {
        setSelectedMember(member);
        setIdentification(member.fullname);
        
        const updatedIds = [...partiesIdentification, member.email];
        setPartiesIdentification(updatedIds);
    }

    return (
        <Box key={party.role} sx={{ mb: 6, p: 2, border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "5px" }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                {
                    party.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                        ?
                        <AlternateEmail color="primary" />
                        :
                        <Business color="primary" />
                }
                <Typography sx={{ ml: 3 }} >{party.role}</Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
                {
                    party.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
                        ?
                        <TextField
                            required
                            fullWidth
                            label="Insira o e-mail deste participante"
                            type="email"
                            value={identification}
                            onChange={(e) => {
                                setIdentification(e.target.value.trim())
                            }}
                            error={identification.length <= 0 || isDuplicate || !/^[^\s@]+@[^\s@,]+\.[^\s@,]+$/.test(identification)}
                            helperText={
                                identification.length <= 0 ? "Campo obrigatório" : 
                                isDuplicate ? "Participante duplicado" : 
                                !/^[^\s@]+@[^\s@,]+\.[^\s@,]+$/.test(identification) ? "Insira um e-mail valido" : ""
                            }
                        />
                        :
                        party.identificationType === IdentificationType.ORGANIZATION_USER
                            ?
                            selectedMember
                                ?
                                <Box>
                                    <Typography variant="caption"  >Usuário selecionado para este participante: </Typography>
                                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                                        <PersonOutline color="primary" />
                                        <Typography sx={{ ml: 3 }} >{selectedMember.fullname}</Typography>
                                    </Box>
                                </Box>
                                :
                                <></>
                            :
                            party.identificationType === IdentificationType.ORGANIZATION_TEAM
                                ?
                                <FormControl required error={!selectedMember || isDuplicate} fullWidth>
                                    <InputLabel id="contract-party-identification" >Identificaçao do participante</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="contract-party-identification"
                                        value={identification}
                                        label=">Identificaçao do participante"
                                        renderValue={(value) => (
                                            <Typography>{value}</Typography>
                                        )}
                                        error={!selectedMember || isDuplicate}
                                    >
                                        {
                                            members.map(member => (
                                                <MenuItem key={member.uuid} onClick={() => handleSelectTeamMember(member)}>
                                                    <ListItemIcon>
                                                        <Person color="primary" />
                                                    </ListItemIcon>
                                                    <ListItemText >
                                                        <Typography>{member.fullname}</Typography>
                                                        <Typography variant="caption" sx={{ mt: 1 }}>{member.email}</Typography>
                                                    </ListItemText>
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormHelperText>{!selectedMember ? "Campo obrigatório" : isDuplicate ? "Participante duplicado" : ""}</FormHelperText>
                                </FormControl>
                                :
                                <></>
                }
            </Box>
            <Box sx={{ mt: 3, width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {
                    party.identificationType !== IdentificationType.IDENTIFIED_BY_EMAIL
                        ?
                        <Button variant="outlined">
                            Verificar duplicidade
                        </Button>
                        :
                        <Box>
                        </Box>
                }
                <Tooltip title={party.comment ? "Editar comentário para o participante" : "Adicionar comentário para o participante"}>
                    <IconButton onClick={() => setOpenCommentDialog(true)} >
                        <AddComment htmlColor="#999" />
                    </IconButton>
                </Tooltip>
            </Box>
            <Dialog open={openCommentDialog} onClose={() => setOpenCommentDialog(false)} maxWidth="lg" fullWidth>
                <DialogTitle>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <Typography variant="h6">{party.comment ? "Editar comentário" : "Adicionar comentário"}</Typography>
                        <IconButton onClick={() => setOpenCommentDialog(false)}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        minRows={5}
                        maxRows={7}
                        value={partyComment}
                        onChange={(e) => setPartyComment(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <Button variant="outlined" onClick={() => setOpenCommentDialog(false)}>
                            Cancelar
                        </Button>
                        <Button variant="contained"
                            onClick={() => {
                                const updatedParty: ContractPartie = {
                                    ...party,
                                    comment: partyComment
                                }

                                updateParties(updatedParty);
                                setOpenCommentDialog(false);
                            }}
                        >
                            Salvar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default IssuePartieForm;